define("plutof/templates/components/common/record-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "htvfJ0BS",
    "block": "{\"symbols\":[\"record\",\"&default\",\"@remove\",\"@namedBlocksInfo\",\"@records\"],\"statements\":[[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"record-list__record\"],[12],[2,\"\\n\"],[6,[37,2],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[11,\"button\"],[24,0,\"record-list__remove-button\"],[4,[38,1],[\"click\",[30,[36,0],[[32,3],[32,1]],null]],null],[12],[2,\"\\n                \"],[10,\"span\"],[14,0,\"icon-remove\"],[12],[13],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,2],[[30,[36,3],[[32,4],\"default\",[27,[32,2]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[18,2,[[32,1]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"            \"],[1,[32,1,[\"representation\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"if\",\"-has-block\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/common/record-list.hbs"
    }
  });
});