define("plutof/components/study/edit", ["exports", "plutof/mixins/component-validations"], function (_exports, _componentValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Study::GeneralData
      @project={{@project}}
      @projectPersons={{@projectPersons}}
      @addPerson={{@addPerson}}
      @removePerson={{@removePerson}}
      @useNewLogo={{@useNewLogo}}
      @validationContext={{@validationContext.project}} />
  
  {{#if @workgroupData}}
      <Agent::OrganizationWorkgroup
          @workgroupData={{@workgroupData}}
          @validationContext={{@validationContext.managingGroup}} />
  {{/if}}
  
  <Study::ProjectAreas
      @projectAreas={{@projectAreas}}
      @add={{@addProjectArea}}
      @remove={{@removeProjectArea}} />
  
  <Identifiers::Edit
      @model={{@identifiers}}
      @workgroupData={{@workgroupData}}
      @allowReservingForAnyone={{@project.public_linking}} />
  
  <LinkedItems::Edit
      @data={{@associatedData}}
      @validationContext={{@validationContext.associatedData}} />
  
  <Study::Access
      @rights={{@accessRights}}
      @project={{@project}}
      @warnAboutRecordPermissions={{@warnAboutPermissionChanges}} />
  
  <StickyBottomWrapper>
      <InvalidPanels @validationContext={{@validationContext}} />
  
      <div class="action-buttons">
          <Common::SaveButton @save={{@save}} @disabled={{@validationContext.isInvalid}} />
          <Common::CancelButton @cancel={{route-action "goBack"}} />
      </div>
  </StickyBottomWrapper>
  
  */
  {
    "id": "KSHl5GR+",
    "block": "{\"symbols\":[\"@workgroupData\",\"@validationContext\",\"@project\",\"@projectPersons\",\"@addPerson\",\"@removePerson\",\"@useNewLogo\",\"@projectAreas\",\"@addProjectArea\",\"@removeProjectArea\",\"@identifiers\",\"@associatedData\",\"@accessRights\",\"@warnAboutPermissionChanges\",\"@save\"],\"statements\":[[8,\"study/general-data\",[],[[\"@project\",\"@projectPersons\",\"@addPerson\",\"@removePerson\",\"@useNewLogo\",\"@validationContext\"],[[32,3],[32,4],[32,5],[32,6],[32,7],[32,2,[\"project\"]]]],null],[2,\"\\n\\n\"],[6,[37,0],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"agent/organization-workgroup\",[],[[\"@workgroupData\",\"@validationContext\"],[[32,1],[32,2,[\"managingGroup\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,\"study/project-areas\",[],[[\"@projectAreas\",\"@add\",\"@remove\"],[[32,8],[32,9],[32,10]]],null],[2,\"\\n\\n\"],[8,\"identifiers/edit\",[],[[\"@model\",\"@workgroupData\",\"@allowReservingForAnyone\"],[[32,11],[32,1],[32,3,[\"public_linking\"]]]],null],[2,\"\\n\\n\"],[8,\"linked-items/edit\",[],[[\"@data\",\"@validationContext\"],[[32,12],[32,2,[\"associatedData\"]]]],null],[2,\"\\n\\n\"],[8,\"study/access\",[],[[\"@rights\",\"@project\",\"@warnAboutRecordPermissions\"],[[32,13],[32,3],[32,14]]],null],[2,\"\\n\\n\"],[8,\"sticky-bottom-wrapper\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"invalid-panels\",[],[[\"@validationContext\"],[[32,2]]],null],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"action-buttons\"],[12],[2,\"\\n        \"],[8,\"common/save-button\",[],[[\"@save\",\"@disabled\"],[[32,15],[32,2,[\"isInvalid\"]]]],null],[2,\"\\n        \"],[8,\"common/cancel-button\",[],[[\"@cancel\"],[[30,[36,1],[\"goBack\"],null]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"route-action\"]}",
    "meta": {
      "moduleName": "plutof/components/study/edit.hbs"
    }
  });
  class StudyEdit extends Ember.Component.extend(_componentValidations.default) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "validationChildren", [{
        name: 'project',
        label: 'General.generalData'
      }, {
        name: 'access',
        label: 'General.access'
      }, {
        name: 'associatedData',
        label: 'General.associatedData'
      }, {
        name: 'managingGroup',
        label: 'group.panels.workgroup'
      }]);
    }
  }
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, StudyEdit);
});