define("plutof/translations/est/occurrence-source", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    newsamplingevent: 'Uus prooviala ja sündmus',
    samplingevent: 'Olemasolev prooviala ja sündmus',
    specimen: 'Olemasolev eksemplar',
    sequence: 'Olemasolev sekvents',
    observation: 'Olemasolev vaatlus',
    strain: 'Olemasolev eluseksemplar',
    materialsample: 'Olemasolev proov',
    none: 'Pole seotud'
  };
});