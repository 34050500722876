define("plutof/transforms/date", ["exports", "plutof/transforms/string"], function (_exports, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // The date type is for the purposes of reflection
  //
  // Ideally, it would serialize between dates and strings,
  // but we're already deep into date=string territory, so
  // no point really
  var _default = _exports.default = _string.default;
});