define("plutof/translations/eng/forms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    baseForm: 'Basic form (default fields)',
    specimen: 'Specimen',
    observation: 'Observation',
    Sequence: 'Sequence',
    useAsTemplate: 'Templates',
    materialS: 'Material Sample',
    livingculture: 'Living Specimen',
    title: 'Forms',
    customForm: 'Custom form',
    clickToMake: 'Checkbox makes the form input required.',
    needsModeration: 'Requires moderation',
    moderateCheck: 'Mark if entries added with this form require moderation',
    moderators: 'Moderators',
    usesCommonNames: 'Enable entering taxa using vernacular names',
    usesCommonNamesTooltip: 'Enable entering taxa using vernacular names',
    isolation: 'Isolation',
    licenceType: 'Licence type',
    uploadingImage: 'Uploading image...',
    taxonList: 'Taxon list',
    taxonListTooltip: 'Taxa (including subtaxa) that can be used with this Form',
    newTitle: 'New Form',
    editTitle: 'Edit Form',
    default: 'Default',
    bird: 'Bird',
    insect: 'Insect',
    animal: 'Animal',
    fungus: 'Fungus',
    plant: 'Plant',
    // TODO: Move all this under per-type prefixes
    host: 'Host-associated',
    // Samples
    plantAssociated: 'Plant-associated',
    soil: 'Soil',
    water: 'Water',
    builtEnv: 'Built Environment',
    materialSample: {
      bulk: 'Bulk sample'
    },
    htsRepresentative: 'HTS representative',
    // Sequence

    algae: 'Algae',
    // Specimen
    mammalia: 'Mammalia',
    bat: 'Bat',
    traitsLabel: 'Traits and Measurements',
    taxonSheet: {
      title: 'Taxon sheet'
    },
    palette: {
      headers: {
        habitat: 'Habitat fields',
        event: 'Samplingevent fields',
        area: 'Samplingarea fields',
        observation: 'Observation fields',
        specimen: 'Specimen fields',
        materialsample: 'Material sample fields',
        strain: 'Living specimen fields',
        sequence: 'Sequence fields'
      }
    },
    formSelector: {
      title: 'Form',
      manual: 'Other',
      placeholder: 'Form name...',
      nonwhitelistedForm: 'Project {{project}} does not support this record type'
    },
    traits: {
      trait: 'Trait',
      addRoute: 'New Trait',
      editRoute: 'Edit Trait',
      setChoices: 'Choices',
      source: 'Source'
    }
  };
});