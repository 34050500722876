define("plutof/templates/forms/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "2orvoDu4",
    "block": "{\"symbols\":[\"translations\",\"Field\",\"form\",\"common\"],\"statements\":[[8,\"test/route-marker\",[],[[\"@route\"],[\"forms.index\"]],null],[2,\"\\n\\n\"],[8,\"navbar/list\",[],[[\"@icon\",\"@title\",\"@addRoute\",\"@pagination\",\"@infoTitle\",\"@infoContent\",\"@goBack\"],[[30,[36,0],[\"form\"],null],[30,[36,1],[\"Forms.title\"],null],\"forms.add\",[32,0,[\"model\",\"pagination\"]],[30,[36,1],[\"Forms.title\"],null],\"information/content/forms/index\",[30,[36,2],[\"goBack\"],null]]],null],[2,\"\\n\\n\"],[8,\"i18n/model-strings\",[],[[\"@model\"],[\"measurement.mainform\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"tables/list-route\",[],[[\"@records\"],[[32,0,[\"model\",\"pagination\",\"objects\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,2],[],[[\"@label\"],[[30,[36,1],[\"PlutofListViewColumn.name\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],null,[[\"route\",\"model\"],[\"forms.view\",[32,3,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"                \"],[1,[32,3,[\"name\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,2],[],[[\"@label\"],[[30,[36,1],[\"PlutofListViewColumn.type\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,4],[[32,1,[\"type\",\"choices\"]],[32,3,[\"type\"]]],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,4,[\"modified\"]],[],[[],[]],null],[2,\"\\n    \"]],\"parameters\":[2,3,4]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\",\"route-action\",\"link-to\",\"get\"]}",
    "meta": {
      "moduleName": "plutof/templates/forms/index.hbs"
    }
  });
});