define("plutof/components/search/filters/time-range-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Search::Filters::-RangeInput
      @filter={{@filter}}
      @inputGroupClass="time-filter-group"
  >
      <:lower>
          <DatePicker
              @value={{@filter.lowerBound}}
              @invertLayout={{true}}
              @pickerClass="form-control time-filter-group__input"
              @class="time-filter-date-picker" />
      </:lower>
  
      <:upper as |disabled|>
          <DatePicker
              @value={{@filter.upperBound}}
              @invertLayout={{true}}
              @disabled={{disabled}}
              @pickerClass="form-control time-filter-group__input"
              @class="time-filter-date-picker" />
      </:upper>
  </Search::Filters::-RangeInput>
  
  */
  {
    "id": "HlfeR7YV",
    "block": "{\"symbols\":[\"__arg0\",\"__arg1\",\"disabled\",\"@filter\"],\"statements\":[[8,\"search/filters/-range-input\",[],[[\"@filter\",\"@inputGroupClass\",\"@namedBlocksInfo\"],[[32,4],\"time-filter-group\",[30,[36,3],null,[[\"lower\",\"upper\"],[0,1]]]]],[[\"default\"],[{\"statements\":[[6,[37,2],[[30,[36,1],[[32,1],\"lower\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n        \"],[8,\"date-picker\",[],[[\"@value\",\"@invertLayout\",\"@pickerClass\",\"@class\"],[[32,4,[\"lowerBound\"]],true,\"form-control time-filter-group__input\",\"time-filter-date-picker\"]],null],[2,\"\\n    \"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[30,[36,1],[[32,1],\"upper\"],null]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"date-picker\",[],[[\"@value\",\"@invertLayout\",\"@disabled\",\"@pickerClass\",\"@class\"],[[32,4,[\"upperBound\"]],true,[32,3],\"form-control time-filter-group__input\",\"time-filter-date-picker\"]],null],[2,\"\\n    \"]],\"parameters\":[3]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1,2]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"let\",\"-is-named-block-invocation\",\"if\",\"hash\"]}",
    "meta": {
      "moduleName": "plutof/components/search/filters/time-range-input.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});