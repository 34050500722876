define("plutof/helpers/repeat-counts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.helper(function ([name, count]) {
    return count === 1 ? name : `${name} (${count})`;
  });
});