define("plutof/components/study/access", ["exports", "@glimmer/component", "plutof/utils/notifications"], function (_exports, _component, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::Simple @title={{i18n-t "General.access"}}>
      <Layout::RegularGrid @columns={{2}}>
          <div>
              {{common/local-label "Access.canView"}}
  
              <Access::Edit::PublicOrPrivate
                  @value={{@project.is_public}}
                  @update={{action (mut @project.is_public)}} />
          </div>
  
          <div>
              {{common/local-label "Access.publicLinkingLabel" "Access.publicLinkingHint"}}
  
              <Access::Edit::PublicOrPrivate
                  @value={{@project.public_linking}}
                  @update={{action (mut @project.public_linking)}}
                  @disabled={{not @project.is_public}} />
          </div>
      </Layout::RegularGrid>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "YXnM1D7I",
    "block": "{\"symbols\":[\"@project\"],\"statements\":[[8,\"plutof-panel/simple\",[],[[\"@title\"],[[30,[36,0],[\"General.access\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"layout/regular-grid\",[],[[\"@columns\"],[2]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"div\"],[12],[2,\"\\n            \"],[1,[30,[36,1],[\"Access.canView\"],null]],[2,\"\\n\\n            \"],[8,\"access/edit/public-or-private\",[],[[\"@value\",\"@update\"],[[32,1,[\"is_public\"]],[30,[36,3],[[32,0],[30,[36,2],[[32,1,[\"is_public\"]]],null]],null]]],null],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"div\"],[12],[2,\"\\n            \"],[1,[30,[36,1],[\"Access.publicLinkingLabel\",\"Access.publicLinkingHint\"],null]],[2,\"\\n\\n            \"],[8,\"access/edit/public-or-private\",[],[[\"@value\",\"@update\",\"@disabled\"],[[32,1,[\"public_linking\"]],[30,[36,3],[[32,0],[30,[36,2],[[32,1,[\"public_linking\"]]],null]],null],[30,[36,4],[[32,1,[\"is_public\"]]],null]]],null],[2,\"\\n        \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"common/local-label\",\"mut\",\"action\",\"not\"]}",
    "meta": {
      "moduleName": "plutof/components/study/access.hbs"
    }
  });
  let ProjectAccess = (_dec = Ember.inject.service, _dec2 = Ember._action, (_class = class ProjectAccess extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "i18n", _descriptor, this);
    }
    setPublic(isPublic) {
      const project = this.args.project;
      project.set('is_public', isPublic);
      project.set('public_linking', project.public_linking && isPublic);
      if (this.args.warnAboutRecordPermissions) {
        (0, _notifications.clearNotifications)();
        (0, _notifications.displayNotification)('warning', this.i18n.translate('Projects.permissionsChangeWarning'), null, 0, false);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "i18n", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setPublic", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "setPublic"), _class.prototype)), _class));
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ProjectAccess);
});