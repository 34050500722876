define("plutof/helpers/reverse-word", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.reverseWord = reverseWord;
  function reverseWord([word]) {
    return word.split('').reverse().join('');
  }
  var _default = _exports.default = Ember.Helper.helper(reverseWord);
});