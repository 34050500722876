define("plutof/translations/est/dmp", ["exports", "plutof/translations/helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    general: {
      dmp: 'Andmehaldusplaan'
    },
    routeTitles: {
      list: 'Andmehaldusplaanid',
      add: 'Uus andmehaldusplaan',
      edit: 'Muuda andmehaldusplaani',
      view: 'Andmehaldusplaan'
    },
    fields: {
      title: {
        label: 'Pealkiri',
        tooltip: 'Andmehaldusplaani pealkiri.'
      },
      description: {
        label: 'Kirjeldus',
        tooltip: 'Andmehaldusplaani kirjeldav tekst vabas vormis.'
      },
      ids: {
        label: 'Indentifikaatorid',
        tooltip: 'Andmehaldusplaani identifikaatorid.'
      },
      project: {
        label: 'Projekt',
        tooltip: `Andmehaldusplaani juurde kuuluv project PlutoF-is, mille raames toorandmed
                kogutakse.`
      },
      ethical_issues_exist: {
        label: 'Esineb eetilisi probleeme',
        tooltip: 'Märkimaks, kas toorandmetes esineb eetilisi küsimusi.'
      },
      ethical_issues_description: {
        label: 'Eetiliste probleemide kirjeldus',
        tooltip: 'Esinevate eetiliste probleemide otsene kirjeldus.'
      },
      ethical_issues_report: {
        label: 'Eetiliste probleemide aruanne',
        tooltip: 'Märgib, kust on leitav eetikakomitee koosoletu protokoll.'
      }
    },
    licence: {
      ref: {
        label: 'Litsents',
        tooltip: 'Litsentsi dokumendi link.',
        placeholder: 'https://example.com/licence'
      },
      start_date: {
        label: 'Litsentsi algus',
        tooltip: 'Tulevikku määratud alguskuupäev tähistab selle ajani kehtivat embargo perioodi.'
      }
    },
    project: {
      title: {
        label: 'Pealkiri',
        tooltip: 'Projekti pealkiri.'
      },
      description: {
        label: 'Kirjeldus',
        tooltip: 'Projekti kirjeldus.'
      },
      category: {
        label: 'Kategooria',
        tooltip: 'Projekti kategooria.'
      },
      type: {
        label: 'Tüüp',
        tooltip: 'Projekti tüüp.'
      },
      start: {
        label: 'Alguskuupäev',
        tooltip: 'Projekti alguskuupäev.'
      },
      end: {
        label: 'Lõppkuupäev',
        tooltip: 'Projekti lõppkuupäev.'
      },
      funding: {
        status: {
          label: 'Rahastusstaatus',
          tooltip: 'Projekti rahastusstaatus.'
        },
        funder: {
          identifier: {
            label: 'Rahastaja identifikaator',
            tooltip: `Rajastaja identifikaator, soovitatavalt CrossRef Funder Registry.

                        Vaata: https://www.crossref.org/services/funder-registry/`
          },
          type: {
            label: 'Rahastaja tüüp',
            tooltip: ''
          }
        },
        grant: {
          identifier: {
            label: 'Granti identifikaator',
            tooltip: 'Seotud projekti granti identifikaator.'
          },
          type: {
            label: 'Identifikaatori tüüp',
            tooltip: ''
          }
        }
      }
    },
    dataset: {
      title: {
        label: 'Pealkiri',
        tooltip: `Vastavalt W3C DCAT andmekataloogi sõnastikule on pealkiri nii andmesetil kui
                distributsioonil. Need pealkirjad võivad ühtida, kuid enamasti viitab distributsiooni
                kirjeldus kindlale failile ja andmeseti kirjelduse sisu jääb abstraktsemaks.`
      },
      description: {
        label: 'Kirjeldus',
        tooltip: `Vastavalt W3C DCAT andmekataloogi sõnastikule kirjeldatakse nii andmeseti kui
                distributsiooni. Need kirjeldused võivad ühtida, kuid enamasti viitab distributsiooni
                kirjeldus kindlale failile ja andmeseti kirjelduse sisu jääb abstraktsemaks.`
      },
      type: {
        label: 'Tüüp',
        tooltip: `Sobiv tüüp DataCite või COAR sõnastikest või tavanimetus, nt. toorandmed, tarkvara,
                uurimustöö jms.

                https://schema.datacite.org/meta/kernel-4.1/doc/DataCite-MetadataKernel_v4.1.pdf

                http://vocabularies.coar-repositories.org/pubby/resource_type.html`,
        placeholder: 'nt. pilt'
      },
      language: {
        label: 'Keel',
        tooltip: 'Andmeseti keel formaadis ISO 639-3.',
        placeholder: 'nt. est'
      },
      keyword: {
        label: 'Märksõna',
        tooltip: ''
      },
      issued: {
        label: 'Välja antud',
        tooltip: ''
      },
      preservation_statement: {
        label: 'Säilitamine',
        tooltip: 'Andmete kaitse ja säilitamise määruste kirjeldus, nt. mil määral andmeid säilitama.'
      },
      personal_data: {
        label: 'Isikustatud andmed',
        tooltip: ''
      },
      sensitive_data: {
        label: 'Tundlikud andmed',
        tooltip: ''
      },
      security_and_privacy: {
        label: 'Turvalisus ja privaatsus',
        tooltip: `Nõuded soses andmeseti turvalisuse ja privaatsusega, nt. kui andmed pole
                anonümiseeritud.`
      },
      technical_resource: {
        label: 'Tehnilised ressursid',
        tooltip: 'Andmete töötlemiseks vajalikud tehnilised vahendid, nt. mikroskoop.'
      },
      identifiers: {
        label: 'Andmeseti identifikaatorid',
        tooltip: 'Identifiers of the dataset.'
      },
      keywords: {
        label: 'Andmeseti märksõnad',
        tooltip: ''
      }
    },
    datasetId: {
      type: {
        label: 'Tüüp',
        tooltip: 'Identifikaatori tüüp.'
      },
      identifier: {
        label: 'Identifikaator',
        tooltip: 'Andmeseti identifikaator.'
      }
    },
    datasetTechnicalResource: {
      name: {
        label: 'Tehnilise ressursi nimi',
        tooltip: (0, _helpers.alias)('dmp.dataset.technical_resource.tooltip')
      },
      description: {
        label: 'Tehnilisi ressursi kirjeldus',
        tooltip: (0, _helpers.alias)('dmp.dataset.technical_resource.tooltip')
      }
    },
    // XXX What's happening here?
    datasetSecurityAndPrivacy: {
      title: {
        label: 'Turvalisus ja privaatsus',
        tooltip: (0, _helpers.alias)('dmp.dataset.security_and_privacy.tooltip')
      },
      description: {
        label: 'Kirjeldus',
        tooltip: (0, _helpers.alias)('dmp.dataset.security_and_privacy.tooltip')
      }
    },
    datasetMetadata: {
      description: {
        label: 'Metaandmete kirjeldus',
        tooltip: ''
      },
      language: {
        label: 'Metaandmete keel',
        tooltip: 'Metaandmete keel formaadis ISO 639-3.',
        placeholder: 'nt. est'
      }
    },
    datasetMetadataStandard: {
      identifier: {
        label: 'Metaandmete standardi identifikaator',
        tooltip: '',
        placeholder: 'nt. http://www.dublincore.org/specifications/dublin-core/dcmi-terms/'
      },
      type: {
        label: 'Metaandmete identifikaator tüüp',
        tooltip: 'Metaandmete standardi identifikaatori tüüp.'
      }
    },
    dmpID: {
      identifier: {
        label: 'Identifikaator',
        tooltip: 'Andmehaldusplaani identifikaator.'
      },
      type: {
        label: 'Identifikaatori tüüp',
        tooltip: 'Andmehaldusplaani Identifikaatori tüüp.'
      }
    },
    datasetID: {
      identifier: {
        label: 'Andmeseti identifikaator',
        tooltip: 'Andmeseti identifikaator.'
      },
      type: {
        label: 'Tüüp',
        tooltip: 'Andmeseti identifikaatori tüüp.'
      }
    },
    distribution: {
      title: {
        label: 'Pealkiri',
        tooltip: (0, _helpers.alias)('dmp.dataset.title.tooltip')
      },
      description: {
        label: 'Kirjeldus',
        tooltip: (0, _helpers.alias)('dmp.dataset.description.tooltip')
      },
      access_url: {
        label: 'URL',
        tooltip: 'URL ressurssile, mis annab pääsu andmeseti distributsioonile. Nt. avaleht.',
        placeholder: 'https://example.com/dataset'
      },
      available_until: {
        label: 'Kättesaadav kuni',
        tooltip: 'Märgib mis ajani peab antud distributsioon kättasaadav olema.'
      },
      byte_size: {
        label: 'Suurus baitides',
        tooltip: ''
      },
      download_url: {
        label: 'Allalaadimise URL',
        tooltip: 'Allalaetava faili URL.',
        placeholder: 'https://example.com/dataset/download'
      },
      format: {
        label: 'Formaat',
        tooltip: `Formaadi meedia tüüp (MIME), kui võimalik, või tavanimetus.

                https://www.iana.org/assignments/media-types/media-types.xhtml`
      },
      data_access: {
        label: 'Juurdepääs',
        tooltip: 'Andmetele juurdepääsu piirang.'
      }
    },
    cost: {
      title: {
        label: 'Kulu',
        tooltip: 'nt. andmemajutus ja varundamine.'
      },
      description: {
        label: 'Kirjeldus',
        tooltip: 'nt. teenuse hoolduskulu...'
      },
      value: {
        label: 'Summa',
        tooltip: ''
      },
      currency: {
        label: 'Valuuta',
        tooltip: 'Lubatud väärtused ISO 4217 standardi järgi.',
        placeholder: 'nt. EUR/USD/GBP'
      }
    },
    host: {
      availability: {
        label: 'Kättesaadavus',
        tooltip: 'Andmeseti kättesaadavusprotsent.',
        placeholder: 'nt. 95%'
      },
      backup_frequency: {
        label: 'Andmevarundussagedus',
        tooltip: '',
        placeholder: 'nt. iga nädal'
      },
      backup_type: {
        label: 'Andmevarunduse tüüp',
        tooltip: 'Andmevarunduse tehniline lahenduse andmekandja.',
        placeholder: 'nt. magnetlint'
      },
      geo_location: {
        label: 'Geograafiline asukoht',
        tooltip: 'Andmemajutuse füüsiline asukoht (ISO 3166-1 riigi kood).',
        placeholder: 'nt. EST/GBR/USA'
      },
      storage_type: {
        label: 'Andmekandja tüüp',
        tooltip: 'Nõutav andmesäilituslahenduse tüüp.'
      },
      url: {
        label: 'URL',
        tooltip: 'Andmeseti majutaja URL.',
        placeholder: 'https://example.com/'
      },
      description: {
        label: 'Kirjeldus',
        tooltip: ''
      },
      title: {
        label: 'Nimi',
        tooltip: ''
      },
      certified_with: {
        label: 'Sertifikaat',
        tooltip: 'Repositooriumi sertifikaat.'
      },
      pid_system: {
        label: 'PID süsteem',
        tooltip: 'Andmemajutaja toetatud püsiidentifikaatorite süsteemid.'
      },
      support_versioning: {
        label: 'Versioonihaldus',
        tooltip: 'Andmemajutaja võimaldab verisoonihaldust.'
      }
    },
    contributor: {
      name: {
        label: 'Nimi',
        tooltip: ''
      },
      role: {
        label: 'Roll',
        tooltip: ''
      },
      mbox: {
        label: 'E-post',
        tooltip: 'E-posti address.',
        placeholder: 'user@example.com'
      },
      type: {
        label: 'Tüüp',
        tooltip: 'Isiku tüüp.'
      },
      contributorID: {
        label: 'Identifikaator',
        tooltip: 'Isiku identifkaator.'
      },
      contributorIDType: {
        label: 'Identifikaatori tüüp',
        tooltip: 'Isiku identifikaatori tüüp.'
      }
    },
    panels: {
      contributors: 'Isikud',
      costs: 'Kulud',
      dataset: 'Andmesett',
      distribution: 'Distributsioon',
      host: 'Andmemajutus',
      general: 'Üldine',
      project: 'Projekt'
    },
    actions: {
      fillHost: {
        label: 'Täida PlutoF andmetega',
        tooltip: 'Täida automaatselt PlutoF andmetega.'
      }
    }
  };
});