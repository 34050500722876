define("plutof/components/project/view/record-project", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#resolve-promise @record.guardedProject as |project|}}
      <PlutofPanel::Simple
          @title={{i18n-t "General.Project"}}
          @collapse={{and (not project.isPrivate) (not project)}}
          data-test="Record::Project"
      >
          {{#if project.isPrivate}}
              <Common::PrivateRelation />
          {{else}}
              <Project::View::RecordProjectFields
                  @project={{project}}
                  @annotated={{annotation/fields-modified @record 'project'}} />
          {{/if}}
      </PlutofPanel::Simple>
  {{/resolve-promise}}
  
  */
  {
    "id": "9Xa6Zj5L",
    "block": "{\"symbols\":[\"project\",\"@record\"],\"statements\":[[6,[37,5],[[32,2,[\"guardedProject\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"plutof-panel/simple\",[[24,\"data-test\",\"Record::Project\"]],[[\"@title\",\"@collapse\"],[[30,[36,1],[\"General.Project\"],null],[30,[36,3],[[30,[36,2],[[32,1,[\"isPrivate\"]]],null],[30,[36,2],[[32,1]],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,4],[[32,1,[\"isPrivate\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[8,\"common/private-relation\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"            \"],[8,\"project/view/record-project-fields\",[],[[\"@project\",\"@annotated\"],[[32,1],[30,[36,0],[[32,2],\"project\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"annotation/fields-modified\",\"i18n-t\",\"not\",\"and\",\"if\",\"resolve-promise\"]}",
    "meta": {
      "moduleName": "plutof/components/project/view/record-project.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});