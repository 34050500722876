define("plutof/templates/components/agent/organization-view/experiments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Z8UiJ5dT",
    "block": "{\"symbols\":[\"@title\",\"@searchModule\",\"&default\"],\"statements\":[[8,\"related-objects/view\",[],[[\"@pagination\",\"@add\",\"@canModify\",\"@title\",\"@searchModule\",\"@searchQuery\"],[[32,0,[\"pagination\"]],[32,0,[\"newExperiment\"]],true,[32,1],[32,2],[32,0,[\"searchQuery\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[18,3,[[30,[36,0],null,[[\"pagination\"],[[32,0,[\"pagination\"]]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hash\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/agent/organization-view/experiments.hbs"
    }
  });
});