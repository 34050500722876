define("plutof/components/plutof-panel/headerless", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="panel plutof-panel no-margin" ...attributes>
      <div class="panel-body">
          {{yield}}
      </div>
  </div>
  
  */
  {
    "id": "RLT+QLZO",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[24,0,\"panel plutof-panel no-margin\"],[17,1],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"panel-body\"],[12],[2,\"\\n        \"],[18,2,null],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "plutof/components/plutof-panel/headerless.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});