define("plutof/templates/components/datacite/geom-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "bBb9sTHb",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"input-group\"],[12],[2,\"\\n\"],[2,\"    \"],[8,\"input\",[[24,0,\"form-control\"]],[[\"@type\",\"@value\",\"@enter\",\"@placeholder\"],[\"text\",[32,0,[\"source\"]],[30,[36,0],[[32,0],[32,0,[\"setGeometry\"]]],null],[30,[36,1],[\"Publishing.manualLocationPlaceholder\"],null]]],null],[2,\"\\n\\n    \"],[10,\"span\"],[14,0,\"input-group-btn\"],[12],[2,\"\\n        \"],[11,\"button\"],[16,\"disabled\",[32,0,[\"sourceInvalid\"]]],[24,0,\"btn btn-default\"],[4,[38,2],[\"click\",[32,0,[\"setGeometry\"]]],null],[12],[2,\"\\n            \"],[1,[30,[36,1],[\"Publishing.setLocationInput\"],null]],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"i18n-t\",\"on\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/datacite/geom-input.hbs"
    }
  });
});