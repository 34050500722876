define("plutof/templates/analysis/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "2Dou2Xw/",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"route-marker-analysis-edit\"],[12],[13],[2,\"\\n\\n\"],[8,\"navbar/edit\",[],[[\"@icon\",\"@title\",\"@record\",\"@infoContent\"],[[30,[36,0],[\"analysis\"],null],[30,[36,1],[\"Analysis.editAnalysis\"],null],[32,0,[\"model\",\"run\"]],\"information/content/analysis/general\"]],null],[2,\"\\n\\n\"],[1,[30,[36,7],null,[[\"run\",\"type\",\"name\",\"sourceData\",\"validationContext\",\"save\",\"saveAndRun\",\"cancel\"],[[35,5],[35,6],[35,5,[\"name\"]],[35,4],[35,3],[30,[36,2],[[32,0],\"save\"],null],[30,[36,2],[[32,0],\"saveAndRun\"],null],[30,[36,2],[[32,0],\"cancel\"],null]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\",\"action\",\"validationContext\",\"sourceData\",\"run\",\"type\",\"analysis/edit-form\"]}",
    "meta": {
      "moduleName": "plutof/templates/analysis/edit.hbs"
    }
  });
});