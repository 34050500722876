define("plutof/components/tables/controls/row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="table-controls">
      <div class="table-controls__left">
          <Layout::SeparatedRow>
              {{yield to="left"}}
          </Layout::SeparatedRow>
      </div>
  
      <div class="table-controls__right">
          <Layout::SeparatedRow>
              {{yield to="right"}}
          </Layout::SeparatedRow>
      </div>
  </div>
  
  */
  {
    "id": "EI+U7Hpl",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"table-controls\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"table-controls__left\"],[12],[2,\"\\n        \"],[8,\"layout/separated-row\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[18,1,[[30,[36,0],[\"left\"],null]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"table-controls__right\"],[12],[2,\"\\n        \"],[8,\"layout/separated-row\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[18,1,[[30,[36,0],[\"right\"],null]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-named-block-invocation\"]}",
    "meta": {
      "moduleName": "plutof/components/tables/controls/row.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});