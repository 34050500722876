define("plutof/components/access/-view-rights/edit-private", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#access/-view-rights/column}}
      {{plutof-labelc path="Access.canEdit" source="local"}}
  
      <p>
          <span class={{icon "private"}}></span>
          {{i18n-t "Access.private"}}
      </p>
  {{/access/-view-rights/column}}
  
  */
  {
    "id": "lNnOWCMC",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],null,null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],null,[[\"path\",\"source\"],[\"Access.canEdit\",\"local\"]]]],[2,\"\\n\\n    \"],[10,\"p\"],[12],[2,\"\\n        \"],[10,\"span\"],[15,0,[30,[36,1],[\"private\"],null]],[12],[13],[2,\"\\n        \"],[1,[30,[36,2],[\"Access.private\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"plutof-labelc\",\"icon\",\"i18n-t\",\"access/-view-rights/column\"]}",
    "meta": {
      "moduleName": "plutof/components/access/-view-rights/edit-private.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});