define("plutof/templates/components/clipboard/table-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "J+hriS1e",
    "block": "{\"symbols\":[\"@orderableLimit\",\"@used\",\"@available\"],\"statements\":[[10,\"div\"],[14,0,\"table-control__info-text table-control__info-text--soft\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"plutof-tooltip-hover\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"Clipboard.clipboardUsage\"],null]],[2,\": \"],[1,[30,[36,1],[[32,2]],null]],[2,\"/\"],[1,[30,[36,1],[[32,3]],null]],[2,\"\\n\\n        \"],[10,\"span\"],[14,0,\"plutof-tooltip\"],[12],[2,\"\\n            \"],[1,[30,[36,0],[\"Clipboard.clipboardUsageTooltip\"],null]],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,2],[[32,0,[\"showLimit\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"table-control__info-text table-control__info-text--soft\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"Clipboard.orderingDisabled\"],[[\"limit\"],[[32,1]]]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"locale-string\",\"if\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/clipboard/table-info.hbs"
    }
  });
});