define("plutof/components/transaction/view/agent", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <tr class="view-transaction-agent" data-test="Transaction::View::Agent">
      <td data-test="agent">
          <span class="view-transaction-agent__agent">
              {{#link-to this.viewRoute (relation-id @agent "agent")}}
                  {{@agent.agent_name}}
              {{/link-to}}
          </span>
      </td>
  
      <td data-test="role">
          {{@agent.roleDisplayName}}
      </td>
  
      <td data-test="name">
          {{@agent.name}}
      </td>
  </tr>
  
  */
  {
    "id": "kM3zVD8T",
    "block": "{\"symbols\":[\"@agent\"],\"statements\":[[10,\"tr\"],[14,0,\"view-transaction-agent\"],[14,\"data-test\",\"Transaction::View::Agent\"],[12],[2,\"\\n    \"],[10,\"td\"],[14,\"data-test\",\"agent\"],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"view-transaction-agent__agent\"],[12],[2,\"\\n\"],[6,[37,1],null,[[\"route\",\"model\"],[[32,0,[\"viewRoute\"]],[30,[36,0],[[32,1],\"agent\"],null]]],[[\"default\"],[{\"statements\":[[2,\"                \"],[1,[32,1,[\"agent_name\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"td\"],[14,\"data-test\",\"role\"],[12],[2,\"\\n        \"],[1,[32,1,[\"roleDisplayName\"]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"td\"],[14,\"data-test\",\"name\"],[12],[2,\"\\n        \"],[1,[32,1,[\"name\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"relation-id\",\"link-to\"]}",
    "meta": {
      "moduleName": "plutof/components/transaction/view/agent.hbs"
    }
  });
  class ViewTransactionAgent extends _component.default {
    get viewRoute() {
      const type = Ember.get(this.args, 'agent.agent_type');
      if (Ember.isNone(type)) {
        return null;
      }
      switch (type) {
        case 'person':
          return 'person.view';
        case 'organization':
          return 'organization.view';
        case 'collection':
          return 'collection.view';
        default:
          return 'collection.view';
      }
    }
  }
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ViewTransactionAgent);
});