define("plutof/components/auth/identity-provider-buttons/orcid", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  let ORCIDSigninButton = (_dec = (0, _component.classNames)('btn btn-default social-login-button'), _dec(_class = class ORCIDSigninButton extends Ember.Component {
    click() {
      this.clicked();
    }
  }) || _class);
  var _default = _exports.default = ORCIDSigninButton;
});