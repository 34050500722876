define("plutof/controllers/annotations/bulk/edit", ["exports", "plutof/utils/controllers"], function (_exports, _controllers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class BulkAnnotationEditController extends _controllers.EditController {
    async _save() {
      await this.model.annotation.save();
    }
  }
  var _default = _exports.default = BulkAnnotationEditController;
});