define("plutof/components/experiment/pcr/experiment-material", ["exports", "plutof/components/experiment/primers", "plutof/components/experiment/utils", "plutof/utils/i18n"], function (_exports, _primers, _utils, _i18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const i18n = (0, _i18n.getI18n)();
  const MAX_MATERIALS = 96;
  let ExperimentMaterial = (_dec = Ember.inject.service, _dec2 = Ember.computed('products.length', 'disableTable'), _dec3 = Ember.computed('products.length', 'disableTable'), _dec4 = Ember.computed('products.[]'), _dec5 = Ember.computed.filterBy('products', 'hasSource'), _dec6 = Ember.computed('selectableProducts.@each.dna'), _dec7 = Ember.computed('selectableProducts.@each.pcr_product'), _dec8 = Ember._action, _dec9 = Ember._action, (_class = class ExperimentMaterial extends Ember.Component {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _defineProperty(this, "maxMaterials", MAX_MATERIALS);
      _defineProperty(this, "experiment", null);
      _defineProperty(this, "products", []);
      _defineProperty(this, "panelTitle", i18n.t('experiment.panels.material'));
      _initializerDefineProperty(this, "selectableProducts", _descriptor2, this);
    }
    get disableAddStrip() {
      return this.disableTable || this.products.length + 8 > this.maxMaterials;
    }
    get disableFillPlate() {
      return this.disableTable || this.products.length === this.maxMaterials;
    }
    get labware() {
      const products = this.products.sort((a, b) => a.tube_nr - b.tube_nr);
      return products.map((product, index) => {
        return {
          product: product,
          representation: (0, _utils.labwareTubeLabel)(index)
        };
      });
    }
    get noDNAs() {
      return !this.selectableProducts.any(product => Ember.isPresent(product.get('dna.id')));
    }
    get noPCRProducts() {
      return !this.selectableProducts.any(product => Ember.isPresent(product.get('pcr_product.id')));
    }
    fillPlate() {
      this.addLabware(96 - this.products.length);
    }
    extrapolateTags(isSelected) {
      const selection = this.products.filter(isSelected);

      // No need to wait for results, so not bothering with RSVP.all and such
      ['forward_primer_tag', 'reverse_primer_tag'].forEach(async tagField => {
        const tags = await (0, _primers.extrapolateTags)(this.ajax, selection.map(product => product.get(tagField)));
        for (let i = 0; i < tags.length; i++) {
          selection[i].set(tagField, tags[i]);
        }
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "disableAddStrip", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "disableAddStrip"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "disableFillPlate", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "disableFillPlate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "labware", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "labware"), _class.prototype), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "selectableProducts", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "noDNAs", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "noDNAs"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "noPCRProducts", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "noPCRProducts"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fillPlate", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "fillPlate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "extrapolateTags", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "extrapolateTags"), _class.prototype)), _class));
  var _default = _exports.default = ExperimentMaterial;
});