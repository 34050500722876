define("plutof/templates/experiment/sequencing/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ZsEx+2QX",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"route-marker-sequencing-edit\"],[12],[13],[2,\"\\n\\n\"],[8,\"navbar/edit\",[],[[\"@icon\",\"@title\",\"@record\",\"@infoTitle\",\"@infoContent\"],[[30,[36,0],[\"sequencing\"],null],[30,[36,1],[\"experiment.sequencing.routeTitles.edit\"],null],[32,0,[\"model\",\"experiment\"]],[30,[36,1],[\"experiment.general.experiments\"],null],\"information/content/dna-lab/general\"]],null],[2,\"\\n\\n\"],[8,\"experiment/sequencing/edit-form\",[],[[\"@experiment\",\"@materials\",\"@linkedItemsData\",\"@validationContext\",\"@cancel\",\"@save\"],[[32,0,[\"model\",\"experiment\"]],[32,0,[\"model\",\"materials\"]],[32,0,[\"model\",\"linkedItemsData\"]],[32,0,[\"validationContext\"]],[30,[36,2],[[32,0],\"cancel\"],null],[30,[36,2],[[32,0],\"saveExperiment\"],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\",\"action\"]}",
    "meta": {
      "moduleName": "plutof/templates/experiment/sequencing/edit.hbs"
    }
  });
});