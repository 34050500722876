define("plutof/templates/components/navbar/-navbar-base/button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "cWfPTebi",
    "block": "{\"symbols\":[\"@icon\",\"&attrs\",\"&default\"],\"statements\":[[11,\"button\"],[24,0,\"btn btn-link navbar-base__button\"],[16,\"disabled\",[32,0,[\"buttonDisabled\"]]],[17,2],[4,[38,1],[\"click\",[32,0,[\"clicked\"]]],null],[12],[2,\"\\n\"],[6,[37,0],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[15,0,[31,[\"\\n            \",[30,[36,0],[[32,0,[\"buttonResolving\"]],\"promise-button-icon resolving\",[32,1]],null],\"\\n            navbar-base__button-icon\\n        \"]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[18,3,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"on\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/navbar/-navbar-base/button.hbs"
    }
  });
});