define("plutof/components/datacite/view/creators", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::ItemList
      @collapse={{not @creators.length}}
      class="datacite-view-creators"
      as |panel|
  >
      <panel.header
          @title={{i18n-t "datacite.panels.creators"}}
          @count={{@creators.length}} />
  
      <panel.content>
          <Common::RecordListView
              @records={{@creators}}
              data-test="creators"
              as |creator|
          >
              <Datacite::View::Creator @creator={{creator}} />
          </Common::RecordListView>
      </panel.content>
  </PlutofPanel::ItemList>
  
  */
  {
    "id": "4yaipJ2i",
    "block": "{\"symbols\":[\"panel\",\"creator\",\"@creators\"],\"statements\":[[8,\"plutof-panel/item-list\",[[24,0,\"datacite-view-creators\"]],[[\"@collapse\"],[[30,[36,0],[[32,3,[\"length\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"header\"]],[],[[\"@title\",\"@count\"],[[30,[36,1],[\"datacite.panels.creators\"],null],[32,3,[\"length\"]]]],null],[2,\"\\n\\n    \"],[8,[32,1,[\"content\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"common/record-list-view\",[[24,\"data-test\",\"creators\"]],[[\"@records\"],[[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,\"datacite/view/creator\",[],[[\"@creator\"],[[32,2]]],null],[2,\"\\n        \"]],\"parameters\":[2]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"not\",\"i18n-t\"]}",
    "meta": {
      "moduleName": "plutof/components/datacite/view/creators.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});