define("plutof/templates/components/filerepository/image-area/select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "oGME6xVH",
    "block": "{\"symbols\":[\"grid\",\"@transform\",\"@cancel\"],\"statements\":[[6,[37,5],[[32,0,[\"preview\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"layout/regular-grid\",[],[[\"@columns\"],[3]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,0],[[32,1,[\"span\"]],2],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"filerepository/image-area/-selector\",[],[[\"@source\",\"@update\",\"@imageWidth\",\"@imageHeight\"],[[32,0,[\"preview\"]],[32,0,[\"updateArea\"]],[32,0,[\"width\"]],[32,0,[\"height\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n        \"],[10,\"div\"],[12],[2,\"\\n            \"],[10,\"p\"],[12],[2,\"\\n                \"],[1,[30,[36,1],[\"FileRepository.areaSelectDescription\"],null]],[2,\"\\n            \"],[13],[2,\"\\n\\n            \"],[11,\"button\"],[24,0,\"btn btn-default control-attention\"],[4,[38,3],[\"click\",[30,[36,2],[[32,2],[32,0,[\"area\"]]],null]],null],[12],[2,\"\\n                \"],[10,\"span\"],[15,0,[30,[36,4],[\"ok\"],null]],[12],[13],[2,\"\\n                \"],[1,[30,[36,1],[\"General.Save\"],null]],[2,\"\\n            \"],[13],[2,\"\\n\\n\"],[2,\"            \"],[11,\"button\"],[24,0,\"btn btn-default\"],[4,[38,3],[\"click\",[32,3]],null],[12],[2,\"\\n                \"],[10,\"span\"],[14,0,\"icon-remove--black\"],[12],[13],[2,\"\\n                \"],[1,[30,[36,1],[\"General.Cancel\"],null]],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"component\",\"i18n-t\",\"fn\",\"on\",\"icon\",\"if\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/filerepository/image-area/select.hbs"
    }
  });
});