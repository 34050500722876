define("plutof/templates/sequence/annotation/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "NwiMDiLU",
    "block": "{\"symbols\":[],\"statements\":[[8,\"navbar/edit\",[],[[\"@kind\",\"@icon\",\"@title\",\"@infoContent\"],[\"annotation\",[30,[36,0],[\"sequence\"],null],[30,[36,1],[\"Sequences.annotation.add\"],null],\"information/sequence/edit\"]],null],[2,\"\\n\\n\"],[8,\"sequence/edit\",[],[[\"@data\",\"@validationContext\",\"@showSourceSelector\"],[[32,0,[\"model\",\"sequenceData\"]],[32,0,[\"validationContext\"]],true]],null],[2,\"\\n\\n\"],[8,\"sticky-bottom-wrapper\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"invalid-panels\",[],[[\"@validationContext\"],[[32,0,[\"validationContext\"]]]],null],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"action-buttons\"],[12],[2,\"\\n        \"],[8,\"annotation/annotate-button\",[],[[\"@annotate\",\"@disabled\"],[[32,0,[\"annotate\"]],[32,0,[\"validationContext\",\"isInvalid\"]]]],null],[2,\"\\n\\n\"],[6,[37,2],[[32,0,[\"enableElixirAnnotations\"]]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"annotation/elixir/annotate-button\",[],[[\"@record\",\"@extract\",\"@send\",\"@disabled\"],[[32,0,[\"model\",\"sequenceData\",\"sequence\"]],[32,0,[\"extractElixirCurations\"]],[32,0,[\"sendElixirAnnotation\"]],[32,0,[\"validationContext\",\"isInvalid\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n        \"],[8,\"common/cancel-button\",[],[[\"@cancel\"],[[30,[36,3],[\"goBack\"],null]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\",\"if\",\"route-action\"]}",
    "meta": {
      "moduleName": "plutof/templates/sequence/annotation/add.hbs"
    }
  });
});