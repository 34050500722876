define("plutof/templates/components/common/local-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Q3TPmpxP",
    "block": "{\"symbols\":[\"t\",\"@infoLink\",\"@label\"],\"statements\":[[6,[37,5],null,null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"plutof-label\"],[12],[2,\"\\n        \"],[1,[30,[36,1],[[32,3]],null]],[2,\"\\n    \"],[13],[2,\"\\n\\n\"],[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[15,1,[31,[[32,1,[\"tid\"]]]]],[14,0,\"wrapper-tooltip\"],[12],[2,\"\\n            \"],[1,[30,[36,1],[[32,0,[\"actualTooltip\"]]],null]],[2,\"\\n\\n\"],[6,[37,2],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[1,[30,[36,0],null,[[\"infoLink\"],[[32,2]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"info-link\",\"i18n-t\",\"if\",\"disableTooltip\",\"unless\",\"tooltip-wrapper\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/common/local-label.hbs"
    }
  });
});