define("plutof/translations/eng/glossary-collection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    title: 'Keyword Collections',
    searchTitle: 'Search Keyword Collections',
    infoTitle: 'Keyword Collections',
    glossary: 'Keyword',
    newCollection: 'New Keyword Collection',
    editCollection: 'Edit Keyword Collection',
    keywordCollection: 'Keyword Collection'
  };
});