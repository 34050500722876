define("plutof/components/reference/edit", ["exports", "plutof/mixins/component-validations"], function (_exports, _componentValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Reference::GeneralData
      @reference={{@reference}}
      @authors={{@authors}}
      @validationContext={{@validationContext.reference}} />
  
  <Reference::Authors
      @reference={{@reference}}
      @authors={{@authors}}
      @objectsToDelete={{@objectsToDelete}}
      @validationContext={{@validationContext.authors}} />
  
  <LinkedItems::Edit
      @data={{@itemData}}
      @validationContext={{@validationContext.associatedData}} />
  
  <Access::EditRights
      @rights={{@accessRights}}
      @validationContext={{@validationContext.access}} />
  
  <StickyBottomWrapper>
      <InvalidPanels @validationContext={{@validationContext}} />
  
      <div class="action-buttons">
          <Common::SaveButton @save={{@save}} @disabled={{@validationContext.isInvalid}} />
          <Common::CancelButton @cancel={{route-action "goBack"}} />
      </div>
  </StickyBottomWrapper>
  
  */
  {
    "id": "yLmr3hb1",
    "block": "{\"symbols\":[\"@reference\",\"@authors\",\"@validationContext\",\"@objectsToDelete\",\"@itemData\",\"@accessRights\",\"@save\"],\"statements\":[[8,\"reference/general-data\",[],[[\"@reference\",\"@authors\",\"@validationContext\"],[[32,1],[32,2],[32,3,[\"reference\"]]]],null],[2,\"\\n\\n\"],[8,\"reference/authors\",[],[[\"@reference\",\"@authors\",\"@objectsToDelete\",\"@validationContext\"],[[32,1],[32,2],[32,4],[32,3,[\"authors\"]]]],null],[2,\"\\n\\n\"],[8,\"linked-items/edit\",[],[[\"@data\",\"@validationContext\"],[[32,5],[32,3,[\"associatedData\"]]]],null],[2,\"\\n\\n\"],[8,\"access/edit-rights\",[],[[\"@rights\",\"@validationContext\"],[[32,6],[32,3,[\"access\"]]]],null],[2,\"\\n\\n\"],[8,\"sticky-bottom-wrapper\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"invalid-panels\",[],[[\"@validationContext\"],[[32,3]]],null],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"action-buttons\"],[12],[2,\"\\n        \"],[8,\"common/save-button\",[],[[\"@save\",\"@disabled\"],[[32,7],[32,3,[\"isInvalid\"]]]],null],[2,\"\\n        \"],[8,\"common/cancel-button\",[],[[\"@cancel\"],[[30,[36,0],[\"goBack\"],null]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"route-action\"]}",
    "meta": {
      "moduleName": "plutof/components/reference/edit.hbs"
    }
  });
  class ReferenceEdit extends Ember.Component.extend(_componentValidations.default) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "validationChildren", [{
        name: 'reference',
        label: 'References.reference'
      }, {
        name: 'authors',
        label: 'References.authors'
      }, {
        name: 'associatedData',
        label: 'General.associatedData'
      }, {
        name: 'access',
        label: 'General.access'
      }]);
    }
  }
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ReferenceEdit);
});