define("plutof/translations/eng/reference-based", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    refBasedTaxonOc: 'Reference Based Taxon Occurrence',
    bioStatus: 'Bio status',
    newRefBased: 'New Reference Based Taxon Occurrence',
    editRefBased: 'Edit Reference Based Taxon Occurrence',
    title: 'Reference Based Taxon Occurrences',
    searchTitle: 'Search Reference Based Taxon Occurrences',
    infoTitle: 'Reference Based Taxon Occurrences',
    referenceB: 'Reference Based',
    nameTitle: 'Taxon name',
    nameTitleLowerC: 'taxon name',
    addPanel: {
      title: 'Occurrences'
    },
    placeholders: {
      searchHint: 'Search by taxon'
    }
  };
});