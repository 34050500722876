define("plutof/templates/photobank/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "zfXIzL+w",
    "block": "{\"symbols\":[],\"statements\":[[8,\"test/route-marker\",[],[[\"@route\"],[\"photobank.add\"]],null],[2,\"\\n\\n\"],[8,\"navbar/edit\",[],[[\"@icon\",\"@title\",\"@record\",\"@reset\"],[[30,[36,0],[\"photobank\"],null],[30,[36,1],[\"Photobank.newObject\"],null],[32,0,[\"model\",\"object\"]],[30,[36,2],[\"triggerReset\"],null]]],null],[2,\"\\n\\n\"],[8,\"photobank/edit\",[],[[\"@object\",\"@additionalIdentifiers\",\"@objectsToDelete\",\"@observationMainform\",\"@eventFormData\",\"@showAreaEditButton\",\"@showObservationForm\",\"@preparationsData\",\"@classificationItems\",\"@observationTableStrings\",\"@itemData\",\"@agentItems\",\"@areaEventViewStrings\",\"@closePanels\",\"@allowAreaReset\",\"@observationData\",\"@cloningRules\",\"@validationContext\"],[[32,0,[\"model\",\"object\"]],[32,0,[\"model\",\"additionalIdentifiers\"]],[32,0,[\"objectsToDelete\"]],[32,0,[\"model\",\"observationMainform\"]],[32,0,[\"model\",\"eventFormData\"]],[32,0,[\"showAreaEditButton\"]],true,[32,0,[\"model\",\"preparationsData\"]],[32,0,[\"model\",\"classificationItems\"]],[32,0,[\"observationTableStrings\"]],[32,0,[\"model\",\"itemData\"]],[32,0,[\"model\",\"agentItems\"]],[32,0,[\"areaEventViewStrings\"]],[32,0,[\"closePanels\"]],[32,0,[\"allowAreaReset\"]],[32,0,[\"model\",\"observationData\"]],[32,0,[\"cloningRules\"]],[32,0,[\"validationContext\"]]]],null],[2,\"\\n\\n\"],[8,\"sticky-bottom-wrapper\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"invalid-panels\",[],[[\"@validationContext\"],[[32,0,[\"validationContext\"]]]],null],[2,\"\\n\\n    \"],[8,\"save-panel\",[],[[\"@save\",\"@cancel\",\"@clone\",\"@ctrl\"],[[32,0,[\"save\"]],[30,[36,3],[[32,0],\"cancel\"],null],[30,[36,3],[[32,0],\"clone\"],null],[32,0]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\",\"route-action\",\"action\"]}",
    "meta": {
      "moduleName": "plutof/templates/photobank/add.hbs"
    }
  });
});