define("plutof/components/gallery/footer/-right-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <span
      class="
          gallery-footer-right__item
          {{if @small 'gallery-footer-right__item--small'}}
      "
  >
      {{yield}}
  </span>
  
  */
  {
    "id": "ln88rmY6",
    "block": "{\"symbols\":[\"@small\",\"&default\"],\"statements\":[[10,\"span\"],[15,0,[31,[\"\\n        gallery-footer-right__item\\n        \",[30,[36,0],[[32,1],\"gallery-footer-right__item--small\"],null],\"\\n    \"]]],[12],[2,\"\\n    \"],[18,2,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "plutof/components/gallery/footer/-right-item.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});