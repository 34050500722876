define("plutof/utils/cookies", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getCookie = getCookie;
  _exports.removeCookie = removeCookie;
  _exports.setCookie = setCookie;
  function getCookie(cname) {
    const name = cname + '=';
    const match = document.cookie.split(';').map(part => part.trim()).find(part => part.startsWith(name));
    if (!match) {
      return null;
    }
    return match.substring(name.length);
  }
  function setCookie(cname, value) {
    document.cookie = `${cname}=${value};path=/`;
  }
  function removeCookie(cname) {
    document.cookie = cname + '=; expires=Fri, 31 Dec 1999 23:59:59 GMT;';
  }
});