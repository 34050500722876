define("plutof/components/layout/module-hub", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="hub-content">
      <Common::Legend>
          <span class={{@icon}}></span>
          {{@title}}
      </Common::Legend>
  
      {{yield (hash
          link=(component "layout/-module-hub/link")
          button=(component "layout/-module-hub/link-button")
      )}}
  </div>
  
  */
  {
    "id": "MGtA81lG",
    "block": "{\"symbols\":[\"@icon\",\"@title\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"hub-content\"],[12],[2,\"\\n    \"],[8,\"common/legend\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"span\"],[15,0,[32,1]],[12],[13],[2,\"\\n        \"],[1,[32,2]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[18,3,[[30,[36,1],null,[[\"link\",\"button\"],[[30,[36,0],[\"layout/-module-hub/link\"],null],[30,[36,0],[\"layout/-module-hub/link-button\"],null]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "plutof/components/layout/module-hub.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});