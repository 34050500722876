define("plutof/translations/eng/annotation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    annotate: 'Annotate',
    noChangesMade: 'No changes made',
    unreviewedAnnotations: 'Annotation proposal waiting for review',
    annotationSent: 'Annotation proposal is sent to record rights holder. You will get notification when proposal is reviewed.',
    selfAnnotationCreated: 'Record is annotated and changes are applied',
    annotation: 'Annotation',
    annotations: 'Annotations',
    routes: {
      created: {
        title: 'My annotations',
        description: 'Annotations you have created',
        buttonTitle: 'My annotations ({{rejected}} rejected)'
      },
      moderated: {
        title: 'Pending annotations',
        description: 'Other users\' annotations waiting for your approval',
        buttonTitle: 'Pending annotations ({{count}})'
      },
      bulk: {
        index: 'Bulk annotations',
        add: {
          title: 'New bulk annotation',
          records: 'Records being annotated'
        },
        edit: {
          title: 'Edit bulk annotation'
        },
        view: {
          title: 'View bulk annotation',
          summary: {
            model: 'Model',
            attribute: 'Attribute',
            change: 'Change'
          }
        },
        errors: {
          recordLimit: 'Currently, can only annotate at most {{limit}} records at once',
          noRecords: 'Cannot make a bulk annotation over zero records'
        }
      },
      search: {
        includeTaxonSubtree: 'Descendants and synonyms',
        contentType: 'Record type',
        changedFields: 'Annotated fields',
        recordProperties: 'Record',
        topResults: {
          author: 'Top authors',
          changedField: 'Top annotated fields'
        }
      }
    },
    commentDialog: {
      title: 'Add annotation comment',
      placeholder: 'Please write small comment about the proposed annotation. It helps to distinguish annotations from each other. It is useful for history.'
    },
    moderation: {
      dialog: {
        title: 'Add moderation comment',
        placeholder: 'Please write small "Thank you" or comment about the reviewed annotation. This will give some feedback to annotation proposer.'
      }
    },
    table: {
      title: 'Annotation proposals'
    },
    summary: {
      title: 'Annotation summary',
      annotatorStatus: '<b>Annotated</b> by {{annotator}} at {{date}} with comment:',
      status: {
        accepted: '<b>Accepted</b> by {{moderator}} at {{date}} with comment:',
        rejected: '<b>Rejected</b> by {{moderator}} at {{date}} with comment:',
        moderated: '<b>Previously rejected by</b> {{moderator}} at {{date}} with comment:'
      }
    },
    log: {
      title: 'Annotation log',
      addComment: 'Add comment',
      fields: {
        kind: 'Event',
        author: 'Author',
        comment: 'Comment',
        timestamp: 'Date'
      }
    },
    fields: {
      status: 'Status',
      author: 'Author',
      moderator: 'Moderator',
      comment: 'Comment',
      annotated_at: 'Date'
    },
    errors: {
      missingRelationPermission: 'Annotation links to a record {{record}} you have no permission for'
    },
    elixir: {
      annotate: 'Annotate to ENA',
      previewTitle: 'Annotation summary for {{record}}',
      changes: {
        all: 'All annotations',
        elixir: 'Annotations reported to ENA',
        none: 'No ENA-compatible annotations'
      },
      fields: {
        field: 'Field',
        value: 'Value',
        assertion: 'Assertion',
        assertionSource: 'Assertion source',
        comment: 'Comment'
      },
      preview: {
        useReferenceAsAssertionSource: 'Use PlutoF reference'
      },
      errors: {
        taxonResolutionFailed: 'Failed to find the taxon in ENA taxonomy'
      }
    }
  };
});