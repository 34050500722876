define("plutof/templates/annotations/bulk/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "SkLo4khg",
    "block": "{\"symbols\":[],\"statements\":[[8,\"navbar/edit\",[],[[\"@kind\",\"@icon\",\"@title\"],[\"annotation\",[30,[36,0],[\"annotation\"],null],[30,[36,1],[\"annotation.routes.bulk.edit.title\"],null]]],null],[2,\"\\n\\n\"],[8,\"annotation/bulk/sections/sequence\",[],[[\"@fields\"],[[32,0,[\"model\",\"annotation\",\"fields\"]]]],null],[2,\"\\n\\n\"],[8,\"sticky-bottom-wrapper\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"action-buttons\"],[12],[2,\"\\n        \"],[8,\"annotation/bulk/annotate-button\",[],[[\"@annotation\",\"@annotate\"],[[32,0,[\"model\",\"annotation\"]],[32,0,[\"save\"]]]],null],[2,\"\\n\\n        \"],[8,\"common/cancel-button\",[],[[\"@cancel\"],[[30,[36,2],[[32,0],\"cancel\"],null]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\",\"action\"]}",
    "meta": {
      "moduleName": "plutof/templates/annotations/bulk/edit.hbs"
    }
  });
});