define("plutof/routes/specimen/annotation/add", ["exports", "plutof/routes/specimen/edit", "plutof/utils/annotation/routes"], function (_exports, _edit, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _routes.annotationAddRoute)(_edit.default, {
    parentRoute: 'specimen'
  });
});