define("plutof/controllers/profile/rights", ["exports", "plutof/mixins/component-validations", "plutof/utils/access", "plutof/utils/i18n", "plutof/utils/notifications"], function (_exports, _componentValidations, _access, _i18n, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const i18n = (0, _i18n.getI18n)();
  let ProfileRightsController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, (_class = class ProfileRightsController extends Ember.Controller.extend(_componentValidations.default) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "settings", _descriptor2, this);
      _defineProperty(this, "validationChildren", [{
        name: 'access',
        label: 'General.access'
      }]);
    }
    async save() {
      (0, _notifications.clearNotifications)();
      (0, _notifications.displayNotification)('status', i18n.t('General.saving'));
      const hash = this.model.profileRights.makeAccessHash();
      this.settings.profile.setProperties({
        'access_view': hash['access_view'],
        'access_edit': hash['access_edit'],
        'default_users_view': hash['user_view'],
        'default_users_edit': hash['user_edit'],
        'default_groups_view': hash['group_view'],
        'default_groups_edit': hash['group_edit']
      });
      try {
        await this.settings.profile.save();
        (0, _notifications.displayNotification)('success', i18n.t('General.done'));
        const profile = await this.store.queryRecord('profile/personalsettings/personalsetting', {
          self: true
        });
        (0, _access.init_default_rights)((0, _access.get_permissions)(this.store, this.ajax, profile));
        this.settings.set('profile', profile);
      } catch (err) {
        (0, _notifications.reportError)(err);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "settings", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "save", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class));
  var _default = _exports.default = ProfileRightsController;
});