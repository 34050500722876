define("plutof/utils/publishing-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.METADATA_LANGUAGES = void 0;
  const METADATA_LANGUAGES = _exports.METADATA_LANGUAGES = [{
    value: 'en',
    display_name: 'English'
  }];
});