define("plutof/components/annotation/-fields/annotatedAt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <@field @label={{i18n-t "annotation.fields.annotated_at"}}>
      {{formatted-date @annotation.annotated_at}}
  </@field>
  
  */
  {
    "id": "ethYzz4e",
    "block": "{\"symbols\":[\"@field\",\"@annotation\"],\"statements\":[[8,[32,1],[],[[\"@label\"],[[30,[36,0],[\"annotation.fields.annotated_at\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,1],[[32,2,[\"annotated_at\"]]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"formatted-date\"]}",
    "meta": {
      "moduleName": "plutof/components/annotation/-fields/annotatedAt.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});