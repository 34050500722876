define("plutof/templates/condition-assessment/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "p1p1eYgx",
    "block": "{\"symbols\":[],\"statements\":[[8,\"test/route-marker\",[],[[\"@route\"],[\"condition-assessment.edit\"]],null],[2,\"\\n\\n\"],[8,\"navbar/edit\",[],[[\"@icon\",\"@title\",\"@record\"],[[30,[36,0],[\"condition-assessment\"],null],[30,[36,1],[\"CollectionManagement.editCA\"],null],[32,0,[\"model\",\"assessmentData\"]]]],null],[2,\"\\n\\n\"],[8,\"collection-lab/condition-assessment/edit\",[],[[\"@model\",\"@validationContext\"],[[32,0,[\"model\",\"assessment\"]],[32,0,[\"validationContext\"]]]],null],[2,\"\\n\\n\"],[8,\"sticky-bottom-wrapper\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"invalid-panels\",[],[[\"@validationContext\"],[[32,0,[\"validationContext\"]]]],null],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"action-buttons\"],[12],[2,\"\\n        \"],[8,\"common/save-button\",[],[[\"@save\",\"@disabled\"],[[32,0,[\"save\"]],[32,0,[\"validationContext\",\"isInvalid\"]]]],null],[2,\"\\n\\n        \"],[8,\"common/cancel-button\",[],[[\"@cancel\"],[[30,[36,2],[[32,0],\"cancel\"],null]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\",\"action\"]}",
    "meta": {
      "moduleName": "plutof/templates/condition-assessment/edit.hbs"
    }
  });
});