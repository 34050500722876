define("plutof/models/dna-lab/common", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hasSource = hasSource;
  function hasSource(...possibleSources) {
    const idPaths = possibleSources.map(source => `${source}.id`);
    return Ember.computed(...idPaths, function () {
      return idPaths.some(path => Ember.isPresent(this.get(path)));
    });
  }
});