define("plutof/controllers/reference/edit", ["exports", "plutof/mixins/edit-controller", "plutof/utils/notifications", "plutof/utils/promises"], function (_exports, _editController, _notifications, _promises) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let ReferenceEditController = (_dec = Ember._action, (_class = class ReferenceEditController extends Ember.Controller.extend(_editController.default) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "recordsToUnload", [{
        record: 'reference'
      }]);
    }
    async _save() {
      const {
        accessRights,
        itemData,
        authors,
        reference
      } = this.model;
      await reference.save();
      await Ember.RSVP.all([accessRights.save(), itemData.save(reference, accessRights), _promises.default.saveSequentially(authors.filterBy('hasDirtyAttributes')), Ember.RSVP.all(this.objectsToDelete.invoke('destroyRecord')).then(() => this.objectsToDelete.clear())]);
    }
    async save() {
      await (0, _notifications.notifyProcess)(this._save(), this.i18n);
      this.set('routeHasBeenLoaded', false);
      this.send('goBack');
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "save", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class));
  var _default = _exports.default = ReferenceEditController;
});