define("plutof/templates/components/sample/navbar-buttons/detach", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "P+HZulTT",
    "block": "{\"symbols\":[\"@navbar\"],\"statements\":[[6,[37,2],[[32,0,[\"ownedByCurrentUser\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"tool-tip\",[],[[\"@tooltip\"],[[30,[36,0],[\"Sample.detach.tooltip\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,1,[\"button\"]],[],[[\"@icon\",\"@clicked\"],[[30,[36,1],[\"detach\"],null],[32,0,[\"detach\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,0],[\"Sample.detach.label\"],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"icon\",\"if\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/sample/navbar-buttons/detach.hbs"
    }
  });
});