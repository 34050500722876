define("plutof/translations/eng/profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    infoTitle: 'Profile Settings',
    defaultLicence: 'Default licence',
    defaultRightsTooltip: 'To be used when inserting data on someone elses name. <br>' + 'Selected name will be the "Rights holder" of this data. No need to add yourself.',
    CreativeCommons: 'Creative Commons licences',
    personalMenu: {
      measurements: {
        selectGuide: 'Select measurements to add to the form',
        selection: 'Currently selected:',
        noResults: 'No matching measurements'
      }
    }
  };
});