define("plutof/components/search/results-statistics", ["exports", "ember-data", "plutof/misc/abstract", "plutof/utils/chart", "plutof/utils/i18n", "plutof/utils/notifications", "plutof/utils/search"], function (_exports, _emberData, _abstract, _chart, _i18n, _notifications, _search) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const i18n = (0, _i18n.getI18n)();
  const INCLUDE_CLUSTER_LIST = ['taxonoccurrence'];
  let ResultsStatistics = (_dec = Ember.inject.service, _dec2 = Ember.computed('module.value'), _dec3 = Ember.computed.and('histogramData.isFulfilled', 'topResultsData.isFulfilled', 'phenologicalData.isFulfilled', 'cumulativeData.isFulfilled', 'districtData.isFulfilled'), _dec4 = Ember.computed('module', 'query'), _dec5 = Ember.computed('module', 'query'), _dec6 = Ember.computed('module', 'query'), _dec7 = Ember.computed('module', 'query'), _dec8 = Ember.computed('module', 'query'), _dec9 = Ember.computed('histogramData'), _dec10 = Ember.computed('histogramData'), _dec11 = Ember.computed('topResultsData'), _dec12 = Ember.computed('phenologicalData'), _dec13 = Ember.computed('cumulativeData'), _dec14 = Ember.computed('districtData'), (_class = class ResultsStatistics extends Ember.Component {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _defineProperty(this, "query", null);
      _defineProperty(this, "module", null);
      _defineProperty(this, "clusterFilter", 'sub_field=module');
      _defineProperty(this, "phenoFilter", 'field=timespan_begin');
      _defineProperty(this, "phenoGraphType", 'taxon');
      _initializerDefineProperty(this, "isLoaded", _descriptor2, this);
    }
    get needsClusteredList() {
      return INCLUDE_CLUSTER_LIST.includes(this.get('module.value'));
    }
    get histogramData() {
      const query = this.query;
      const module = this.get('module.value');
      const fields = this.get('module.chartFields');
      const cFilter = this.needsClusteredList ? this.clusterFilter : null;
      const endpoint = module + _chart.HISTOGRAM_ENDPOINT;
      const queryP = Ember.isEmpty(fields) ? _abstract.EMPTY_PROMISE : (0, _chart.getChartData)(this.ajax, endpoint, fields, query, cFilter);
      return _emberData.default.PromiseObject.create({
        promise: queryP
      });
    }
    get topResultsData() {
      const query = this.query;
      const module = this.get('module.value');
      const fields = this.get('module.topResults').mapBy('id');
      const endpoint = module + _search.TOP_RESULTS_ENDPOINT;
      const queryP = Ember.isEmpty(fields) ? _abstract.EMPTY_PROMISE : (0, _chart.getChartData)(this.ajax, endpoint, fields, query);
      return _emberData.default.PromiseObject.create({
        promise: queryP
      });
    }
    get phenologicalData() {
      const query = this.query;
      const module = this.get('module.value');
      const fields = this.get('module.topResults');
      const pFilter = this.phenoFilter;
      const endpoint = module + _chart.PHENOLOGICAL_ENDPOINT;
      const queryP = Ember.isEmpty(fields) ? _abstract.EMPTY_PROMISE : (0, _chart.getChartData)(this.ajax, endpoint, fields, query, pFilter);
      return _emberData.default.PromiseObject.create({
        promise: queryP
      });
    }
    get cumulativeData() {
      const query = this.query;
      const module = this.get('module.value');
      const fields = ['timespan_begin'];
      const endpoint = module + _chart.CUMULATIVE_ENDPOINT;
      const queryP = Ember.isEmpty(fields) ? _abstract.EMPTY_PROMISE : (0, _chart.getChartData)(this.ajax, endpoint, fields, query);
      return _emberData.default.PromiseObject.create({
        promise: queryP
      });
    }
    get districtData() {
      const query = this.query;
      const module = this.get('module.value');
      const fields = ['district']; // XXX
      const endpoint = module + _chart.GEO_DISTRIBUTION_ENDPOINT;
      const cFilter = this.clusterFilter;
      return _emberData.default.PromiseObject.create({
        promise: (0, _chart.getChartData)(this.ajax, endpoint, fields, query, cFilter)
      });
    }
    get createdAtConfig() {
      return _emberData.default.PromiseObject.create({
        promise: this.histogramData.then(data => {
          return {
            context: this,
            data: data,
            field: {
              key: 'created_at',
              label: i18n.t('chart.createdAt')
            },
            type: this.needsClusteredList ? 'clustered' : 'regular'
          };
        }).catch(_notifications.reportError)
      });
    }
    get timespanBeginConfig() {
      return _emberData.default.PromiseObject.create({
        promise: this.histogramData.then(data => {
          return {
            context: this,
            data: data,
            field: {
              key: 'timespan_begin',
              label: i18n.t('chart.timespanBegin')
            },
            type: this.needsClusteredList ? 'clustered' : 'regular'
          };
        }).catch(_notifications.reportError)
      });
    }
    get taxonNodeConfig() {
      return _emberData.default.PromiseObject.create({
        promise: this.topResultsData.then(data => {
          return {
            context: this,
            data: data,
            field: {
              key: 'taxon_node',
              label: i18n.t('chart.taxonNode')
            }
          };
        }).catch(_notifications.reportError)
      });
    }
    get phenologicalConfig() {
      return _emberData.default.PromiseObject.create({
        promise: this.phenologicalData.then(data => {
          return {
            context: this,
            data: data,
            field: {
              key: 'taxon_node',
              label: i18n.t('chart.phenological')
            }
          };
        }).catch(_notifications.reportError)
      });
    }
    get cumulativeConfig() {
      return _emberData.default.PromiseObject.create({
        promise: this.cumulativeData.then(data => {
          return {
            context: this,
            data: data,
            config: {
              properties: ['unique_taxa', 'total_taxa'],
              translations: [i18n.t('chart.occurrenceInYear'), i18n.t('chart.cumulativeCount')],
              key: 'timespan_begin',
              label: i18n.t('chart.cumulative')
            }
          };
        }).catch(_notifications.reportError)
      });
    }
    get districtConfig() {
      return _emberData.default.PromiseObject.create({
        promise: this.districtData.then(data => {
          // Fail request handling.
          if (Ember.isEmpty(data)) {
            return {};
          }

          // Modify data before displaying histogram.
          data.district.data.forEach(el => {
            if (el.key.length > 18) {
              el.key = el.key.substring(0, 18);
              el.key += '..';
            }
          });
          return {
            context: this,
            data: data,
            field: {
              key: 'district',
              label: i18n.t('chart.district')
            },
            type: 'stacked'
          };
        }).catch(_notifications.reportError)
      });
    }
    handleClick(event, items, interval, field) {
      const chartItem = Ember.get(items, 'firstObject');
      if (!Ember.isNone(chartItem)) {
        this.addTimeFilter(field, Ember.get(chartItem, '_model.label'));
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "needsClusteredList", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "needsClusteredList"), _class.prototype), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isLoaded", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "histogramData", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "histogramData"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "topResultsData", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "topResultsData"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "phenologicalData", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "phenologicalData"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cumulativeData", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "cumulativeData"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "districtData", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "districtData"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createdAtConfig", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "createdAtConfig"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "timespanBeginConfig", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "timespanBeginConfig"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "taxonNodeConfig", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "taxonNodeConfig"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "phenologicalConfig", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "phenologicalConfig"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cumulativeConfig", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "cumulativeConfig"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "districtConfig", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "districtConfig"), _class.prototype)), _class));
  var _default = _exports.default = ResultsStatistics;
});