define("plutof/templates/components/clipboard/navbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "G4TglM79",
    "block": "{\"symbols\":[\"navbar\",\"slots\",\"@export\",\"@icon\",\"@title\",\"@infoContent\"],\"statements\":[[8,\"navbar/navbar-base\",[],[[\"@kind\"],[\"util\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,1,[\"header\"]],[],[[\"@icon\",\"@title\"],[[32,4],[32,5]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,2,[\"primaryControls\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,1,[\"info\"]],[],[[\"@title\",\"@content\"],[[32,5],[32,6]]],null],[2,\"\\n\\n        \"],[8,[32,1,[\"bookmark\"]],[],[[],[]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,2,[\"secondaryControls\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[32,3,[\"canReset\"]]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,[32,1,[\"button\"]],[],[[\"@clicked\",\"@icon\"],[[32,3,[\"reset\"]],[30,[36,0],[\"reset\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[1,[30,[36,1],[\"General.Reset\"],null]],[2,\"\\n            \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n        \"],[8,[32,1,[\"goBack\"]],[],[[],[]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\",\"if\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/clipboard/navbar.hbs"
    }
  });
});