define("plutof/components/tables/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="table-responsive" ...attributes>
      <table class="plutof-table table-hover">
          <thead>
              <tr class={{@headersClasses}}>
                  {{yield (component "tables/-list/field-header")}}
              </tr>
          </thead>
  
          <tbody>
              {{#each @records as |record|}}
                  <tr class={{@rowClasses}}>
                      {{yield (component "tables/-list/field") record}}
                  </tr>
              {{/each}}
          </tbody>
      </table>
  </div>
  
  */
  {
    "id": "lfieloFr",
    "block": "{\"symbols\":[\"record\",\"@rowClasses\",\"&default\",\"&attrs\",\"@headersClasses\",\"@records\"],\"statements\":[[11,\"div\"],[24,0,\"table-responsive\"],[17,4],[12],[2,\"\\n    \"],[10,\"table\"],[14,0,\"plutof-table table-hover\"],[12],[2,\"\\n        \"],[10,\"thead\"],[12],[2,\"\\n            \"],[10,\"tr\"],[15,0,[32,5]],[12],[2,\"\\n                \"],[18,3,[[30,[36,0],[\"tables/-list/field-header\"],null]]],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,6]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[10,\"tr\"],[15,0,[32,2]],[12],[2,\"\\n                    \"],[18,3,[[30,[36,0],[\"tables/-list/field\"],null],[32,1]]],[2,\"\\n                \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "plutof/components/tables/list.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});