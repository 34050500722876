define("plutof/components/experiment/sequencing/experiment-metadata", ["exports", "ember-data", "ember-cp-validations", "plutof/misc/options-getter", "plutof/models/agent/organization", "plutof/mixins/component-validations"], function (_exports, _emberData, _emberCpValidations, _optionsGetter, _organization, _componentValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const Validations = (0, _emberCpValidations.buildValidations)({
    'experiment.title': (0, _emberCpValidations.validator)('presence', true),
    'experiment.prepared_by': (0, _emberCpValidations.validator)('presence', true),
    'experiment.sequenced_by': (0, _emberCpValidations.validator)('presence', true),
    'experiment.laboratory': (0, _emberCpValidations.validator)('presence', true),
    'experiment.labware': (0, _emberCpValidations.validator)('presence', true),
    'experiment.platform': (0, _emberCpValidations.validator)('presence', true),
    'startDateValidity': (0, _emberCpValidations.validator)('is-truthy'),
    'endDateValidity': (0, _emberCpValidations.validator)('is-truthy')
  });
  let ExperimentMetadataComponent = (_dec = Ember.inject.service, _dec2 = Ember.computed('experiment.isNew', 'experiment.platform'), _dec3 = Ember.computed(), _dec4 = Ember.computed(), _dec5 = Ember.computed(), _dec6 = Ember.computed(), _dec7 = Ember.computed(), _dec8 = Ember._action, (_class = class ExperimentMetadataComponent extends Ember.Component.extend(Validations, _componentValidations.default) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _defineProperty(this, "startDateValidity", false);
      _defineProperty(this, "endDateValidity", false);
      _defineProperty(this, "laboratoryFilters", {
        organization_type: _organization.OrganizationType.Laboratory
      });
    }
    getChoices(name) {
      const path = `data.actions.POST.${name}`;
      return _emberData.default.PromiseObject.create({
        promise: (0, _optionsGetter.getPromise)(this.ajax, '/dna-lab/sequencings/').then(response => Ember.get(response, path))
      });
    }
    get disablePlatform() {
      return !this.experiment.isNew && Ember.isPresent(this.experiment.platform);
    }
    get platforms() {
      return this.getChoices('platform');
    }
    get libraryStrategies() {
      return this.getChoices('library_strategy');
    }
    get librarySources() {
      return this.getChoices('library_source');
    }
    get libraryLayouts() {
      return this.getChoices('library_layout');
    }
    get labwareTypes() {
      return this.getChoices('labware');
    }
    updateLaboratory(laboratory) {
      this.experiment.set('laboratory', laboratory);
      if (this.laboratoryChanged) {
        this.laboratoryChanged(laboratory);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "disablePlatform", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "disablePlatform"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "platforms", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "platforms"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "libraryStrategies", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "libraryStrategies"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "librarySources", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "librarySources"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "libraryLayouts", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "libraryLayouts"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "labwareTypes", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "labwareTypes"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateLaboratory", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "updateLaboratory"), _class.prototype)), _class));
  var _default = _exports.default = ExperimentMetadataComponent;
});