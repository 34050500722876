define("plutof/templates/profile/notifications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "UUJhZpIw",
    "block": "{\"symbols\":[\"nOption\"],\"statements\":[[8,\"test/route-marker\",[],[[\"@route\"],[\"profile.notifications\"]],null],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"plutof-tooltip-hover\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"plutof-label spacer-below\"],[12],[1,[30,[36,1],[\"Settings.notifications.label\"],null]],[13],[2,\"\\n\\n    \"],[10,\"div\"],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"plutof-tooltip\"],[12],[1,[30,[36,1],[\"Settings.notifications.toolT\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"label\"],[14,0,\"plutof-label plutof-label--light plutof-label--wrap\"],[12],[2,\"\\n    \"],[1,[30,[36,0],null,[[\"type\",\"checked\"],[\"checkbox\",[35,2,[\"profile\",\"general_emails\"]]]]]],[2,\"\\n    \"],[1,[30,[36,1],[\"Settings.notifications.systemNotifications\"],null]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"br\"],[12],[13],[2,\"\\n\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2,[\"notificationCheckboxes\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"label\"],[14,0,\"plutof-label plutof-label--light plutof-label--wrap\"],[12],[2,\"\\n        \"],[1,[30,[36,0],null,[[\"type\",\"checked\"],[\"checkbox\",[32,1,[\"isActive\"]]]]]],[2,\"\\n        \"],[1,[32,1,[\"name\"]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"br\"],[12],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[8,\"promise-button\",[[24,0,\"btn btn-default spacer-large-above\"]],[[\"@clicked\",\"@restIcon\"],[[32,0,[\"updateNotifications\"]],[30,[36,5],[\"ok\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,1],[\"General.Save\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"input\",\"i18n-t\",\"model\",\"-track-array\",\"each\",\"icon\"]}",
    "meta": {
      "moduleName": "plutof/templates/profile/notifications.hbs"
    }
  });
});