define("plutof/templates/components/datacite/taxon-row-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "KJIPWGxv",
    "block": "{\"symbols\":[\"@taxon\",\"@remove\"],\"statements\":[[10,\"td\"],[15,0,[30,[36,2],[[35,1,[\"attrs\",\"taxon\",\"full_taxon_name\",\"isInvalid\"]],\"has-error\"],null]],[12],[2,\"\\n\"],[6,[37,2],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[1,[32,1,[\"full_taxon_name\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[8,\"taxonomy/taxon/auto-complete\",[[24,0,\"datacite-edit-taxa__taxon\"]],[[\"@update\",\"@params\"],[[32,0,[\"fillTaxon\"]],[30,[36,0],null,[[\"mini\"],[true]]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"],[10,\"td\"],[14,0,\"data-table-icon-cell align-right\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-link data-table-btn\"],[4,[38,4],[\"click\",[32,2]],null],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"icon-remove--red\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hash\",\"validations\",\"if\",\"hasTaxonName\",\"on\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/datacite/taxon-row-form.hbs"
    }
  });
});