define("plutof/templates/components/observation/taxon-sheet/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "2jJslN3a",
    "block": "{\"symbols\":[\"@item\",\"@selected\",\"@toggle\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"\\n        taxon-sheet-item\\n        \",[30,[36,0],[[32,2],\"taxon-sheet-item--selected\"],null],\"\\n        taxon-sheet-item--category-\",[32,1,[\"category\"]],\"\\n    \"]]],[4,[38,1],[\"click\",[32,3]],null],[12],[2,\"\\n\"],[2,\"    \"],[10,\"span\"],[14,0,\"taxon-sheet-item__scientific-name\"],[12],[1,[32,1,[\"taxon_name\"]]],[13],[6,[37,0],[[32,1,[\"commonName\"]]],null,[[\"default\"],[{\"statements\":[[2,\";\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[6,[37,0],[[32,1,[\"commonName\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[14,0,\"taxon-sheet-item__common-name\"],[12],[2,\"\\n            \"],[1,[32,1,[\"commonName\"]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"on\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/observation/taxon-sheet/item.hbs"
    }
  });
});