define("plutof/components/tables/-list-view/order", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <@field @label={{component
      "tables/-list-view/order-label"
      label=@label
      active=@active
      ascending=@ascending
      sortBy=@sortBy
      disabled=@disabled
  }}>
      {{yield}}
  </@field>
  
  */
  {
    "id": "rlkOlx2p",
    "block": "{\"symbols\":[\"@field\",\"@disabled\",\"@sortBy\",\"@ascending\",\"@active\",\"@label\",\"&default\"],\"statements\":[[8,[32,1],[],[[\"@label\"],[[30,[36,0],[\"tables/-list-view/order-label\"],[[\"label\",\"active\",\"ascending\",\"sortBy\",\"disabled\"],[[32,6],[32,5],[32,4],[32,3],[32,2]]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[18,7,null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\"]}",
    "meta": {
      "moduleName": "plutof/components/tables/-list-view/order.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});