define("plutof/translations/est/taxonomy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    taxonName: 'Taksoni nimi',
    /* INDEX */
    indexTitle: 'Taksonoomia labor',
    taxonomyLabel: 'Klassifikatsioonid',
    descriptionsLabel: 'Taksonikirjeldused',
    taxonomyInfo: 'Sellel lehel on võimalik vaadata, lisada ja muuta taksoninimesid.',
    descriptionsInfo: 'Sellel lehel on võimalik vaadata, lisada ja muuta taksonikirjeldusi.',
    /* TREES */
    treesTitle: 'Klassifikatsioonid',
    newClassification: 'Uus klassifikatsioon',
    treesSearchTitle: 'Otsi taksoneid',
    treeIsBlocked: 'Klassifikatsiooni ei saa muuta sellel hetkel',
    placeholders: {
      searchHint: 'Otsi taksoninime järgi',
      descriptionsSearchHint: 'Otsi taksoninime järgi'
    },
    /* DESCRIPTIONS */
    descriptionsTitle: 'Taksonikirjeldused',
    descriptionsSearchTitle: 'Otsi taksonikirjeldusi',
    descriptionsInfoTitle: 'Taksonikirjeldused',
    /* THE REST */
    classifications: 'Klassifikatsioonid',
    treeData: 'Klassifikatsiooni andmed',
    newTree: 'Uus klassifikatsioon',
    editTree: 'Muuda Klassifikatsiooni',
    editAccess: 'Muuda õiguseid',
    tree: 'Klassifikatsioon',
    attributes: 'Atribuudid',
    vernacularNames: 'Tavanimetused',
    newTaxon: 'Uus takson',
    editTaxon: 'Muuda taksoni andmeid',
    viewTaxon: 'Taksoni vaade',
    showOnTree: 'Kuva klassifikatsioon',
    higherTaxonNodeLineage: 'Kõrgema taksoni liin:',
    treeName: 'Klassifikatsiooni nimi',
    higherTaxonNode: 'Kõrgem takson',
    originTree: 'Põhineb klassifikatsioonil',
    startNewTree: 'Alusta uut klassifikatsiooni',
    commonName: 'Tavanimetus',
    isPreferred: 'Eelistatud',
    isNotPreferred: 'Mitte eelistatud',
    parentOne: '1. vanem',
    parentTwo: '2. vanem',
    history: 'Muudatused',
    properties: 'Põhiandmed',
    locationOnTree: 'Asukoht klassifikatsioonis',
    validNameLineage: 'Kehtiva nime asukoht klassifikatsioonis',
    addChild: 'Uus alamtakson',
    isHybrid: 'Hübriid',
    syn: 'sün',
    deletedTaxon: 'NB! Asute kustutatud taksoni detailvaates.',
    restricted: 'Kaitsealune liik antud riigis.',
    synonyms: 'Sünonüümid',
    showSynonyms: 'Näita sünonüüme.',
    includeSubtaxa: 'Kaasa alamtaksonite muudatused',
    addPanelTitle: 'Uue taksoni märkused',
    editPanelTitle: 'Muudatuste märkused',
    editPanelInfo: 'Lisa märkused taksonoomia muudatuse kohta.',
    changes: 'Muudatused',
    copyLineageTooltip: 'Kopeeri taksoni asukoht',
    indexFungorumId: 'Index Fungorum ID',
    protectionStatus: {
      panelTitle: 'Kaitsekategooria',
      country: 'Riik',
      is_restricted: 'Piiratud ligipääs',
      category: 'Kategooria',
      remarks: 'Märkused'
    },
    ranks: {
      kingdom: 'Riik',
      phylum: 'Hõimkond',
      'class': 'Klass',
      order: 'Selts',
      family: 'Sugukond',
      genus: 'Perekond',
      species: 'Liik'
    },
    externalLinksTitle: 'Välised ressurssid'
  };
});