define("plutof/translations/eng/reference-series", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    title: 'Reference Series',
    searchTitle: 'Search Reference Series',
    infoTitle: 'Reference Series',
    editReference: 'Edit Reference',
    reference: 'Reference'
  };
});