define("plutof/components/agent/organization-additional-data", ["exports", "@ember-decorators/component", "ember-cp-validations", "plutof/mixins/component-validations", "plutof/utils/validations"], function (_exports, _component, _emberCpValidations, _componentValidations, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'organization.original_name': (0, _validations.nonblank)(256),
    'organization.herbarium_name': (0, _validations.nonblank)(256),
    'organization.original_herbarium_name': (0, _validations.nonblank)(256)
  });
  let OrganizationAdditionalData = (_dec = (0, _component.classNames)('organization-additional-data'), _dec(_class = class OrganizationAdditionalData extends Ember.Component.extend(Validations, _componentValidations.default) {}) || _class);
  var _default = _exports.default = OrganizationAdditionalData;
});