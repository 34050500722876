define("plutof/templates/components/observation/substrate-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "QAMZAc1u",
    "block": "{\"symbols\":[\"value\",\"update\",\"@substrate\"],\"statements\":[[10,\"td\"],[12],[2,\"\\n\"],[6,[37,1],[[32,3,[\"substrate\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"taxonomy/taxon/auto-complete\",[[24,\"data-test\",\"substrate\"]],[[\"@value\",\"@update\",\"@params\"],[[32,1],[32,2],[30,[36,0],null,[[\"mini\"],[true]]]]],null],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[13],[2,\"\\n\\n\"],[10,\"td\"],[12],[2,\"\\n    \"],[8,\"plutof-select/value\",[[24,\"data-test\",\"substrate_type\"]],[[\"@content\",\"@value\",\"@selectClass\"],[[32,0,[\"types\"]],[32,3,[\"type\"]],\"mini-measurement-input mini-measurement-select\"]],null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[12],[2,\"\\n    \"],[8,\"input\",[[24,0,\"mini-measurement-input\"],[24,\"data-test\",\"substrate_text\"]],[[\"@type\",\"@value\"],[\"text\",[32,3,[\"text\"]]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hash\",\"async/bind-relation\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/observation/substrate-edit.hbs"
    }
  });
});