define("plutof/templates/components/clipboard/external-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "TdcVbHPT",
    "block": "{\"symbols\":[\"externalAction\",\"@disabled\",\"@selectedCount\",\"@externalActions\"],\"statements\":[[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"button\"],[16,\"disabled\",[32,2]],[24,0,\"btn btn-default plutof-btn-green\"],[4,[38,0],[[32,0],[32,1,[\"action\"]]],null],[12],[2,\"\\n        \"],[10,\"span\"],[15,0,[31,[[32,1,[\"icon\"]]]]],[12],[13],[2,\"\\n\\n        \"],[10,\"span\"],[14,0,\"hidden-sm hidden-xs\"],[12],[2,\"\\n            \"],[1,[30,[36,1],[[32,1,[\"label\"]]],[[\"count\"],[[30,[36,3],[[32,3],[30,[36,2],[[32,3]],null],[30,[36,1],[\"Clipboard.all\"],null]],null]]]]],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"i18n-t\",\"locale-string\",\"if\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/clipboard/external-actions.hbs"
    }
  });
});