define("plutof/misc/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    Common: {
      PAGE_SIZE: 20,
      ENABLE_TRANSITION_CONFIRMATION: true,
      ENABLE_UNLOAD_ON_CONFIRMED_LEAVE: true,
      ENABLE_AUTOFOCUS: true,
      // Regulates how we show datetimes when UTC offset was different from current.
      // At 2016-06-01, Estonia was UTC+0300, so 2016-06-01 10:00 is 2016-06-01 07:00 UTC.
      // At 2016-11-16, we are, however, in UTC+0200, so 2016-11-16 10:00 is 2016-11-16 08:00 UTC
      //
      // So, if the observation was made at 2016-06-01 10:00, what do we show at this moment?
      //
      // The correct way, with COERCE_TO_LOCAL_UTC_OFFSET set to false, is 2016-06-01 10:00,
      // with understanding that 10:00 now and 10:00 then are not the same. This was the case
      // for the most of plutof existence. Then IIRC some user complained that his event timestamps
      // are off-by-one, so we decided to always show timestamps with the local offset
      // (COERCE_TO_LOCAL_UTC_OFFSET = true). Now, after some time, I think that
      // that was a bad idea and set this to false, keeping the previous behavious behind the flag
      // just in case.
      //
      // Timezones are hard
      COERCE_TO_LOCAL_UTC_OFFSET: false,
      DEFAULT_TIMEZONE: 'Europe/Tallinn'
    },
    Taxonomy: {
      DEFAULT_TAXONOMY_ID: 1,
      CUTOFF_RANK: 70,
      DEFAULT_RANK_ID: 0,
      USE_DEFAULT_RANK: false,
      TAIMEATLAS_COLUMN_LENGTH: 90,
      ENABLE_BLOCK_POLL: true,
      BLOCK_POLL_INTERVAL: 10000,
      PLUTOF_TAXONOMY_GROUP_ID: 68,
      TOPLEVEL_PAGE_SIZE: 100,
      ranks: {
        unspecified: 0,
        // This rank should be allowed on any level
        genus: 60,
        species: 70,
        cultivar: 90
      },
      // Plantae with the ID of 2 was the "plant" kingdom (still used in some
      // local setups and devserver). In updated taxonomy Viridiplantae with
      // the ID of 674814 is the current taxon for "plant" kingdom.
      // Since both IDs are used, and not dependent on environment, this will
      // be here instead of environment config - for now.
      nodes: {
        viridiplantae: [2, 674814]
      }
    },
    Clipboard: {
      LABEL_PADDING: 10,
      HEADER_FONT_SIZE: 40,
      BODY_FONT_SIZE: 20,
      POINTS_IN_MM: 2.835,
      ADD_ATTRIBUTION_TO_LABELS: true,
      SINGLETON_LABEL_DIMENSIONS: {
        width: 140,
        height: 90
      },
      SINGLETON_RESIZE_ON_OVERFLOW: true,
      FIELD_LABEL_SPACING: 1,
      ALTITUDE_MEASUREMENTS: [75, 76],
      // TODO: Do we still need this?
      MODULES: ['specimen', 'observation', 'sequence', 'photobank', 'livingspecimen', 'materialsample', 'refbased'],
      // Request page size when loading all records to clipboard map.
      MAP_BATCH_SIZE: 100
    },
    Globalkey: {
      SHOW_COUNTRY_GEOMS: true,
      UNITE_GROUP_ID: 23
    },
    Sample: {
      COORDINATE_DECIMAL_PLACES: 8,
      MAP_DRAWING_DEFAULT_PRECISION: 5,
      DEFAULT_LAYERSET: 'open_layers_3',
      MAAAMET_LAYERSET: 'eesti',
      ESTONIA_COUNTRY_ID: '47',
      PRESERVE_EXTENT_ON_LAYER_SWITCH: true,
      GEOCODING_OVERRIDES_COUNTRY: true,
      WATER_MAINFORM_ID: '34',
      BUILT_ENVIRONMENT_MAINFORM_ID: '32',
      PLANT_ASSOCIATED_MAINFORM_ID: '21',
      HOST_ASSOCIATED_MAINFORM_ID: '19',
      SOIL_MAINFORM_ID: '17',
      DEFAULT_MAINFORM_ID: '14',
      DEFAULT_AREA_MAINFORM_ID: '1',
      DEFAULT_EVENT_MAINFORM_ID: '2',
      COLLAPSE_SMALL_POLYGONS: true,
      SMALL_POLYGON_COLLAPSE_POINT: 20,
      PROJECT_MAP_LIMIT: 1000,
      PROJECT_MAP_LOADING_PAGE: 100,
      PROJECT_GEOM_LOADING_PAGE: 10000,
      // Number of inputs that are considered "a lot" by map, in which case it can operate
      // differently to try and optimize rendering. Currently "optimize" just means debouncing
      // feature recalculation
      MAP_BIG_DATA_POINT: 200,
      // Number of inputs when map start to process them async instead of blocking the event loop
      MAP_BATCH_DATA_POINT: 10000,
      MAP_PREPROCESS_BATCH_SIZE: 500,
      MAP_INSERT_BATCH_SIZE: 100,
      CLUSTER_MAP_LIMIT: 100000
    },
    Photobank: {
      Prefix: {
        ENABLED: true,
        ONLY_FOR_EMPTY_ID: false
      }
    },
    Observation: {
      SHOWN_CHANGES: ['taxon_node', 'typification', 'determined_date'],
      CLONE_OBSERVATION_DATA: false,
      TAIMEATLAS_MAINFORM_ID: '20',
      BIRD_MAINFORM_ID: '13',
      INSECT_MAINFORM_ID: '24',
      DEFAULT_MAINFORM_ID: '12',
      ANIMAL_MAINFORM_ID: '71',
      FUNGUS_MAINFORM_ID: '72',
      PLANT_MAINFORM_ID: '73',
      IDS_REQUIRE_DETERMINERS: ['12', '24'],
      BATCH_SAVES: true,
      SAVE_BATCH_SIZE: 3
    },
    Citizenscience: {
      INITIAL_PAGE_SIZE: 20,
      PAGE_SIZE: 100,
      GALLERY_FILE_COUNT: 30,
      CS_PROJECT_TYPE: 3
    },
    Filerepository: {
      ENABLE_AREA_SELECTION: true,
      AREA_MINIMUM_SIZE: 1000,
      MAX_PREVIEW_WIDTH: 1500,
      MAX_ANALYZED_SIZE: 10 * 1024 * 1024,
      // Images bigger than this won't be read locally

      DATACITE_TYPES: [12, 14, 15, 17, 18],
      // If upload encounters an error and can't recover in UPLOAD_RETRY_WINDOW ms, it
      // is cancelled
      UPLOAD_RETRY_INTERVAL: 1000,
      UPLOAD_RETRY_LIMIT: 10,
      // Number of files that can be uploaded at the same time.
      UPLOAD_BATCH_SIZE: 5
    },
    Specimen: {
      Prefix: {
        ENABLED: true,
        ONLY_FOR_EMPTY_ID: false
      },
      ALGAE_MAINFORM_ID: '60',
      ANIMAL_MAINFORM_ID: '5',
      FUNGUS_MAINFORM_ID: '3',
      INSECT_MAINFORM_ID: '6',
      PLANT_MAINFORM_ID: '4',
      MAMMALIA_MAINFORM_ID: '7',
      BIRD_MAINFORM_ID: '9',
      BAT_MAINFORM_ID: '8'
    },
    Measurements: {
      ENABLE_METHOD_DESC: true
    },
    Sequence: {
      // Parent objects that are linked through sequence ctype/oid
      POSSIBLE_TARGETS: ['observation', 'materialsample', 'strain', 'specimen'],
      TARGETS_REQUIRING_NO_DETERMINATION: ['specimen', 'strain'],
      UNITE_DATABASE_ID: '4',
      DEFAULT_MAINFORM_ID: '11',
      HTS_REPRESENTATIVE_MAINFORM_ID: '66'
    },
    Livingculture: {
      POSSIBLE_TARGETS: ['materialsample', 'specimen'],
      DEFAULT_MAINFORM_ID: '10'
    },
    ReferenceBased: {
      DEFAULT_MAINFORM_ID: '2'
    },
    I18n: {
      ALIAS_PREFIX: '@@'
    },
    Import: {
      HASMANY_SEPARATOR: ';',
      ENABLE_HASMANY_RESOLUTIONS: true,
      POLL_INTERVAL: 10000,
      PROGRESS_ROUND_PRECISION: 2
    },
    Search: {
      MAP_PAGE_PROCESSING_BATCH_SIZE: 50,
      MAP_PAGE_LOADING_BATCH_SIZE: 3
    },
    Organization: {
      COLLECTION_TYPE: 2,
      LABORATORY_TYPE: 3
    }
  };
});