define("plutof/components/specimen/exsiccata", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "General.exsiccata"}}
      @collapse={{and (not touched) (not @specimen.exsiccata.id)}}
  >
      <Layout::RegularGrid @columns={{2}}>
          <div>
              {{plutof-labelc "exsiccata.title"}}
  
              {{#resolve-promise @specimen.exsiccata as |value|}}
                  <AutoComplete::Model
                      @model="taxonoccurrence/specimen/exsiccata"
                      @value={{value}}
                      @update={{this.update}} />
              {{/resolve-promise}}
          </div>
  
          <div>
              {{plutof-labelc "specimen.exsiccata_no"}}
  
              <Input @type="text" @value={{@specimen.exsiccata_no}} class="form-control" />
          </div>
      </Layout::RegularGrid>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "au9Z+wWc",
    "block": "{\"symbols\":[\"value\",\"@specimen\"],\"statements\":[[8,\"plutof-panel/simple\",[],[[\"@title\",\"@collapse\"],[[30,[36,0],[\"General.exsiccata\"],null],[30,[36,3],[[30,[36,1],[[35,2]],null],[30,[36,1],[[32,2,[\"exsiccata\",\"id\"]]],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"layout/regular-grid\",[],[[\"@columns\"],[2]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"div\"],[12],[2,\"\\n            \"],[1,[30,[36,4],[\"exsiccata.title\"],null]],[2,\"\\n\\n\"],[6,[37,5],[[32,2,[\"exsiccata\"]]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[8,\"auto-complete/model\",[],[[\"@model\",\"@value\",\"@update\"],[\"taxonoccurrence/specimen/exsiccata\",[32,1],[32,0,[\"update\"]]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"        \"],[13],[2,\"\\n\\n        \"],[10,\"div\"],[12],[2,\"\\n            \"],[1,[30,[36,4],[\"specimen.exsiccata_no\"],null]],[2,\"\\n\\n            \"],[8,\"input\",[[24,0,\"form-control\"]],[[\"@type\",\"@value\"],[\"text\",[32,2,[\"exsiccata_no\"]]]],null],[2,\"\\n        \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"not\",\"touched\",\"and\",\"plutof-labelc\",\"resolve-promise\"]}",
    "meta": {
      "moduleName": "plutof/components/specimen/exsiccata.hbs"
    }
  });
  let SpecimenExsiccata = _exports.default = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = class SpecimenExsiccata extends _component.default {
    constructor(...args) {
      super(...args);
      // Without all this, clearing AC collapses the panel
      _initializerDefineProperty(this, "touched", _descriptor, this);
    }
    update(exsiccata) {
      this.touched = true;
      this.args.specimen.set('exsiccata', exsiccata);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "touched", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "update", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "update"), _class.prototype)), _class));
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SpecimenExsiccata);
});