define("plutof/translations/est/permit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    permit: 'Luba',
    permits: 'Load',
    add: 'Uus luba',
    edit: 'Muuda luba',
    source: 'Allikas',
    usedIn: 'Kasutatud'
  };
});