define("plutof/templates/components/promise-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "lJfSb8PK",
    "block": "{\"symbols\":[\"&default\",\"@tooltip\",\"&attrs\"],\"statements\":[[11,\"button\"],[16,\"disabled\",[32,0,[\"buttonDisabled\"]]],[17,3],[4,[38,0],[\"click\",[32,0,[\"clicked\"]]],null],[12],[2,\"\\n\"],[6,[37,1],[[32,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[8,\"tool-tip\",[],[[\"@tooltip\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[10,\"span\"],[15,0,[31,[[32,0,[\"icon\"]]]]],[12],[13],[2,\"\\n\\n            \"],[18,1,null],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"span\"],[15,0,[31,[[32,0,[\"icon\"]]]]],[12],[13],[2,\"\\n\\n        \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\",\"if\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/promise-button.hbs"
    }
  });
});