define("plutof/routes/taxonomy/addtree", ["exports", "plutof/mixins/add-route", "plutof/utils/access"], function (_exports, _addRoute, _access) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class AddTreeRoute extends Ember.Route.extend(_addRoute.default) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "defaultReturnRoute", 'taxonomy.trees');
      _defineProperty(this, "title", 'Taxonomy.newTree');
    }
    model(params) {
      if (this.get('controller.routeHasBeenLoaded')) {
        return undefined;
      }
      const store = this.store;
      const {
        origin_tree_id,
        root_id
      } = params;
      return Ember.RSVP.hash({
        accessRights: (0, _access.create_access_rights)(this.store),
        rootNode: !Ember.isEmpty(root_id) ? store.findRecord('taxonomy/taxonnode', root_id) : null,
        originTree: !Ember.isEmpty(origin_tree_id) ? store.findRecord('taxonomy/tree', origin_tree_id) : null
      });
    }
    setupController(controller, model) {
      super.setupController(controller, model);
      if (!Ember.isNone(model)) {
        controller.setProperties({
          attributesAreInvalid: false,
          name: ''
        });
      }
    }
  }
  var _default = _exports.default = AddTreeRoute;
});