define("plutof/templates/components/tables/-bulk-update/update-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "7a9bstJS",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[14,0,\"fas fa-check\"],[12],[13],[2,\"\\n\"],[1,[30,[36,0],[\"General.tableBulkUpdate.update\"],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/tables/-bulk-update/update-button.hbs"
    }
  });
});