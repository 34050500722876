define("plutof/translations/eng/chart", ["exports", "plutof/translations/helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    time: 'time',
    count: 'count',
    year: 'year',
    years: 'Years',
    quarter: 'quarter',
    month: 'month',
    months: 'Months',
    week: 'week',
    day: 'day',
    hour: 'hour',
    minute: 'minute',
    createdAt: (0, _helpers.alias)('Search.createdAt'),
    timespanBegin: 'Collected at',
    taxonNode: (0, _helpers.alias)('General.taxon'),
    observations: 'Observations',
    specimens: 'Specimens',
    'material-samples': 'Material samples',
    sequences: 'Sequences',
    'refbased-occurrences': 'Ref. based',
    'living-specimens': 'Living Specimens',
    phenological: 'Phenological',
    cumulative: 'Cumulative',
    occurrenceInYear: 'Taxon count in a year',
    cumulativeCount: 'Cumulative taxon count ',
    district: 'District',
    unspecified: 'Unspecified',
    taxa: 'Taxa',
    period: 'Period'
  };
});