define("plutof/components/collection/view/annotations/annotation-link", ["exports", "@glimmer/component", "plutof/utils/modules"], function (_exports, _component, _modules) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.route}}
      {{#link-to this.route @annotation.record.id @annotation.id}}
          {{@annotation.status}}
      {{/link-to}}
  {{else}}
      {{@annotation.status}}
  {{/if}}
  
  */
  {
    "id": "146soFx9",
    "block": "{\"symbols\":[\"@annotation\"],\"statements\":[[6,[37,2],[[32,0,[\"route\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,1],null,[[\"route\",\"models\"],[[32,0,[\"route\"]],[30,[36,0],[[32,1,[\"record\",\"id\"]],[32,1,[\"id\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[32,1,[\"status\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[32,1,[\"status\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"array\",\"link-to\",\"if\"]}",
    "meta": {
      "moduleName": "plutof/components/collection/view/annotations/annotation-link.hbs"
    }
  });
  class CollectionViewAnnotationLink extends _component.default {
    get route() {
      const module = _modules.default[this.args.annotation.record.content_type];
      return Ember.get(module, 'routes.annotations.view');
    }
  }
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CollectionViewAnnotationLink);
});