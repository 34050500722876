define("plutof/components/search/filter-panels/occurrence-taxon-inputs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Layout::RegularGrid @columns={{6}}>
      <Search::TaxonFilterInputs
          @filters={{@filterInputs}} />
  
      <Search::FilterInput
          @filter={{@filterInputs.taxon_rank}}
          @autocompletePreset="taxonomy/taxonrank" />
  
      <Search::FilterInput @filter={{@filterInputs.new_name}} />
  
      {{#if @filterInputs.typification}}
          <Search::Filters::Typification @filter={{@filterInputs.typification}} />
      {{/if}}
  
      <Search::Filters::ChoiceOrExists
          @choiceFilter={{@filterInputs.assessment}}
          @existsFilter={{@filterInputs.assessment__exists}} />
  
      <Search::FilterInput @filter={{@filterInputs.interacting_taxa}} />
      <Search::FilterInput @filter={{@filterInputs.prot_category}} />
      <Search::FilterInput @filter={{@filterInputs.substrate}} />
      <Search::FilterInput @filter={{@filterInputs.substrate_type}} />
      <Search::FilterInput @filter={{@filterInputs.exsiccata}} />
  
      <Search::FilterInput
          @filter={{@filterInputs.sequence_taxon}}
          @autocompletePreset="includeCommonnames" />
  
      <Search::FilterInput @filter={{@filterInputs.cluster}} />
  </Layout::RegularGrid>
  
  */
  {
    "id": "MHSMCxoh",
    "block": "{\"symbols\":[\"@filterInputs\"],\"statements\":[[8,\"layout/regular-grid\",[],[[\"@columns\"],[6]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"search/taxon-filter-inputs\",[],[[\"@filters\"],[[32,1]]],null],[2,\"\\n\\n    \"],[8,\"search/filter-input\",[],[[\"@filter\",\"@autocompletePreset\"],[[32,1,[\"taxon_rank\"]],\"taxonomy/taxonrank\"]],null],[2,\"\\n\\n    \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"new_name\"]]]],null],[2,\"\\n\\n\"],[6,[37,0],[[32,1,[\"typification\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"search/filters/typification\",[],[[\"@filter\"],[[32,1,[\"typification\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,\"search/filters/choice-or-exists\",[],[[\"@choiceFilter\",\"@existsFilter\"],[[32,1,[\"assessment\"]],[32,1,[\"assessment__exists\"]]]],null],[2,\"\\n\\n    \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"interacting_taxa\"]]]],null],[2,\"\\n    \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"prot_category\"]]]],null],[2,\"\\n    \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"substrate\"]]]],null],[2,\"\\n    \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"substrate_type\"]]]],null],[2,\"\\n    \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"exsiccata\"]]]],null],[2,\"\\n\\n    \"],[8,\"search/filter-input\",[],[[\"@filter\",\"@autocompletePreset\"],[[32,1,[\"sequence_taxon\"]],\"includeCommonnames\"]],null],[2,\"\\n\\n    \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"cluster\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "plutof/components/search/filter-panels/occurrence-taxon-inputs.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});