define("plutof/translations/eng/menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    labs: {
      collection: 'Collection Lab',
      publishing: 'Publishing Lab',
      taxonomy: 'Taxonomy Lab',
      analysis: 'Analysis Lab',
      molecular: 'Molecular Lab',
      gis: 'GIS Lab'
    },
    notifications: {
      notifications: 'Notifications'
    }
  };
});