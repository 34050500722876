define("plutof/translations/est/group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    fields: {
      description: 'Kirjeldus'
    },
    actions: {
      join: 'Liitu'
    },
    status: {
      0: 'Ootab kinnitamist',
      2: 'Tavakasutaja',
      4: 'Moderaator',
      8: 'Omanik'
    },
    messages: {
      joinRequestSent: 'Liitumistaotlus on saadetud.'
    },
    panels: {
      workgroup: 'Haldav töörühm'
    }
  };
});