define("plutof/translations/est/conservation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    laboratory: {
      title: 'Looduskaitse labor'
    },
    redList: {
      list: 'Punane nimestik',
      fields: {
        area: 'Ala'
      },
      index: {
        title: 'Punased nimestikud',
        description: ''
      },
      add: {
        title: 'Uus punane nimestik',
        uploadArea: 'Laadi üles GeoJSON',
        geojsonMustHaveExactlyOneFeature: 'GeoJSON-l võib olla vaid üks "feature". Fail sisaldab {{count}}',
        unsupportedGeometryType: 'Toetatud on ainult Polygon ja MultiPolygon geomeetriad'
      },
      edit: {
        title: 'Muuda punast nimestikku'
      },
      view: {
        title: 'Punane nimestik',
        assessments: 'Hinnangud'
      }
    },
    redListAssessment: {
      assessment: 'Red List Assessment',
      assessments: 'Red List Assessments',
      iucnFields: 'IUCN',
      index: {
        title: 'Punase nimestiku hinnangud',
        description: ''
      },
      add: {
        title: 'Uus punanese nimestiku hinnang'
      },
      edit: {
        title: 'Muuda punase nimestiku hinnangut'
      },
      view: {
        title: 'Punase nimestiku hinnang'
      }
    }
  };
});