define("plutof/components/navbar/-navbar-base/upload", ["exports", "plutof/misc/abstract", "plutof/misc/fileupload", "plutof/utils/access", "plutof/utils/notifications"], function (_exports, _abstract, _fileupload, _access, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let NavbarUploadButton = (_dec = Ember.inject.service, _dec2 = Ember.computed.gt('pendingUploads.length', 0), _dec3 = Ember.computed('pendingUploads.@each.uploadProgress'), _dec4 = Ember._action, (_class = class NavbarUploadButton extends Ember.Component {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "fileUpload", _descriptor, this);
      _defineProperty(this, "pendingUploads", []);
      _initializerDefineProperty(this, "isUploading", _descriptor2, this);
    }
    get uploadProgress() {
      if (this.pendingUploads.length === 0) {
        return 100;
      }
      return (100 * (0, _abstract.sum)(this.pendingUploads.mapBy('uploadProgress')) / this.pendingUploads.length).toFixed(0);
    }
    async upload() {
      const selection = await (0, _fileupload.selectFile)({
        multiple: !this.singular
      });
      if (selection.length === 0) {
        return;
      }
      try {
        const files = await this.fileUpload.addLocalFiles(this.singular ? [selection] : selection);
        this.set('pendingUploads', files);
        const access = await (0, _access.create_access_rights)(this.store);
        const fileRecords = await Ember.RSVP.all(files.map(file => file.save(access)));
        this.use(this.singular ? fileRecords[0] : fileRecords);
      } catch (error) {
        (0, _notifications.reportError)(error);
        throw error;
      } finally {
        this.set('pendingUploads', []);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fileUpload", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isUploading", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "uploadProgress", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "uploadProgress"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "upload", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "upload"), _class.prototype)), _class));
  var _default = _exports.default = NavbarUploadButton;
});