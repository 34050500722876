define("plutof/components/search/filter-panels/transaction/properties", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "Search.panelProperties"}}
      @collapse={{true}}
  >
      <Search::FilterPanels::CommonProperties::Agents @filterInputs={{@filterInputs}} />
  
      <Layout::RegularGrid @columns={{6}} as |grid|>
          {{#grid.span 2}}
              <Search::RepositoryInput @repositoryFilter={{@filterInputs.collection}} />
          {{/grid.span}}
  
          <Search::FilterInput @filter={{@filterInputs.keywords}} />
          <Search::FilterInput @filter={{@filterInputs.purpose}} />
          <Search::FilterInput @filter={{@filterInputs.transaction_type}} />
          <Search::FilterInput @filter={{@filterInputs.agents}} />
      </Layout::RegularGrid>
  
      <Layout::RegularGrid @columns={{6}}>
          <Search::FilterPanels::CommonProperties::Checkboxes @filterInputs={{@filterInputs}} />
          <Search::FilterInput @filter={{@filterInputs.is_locked}} />
      </Layout::RegularGrid>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "UTgi3/nv",
    "block": "{\"symbols\":[\"grid\",\"@filterInputs\"],\"statements\":[[8,\"plutof-panel/simple\",[],[[\"@title\",\"@collapse\"],[[30,[36,0],[\"Search.panelProperties\"],null],true]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"search/filter-panels/common-properties/agents\",[],[[\"@filterInputs\"],[[32,2]]],null],[2,\"\\n\\n    \"],[8,\"layout/regular-grid\",[],[[\"@columns\"],[6]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[32,1,[\"span\"]],2],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"search/repository-input\",[],[[\"@repositoryFilter\"],[[32,2,[\"collection\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n        \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,2,[\"keywords\"]]]],null],[2,\"\\n        \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,2,[\"purpose\"]]]],null],[2,\"\\n        \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,2,[\"transaction_type\"]]]],null],[2,\"\\n        \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,2,[\"agents\"]]]],null],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\\n    \"],[8,\"layout/regular-grid\",[],[[\"@columns\"],[6]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"search/filter-panels/common-properties/checkboxes\",[],[[\"@filterInputs\"],[[32,2]]],null],[2,\"\\n        \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,2,[\"is_locked\"]]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"component\"]}",
    "meta": {
      "moduleName": "plutof/components/search/filter-panels/transaction/properties.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});