define("plutof/templates/components/save-and-reset", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "troJLcV9",
    "block": "{\"symbols\":[\"@disabled\"],\"statements\":[[8,\"promise-button\",[[24,0,\"btn btn-default save-and-new-button\"]],[[\"@clicked\",\"@disabled\",\"@restIcon\",\"@tooltip\"],[[32,0,[\"save\"]],[32,1],[30,[36,0],[\"add-new\"],null],[30,[36,1],[\"cloning.saveAndAddNewTooltip\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,1],[\"cloning.saveAndAddNew\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/save-and-reset.hbs"
    }
  });
});