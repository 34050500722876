define("plutof/templates/referencebased/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "t26FT16T",
    "block": "{\"symbols\":[\"Field\",\"refbased\",\"common\",\"navbar\",\"slots\"],\"statements\":[[10,\"div\"],[14,0,\"route-marker-referencebased-index\"],[12],[13],[2,\"\\n\\n\"],[8,\"navbar/list\",[],[[\"@icon\",\"@title\",\"@addRoute\",\"@pagination\",\"@infoTitle\"],[[30,[36,0],[\"refbased\"],null],[30,[36,1],[\"ReferenceBased.title\"],null],\"referencebased.add\",[32,0,[\"model\",\"pagination\"]],[30,[36,1],[\"ReferenceBased.infoTitle\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,5,[\"primaryControls\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"import/navbar-button\",[],[[\"@navbar\",\"@module\"],[[32,4],\"reference-based\"]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[4,5]}]]],[2,\"\\n\\n\"],[8,\"tables/list-route\",[],[[\"@records\"],[[32,0,[\"model\",\"pagination\",\"objects\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1],[],[[\"@label\"],[[30,[36,1],[\"PlutofListViewColumn.taxon\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],null,[[\"route\",\"model\"],[\"referencebased.view\",[32,2,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[32,2,[\"taxon_name\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,1],[],[[\"@label\"],[[30,[36,1],[\"PlutofListViewColumn.reference\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,3],[[32,2,[\"reference_name\"]],50],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,3,[\"modified\"]],[],[[],[]],null],[2,\"\\n    \"],[8,[32,3,[\"relatedObjects\"]],[],[[],[]],null],[2,\"\\n    \"],[8,[32,3,[\"clipboard\"]],[],[[\"@contentType\"],[[32,0,[\"contentType\"]]]],null],[2,\"\\n    \"],[8,[32,3,[\"access\"]],[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[1,2,3]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\",\"link-to\",\"short-string\"]}",
    "meta": {
      "moduleName": "plutof/templates/referencebased/index.hbs"
    }
  });
});