define("plutof/components/taxonomy/taxon/view/taxon-descriptions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::ItemList as |panel|>
      <panel.header
          @title={{i18n-t "Taxonomy.descriptionsTitle"}}
          @count={{@taxonDescriptions.length}} />
  
      <panel.innerPanels>
          {{#each @taxonDescriptions as |taxonDescription|}}
              <PlutofPanel::Inner>
                  <Taxonomy::Taxon::View::TaxonDescription
                      @taxonDescription={{taxonDescription}} />
              </PlutofPanel::Inner>
          {{/each}}
      </panel.innerPanels>
  </PlutofPanel::ItemList>
  
  */
  {
    "id": "bSqsnhed",
    "block": "{\"symbols\":[\"panel\",\"taxonDescription\",\"@taxonDescriptions\"],\"statements\":[[8,\"plutof-panel/item-list\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"header\"]],[],[[\"@title\",\"@count\"],[[30,[36,0],[\"Taxonomy.descriptionsTitle\"],null],[32,3,[\"length\"]]]],null],[2,\"\\n\\n    \"],[8,[32,1,[\"innerPanels\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"plutof-panel/inner\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[8,\"taxonomy/taxon/view/taxon-description\",[],[[\"@taxonDescription\"],[[32,2]]],null],[2,\"\\n            \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "plutof/components/taxonomy/taxon/view/taxon-descriptions.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});