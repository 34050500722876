define("plutof/components/dmp/dataset-metadata", ["exports", "plutof/misc/options-getter"], function (_exports, _optionsGetter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Layout::RegularGrid @columns={{2}} @cellGap={{true}}>
      <GuidedForm @path="dmp.datasetMetadata.language">
          <Input
              @type="text"
              @value={{@dataset.metadata.language}}
              @placeholder={{i18n-t "dmp.dataset.language.placeholder"}}
              @class="form-control" />
      </GuidedForm>
  
      <GuidedForm @path="dmp.datasetMetadata.description">
          <FlexibleTextarea @value={{@dataset.metadata.description}} @class="form-control" />
      </GuidedForm>
  
      <GuidedForm @path="dmp.datasetMetadataStandard.identifier">
          <Input
              @type="text"
              @value={{@dataset.metadata.metadata_standard_id.identifier}}
              @placeholder={{i18n-t "dmp.datasetMetadataStandard.identifier.placeholder"}}
              @class="form-control" />
      </GuidedForm>
  
      <GuidedForm @path="dmp.datasetMetadataStandard.type">
          <PlutofSelect::Value
              @content={{this.metadataStandardTypes}}
              @value={{@dataset.metadata.metadata_standard_id.type}} />
      </GuidedForm>
  </Layout::RegularGrid>
  
  */
  {
    "id": "ApAw9+co",
    "block": "{\"symbols\":[\"@dataset\"],\"statements\":[[8,\"layout/regular-grid\",[],[[\"@columns\",\"@cellGap\"],[2,true]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"guided-form\",[],[[\"@path\"],[\"dmp.datasetMetadata.language\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"input\",[],[[\"@type\",\"@value\",\"@placeholder\",\"@class\"],[\"text\",[32,1,[\"metadata\",\"language\"]],[30,[36,0],[\"dmp.dataset.language.placeholder\"],null],\"form-control\"]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,\"guided-form\",[],[[\"@path\"],[\"dmp.datasetMetadata.description\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"flexible-textarea\",[],[[\"@value\",\"@class\"],[[32,1,[\"metadata\",\"description\"]],\"form-control\"]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,\"guided-form\",[],[[\"@path\"],[\"dmp.datasetMetadataStandard.identifier\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"input\",[],[[\"@type\",\"@value\",\"@placeholder\",\"@class\"],[\"text\",[32,1,[\"metadata\",\"metadata_standard_id\",\"identifier\"]],[30,[36,0],[\"dmp.datasetMetadataStandard.identifier.placeholder\"],null],\"form-control\"]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,\"guided-form\",[],[[\"@path\"],[\"dmp.datasetMetadataStandard.type\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"plutof-select/value\",[],[[\"@content\",\"@value\"],[[32,0,[\"metadataStandardTypes\"]],[32,1,[\"metadata\",\"metadata_standard_id\",\"type\"]]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\"]}",
    "meta": {
      "moduleName": "plutof/components/dmp/dataset-metadata.hbs"
    }
  });
  let DMPDatasetMetadata = (_dec = Ember.inject.service, _dec2 = (0, _optionsGetter.getDMPChoices)('dataset.properties.metadata.properties.metadata_standard_id.properties.type'), (_class = class DMPDatasetMetadata extends Ember.Component {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "metadataStandardTypes", _descriptor2, this);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "metadataStandardTypes", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DMPDatasetMetadata);
});