define("plutof/templates/forms/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "a6stzzQQ",
    "block": "{\"symbols\":[],\"statements\":[[8,\"test/route-marker\",[],[[\"@route\"],[\"forms.edit\"]],null],[2,\"\\n\\n\"],[8,\"navbar/edit\",[],[[\"@icon\",\"@title\",\"@record\",\"@infoContent\"],[[30,[36,0],[\"form\"],null],[30,[36,1],[\"Forms.editTitle\"],null],[32,0,[\"model\",\"mainformData\",\"mainform\"]],\"information/content/forms/general\"]],null],[2,\"\\n\\n\"],[8,\"forms/edit\",[],[[\"@data\",\"@managingGroup\",\"@validationContext\"],[[32,0,[\"model\",\"mainformData\"]],[32,0,[\"model\",\"managingGroup\"]],[32,0,[\"validationContext\",\"generalData\"]]]],null],[2,\"\\n\\n\"],[8,\"forms/access\",[],[[\"@mainform\"],[[32,0,[\"model\",\"mainformData\",\"mainform\"]]]],null],[2,\"\\n\\n\"],[8,\"sticky-bottom-wrapper\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"invalid-panels\",[],[[\"@validationContext\"],[[32,0,[\"validationContext\"]]]],null],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"action-buttons\"],[12],[2,\"\\n        \"],[8,\"common/save-button\",[],[[\"@save\",\"@disabled\"],[[32,0,[\"save\"]],[32,0,[\"validationContext\",\"isInvalid\"]]]],null],[2,\"\\n\\n        \"],[8,\"common/cancel-button\",[],[[\"@cancel\"],[[30,[36,2],[[32,0],\"cancel\"],null]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\",\"action\"]}",
    "meta": {
      "moduleName": "plutof/templates/forms/edit.hbs"
    }
  });
});