define("plutof/misc/options-getter", ["exports", "ember-inflector", "ember-data", "plutof/config/environment", "plutof/misc/abstract", "plutof/utils/caching", "plutof/utils/i18n"], function (_exports, _emberInflector, _emberData, _environment, _abstract, _caching, _i18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getChoices = getChoices;
  _exports.getDMPChoices = getDMPChoices;
  _exports.getModelTexts = getModelTexts;
  _exports.getPromise = void 0;
  _exports.getTexts = getTexts;
  _exports.setupAliases = setupAliases;
  const getPromise = _exports.getPromise = function () {
    let cache;
    const locale = (0, _i18n.getI18n)().locale;
    return function (ajax, modelPath) {
      if (!cache) {
        cache = (0, _caching.simple)(`options.${locale}`, function (modelPath) {
          return ajax.request(`${_environment.default.API_HOST}${modelPath}`, {
            type: 'OPTIONS',
            headers: {
              'Accept-Language': locale
            }
          });
        });
      }
      return cache(modelPath);
    };
  }();

  // TODO: choices()-like interface (property path)
  function getChoices(ajax, modelPath, property, {
    preserveEmpty = false,
    jsonAPI = false
  } = {}) {
    return getPromise(ajax, modelPath).then(function (answer) {
      const description = Ember.get((jsonAPI ? answer.data : answer).actions.POST, property);
      if (Ember.isNone(description)) {
        return [];
      }
      let choices = description.child ? description.child.choices : description.choices;
      if (Ember.isNone(choices)) {
        return [];
      }
      if (preserveEmpty) {
        choices.forEach(function (choice) {
          if (Ember.isEmpty(choice.value)) {
            Ember.set(choice, 'display_name', '---');
          }
        });
      } else {
        choices = choices.reject(choice => Ember.isEmpty(choice.value));
      }
      return choices;
    });
  }

  // samplingevent -> [sample.samplingevent]
  // type -> [foo.type, bar.type]
  // sequence -> [taxonoccurrence.sequence.sequence]
  // sequence.sequence -> [taxonoccurrence.sequence.sequence]
  var aliases = {};
  function setupAliases(models) {
    aliases = {};
    models.forEach(function (model) {
      var parts = model.split('.');
      var suffix;
      for (var i = parts.length - 1; i >= 0; i--) {
        suffix = suffix ? parts[i] + '.' + suffix : parts[i]; // XXX

        if (!Ember.isNone(aliases[suffix])) {
          aliases[suffix].pushObject(model);
        } else {
          aliases[suffix] = [model];
        }
      }
    });
  }
  var texts_cache = [];
  function getModelTexts(ajax, model) {
    if (!texts_cache[model]) {
      const url = '/' + (0, _emberInflector.pluralize)(model).replace(/\./g, '/') + '/';
      texts_cache[model] = getPromise(ajax, url).then(function (response) {
        // XXX JSONAPI OPTIONS response is wrapped in data.
        return Ember.isPresent(response.actions) ? response.actions.POST : response.data.actions.POST;
      });
    }
    return texts_cache[model];
  }
  function separatePath(path) {
    var field_sep_idx = path.lastIndexOf('.');
    return {
      prefix: path.substr(0, field_sep_idx),
      property: path.substr(field_sep_idx + 1)
    };
  }
  function getModelSourceTexts(ajax, path) {
    var parts = separatePath(path);
    var models = aliases[parts.prefix];
    var field = parts.property;

    /* eslint-disable no-console */
    if (Ember.isNone(models)) {
      console.error('No model found for alias', parts.prefix);
      return (0, _abstract.wrap_as_promise)(undefined);
    }
    if (models.length > 1) {
      console.error('Alias', parts.prefix, 'doesn\'t define a model uniquely. Possible models are', models.join(', '));
      return (0, _abstract.wrap_as_promise)(undefined);
    }
    return getModelTexts(ajax, models[0]).then(function (texts) {
      if (Ember.isNone(texts[field])) {
        console.error('Resolving text fortspath', path, 'failed: response does not contain data for field', field);
      }
      return texts[field] || {};
    });
    /* eslint-enable no-console */
  }
  function getLocalTexts(ajax, path) {
    var translation = (0, _i18n.getI18n)().t(path);
    return (0, _abstract.wrap_as_promise)({
      label: translation,
      help_text: translation
    });
  }
  function getEndpointTexts(ajax, path) {
    var parts = separatePath(path);
    return getPromise(ajax, '/' + parts.prefix.replace(/\./g, '/') + '/').then(function (response) {
      return response.actions.POST[parts.property];
    });
  }
  function getOptionsTexts(ajax, path) {
    return (0, _abstract.wrap_as_promise)(path);
  }
  var sources = {
    'model': getModelSourceTexts,
    'local': getLocalTexts,
    'endpoint': getEndpointTexts,
    'options': getOptionsTexts
  };
  function getTexts(ajax, path, source) {
    return sources[source](ajax, path);
  }
  function getNestedChoices(modelPath, property, options) {
    return Ember.computed(function () {
      const url = `/${(0, _emberInflector.pluralize)(modelPath).replace(/\./g, '/')}/`;
      return _emberData.default.PromiseArray.create({
        promise: getChoices(this.ajax, url, property, options)
      });
    });
  }
  function getDMPChoices(property) {
    return getNestedChoices('study.dmp', `dmp.properties.${property}`, {
      jsonAPI: true,
      preserveEmpty: true
    });
  }
});