define("plutof/adapters/auth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const AdapterAuth = Ember.Mixin.create({
    session: Ember.inject.service(),
    locale: Ember.inject.service(),
    headers: Ember.computed('session.isAuthenticated', 'session.data.authenticated.access_token', function () {
      let headers = {
        'Accept-Language': this.locale.locale
      };
      if (this.session.isAuthenticated) {
        headers['Authorization'] = `Bearer ${this.session.data.authenticated.access_token}`;
      }
      return headers;
    })
  });
  var _default = _exports.default = AdapterAuth;
});