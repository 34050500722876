define("plutof/templates/components/pagination/page-controls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "+SNtWhwl",
    "block": "{\"symbols\":[\"@pagination\",\"@maxPages\",\"@disabled\",\"@split\",\"@removeSpacing\"],\"statements\":[[6,[37,2],[[32,0,[\"showInterface\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"common/page-navigation\",[],[[\"@pageNumber\",\"@pageCount\",\"@pageLoading\",\"@isFirstPage\",\"@isLastPage\",\"@isMaxPage\",\"@firstPage\",\"@previousPage\",\"@nextPage\",\"@lastPage\",\"@refresh\",\"@canRefresh\",\"@disabled\",\"@split\"],[[32,1,[\"pageNumber\"]],[32,0,[\"browsablePagesCount\"]],[32,1,[\"isLoading\"]],[32,1,[\"onFirstPage\"]],[32,1,[\"onLastPage\"]],[30,[36,0],[[32,1,[\"pageNumber\"]],[32,2]],null],[32,0,[\"firstPage\"]],[32,0,[\"previousPage\"]],[32,0,[\"nextPage\"]],[32,0,[\"lastPage\"]],[32,0,[\"_refresh\"]],[32,0,[\"canRefresh\"]],[32,3],[32,4]]],null],[2,\"\\n\\n\"],[6,[37,1],[[32,5]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[14,0,\"spacer-large\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"is-equal\",\"unless\",\"if\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/pagination/page-controls.hbs"
    }
  });
});