define("plutof/translations/eng/unite-sh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    SHsearch: 'search Species Hypotheses by SH code, taxon name and sequence accession number.',
    // In this section you can ...
    SHrefs: 'browse SH reference sequences.',
    // In this section you can ...
    SHtaxonomyBrowser: 'browse SH-s in Linnaean Classification',
    // In this section you can ...
    statisticsInfo: 'find various statistics on SH-s.',
    // In this section you can ...
    downloads: 'download UNITE reference datasets.',
    // In this section you can ...
    SHS: 'SH Search',
    SHR: 'SH RefS',
    SHTB: 'SH Taxonomy Browser',
    USH: 'UNITE Species Hypotheses',
    referenceSequence: 'Reference sequence',
    centroidSequence: 'Centroid sequence',
    distance: 'Distance (%)',
    insertedBy: 'Inserted by',
    instertedAt: 'Inserted at',
    shCode: 'SH code',
    accessionNumbers: 'Accession numbers',
    version: 'Version',
    refseqChosenBy: 'Chosen by',
    refseqChosenAt: 'Date',
    unassignRefseq: 'Unassign',
    lineage: 'Placement in PlutoF classification',
    sequenceSource: 'DNA Source',
    selectedSequences: 'Selected sequences',
    sequenceID: 'Sequence ID',
    location: 'Country',
    refseq: 'RefSeq',
    actions: 'Actions',
    selectDistance: 'Select distance to browse',
    includeSingletons: 'Include',
    onlySingletons: 'Only singletons',
    onlyNonsingletons: 'Only non-singletons',
    allSHs: 'All SH-s',
    conflicts: 'Conflicts',
    conflictSH: 'SH',
    conflictFirstSeq: 'First sequence',
    conflictSecondSeq: 'Second sequence',
    searchBySH: 'Search by UNITE SH code',
    searchTitle: 'Search Species Hypotheses',
    downloadFASTA: 'FASTA',
    statistics: {
      total: 'Total',
      clusters: 'Total no. of SH-s',
      singletons: 'No. of singleton SH-s',
      referencedClusters: 'No. of referenced SH-s',
      referencedSingletons: 'No. of referenced singleton SH-s'
    },
    shView: {
      title: 'Species Hypotheses (SH)',
      shortName: 'SH short name',
      communication: 'Communication of the SH',
      thTaxonomy: 'Placement in Taxon Hypotheses',
      previousDOIVersion: 'Previous version'
    },
    collapse: {
      title: 'Collapsible views',
      level: 'Level {{level}}',
      levelDescriptions: {
        0: 'Sequences within the same 1.0% SH for the same continent collapsed',
        1: 'Sequences within the same 1.0% SH for the same country collapsed',
        2: 'Sequences within the same 0.5% SH for the same continent collapsed',
        3: 'Sequences within the same 0.5% SH for the same country collapsed',
        4: 'Identical sequences for the same continent collapsed',
        5: 'Identical sequences for the same country collapsed',
        6: 'All sequences belonging to the SH are shown'
      }
    },
    taxonSearchPlaceholder: 'Search by taxon',
    versionCreatedAt: 'Created at',
    colorSequences: 'Colors',
    treshold: 'Treshold',
    nrOfSeq: 'No. of seqs',
    refseqChosenByII: 'RefSeq chosen by',
    additionalViews: 'Additional Views',
    alignmentView: 'Alignment View',
    clusterView: 'Compound Cluster View',
    determinations: 'SH identifications',
    refseqList: 'Reference Sequences',
    description: 'Unified system for the DNA based fungal species linked to the classification.',
    // XXX: Refactor those
    DoiSHref: {
      refSeq: 'Reference sequence',
      chosenBy: 'Chosen by',
      date: 'Date',
      minimumDistance: 'Minimum distance to the closest SH',
      nrOfSeqInSH: 'No. of sequences in SH',
      placementInClassif: 'Placement in classification',
      statistics: 'Statistics',
      distrOffDist: 'Distribution of distances',
      listOfIds: 'Identifications',
      listOfInterTaxa: 'List of interacting taxa',
      seqID: 'Sequence ID',
      seqRepresentative: 'Representative sequence',
      UniteTaxName: 'UNITE taxon name',
      INSDtaxName: 'INSD taxon name',
      country: 'Country',
      DNAsource: 'DNA source',
      interactTaxa: 'Interacting taxa',
      chimeric: 'Chimeric',
      lowq: 'Low quality',
      locked: 'Locked',
      uniteCoreSeq: 'UNITE core sequence',
      automaticallyChosen: 'automatically chosen SH representative sequence',
      EX: 'EX = sequence to be excluded from the next version of global key',
      reprSeq: 'Representative sequence',
      seqIDShort: 'Seq ID',
      accessionNr: 'Accession numbers',
      linkToIndexFungorium: 'in Index Fungorum',
      see: 'See',
      locationsWithoutCord: '* Locations without exact coordinates are displayed as country centroids',
      distributionMap: 'Distribution map',
      ecology: 'Ecology',
      shGraphicalView: 'SH graphical view',
      refSeqShort: 'RefSeq',
      repSeqShort: 'RepSeq',
      speciesHypothesis: 'Species Hypothesis pages version 7',
      ECMLineage: 'EcM lineage',
      galleryTitle: 'Gallery',
      showOldTable: 'Show legacy alignment table'
    },
    clusters: {
      title: 'Compound Clusters',
      accession: 'Accession Number',
      source: 'Source',
      interaction: 'Interacting Taxa',
      area: 'Area',
      alignment: 'Alignment',
      nrOfSeq: 'No. of seqs',
      uniteTaxon: 'Unite Taxon Name',
      insdTaxon: 'INSD Taxon Name'
    }
  };
});