define("plutof/components/plutof-select/multiple/choices", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofSelect::Multiple::-Wrap
      @value={{this.items}}
      @add={{this.add}}
      @remove={{this.remove}}
  >
      <:select as |wrap|>
          <PlutofSelect::Value
              @content={{@choices}}
              @oneWay={{true}}
              @value={{wrap.value}}
              @selectionChanged={{wrap.update}} />
      </:select>
  
      <:selection as |choice|>
          {{choice.display_name}}
      </:selection>
  </PlutofSelect::Multiple::-Wrap>
  
  */
  {
    "id": "4qF85Vpj",
    "block": "{\"symbols\":[\"__arg0\",\"__arg1\",\"choice\",\"wrap\",\"@choices\"],\"statements\":[[8,\"plutof-select/multiple/-wrap\",[],[[\"@value\",\"@add\",\"@remove\",\"@namedBlocksInfo\"],[[32,0,[\"items\"]],[32,0,[\"add\"]],[32,0,[\"remove\"]],[30,[36,3],null,[[\"select\",\"selection\"],[1,1]]]]],[[\"default\"],[{\"statements\":[[6,[37,2],[[30,[36,1],[[32,1],\"select\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,0],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"plutof-select/value\",[],[[\"@content\",\"@oneWay\",\"@value\",\"@selectionChanged\"],[[32,5],true,[32,4,[\"value\"]],[32,4,[\"update\"]]]],null],[2,\"\\n    \"]],\"parameters\":[4]}]]]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[30,[36,1],[[32,1],\"selection\"],null]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,3,[\"display_name\"]]],[2,\"\\n    \"]],\"parameters\":[3]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1,2]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"let\",\"-is-named-block-invocation\",\"if\",\"hash\"]}",
    "meta": {
      "moduleName": "plutof/components/plutof-select/multiple/choices.hbs"
    }
  });
  let MultipleChoiceSelect = (_dec = Ember.computed('args.choices.[]'), _dec2 = Ember.computed('args.value.[]', 'choiceMap'), _dec3 = Ember._action, _dec4 = Ember._action, (_class = class MultipleChoiceSelect extends _component.default {
    get choiceMap() {
      const map = {};
      this.args.choices.forEach(choice => {
        map[choice.value] = choice;
      });
      return map;
    }
    get items() {
      return this.args.value.map(value => this.choiceMap[value]);
    }
    add(choice) {
      if (!this.args.value.includes(choice.value)) {
        this.args.value.pushObject(choice.value);
      }
    }
    remove(choice) {
      this.args.value.removeObject(choice.value);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "choiceMap", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "choiceMap"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "items", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "items"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "add", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "add"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "remove", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "remove"), _class.prototype)), _class));
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, MultipleChoiceSelect);
});