define("plutof/templates/components/datacite/alternate-identifier-row-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Y6BP7cKc",
    "block": "{\"symbols\":[\"@identifier\",\"@remove\"],\"statements\":[[10,\"td\"],[15,0,[30,[36,1],[[35,0,[\"attrs\",\"identifier\",\"alternate_identifier\",\"isInvalid\"]],\"has-error\"],null]],[12],[2,\"\\n    \"],[1,[30,[36,2],null,[[\"type\",\"value\",\"class\"],[\"text\",[32,1,[\"alternate_identifier\"]],\"datacite-edit-alternate-identifiers__identifier mini-input\"]]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[15,0,[30,[36,1],[[35,0,[\"attrs\",\"identifier\",\"alternate_identifier_type\",\"isInvalid\"]],\"has-error\"],null]],[12],[2,\"\\n    \"],[1,[30,[36,2],null,[[\"type\",\"value\",\"class\"],[\"text\",[32,1,[\"alternate_identifier_type\"]],\"datacite-edit-alternate-identifiers__identifier-type mini-input\"]]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[14,0,\"data-table-icon-cell align-right\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-link data-table-btn\"],[4,[38,3],[\"click\",[32,2]],null],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"icon-remove--red\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"validations\",\"if\",\"input\",\"on\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/datacite/alternate-identifier-row-form.hbs"
    }
  });
});