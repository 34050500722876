define("plutof/components/login/form-row", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="registration-form-row">
      <div class="registration-form-row__input">
          {{#if (and @isValid (not @isConfirmed))}}
              <div class="login-input-status__warning visible-xs">
                  {{@warning}}
              </div>
          {{/if}}
  
          <Login::FormInput
              @label={{@label}}
              @help={{@help}}
              @value={{@value}}
              @isValid={{and @isValid this._isConfirmed}}
          >
              {{yield}}
          </Login::FormInput>
      </div>
  
      <Login::FormInputStatus
          @isValid={{@isValid}}
          @isConfirmed={{this._isConfirmed}}
          @warning={{@warning}} />
  </div>
  
  */
  {
    "id": "WrGxLzTD",
    "block": "{\"symbols\":[\"@warning\",\"@isConfirmed\",\"@isValid\",\"@label\",\"@help\",\"@value\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"registration-form-row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"registration-form-row__input\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[32,3],[30,[36,0],[[32,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"div\"],[14,0,\"login-input-status__warning visible-xs\"],[12],[2,\"\\n                \"],[1,[32,1]],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n        \"],[8,\"login/form-input\",[],[[\"@label\",\"@help\",\"@value\",\"@isValid\"],[[32,4],[32,5],[32,6],[30,[36,1],[[32,3],[32,0,[\"_isConfirmed\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[18,7,null],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[8,\"login/form-input-status\",[],[[\"@isValid\",\"@isConfirmed\",\"@warning\"],[[32,3],[32,0,[\"_isConfirmed\"]],[32,1]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"not\",\"and\",\"if\"]}",
    "meta": {
      "moduleName": "plutof/components/login/form-row.hbs"
    }
  });
  class LoginFormRow extends _component.default {
    get _isConfirmed() {
      if (Ember.isPresent(this.args.isConfirmed)) {
        return this.args.isConfirmed;
      }
      return this.args.isValid;
    }
  }
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, LoginFormRow);
});