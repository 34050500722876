define("plutof/translations/est/chart", ["exports", "plutof/translations/helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    time: 'aeg',
    count: 'arv',
    year: 'aasta',
    years: 'Aastad',
    quarter: 'kvartal',
    month: 'kuu',
    months: 'Kuud',
    week: 'nädal',
    day: 'päev',
    hour: 'tund',
    minute: 'minut',
    createdAt: (0, _helpers.alias)('Search.createdAt'),
    timespanBegin: 'Kogumise aeg',
    taxonNode: (0, _helpers.alias)('General.taxon'),
    observations: 'Vaatlused',
    specimens: 'Eksemplarid',
    'material-samples': 'Proovid',
    sequences: 'Sekventsid',
    'refbased-occurrences': 'Kirjanduspõhised',
    'living-specimens': 'Eluseksemplarid',
    phenological: 'Fenoloogiline',
    cumulative: 'Kumulatiivne',
    occurrenceInYear: 'Liikide arv ühel aastal',
    cumulativeCount: 'Liikide kumulatiivne arv ',
    district: 'Maakond',
    unspecified: 'Täpsustamata',
    taxa: 'Taksonid',
    period: 'Periood'
  };
});