define("plutof/mixins/display-name-from-value", ["exports", "ember-data", "plutof/misc/options-getter", "plutof/services/ajax"], function (_exports, _emberData, _optionsGetter, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.DisplayNameMixin = void 0;
  _exports.displayName = displayName;
  _exports.displayNames = displayNames;
  // TODO: At the very least, parametrise during Mixin creation:
  // Model.extend(DisplayNameMixin('app/model'))
  // But better to just drop the mixin altogether
  const DisplayNameMixin = _exports.DisplayNameMixin = Ember.Mixin.create({
    ajax: Ember.inject.service(),
    optionTextMixinModel: null,
    optionsTextMixinTexts: Ember.computed('optionMixinModelName', function () {
      return _emberData.default.PromiseObject.create({
        promise: (0, _optionsGetter.getModelTexts)(this.ajax, this.optionMixinModelName)
      });
    })
  });
  function displayName(optionsProperty) {
    return Ember.computed(optionsProperty, `optionsTextMixinTexts.${optionsProperty}`, function () {
      const value = this.get(optionsProperty);
      const texts = this.get(`optionsTextMixinTexts.${optionsProperty}`);
      if (Ember.isNone(texts) || Ember.isEmpty(value)) {
        return null;
      }
      const displayName = texts['choices'].findBy('value', value);
      return displayName ? displayName['display_name'] : null;
    });
  }

  // XXX: This is written in a bullshit way to work with AnnotatedRecord
  // Not for general use (yet! we do need to migrate from DisplayNameMixin)
  function displayNames(model) {
    const optionsProperty = `__${model.replace(/\./g, '/')}-display-names`;
    return function (field) {
      return Ember.computed(field, `${optionsProperty}.${field}`, function () {
        if (!this[optionsProperty]) {
          const ajax = this.ajax || this.emberContainer && this.emberContainer.lookup('service:ajax') || (0, _ajax.ajaxFromControlled)(this);
          Ember.set(this, optionsProperty, {});
          (0, _optionsGetter.getModelTexts)(ajax, model).then(foo => {
            Ember.set(this, optionsProperty, foo);
          });
        }
        const texts = Ember.get(this, `${optionsProperty}.${field}`);
        if (!texts) {
          return null;
        }
        const match = texts.choices.findBy('value', this.get(field));
        return match ? match.display_name : null;
      });
    };
  }
  var _default = _exports.default = DisplayNameMixin;
});