define("plutof/components/experiment/dna/view/pcr-products", ["exports", "plutof/utils/pagination"], function (_exports, _pagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let DNAViewPCRProducts = _exports.default = (_dec = Ember.computed('dna.id'), (_class = class DNAViewPCRProducts extends Ember.Component {
    get queryParams() {
      return {
        dna: this.get('dna.id'),
        ordering: '-id'
      };
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      this.set('pagination', (0, _pagination.paginatePublishingModel)(this.store, 'dna-lab/pcr-product', this.queryParams));
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "queryParams", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "queryParams"), _class.prototype)), _class));
});