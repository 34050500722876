define("plutof/components/publishing/gbif/records/new-record", ["exports", "@ember-decorators/component", "ember-cp-validations", "plutof/mixins/component-validations"], function (_exports, _component, _emberCpValidations, _componentValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <td class="{{if validations.attrs.record.record.isInvalid 'has-error'}}">
      {{#async/bind-relation @record.record as |value update|}}
          <AutoComplete::Generic
              @model={{@type.model}}
              @value={{value}}
              @update={{update}}
              @filters={{hash access="edit"}}
              @mini={{true}} />
      {{/async/bind-relation}}
  </td>
  
  <td colspan="5">
      {{! Nothing here, just aligning with existing record rows !}}
  </td>
  
  <td class="align-right">
      <button
          {{on "click" @remove}}
          class="btn btn-link data-table-btn"
      >
          <span class="icon-remove--red"></span>
      </button>
  </td>
  
  */
  {
    "id": "eZspDUee",
    "block": "{\"symbols\":[\"value\",\"update\",\"@type\",\"@record\",\"@remove\"],\"statements\":[[10,\"td\"],[15,0,[31,[[30,[36,2],[[35,1,[\"attrs\",\"record\",\"record\",\"isInvalid\"]],\"has-error\"],null]]]],[12],[2,\"\\n\"],[6,[37,3],[[32,4,[\"record\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"auto-complete/generic\",[],[[\"@model\",\"@value\",\"@update\",\"@filters\",\"@mini\"],[[32,3,[\"model\"]],[32,1],[32,2],[30,[36,0],null,[[\"access\"],[\"edit\"]]],true]],null],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[13],[2,\"\\n\\n\"],[10,\"td\"],[14,\"colspan\",\"5\"],[12],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[14,0,\"align-right\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-link data-table-btn\"],[4,[38,4],[\"click\",[32,5]],null],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"icon-remove--red\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hash\",\"validations\",\"if\",\"async/bind-relation\",\"on\"]}",
    "meta": {
      "moduleName": "plutof/components/publishing/gbif/records/new-record.hbs"
    }
  });
  const Validations = (0, _emberCpValidations.buildValidations)({
    'record.record': (0, _emberCpValidations.validator)('presence', true)
  });
  let NewRecord = (_dec = (0, _component.tagName)('tr'), _dec(_class = class NewRecord extends Ember.Component.extend(Validations, _componentValidations.default) {}) || _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, NewRecord);
});