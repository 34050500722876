define("plutof/templates/components/information/collapsed-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "2yFj3MdP",
    "block": "{\"symbols\":[\"@label\",\"&default\"],\"statements\":[[11,\"button\"],[16,0,[31,[\"collapsed-section-btn \",[30,[36,0],[[32,0,[\"open\"]],\"collapsed-section-btn--expanded\"],null]]]],[4,[38,1],[\"click\",[32,0,[\"toggle\"]]],null],[12],[2,\"\\n    \"],[1,[32,1]],[2,\"\\n\\n    \"],[10,\"span\"],[15,0,[31,[\"collapsed-section-caret\\n        \",[30,[36,0],[[32,0,[\"open\"]],\"collapsed-section-caret--expanded\"],null]]]],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[15,1,[31,[[32,0,[\"contentId\"]]]]],[15,0,[31,[\"collapsed-section-content \",[30,[36,0],[[32,0,[\"open\"]],\"collapsed-section-content--expanded\"],null]]]],[12],[2,\"\\n    \"],[18,2,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"on\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/information/collapsed-section.hbs"
    }
  });
});