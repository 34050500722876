define("plutof/components/plutof-panel/item-list/content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{! TODO: Render override. }}
  <div
      {{collapse @open}}
      class="item-list-panel-content"
      ...attributes
  >
      <div class={{default-to @panelBodyClass "panel-body"}}>
          {{yield}}
      </div>
  </div>
  
  */
  {
    "id": "a2WbJdGk",
    "block": "{\"symbols\":[\"&attrs\",\"@open\",\"@panelBodyClass\",\"&default\"],\"statements\":[[11,\"div\"],[24,0,\"item-list-panel-content\"],[17,1],[4,[38,0],[[32,2]],null],[12],[2,\"\\n    \"],[10,\"div\"],[15,0,[30,[36,1],[[32,3],\"panel-body\"],null]],[12],[2,\"\\n        \"],[18,4,null],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"collapse\",\"default-to\"]}",
    "meta": {
      "moduleName": "plutof/components/plutof-panel/item-list/content.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});