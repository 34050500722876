define("plutof/templates/components/analysis/analysis-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "asy4HSBl",
    "block": "{\"symbols\":[\"@setType\",\"@name\"],\"statements\":[[8,\"layout/regular-grid\",[],[[\"@columns\"],[3]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[12],[2,\"\\n        \"],[1,[30,[36,0],null,[[\"path\"],[\"run.analysis_type\"]]]],[2,\"\\n\\n        \"],[10,\"div\"],[15,0,[31,[\"control-group \",[30,[36,2],[[35,1,[\"attrs\",\"type\",\"isInvalid\"]],\"has-error\"],null]]]],[12],[2,\"\\n            \"],[1,[30,[36,6],null,[[\"oneWay\",\"content\",\"value\",\"selectionChanged\",\"disabled\",\"class\",\"selectClass\"],[true,[32,0,[\"enabledTypes\"]],[35,5,[\"id\"]],[30,[36,4],[[32,0],\"typeSelected\"],null],[30,[36,3],[[32,1]],null],\"analysis-data__type\",\"form-control default-focus-control\"]]]],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[15,0,[31,[\"control-group \",[30,[36,2],[[35,1,[\"attrs\",\"name\",\"isInvalid\"]],\"has-error\"],null]]]],[12],[2,\"\\n        \"],[1,[30,[36,0],null,[[\"path\"],[\"run.name\"]]]],[2,\"\\n\\n        \"],[1,[30,[36,7],null,[[\"type\",\"value\",\"class\"],[\"text\",[32,2],\"form-control analysis-data__name\"]]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"plutof-labelc\",\"validations\",\"if\",\"not\",\"action\",\"type\",\"plutof-select/value\",\"input\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/analysis/analysis-data.hbs"
    }
  });
});