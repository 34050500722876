define("plutof/templates/specimen/annotation/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Dema8AmD",
    "block": "{\"symbols\":[],\"statements\":[[8,\"annotation/navbar/view\",[],[[\"@icon\",\"@title\",\"@annotation\",\"@permissions\",\"@parentRoute\"],[[30,[36,0],[\"specimen\"],null],[30,[36,1],[\"Specimen.annotation.view\"],null],[32,0,[\"model\",\"annotation\"]],[32,0,[\"model\",\"permissions\"]],\"specimen\"]],null],[2,\"\\n\\n\"],[8,\"annotation/summary\",[],[[\"@annotation\",\"@permissions\"],[[32,0,[\"model\",\"annotation\"]],[32,0,[\"model\",\"permissions\"]]]],null],[2,\"\\n\\n\"],[8,\"specimen/view\",[],[[\"@specimen\",\"@additionalIdentifiers\",\"@accessRights\",\"@contentType\",\"@canModifyDeterminations\"],[[32,0,[\"model\",\"specimen\"]],[32,0,[\"model\",\"additionalIdentifiers\"]],[32,0,[\"model\",\"accessRights\"]],[32,0,[\"model\",\"contentType\"]],false]],null],[2,\"\\n\\n\"],[8,\"linked-items/view\",[],[[\"@object\",\"@disabledTabs\"],[[32,0,[\"model\",\"specimen\"]],[34,2]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\",\"list\"]}",
    "meta": {
      "moduleName": "plutof/templates/specimen/annotation/view.hbs"
    }
  });
});