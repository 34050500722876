define("plutof/components/compound/cluster-list", ["exports", "plutof/components/unite/node-sh-list"], function (_exports, _nodeShList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } // XXX: Never subclass components.
  class ClusterList extends _nodeShList.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "modelPath", 'globalkey/cluster');
      _defineProperty(this, "prefixer", '');
      _defineProperty(this, "pageSize", 50);
    }
  }
  var _default = _exports.default = ClusterList;
});