define("plutof/templates/components/datacite/contributor-row-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "WH3srMws",
    "block": "{\"symbols\":[\"value\",\"update\",\"@contributor\",\"@remove\"],\"statements\":[[10,\"td\"],[15,0,[30,[36,2],[[35,1,[\"attrs\",\"contributor\",\"contributor_name\",\"isInvalid\"]],\"has-error\"],null]],[12],[2,\"\\n\"],[6,[37,3],[[32,3,[\"contributor_name\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"agent/auto-complete\",[[24,\"data-test\",\"agent\"]],[[\"@value\",\"@update\",\"@params\"],[[32,1],[32,2],[30,[36,0],null,[[\"mini\"],[true]]]]],null],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[13],[2,\"\\n\\n\"],[10,\"td\"],[15,0,[31,[\"data-table-short-cell \",[30,[36,2],[[35,1,[\"attrs\",\"contributor\",\"contributor_type\",\"isInvalid\"]],\"has-error\"],null]]]],[12],[2,\"\\n    \"],[8,\"plutof-select/value\",[[24,\"data-test\",\"type\"]],[[\"@content\",\"@value\",\"@selectClass\"],[[32,0,[\"contributorTypes\"]],[32,3,[\"contributor_type\"]],\"mini-input\"]],null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[12],[2,\"\\n    \"],[8,\"flexible-textarea\",[[24,0,\"mini-input\"]],[[\"@value\"],[[34,4,[\"affiliation\"]]]],null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[14,0,\"data-table-icon-cell align-right\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-link data-table-btn\"],[4,[38,5],[\"click\",[32,4]],null],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"icon-remove--red\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hash\",\"validations\",\"if\",\"async/bind-relation\",\"contributor\",\"on\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/datacite/contributor-row-form.hbs"
    }
  });
});