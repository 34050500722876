define("plutof/components/habitat/view-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (get @habitat (concat @type ".id"))}}
      {{#@view.attr @type}}
          {{get @habitat (concat @type ".representation")}}
      {{/@view.attr}}
  {{/if}}
  
  */
  {
    "id": "Gh6LHmKL",
    "block": "{\"symbols\":[\"@type\",\"@habitat\",\"@view\"],\"statements\":[[6,[37,3],[[30,[36,1],[[32,2],[30,[36,0],[[32,1],\".id\"],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[32,3,[\"attr\"]],[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,1],[[32,2],[30,[36,0],[[32,1],\".representation\"],null]],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"concat\",\"get\",\"component\",\"if\"]}",
    "meta": {
      "moduleName": "plutof/components/habitat/view-type.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});