define("plutof/components/photobank/classification-form", ["exports", "ember-data", "@ember-decorators/component", "@ember-decorators/object", "plutof/misc/abstract", "plutof/misc/config", "plutof/utils/store"], function (_exports, _emberData, _component, _object, _abstract, _config, _store) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _class2, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ClassificationForm = (_dec = (0, _component.classNames)('photobank-classification-form'), _dec2 = Ember.inject.service, _dec3 = (0, _component.className)('photobank-classification-form--small', 'photobank-classification-form--big'), _dec4 = (0, _object.observes)('data.classification'), _dec5 = Ember.computed('data.classification', 'smallClassification'), _dec6 = Ember.computed('selectedItems.@each.custom_list_item', 'data.classification'), _dec7 = Ember.computed('data.classification'), _dec8 = Ember.computed('newItem.id', 'selectedItems.@each.custom_list_item'), _dec9 = Ember.computed('newItem', 'newItemAlreadyInList'), _dec10 = Ember._action, _dec11 = Ember._action, _dec(_class = (_class2 = class ClassificationForm extends Ember.Component {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _defineProperty(this, "object", null);
      _defineProperty(this, "selectedItems", null);
      _defineProperty(this, "objectsToDelete", []);
      _defineProperty(this, "newItem", null);
      _defineProperty(this, "newItemRemarks", null);
      // If there is more than one item page, we replace select with autocomplete
      _initializerDefineProperty(this, "smallClassification", _descriptor2, this);
    }
    init() {
      super.init(...arguments);
      this.checkClassificationSize();
    }
    checkClassificationSize() {
      const classification = this.get('data.classification');
      if (Ember.isNone(classification)) {
        this.set('smallClassification', false);
        return;
      }
      const countURL = (0, _abstract.construct_request)('/photobank/customlistitems/count/', {
        custom_classification: classification
      });
      this.ajax.request(countURL).then(response => {
        const small = response.objects_count <= _config.default.Common.PAGE_SIZE;
        (0, _abstract.safe_set)(this, 'smallClassification', small);
      });
    }
    get items() {
      let itemsPromise = (0, _abstract.wrap_as_promise)([]);
      const classification = this.get('data.classification');
      if (this.smallClassification && !Ember.isEmpty(classification)) {
        itemsPromise = (0, _store.findAllFiltered)(this.store, 'photobank/customlistitem', {
          custom_classification: classification
        });
      }
      return _emberData.default.PromiseArray.create({
        promise: itemsPromise
      });
    }
    get selectedClassificationItems() {
      const value = this.get('data.classification');
      return this.selectedItems.filterBy('custom_list_item.custom_classification', value);
    }
    get itemAutocompleteFilters() {
      return {
        classification: this.classification,
        filter_type: 'includes'
      };
    }
    get newItemAlreadyInList() {
      return this.selectedItems.mapBy('custom_list_item.id').includes(this.get('newItem.id'));
    }
    get newItemIsInvalid() {
      return Ember.isNone(this.newItem) || this.newItemAlreadyInList;
    }
    addItem() {
      var item = this.store.createRecord('photobank/customitem', {
        collection_object: this.object,
        custom_list_item: this.newItem,
        remarks: this.newItemRemarks
      });
      this.selectedItems.pushObject(item);
      this.set('newItem', null);
      this.set('newItemRemarks', null);
    }
    removeItem(item) {
      this.selectedItems.removeObject(item);
      this.objectsToDelete.pushObject(item);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "smallClassification", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "checkClassificationSize", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "checkClassificationSize"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "items", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "items"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "selectedClassificationItems", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "selectedClassificationItems"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "itemAutocompleteFilters", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "itemAutocompleteFilters"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "newItemAlreadyInList", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "newItemAlreadyInList"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "newItemIsInvalid", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "newItemIsInvalid"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "addItem", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "addItem"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "removeItem", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "removeItem"), _class2.prototype)), _class2)) || _class);
  var _default = _exports.default = ClassificationForm;
});