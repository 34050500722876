define("plutof/templates/permit/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "SV+ojzNI",
    "block": "{\"symbols\":[],\"statements\":[[8,\"navbar/edit\",[],[[\"@icon\",\"@title\"],[[30,[36,0],[\"permit\"],null],[30,[36,1],[\"permit.edit\"],null]]],null],[2,\"\\n\\n\"],[8,\"collection-lab/permit/edit\",[],[[\"@model\",\"@validationContext\"],[[32,0,[\"model\",\"permit\"]],[32,0,[\"validationContext\"]]]],null],[2,\"\\n\\n\"],[8,\"sticky-bottom-wrapper\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"action-buttons\"],[12],[2,\"\\n        \"],[8,\"common/save-button\",[],[[\"@save\",\"@disabled\"],[[32,0,[\"save\"]],[32,0,[\"validationContext\",\"isInvalid\"]]]],null],[2,\"\\n\\n        \"],[8,\"common/cancel-button\",[],[[\"@cancel\"],[[30,[36,2],[[32,0],\"cancel\"],null]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\",\"action\"]}",
    "meta": {
      "moduleName": "plutof/templates/permit/edit.hbs"
    }
  });
});