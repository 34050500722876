define("plutof/components/common/data-quality/utils", ["exports", "moment", "plutof/misc/config"], function (_exports, moment, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.countValidity = countValidity;
  _exports.countryValidity = countryValidity;
  _exports.gatheringAgentsValidity = gatheringAgentsValidity;
  _exports.geomValidity = geomValidity;
  _exports.taxonRankValidity = taxonRankValidity;
  _exports.timespanAfterCreatedValidity = timespanAfterCreatedValidity;
  _exports.timespanAfterNowValidity = timespanAfterNowValidity;
  const UNSPECIFIED_COUNTRY_NAMES = ['Unspecified', 'Täpsustamata'];
  function countValidity(count) {
    return Ember.computed(count, function () {
      return Ember.isEmpty(Ember.get(this, count)) || Ember.get(this, count) < 200;
    });
  }
  function countryValidity(country) {
    return Ember.computed(country, function () {
      return !UNSPECIFIED_COUNTRY_NAMES.includes(Ember.get(this, country));
    });
  }
  function geomValidity(geom, restricted) {
    return Ember.computed(geom, restricted, function () {
      return Ember.isNone(Ember.get(this, restricted)) || Ember.get(this, restricted) || Ember.isPresent(Ember.get(this, geom));
    });
  }
  function gatheringAgentsValidity(agentsLength) {
    return Ember.computed(agentsLength, function () {
      return Ember.isNone(Ember.get(this, agentsLength)) || Ember.get(this, agentsLength) > 0;
    });
  }
  function taxonRankValidity(taxonRankId) {
    return Ember.computed(taxonRankId, function () {
      return Ember.isEmpty(Ember.get(this, taxonRankId)) || Ember.get(this, taxonRankId) >= _config.default.Taxonomy.ranks.species;
    });
  }
  function timespanAfterCreatedValidity(timespanBegin, createdAt) {
    return Ember.computed(timespanBegin, createdAt, function () {
      const timespanBeginValue = Ember.get(this, timespanBegin);
      const createdAtValue = Ember.get(this, createdAt);
      if (Ember.isEmpty(timespanBeginValue)) {
        return true;
      }
      return moment(timespanBeginValue).isBefore(moment(createdAtValue));
    });
  }
  function timespanAfterNowValidity(timespanBegin) {
    return Ember.computed(timespanBegin, function () {
      const date = moment(Ember.get(this, timespanBegin));
      const now = moment();
      return !date.isAfter(now);
    });
  }
});