define("plutof/translations/eng/error-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    notFoundTitle: 'Problem loading page',
    notFoundMsg: 'Sorry but the page that your are looking for does not exist or access is denied.',
    backHome: 'Back to home.',
    maintenanceInProgress: 'Scheduled server maintenance is in progress'
  };
});