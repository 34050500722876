define("plutof/components/layer/edit", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Layer::GeneralData
      @layer={{@layer}}
      @validationContext={{@validationContext.generalData}} />
  
  <TabGroup
      @selected={{this.selectedTab}}
      @tabSwitched={{this.switchTab}}
  >
      <:tabs as |tabGroup|>
          <Layer::Areas
              @areas={{@areas}}
              @tabGroup={{tabGroup}}
              @validationContext={{@validationContext.areas}} />
  
          <Layer::Map
              @layer={{@layer}}
              @tabGroup={{tabGroup}}
              @updateFilter={{this.updateFilter}} />
      </:tabs>
  </TabGroup>
  
  <StickyBottomWrapper>
      <InvalidPanels @validationContext={{@validationContext}} />
  
      <div class="action-buttons">
          <Common::SaveButton @save={{@save}} @disabled={{@validationContext.isInvalid}} />
          <Common::CancelButton @cancel={{route-action "goBack"}} />
      </div>
  </StickyBottomWrapper>
  
  */
  {
    "id": "EqKKNSKw",
    "block": "{\"symbols\":[\"__arg0\",\"__arg1\",\"tabGroup\",\"@areas\",\"@validationContext\",\"@layer\",\"@save\"],\"statements\":[[8,\"layer/general-data\",[],[[\"@layer\",\"@validationContext\"],[[32,6],[32,5,[\"generalData\"]]]],null],[2,\"\\n\\n\"],[8,\"tab-group\",[],[[\"@selected\",\"@tabSwitched\",\"@namedBlocksInfo\"],[[32,0,[\"selectedTab\"]],[32,0,[\"switchTab\"]],[30,[36,1],null,[[\"tabs\"],[1]]]]],[[\"default\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[32,1],\"tabs\"],null]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"layer/areas\",[],[[\"@areas\",\"@tabGroup\",\"@validationContext\"],[[32,4],[32,3],[32,5,[\"areas\"]]]],null],[2,\"\\n\\n        \"],[8,\"layer/map\",[],[[\"@layer\",\"@tabGroup\",\"@updateFilter\"],[[32,6],[32,3],[32,0,[\"updateFilter\"]]]],null],[2,\"\\n    \"]],\"parameters\":[3]}]]]],\"parameters\":[]}]]]],\"parameters\":[1,2]}]]],[2,\"\\n\\n\"],[8,\"sticky-bottom-wrapper\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"invalid-panels\",[],[[\"@validationContext\"],[[32,5]]],null],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"action-buttons\"],[12],[2,\"\\n        \"],[8,\"common/save-button\",[],[[\"@save\",\"@disabled\"],[[32,7],[32,5,[\"isInvalid\"]]]],null],[2,\"\\n        \"],[8,\"common/cancel-button\",[],[[\"@cancel\"],[[30,[36,4],[\"goBack\"],null]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"let\",\"hash\",\"-is-named-block-invocation\",\"if\",\"route-action\"]}",
    "meta": {
      "moduleName": "plutof/components/layer/edit.hbs"
    }
  });
  let LayerEdit = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, (_class = class LayerEdit extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "i18n", _descriptor, this);
      _initializerDefineProperty(this, "selectedTab", _descriptor2, this);
      this.args.validationContext.addChild('generalData', this.i18n.translate('General.generalData'));
      this.args.validationContext.addChild('areas', this.i18n.translate('Layer.tabs.areas'));
    }
    updateFilter(filter) {
      this.args.areas.filter = filter;
      this.args.areas.updateFilter();
      this.switchTab('areas');
    }
    switchTab(tabName) {
      this.selectedTab = tabName;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "i18n", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "selectedTab", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'map';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "updateFilter", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "updateFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "switchTab", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "switchTab"), _class.prototype)), _class));
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, LayerEdit);
});