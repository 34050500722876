define("plutof/components/x-select", ["exports", "emberx-select/components/x-select"], function (_exports, _xSelect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PatchedXSelect extends _xSelect.default {
    // Not sure why this mess is needed (not our invention, x-select has the same, we override just
    // the action property iirc)
    __setDefaultValues() {
      if (this.skipDefault) {
        return;
      }
      const canSet = !this.isDestroying && !this.isDestroyed;
      if (canSet && this.action && Ember.isNone(this.value)) {
        this.action(this._getValue());
      }
    }
    change(event) {
      const nextValue = this._getValue();
      if (this.action) {
        this.action(nextValue, event, this);
      }
      this._handleAction('on-change', nextValue, event);
    }
  }
  var _default = _exports.default = PatchedXSelect;
});