define("plutof/components/referencebased/refbased-table", ["exports", "@ember-decorators/component", "plutof/components/linked-items/-edit/files", "plutof/models/taxonoccurrence/referencebased/speciesstatus"], function (_exports, _component, _files, _speciesstatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createRefbasedTableData = createRefbasedTableData;
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class RefbasedTableEntry {
    constructor({
      occurrence,
      biostatus,
      linkedFiles
    }) {
      this.occurrence = occurrence;
      this.biostatus = biostatus;
      this.linkedFiles = linkedFiles;
    }
    get biostatusIsEmpty() {
      return _speciesstatus.FIELDS.map(field => this.biostatus.get(field)).every(Ember.isEmpty);
    }
    async save({
      reference,
      access,
      sample
    }) {
      if (!this.biostatusIsEmpty) {
        await this.biostatus.save();
      }
      this.occurrence.setProperties({
        reference,
        species_status: this.biostatus
      });
      sample.apply(this.occurrence);
      await this.occurrence.save();
      await this.linkedFiles.save(this.occurrence, access);
      await access.saveWithTarget(this.occurrence);
    }
  }
  class RefbasedTableData {
    constructor({
      store,
      fileUpload
    }) {
      _defineProperty(this, "entries", []);
      this.store = store;
      this.fileUpload = fileUpload;
    }
    add() {
      const occurrence = this.store.createRecord('taxonoccurrence/referencebased/occurrence');
      this.entries.pushObject(new RefbasedTableEntry({
        occurrence,
        biostatus: this.store.createRecord('taxonoccurrence/referencebased/speciesstatus'),
        linkedFiles: (0, _files.createFileItemsModel)(this.store)
      }));
    }
    remove(entry) {
      this.entries.removeObject(entry);
    }
    save(sharedFields) {
      return Ember.RSVP.all(this.entries.map(entry => entry.save(sharedFields)));
    }
  }
  function createRefbasedTableData({
    store,
    fileUpload
  }) {
    const data = new RefbasedTableData({
      store,
      fileUpload
    });
    data.add();
    return data;
  }
  let RefbasedTable = (_dec = (0, _component.classNames)('refbased-table'), _dec2 = Ember.computed.gt('data.entries.length', 1), _dec3 = Ember._action, _dec4 = Ember._action, _dec(_class = (_class2 = class RefbasedTable extends Ember.Component {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "canRemove", _descriptor, this);
    }
    add() {
      this.data.add();
    }
    remove(entry) {
      this.data.remove(entry);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "canRemove", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "add", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "add"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "remove", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "remove"), _class2.prototype)), _class2)) || _class);
  var _default = _exports.default = RefbasedTable;
});