define("plutof/routes/experiment/pcr/add", ["exports", "plutof/components/experiment/utils", "plutof/components/linked-items/edit", "plutof/mixins/add-route", "plutof/utils/access"], function (_exports, _utils, _edit, _addRoute, _access) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let PCRAddRoute = (_dec = Ember.inject.service, (_class = class PCRAddRoute extends Ember.Route.extend(_addRoute.default) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "settings", _descriptor, this);
      _defineProperty(this, "defaultReturnRoute", 'experiment.pcr.index');
      _defineProperty(this, "title", 'experiment.pcr.routeTitles.add');
    }
    async model(params) {
      const experiment = await this.store.createRecord('dna-lab/pcr');
      const [linkedItemsData, accessRights] = await Ember.RSVP.all([(0, _edit.createLinkedItemsData)(this.store, experiment, {
        useEventFiles: false
      }), (0, _access.create_access_rights)(this.store, experiment)]);
      let materials = [];
      if (Ember.isPresent(params.materials)) {
        materials = await (0, _utils.makeMaterialsWithSource)({
          store: this.store,
          sourceKey: 'dna',
          sourceIds: params.materials.split(','),
          model: 'dna-lab/pcr-product',
          properties: {
            pcr: experiment
          }
        });
      } else {
        materials = await (0, _utils.makeMaterialsWithOffset)({
          store: this.store,
          count: 8,
          offset: 0,
          model: 'dna-lab/pcr-product',
          properties: {
            pcr: experiment
          }
        });
      }
      const lab = await (Ember.isPresent(params.lab) ? this.store.findRecord('agent/organization', params.lab) : this.settings.wait().then(profile => profile.laboratory));
      experiment.set('laboratory', lab);
      return {
        experiment,
        materials,
        linkedItemsData,
        accessRights
      };
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "settings", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  var _default = _exports.default = PCRAddRoute;
});