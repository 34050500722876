define("plutof/templates/components/analysis/wrapper-source", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "59Svk5lG",
    "block": "{\"symbols\":[\"grid\",\"@data\"],\"statements\":[[8,\"plutof-panel/simple\",[],[[\"@title\"],[[30,[36,0],[\"Analysis.sourceData\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"layout/regular-grid\",[],[[\"@columns\"],[3]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"div\"],[15,0,[31,[[30,[36,2],[[32,2,[\"present\"]],\"has-error\"],null]]]],[12],[2,\"\\n            \"],[1,[30,[36,1],[\"Analysis.fileLabel\"],null]],[2,\"\\n\\n            \"],[8,\"filerepository/select-or-upload\",[],[[\"@model\",\"@disabled\"],[[32,2,[\"source\"]],[32,2,[\"fasta_in_text\"]]]],null],[2,\"\\n        \"],[13],[2,\"\\n\\n\"],[6,[37,3],[[32,1,[\"span\"]],2],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,1],[\"Analysis.fastaTextLabel\"],null]],[2,\"\\n\\n            \"],[8,\"flexible-textarea\",[[24,0,\"form-control analysis-data__fasta-in-text\"],[16,\"disabled\",[32,2,[\"source\",\"present\"]]]],[[\"@value\",\"@rows\"],[[32,2,[\"fasta_in_text\"]],5]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"],[6,[37,2],[[32,2,[\"fileSizeIsAcceptable\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"failed-check-message\"],[12],[2,\"\\n            \"],[1,[30,[36,0],[\"Analysis.fileTooBigInfo\"],null]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"common/local-label\",\"unless\",\"component\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/analysis/wrapper-source.hbs"
    }
  });
});