define("plutof/utils/exif", ["exports", "exifr", "moment", "ol/format", "ol/geom", "ol/geom/Polygon", "plutof/misc/abstract", "plutof/misc/config", "plutof/misc/fileupload", "plutof/models/filerepository/file", "plutof/utils/coordinates", "plutof/utils/formatters", "plutof/utils/map"], function (_exports, _exifr, moment, _format, _geom, _Polygon, _abstract, _config, _fileupload, _file, _coordinates, _formatters, _map) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.extractMediaData = extractMediaData;
  _exports.fillSampleFromEXIF = fillSampleFromEXIF;
  _exports.loadEXIFTags = loadEXIFTags;
  async function loadEXIFTags(source) {
    const result = await _exifr.default.parse(source);
    if (result) {
      return result;
    }
    return {};
  }

  // The only way exif-js receives image data (beside File object) -_-
  function loadImageElement(url) {
    return new Ember.RSVP.Promise((resolve, reject) => {
      const image = window.document.createElement('img');
      function loaded() {
        image.removeEventListener('load', loaded);
        resolve(image);
      }
      image.addEventListener('load', loaded);
      image.addEventListener('error', error => {
        reject(error);
      });
      image.src = url;
    });
  }
  const EXIF_DATETIME_FORMAT = 'YYYY:MM:DD HH:mm:SS';
  function parseEXIFCoord(coord, direction) {
    const [degrees, minutes, seconds] = coord.invoke('toString').map(part => parseFloat(part));
    let value = (0, _coordinates.DMSToDecimal)({
      degrees,
      minutes,
      seconds
    });
    if (direction === 'S' || direction === 'W') {
      value = -value;
    }
    return value;
  }
  async function extractMediaData(fileRecord) {
    if (!(0, _fileupload.shouldAnalyze)(fileRecord) || fileRecord.type !== _file.FileType.Image) {
      return {};
    }

    // If we've just uploaded this file in this session, don't want to have to re-download it
    // just to get EXIF data. _localFile hack is meh, TODO think of a better way
    // TODO: Actually set _localFile
    let source = fileRecord._localFile;
    if (!source) {
      // File from a previous session
      const url = fileRecord.download_links.link;
      source = await loadImageElement(url);
    }
    const tags = await loadEXIFTags(source);
    let mediaData = {};
    const datetimes = [tags['DateTime'], tags['DateTimeOriginal'], tags['DateTimeDigitized']].compact().map(dt => moment(dt, EXIF_DATETIME_FORMAT));
    if (datetimes.length > 0) {
      mediaData['datetime'] = moment.min(datetimes).format(_formatters.DEFAULT_DATETIME_FORMAT);
    }
    if (Ember.isPresent(tags['GPSLatitude']) && Ember.isPresent(tags['GPSLatitudeRef'])) {
      mediaData['latitude'] = parseEXIFCoord(tags['GPSLatitude'], tags['GPSLatitudeRef']);
    }
    if (Ember.isPresent(tags['GPSLongitude']) && Ember.isPresent(tags['GPSLongitudeRef'])) {
      mediaData['longitude'] = parseEXIFCoord(tags['GPSLongitude'], tags['GPSLongitudeRef']);
    }
    return mediaData;
  }
  function geoqueryArea(store, geocoding, area, geometry) {
    const [lon, lat] = (0, _map.getGeometryCenter)(geometry);
    return (0, _map.geoqueryCountry)(store, lon, lat).then(function (country) {
      area.set('country', country);
      area.setProperties({
        longitude: (0, _abstract.decimal_round)(lon, _config.default.Sample.COORDINATE_DECIMAL_PLACES).toString(),
        latitude: (0, _abstract.decimal_round)(lat, _config.default.Sample.COORDINATE_DECIMAL_PLACES).toString(),
        country: country,
        method: 'GPS'
      });
      return geocoding.resolve(lon, lat).then(function (areaData) {
        if (!Ember.isNone(areaData)) {
          if (Ember.isPresent(country)) {
            // geocoding country result is just a hint, usually missing
            delete areaData.country;
          }
          area.setProperties(areaData);
        }
      });
    });
  }
  async function fillSampleFromEXIF(store, geocoding, sample, files) {
    // TODO: Fill area/event fields (if multiple datetimes, create a timespan)
    const {
      area,
      event
    } = sample;
    const metadata = await Ember.RSVP.all(files.map(extractMediaData)).then(function (metadata) {
      metadata = metadata.reject(Ember.isNone);
      const collected = {};
      metadata.forEach(function (fields) {
        Object.keys(fields).forEach(function (key) {
          if (Ember.isPresent(fields[key])) {
            if (Ember.isNone(collected[key])) {
              collected[key] = [];
            }
            collected[key].push(fields[key]);
          }
        });
      });
      return collected;
    });

    // Event timespan
    if (event.get('isNew') && !Ember.isEmpty(metadata['datetime'])) {
      const times = metadata['datetime'].map(dt => moment(dt));
      const timespan_begin = moment.min(times).format(_formatters.DEFAULT_DATETIME_FORMAT);
      const timespan_end = moment.max(times).format(_formatters.DEFAULT_DATETIME_FORMAT);
      event.set('timespan_begin', timespan_begin);
      if (timespan_end !== timespan_begin) {
        event.set('timespan_end', timespan_end);
      }
    }

    // Area geom
    if (area.get('isNew') && !Ember.isEmpty(metadata['latitude']) && !Ember.isEmpty(metadata['longitude'])) {
      const COORDINATE_DECIMAL_PLACES = _config.default.Sample.COORDINATE_DECIMAL_PLACES;
      var geometry;

      // Should be AND but this protects agains possible wierdness where only one of
      // coordinates is present
      if (metadata['latitude'].length === 1 || metadata['longitude'].length === 1) {
        let lat = (0, _abstract.decimal_round)(metadata['latitude'][0], COORDINATE_DECIMAL_PLACES);
        let lon = (0, _abstract.decimal_round)(metadata['longitude'][0], COORDINATE_DECIMAL_PLACES);
        area.set('latitude', lat);
        area.set('longitude', lon);
        geometry = new _geom.Point([lon, lat], 'XY');
      } else {
        // If there are more than one geocoded file, set area geom to a box
        // that includes them all
        const minlat = Math.min.apply(Math, metadata['latitude']);
        const maxlat = Math.max.apply(Math, metadata['latitude']);
        const minlon = Math.min.apply(Math, metadata['longitude']);
        const maxlon = Math.max.apply(Math, metadata['longitude']);
        const extent = [minlon, minlat, maxlon, maxlat].map(function (coord) {
          return (0, _abstract.decimal_round)(coord, COORDINATE_DECIMAL_PLACES);
        });
        geometry = (0, _Polygon.fromExtent)(extent);
      }
      var center = (0, _map.getGeometryCenter)(geometry);

      // TODO: Wait for this
      await (0, _map.geoqueryCountry)(store, center[0], center[1]).then(function (country) {
        area.set('country', country);
      });
      const format = new _format.WKT();
      area.set('geom', format.writeGeometry(geometry));
      await geoqueryArea(store, geocoding, area, geometry);
    }
    return sample;
  }
});