define("plutof/components/wrapped-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="plutof-tooltip-hover wrapped-text">
      <div class="wrapped-text__text">
          {{@text}}
      </div>
  
      <div class="wrapped-text__tooltip {{if @breakWord 'wrapped-text__tooltip--break'}}">
          <span class="plutof-tooltip">
              {{@text}}
          </span>
      </div>
  </div>
  
  */
  {
    "id": "ZXisfmui",
    "block": "{\"symbols\":[\"@text\",\"@breakWord\"],\"statements\":[[10,\"div\"],[14,0,\"plutof-tooltip-hover wrapped-text\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"wrapped-text__text\"],[12],[2,\"\\n        \"],[1,[32,1]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[15,0,[31,[\"wrapped-text__tooltip \",[30,[36,0],[[32,2],\"wrapped-text__tooltip--break\"],null]]]],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"plutof-tooltip\"],[12],[2,\"\\n            \"],[1,[32,1]],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "plutof/components/wrapped-text.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});