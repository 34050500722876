define("plutof/mixins/search-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // XXX TODO: No longer necessary
  const SearchFilterMixin = Ember.Mixin.create({});
  var _default = _exports.default = SearchFilterMixin;
});