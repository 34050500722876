define("plutof/utils/routes", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "plutof/mixins/route", "plutof/mixins/add-route", "plutof/utils/validations"], function (_exports, _authenticatedRouteMixin, _route, _addRoute, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ViewRoute = _exports.Route = _exports.ListRoute = _exports.EditRoute = _exports.AddRoute = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class Route extends Ember.Route.extend(_authenticatedRouteMixin.default, _route.default) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "searchModule", null);
    }
    buildRouteInfoMetadata() {
      const meta = super.buildRouteInfoMetadata() || {};
      meta.searchModule = this.searchModule;
      return meta;
    }
  }
  _exports.Route = Route;
  class ListRoute extends Route {}

  // TODO: Just move AddRouteMixin contents here
  _exports.ListRoute = ListRoute;
  class AddRoute extends Route.extend(_addRoute.default) {}
  _exports.AddRoute = AddRoute;
  class ViewRoute extends Route {}
  _exports.ViewRoute = ViewRoute;
  class EditRoute extends Route.extend(_validations.ValidatedRoute) {}
  _exports.EditRoute = EditRoute;
});