define("plutof/components/search/map", ["exports", "ol/Feature", "ol/geom/Polygon", "ol/source/ImageWMS", "ol/source/TileWMS", "ol/source/Vector", "ol/layer/Image", "ol/layer/Tile", "ol/layer/Vector", "ol/proj", "ol/style", "ember-concurrency", "latlon-geohash", "plutof/components/map/view-map", "plutof/config/environment", "plutof/misc/config"], function (_exports, _Feature, _Polygon, _ImageWMS, _TileWMS, _Vector, _Image, _Tile, _Vector2, _proj, _style, _emberConcurrency, _latlonGeohash, _viewMap, _environment, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const WMS_RESULT_LIMIT = 25000;
  const WMS_USE_TILES = false;
  class ImageNonWMS extends _ImageWMS.default {
    constructor(wmsoptions, searchTextQuery) {
      super(wmsoptions);
      this.searchTextQuery = searchTextQuery;
    }
    getRequestUrl_(...args) {
      const wmsurl = new URL(super.getRequestUrl_(...args));
      const wrappedURL = new URL(wmsurl.origin + wmsurl.pathname);
      for (const [param, value] of wmsurl.searchParams.entries()) {
        wrappedURL.searchParams.set(`wms:${param}`, value);
      }
      return `${wrappedURL}&${this.searchTextQuery}`;
    }
  }
  class SearchMapSourceTiled extends _TileWMS.default {
    constructor(wmsoptions, searchTextQuery) {
      super(wmsoptions);
      this.searchTextQuery = searchTextQuery;
    }
    getRequestUrl_(...args) {
      const wmsurl = new URL(super.getRequestUrl_(...args));
      const wrappedURL = new URL(wmsurl.origin + wmsurl.pathname);
      for (const [param, value] of wmsurl.searchParams.entries()) {
        wrappedURL.searchParams.set(`wms:${param}`, value);
      }
      return `${wrappedURL}&${this.searchTextQuery}`;
    }
  }
  let SearchWMSMap = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = (0, _emberConcurrency.task)({
    restartable: true
  }), (_class = class SearchWMSMap extends _viewMap.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "layoutName", 'components/map/base-map');
      _defineProperty(this, "popupEnabled", true);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _defineProperty(this, "wmsSource", null);
      _initializerDefineProperty(this, "wmsSelection", _descriptor2, this);
      _defineProperty(this, "gridSource", null);
      _initializerDefineProperty(this, "resultCount", _descriptor3, this);
      _defineProperty(this, "usingGrid", true);
      _initializerDefineProperty(this, "reloadGrid", _descriptor4, this);
    }
    didUpdateAttrs() {
      super.didUpdateAttrs();
      this.totalCount = this.totalResults;
      this.resultCount = this.totalResults;
      this.reinitializeMap();
    }
    get useGrid() {
      // The entire result set is small enough, no need for a grid whatever the zoom level is
      if (!Ember.isNone(this.totalResults) && this.totalResults <= WMS_RESULT_LIMIT) {
        return false;
      }
      return Ember.isNone(this.resultCount) || this.resultCount > WMS_RESULT_LIMIT;
    }
    createCommonLayers() {
      let layers = super.createCommonLayers();
      let layer;
      if (this.useGrid) {
        this.gridSource = new _Vector.default();
        layer = new _Vector2.default({
          source: this.gridSource,
          style: feature => {
            const fill = new _style.Fill({
              color: [255, 0, 0, 0.2]
            });
            const stroke = new _style.Stroke({
              color: [255, 0, 0],
              width: 1
            });
            return new _style.Style({
              fill,
              stroke,
              text: new _style.Text({
                text: feature.getProperties().count.toString(),
                stroke: new _style.Stroke({
                  color: [0, 0, 0],
                  width: 2
                }),
                fill: new _style.Fill({
                  color: [255, 255, 255]
                })
              })
            });
          }
        });
      } else if (WMS_USE_TILES) {
        // Tiles, unfortunately, lead to tile-edge artifacts, at least because of
        // serverside clustering. But there is a performance benefit
        this.wmsSource = new SearchMapSourceTiled({
          url: `${_environment.default.API_HOST}/${this.module.value}/search/wms/`,
          params: {
            'LAYERS': 'set-by-server' // WMS endpoint selects the layer itself
          }
        }, this.textquery);
        layer = new _Tile.default({
          source: this.wmsSource
        });
      } else {
        this.wmsSource = new ImageNonWMS({
          url: `${_environment.default.API_HOST}/${this.module.value}/search/wms/`,
          params: {
            'LAYERS': 'set-by-server' // WMS endpoint selects the layer itself
          }
        }, this.textquery);
        layer = new _Image.default({
          source: this.wmsSource
        });
      }
      this.usingGrid = this.useGrid;
      return layers.concat(layer);
    }
    createMap(...args) {
      super.createMap(...args);
      this.addEventListener(this.map, 'singleclick', event => this.handleClick(event));
      this.addEventListener(this.view, 'change:center', event => {
        if (this.useGrid) {
          this.resultCount = undefined;
          this.reloadGrid.perform();
        }
      });
      this.addEventListener(this.view, 'change:resolution', event => {
        if (this.useGrid) {
          this.reloadGrid.perform();
        } else if (this.totalResults > WMS_RESULT_LIMIT) {
          // TODO: On zoom out, switch to grid, depedning on totalResults
          const zoomOut = event.oldValue < event.target.getResolution();
          if (zoomOut) {
            this.resultCount = undefined;
            this.reloadGrid.perform();
          }
        }
      });
      this.reloadGrid.perform();
    }
    // TODO: Don't recreate entire map in those?
    switchToWMS() {
      if (this.usingGrid) {
        this.reinitializeMap();
        this.usingGrid = false;
      }
    }
    switchToGrid() {
      if (!this.usingGrid) {
        this.reinitializeMap();
        this.usingGrid = true;
      }
    }
    getGridPrecisionForCurrentZoom() {
      // Would probably be better to check resolution, but in any case it's not an exact science
      const zoom = this.view.getZoom();
      if (zoom < 7) {
        return 3;
      } else if (zoom < 10) {
        return 4;
      } else {
        return 5;
      }
    }
    handleClick(event) {
      if (this.usingGrid) {
        // TODO: Zoom to clicked grid rect?
      } else {
        this.getFeatureInfo(event);
      }
    }
    async getFeatureInfo(event) {
      const clusterURL = this.wmsSource.getFeatureInfoUrl(event.coordinate, this.view.getResolution(), this.internalProjection, {
        'INFO_FORMAT': 'application/json'
      });
      const clusterQueryResponse = await this.ajax.request(clusterURL);
      if (clusterQueryResponse.features.length === 0) {
        this.hidePopup();
        return;
      }
      const cluster = clusterQueryResponse.features[0];
      if (cluster.properties.countunique > 1) {
        // Cluster: zoom into it
        // Cluster info also has geomBBOX GeoJSON geometry, but it's wrong (coordinates shuffled around,
        // some are even (lon, lon) etc)
        const envBBOX = cluster.properties.envBBOX;
        const bboxCoords = envBBOX.slice(4, envBBOX.length - 1).split(',').map(p => p.split(' : ').map(c => parseFloat(c)));
        const [[minlon, minlat], [maxlon, maxlat]] = bboxCoords;
        const extent = [minlon, minlat, maxlon, maxlat];
        const transformedExtent = (0, _proj.transformExtent)(extent, 'EPSG:4326', this.internalProjection);
        this.view.fit(transformedExtent, {
          maxZoom: 12,
          duration: 100
        });
      } else {
        // Single point: get its unclustered info
        const pointQueryURL = new URL(clusterURL);
        pointQueryURL.searchParams.set('wms:STYLES', 'occurrences');
        const pointQueryResponse = await this.ajax.request(pointQueryURL.toString());
        if (pointQueryResponse.features.length > 0) {
          this.showPopup(event.coordinate);
        } else {
          this.hidePopup();
        }
        this.wmsFeaturesSelected(pointQueryResponse.features.map(feature => {
          const [longitude, latitude] = this.mapToGeoCoords(feature.geometry.coordinates);
          return {
            longitude: longitude.toFixed(_config.default.Sample.COORDINATE_DECIMAL_PLACES),
            latitude: latitude.toFixed(_config.default.Sample.COORDINATE_DECIMAL_PLACES),
            fields: feature.properties
          };
        }));
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "wmsSelection", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "resultCount", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "reloadGrid", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        yield (0, _emberConcurrency.timeout)(200);
        const internalExtent = this.view.calculateExtent(this.map.getSize());
        const externalExtent = (0, _proj.transformExtent)(internalExtent, this.internalProjection, 'EPSG:4326');
        const {
          count,
          grid
        } = yield this.ajax.request(`${_environment.default.API_HOST}/${this.module.value}/search/grid/?${this.textquery}`, {
          data: {
            bbox: externalExtent.join(','),
            precision: this.getGridPrecisionForCurrentZoom()
          }
        });
        this.resultCount = count;
        if (this.useGrid) {
          this.switchToGrid();
          const features = grid.map(({
            key,
            count
          }) => {
            const bounds = _latlonGeohash.default.bounds(key);
            const externalBounds = [bounds.sw.lon, bounds.sw.lat, bounds.ne.lon, bounds.ne.lat];
            const [xmin, ymin, xmax, ymax] = (0, _proj.transformExtent)(externalBounds, 'EPSG:4326', this.internalProjection);
            const geometry = new _Polygon.default([[[xmin, ymin], [xmin, ymax], [xmax, ymax], [xmax, ymin], [xmin, ymin]]]);
            const feature = new _Feature.default({
              geometry,
              geohash: key,
              count
            });
            return feature;
          });
          this.gridSource.clear();
          this.gridSource.addFeatures(features);
        } else {
          this.switchToWMS();
        }
      };
    }
  })), _class));
  var _default = _exports.default = SearchWMSMap;
});