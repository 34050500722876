define("plutof/templates/components/interaction/target-type-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "HxzwaCJV",
    "block": "{\"symbols\":[\"type\",\"@selection\"],\"statements\":[[10,\"div\"],[14,0,\"interaction-radio-buttons-container\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"types\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"label\"],[14,0,\"radio-btn-label\"],[12],[2,\"\\n            \"],[8,\"radio-button\",[[16,0,[31,[\"edit-interactions__interaction__object-type edit-interactions__interaction__object-type--\",[32,1,[\"marker\"]]]]]],[[\"@selection\",\"@option\"],[[32,2],[32,1]]],null],[2,\"\\n\\n            \"],[1,[30,[36,0],[[32,1,[\"label\"]]],null]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/interaction/target-type-selector.hbs"
    }
  });
});