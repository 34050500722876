define("plutof/templates/gis-lab/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "og9eeUpU",
    "block": "{\"symbols\":[\"hub\"],\"statements\":[[8,\"test/route-marker\",[],[[\"@route\"],[\"gis-lab.index\"]],null],[2,\"\\n\\n\"],[8,\"layout/module-hub\",[],[[\"@icon\",\"@title\"],[[30,[36,0],[\"gis-lab\"],null],[30,[36,1],[\"General.gisLab\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"layout/regular-grid\",[],[[\"@columns\"],[3]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,1,[\"link\"]],[],[[\"@title\",\"@description\",\"@buttonTitle\",\"@route\",\"@icon\"],[[30,[36,1],[\"Sample.area.areasTitle\"],null],[30,[36,1],[\"Sample.area.areasInfo\"],null],[30,[36,1],[\"Sample.area.areasButton\"],null],\"area.index\",[30,[36,0],[\"area\"],null]]],null],[2,\"\\n\\n        \"],[8,[32,1,[\"link\"]],[],[[\"@title\",\"@description\",\"@buttonTitle\",\"@route\",\"@icon\"],[[30,[36,1],[\"GisLab.moduleHub.import.title\"],null],[30,[36,1],[\"GisLab.moduleHub.import.info\"],null],[30,[36,1],[\"GisLab.moduleHub.import.button\"],null],\"gis-lab.import\",[30,[36,0],[\"area\"],null]]],null],[2,\"\\n\\n        \"],[8,[32,1,[\"link\"]],[],[[\"@title\",\"@description\",\"@buttonTitle\",\"@route\",\"@icon\"],[[30,[36,1],[\"GisLab.moduleHub.layers.title\"],null],[30,[36,1],[\"GisLab.moduleHub.layers.info\"],null],[30,[36,1],[\"GisLab.moduleHub.layers.button\"],null],\"layer.index\",[30,[36,0],[\"area\"],null]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\"]}",
    "meta": {
      "moduleName": "plutof/templates/gis-lab/index.hbs"
    }
  });
});