define("plutof/templates/components/unite/its-sequence", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "uy5RFr3/",
    "block": "{\"symbols\":[\"base\"],\"statements\":[[6,[37,5],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],[[\"key\"],[\"@index\"]],[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"td\"],[15,0,[31,[\"sequence-base sequence-base-\",[32,1]]]],[12],[1,[32,1]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"td\"],[12],[2,\"\\n        \"],[1,[34,0]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"sequence\",\"bases\",\"-track-array\",\"each\",\"colorize\",\"if\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/unite/its-sequence.hbs"
    }
  });
});