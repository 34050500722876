define("plutof/authenticators/access-token", ["exports", "ember-simple-auth/authenticators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Last step of the social auth flow
  class AccessTokenAuthenticator extends _base.default {
    authenticate(access_token) {
      return Ember.RSVP.Promise.resolve({
        access_token
      });
    }
    restore(data) {
      return Ember.RSVP.Promise.resolve(data);
    }
  }
  var _default = _exports.default = AccessTokenAuthenticator;
});