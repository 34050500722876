define("plutof/templates/components/filerepository/file-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Lcs1/Y4u",
    "block": "{\"symbols\":[\"@value\",\"@update\"],\"statements\":[[8,\"filerepository/auto-complete\",[],[[\"@value\",\"@update\",\"@access\",\"@localSelected\",\"@localSelectSingle\"],[[32,1],[32,2],\"edit\",[32,0,[\"upload\"]],true]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "plutof/templates/components/filerepository/file-selector.hbs"
    }
  });
});