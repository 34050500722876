define("plutof/components/search/filters/number-range-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Search::Filters::-RangeInput
      @filter={{@filter}}
      @inputGroupClass="range-filter-group"
  >
      <:lower>
          <Input
              @type="number"
              @value={{@filter.lowerBound}}
              class="form-control" />
      </:lower>
  
      <:upper as |disabled|>
          <Input
              @type="number"
              @value={{@filter.upperBound}}
              @disabled={{disabled}}
              class="form-control" />
      </:upper>
  </Search::Filters::-RangeInput>
  
  */
  {
    "id": "ZzmYMnOO",
    "block": "{\"symbols\":[\"__arg0\",\"__arg1\",\"disabled\",\"@filter\"],\"statements\":[[8,\"search/filters/-range-input\",[],[[\"@filter\",\"@inputGroupClass\",\"@namedBlocksInfo\"],[[32,4],\"range-filter-group\",[30,[36,3],null,[[\"lower\",\"upper\"],[0,1]]]]],[[\"default\"],[{\"statements\":[[6,[37,2],[[30,[36,1],[[32,1],\"lower\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n        \"],[8,\"input\",[[24,0,\"form-control\"]],[[\"@type\",\"@value\"],[\"number\",[32,4,[\"lowerBound\"]]]],null],[2,\"\\n    \"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[30,[36,1],[[32,1],\"upper\"],null]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"input\",[[24,0,\"form-control\"]],[[\"@type\",\"@value\",\"@disabled\"],[\"number\",[32,4,[\"upperBound\"]],[32,3]]],null],[2,\"\\n    \"]],\"parameters\":[3]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1,2]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"let\",\"-is-named-block-invocation\",\"if\",\"hash\"]}",
    "meta": {
      "moduleName": "plutof/components/search/filters/number-range-input.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});