define("plutof/controllers/unite/view", ["exports", "ember-concurrency", "@ember-decorators/object", "plutof/misc/abstract", "plutof/misc/fileupload", "plutof/utils/notifications", "plutof/utils/push-to-store", "plutof/utils/store", "plutof/services/ajax"], function (_exports, _emberConcurrency, _object, _abstract, _fileupload, _notifications, _pushToStore, _store, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.downloadClusterFASTA = downloadClusterFASTA;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function downloadClusterFASTA(store, url) {
    const ajax = (0, _ajax.ajaxFromControlled)(store);
    return ajax.request(url).then(response => {
      const file = (0, _pushToStore.default)(store, 'filerepository/file', response);
      return (0, _fileupload.downloadFile)(file.download_links.link);
    }).catch(_notifications.reportError);
  }
  let ViewClusterController = (_dec = Ember.computed('model.dshcluster.code', '_loadTaxon.last.value'), _dec2 = (0, _object.observes)('model.dshcluster'), _dec3 = (0, _emberConcurrency.task)({
    restartable: true
  }), _dec4 = Ember._action, (_class = class ViewClusterController extends Ember.Controller {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "permissions", {
        canModify: false,
        canDelete: false
      });
      _initializerDefineProperty(this, "_loadTaxon", _descriptor, this);
    }
    get title() {
      const code = this.get('model.dshcluster.code');
      const taxon = this.get('_loadTaxon.last.value.representation');
      return taxon ? `${taxon} | ${code}` : code;
    }

    // TODO: This should just be resolved in route then
    loadTaxon() {
      this._loadTaxon.perform(this.model.dshcluster);
    }
    downloadFASTA() {
      const dshclusterURL = this.get('model.dshcluster.url');
      if (Ember.isEmpty(dshclusterURL)) {
        return _abstract.EMPTY_PROMISE;
      }
      return downloadClusterFASTA(this.store, dshclusterURL + 'fasta-file/');
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "title", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "title"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadTaxon", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "loadTaxon"), _class.prototype), _descriptor = _applyDecoratedDescriptor(_class.prototype, "_loadTaxon", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* (sh) {
        if (!sh) {
          return null;
        }
        const trees = yield (0, _store.query)(this.store, 'globalkey/shtaxonomytree', {
          dshcluster: sh.id
        });
        const levels = ['species', 'genus', 'family', 'order', 'class', 'phylum', 'kingdom'];
        const matchedTrees = trees.map(tree => {
          const level = levels.find(level => tree.belongsTo(`dsh_${level}`).id());
          if (!level) {
            return null;
          }
          return {
            tree,
            level,
            order: levels.indexOf(level)
          };
        });
        const bestTree = matchedTrees.compact().sortBy('order')[0];
        if (!bestTree) {
          return null;
        }
        return yield bestTree.tree.get(`dsh_${bestTree.level}`);
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "downloadFASTA", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "downloadFASTA"), _class.prototype)), _class));
  var _default = _exports.default = ViewClusterController;
});