define("plutof/components/experiment/common/record-metadata", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::Simple @title={{i18n-t "General.access"}}>
      <Access::ViewRightsBase as |vr|>
          <vr.panel @highlight={{true}}>
              <vr.column>
                  {{plutof-labelc path="agent.organization.managing_group"}}
  
                  {{#link-to "profile.groups.group" @record.laboratory.managing_group.id}}
                      {{@record.laboratory.managing_group.representation}}
                  {{/link-to}}
              </vr.column>
  
              <vr.history
                  @createdBy={{@record.created_by.representation}}
                  @createdAt={{@record.created_at}}
                  @updatedBy={{@record.updated_by.representation}}
                  @updatedAt={{@record.updated_at}} />
          </vr.panel>
      </Access::ViewRightsBase>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "81mSbwZh",
    "block": "{\"symbols\":[\"vr\",\"@record\"],\"statements\":[[8,\"plutof-panel/simple\",[],[[\"@title\"],[[30,[36,0],[\"General.access\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"access/view-rights-base\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,1,[\"panel\"]],[],[[\"@highlight\"],[true]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,[32,1,[\"column\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[1,[30,[36,1],null,[[\"path\"],[\"agent.organization.managing_group\"]]]],[2,\"\\n\\n\"],[6,[37,2],null,[[\"route\",\"model\"],[\"profile.groups.group\",[32,2,[\"laboratory\",\"managing_group\",\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"                    \"],[1,[32,2,[\"laboratory\",\"managing_group\",\"representation\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"            \"]],\"parameters\":[]}]]],[2,\"\\n\\n            \"],[8,[32,1,[\"history\"]],[],[[\"@createdBy\",\"@createdAt\",\"@updatedBy\",\"@updatedAt\"],[[32,2,[\"created_by\",\"representation\"]],[32,2,[\"created_at\"]],[32,2,[\"updated_by\",\"representation\"]],[32,2,[\"updated_at\"]]]],null],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"plutof-labelc\",\"link-to\"]}",
    "meta": {
      "moduleName": "plutof/components/experiment/common/record-metadata.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});