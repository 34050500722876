define("plutof/components/search/filter-panels/dna/properties", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "Search.panelProperties"}}
      @collapse={{true}}
  >
      <Layout::RegularGrid @columns={{6}}>
          <Search::FilterInput
              @filter={{@filterInputs.created_by}}
              @autocompletePreset="users/user"
              @additionalTooltip={{i18n-t "Agents.personTooltip"}} />
  
          <Search::FilterInput
              @filter={{@filterInputs.laboratory}}
              @autocompletePreset="agent/laboratory" />
  
          <Search::FilterInput
              @filter={{@filterInputs.dna_collection}}
              @autocomplete="agent/collection" />
      </Layout::RegularGrid>
  
      <Layout::RegularGrid @columns={{6}}>
          <Search::FilterInput @filter={{@filterInputs.purification_method}} />
  
          {{! Range input }}
          <Search::FilterInput
              @filter={{@filterInputs.concentration}}
              @filters={{@filterInputs}} />
  
          {{! Range input }}
          <Search::FilterInput
              @filter={{@filterInputs.unit_of_volume}}
              @filters={{@filterInputs}} />
      </Layout::RegularGrid>
  
      <Layout::RegularGrid @columns={{6}}>
          <Search::FilterInput @filter={{@filterInputs.condition_assessments}} />
      </Layout::RegularGrid>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "ziHEId3y",
    "block": "{\"symbols\":[\"@filterInputs\"],\"statements\":[[8,\"plutof-panel/simple\",[],[[\"@title\",\"@collapse\"],[[30,[36,0],[\"Search.panelProperties\"],null],true]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"layout/regular-grid\",[],[[\"@columns\"],[6]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"search/filter-input\",[],[[\"@filter\",\"@autocompletePreset\",\"@additionalTooltip\"],[[32,1,[\"created_by\"]],\"users/user\",[30,[36,0],[\"Agents.personTooltip\"],null]]],null],[2,\"\\n\\n        \"],[8,\"search/filter-input\",[],[[\"@filter\",\"@autocompletePreset\"],[[32,1,[\"laboratory\"]],\"agent/laboratory\"]],null],[2,\"\\n\\n        \"],[8,\"search/filter-input\",[],[[\"@filter\",\"@autocomplete\"],[[32,1,[\"dna_collection\"]],\"agent/collection\"]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,\"layout/regular-grid\",[],[[\"@columns\"],[6]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"purification_method\"]]]],null],[2,\"\\n\\n\"],[2,\"        \"],[8,\"search/filter-input\",[],[[\"@filter\",\"@filters\"],[[32,1,[\"concentration\"]],[32,1]]],null],[2,\"\\n\\n\"],[2,\"        \"],[8,\"search/filter-input\",[],[[\"@filter\",\"@filters\"],[[32,1,[\"unit_of_volume\"]],[32,1]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,\"layout/regular-grid\",[],[[\"@columns\"],[6]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"condition_assessments\"]]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\"]}",
    "meta": {
      "moduleName": "plutof/components/search/filter-panels/dna/properties.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});