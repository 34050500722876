define("plutof/templates/components/publishing/gbif/bibliography-row-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "fvEsv/r9",
    "block": "{\"symbols\":[\"@citation\",\"@removeCitation\"],\"statements\":[[10,\"td\"],[12],[2,\"\\n    \"],[10,\"div\"],[15,0,[31,[[30,[36,1],[[35,0,[\"attrs\",\"citation\",\"citation\",\"isInvalid\"]],\"has-error\"],null]]]],[12],[2,\"\\n        \"],[1,[30,[36,2],null,[[\"type\",\"value\",\"class\"],[\"text\",[32,1,[\"citation\"]],\"mini-input\"]]]],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[12],[2,\"\\n    \"],[1,[30,[36,2],null,[[\"type\",\"value\",\"class\"],[\"text\",[32,1,[\"url\"]],\"mini-input\"]]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[14,0,\"align-right\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-link data-table-btn\"],[4,[38,4],[\"click\",[30,[36,3],[[32,2],[32,1]],null]],null],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"icon-remove--red\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"validations\",\"if\",\"input\",\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/publishing/gbif/bibliography-row-form.hbs"
    }
  });
});