define("plutof/translations/est/navbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    delete: {
      info: 'Kustuta seotud kirjed',
      choices: {
        area: 'Kustuta kirje, ala ja sündmus',
        event: 'Kustuta kirje ja sündmus',
        record: 'Kustuta ainult kirje'
      },
      success: 'Kirje "{{name}}" on kustutatud',
      failure: 'Ei saanud kustutada kirjet "{{name}}"'
    }
  };
});