define("plutof/translations/est/registration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    register: 'Registreeru',
    aboutYou: 'Sinust',
    workRelated: 'Tegevusega seotud',
    successRegister: 'Registreerimine õnnestus!',
    successRegister2: 'E-posti aadress on kinnitatud! Jätkamiseks logi sisse.',
    resetSuccessMessage: 'Salasõna taastamine õnnestus. Teile on saadetud e-kiri edasiste juhistega',
    credentials: 'Registreeritud e-posti aadress',
    recoverPassword: 'Taasta salasõna',
    observationForm: 'Vaikimisi vaatlusvorm',
    firstStepSuccess: 'Registreerumise lõpetamiseks palun järgige Teie e-postile saadetud juhiseid.',
    welcomeStepTwo: 'Tere tulemast PlutoF töölauale',
    firstName: 'Eesnimi *',
    lastName: 'Perenimi *',
    username: 'Kasutajanimi *',
    email: 'E-posti aadress *',
    password: 'Parool *',
    passwordConfirm: 'Kinnita parool *',
    invalidUserOrPass: 'Vale kasutajanimi või salasõna.',
    errors: {
      usernameNotUnique: 'Sama nimega kasutaja on juba registreerunud!',
      emailNotUnique: 'Sama e-posti aadressiga kasutaja on juba registreerunud!'
    },
    help: {
      username: '8-30 tähemärki: numbrid, @ . - ja ladina tähed',
      password: 'vähemalt 12 tähemärki, mis võivad olla numbrid, ladina tähed ja kirjavahemärgid ! ? @ % $ # & : * - _',
      email: 'sellele aadressile saadetakse registreerumise kinnitus'
    }
  };
});