define("plutof/utils/i18n-translated", ["exports", "plutof/utils/i18n", "plutof/utils/model"], function (_exports, _i18n, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = translated;
  // XXX TODO: Remove this
  function translated(code) {
    const t = (0, _i18n.getI18n)().t(code);
    return (0, _model.overridable)(() => t);
  }
});