define("plutof/translations/eng/cloning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    // Navbar.
    clone: 'Copy',
    cloneTooltip: 'Opens a new form pre-filled with information from this record.',
    linkToArea: 'Link to Area',
    linkToAreaTooltip: 'Opens a new form pre-filled with information from this record. New records will be linked to the same area, and parent area.',
    linkToEvent: 'Link to Event',
    linkToEventTooltip: 'Opens a new form pre-filled with information form this record. New records will be linked to the same event, area, and parent area.',
    // Save panel.
    saveAndClone: 'Save and copy',
    saveAndCloneTooltip: 'Saves record(s) and opens a new form pre-filled with information from current form, including area information. New records will be linked to the same parent area. Project relations of parent areas will remain unchanged.',
    saveUseArea: 'Save and link to area',
    saveUseAreaTooltip: 'Saves record(s) and opens a new form pre-filled with information from current form. New records will be linked to the same area, and parent area. Project relations of area and parent areas will remain unchanged.',
    saveUseEvent: 'Save and link to event',
    saveUseEventTooltip: 'Saves record(s) and opens a new form pre-filled with information from current form. New records will be linked to the same event, area, and parent area. Project relations of area and parent areas will remain unchanged.',
    saveAndAddNew: 'Save and add new',
    saveAndAddNewTooltip: 'Saves record(s) and opens a new blank form.'
  };
});