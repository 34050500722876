define("plutof/templates/components/layout/regular-grid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "6sEbO7Sv",
    "block": "{\"symbols\":[\"@class\",\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[16,5,[32,0,[\"style\"]]],[16,0,[31,[[32,1],\" \",[32,0,[\"dynamicGridClass\"]]]]],[17,2],[12],[2,\"\\n    \"],[18,3,[[30,[36,1],null,[[\"span\"],[[30,[36,0],[\"layout/-grid/span\"],null]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/layout/regular-grid.hbs"
    }
  });
});