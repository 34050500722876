define("plutof/helpers/parse-to-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.parseToArrayHelper = parseToArrayHelper;
  // Data is either an array; a string or a null;
  function parseToArrayHelper([data]) {
    if (Array.isArray(data)) {
      return data;
    } else if (Ember.isNone(data)) {
      return [];
    } else {
      return [data];
    }
  }
  var _default = _exports.default = Ember.Helper.helper(parseToArrayHelper);
});