define("plutof/helpers/formatted-date", ["exports", "plutof/utils/formatters"], function (_exports, _formatters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatDate = formatDate;
  function formatDate([date, format]) {
    if (Ember.isEmpty(date)) {
      return '';
    }
    const usedFormat = Ember.isEmpty(format) ? (0, _formatters.getAmbiguousDateFormat)(date) : format;
    return (0, _formatters.parseAmbiguousDate)(date, format).format(usedFormat);
  }
  var _default = _exports.default = Ember.Helper.helper(formatDate);
});