define("plutof/components/transaction/return-date", ["exports", "@glimmer/component", "moment"], function (_exports, _component, moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#let (formatted-date @transaction.expected_end_date) as |date|}}
      {{#if status.overdue}}
          <span class="list-highlight">
              {{date}} ({{i18n-t "PlutofListViewColumn.overdue"}})
          </span>
      {{else if status.due}}
          <span class="list-highlight">
              {{date}} ({{i18n-t "PlutofListViewColumn.due"}})
          </span>
      {{else}}
          {{date}}
      {{/if}}
  {{/let}}
  
  */
  {
    "id": "g7lisS1v",
    "block": "{\"symbols\":[\"date\",\"@transaction\"],\"statements\":[[6,[37,4],[[30,[36,3],[[32,2,[\"expected_end_date\"]]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[35,1,[\"overdue\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[14,0,\"list-highlight\"],[12],[2,\"\\n            \"],[1,[32,1]],[2,\" (\"],[1,[30,[36,0],[\"PlutofListViewColumn.overdue\"],null]],[2,\")\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[35,1,[\"due\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[14,0,\"list-highlight\"],[12],[2,\"\\n            \"],[1,[32,1]],[2,\" (\"],[1,[30,[36,0],[\"PlutofListViewColumn.due\"],null]],[2,\")\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[32,1]],[2,\"\\n    \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"status\",\"if\",\"formatted-date\",\"let\"]}",
    "meta": {
      "moduleName": "plutof/components/transaction/return-date.hbs"
    }
  });
  class TransactionReturnDate extends _component.default {
    get status() {
      const {
        expected_end_date,
        is_locked
      } = this.args.transaction;
      if (is_locked || !expected_end_date) {
        return {
          due: false,
          overdue: false
        };
      }
      return {
        due: moment().isAfter(moment(expected_end_date).subtract(14, 'days')),
        overdue: moment().isAfter(moment(expected_end_date))
      };
    }
  }
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TransactionReturnDate);
});