define("plutof/components/unite/th-lineage", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::Simple @title={{i18n-t "UniteSH.shView.thTaxonomy"}}>
      <Common::RecordListView
          @records={{this.taxa}}
          @separator=";"
          as |taxon|
      >
          {{taxon.name}}
  
          {{#if taxon.th}}
              {{#if taxon.link}}
                  (<a href={{taxon.link}}>{{taxon.th}}</a>)
              {{else}}
                  ({{taxon.th}})
              {{/if}}
          {{/if}}
      </Common::RecordListView>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "YoBE3mNp",
    "block": "{\"symbols\":[\"taxon\"],\"statements\":[[8,\"plutof-panel/simple\",[],[[\"@title\"],[[30,[36,1],[\"UniteSH.shView.thTaxonomy\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"common/record-list-view\",[],[[\"@records\",\"@separator\"],[[32,0,[\"taxa\"]],\";\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,1,[\"name\"]]],[2,\"\\n\\n\"],[6,[37,0],[[32,1,[\"th\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[32,1,[\"link\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"                (\"],[10,\"a\"],[15,6,[32,1,[\"link\"]]],[12],[1,[32,1,[\"th\"]]],[13],[2,\")\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"                (\"],[1,[32,1,[\"th\"]]],[2,\")\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"    \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"i18n-t\"]}",
    "meta": {
      "moduleName": "plutof/components/unite/th-lineage.hbs"
    }
  });
  class THLineage extends _component.default {
    get taxa() {
      return this.args.lineage.map(taxon => ({
        name: taxon.taxon_name,
        th: taxon.th_code,
        link: taxon.doi ? `${window.location.origin}/doi/${taxon.doi}` : null
      }));
    }
  }
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, THLineage);
});