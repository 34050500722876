define("plutof/templates/reset-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "vrtEAbOT",
    "block": "{\"symbols\":[],\"statements\":[[8,\"test/route-marker\",[],[[\"@route\"],[\"reset-password\"]],null],[2,\"\\n\\n\"],[8,\"common/legend\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"Registration.recoverPassword\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,\"auth/password-change\",[],[[\"@showOldPasswordField\",\"@changed\",\"@key\"],[false,[32,0,[\"changed\"]],[32,0,[\"key\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\"]}",
    "meta": {
      "moduleName": "plutof/templates/reset-password.hbs"
    }
  });
});