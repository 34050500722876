define("plutof/translations/eng/traits", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    title: 'Traits and Measurements',
    traits: 'Traits',
    translations: 'Translations',
    languageInfo: 'Trait is expected to be in English. Texts in another language can be entered to the "Translations" panel.'
  };
});