define("plutof/components/information/content/clipboard/livingspecimen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Information::MarkdownReader @path="clipboard/condition-assessment" @locale={{@locale}} />
  
  */
  {
    "id": "4mnXIGRm",
    "block": "{\"symbols\":[\"@locale\"],\"statements\":[[8,\"information/markdown-reader\",[],[[\"@path\",\"@locale\"],[\"clipboard/condition-assessment\",[32,1]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "plutof/components/information/content/clipboard/livingspecimen.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});