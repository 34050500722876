define("plutof/models/agent/person", ["exports", "@ember-data/model", "plutof/mixins/display-name-from-value", "plutof/models/plutof-model"], function (_exports, _model, _displayNameFromValue, _plutofModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let Person = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('datetime'), _dec6 = (0, _model.attr)('datetime'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('enum'), _dec11 = (0, _model.attr)('boolean'), _dec12 = (0, _model.belongsTo)('agent/agent'), _dec13 = (0, _model.belongsTo)('agent/organization'), _dec14 = (0, _model.belongsTo)('users/user'), _dec15 = (0, _displayNameFromValue.displayName)('gender'), _dec16 = Ember.computed('given_name', 'family_name'), (_class = class Person extends _plutofModel.default.extend(_displayNameFromValue.DisplayNameMixin) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "given_name", _descriptor, this);
      _initializerDefineProperty(this, "family_name", _descriptor2, this);
      _initializerDefineProperty(this, "primary_given_name", _descriptor3, this);
      _initializerDefineProperty(this, "agent_name", _descriptor4, this);
      _initializerDefineProperty(this, "birth_date", _descriptor5, this);
      _initializerDefineProperty(this, "death_date", _descriptor6, this);
      _initializerDefineProperty(this, "prefix", _descriptor7, this);
      _initializerDefineProperty(this, "suffix", _descriptor8, this);
      _initializerDefineProperty(this, "orcid_identifier", _descriptor9, this);
      _initializerDefineProperty(this, "gender", _descriptor10, this);
      _initializerDefineProperty(this, "is_ambiguous", _descriptor11, this);
      _initializerDefineProperty(this, "valid_agent", _descriptor12, this);
      _initializerDefineProperty(this, "contact", _descriptor13, this);
      _initializerDefineProperty(this, "user", _descriptor14, this);
      _defineProperty(this, "optionMixinModelName", 'agent.person');
      _initializerDefineProperty(this, "genderDisplayName", _descriptor15, this);
    }
    get representation() {
      return `${this.given_name} ${this.family_name}`;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "given_name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "family_name", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "primary_given_name", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "agent_name", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "birth_date", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "death_date", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "prefix", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "suffix", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "orcid_identifier", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "gender", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "is_ambiguous", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "valid_agent", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "contact", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "genderDisplayName", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "representation", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "representation"), _class.prototype)), _class));
  var _default = _exports.default = Person;
});