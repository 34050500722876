define("plutof/routes/permit/edit", ["exports", "plutof/components/collection-lab/permit/edit", "plutof/utils/routes"], function (_exports, _edit, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PermitEditRoute extends _routes.EditRoute {
    async model({
      id
    }) {
      const permitRecord = await this.store.findRecord('permit/permit', id);
      const permit = await (0, _edit.loadPermitModel)(this.store, permitRecord);
      return {
        permit
      };
    }
    getTitle(model) {
      return model.permit.permit.representation;
    }
    transitionToDefaultRoute() {
      return this.transitionToDefaultRoute('permit.view', this.currentModel.permit.permit.id);
    }
  }
  var _default = _exports.default = PermitEditRoute;
});