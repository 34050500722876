define("plutof/translations/eng/occurrence-source", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    newsamplingevent: 'New Sampling Area and Event',
    samplingevent: 'Existing Sampling Area and Event',
    specimen: 'Existing Specimen',
    sequence: 'Existing Sequence',
    observation: 'Existing Observation',
    strain: 'Existing Living Specimen',
    materialsample: 'Existing Material Sample',
    none: 'Not linked'
  };
});