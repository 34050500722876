define("plutof/utils/filetypes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.get_filename_extension = get_filename_extension;
  _exports.is_file_image = is_file_image;
  _exports.type_to_internal = void 0;
  const type_to_internal = _exports.type_to_internal = {
    3: 'data',
    4: 'data',
    12: 'sound',
    14: 'text',
    15: 'software',
    17: 'video',
    18: 'image',
    19: '3dmodel'
  };
  function get_filename_extension(filename) {
    return filename.includes('.') ? filename.split('.').pop() : '';
  }
  function is_file_image(file) {
    return /^image/.test(file.type);
  }
});