define("plutof/components/generic-forms/record", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield (hash
      field=(component "generic-forms/-record/field" model=@record.constructor.modelName)
      bind=(component "generic-forms/-record/bind" record=@record)
  )}}
  
  */
  {
    "id": "S43by7y9",
    "block": "{\"symbols\":[\"@record\",\"&default\"],\"statements\":[[18,2,[[30,[36,1],null,[[\"field\",\"bind\"],[[30,[36,0],[\"generic-forms/-record/field\"],[[\"model\"],[[32,1,[\"constructor\",\"modelName\"]]]]],[30,[36,0],[\"generic-forms/-record/bind\"],[[\"record\"],[[32,1]]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "plutof/components/generic-forms/record.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});