define("plutof/utils/scroll-to-div", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = scrollToDiv;
  function scrollToDiv(id) {
    const node = document.getElementById(id);
    const offset = node.offsetTop - 100;
    window.scrollTo({
      top: offset,
      behavior: 'smooth'
    });
  }
});