define("plutof/components/analysis/matching-source-fasta-item", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  let MatchingSourceFastaItem = (_dec = (0, _component.tagName)('tr'), _dec(_class = class MatchingSourceFastaItem extends Ember.Component {}) || _class);
  var _default = _exports.default = MatchingSourceFastaItem;
});