define("plutof/mixins/clear_query_params", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ClearQueryParamsMixin = Ember.Mixin.create({
    actions: {
      willTransition: function (transition) {
        var ctr = this.controller;
        var params = ctr.get('queryParams');
        if (!Ember.isNone(params)) {
          params.forEach(function (param) {
            ctr.set(param, null);
          });
        }
        return this._super(transition);
      }
    }
  });
  var _default = _exports.default = ClearQueryParamsMixin;
});