define("plutof/translations/eng/navbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    delete: {
      info: 'Delete related records',
      choices: {
        area: 'Delete record, area, and event',
        event: 'Delete record and event',
        record: 'Delete only record'
      },
      success: 'Deleted record "{{name}}"',
      failure: 'Could not delete record "{{name}}"'
    }
  };
});