define("plutof/components/search/filter-panels/taxon-node/taxon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "Search.panelTaxon"}}
      @collapse={{true}}
  >
      <Layout::RegularGrid @columns={{6}}>
          <Search::FilterInput @filter={{@filterInputs.tree}} />
          <Search::FilterInput @filter={{@filterInputs.taxon_rank}} @autocompletePreset="taxonomy/taxonrank" />
          <Search::FilterInput @filter={{@filterInputs.valid_name}} />
          <Search::FilterInput @filter={{@filterInputs.parent_taxon}} />
          <Search::FilterInput @filter={{@filterInputs.prot_category}} />
          <Search::Filters::VernacularNameLanguage @filter={{@filterInputs.language_id}} />
      </Layout::RegularGrid>
  
      <Layout::RegularGrid @columns={{6}}>
          <Search::FilterInput @filter={{@filterInputs.include_commonnames}} />
          <Search::FilterInput @filter={{@filterInputs.is_synonym}} />
          <Search::FilterInput @filter={{@filterInputs.include_synonyms}} />
          <Search::FilterInput @filter={{@filterInputs.is_informal}} />
          <Search::FilterInput @filter={{@filterInputs.is_deleted}} />
      </Layout::RegularGrid>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "dU34xC0Y",
    "block": "{\"symbols\":[\"@filterInputs\"],\"statements\":[[8,\"plutof-panel/simple\",[],[[\"@title\",\"@collapse\"],[[30,[36,0],[\"Search.panelTaxon\"],null],true]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"layout/regular-grid\",[],[[\"@columns\"],[6]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"tree\"]]]],null],[2,\"\\n        \"],[8,\"search/filter-input\",[],[[\"@filter\",\"@autocompletePreset\"],[[32,1,[\"taxon_rank\"]],\"taxonomy/taxonrank\"]],null],[2,\"\\n        \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"valid_name\"]]]],null],[2,\"\\n        \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"parent_taxon\"]]]],null],[2,\"\\n        \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"prot_category\"]]]],null],[2,\"\\n        \"],[8,\"search/filters/vernacular-name-language\",[],[[\"@filter\"],[[32,1,[\"language_id\"]]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,\"layout/regular-grid\",[],[[\"@columns\"],[6]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"include_commonnames\"]]]],null],[2,\"\\n        \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"is_synonym\"]]]],null],[2,\"\\n        \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"include_synonyms\"]]]],null],[2,\"\\n        \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"is_informal\"]]]],null],[2,\"\\n        \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"is_deleted\"]]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\"]}",
    "meta": {
      "moduleName": "plutof/components/search/filter-panels/taxon-node/taxon.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});