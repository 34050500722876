define("plutof/templates/components/occurrences-table/-responsive-row/action-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "15XKBwMB",
    "block": "{\"symbols\":[\"@icon\"],\"statements\":[[10,\"span\"],[15,0,[31,[[32,1]]]],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "plutof/templates/components/occurrences-table/-responsive-row/action-button.hbs"
    }
  });
});