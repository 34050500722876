define("plutof/components/information/content/general/default-licence", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <img src="/assets/images/creativecommons.png" class="img-responsive">
  
  */
  {
    "id": "+aYnt1rB",
    "block": "{\"symbols\":[],\"statements\":[[10,\"img\"],[14,\"src\",\"/assets/images/creativecommons.png\"],[14,0,\"img-responsive\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "plutof/components/information/content/general/default-licence.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});