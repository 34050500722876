define("plutof/components/agent/organization/associations/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <RelatedObjects::View
      @title={{i18n-t "Agents.associations.persons"}}
      @pagination={{@associations}}
  >
      <RelatedObjects::Content @pagination={{@associations}} as |Field association|>
          <Field @label={{backend-translations/label "agentassociation.person"}}>
              {{#link-to "person.view" association.person.id}}
                  {{association.person.representation}}
              {{/link-to}}
          </Field>
  
          <Field @label={{backend-translations/label "agentassociation.agent_role"}}>
              {{association.agent_role}}
          </Field>
  
          <Field @label={{backend-translations/label "agentassociation.start_date"}}>
              {{association.start_date}}
          </Field>
  
          <Field @label={{backend-translations/label "agentassociation.end_date"}}>
              {{association.end_date}}
          </Field>
      </RelatedObjects::Content>
  </RelatedObjects::View>
  
  */
  {
    "id": "RmAqEoHF",
    "block": "{\"symbols\":[\"Field\",\"association\",\"@associations\"],\"statements\":[[8,\"related-objects/view\",[],[[\"@title\",\"@pagination\"],[[30,[36,0],[\"Agents.associations.persons\"],null],[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"related-objects/content\",[],[[\"@pagination\"],[[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,1],[],[[\"@label\"],[[30,[36,1],[\"agentassociation.person\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],null,[[\"route\",\"model\"],[\"person.view\",[32,2,[\"person\",\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"                \"],[1,[32,2,[\"person\",\"representation\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,1],[],[[\"@label\"],[[30,[36,1],[\"agentassociation.agent_role\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[32,2,[\"agent_role\"]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,1],[],[[\"@label\"],[[30,[36,1],[\"agentassociation.start_date\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[32,2,[\"start_date\"]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,1],[],[[\"@label\"],[[30,[36,1],[\"agentassociation.end_date\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[32,2,[\"end_date\"]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[1,2]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"backend-translations/label\",\"link-to\"]}",
    "meta": {
      "moduleName": "plutof/components/agent/organization/associations/view.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});