define("plutof/components/publishing/gbif/access-rights/utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.resolveUsernames = resolveUsernames;
  async function resolveUsernames(store, rawRights) {
    const {
      users_view,
      users_change,
      rights_holder
    } = rawRights.data;

    // Combining usernames so that we don't query the same user twice if they are
    // in both view and edit lists
    const usernames = new Set(users_view.concat(users_change).concat(rights_holder).mapBy('username'));
    let users = {};
    await Ember.RSVP.all(Array.from(usernames).map(username => {
      return store.queryRecord('users/user', {
        username
      }).then(user => {
        users[username] = user;
      });
    }));
    return {
      viewUsers: users_view.map(user => users[user.username]),
      editUsers: users_change.map(user => users[user.username]),
      owner: users[rights_holder.username]
    };
  }
});