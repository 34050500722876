define("plutof/components/clipboard/send-to-dataset", ["exports", "@glimmer/component", "plutof/controllers/publishing/utils", "plutof/utils/notifications"], function (_exports, _component, _utils, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let SendClipboardItemsToDataset = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember.computed.empty('dataset'), _dec5 = Ember.computed.equal('activeMode', 'overwrite'), _dec6 = Ember.computed.equal('activeMode', 'remove'), _dec7 = Ember.computed.and('activeMode', 'removeMode'), _dec8 = Ember.computed('activeMode'), _dec9 = Ember.computed('args.selectedEntries.[]'), _dec10 = Ember._action, _dec11 = Ember._action, (_class = class SendClipboardItemsToDataset extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "i18n", _descriptor, this);
      _defineProperty(this, "OCCURRENCE_DATASET", (0, _utils.getDatasetType)('occurrences'));
      _initializerDefineProperty(this, "dataset", _descriptor2, this);
      _initializerDefineProperty(this, "activeMode", _descriptor3, this);
      _initializerDefineProperty(this, "cannotSend", _descriptor4, this);
      _initializerDefineProperty(this, "overwrite", _descriptor5, this);
      _initializerDefineProperty(this, "removeMode", _descriptor6, this);
      _initializerDefineProperty(this, "disableDataset", _descriptor7, this);
    }
    get actionLabel() {
      const mode = this.activeMode;
      return Ember.isPresent(mode) ? this.i18n.translate(`Clipboard.gbif.mode.${mode}.label`) : null;
    }
    get hasSelected() {
      return this.args.selectedEntries.length > 0;
    }
    async addToDataset(datasetID, ids, overwrite = false) {
      const recordType = (0, _utils.getRecordType)(this.recordTypeName);
      const {
        async
      } = await (0, _utils.assignDataset)(this.ajax, datasetID, recordType, ids, overwrite);
      (0, _notifications.displayNotification)('status', this.i18n.translate('Clipboard.loading'));

      // TODO: DRY with components/clipboard/bulk-update
      if (async) {
        (0, _notifications.displayNotification)('success', this.i18n.translate('Clipboard.editFinishesLater'), null, 12000);
      } else {
        (0, _notifications.displayNotification)('success', this.i18n.translate('General.done'));
      }
      if (datasetID) {
        this.transitionToRoute('publishing.gbif.view', datasetID);
      }
    }
    async sendToDataset(datasetID, ids, overwrite = false) {
      try {
        await this.addToDataset(datasetID, ids, overwrite);
      } catch (err) {
        (0, _notifications.reportError)(err);
        throw err;
      }
    }
    send() {
      if (this.hasSelected) {
        this.sendToDataset(this.dataset.id, this.args.selectedEntries.mapBy('id'), this.overwrite);
      } else {
        this.sendToDataset(this.dataset.id, [], this.overwrite);
      }
    }
    remove() {
      if (this.hasSelected) {
        this.sendToDataset(null, this.args.selectedEntries.mapBy('id'));
      } else {
        this.sendToDataset(null, []);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "i18n", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dataset", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "activeMode", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "cannotSend", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "overwrite", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "removeMode", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "disableDataset", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "actionLabel", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "actionLabel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hasSelected", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "hasSelected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "send", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "send"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "remove", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "remove"), _class.prototype)), _class));
  var _default = _exports.default = SendClipboardItemsToDataset;
});