define("plutof/components/login/form-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="login-input {{if @value 'login-input--filled'}} {{if @isValid 'login-input--valid'}}">
      <label class="login-input__label">
          {{@label}}
      </label>
  
      {{yield}}
  
      {{#if @help}}
          <div class="login-input__help">
              {{@help}}
          </div>
      {{/if}}
  </div>
  
  */
  {
    "id": "Umk5aysb",
    "block": "{\"symbols\":[\"@help\",\"@isValid\",\"@value\",\"@label\",\"&default\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"login-input \",[30,[36,0],[[32,3],\"login-input--filled\"],null],\" \",[30,[36,0],[[32,2],\"login-input--valid\"],null]]]],[12],[2,\"\\n    \"],[10,\"label\"],[14,0,\"login-input__label\"],[12],[2,\"\\n        \"],[1,[32,4]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[18,5,null],[2,\"\\n\\n\"],[6,[37,0],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"login-input__help\"],[12],[2,\"\\n            \"],[1,[32,1]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "plutof/components/login/form-input.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});