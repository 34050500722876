define("plutof/components/agent/organization-form", ["exports", "@ember-decorators/component", "@ember-decorators/object", "ember-cp-validations", "plutof/misc/abstract", "plutof/mixins/component-validations", "plutof/utils/formatters", "plutof/utils/model", "plutof/utils/notifications"], function (_exports, _component, _object, _emberCpValidations, _abstract, _componentValidations, _formatters, _model, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const Validations = (0, _emberCpValidations.buildValidations)({
    'birthDateValidity': (0, _emberCpValidations.validator)('is-truthy'),
    'deathDateValidity': (0, _emberCpValidations.validator)('is-truthy'),
    'nameIsValid': (0, _emberCpValidations.validator)('is-truthy'),
    'organization.organization_type': (0, _emberCpValidations.validator)('presence', true),
    'organization.homepage': [(0, _emberCpValidations.validator)('length', {
      max: 128
    }), (0, _emberCpValidations.validator)('format', {
      regex: _formatters.URL_REGEX,
      allowBlank: true
    })],
    'organization.contact_email': [(0, _emberCpValidations.validator)('length', {
      max: 256
    }), (0, _emberCpValidations.validator)('format', {
      type: 'email',
      allowBlank: true
    })]
  });
  let OrganizationForm = (_dec = (0, _component.classNames)('organization-edit'), _dec2 = Ember.inject.service, _dec3 = (0, _model.choices)('agent.organization.organization_level'), _dec4 = (0, _model.choices)('agent.organization.organization_type'), _dec5 = (0, _object.observes)('organization.name', 'organization.abbreviation'), _dec6 = Ember.computed('organization.name', 'nameIsUnique'), _dec(_class = (_class2 = class OrganizationForm extends Ember.Component.extend(Validations, _componentValidations.default) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "levels", _descriptor2, this);
      _initializerDefineProperty(this, "types", _descriptor3, this);
      _defineProperty(this, "validationChildren", ['address', 'acronym']);
      _defineProperty(this, "birthDateValidity", false);
      _defineProperty(this, "deathDateValidity", false);
      _defineProperty(this, "nameIsUnique", true);
    }
    /*
        To avoid creating duplicate organizations
        Ideally acronyms should be unique, UT - uni. tartu etc..
    */
    checkFullNameUniquness() {
      let {
        abbreviation,
        name,
        id
      } = this.organization.getProperties('abbreviation', 'name', 'id');
      if (this.isDestroyed) {
        return;
      }
      if (Ember.isEmpty(name)) {
        this.set('nameIsUnique', true);
        return;
      }
      abbreviation = Ember.isBlank(abbreviation) ? '' : abbreviation + ' - ';
      const url = (0, _abstract.construct_request)('/agent/organizations/autocomplete/', {
        name: abbreviation + name,
        filter_type: 'exact'
      });
      this.ajax.request(url).then(names => {
        if (Ember.isEmpty(names.results)) {
          this.set('nameIsUnique', true);
          return;
        }

        /*  Append hyperlinks of organizations with same name to warning msg */
        const exactMatch = names.results.find(r => {
          return r.name.toLowerCase() === abbreviation.toLowerCase() + name.toLowerCase() && r.id !== parseInt(id);
        });
        if (Ember.isPresent(exactMatch)) {
          let warningMsg = this.i18n.t('warnings.organizationHasMatches');
          warningMsg += `<p><a href="${window.location.origin}/organization/view/${exactMatch.id}" target="_blank"> ${exactMatch.name}</a></p>`;
          (0, _notifications.clearNotifications)();
          (0, _notifications.displayHtmlNotification)('error', warningMsg);

          // When we have an exact match and no abbreviation than org name is considered a duplicate, org full_name is equal.
          // My Org - TMK and My Org - WWW are not considered a duplicate.
          this.set('nameIsUnique', !Ember.isBlank(abbreviation));
        }
      }, _notifications.reportError);
    }
    nameChanged() {
      Ember.run.debounce(this, this.checkFullNameUniquness, 1500);
    }
    get nameIsValid() {
      return !Ember.isBlank(this.get('organization.name')) && this.nameIsUnique;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "levels", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "types", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "nameChanged", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "nameChanged"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "nameIsValid", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "nameIsValid"), _class2.prototype)), _class2)) || _class);
  var _default = _exports.default = OrganizationForm;
});