define("plutof/templates/components/experiment/sequencing/material-bulk-update", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "6z8pHqhJ",
    "block": "{\"symbols\":[\"values\",\"updateButton\",\"@update\"],\"statements\":[[6,[37,2],null,[[\"update\"],[[32,3]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[12],[2,\"\\n        \"],[1,[30,[36,0],null,[[\"path\"],[\"sequencing-result.primer\"]]]],[2,\"\\n\\n        \"],[8,\"auto-complete/bound-model\",[],[[\"@model\",\"@value\"],[\"dna-lab/primer\",[32,1,[\"primer\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[12],[2,\"\\n        \"],[1,[30,[36,0],null,[[\"path\"],[\"sequencing-result.comments\"]]]],[2,\"\\n\\n        \"],[1,[30,[36,1],null,[[\"type\",\"value\",\"class\"],[\"text\",[32,1,[\"comments\"]],\"form-control\"]]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"spacer-large\"],[12],[13],[2,\"\\n\\n    \"],[1,[32,2]],[2,\"\\n\"]],\"parameters\":[1,2]}]]]],\"hasEval\":false,\"upvars\":[\"plutof-labelc\",\"input\",\"tables/bulk-fields-update\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/experiment/sequencing/material-bulk-update.hbs"
    }
  });
});