define("plutof/translations/est/publishing", ["exports", "plutof/translations/helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    doi: {
      doi: 'DataCite DOI',
      dois: 'DataCite DOI-d',
      files: 'Failid',
      creators: 'Autorid',
      taxa: 'Taksonid',
      subjects: 'Valdkonnad',
      contributors: 'Kaasautorid',
      dates: 'Kuupäevad',
      language: 'Keel',
      descriptions: 'Kirjeldused',
      alternateIds: 'Teisesed identifikaatorid',
      sizes: 'Suurused',
      formats: 'Formaadid',
      rightsList: 'Litsentsi tüübid',
      newDOI: 'Uus DataCite DOI',
      editDOI: 'Muuda DataCite DOI-d',
      documents: 'Dokumendid',
      detailedInformation: 'Detailsed andmed',
      metadata: 'Metaandmed',
      geography: 'Geograafia',
      description: 'Kirjeldus',
      title: 'Pealkiri',
      subtitle: 'Alapealkiri',
      abstract: 'Kokkuvõte',
      location: 'Asukoht',
      publisher: 'Avaldaja',
      publishedIn: 'Avaldatud',
      // year/platform
      resourceType: 'Materjali tüüp',
      relatedIdentifiers: 'Seotud identifikaatorid',
      bytes: 'Baiti',
      newSet: 'Uus tüüp',
      existing: 'Olemasolev',
      existingRights: 'Litsentsi tüüp',
      rights: 'Litsents',
      rightsURI: 'Litsentsi URI',
      name: 'Nimi',
      otherElements: 'Ülejäänud',
      recommended: 'Soovituslikud',
      media: 'Meedia',
      manualSet: 'Käsitisi',
      size: 'Andmestiku suurus vabatekstina, näiteks: 3 faili, 5mb',
      format: 'Kirjelda lisatud failide formaate, näiteks: pdf, jpg, txt',
      dataCiteFileFormats: 'DOI faili formaadid',
      fileLabel: 'Üleslaaditud fail',
      fileLabelToolT: 'Eelnevalt üleslaaditud failid, millel on õigused. Failid on leitavad ka failide repositooriumist.',
      manualLocationTooltip: 'Punkt: "laiuskraad pikkuskraad"; Ruut: "ülemine laiuskraad ülemine pikkuskraad alumine laiuskraad alumine pikkuskraad"',
      manualLocationPlaceholder: 'laiuskraad pikkuskraad [laius pikkuskraad]',
      rightsSetting: 'Õiguste valikud',
      relatedSettings: 'Seoste valikud',
      setLocationInput: 'Set',
      properties: 'Atribuudid',
      author: 'Autor',
      downloadEML: 'Laadi alla EML',
      fundingRef: 'Rahastusallikas',
      fundingSource: 'Allikas',
      fundingAward: 'Auhind',
      fundingType: 'Tüüp',
      notRegistered: 'registreerimata'
    },
    dataResource: {
      intro: {
        description: 'Halda ja publitseeri <em>Global Biodiversity Information Facility</em> andmesette.',
        publishing: 'Andmete GBIF-i publitseerimine',
        title: 'GBIF andmesetid'
      },
      fields: {
        DwCALink: 'Darwin Core Archive',
        DwCALinkTooltip: 'Laadi alla viimati publitseeritud Darwin Core Archive (DwC-A) file.',
        contacts: 'Kontaktid',
        description: 'Kirjeldus',
        geographicCoverage: 'Geograafiline katvus',
        homepage: 'Veebileht',
        language: 'Keel',
        licence: 'Litsents',
        logoURL: 'Logo',
        modified: 'Muudetud',
        project: 'Projekt',
        published: 'Publiteseeritud',
        taxonomicCoverage: 'Taksonoomilne katvus',
        temporalCoverageBegin: 'Ajalise katvuse algus',
        temporalCoverageEnd: 'Ajalise katvuse lõpp',
        formationPeriod: 'Formation period',
        // TODO
        title: 'Nimi',
        recordCount: 'Kirjete arv',
        recordType: 'Kirjete tüüp',
        datasetType: 'Andmeseti tüüp',
        shCode: 'UNITE SH code as scientific name'
      },
      tooltips: {
        geographicCoverage: 'Geographic Coverage specifies spatial information about a project, a resource, or an entity within a resource.',
        taxonomicCoverage: 'Taxonomic Coverage specifies taxonomic information about a project, a resource, or an entity within a resource.',
        // TODO
        formationPeriod: 'Text description of the time period during which the collection was assembled (e.g., "Victorian", "1922-1932", "c. 1750"). Use this type to indicate an ongoing collection (e.g., "2010-current").',
        // TODO
        formationPeriodPlaceholder: 'Ex. 2018-08-01, 2018 - current',
        logo: 'Logo associated with the dataset. This image will be used to illustrate dataset page in GBIF.',
        licence: 'The licence that you apply to a dataset provides a standardized way to define appropriate uses of your work. GBIF encourages publishers to adopt the least restrictive licence possible from among three machine-readable options (CC0 1.0, CC-BY 4.0 or CC-BY-NC 4.0) to encourage the widest possible use and application of data. Learn more <a href="https://www.gbif.org/terms" target="_blank">here</a>. If you are unable to choose one of the three options, and your dataset contains occurrence data, you will not be able to register your dataset with GBIF or make it globally discoverable through GBIF.org. If you feel unable to select one of the three options, please contact the GBIF Secretariat at participation@gbif.org.',
        description: 'A brief overview of the resource that is being documented broken into paragraphs.',
        dataLanguage: 'The primary language in which the described data (not the metadata document) is written.',
        projectAbstract: 'Descriptive abstract that summarizes information about the research project.',
        projectFunding: 'The funding field is used to provide information about funding sources for the project such as: grant and contract numbers; names and addresses of funding sources. Other funding-related information may also be included.',
        shCode: 'Use sequence record\'s UNITE SH belonging (instead of SH identification or Identification) as record\'s Scientific name in GBIF dataset.'
      },
      general: {
        controls: (0, _helpers.alias)('General.controls'),
        editDataset: 'Muuda GBIF andmesetti',
        findLogo: 'Otsi logo',
        uploadLogo: 'Laadi logo üles',
        newDataset: 'Uus GBIF andmesett',
        viewDataset: 'GBIF andmesett',
        publishingInProgress: 'Töös (kirjete kogumine)',
        GBIFUploadInProgress: 'Töös (GBIF-i ootel)',
        deleted: 'See andmesett on kustutatud.'
      },
      list: {
        description: (0, _helpers.alias)('dataResource.fields.description'),
        edit: 'Muuda',
        language: (0, _helpers.alias)('dataResource.fields.language'),
        modified: (0, _helpers.alias)('dataResource.fields.modified'),
        published: (0, _helpers.alias)('dataResource.fields.published'),
        title: (0, _helpers.alias)('dataResource.fields.title'),
        deleted: 'kustutatud'
      },
      panels: {
        contacts: (0, _helpers.alias)('dataResource.fields.contacts'),
        bibliography: 'Bibliograafia',
        general: (0, _helpers.alias)('General.generalData'),
        project: (0, _helpers.alias)('dataResource.fields.project'),
        history: 'Ajalugu'
      },
      tabs: {
        sequences: 'Seotud kirjed: sekventsid',
        specimens: 'Seotud kirjed: eksemplarid',
        observations: 'Seotud kirjed: vaatlused',
        referencebased: 'Seotud kirjed: kirjanduspõhised'
      },
      project: {
        title: 'Tiitel',
        abstract: 'Abstrakt',
        funding: 'Rahastus'
      },
      buttons: {
        publish: 'Publitseeri (GBIF)',
        generateDwCA: 'Loo DwCA'
      },
      dialogs: {
        publish: {
          title: 'Andmete GBIF-i publitseerimine',
          content: 'Publitseeritud andmesetti saab peale publitseerimist täiendada ja uuesti publitseerida. Publitseeritud andmesette ei saa kustutada.',
          confirm: 'Publitseeri',
          cancel: (0, _helpers.alias)('General.Cancel')
        }
      },
      generateDwCA: {
        process: 'Scheduling DwCA export...',
        success: 'DwCA export scheduled. You will be notified via e-mail.'
      },
      history: {
        created: 'Sisestatud',
        publish: 'Publitseeri'
      },
      types: {
        occurrences: 'Kirjed',
        metadata: 'Metaandmed'
      }
    },
    datasetContact: {
      fields: {
        email: 'E-post',
        firstName: 'Eesnimi',
        lastName: 'Perekonna nimi',
        position: 'Positsioon',
        agentType: 'Kontakti tüüp',
        orcid: 'ORCID'
      },
      general: {
        controls: (0, _helpers.alias)('General.controls'),
        personSearch: 'Isiku otsing'
      },
      tooltips: {
        agentTypes: {
          contact: 'Contact represents the people and organizations that should be contacted to get more information about the resource, that curate the resource or to whom putative problems with the resource or its data should be addressed.',
          resourceCreator: 'Creator represents the people and organizations who created the resource, in priority order.',
          metadataProvider: 'Metadata provider metadata provider represents the people and organizations responsible for producing the resource metadata.'
        },
        orcid: 'The ORCID iD is an https URI with a 16-digit number that is compatible with the ISO Standard (ISO 27729), also known as the International Standard Name Identifier (ISNI), e.g. https://orcid.org/0000-0001-2345-6789'
      }
    },
    dataResourceBibliography: {
      citation: 'Viide',
      url: 'URL'
    },
    datasetRecord: {
      fields: {
        commune: 'Vald või linn',
        createdAt: 'Andmesetti lisatud',
        deleted: 'Kustutatud',
        district: 'Maakond',
        eventID: 'Kogumissündmuse ID',
        locality_text: 'Asukoht tekst',
        name: 'ID',
        project: 'Projekt',
        published: 'Publitseeritud',
        remarks: 'Märkused',
        taxonNode: 'Takson',
        timespan: 'Aeg',
        type: 'Tüüp',
        record: 'Kirje'
      },
      general: {
        controls: (0, _helpers.alias)('General.controls'),
        createdAtNow: 'Praegu'
      },
      recordTypes: {
        'Sequence': 'Sekvents'
      },
      addObservationTooltip: 'Vaatluseid saab lisada näpitstahvli kaudu.'
    }
  };
});