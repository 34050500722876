define("plutof/helpers/has-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ValidationClassHelper extends Ember.Helper {
    init() {
      super.init(...arguments);
      this.set('component', null);
      this.set('property', null);
    }
    observedPath(property) {
      return `component.validations.attrs.${property}.isInvalid`;
    }
    cleanup() {
      if (!Ember.isNone(this.property)) {
        this.removeObserver(this.observedPath(this.property), this, this.recompute);
      }
    }
    compute([component, property]) {
      if (this.component !== component || this.property !== property) {
        this.cleanup();
        this.setProperties({
          component,
          property
        });
        this.addObserver(this.observedPath(property), this, this.recompute);
      }
      return this.get(this.observedPath(this.property)) ? 'has-error' : '';
    }

    // TODO: Find out if observers defined this way are automatically cleaned up. Docs are mum
    // on this point
    willDestroy() {
      this.cleanup();
    }
  }
  var _default = _exports.default = ValidationClassHelper;
});