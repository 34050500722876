define("plutof/utils/access", ["exports", "copy-anything", "plutof/config/environment", "plutof/misc/abstract", "plutof/misc/content_types", "plutof/models/filerepository/file", "plutof/models/taxonoccurrence/specimen/specimen", "plutof/models/taxonoccurrence/observation/observation", "plutof/models/taxonoccurrence/sequence/sequence", "plutof/models/taxonoccurrence/livingculture/strain", "plutof/models/taxonoccurrence/referencebased/occurrence", "plutof/models/taxonoccurrence/materialsample/materialsample", "plutof/services/ajax", "plutof/utils/push-to-store", "plutof/utils/reflection", "plutof/utils/structures"], function (_exports, _copyAnything, _environment, _abstract, _content_types, _file, _specimen, _observation, _sequence, _strain, _occurrence, _materialsample, _ajax, _pushToStore, _reflection, _structures) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createDerivedRecordRights = createDerivedRecordRights;
  _exports.create_access_rights = create_access_rights;
  _exports.get_permissions = get_permissions;
  _exports.groupBasedPermissions = groupBasedPermissions;
  _exports.init_default_rights = init_default_rights;
  _exports.is_own_object = is_own_object;
  _exports.rightsPermitEdit = rightsPermitEdit;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  // TODO: Refactor this entire module
  var defaultRights = (0, _abstract.wrap_as_promise)(Ember.Object.create({
    visible: 'PRIVATE',
    editable: 'PRIVATE',
    public_linking: false,
    group_view: [],
    group_edit: [],
    user_view: [],
    user_edit: []
  }));
  const MODELS_SUPPORTING_OWNER_CHANGE = [_specimen.default, _observation.default, _sequence.default, _strain.default, _occurrence.default, _materialsample.default, _file.default];
  function supportsOwnerChange(target) {
    return MODELS_SUPPORTING_OWNER_CHANGE.some(model => target instanceof model);
  }
  let AccessRights = (_dec = Ember.computed.equal('visible', 'PUBLIC'), _dec2 = Ember.computed.equal('visible', 'WORKGROUPS'), _dec3 = Ember.computed.equal('visible', 'PRIVATE'), _dec4 = Ember.computed.equal('editable', 'WORKGROUPS'), _dec5 = Ember.computed.equal('editable', 'PRIVATE'), (_class = class AccessRights extends Ember.Object {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "target", null);
      _initializerDefineProperty(this, "public", _descriptor, this);
      _initializerDefineProperty(this, "visibleToSelected", _descriptor2, this);
      _initializerDefineProperty(this, "visibleToOwner", _descriptor3, this);
      _initializerDefineProperty(this, "editableBySelected", _descriptor4, this);
      _initializerDefineProperty(this, "editableByOwner", _descriptor5, this);
      _defineProperty(this, "visible", 'PRIVATE');
      _defineProperty(this, "editable", 'PRIVATE');
      _defineProperty(this, "public_linking", false);
      _defineProperty(this, "group_view", []);
      _defineProperty(this, "group_edit", []);
      _defineProperty(this, "user_view", []);
      _defineProperty(this, "user_edit", []);
      _defineProperty(this, "ownedByCurrentUser", false);
      _defineProperty(this, "canDelete", false);
      _defineProperty(this, "canModify", false);
      _defineProperty(this, "derivedFrom", null);
      _defineProperty(this, "ownerModified", false);
    }
    makeAccessHash() {
      let data = {
        access_view: this.visible,
        access_edit: this.editable,
        public_linking: this.public_linking,
        group_view: [],
        group_edit: [],
        user_view: [],
        user_edit: []
      };
      if (this.visibleToSelected) {
        data.group_view = this.group_view.mapBy('url');
        data.user_view = this.user_view.mapBy('url');
      }
      if (this.editableBySelected && !this.visibleToOwner) {
        data.group_edit = this.group_edit.mapBy('url');
        data.user_edit = this.user_edit.mapBy('url');
      }
      return data;
    }
    async saveWithTarget(accessTarget) {
      if (Ember.isEmpty(accessTarget) || !this.canModifyPermissions) {
        return null;
      }
      const accessTargets = (0, _structures.makeArray)(accessTarget);
      let data = this.makeAccessHash();
      data.hyperlinks = accessTargets.map(_reflection.get_record_url);
      await this.ajax.request(_environment.default.API_HOST + '/access/', {
        method: 'POST',
        data: JSON.stringify(data),
        processData: false,
        contentType: 'application/json; charset=UTF-8'
      });
      if (this.ownerModified && this.owner) {
        // XXX: Need to pass store during creation, but now is not the time
        // for proper refactor
        const store = accessTargets[0].store;
        let ctypes = new Map();
        await Ember.RSVP.all(accessTargets.filter(supportsOwnerChange).map(async target => {
          const ctype = await (0, _content_types.get_object_ctype)(store, target);
          if (!ctypes.has(ctype)) {
            ctypes.set(ctype, []);
          }
          ctypes.get(ctype).push(target);
        }));
        const bulkAccessEndpoint = `${_environment.default.API_HOST}/access/bulk-update/`;
        await Ember.RSVP.all(Array.from(ctypes).map(([ctype, targets]) => {
          // Change local-only state so that owner change is reflected without needing
          // to reload a target
          targets.forEach(target => target.set('owner', this.owner));

          // TODO: Get proper ajax instance
          return this.ajax.request(bulkAccessEndpoint, {
            method: 'POST',
            processData: false,
            contentType: 'application/json; charset=UTF-8',
            data: JSON.stringify(Object.assign({}, data, {
              new_owner: (0, _reflection.get_record_url)(this.owner),
              content_type: (0, _reflection.get_record_url)(ctype),
              object_list: targets.mapBy('id'),
              change_related_files: Boolean(this.fileOwnerModified)
            }))
          });
        }));
      }
    }

    // NB: Does nothing if ownedByCurrentUsers is false
    save() {
      return this.saveWithTarget(this.target);
    }
    canView(user) {
      var owner = this.get('target.owner');
      var ownerIsUser = owner === user;
      // TODO: How to get workgroups for a user?
      return this.public || ownerIsUser || this.visibleToSelected && this.user_view.includes(user);
    }
    canEdit(user) {
      var owner = this.get('target.owner');
      var ownerIsUser = owner === user;
      return ownerIsUser || this.editableBySelected.includes(user);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "public", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "visibleToSelected", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "visibleToOwner", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "editableBySelected", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "editableByOwner", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  const RIGHTS_FIELDS = ['visible', 'editable', 'public_linking', 'group_view', 'group_edit', 'user_edit', 'user_view'];
  async function create_access_rights(store, target) {
    const defaultRightsFields = (await defaultRights).getProperties(RIGHTS_FIELDS);
    const profile = (0, _reflection.getService)(store, 'settings');
    const currentUser = await profile.getUser();
    const {
      default_owner
    } = await profile.wait();
    const owner = default_owner || currentUser;
    const ajax = (0, _ajax.ajaxFromControlled)(store);
    let fields = {
      store,
      ajax,
      target,
      canModify: true,
      canDelete: true,
      ownedByCurrentUser: owner === currentUser,
      canModifyPermissions: true,
      owner
    };

    // Making deep copy of defaultAccess group/user lists.
    Object.keys(defaultRightsFields).forEach(key => {
      fields[key] = (0, _copyAnything.copy)(defaultRightsFields[key]);
    });
    return AccessRights.create(fields);
  }
  function init_default_rights(defaultPermissionsPromise) {
    defaultRights = (0, _abstract.wrap_as_promise)(defaultPermissionsPromise);
  }

  // If the record's owner is the current user:
  // * All fields reflect actual permissions
  // * ownedByCurrentUser = true, canModify = true
  //
  // Otherwise:
  // * fields are meaningless, except for
  // * ownedByCurrentUser = false, canModify = true/false, depending on whether the current user can modify the record
  //
  // If the second case, taxonomy/access view doesn't show a permissions edit form
  function get_permissions(store, ajax, record, {
    jsonAPI = false
  } = {}) {
    var url = (0, _reflection.get_record_url)(record) + 'permissions/';
    var currentUserPromise = (0, _reflection.getService)(store, 'settings').getUser();
    return ajax.request(url).then(function (answer) {
      const _access = jsonAPI ? answer.data : answer;

      // XXX: Area rights have only { can_edit }
      const access = Object.assign({
        groups_view: [],
        groups_change: [],
        users_view: [],
        users_change: [],
        access_view: record.is_public ? 'PUBLIC' : 'PRIVATE',
        access_edit: 'PRIVATE'
      }, _access);

      // Turns out full json is returned only to the owner, other get { access_edit :: bool }
      return Ember.RSVP.Promise.resolve(record).then(function () {
        return currentUserPromise.then(function (currentUser) {
          var groups_view = access.groups_view.map(_pushToStore.default.bind(undefined, store, 'users/workgroup'));
          var groups_edit = access.groups_change.map(_pushToStore.default.bind(undefined, store, 'users/workgroup'));
          var users_view = access.users_view.map(_pushToStore.default.bind(undefined, store, 'users/user'));
          var users_edit = access.users_change.map(_pushToStore.default.bind(undefined, store, 'users/user'));
          var visible = access.access_view;
          var editable = access.access_edit;
          var public_linking = access.public_linking;
          return record.get('owner').then(function (owner) {
            // Comparing by IDs because of annotations (see extendPermissionsToModerators)
            const owned = owner && Ember.get(owner, 'id') === Ember.get(currentUser, 'id');
            return AccessRights.create({
              store,
              ajax,
              visible: visible,
              editable: editable,
              public_linking: public_linking,
              user_view: users_view,
              user_edit: users_edit,
              group_view: groups_view,
              group_edit: groups_edit,
              target: record,
              ownedByCurrentUser: owned,
              canDelete: access.can_delete,
              canModify: owned || access.can_edit,
              canModifyPermissions: owned && !access.derived_from,
              owner,
              derivedFrom: access.derived_from
            });
          });
        });
      });
    });
  }
  async function is_own_object(store, record) {
    const user = await (0, _reflection.getService)(store, 'settings').getUser();
    const owner = await record.get('owner');
    return owner === user;
  }

  // Check that user with profile @profile will be allowed to edit object with permissions described by @rights
  //
  // Ideally, this takes user instead of profile, but group memberships are apparrently a state secret, so we can
  // only test current user against rights
  async function rightsPermitEdit(ajax, rights, profile) {
    const user = await profile.user;
    if (rights.owner === user) {
      return true;
    }
    const onlyOwner = rights.editableByOwner || rights.visibleToOwner;
    if (!onlyOwner && rights.user_edit.includes(user)) {
      return true;
    }
    if (onlyOwner || rights.group_edit.length === 0) {
      return false;
    }
    const response = await ajax.request((0, _reflection.get_record_url)(profile) + 'user_in_groups/', {
      data: {
        groups: rights.group_edit.mapBy('id').join(',')
      }
    });
    return response.user_in_groups;
  }

  // TODO: Is it even worth it to not just pass the resolved group here?
  async function groupBasedPermissions(store, record, {
    getManagingGroup
  } = {}) {
    let canModify = false;
    const user = await (0, _reflection.getService)(store, 'settings').getUser();
    const owner = await record.created_by;
    const isOwner = owner && user.id === owner.id;
    if (isOwner) {
      canModify = true;
    } else if (Ember.isPresent(getManagingGroup)) {
      const group = await getManagingGroup();
      canModify = group && group.is_admin;
    }
    return {
      canDelete: canModify,
      canModify
    };
  }

  // TODO: This should probably take project in as well, so we can
  // default to project managing group as well (or managing group,
  // though unmanaged collections will then work differently, so
  // probably not)
  //
  // And in a better world, of course, none of this would be
  // necessary
  async function createDerivedRecordRights(store, record, {
    collection,
    project
  } = {}) {
    if (collection || project) {
      const ajax = (0, _ajax.ajaxFromControlled)(store);
      const user = await (0, _reflection.getService)(store, 'settings').getUser();
      const owner = await record.owner;
      const ownedByCurrentUser = owner === user;
      const access = AccessRights.create({
        ajax,
        ownedByCurrentUser,
        canDelete: true,
        canModify: true,
        canModifyPermissions: ownedByCurrentUser
      });
      access.visible = 'PUBLIC';
      access.editable = 'WORKGROUPS';
      access.derivedFrom = collection || project;
      if (collection) {
        const group = await collection.managing_group;
        if (group) {
          access.group_edit.push(group);
        }
      }
      if (project) {
        const group = await project.managing_group;
        if (group) {
          access.group_edit.push(group);
        }
      }
      return access;
    } else {
      const access = await create_access_rights(store, record);
      return access;
    }
  }
});