define("plutof/templates/components/search/gallery-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "suByuHDj",
    "block": "{\"symbols\":[\"@select\",\"@entry\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"gallery__thumbnail \",[30,[36,1],[[32,2,[\"image\",\"selected\"]],\"gallery__thumbnail--selected\"],null]]]],[12],[2,\"\\n\"],[6,[37,1],[[32,2,[\"image\",\"small_link\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[11,\"img\"],[16,\"src\",[31,[[32,2,[\"image\",\"small_link\"]]]]],[24,0,\"gallery__thumbnail__img clickable\"],[4,[38,0],[[32,0],[32,1]],null],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[11,\"div\"],[24,0,\"gallery__thumbnail__img clickable\"],[4,[38,0],[[32,0],[32,1]],null],[12],[2,\"\\n            \"],[10,\"span\"],[15,0,[31,[[32,2,[\"icon\"]],\" gallery__thumbnail__icon\"]]],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,\"common/local-link\",[],[[\"@url\"],[[32,2,[\"record\",\"viewURL\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"div\"],[14,0,\"gallery__thumbnail__info\"],[12],[2,\"\\n            \"],[10,\"span\"],[15,0,[31,[\"gallery__thumbnail__info__icon \",[32,2,[\"record\",\"icon\"]]]]],[12],[13],[2,\"\\n\\n            \"],[1,[32,2,[\"record\",\"name\"]]],[2,\"\\n        \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"if\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/search/gallery-item.hbs"
    }
  });
});