define("plutof/templates/components/occurrences-table/-responsive-row/cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "CZp5AQzr",
    "block": "{\"symbols\":[\"@label\",\"&default\"],\"statements\":[[6,[37,1],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],[[32,1]],[[\"class\"],[\"occ-table-responsive-row__cell-label\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[18,2,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"if\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/occurrences-table/-responsive-row/cell.hbs"
    }
  });
});