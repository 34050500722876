define("plutof/translations/eng/query-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    status: {
      500: 'Something went wrong. Our team has been notified.'
    }
  };
});