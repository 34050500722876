define("plutof/templates/components/tables/-bulk-update/selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "4DP6x9CW",
    "block": "{\"symbols\":[\"@disabled\"],\"statements\":[[1,[30,[36,0],null,[[\"type\",\"checked\",\"disabled\"],[\"checkbox\",[32,0,[\"selected\"]],[32,1]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"input\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/tables/-bulk-update/selector.hbs"
    }
  });
});