define("plutof/translations/eng/conservation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    laboratory: {
      title: 'Nature Conservation Lab'
    },
    redList: {
      list: 'Red List',
      fields: {
        area: 'Area'
      },
      index: {
        title: 'Red Lists',
        description: ''
      },
      add: {
        title: 'Add Red List',
        uploadArea: 'Upload GeoJSON',
        geojsonMustHaveExactlyOneFeature: 'GeoJSON must have exactly one feature. Uploaded file contains {{count}}',
        unsupportedGeometryType: 'Only Polygon and MultiPolygon geometries are supported'
      },
      edit: {
        title: 'Edit Red List'
      },
      view: {
        title: 'Red List',
        assessments: 'Assessments'
      }
    },
    redListAssessment: {
      assessment: 'Red List Assessment',
      assessments: 'Red List Assessments',
      iucnFields: 'IUCN',
      index: {
        title: 'Red List Assessments',
        description: ''
      },
      add: {
        title: 'Add Red List Assessment'
      },
      edit: {
        title: 'Edit Red List Assessment'
      },
      view: {
        title: 'Red List Assessment'
      }
    }
  };
});