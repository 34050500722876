define("plutof/components/layout/-module-hub/link-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#link-to @route tagName="button" class="btn btn-default"}}
      <span class={{@icon}}></span>
      {{@title}}
  {{/link-to}}
  
  */
  {
    "id": "Tq8dGAbs",
    "block": "{\"symbols\":[\"@icon\",\"@title\",\"@route\"],\"statements\":[[6,[37,0],null,[[\"tagName\",\"class\",\"route\"],[\"button\",\"btn btn-default\",[32,3]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[15,0,[32,1]],[12],[13],[2,\"\\n    \"],[1,[32,2]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"link-to\"]}",
    "meta": {
      "moduleName": "plutof/components/layout/-module-hub/link-button.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});