define("plutof/translations/est/gis-lab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    moduleHub: {
      import: {
        title: 'Import',
        info: 'Impordi GeoJSON, WKT või KML alad.',
        button: 'Import'
      },
      layers: {
        title: 'Kihid',
        info: 'Halda kaardi kihte.',
        button: 'Kihid'
      }
    },
    import: {
      title: 'Impordi alad',
      sourceHelp: 'Impordi alad geomeetria failidest või teksti kujul geomeetriast.',
      fields: {
        files: 'Ala geomeetria failidest',
        source: 'Ala geomeetria tekstina'
      },
      tabs: {
        list: 'Alad',
        map: 'Kaart'
      },
      actions: {
        fillCommune: 'Täida asukoha info',
        createAreas: 'Loo alad',
        removeAll: 'Eemalda kõik',
        removeSelected: 'Eemalda ({{count}})'
      },
      columns: {
        file: 'Faili nimi',
        name: 'Ala nimi',
        country: 'Riik',
        district: 'Maakond',
        commune: 'Vald või linn',
        localityText: 'Asukoha tekst',
        parish: 'Kihelkond',
        geom: 'Geomeetria',
        project: 'Projekt'
      }
    },
    information: {
      title: 'Import'
    }
  };
});