define("plutof/translations/eng/table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    actions: {
      copyColumnTooltip: 'Copy column to system clipboard.',
      pinColumnTooltip: 'Pin column to keep it visible when scrolling.'
    }
  };
});