define("plutof/templates/components/filerepository/select-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "HptjQ5Yl",
    "block": "{\"symbols\":[\"@url\",\"@label\",\"@tooltip\"],\"statements\":[[10,\"div\"],[14,0,\"plutof-tooltip-hover\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"plutof-label data-resource-logo-label\"],[12],[2,\"\\n        \"],[1,[32,2]],[2,\"\\n\\n\"],[6,[37,2],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[11,\"button\"],[24,0,\"btn btn-link btn-inline\"],[4,[38,0],[[32,0],\"remove\"],null],[12],[2,\"\\n                \"],[10,\"span\"],[14,0,\"icon-remove--red clickable\"],[12],[13],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\\n\"],[6,[37,2],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"plutof-tooltip\"],[12],[2,\"\\n            \"],[1,[34,3]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"],[6,[37,2],[[32,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"img\"],[15,\"src\",[32,1]],[14,0,\"org-logo img-responsive img-rounded\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"img\"],[14,\"src\",\"\"],[14,1,\"selected-image\"],[14,0,\"org-logo img-responsive img-rounded\"],[12],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"selected-img-name\"],[12],[2,\"\\n        \"],[1,[35,1,[\"name\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[11,\"button\"],[24,0,\"btn btn-default img-upload-btn img-rounded\"],[4,[38,0],[[32,0],\"select\"],null],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"far fa-file-image img-upload-btn__icon\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"pendingImage\",\"if\",\"tooltip\",\"haveSelection\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/filerepository/select-image.hbs"
    }
  });
});