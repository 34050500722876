define("plutof/templates/components/tab-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Wv92Dluh",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"tab-group__tablist-container\"],[12],[2,\"\\n    \"],[10,\"ul\"],[15,1,[34,0]],[14,0,\"tab-group__tablist\"],[14,\"role\",\"tablist\"],[12],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[18,1,[[30,[36,1],[\"tablist\"],null]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[18,1,[[30,[36,1],[\"tabs\"],null],[30,[36,3],null,[[\"header\",\"tab\",\"frame\",\"panel\",\"group\"],[[30,[36,2],[\"tab-group/header\"],[[\"group\"],[[32,0]]]],[30,[36,2],[\"tab-group/tab\"],[[\"group\"],[[32,0]]]],[30,[36,2],[\"tab-group/tab-frame\"],[[\"group\"],[[32,0]]]],[30,[36,2],[\"tab-group/tab-panel\"],null],[32,0]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"headersContainerId\",\"-named-block-invocation\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/tab-group.hbs"
    }
  });
});