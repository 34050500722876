define("plutof/components/information/content/sequence/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <h4> GBIF import </h4>
  <Information::MarkdownReader @path="taxon/gbif-import" @locale={{@locale}} />
  <Information::Content::General::MapControls @locale={{@locale}} />
  
  */
  {
    "id": "2Jk9C/jJ",
    "block": "{\"symbols\":[\"@locale\"],\"statements\":[[10,\"h4\"],[12],[2,\" GBIF import \"],[13],[2,\"\\n\"],[8,\"information/markdown-reader\",[],[[\"@path\",\"@locale\"],[\"taxon/gbif-import\",[32,1]]],null],[2,\"\\n\"],[8,\"information/content/general/map-controls\",[],[[\"@locale\"],[[32,1]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "plutof/components/information/content/sequence/add.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});