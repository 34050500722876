define("plutof/misc/profiling", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ModelResolveTimerMixin = void 0;
  const ModelResolveTimerMixin = _exports.ModelResolveTimerMixin = Ember.Mixin.create({
    beforeModel: function (transition, queryParams) {
      this._super(transition, queryParams);
      this.set('__beforeModel_timestamp', Date.now());
    },
    afterModel: function (resolvedModel, transition, queryParams) {
      this._super(resolvedModel, transition, queryParams);
      const resolve_time = Date.now() - this.__beforeModel_timestamp;
      /* eslint-disable no-console */
      console.debug('Time elapsed for model resolving: ', resolve_time);
      /* eslint-enable no-console */
    }
  });
});