define("plutof/components/layout/regular-grid", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _class2;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  // Prefer usually to handle layout in component-specific css, but this one seems OK (probably because
  // it gets applied to generic detail views etc)
  let RegularGridLayout = (_dec = (0, _component.tagName)(''), _dec2 = Ember.computed('columns', 'gapless'), _dec3 = Ember.computed('columns', 'static'), _dec(_class = (_class2 = class RegularGridLayout extends Ember.Component {
    get style() {
      let parts = ['display:grid'];
      if (!this.gapless) {
        // Same layout as bootstrap grid
        parts.push('grid-column-gap: 12px');
      }
      return Ember.String.htmlSafe(parts.join('; '));
    }
    get dynamicGridClass() {
      return `dynamic-grid-${this.columns}`;
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "style", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "style"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "dynamicGridClass", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "dynamicGridClass"), _class2.prototype)), _class2)) || _class);
  var _default = _exports.default = RegularGridLayout;
});