define("plutof/components/analysis/ml-sh/-predictions-item", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <li class="analysis-model-predictions__prediction {{this.confidenceClass}}">
      {{yield}}
  
      {{#if @confidence}}
          ({{this.formattedConfidence}})
      {{/if}}
  </li>
  
  */
  {
    "id": "lg+SU8X4",
    "block": "{\"symbols\":[\"&default\",\"@confidence\"],\"statements\":[[10,\"li\"],[15,0,[31,[\"analysis-model-predictions__prediction \",[32,0,[\"confidenceClass\"]]]]],[12],[2,\"\\n    \"],[18,1,null],[2,\"\\n\\n\"],[6,[37,0],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"        (\"],[1,[32,0,[\"formattedConfidence\"]]],[2,\")\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "plutof/components/analysis/ml-sh/-predictions-item.hbs"
    }
  });
  let AnalysisPredictionsItem = _exports.default = (_dec = Ember.computed('args.confidence'), _dec2 = Ember.computed('args.confidence'), (_class = class AnalysisPredictionsItem extends _component.default {
    get confidenceClass() {
      if (!Ember.isNone(this.args.confidence)) {
        if (this.args.confidence >= 0.8) {
          return 'analysis-model-predictions__prediction--confident';
        } else if (this.args.confidence < 0.2) {
          return 'analysis-model-predictions__prediction--worthless';
        }
      }
      return '';
    }
    get formattedConfidence() {
      if (Ember.isNone(this.args.confidence)) {
        return '';
      }
      return `${(this.args.confidence * 100).toFixed(2)}%`;
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "confidenceClass", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "confidenceClass"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "formattedConfidence", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "formattedConfidence"), _class.prototype)), _class));
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, AnalysisPredictionsItem);
});