define("plutof/controllers/photobank/view", ["exports", "plutof/utils/reflection"], function (_exports, _reflection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let PhotobankViewController = (_dec = Ember.computed('model.object.samplingevent.id'), _dec2 = Ember._action, _dec3 = Ember._action, (_class = class PhotobankViewController extends Ember.Controller {
    init() {
      super.init(...arguments);

      // TODO: Views should allow to override their strings partially
      this.set('areaEventViewStrings', {
        SamplingEvent: {
          gatheringAgents: 'Photobank.gatheringAgents'
        },
        General: {
          samplingAreaEvent: 'Photobank.areaEventTitle'
        }
      });
    }
    get observationListClipboardQuery() {
      const eventID = this.get('model.object.samplingevent.id');
      return eventID ? {
        samplingevent: eventID
      } : null;
    }
    newObservation() {
      const eventId = this.get('model.object.samplingevent.id');
      return this.transitionToRoute('observation.add', {
        queryParams: {
          event_id: eventId
        }
      });
    }
    taxonID(taxon) {
      return (0, _reflection.recordURLToID)(taxon.url);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "observationListClipboardQuery", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "observationListClipboardQuery"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "newObservation", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "newObservation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "taxonID", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "taxonID"), _class.prototype)), _class));
  var _default = _exports.default = PhotobankViewController;
});