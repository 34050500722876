define("plutof/utils/annotation/moderation", ["exports", "plutof/utils/annotation/format", "plutof/utils/reflection"], function (_exports, _format, _reflection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.acceptAnnotation = acceptAnnotation;
  _exports.rejectAnnotation = rejectAnnotation;
  async function acceptAnnotation(annotation, comment = '') {
    const ajax = (0, _reflection.getService)(annotation.store, 'ajax');
    if (annotation.constructor.modelName === 'annotation/client-annotation') {
      const emberDataStore = (0, _reflection.getService)(annotation.store, 'ember-data-store');
      await (0, _format.applyAnnotation)(ajax, emberDataStore, annotation);
    }
    const url = (0, _reflection.get_record_url)(annotation);
    await ajax.post(url + 'accept/', {
      data: {
        comment
      }
    });
  }
  async function rejectAnnotation(annotation, comment = '') {
    const ajax = (0, _reflection.getService)(annotation.store, 'ajax');
    const url = (0, _reflection.get_record_url)(annotation);
    await ajax.post(url + 'reject/', {
      data: {
        comment
      }
    });
  }
});