define("plutof/utils/objects", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.deepEquality = deepEquality;
  _exports.ensurePathExists = ensurePathExists;
  _exports.objects_are_equal = objects_are_equal;
  function objects_are_equal(x, y) {
    if (Ember.isNone(x) || Ember.isNone(y)) {
      return x === y;
    }
    return Object.keys(x).concat(Object.keys(y)).every(function (key) {
      return x[key] === y[key];
    });
  }

  // For JSONs
  function deepEquality(x, y) {
    if (x === y) {
      return true;
    }
    const tx = typeof x;
    const ty = typeof y;
    if (tx !== ty) {
      return false;
    }
    if (tx !== 'object') {
      return false;
    }
    if (!x || !y) {
      return !x === !y;
    }
    if (Ember.isArray(x) !== Ember.isArray(y)) {
      return false;
    }
    if (Ember.isArray(x)) {
      return x.length === y.length && x.every((el, idx) => deepEquality(y[idx], el));
    } else {
      const keysx = Object.keys(x).sort();
      const keysy = Object.keys(y).sort();
      if (!deepEquality(keysx, keysy)) {
        return false;
      }
      return keysx.every(key => deepEquality(x[key], y[key]));
    }
  }
  function ensurePathExists(obj, path, leafValue = {}) {
    const pathParts = Ember.isArray(path) ? path : path.split('.');
    for (let i = 0, node = obj; i < pathParts.length; i++) {
      const part = pathParts[i];
      if (!(part in node)) {
        node[part] = i === pathParts.length - 1 ? leafValue : {};
      }
      node = node[part];
    }
  }
});