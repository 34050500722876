define("plutof/templates/components/sample/gathering-agents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Xytd3nbX",
    "block": "{\"symbols\":[\"add\",\"@agents\"],\"statements\":[[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n    \"],[10,\"div\"],[15,0,[31,[[30,[36,1],[[35,0,[\"isInvalid\"]],\"has-error\"],null]]]],[12],[2,\"\\n        \"],[8,\"auto-complete/bind-multiple\",[],[[\"@value\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,\"agent/person/auto-complete\",[[24,0,\"gathering-agents__agents\"]],[[\"@update\"],[[32,1]]],null],[2,\"\\n        \"]],\"parameters\":[1]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"validations\",\"if\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/sample/gathering-agents.hbs"
    }
  });
});