define("plutof/translations/eng/glossary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    title: 'Keywords',
    searchTitle: 'Search Keywords',
    infoTitle: 'Keywords',
    glossary: 'Keyword',
    newGlossary: 'New Keyword',
    editGlossary: 'Edit Keyword',
    relatedKeywords: 'Related Keywords',
    word: 'Word',
    wordTooltip: 'Define a new keyword or select existing one'
  };
});