define("plutof/components/my-menu/coordinate-converter", ["exports", "@ember-decorators/component", "plutof/utils/notifications"], function (_exports, _component, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _class2;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let MyMenuCoordinateConverter = (_dec = (0, _component.classNames)('my-menu-coordinate-converter'), _dec2 = Ember.computed('latitude', 'longitude'), _dec3 = Ember._action, _dec4 = Ember._action, _dec(_class = (_class2 = class MyMenuCoordinateConverter extends Ember.Component {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "usingDMS", true);
      _defineProperty(this, "latitude", null);
      _defineProperty(this, "longitude", null);
    }
    get showResults() {
      return !Ember.isNone(this.latitude) && !Ember.isNone(this.longitude);
    }
    toggleDMSFormat(usingDMS) {
      this.set('usingDMS', usingDMS);
    }

    // TODO: use CoordinateConverterData instead of the mess below
    convertDegrees(deg, min, sec) {
      return deg.toString() + '.' + (min / 60 + sec / 3600).toFixed(7).toString().split('.')[1];
    }
    convertOddDegrees(lonlat, remainderDegree) {
      return lonlat.toString() + '.' + (remainderDegree / 60).toFixed(7).toString().split('.')[1];
    }
    convertOddToDecimal() {
      /* North Or South (neg value and pos value) Latitude  and the remainder  e.g N41° 52.736*/
      var lat = this.degreeLatitude || '0';
      var latRemainder = this.latitudeRemainder || '0';
      /* West Or East (neg value and pos value) Longitude and the remainer e.g W41° 52.736 */
      var lon = this.degreeLongitude || '0';
      var lonRemainder = this.longitudeRemainder || '0';
      var Longitude = this.convertOddDegrees(lon.replace(/[^\d.-]/g, ''), lonRemainder);
      var Latitude = this.convertOddDegrees(lat.replace(/[^\d.-]/g, ''), latRemainder);
      if (Longitude > 180) {
        Longitude = 180 - Longitude;
      }
      if (Latitude > 180) {
        Latitude = 180 - Latitude;
      }

      // -182 + 364 - 180  == 2 from -182 to 2
      if (Longitude < -180) {
        Longitude = Longitude - 180 + 2 * Math.abs(Longitude);
      }
      if (Latitude < -180) {
        Latitude = Latitude - 180 + 2 * Math.abs(Latitude);
      }
      this.set('latitude', Latitude);
      this.set('longitude', Longitude);
    }
    convertDegreesToDecimal() {
      var isValid = true;
      /* Latitude*/
      var deg1 = this.degree1 || '0';
      var min1 = this.minute1 || '0';
      var sec1 = this.second1 || '0';
      /* Longitude*/
      var deg2 = this.degree2 || '0';
      var min2 = this.minute2 || '0';
      var sec2 = this.second2 || '0';
      if (deg2 > 180) {
        (0, _notifications.displayNotification)('info', this.i18n.t('Map.maxLatitude'));
        isValid = false;
      }
      if (deg1 > 90) {
        (0, _notifications.displayNotification)('info', this.i18n.t('Map.maxLongitude'));
        isValid = false;
      }
      if (isValid) {
        var Latitude = this.convertDegrees(deg1, min1, sec1);
        var Longitude = this.convertDegrees(deg2, min2, sec2);
        this.set('latitude', Latitude);
        this.set('longitude', Longitude);
      } else {
        isValid = true;
        return;
      }
    }

    // XXX TODO: Should use utils/coordinates
    // TODO: Rename degree1 to latDegrees etc
    convert() {
      if (this.usingDMS) {
        this.convertDegreesToDecimal();
      } else {
        this.convertOddToDecimal();
      }
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "showResults", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "showResults"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "toggleDMSFormat", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleDMSFormat"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "convert", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "convert"), _class2.prototype)), _class2)) || _class);
  var _default = _exports.default = MyMenuCoordinateConverter;
});