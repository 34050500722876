define("plutof/components/filerepository/gallery/-model-viewer-preview", ["exports", "@glimmer/component", "ember-concurrency", "plutof/utils/notifications"], function (_exports, _component, _emberConcurrency, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.GalleryModelViewerPreviewController = void 0;
  var _dec, _dec2, _class, _descriptor, _dec3, _class2, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.loadModelViewer.isRunning}}
      <span class="gallery-model-viewer-loading-icon"></span>
  {{else if @model}}
      <div id="model-viewer-container">
          <model-viewer
              src={{@model}}
              loading="auto"
              camera-controls
              touch-action="pan-y"
              shadow-intensity="1"
              class="model-viewer {{if @controller.fullscreen 'model-viewer--fullscreen'}}"
          >
          </model-viewer>
  
          {{#if @controller.fullscreen}}
              <button
                  {{on "click" @controller.toggleFullscreen}}
                  class="exit-fullscreen-button"
              >
                  <span class={{icon "compress"}}></span>
              </button>
          {{/if}}
      </div>
  {{/if}}
  
  */
  {
    "id": "Fypd3ulA",
    "block": "{\"symbols\":[\"@controller\",\"@model\"],\"statements\":[[6,[37,2],[[32,0,[\"loadModelViewer\",\"isRunning\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"gallery-model-viewer-loading-icon\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,1,\"model-viewer-container\"],[12],[2,\"\\n        \"],[10,\"model-viewer\"],[15,\"src\",[32,2]],[14,\"loading\",\"auto\"],[14,\"camera-controls\",\"\"],[14,\"touch-action\",\"pan-y\"],[14,\"shadow-intensity\",\"1\"],[15,0,[31,[\"model-viewer \",[30,[36,2],[[32,1,[\"fullscreen\"]],\"model-viewer--fullscreen\"],null]]]],[12],[2,\"\\n        \"],[13],[2,\"\\n\\n\"],[6,[37,2],[[32,1,[\"fullscreen\"]]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[11,\"button\"],[24,0,\"exit-fullscreen-button\"],[4,[38,0],[\"click\",[32,1,[\"toggleFullscreen\"]]],null],[12],[2,\"\\n                \"],[10,\"span\"],[15,0,[30,[36,1],[\"compress\"],null]],[12],[13],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"on\",\"icon\",\"if\"]}",
    "meta": {
      "moduleName": "plutof/components/filerepository/gallery/-model-viewer-preview.hbs"
    }
  });
  let GalleryModelViewerPreviewController = _exports.GalleryModelViewerPreviewController = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = class GalleryModelViewerPreviewController {
    constructor() {
      _initializerDefineProperty(this, "fullscreen", _descriptor, this);
      // Initialised simply so that we don't have to check for null
      _defineProperty(this, "_fullscreenAbortController", new AbortController());
    }
    toggleFullscreen() {
      const element = document.querySelector('#model-viewer-container');
      if (element) {
        // Fullscreen exit via button.
        if (document.fullscreenElement) {
          document.exitFullscreen();
          this._fullscreenAbortController.abort();
          this.fullscreen = false;
        } else {
          this._fullscreenAbortController = new AbortController();
          document.addEventListener('fullscreenchange', () => {
            if (!document.fullscreenElement) {
              this._fullscreenAbortController.abort();
              this.fullscreen = false;
            }
          }, {
            signal: this._fullscreenAbortController.signal
          });
          element.requestFullscreen().catch(_notifications.reportError);
          this.fullscreen = true;
        }
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fullscreen", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleFullscreen", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "toggleFullscreen"), _class.prototype)), _class));
  let GalleryModelViewerPreview = (_dec3 = (0, _emberConcurrency.task)({
    drop: true
  }), (_class2 = class GalleryModelViewerPreview extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "loadModelViewer", _descriptor2, this);
      this.loadModelViewer.perform();
    }
  }, (_descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "loadModelViewer", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        yield emberAutoImportDynamic("@google/model-viewer");
      };
    }
  })), _class2));
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, GalleryModelViewerPreview);
});