define("plutof/templates/components/sample/navbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "hC+QtGcq",
    "block": "{\"symbols\":[\"navbar\",\"slots\",\"@addRoute\",\"@record\",\"@icon\",\"@title\",\"@module\",\"@editRoute\",\"@annotateRoute\",\"@noDelete\",\"@infoContent\",\"@accessRights\",\"&default\",\"@noCloning\"],\"statements\":[[8,\"navbar/view\",[],[[\"@icon\",\"@title\",\"@record\",\"@module\",\"@editRoute\",\"@annotateRoute\",\"@deletable\",\"@infoTitle\",\"@infoContent\",\"@accessRights\"],[[32,5],[32,6],[32,4],[32,7],[32,8],[32,9],[30,[36,0],[[32,10]],null],[32,6],[32,11],[32,12]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"primaryControls\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[18,13,[[32,1],[30,[36,2],null,[[\"primaryControls\"],[[30,[36,1],[\"named-slot\"],[[\"tagName\"],[\"\"]]]]]]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,2,[\"middleControls\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[18,13,[[32,1],[30,[36,2],null,[[\"middleControls\"],[[30,[36,1],[\"named-slot\"],[[\"tagName\"],[\"\"]]]]]]]],[2,\"\\n\\n        \"],[8,\"clipboard/navbar-button-new\",[],[[\"@navbar\",\"@record\"],[[32,1],[32,4]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,2,[\"secondaryControls\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[32,14]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"sample/navbar-buttons/cloning\",[],[[\"@navbar\",\"@addRoute\",\"@record\"],[[32,1],[32,3],[32,4]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n        \"],[8,\"sample/navbar-buttons/detach\",[],[[\"@navbar\",\"@record\"],[[32,1],[32,4]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"not\",\"component\",\"hash\",\"unless\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/sample/navbar.hbs"
    }
  });
});