define("plutof/controllers/publishing/utils", ["exports", "plutof/config/environment", "plutof/misc/abstract", "plutof/services/ajax", "plutof/utils/group", "plutof/utils/i18n", "plutof/utils/pagination"], function (_exports, _environment, _abstract, _ajax, _group, _i18n, _pagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.RECORD_TYPES = _exports.DATASET_TYPES = void 0;
  _exports.assignDataset = assignDataset;
  _exports.datasetEndpoint = datasetEndpoint;
  _exports.getRecordType = _exports.getDatasetType = _exports.datasetType = void 0;
  _exports.keyedType = keyedType;
  _exports.paginateRecords = paginateRecords;
  _exports.recordType = void 0;
  _exports.userIsAllowedToRegisterDOIs = userIsAllowedToRegisterDOIs;
  const i18n = (0, _i18n.getI18n)();
  function userIsAllowedToRegisterDOIs(store) {
    return (0, _group.userIsInWorkgroup)(store, _environment.default.DATACITE_WORKGROUP_ID);
  }
  const SIDELOADED_RELATIONSHIPS = ['taxon_node', 'project'];
  const GBIFRecordsPaginationMixin = Ember.Mixin.create({
    constructQuery(number) {
      let query = Object.assign({
        'page[number]': number,
        'page[size]': this.pageSize
      }, this.filters);
      query['include'] = this.sideloadedRelationships.join(',');
      return query;
    },
    loadPage(number) {
      const query = this.constructQuery(number);
      const url = (0, _abstract.construct_request)(this.endpoint, query);
      return this.ajax.request(url);
    }
  });
  function paginateRecords(dataset, endpoint, extraMixins = []) {
    const ajax = (0, _ajax.ajaxFromControlled)(dataset);
    const mixins = [GBIFRecordsPaginationMixin].concat(extraMixins);
    return (0, _pagination.paginateJSONAPI)(ajax, endpoint, {
      filters: {
        gbif_dataset: dataset.id
      },
      sideloadedRelationships: SIDELOADED_RELATIONSHIPS,
      mixins
    });
  }

  // Most of the fields are repeated elsewhere
  // TODO: Combine what we can into a single global registry and augment in places
  const RECORD_TYPES = _exports.RECORD_TYPES = [{
    label: i18n.t('Sequences.sequence'),
    // JSON API type, also serves as dataset record_type
    type: 'Sequence',
    model: 'taxonoccurrence/sequence/sequence',
    bulkEndpoint: `${_environment.default.API_HOST}/bulk-update/sequences/`,
    recordsEndpoint: `${_environment.default.API_HOST}/public/sequences/`,
    viewRoute: 'sequence.view',
    searchModule: 'sequence'
  }, {
    label: i18n.t('Specimen.specimen'),
    type: 'Specimen',
    model: 'taxonoccurrence/specimen/specimen',
    bulkEndpoint: `${_environment.default.API_HOST}/bulk-update/specimens/`,
    recordsEndpoint: `${_environment.default.API_HOST}/public/specimens/`,
    viewRoute: 'specimen.view',
    searchModule: 'specimen'
  }, {
    label: i18n.t('Model.observation'),
    type: 'Observation',
    model: 'taxonoccurrence/observation/observation',
    bulkEndpoint: `${_environment.default.API_HOST}/bulk-update/observations/`,
    recordsEndpoint: `${_environment.default.API_HOST}/public/observations/`,
    viewRoute: 'observation.view',
    searchModule: 'observation',
    disableRecordColumn: true
  }, {
    label: i18n.t('General.RefBased'),
    type: 'Reference Based',
    model: 'taxonoccurrence/referencebased/occurrence',
    bulkEndpoint: `${_environment.default.API_HOST}/bulk-update/reference-based/`,
    recordsEndpoint: `${_environment.default.API_HOST}/taxonoccurrence/reference-based-occurrences/`,
    viewRoute: 'referencebased.view',
    searchModule: 'referencebased'
  }];

  // XXX: Needs a better name, stat
  function keyedType(types, by) {
    let keyedTypes = {};
    types.forEach(type => keyedTypes[type[by]] = type);
    function accessor(key) {
      return keyedTypes[key];
    }
    function property(keyProperty) {
      return Ember.computed(keyProperty, {
        get(key) {
          return Ember.isEmpty(this[keyProperty]) ? null : accessor(this[keyProperty]);
        },
        set(key, type) {
          this.set(keyProperty, Ember.isEmpty(type) ? null : type[by]);
          return type;
        }
      });
    }
    return [accessor, property];
  }
  const [getRecordType, recordType] = keyedType(RECORD_TYPES, 'type');
  _exports.recordType = recordType;
  _exports.getRecordType = getRecordType;
  const DATASET_TYPES = _exports.DATASET_TYPES = [{
    id: 'occurrences',
    label: i18n.t('dataResource.types.occurrences'),
    hasRecords: true
  }, {
    id: 'metadata',
    label: i18n.t('dataResource.types.metadata'),
    hasRecords: false
  }];
  const [getDatasetType, datasetType] = keyedType(DATASET_TYPES, 'id');
  _exports.datasetType = datasetType;
  _exports.getDatasetType = getDatasetType;
  function assignDataset(ajax, datasetID, recordType, recordIDs, overwrite = false) {
    return ajax.post(`${recordType.bulkEndpoint}gbif-dataset/`, {
      data: {
        object_list: recordIDs,
        gbif_dataset: datasetID,
        overwrite: overwrite
      }
    });
  }
  function datasetEndpoint(datasetID, endpoint) {
    return `${_environment.default.PUBLISHING_API_HOST}/datasets/${datasetID}/${endpoint}/`;
  }
});