define("plutof/translations/est/sample", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    title: 'Proov',
    samples: 'Proovid',
    newSample: 'Uus proov',
    editSample: 'Muuda proovi andmeid',
    sampleLocality: 'Asukoha andmed',
    sampleHabitat: 'Elupaiga andmed',
    sampleSoil: 'Pinnase andmed',
    sampleData: 'Proovi andmed',
    locality: 'Asukoht',
    data: 'Üldandmed',
    results: 'Tulemused',
    locationInfoButton: 'Saa asukoha info',
    goToButton: 'Kuva kaardil',
    originalINSD: 'Original INSD data',
    samplingArea: 'Prooviala',
    samplingAreas: 'Proovialad',
    existingSamplingArea: 'Olemasolev ala',
    // "Olemasolev vaatlusala" doesn't quite fit where it goes.
    existingSamplingAreaTooltip: 'Olemasoleva ala kasutamine. Uut ala ei lisata.',
    parentSamplingArea: 'Ülemprooviala',
    parentSamplingAreaTooltip: 'Olemasolev ala, mille piires soovid asukohta täpsustada. Lisatakse uus ala.',
    existingEvent: 'Olemasolev sündmus',
    subSamplingArea: 'Alamprooviala',
    newSArea: 'Uus prooviala',
    // XXX RENAME
    editSArea: 'Muuda prooviala',
    // XXX RENAME
    name: 'Nimi',
    country: 'Riik',
    associatedOccurrences: 'Seotud kirjed',
    areaMeasurements: 'Ala tunnused',
    eventMeasurements: 'Sündmuse tunnused',
    geometryOutOfBounds: 'Tähelepanu: ala ei asu ülemalaa või kihtide piirides',
    commune: 'Vald',
    mapTitle: 'Asukoha määramine',
    mapTitleTooltip: 'Kasutades kaardil asukoha märkimiseks punkti või polügoni tööriista, täidetakse asukohainfo väljad võimalusel automaatselt.',
    countrySearchPlaceholder: 'Leia riik...',
    countrySearchTooltip: 'Leia riik kaardil. Kasulik leidmaks kiiresti väikeseid riike.',
    pointSearchPlaceholder: 'Kohanimi või koordinaadid',
    pointSearchTooltip: `Asukoha nimi, aadress või koordinaadid kujul "laiuskraad pikkuskraad".
        Koordinaadid eraldatakse komaga või tühikuga; komakoha eraldajaks on punkt.<br/>
        <br/>
        Vale: 58,4255, 26,3020<br/>
        Õige: 58.4255 26.3020`,
    areaSelectPlaceholder: 'Vali projekti prooviala',
    areaSelectTooltip: 'Proovialad on kasutatavad ainult siis kui projekt on valitud.',
    habitatImage: 'Märgi prooviala pildina',
    detach: {
      label: 'Ühenda lahti',
      tooltip: 'Ühenda sündmusest lahti'
    },
    formSelector: {
      placeholder: 'Vormi nimi...'
    },
    event: {
      add: 'Lisa sündmus',
      edit: 'Muuda sündmust',
      view: 'Vaata sündmust',
      attributes: 'Atribuudid',
      habitat: 'Elupaik',
      gatheringAgents: 'Koguja(d)',
      gatheringAgentsTooltip: 'Kui kogujaid pole märgitud, siis käsitleme neid kui \'puuduvaid\'',
      timespanBegin: 'Alguskuupäev ja -aeg',
      timespanEnd: 'Lõpukuupäev ja -aeg',
      beginDate: 'Alguse kuupäev',
      beginTime: 'Alguse aeg',
      endDate: 'Lõpu kuupäev',
      endTime: 'Lõpu aeg',
      community: {
        paal: 'Paal',
        waterbody: 'Veekogud'
      }
    },
    area: {
      accuracy: 'Täpsus (m)',
      nameTooltip: 'Prooviala kirjeldav lühike nimi, näiteks: Järvselja ürgmets',
      restricted: 'Piiratud liigipääs',
      areasTitle: 'Alad',
      areasSubtitle: 'Proovialad ja eeldefineeritud alad',
      areasInfo: 'Vaata, muuda, lisa proovi- ja püsialasid.',
      areasButton: 'Alad',
      uploadPolygon: 'Laadi üles GeoJSON',
      createFromGeoJSON: 'Loo prooviala',
      geometry: 'Geomeetria',
      areaSelectionHelper: 'Proovialade valimine',
      map: {
        searchFailed: 'Otsing ei leidnud midagi: {{query}}'
      }
    },
    habitat: {
      habitat: 'Elupaik',
      envo: {
        short: {
          envo_biome: 'Broad',
          envo_local_environmental_context: 'Local',
          envo_environmental_medium: 'Medium'
        }
      }
    }
  };
});