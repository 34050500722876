define("plutof/translations/est/layer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    layer: 'Kiht',
    layers: 'Kihid',
    newLayer: 'Uus kiht',
    editLayer: 'Muuda kihti',
    layerArea: 'Ala',
    tabs: {
      areas: 'Alad',
      map: 'Kaart'
    },
    area: {
      name: 'Nimi',
      geom: 'Geomeetria'
    },
    actions: {
      filterAreas: 'Filtreeri nime järgi',
      deleteArea: 'Kustuta ala',
      findInList: 'Leia ala nimekirjast'
    },
    popup: {
      layer: 'Kiht',
      area: 'Ala'
    },
    info: {
      geomError: {
        label: 'Geomeetria puudub',
        tooltip: 'Alasid, millel puudub geomeetria, ei saa kihti salvestada.'
      },
      oneAreaPerLayerError: 'Ei saa siduda rohkem kui ühe kihi alaga. Kirje on juba seotud alaga {{area}} kihis {{layer}}',
      areaOutsideLayerError: 'Kihi ala jääb proovialast välja'
    }
  };
});