define("plutof/templates/components/study/project-areas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "e6ey9G6C",
    "block": "{\"symbols\":[\"panel\",\"@projectAreas\",\"@remove\",\"@add\"],\"statements\":[[8,\"plutof-panel/item-list\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"header\"]],[],[[\"@title\",\"@count\"],[[30,[36,0],[\"Projects.predefinedAreas\"],null],[32,2,[\"length\"]]]],null],[2,\"\\n\\n    \"],[8,[32,1,[\"content\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"study/-project-areas-table\",[],[[\"@projectAreas\",\"@editable\",\"@remove\"],[[32,2],true,[32,3]]],null],[2,\"\\n\\n        \"],[10,\"div\"],[14,0,\"spacer-below\"],[12],[13],[2,\"\\n\\n        \"],[8,\"sample/area/auto-complete\",[],[[\"@update\",\"@site\"],[[32,4],true]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/study/project-areas.hbs"
    }
  });
});