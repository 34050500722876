define("plutof/routes/search/index", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "plutof/misc/profile_settings", "plutof/mixins/route"], function (_exports, _authenticatedRouteMixin, _profile_settings, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class SearchIndexRoute extends Ember.Route.extend(_authenticatedRouteMixin.default, _route.default) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "title", 'General.Search');
    }
    model(params) {
      return Ember.RSVP.hash({
        personalSettings: (0, _profile_settings.get_personal_settings)(this.store)
      });
    }
    setupController(controller, model) {
      super.setupController(controller, model);
      controller.set('showFilterPanels', false);
      // It's set directly into controller because ProfilePrefedinedColumns mixing depends on it.
      controller.set('personalSettings', model.personalSettings);

      // Every route hook fires before url changes
      Ember.run.scheduleOnce('afterRender', controller.searchFromURL, controller.searchFromURL.perform);
    }
  }
  var _default = _exports.default = SearchIndexRoute;
});