define("plutof/translations/est/table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    actions: {
      copyColumnTooltip: 'Kopeeri tulp süsteemi lõikelauale.',
      pinColumnTooltip: 'Kinnita tulp, et see oleks kerides nähtav.'
    }
  };
});