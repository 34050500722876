define("plutof/components/forms/view/fields", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @traits.length}}
      <div class="plutof-label">{{@label}}</div>
  
      <div class="mainform-measurements">
          {{#each @traits as |trait|}}
              {{#link-to "trait.view" trait.id
                  class=(concat @labelClass " form-items")
              }}
                  <div class="form-item__content" data-test="trait">
                      <b>
                          {{trait.name}} [{{trait.type}}]
                      </b>
  
                      <span> - </span>
  
                      {{trait.description}}
                  </div>
              {{/link-to}}
          {{/each}}
      </div>
  
      <div class="spacing"></div>
  {{/if}}
  
  */
  {
    "id": "ZjcKJFuf",
    "block": "{\"symbols\":[\"trait\",\"@labelClass\",\"@label\",\"@traits\"],\"statements\":[[6,[37,4],[[32,4,[\"length\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"plutof-label\"],[12],[1,[32,3]],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"mainform-measurements\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],null,[[\"class\",\"route\",\"model\"],[[30,[36,0],[[32,2],\" form-items\"],null],\"trait.view\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"                \"],[10,\"div\"],[14,0,\"form-item__content\"],[14,\"data-test\",\"trait\"],[12],[2,\"\\n                    \"],[10,\"b\"],[12],[2,\"\\n                        \"],[1,[32,1,[\"name\"]]],[2,\" [\"],[1,[32,1,[\"type\"]]],[2,\"]\\n                    \"],[13],[2,\"\\n\\n                    \"],[10,\"span\"],[12],[2,\" - \"],[13],[2,\"\\n\\n                    \"],[1,[32,1,[\"description\"]]],[2,\"\\n                \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"spacing\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"concat\",\"link-to\",\"-track-array\",\"each\",\"if\"]}",
    "meta": {
      "moduleName": "plutof/components/forms/view/fields.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});