define("plutof/adapters/drf-config", ["exports", "ember-django-adapter/adapters/drf", "plutof/config/environment"], function (_exports, _drf, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _drf.default.extend({
    host: Ember.computed(function () {
      return _environment.default.API_HOST;
    }),
    namespace: Ember.computed(function () {
      return _environment.default.API_NAMESPACE;
    })
  });
});