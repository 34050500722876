define("plutof/components/filerepository/select-image", ["exports", "plutof/misc/fileupload"], function (_exports, _fileupload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let SelectImage = (_dec = Ember.computed('url', 'pendingImage'), _dec2 = Ember._action, _dec3 = Ember._action, (_class = class SelectImage extends Ember.Component {
    get haveSelection() {
      return !Ember.isEmpty(this.url) || !Ember.isEmpty(this.pendingImage);
    }
    showImage(image) {
      const reader = new FileReader();
      reader.onload = function (e) {
        // TODO: Shouldn't be ID. Instead use a second component that
        // takes a local file and renders it
        const preview = document.getElementById('selected-image');
        preview.setAttribute('src', e.target.result);
      };
      reader.readAsDataURL(image);
    }
    setImage(file) {
      this.set('pendingImage', file);
      this.changed(file);
    }
    remove() {
      const preview = document.getElementById('selected-image');
      if (preview) {
        preview.setAttribute('src', '');
      }
      this.setImage(null);
    }
    select() {
      (0, _fileupload.selectFile)({
        accept: 'image/*'
      }).then(image => {
        if (image) {
          this.setImage(image);
          this.showImage(image);
        }
      });
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "haveSelection", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "haveSelection"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "remove", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "remove"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "select", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "select"), _class.prototype)), _class));
  var _default = _exports.default = SelectImage;
});