define("plutof/components/interaction/view-all", ["exports", "ember-concurrency", "plutof/misc/abstract", "plutof/misc/content_types", "plutof/utils/store"], function (_exports, _emberConcurrency, _abstract, _content_types, _store) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let InteractionView = (_dec = (0, _emberConcurrency.task)({
    restartable: true
  }), (_class = class InteractionView extends Ember.Component {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "object", null);
      _defineProperty(this, "total", 0);
      _initializerDefineProperty(this, "load", _descriptor, this);
    }
    didReceiveAttrs() {
      this.load.perform();
    }
    loadInteractions(ctype, id) {
      return (0, _store.query)(this.store, 'taxonoccurrence/interaction/interaction', {
        content_type: ctype.id,
        object_id: id
      });
    }
    loadInteractings(ctype, id) {
      return (0, _store.query)(this.store, 'taxonoccurrence/interaction/interaction', {
        interacting_content_type: ctype.id,
        interacting_object_id: id
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "load", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        const object = yield this.object;
        const parent = yield this.parent;
        const ctype = yield (0, _content_types.get_object_ctype)(this.store, object);
        let props = {
          interactions: this.loadInteractions(ctype, object.id),
          interactings: this.loadInteractings(ctype, object.id),
          parentInteractions: [],
          parentInteractings: []
        };
        if (parent) {
          const parentCType = yield (0, _content_types.get_object_ctype)(this.store, parent);
          const parentInteractionPromises = [this.loadInteractions(parentCType, parent.id), this.loadInteractings(parentCType, parent.id)];
          let grandparentInteractionPromises = [[], []];
          if (parentCType.full_name === 'sequence/sequence' || parentCType.full_name === 'livingculture/strain') {
            const gpContentType = yield parent.content_type;
            if (gpContentType) {
              grandparentInteractionPromises = [this.loadInteractions(gpContentType, parent.object_id), this.loadInteractings(gpContentType, parent.object_id)];
            }
          }
          const [parentInteractions, parentInteractings] = yield Ember.RSVP.all(parentInteractionPromises);
          const [gpInteractions, gpInteractings] = yield Ember.RSVP.all(grandparentInteractionPromises);
          props.parentInteractions = gpInteractions.concat(parentInteractions);
          props.parentInteractings = gpInteractings.concat(parentInteractings);
        }
        const loaded = yield Ember.RSVP.hash(props);
        this.setProperties(loaded);
        this.set('total', (0, _abstract.sum)(Object.values(loaded).map(is => is.length)));
      };
    }
  })), _class));
  var _default = _exports.default = InteractionView;
});