define("plutof/authenticators/plutof", ["exports", "ember-simple-auth/authenticators/oauth2-password-grant", "plutof/config/environment"], function (_exports, _oauth2PasswordGrant, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PlutoFAuthenticator = _oauth2PasswordGrant.default.extend({
    serverTokenEndpoint: _environment.default.AUTH_SERVER_TOKEN_ENDPOINT,
    rejectWithResponse: true,
    sendClientIdAsQueryParam: true,
    makeRequest: function (url, data) {
      data.client_id = _environment.default.API_CLIENT_ID;
      data.client_secret = _environment.default.API_CLIENT_SECRET;
      return this._super(url, data);
    }
  });
  var _default = _exports.default = PlutoFAuthenticator;
});