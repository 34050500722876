define("plutof/routes/taxon-description/add", ["exports", "plutof/utils/routes", "plutof/components/taxonomy-lab/taxon-description/edit"], function (_exports, _routes, _edit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class TaxonDescriptionAddRoute extends _routes.AddRoute {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "defaultReturnRoute", 'taxon-description.index');
    }
    async model() {
      if (this.get('controller.routeHasBeenLoaded')) {
        return undefined;
      }
      const taxonDescription = await _edit.TaxonDescriptionEditModel.create(this.store);
      return {
        taxonDescription
      };
    }
    getTitle(model) {
      return model.taxonDescription.taxonDescription.representation;
    }
  }
  var _default = _exports.default = TaxonDescriptionAddRoute;
});