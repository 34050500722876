define("plutof/components/clipboard/external-actions", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  // XXX
  let ClipboardExternalActions = (_dec = (0, _component.tagName)('span'), _dec(_class = class ClipboardExternalActions extends Ember.Component {}) || _class);
  var _default = _exports.default = ClipboardExternalActions;
});