define("plutof/translations/est/error-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    notFoundTitle: 'Probleem lehe laadimisel.',
    notFoundMsg: 'Kahjuks teie valitud lehte ei leitud või puudub ligipääs.',
    backHome: 'Tagasi avalehele.',
    maintenanceInProgress: 'Toimub plaaniline serverite hooldus'
  };
});