define("plutof/components/map/mixins/preferred-layerset", ["exports", "plutof/misc/config", "plutof/misc/profile_settings"], function (_exports, _config, _profile_settings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const LAYERSET_CODES = {
    'eesti': 'maaamet',
    'open_layers_3': 'osm',
    'mapbox': 'mapbox'
  };
  const PreferredLayersetMixin = Ember.Mixin.create({
    init() {
      this._super(...arguments);
      this.setPreferredLayerset();
      this.selectLayerset();
    },
    setPreferredLayerset: Ember.observer('country.code', function () {
      (0, _profile_settings.get_personal_settings)(this.store).then(settings => {
        // Handle users that are not logged in
        if (Ember.isNone(settings)) {
          this.set('preferredLayerset', this.defaultLayerset);
          return;
        }
        let layerset = settings.get('default_map');
        const optionalLayer = settings.get('default_map_layer');
        const countryCode = this.get('country.code');
        const notEstonia = !Ember.isNone(countryCode) && countryCode !== 'EST';
        const isMaaametLayerset = layerset === _config.default.Sample.MAAAMET_LAYERSET;

        // Override default Maa-amet layerset if country is not Estonia.
        // When no country is set, the layer can be used.
        if (isMaaametLayerset && notEstonia) {
          layerset = this.defaultLayerset;
        }
        this.set('preferredOptionalLayer', optionalLayer);
        this.set('preferredLayerset', layerset);
      });
    }),
    preferredLayerset: _config.default.Sample.DEFAULT_LAYERSET,
    defaultLayerset: _config.default.Sample.DEFAULT_LAYERSET,
    // XXXX
    typeChanged: Ember.observer('preferredLayerset', function () {
      const preferred = LAYERSET_CODES[this.preferredLayerset];
      if (this.insertedIntoDOM && this.get('layerSelection.layerset.name') !== preferred) {
        this.reinitializeMap();
      }
    }),
    selectLayerset: Ember.observer('preferredLayerset', 'preferredOptionalLayer', 'insertedIntoDOM', function () {
      if (!this.insertedIntoDOM) {
        return;
      }
      var code = this.preferredLayerset;
      if (Ember.isEmpty(code)) {
        code = _config.default.Sample.DEFAULT_LAYERSET;
      }
      this.layerSelection.switchLayerset(LAYERSET_CODES[code]);
      this.selectOptionalLayer(this.preferredOptionalLayer);
    }),
    selectOptionalLayer: function (optionalLayer) {
      if (Ember.isPresent(optionalLayer) && this.insertedIntoDOM) {
        const layerSelection = this.layerSelection;
        Ember.run.next(() => {
          layerSelection.activateOptional(optionalLayer);
        });
      }
    }
  });
  var _default = _exports.default = PreferredLayersetMixin;
});