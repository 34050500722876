define("plutof/templates/permit/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "vX1sWjW4",
    "block": "{\"symbols\":[\"Field\",\"permit\"],\"statements\":[[8,\"navbar/list\",[],[[\"@icon\",\"@title\",\"@addRoute\",\"@goBack\",\"@pagination\"],[[30,[36,0],[\"permit\"],null],[30,[36,1],[\"permit.permits\"],null],\"permit.add\",[30,[36,2],[\"goBack\"],null],[32,0,[\"model\",\"pagination\"]]]],null],[2,\"\\n\\n\"],[8,\"tables/list-route\",[],[[\"@records\"],[[32,0,[\"model\",\"pagination\",\"objects\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1],[],[[\"@label\"],[[30,[36,3],[\"permit.permit.name\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,4],null,[[\"route\",\"model\"],[\"permit.view\",[32,2,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[32,2,[\"name\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,1],[],[[\"@label\"],[[30,[36,3],[\"permit.permit.status\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,2,[\"statusDisplayName\"]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,1],[],[[\"@label\"],[[30,[36,3],[\"permit.permit.permit_type\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,2,[\"permitTypeDisplayName\"]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,1],[],[[\"@label\"],[[30,[36,3],[\"permit.permit.created_by\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,2,[\"created_by\",\"representation\"]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\",\"route-action\",\"backend-translations/label\",\"link-to\"]}",
    "meta": {
      "moduleName": "plutof/templates/permit/index.hbs"
    }
  });
});