define("plutof/components/publishing/gbif/access-rights/edit", ["exports", "plutof/components/publishing/gbif/access-rights/utils", "@ember-decorators/component", "plutof/controllers/publishing/utils", "plutof/misc/profile_settings"], function (_exports, _utils, _component, _utils2, _profile_settings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createDatasetRights = createDatasetRights;
  _exports.default = void 0;
  _exports.loadDatasetRights = loadDatasetRights;
  var _dec, _class;
  class DatasetRights extends Ember.Object {
    save() {
      if (!this.canModifyPermissions) {
        return Ember.RSVP.Promise.resolve();
      }
      return this.ajax.put((0, _utils2.datasetEndpoint)(this.dataset.id, 'permissions'), {
        contentType: 'application/vnd.api+json',
        data: {
          data: {
            type: 'Dataset',
            id: this.dataset.id,
            attributes: {
              users_view: this.viewOption === 'selected' ? this.viewUsers.mapBy('username') : [],
              users_change: this.editOption === 'selected' ? this.editUsers.mapBy('username') : []
            }
          }
        }
      });
    }
  }
  function createDatasetRights(ajax, dataset) {
    const rights = DatasetRights.create({
      ajax,
      dataset,
      viewOption: 'private',
      editOption: 'private',
      viewUsers: [],
      editUsers: [],
      canModifyPermissions: true
    });
    return Ember.RSVP.Promise.resolve(rights);
  }
  async function loadDatasetRights(store, ajax, dataset) {
    const user = await (0, _profile_settings.get_current_user)(store);
    if (user.username !== dataset.owner) {
      // No need to resolve anything, as rights cannot be modified and
      // won't even be shown
      return DatasetRights.create({
        canModifyPermissions: false
      });
    }
    const rawRights = await ajax.request((0, _utils2.datasetEndpoint)(dataset.id, 'permissions'));
    const {
      viewUsers,
      editUsers
    } = await (0, _utils.resolveUsernames)(store, rawRights);
    return DatasetRights.create({
      ajax,
      dataset,
      viewOption: viewUsers.length > 0 ? 'selected' : 'private',
      editOption: editUsers.length > 0 ? 'selected' : 'private',
      viewUsers,
      editUsers,
      canModifyPermissions: true
    });
  }
  let Edit = (_dec = (0, _component.classNames)('rights-form'), _dec(_class = class Edit extends Ember.Component {}) || _class);
  var _default = _exports.default = Edit;
});