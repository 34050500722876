define("plutof/components/access/-view-rights/view-private", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#access/-view-rights/column}}
      <div class="plutof-label">
          {{i18n-t "Access.canView"}}
      </div>
  
      <p>
          <span class={{icon "private"}}></span>
          {{i18n-t "Access.private"}}
      </p>
  {{/access/-view-rights/column}}
  
  */
  {
    "id": "8UujcmMA",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],null,null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"plutof-label\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"Access.canView\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"p\"],[12],[2,\"\\n        \"],[10,\"span\"],[15,0,[30,[36,1],[\"private\"],null]],[12],[13],[2,\"\\n        \"],[1,[30,[36,0],[\"Access.private\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"icon\",\"access/-view-rights/column\"]}",
    "meta": {
      "moduleName": "plutof/components/access/-view-rights/view-private.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});