define("plutof/templates/components/long-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "FJ5U3rhI",
    "block": "{\"symbols\":[\"@text-height\",\"@text\"],\"statements\":[[6,[37,0],[[32,2,[\"length\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[11,\"div\"],[16,0,[31,[\"long-text \",[30,[36,0],[[32,0,[\"shortened\"]],\"long-text--shortened\"],null],\" \",[32,1]]]],[4,[38,1],[\"click\",[32,0,[\"toggleFullText\"]]],null],[12],[2,\"\\n        \"],[1,[32,2]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"     \\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"on\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/long-text.hbs"
    }
  });
});