define("plutof/translations/eng/moderation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    taxonName: 'Taxon name',
    count: 'Count',
    sample: 'Sample',
    observedBy: 'Observed By',
    controls: 'Controls',
    rejectionInfo: 'By entering a comment and replying the observation gets rejected and sent back to user for improvements.',
    rejectObservation: 'Reject observation',
    close: 'Close',
    denialOfObservation: 'Denial Of Observation',
    comment: 'Comment',
    required: 'required',
    discussion: 'Discussion',
    project: 'Project',
    observationsModeration: 'Observations Moderation',
    accept: 'Accept',
    reject: 'Reject',
    forward: 'Ask another user to moderate',
    selectModerators: 'Select moderators',
    moderationPlaceholder: 'Enter acceptance/rejection message.',
    replyPlaceholder: 'Discuss and ask for review.',
    rejectPlaceholder: 'Enter a reason for rejection. Notification will be sent to user.',
    wasChanged: '{{property}} was changed:',
    oldValue: 'Old value',
    newValue: 'New value',
    successfullyAccepted: 'Successfully accepted',
    reasonOfDenial: 'Reason of denial successfully sent.',
    identification: 'Identification',
    moderatedByMe: 'Moderated by me',
    status: {
      pending: 'Observation is pending for verification.',
      rejected: 'Observation has been rejected by a form moderator.'
    },
    dataQuality: {
      label: 'Issues and flags',
      tooltip: 'Warnings of potential data quality issues. Records with these warnings should be checked with scrutiny.'
    },
    advancedTableFields: {
      name: 'Taxon',
      study: 'Project',
      mainform: 'Form name',
      timespan_begin: 'Timespan begin',
      timespan_end: 'Timespan end',
      created_at: 'Created at',
      created_by: 'Created by',
      individual_count: 'Count',
      measurements: 'Measurements',
      related_objects: 'Linked objects',
      gathering_agents: 'Observed by',
      determiners: 'Identified by',
      controls: 'Controls',
      country: 'Country',
      commune: 'Commune',
      district: 'District',
      state: 'State'
    },
    filters: {
      panelTitle: 'Filters',
      form: 'Form',
      project: 'Project',
      createdAt: 'Created at',
      today: 'Today',
      week: '7 days',
      month: '1 month',
      anytime: 'All',
      hasMedia: 'Media',
      hasAudio: 'Audio',
      country: 'Country'
    },
    forwarding: {
      forwardedTo: 'Forwarded to {{moderators}}',
      forwardedRecords: {
        label: 'Forwarded',
        tooltip: 'Forwarded to you'
      },
      allRecords: {
        label: 'All',
        tooltip: 'All records you can moderate - including those forwarded to you.'
      }
    },
    quickReplyOptions: {
      needProof: 'Additional material (photo, video, or sound) is to confirm taxon identification.',
      needExpertise: 'Proof of expertise is needed to accept taxon identification.',
      taxonTime: 'Taxon occurrence at that time is unlikely.',
      taxonLocation: 'Taxon occurrence at that location is unlikely'
    },
    controlTooltip: {
      details: 'Open observation media and details dialog.',
      reject: 'Open rejection dialog.',
      accept: 'Accept observation.',
      forward: 'Open forwarding dialog.'
    }
  };
});