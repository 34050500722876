define("plutof/mixins/occurrence_row_mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // This mixin holds states of observation and msample validity
  var OccurrenceRowMixin = Ember.Mixin.create({
    measurementsAreEmpty: Ember.computed('measurementsData.@each.value', function () {
      var measurementsData = this.measurementsData;
      if (Ember.isNone(measurementsData)) {
        return false;
      }
      return measurementsData.get('objectMeasurements').mapBy('value').every(Ember.isEmpty);
    }),
    occurrenceRowIsEmpty: Ember.computed.and('measurementsAreEmpty', 'defaultValuesAreEmpty')
  });
  var _default = _exports.default = OccurrenceRowMixin;
});