define("plutof/components/analysis/view/matching-content-item", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  let MatchingContentItem = (_dec = (0, _component.tagName)('tr'), _dec(_class = class MatchingContentItem extends Ember.Component {}) || _class);
  var _default = _exports.default = MatchingContentItem;
});