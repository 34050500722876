define("plutof/components/generic-views/pojo-record-link", ["exports", "@glimmer/component", "plutof/utils/modules"], function (_exports, _component, _modules) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#link-to this.module.routes.view @id target=@target}}
      <span class={{this.module.icon}}></span>
  
      {{#if (has-block)}}
          {{yield}}
      {{else}}
          {{@name}}
      {{/if}}
  {{/link-to}}
  
  */
  {
    "id": "ON99Uu/w",
    "block": "{\"symbols\":[\"@name\",\"&default\",\"@namedBlocksInfo\",\"@id\",\"@target\"],\"statements\":[[6,[37,2],null,[[\"target\",\"route\",\"model\"],[[32,5],[32,0,[\"module\",\"routes\",\"view\"]],[32,4]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[15,0,[32,0,[\"module\",\"icon\"]]],[12],[13],[2,\"\\n\\n\"],[6,[37,1],[[30,[36,0],[[32,3],\"default\",[27,[32,2]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[18,2,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[32,1]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-has-block\",\"if\",\"link-to\"]}",
    "meta": {
      "moduleName": "plutof/components/generic-views/pojo-record-link.hbs"
    }
  });
  // Note: @contentType is not ContentType record,
  // but its name: app_label/model. The dream is to replace
  // ContentType objects in API with those names, because
  // ctype objects and their ids are really an implementation
  // detail and just complicate things
  //
  // Basically, have { id, content_type, name } be the usual
  // way in which API points to records
  class POJORecordLink extends _component.default {
    get module() {
      return _modules.default[this.args.contentType];
    }
  }
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, POJORecordLink);
});