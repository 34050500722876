define("plutof/controllers/materialsample/add", ["exports", "plutof/components/occurrences-table", "plutof/config/environment", "plutof/controllers/observation/add", "plutof/misc/config", "plutof/mixins/add-controller", "plutof/mixins/component-validations", "plutof/utils/access", "plutof/utils/cloning", "plutof/utils/i18n", "plutof/utils/notifications"], function (_exports, _occurrencesTable, _environment, _add, _config, _addController, _componentValidations, _access, _cloning, _i18n, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const i18n = (0, _i18n.getI18n)();
  let MaterialsampleAddController = (_dec = Ember.inject.service, _dec2 = Ember.computed.alias('model.materialSamplesData.occurrences.firstObject'), _dec3 = Ember.computed.alias('model.selectedMeasurementsForm.interaction'), _dec4 = Ember.computed.alias('model.mainform'), _dec5 = Ember.computed.alias('model.formData'), _dec6 = Ember.computed, _dec7 = Ember.computed('template_id', 'cloning'), _dec8 = Ember._action, (_class = class MaterialsampleAddController extends Ember.Controller.extend(_addController.default, _componentValidations.default, _cloning.ControllerCloningMixin) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "listRoutes", _descriptor, this);
      _defineProperty(this, "validationChildren", [{
        name: 'form',
        label: 'Forms.formSelector.title'
      }, {
        name: 'areaEvent',
        label: 'General.samplingAreaEvent'
      }, {
        name: 'occurrences',
        label: 'General.materialSamples'
      }, {
        name: 'interactions',
        label: 'Interaction.interaction'
      }]);
      _defineProperty(this, "defaultReturnRoute", 'materialsample.index');
      _defineProperty(this, "queryParams", ['area_id', 'event_id', 'project_id', 'template_id', 'clone_area', 'clone_event', 'mainform_id', 'collection_id']);
      _defineProperty(this, "template_id", null);
      _defineProperty(this, "mainform_id", null);
      _defineProperty(this, "project_id", null);
      _defineProperty(this, "clone_area", null);
      _defineProperty(this, "clone_event", null);
      _defineProperty(this, "event_id", null);
      _defineProperty(this, "area_id", null);
      _defineProperty(this, "collection_id", null);
      _initializerDefineProperty(this, "primaryOccurrence", _descriptor2, this);
      _initializerDefineProperty(this, "needInteractions", _descriptor3, this);
      _initializerDefineProperty(this, "occurrenceMainform", _descriptor4, this);
      _initializerDefineProperty(this, "formData", _descriptor5, this);
      // Sample specific occurrence table translations
      _defineProperty(this, "occurrencesTableTranslations", {
        panelTitle: i18n.t('MaterialSamples.title'),
        formType: i18n.t('General.formType')
      });
    }
    init() {
      super.init(...arguments);
      (0, _add.addSharedFileWarning)(this, {
        itemData: 'model.itemData',
        occurrenceData: 'model.materialSamplesData'
      });
    }
    get plutofMainforms() {
      return [{
        label: this.i18n.t('Forms.water'),
        id: _config.default.Sample.WATER_MAINFORM_ID
      }, {
        label: this.i18n.t('Forms.soil'),
        id: _config.default.Sample.SOIL_MAINFORM_ID
      }, {
        label: this.i18n.t('Forms.plantAssociated'),
        id: _config.default.Sample.PLANT_ASSOCIATED_MAINFORM_ID
      }, {
        label: this.i18n.t('Forms.host'),
        id: _config.default.Sample.HOST_ASSOCIATED_MAINFORM_ID
      }, {
        label: this.i18n.t('Forms.builtEnv'),
        id: _config.default.Sample.BUILT_ENVIRONMENT_MAINFORM_ID
      }].concat(_environment.default.EXTRA_MAINFORMS.materialSample.map(form => ({
        label: this.i18n.t(form.label),
        id: form.id
      }))).concat([{
        label: this.i18n.t('Forms.default'),
        id: _config.default.Sample.DEFAULT_MAINFORM_ID
      }]);
    }
    get preserveZoom() {
      return this.cloning || !Ember.isEmpty(this.template_id);
    }
    saveSamples() {
      const {
        formData,
        itemData,
        interactions,
        materialSamplesData
      } = this.model;
      return formData.save().then(() => {
        materialSamplesData.occurrences.forEach(msample => {
          formData.apply(msample);
        });
        const sampleProgress = materialSamplesData.save();
        (0, _occurrencesTable.notifySaveProgress)(sampleProgress, 'MaterialSamples.saveProgress');
        return sampleProgress.get('completionPromise').then(savedSamples => {
          return Ember.RSVP.all([(0, _access.create_access_rights)(this.store, []).then(accessRights => {
            return itemData.save(savedSamples, accessRights);
          }), interactions.save(savedSamples), this.model.layers.save(savedSamples)]);
        });
      });
    }
    _save() {
      (0, _notifications.displayNotification)('status', i18n.t('General.saving'));
      return this.saveSamples().then(() => {
        this.set('routeHasBeenLoaded', false);
        this.set('area_id', null);
        this.set('event_id', null);
        this.listRoutes.reset('materialsample/materialsample');
        (0, _notifications.displayNotification)('success', i18n.t('General.done'));
      });
    }
    save() {
      return this._save().then(() => {
        this.send('goBack');
      }).catch(_notifications.reportError);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "listRoutes", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "primaryOccurrence", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "needInteractions", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "occurrenceMainform", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "formData", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "plutofMainforms", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "plutofMainforms"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "preserveZoom", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "preserveZoom"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class));
  var _default = _exports.default = MaterialsampleAddController;
});