define("plutof/mixins/subrepository-mixin", ["exports", "plutof/misc/abstract"], function (_exports, _abstract) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // XXX: Remove
  var _default = _exports.default = Ember.Mixin.create({
    ajax: Ember.inject.service(),
    init() {
      this._super(...arguments);
      this.updateIDPrimPrefix();
      this.additionalCodeChanged();
      this.subRepositoryIDChanged();
      this.objectPrimaryIdChanged();
      this.objectSubIdChanged();
      this.depositedInChanged();
    },
    updateIDPrimPrefix: Ember.observer('subRepositoryObject.deposited_in', function () {
      // Weird ass code.
      var subRepoObject = this.subRepositoryObject;
      if (Ember.isNone(subRepoObject)) {
        return;
      }
      subRepoObject.get('deposited_in').then(organization => {
        if (Ember.isNone(organization)) {
          return;
        }
        if (subRepoObject.get('deposited_in.id') !== organization.get('id')) {
          return;
        }
        var prefix = organization.get('specimen_identifier_format_prefix') || '';
        var primaryID = this.primaryIdString;
        if (Ember.isEmpty(subRepoObject.get(primaryID))) {
          subRepoObject.set(primaryID, prefix);
          this.set('previousPrefix', prefix);
        } else {
          var idprim = subRepoObject.get(primaryID);
          var previousPrefix = this.previousPrefix;
          if (!Ember.isEmpty(previousPrefix) && idprim.indexOf(previousPrefix) === 0) {
            idprim = idprim.substring(previousPrefix.length);
          }
          if (Ember.isEmpty(prefix) || idprim.indexOf(prefix) !== 0) {
            subRepoObject.set(primaryID, prefix + idprim);
          }
          this.set('previousPrefix', prefix);
        }
      });
    }),
    checkForObjectsUniqueness: function () {
      if (this.isDestroyed) {
        return;
      }
      const objectId = this.primaryId;
      const objectPk = this.get('subRepositoryObject.id');
      const subRepoID = this.subRepositoryId;
      const objectSubID = this.subId;
      if (Ember.isEmpty(objectId)) {
        this.set('itemIsUnique', true);
        return;
      }

      // Duplication is allowed.
      if (Ember.isNone(subRepoID)) {
        this.set('itemIsUnique', true);
        return;
      }
      const filterHash = {
        deposited_in: subRepoID,
        object_idprim: objectId
      };
      if (objectSubID) {
        filterHash.specimen_idprim_sub = objectSubID;
      }
      const url = this.uniqueURL;
      const constructedUrl = (0, _abstract.construct_request)(url, filterHash, false);
      this.ajax.request(constructedUrl).then(response => {
        if (parseInt(objectPk) === response.duplicate_object_id) {
          response.is_unique = true;
        }
        (0, _abstract.safe_set)(this, 'itemIsUnique', response.is_unique);
      });
    },
    checkIdentifiersUniqueness: function (x) {
      if (this.isDestroyed) {
        return;
      }
      var code = this.code;
      var subRepositoryID = this.subRepositoryId;
      if (Ember.isEmpty(subRepositoryID) || Ember.isEmpty(code)) {
        this.set('codeIsUnique', true);
        return;
      }
      var url = this.additionalURL;
      var hash = {
        'code': code,
        'deposited_in': subRepositoryID
      };
      var constructedUrl = (0, _abstract.construct_request)(url, hash, true);
      this.ajax.request(constructedUrl).then(response => {
        if (Ember.isEmpty(this.subRepositoryId)) {
          return; // Race condition
        }
        (0, _abstract.safe_set)(this, 'codeIsUnique', response.is_unique);
      });
    },
    additionalCodeChanged: Ember.observer('code', function () {
      if (!Ember.isNone(this.checkForUniqueness)) {
        Ember.run.debounce(this, this.checkIdentifiersUniqueness, 400);
      }
    }),
    subRepositoryIDChanged: Ember.observer('subRepositoryId', function () {
      if (!Ember.isNone(this.checkForUniqueness)) {
        this.checkIdentifiersUniqueness();
      }
    }),
    objectPrimaryIdChanged: Ember.observer('primaryId', function () {
      if (!Ember.isNone(this.primaryId)) {
        Ember.run.debounce(this, this.checkForObjectsUniqueness, 400);
      }
    }),
    objectSubIdChanged: Ember.observer('subId', function () {
      if (!Ember.isNone(this.subId)) {
        Ember.run.debounce(this, this.checkForObjectsUniqueness, 400);
      }
    }),
    depositedInChanged: Ember.observer('subRepositoryObject.deposited_in', function () {
      const isChanged = this.get('subRepositoryObject.hasDirtyAttributes');
      if (isChanged) {
        Ember.run.debounce(this, this.checkForObjectsUniqueness, 400);
      }
    }),
    subrepositories: Ember.computed('subRepositoryId', function () {
      if (Ember.isNone(this.subRepositoryId)) {
        return [];
      }
      return this.store.query('agent/repository', {
        parent_organization: this.subRepositoryId,
        ordering: 'name'
      });
    })
  });
});