define("plutof/components/layout/split-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{! XXX: Accepting proposals for a better name}}
  <div class="split-row">
      <div>
          {{yield to="left"}}
      </div>
  
      {{#if (has-block "center")}}
          <div>
              {{yield to="center"}}
          </div>
      {{/if}}
  
      <div>
          {{yield to="right"}}
      </div>
  </div>
  
  */
  {
    "id": "5nlgpq1h",
    "block": "{\"symbols\":[\"&default\",\"@namedBlocksInfo\"],\"statements\":[[10,\"div\"],[14,0,\"split-row\"],[12],[2,\"\\n    \"],[10,\"div\"],[12],[2,\"\\n        \"],[18,1,[[30,[36,0],[\"left\"],null]]],[2,\"\\n    \"],[13],[2,\"\\n\\n\"],[6,[37,2],[[30,[36,1],[[32,2],\"center\",false],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[12],[2,\"\\n            \"],[18,1,[[30,[36,0],[\"center\"],null]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"div\"],[12],[2,\"\\n        \"],[18,1,[[30,[36,0],[\"right\"],null]]],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-named-block-invocation\",\"-has-block\",\"if\"]}",
    "meta": {
      "moduleName": "plutof/components/layout/split-row.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});