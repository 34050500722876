define("plutof/components/datacite/geom-input", ["exports", "plutof/utils/map"], function (_exports, _map) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let GeomInput = (_dec = Ember.computed('source'), _dec2 = Ember._action, (_class = class GeomInput extends Ember.Component {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "source", '');
      _defineProperty(this, "geolocation", null);
    }
    get sourceInvalid() {
      const parts = this.source.split(/\s+/);
      if (parts.length !== 2) {
        return true;
      }
      return !parts.every(part => /^-?\d+(\.\d+)?$/.test(part));
    }
    setGeometry() {
      if (this.sourceInvalid) {
        return;
      }
      const parts = this.source.split(/\s+/);
      let center;
      let geom;
      if (parts.length === 2) {
        geom = 'POINT(' + parts[1] + ' ' + parts[0] + ')';
        center = {
          lat: parts[0],
          lon: parts[1]
        };
      }
      if (!Ember.isNone(geom)) {
        this.set('geolocation.geom', geom);
      }
      if (!Ember.isNone(center)) {
        const geolocation = this.geolocation;
        (0, _map.geoqueryCountry)(this.store, center.lon, center.lat).then(country => {
          geolocation.set('country', country);
        });
      }
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "sourceInvalid", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "sourceInvalid"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setGeometry", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "setGeometry"), _class.prototype)), _class));
  var _default = _exports.default = GeomInput;
});