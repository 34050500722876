define("plutof/translations/eng/observations", ["exports", "plutof/translations/helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    title: 'Observations',
    searchTitle: 'Search Observations',
    infoTitle: 'Observations',
    observers: 'Observers',
    newObservation: 'New Observation',
    editObservation: 'Edit Observation',
    observation: 'Observation',
    moderate: 'Moderate',
    parentSamplingArea: 'Parent area',
    parentSamplingAreaTooltip: (0, _helpers.alias)('Sample.parentSamplingAreaTooltip'),
    existingSamplingArea: 'Existing area',
    existingSamplingAreaTooltip: (0, _helpers.alias)('Sample.existingSamplingAreaTooltip'),
    samplingArea: 'Area',
    samplingEvent: 'Event',
    samplingAreaEvent: 'Area and Event',
    beginDate: 'Begin date',
    endDate: 'End date',
    beginTime: 'Begin time',
    endTime: 'End time',
    cloneToolt: 'Copy this row to add record of the same taxon with different measurements.',
    showTaimeatlas: 'Add sheet',
    taAcronym: 'TA Acronym',
    taSheetCurrent: 'Current sheet',
    taSheetOld: 'Old sheet',
    addMediaObservation: 'File based',
    savingRest: 'Saving the rest',
    saveProgress: 'Saved {{finished}} out of {{total}} observations',
    fileToolt: 'Attach file to this observation.',
    expandedFormToolt: 'Open extended form with additional, more rarely used fields included',
    placeholders: {
      searchHint: 'Search by taxon name',
      observationTaxon: 'Taxon name',
      taxon: 'Taxon',
      observationSource: 'Source',
      observationRemarks: 'Remarks',
      observationCount: 'Count',
      observationCountToolt: 'Number of observed specimens:<br>\'0\' - no specimens were found although looked for;<br>\'-1\' - specimens were present but were not counted.',
      commonNameToolt: 'Vernacular name. E.g Saturnus',
      observationEstbirds: '3+3 acronym',
      estbirdsToolt: '3+3 acronym',
      substrateTaxon: 'Substrate taxon name',
      taAcronym: 'Taimeatlas acronym',
      determiner: 'Determiner',
      determinerToolt: 'Determined by'
    }
  };
});