define("plutof/controllers/analysis/utils", ["exports", "plutof/misc/fileupload", "plutof/utils/push-to-store", "plutof/utils/reflection"], function (_exports, _fileupload, _pushToStore, _reflection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addMatchingItemsFromFASTA = addMatchingItemsFromFASTA;
  _exports.addSHDetermination = addSHDetermination;
  _exports.batchAddMatchingItems = batchAddMatchingItems;
  _exports.downloadRunItems = downloadRunItems;
  _exports.fetchRun = fetchRun;
  _exports.getAnalysisType = getAnalysisType;
  _exports.sendItemsToClipboard = sendItemsToClipboard;
  _exports.startRun = startRun;
  function recordMethodURL(record, method) {
    const endpoint = (0, _reflection.get_record_url)(record);
    return `${endpoint}${method}/`;
  }
  function callRecordEndpoint(ajax, record, method, params = {}) {
    const url = recordMethodURL(record, method);
    return ajax.post(url, {
      data: params,
      dataType: 'json'
    });
  }
  function startRun(store, ajax, run) {
    return callRecordEndpoint(ajax, run, 'run').then(data => {
      (0, _pushToStore.default)(store, run.constructor.modelName, data);
    });
  }
  function fetchRun(store, id) {
    return store.findRecord('analysis/run', id, {
      reload: true
    }).then(baseRun => {
      const type = getAnalysisType(baseRun.analysis_type);
      const model = type.wrapper ? 'wrapper-run' : 'sequence-matching-run';
      return store.findRecord(`analysis/${model}`, id, {
        reload: true
      }).then(run => ({
        run,
        type
      }));
    });
  }
  function addSHDetermination(store, ajax, item) {
    return callRecordEndpoint(ajax, item, 'add_determination').then(data => {
      (0, _pushToStore.default)(store, item.constructor.modelName, data);
    });
  }
  function batchAddMatchingItems(ajax, run, ids, ordering) {
    return callRecordEndpoint(ajax, run, 'add_items', {
      ordering,
      sequences: ids
    });
  }
  function addMatchingItemsFromFASTA(ajax, run, fasta, ordering) {
    const url = recordMethodURL(run, 'add_fasta');
    let formData = new FormData();
    formData.append('fasta', fasta);
    formData.append('ordering', ordering);
    return ajax.raw(url, {
      type: 'POST',
      data: formData,
      processData: false,
      contentType: false
    });
  }
  function sendItemsToClipboard(ajax, run, {
    onlyIdentified = false
  } = {}) {
    return callRecordEndpoint(ajax, run, 'send_items_to_clipboard', {
      only_identified: onlyIdentified
    });
  }
  function downloadRunItems(ajax, run) {
    const url = recordMethodURL(run, 'download-csv');
    (0, _fileupload.downloadFile)(url);
  }
  const WRAPPER_COMPONENTS = {
    source: 'analysis/wrapper-source',
    contentView: 'analysis/view/wrapper-content'
  };
  const BLAST_DEFAULT_PARAMETERS = {
    unite: 'true'
  };

  // TODO: Analysis types that can support both matching and a wrapper run? Do
  // we need such a thing?
  const ANALYSIS_TYPES = {
    itsx: {
      id: 'itsx',
      wrapper: true,
      defaultParameters: {
        domains: 2,
        complement: true,
        preserve: true,
        concat: true,
        detailed: false,
        organism: 'F'
      },
      components: Object.assign({}, WRAPPER_COMPONENTS, {
        parameters: 'analysis/type-itsx'
      })
    },
    blastn: {
      id: 'blastn',
      wrapper: true,
      defaultParameters: BLAST_DEFAULT_PARAMETERS,
      components: Object.assign({}, WRAPPER_COMPONENTS, {
        parameters: 'analysis/type-blaster'
      })
    },
    'blastn-sh': {
      id: 'blastn-sh',
      wrapper: false,
      report: true,
      defaultParameters: BLAST_DEFAULT_PARAMETERS,
      components: {
        parameters: 'analysis/type-blaster',
        source: 'analysis/matching-source',
        contentView: 'analysis/view/matching-content'
      },
      // TODO: Think about those now that they're not just for showing generated details
      details: [{
        label: 'Analysis.match.details.blast.evalue',
        field: 'evalue',
        extract: details => details.evalue
      }, {
        label: 'Analysis.match.details.blast.bitscore',
        field: 'bit_score',
        extract: details => details.bit_score
      }, {
        label: 'Analysis.match.percentage',
        field: 'percentage',
        extract(details) {
          return Ember.isNone(details.percentage) ? '' : details.percentage.toFixed(2);
        }
      }]
    },
    'sh_match': {
      id: 'sh_match',
      wrapper: true,
      components: Object.assign({}, WRAPPER_COMPONENTS, {
        parameters: 'analysis/type-shmatch'
      }),
      defaultParameters: {
        sequence_region: 'itsfull',
        itsx_step: true,
        substring_dereplication: true
      }
    },
    sh_match_processed: {
      id: 'sh_match_processed',
      wrapper: false,
      resultsDownload: true,
      components: {
        parameters: 'analysis/type-shmatch',
        source: 'analysis/matching-source',
        contentView: 'analysis/view/sh-matching-content'
      },
      defaultParameters: {
        sequence_region: 'itsfull',
        itsx_step: true,
        substring_dereplication: true
      },
      details: []
    },
    'ml-taxon': {
      id: 'ml-taxon',
      wrapper: true,
      defaultParameters: {},
      components: Object.assign({}, WRAPPER_COMPONENTS, {
        contentView: 'analysis/ml-taxon/content'
      })
    },
    'ml-sh': {
      id: 'ml-sh',
      wrapper: true,
      defaultParameters: {},
      components: Object.assign({}, WRAPPER_COMPONENTS, {
        contentView: 'analysis/ml-sh/content'
      })
    }
  };
  function getAnalysisType(id) {
    if (!(id in ANALYSIS_TYPES)) {
      throw new Error(`Programmer error: unknown analysis type ${id}`);
    }
    return ANALYSIS_TYPES[id];
  }
});