define("plutof/utils/promises", ["exports", "plutof/utils/structures"], function (_exports, _structures) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TaskBlock = void 0;
  _exports.filter = filter;
  _exports.wait = wait;
  var _dec, _dec2, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let TaskBlock = _exports.TaskBlock = (_dec = Ember.computed.notEmpty('promises'), _dec2 = Ember.computed.notEmpty('errors'), (_class = class TaskBlock extends Ember.Object {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "isWorking", _descriptor, this);
      _initializerDefineProperty(this, "inError", _descriptor2, this);
    }
    init() {
      this.set('promises', []);
      this.set('errors', []);
    }
    addTask(promise) {
      var tasks = this.promises;
      var errors = this.errors;
      tasks.pushObject(promise);
      promise.then(function () {
        tasks.removeObject(promise);
      }, function (error) {
        tasks.removeObject(promise);
        errors.pushObject(error);
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isWorking", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "inError", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  function filter(array, callback) {
    return Ember.RSVP.all(array.map(function (object) {
      return callback(object).then(function (include) {
        return include ? object : null;
      });
    })).then(function (results) {
      return results.compact();
    });
  }
  function filterBy(array, property, value) {
    return filter(array, function (object) {
      return Ember.RSVP.Promise.resolve(Ember.get(object, property)).then(function (pvalue) {
        return pvalue === value;
      });
    });
  }

  // TODO: One-by-one instead on filter-then-slice
  function find(array, callback) {
    return filter(array, callback).then(function (results) {
      return results.get('firstObject');
    });
  }
  function findBy(array, property, value) {
    return filterBy(array, property, value).then(function (results) {
      return results.get('firstObject');
    });
  }
  function fold(array, initial, func) {
    function doFold(elements, accumulator) {
      if (elements.length === 0) {
        return accumulator;
      }
      var head = elements.shift();
      return func(accumulator, head).then(function (acc) {
        return doFold(elements, acc);
      });
    }
    return doFold(array.slice(), initial);
  }

  // Private
  function safe_get(obj, path) {
    return Ember.isNone(obj) ? obj : Ember.get(obj, path);
  }
  function get(obj, path) {
    const properties = path.split('.');
    return fold(properties, obj, (obj, key) => Ember.RSVP.Promise.resolve(safe_get(obj, key)));
  }
  function saveSequentially(records) {
    var _records = records.slice();
    function doSave() {
      if (_records.length === 0) {
        return Ember.RSVP.Promise.resolve(records); // Resolve with saved array
      } else {
        var first = _records.shift();
        return first.save().then(doSave);
      }
    }
    return doSave();
  }

  // fs :: ([() -> Promise x], int) -> [x]
  //
  // Splits funcs into batches of @batchSize, executes batches sequenctially
  function batch(fs, batchSize) {
    var batches = (0, _structures.chop)(batchSize, fs);
    batches.reverse();
    var results = [];
    function process() {
      if (batches.length === 0) {
        return Ember.RSVP.Promise.resolve(results);
      }
      var currentBatch = batches.pop();
      return Ember.RSVP.all(currentBatch.map(f => f())).then(function (rs) {
        rs.forEach(r => results.push(r));
        return process();
      });
    }
    return process();
  }

  // fs :: ([() -> Promise x], int) -> [x]
  //
  // Executes @fs sequentially, no more than @batchSize will be executing at any time
  // TODO & see how it works in occurrences-table
  // function sequence(fs, batchSize = 1) {}

  // TODO: better name?
  function processInBatches(data, action, batchSize) {
    return new Promise((resolve, reject) => {
      const total = data.length;
      let batchStart = 0;
      function next() {
        if (batchStart < total) {
          const batch = data.slice(batchStart, batchStart + batchSize);
          Ember.RSVP.Promise.resolve(action(batch)).then(() => {
            batchStart += batchSize;
            window.setTimeout(next, 0);
          });
        } else {
          resolve();
        }
      }
      window.setTimeout(next, 0);
    });
  }
  function wait(timeout) {
    return new Ember.RSVP.Promise((resolve, reject) => {
      setTimeout(resolve, timeout);
    });
  }
  var Promises = {
    filter: filter,
    filterBy: filterBy,
    find: find,
    findBy: findBy,
    fold: fold,
    get: get,
    saveSequentially: saveSequentially,
    batch: batch,
    processInBatches: processInBatches,
    TaskBlock: TaskBlock
  };
  var _default = _exports.default = Promises;
});