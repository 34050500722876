define("plutof/components/publishing/gbif/records/add-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PromiseButton
      @clicked={{@add}}
      class="plutof-btn__controls plutof-btn__controls--xs"
      data-test="add-button"
  >
      {{i18n-t "General.Add"}}
  </PromiseButton>
  
  */
  {
    "id": "RQacuxMi",
    "block": "{\"symbols\":[\"@add\"],\"statements\":[[8,\"promise-button\",[[24,0,\"plutof-btn__controls plutof-btn__controls--xs\"],[24,\"data-test\",\"add-button\"]],[[\"@clicked\"],[[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"General.Add\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\"]}",
    "meta": {
      "moduleName": "plutof/components/publishing/gbif/records/add-button.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});