define("plutof/templates/components/analysis/type-shmatch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "WefZg96K",
    "block": "{\"symbols\":[\"@run\"],\"statements\":[[10,\"div\"],[14,0,\"type-shmatch\"],[12],[2,\"\\n    \"],[10,\"div\"],[12],[2,\"\\n        \"],[8,\"plutof-labelc\",[],[[\"@path\"],[\"wrapper-run.sequence_region\"]],null],[2,\"\\n\\n        \"],[8,\"plutof-select/value\",[],[[\"@content\",\"@value\",\"@prompt\",\"@class\",\"@selectClass\"],[[32,0,[\"sequenceRegion\"]],[32,1,[\"sequence_region\"]],null,\"type-shmatch__sequence_region\",\"form-control default-focus-control\"]],null],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"spacer-large\"],[12],[13],[2,\"\\n\\n    \"],[10,\"div\"],[12],[2,\"\\n        \"],[8,\"common/check-box\",[],[[\"@value\"],[[32,1,[\"itsx_step\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,\"plutof-labelc\",[],[[\"@path\"],[\"wrapper-run.itsx_step\"]],null],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,\"common/check-box\",[],[[\"@value\"],[[32,1,[\"substring_dereplication\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,\"plutof-labelc\",[],[[\"@path\"],[\"wrapper-run.substring_dereplication\"]],null],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "plutof/templates/components/analysis/type-shmatch.hbs"
    }
  });
});