define("plutof/routes/experiment/sequencing/view", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "plutof/components/experiment/utils", "plutof/mixins/route"], function (_exports, _authenticatedRouteMixin, _utils, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class SequencingViewRoute extends Ember.Route.extend(_authenticatedRouteMixin.default, _route.default) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "defaultReturnRoute", 'experiment.sequencing.index');
      _defineProperty(this, "title", 'experiment.sequencing.routeTitles.view');
    }
    async model(params) {
      const experiment = await this.store.findRecord('dna-lab/sequencing', params.id);
      const permissions = await (0, _utils.experimentPermissions)(this.store, experiment, await experiment.laboratory);
      const oldMaterials = await this.store.query('dna-lab/sequencing-result', {
        sequencing: params.id,
        include: 'dna,pcr_product,primer,original_file'
      });
      const emptyMaterials = (0, _utils.makeEmptyMaterials)({
        materials: oldMaterials,
        model: 'dna-lab/sequencing-result',
        properties: {
          sequencing: experiment
        },
        store: this.store
      });
      const materials = oldMaterials.toArray().concat(emptyMaterials);
      return {
        experiment,
        materials,
        permissions
      };
    }
  }
  var _default = _exports.default = SequencingViewRoute;
});