define("plutof/translations/est/sequences", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    title: 'Sekventsid',
    searchTitle: 'Otsi sekventse',
    infoTitle: 'Sekventsid',
    sIdentifiers: 'Sekventsi identifikaatorid',
    sTypes: 'Sekventsi tüübid',
    accessionNumber: 'Kood',
    searchLink: 'Otsi',
    newSequence: 'Uus sekvents',
    addMultiple: 'Laadi üles failist',
    editSequence: 'Muuda sekventsi',
    sequence: 'Sekvents',
    generalInfo: 'Üldandmed',
    linkedTo: 'Seotud',
    sequencedRegions: 'Sekveneeritud regioonid',
    object: 'Objekt',
    taxon: 'Takson',
    length: 'Pikkus',
    determinedBy: 'Määraja(d)',
    project: 'Projekt',
    types: 'Tüübid',
    parentTaxonNode: 'Kõrgem takson',
    lineage: 'Lineage',
    lineageDescription: 'Lineage description',
    allToClipboard: 'Kõik näpitstahvlile',
    toClipboard: 'Näpitstahvlile',
    source: 'Allikas',
    originalSource: 'Algallikas',
    cannotRemoveUniteID: 'Ei saa kustutada UNITE numbrit UNITE sekvetsist',
    potentiallyChimeric: 'Antud sekvents võib olla kimäärne',
    chimericScore: 'tulemus',
    chimeraParents: 'Oletatavate vanemate sekventsid on',
    id: 'Sekventsi Id',
    uniteStatusOption: 'Küsi UNITE koodi',
    parentEventReuseDialog: {
      title: 'Kasuta olemasoleva taksoni esinemise kogumise sündmust?',
      content: 'Kas soovite sekventsi siduda olemasoleva kogumise sündmuse külge? Sellisel juhul ei saa te hiljem sekventsi kogumise sündmust muuta. Kogumise sundmust saaks siis muuta ainult läbi olemasoleva eksmeplari kaudu.',
      eventFromSource: 'Kasuta olemsolevat sündmust',
      createEvent: 'Loo uus sündmus',
      continueUsingSameEvent: 'Jätka sama sündmusega'
    },
    annotation: {
      add: 'Annoteeri sekventsi',
      edit: 'Muuda annotatsiooni',
      view: 'Annoteeritud sekvents'
    }
  };
});