define("plutof/components/ui/modal/frame", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
      class="modal-frame {{if @open 'modal-frame--open'}}"
  >
      {{#if @close}}
          {{! If click handler is directly on frame, it capture content clicks }}
          <div
              {{on "click" @close}}
              class="modal-frame__click-area"
          >
          </div>
      {{/if}}
  
      {{yield}}
  </div>
  
  */
  {
    "id": "YvnHmjcO",
    "block": "{\"symbols\":[\"@close\",\"@open\",\"&default\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"modal-frame \",[30,[36,1],[[32,2],\"modal-frame--open\"],null]]]],[12],[2,\"\\n\"],[6,[37,1],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[11,\"div\"],[24,0,\"modal-frame__click-area\"],[4,[38,0],[\"click\",[32,1]],null],[12],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[18,3,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\",\"if\"]}",
    "meta": {
      "moduleName": "plutof/components/ui/modal/frame.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});