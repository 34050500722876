define("plutof/components/unite/tree-statistics", ["exports", "ember-data", "@ember-decorators/object", "plutof/misc/abstract", "plutof/utils/reflection"], function (_exports, _emberData, _object, _abstract, _reflection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let TreeStatistics = (_dec = Ember.inject.service(), _dec2 = Ember.computed('taxon.taxon_name'), _dec3 = Ember.computed('level', 'taxon'), _dec4 = Ember.computed('expanded', 'filterField', 'taxon', 'threshold', 'includeSingletons', 'version'), _dec5 = Ember.computed('path.[]', 'taxon'), _dec6 = Ember.computed('inPath', 'path.[]', 'taxon'), _dec7 = (0, _object.observes)('inPath', 'isLastInPath'), _dec8 = (0, _object.observes)('isLastInPath'), _dec9 = Ember._action, _dec10 = Ember._action, (_class = class TreeStatistics extends Ember.Component {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "terminal_level", 'genus');
      _defineProperty(this, "taxon_prop", 'taxonnode');
      _defineProperty(this, "count_prop", 'sh_count');
      _defineProperty(this, "stats_url", '/globalkey/shtreestatistics/');
      _defineProperty(this, "level", null);
      _defineProperty(this, "taxon", null);
      _defineProperty(this, "threshold", null);
      _defineProperty(this, "version", null);
      _defineProperty(this, "isRoot", false);
      _defineProperty(this, "includeSingletons", false);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
    }
    init() {
      super.init(...arguments);
      if (Ember.isNone(this.expanded)) {
        this.set('expanded', false);
      }
      this.expandIfInPath();
      this.notifyPathTermination();
    }
    get label() {
      return this.get('taxon.taxon_name') || '---';
    }
    get isTerminal() {
      return this.level === this.terminal_level || Ember.isNone(this.taxon) && !this.isRoot;
    }
    get children() {
      var self = this;
      var store = self.get('store');

      // XXX: Needed so that children don't get preloaded when the tree is closed, which
      // happends because the expand/collapse is under control of the node itself, not the parent
      if (!self.get('expanded')) {
        return [];
      }

      // is_singleton is actually not boolean:
      // True - only singletons
      // False - only non-singletons
      // None - both
      //
      // We select between None and False
      var url = (0, _abstract.construct_request)(self.get('stats_url'), {
        filter_field: self.get('level'),
        filter_param: self.get('taxon.id'),
        threshold: self.get('threshold.id'),
        version: self.get('version.id'),
        is_singleton: self.get('includeSingletons')
      });
      var childrenP = self.get('ajax').request(url).then(function (response) {
        return Ember.RSVP.all(response.counts.map(function (child) {
          const taxonProp = self.get('taxon_prop');
          var taxonP = Ember.isNone(child[taxonProp]) ? (0, _abstract.wrap_as_promise)(null) : store.findRecord('taxonomy/taxonnode', (0, _reflection.recordURLToID)(child[taxonProp]));
          return taxonP.then(function (taxon) {
            return {
              taxon: taxon,
              count: child[self.get('count_prop')],
              level: response.field
            };
          });
        }));
      });
      return _emberData.default.PromiseArray.create({
        promise: childrenP
      });
    }
    get inPath() {
      var taxon = this.taxon;
      var path = this.path;
      return !Ember.isNone(taxon) && !Ember.isEmpty(path) && path.includes(taxon);
    }
    get isLastInPath() {
      if (this.inPath) {
        var path = this.path;
        return path.indexOf(this.taxon) === path.get('length') - 1;
      } else {
        return false;
      }
    }
    expandIfInPath() {
      Ember.run.once(function () {
        if (this.inPath && !this.isLastInPath && !this.isTerminal) {
          this.set('expanded', true);
        }
      }.bind(this));
    }

    // XXX
    notifyPathTermination() {
      if (this.isLastInPath) {
        this.select();
      }
    }
    toggleExpanded() {
      this.toggleProperty('expanded');
    }
    select() {
      this.nodeSelected({
        terminal: this.isTerminal,
        taxon: this.taxon,
        level: this.level,
        parentTaxon: this.parentTaxon,
        parentLevel: this.parentLevel
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "label", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "label"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isTerminal", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "isTerminal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "children", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "children"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "inPath", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "inPath"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isLastInPath", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "isLastInPath"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "expandIfInPath", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "expandIfInPath"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "notifyPathTermination", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "notifyPathTermination"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleExpanded", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "toggleExpanded"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "select", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "select"), _class.prototype)), _class));
  var _default = _exports.default = TreeStatistics;
});