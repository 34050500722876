define("plutof/translations/est/taxon-import", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    importGbif: 'Impordi GBIF-ist',
    searchGbif: 'Otsi GBIF-ist',
    searchGbifTooltip: 'Impordi puuduv takson GBIF-ist.',
    gbifDataset: 'GBIF-i andmestik',
    importMycobank: 'MycoBank-i import',
    searchMycobank: 'Otsi MycoBank-ist',
    searchMycobankTooltip: 'Impordi puuduv takson MycoBank-ist.',
    notFound: 'Vasteid otsingule ei leitud.',
    synonymOf: '{{acceptedName}} sünonüüm',
    newCreated: 'Uus takson edukalt lisatud.',
    modal: {
      title: 'Taksoni importimine'
    },
    actions: {
      open: 'Impordi taksoneid',
      close: 'Sulge',
      view: 'Vaata',
      import: 'Impordi'
    }
  };
});