define("plutof/components/menu/top-bar", ["exports", "plutof/config/environment", "plutof/utils/cookies", "plutof/utils/menu", "plutof/utils/search/modules"], function (_exports, _environment, _cookies, _menu, _modules) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const SEARCH_ROUTE = 'search.index';
  const DEFAULT_SEARCH_MODULE = 'taxonoccurrence';
  function checkIfSubRoute(routes, path) {
    return !Ember.isNone(routes) && routes.any(subRoute => {
      return subRoute.route === path;
    });
  }

  // TODO: Replace this with route metadata (see utils/routes/ListRoute)
  function findSearchModule(menuConfig, path) {
    const route = path.split('.')[0];
    const pathIsSubRoute = checkIfSubRoute(menuConfig.subRoutes, path);
    const routeIsRemoteRoute = checkIfSubRoute(menuConfig.remoteRoutes, route);
    if (pathIsSubRoute) {
      // Sub-routes are full length route paths.
      return menuConfig.subRoutes.findBy('route', path).searchModule;
    } else if (routeIsRemoteRoute) {
      return menuConfig.remoteRoutes.findBy('route', route).searchModule;
    } else if (menuConfig.route === route) {
      return menuConfig.searchModule;
    } else if (!Ember.isEmpty(menuConfig.children)) {
      /**
       * Badly optimized: it should terminate at first value.
       */
      return menuConfig.children.map(child => findSearchModule(child, path)).compact()[0];
    } else {
      return null;
    }
  }
  let TopBar = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed.equal('router.currentRouteName', SEARCH_ROUTE), _dec5 = Ember.computed('Environment.LANDING_PAGE'), _dec6 = Ember.computed('inSearchRoute', 'searchModule'), _dec7 = Ember.computed('parentObject.currentRouteName'), _dec8 = Ember.computed('session.isAuthenticated'), _dec9 = Ember._action, _dec10 = Ember._action, (_class = class TopBar extends Ember.Component {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "session", _descriptor2, this);
      _initializerDefineProperty(this, "store", _descriptor3, this);
      _initializerDefineProperty(this, "inSearchRoute", _descriptor4, this);
    }
    get landingPageURL() {
      const locale = (0, _cookies.getCookie)('language') === 'est' ? 'et' : 'en';
      return `${_environment.default.LANDING_PAGE}/${locale}`;
    }
    get searchPlaceholder() {
      if (this.inSearchRoute) {
        return this.i18n.t('General.Search');
      }
      const module = _modules.default.findBy('id', this.searchModule);
      return module ? module.name : this.i18n.t(`searchModule.${DEFAULT_SEARCH_MODULE}`);
    }
    get searchModule() {
      let route = this.router.currentRoute;
      let searchModule;
      do {
        if (route.metadata) {
          searchModule = route.metadata.searchModule;
        }
        route = route.parent;
      } while (route && !searchModule);
      if (!searchModule) {
        searchModule = findSearchModule(_menu.menuConfig, this.router.currentRouteName);
      }
      return searchModule;
    }
    get loginCaption() {
      return this.get('session.isAuthenticated') ? 'General.openWorkbench' : 'General.login';
    }
    async search() {
      const searchModule = this.searchModule || DEFAULT_SEARCH_MODULE;
      if (this.inSearchRoute) {
        // TODO: If we're already doing this, at least inject the search controller
        // instead
        // Except you can't inject controllers into components
        if (Ember.isPresent(this.searchInput)) {
          const searchController = this.get('parentObject.currentRoute.controller');
          searchController.searchQuery.perform({
            module: searchController.selectedModule.id,
            filters: {
              q: this.searchInput
            }
          });
          this.set('searchInput', null);
        }
      } else {
        let params = new window.URLSearchParams({
          module: searchModule
        });
        if (Ember.isPresent(this.searchInput)) {
          params.set('q', this.searchInput);
        }
        const module = _modules.default.findBy('id', searchModule);
        if (Ember.isPresent(module.defaultValues)) {
          const defaultValues = await module.defaultValues(this.store);
          Object.keys(defaultValues).forEach(key => params.set(key, defaultValues[key]));
        }
        this.router.transitionTo(`/search?${params}`);
        this.set('searchInput', null);
      }
    }
    goTo(route) {
      this.router.transitionTo(route);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "inSearchRoute", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "landingPageURL", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "landingPageURL"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchPlaceholder", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "searchPlaceholder"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchModule", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "searchModule"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loginCaption", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "loginCaption"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "search", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "search"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "goTo", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "goTo"), _class.prototype)), _class));
  var _default = _exports.default = TopBar;
});