define("plutof/translations/eng/agents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    personsTitle: 'Persons',
    personsSearchTitle: 'Search Persons',
    personsInfoTitle: 'Persons',
    orgTitle: 'Organizations',
    // XXX die
    orgSearchTitle: 'Search Organizations',
    orgInfoTitle: 'Organizations',
    associatedOrg: 'Associated Organizations',
    personAddress: 'Person\'s address in this organization',
    personAdd: 'add persons and link them to organizations.',
    // In this section you can ...
    personView: 'search and view existing persons.',
    // In this section you can ...
    additionalData: 'Additional Data',
    newPerson: 'New Person',
    editPerson: 'Edit Person',
    newOrganization: 'New Organization',
    editOrganization: 'Edit Organization',
    address: 'Address',
    findByModule: 'Search by module',
    personTooltip: '<span class="icon-user"></span> - person has an account. <br>' + '<span class="icon-person"></span> - name is ambiguous, represents an organization or group of people. <br>' + '<i class="person-valid-name"> jsmith </i> - created by this user. <br>' + '<i>J.Smith</i> (John Smith) - John Smith is the valid name for <i>J.Smith</i>.',
    agentTooltip: '<span class="icon-user"></span> - person has an account. <br>' + '<span class="icon-person"></span> - name is ambiguous, represents an organization or group of people. <br>' + '<span class="fas fa-archive"></span> - organization refers to collection (e.g herbarium, fungarium, entomological collection, etc.). <br>' + '<i class="person-valid-name"> jsmith </i> - created by this user. <br>' + '<i>J.Smith</i> (John Smith) - John Smith is the valid name for <i>J.Smith</i>.',
    organizationTooltip: '<span class="fas fa-archive"></span> - organization refers to collection (e.g herbarium, fungarium, entomological collection, etc.).',
    personDetails: 'Person\'s Details',
    alternateText: 'Alternative names for the same agent are shown in parentheses.',
    validNameTooltip: 'Choose valid name for which the present name is a duplicate of (e.g. valid name for A. Smith is Adrian Smith). Valid name will be shown in parentheses throughout the system.',
    acronymTooltip: 'To avoid duplicates. When creating acronyms, it is recommended to consult https://www.gbif.org/grscicoll or PlutoF platform.',
    selectLogo: 'Upload logo',
    selectedLogo: 'Selected logo',
    logoTooltip: 'Organization/Collection logo. Logo will be inlcuded on printed collection transactions.',
    acronymClashWarning: {
      'agent/organization': 'NB! We have found similar organization(s).',
      'agent/collection': 'NB! We have found similar collection(s).'
    },
    person: {
      person: 'Person',
      synonyms: 'Synonyms'
    },
    organization: {
      organization: 'Organization',
      fields: {
        fullName: 'Full name',
        name: 'Name'
      },
      panels: {
        collections: 'Collections',
        suborganizations: 'Suborganizations'
      }
    },
    placeholders: {
      personsSearchHint: 'Search by name',
      orgSearchHint: 'Search by name'
    },
    associations: {
      persons: 'People',
      showAll: 'Show all',
      showChanged: 'Show changes'
    }
  };
});