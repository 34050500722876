define("plutof/initializers/i18n-setup", ["exports", "plutof/utils/i18n"], function (_exports, _i18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(application) {
    application.register('util:i18n', _i18n.default);
    application.inject('route', 'i18n', 'util:i18n');
    application.inject('controller', 'i18n', 'util:i18n');
    application.inject('component', 'i18n', 'util:i18n');
    application.inject('helper', 'i18n', 'util:i18n');
  }
  var _default = _exports.default = {
    name: 'i18n-setup.js',
    initialize: initialize
  };
});