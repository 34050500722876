define("plutof/components/annotation/summary", ["exports", "@glimmer/component", "plutof/utils/annotation/format"], function (_exports, _component, _format) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "annotation.summary.title"}}
      class="annotation-summary"
  >
      <div>
          {{i18n-t "annotation.summary.annotatorStatus"
              annotator=@annotation.author.representation
              date=(formatted-date @annotation.annotated_at)}}
  
          <p>
              {{@annotation.comment}}
          </p>
      </div>
  
      {{#if @annotation.moderated_at}}
          <Annotation::-Summary::ModerationStatus @annotation={{@annotation}} />
      {{/if}}
  
      {{component this.format.components.view annotation=@annotation.annotation}}
  </PlutofPanel::Simple>
  
  <Annotation::-Summary::Log
      @annotation={{@annotation}}
      @canComment={{@permissions.canComment}} />
  
  */
  {
    "id": "3NJmPtYd",
    "block": "{\"symbols\":[\"@annotation\",\"@permissions\"],\"statements\":[[8,\"plutof-panel/simple\",[[24,0,\"annotation-summary\"]],[[\"@title\"],[[30,[36,0],[\"annotation.summary.title\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"annotation.summary.annotatorStatus\"],[[\"annotator\",\"date\"],[[32,1,[\"author\",\"representation\"]],[30,[36,1],[[32,1,[\"annotated_at\"]]],null]]]]],[2,\"\\n\\n        \"],[10,\"p\"],[12],[2,\"\\n            \"],[1,[32,1,[\"comment\"]]],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n\"],[6,[37,2],[[32,1,[\"moderated_at\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"annotation/-summary/moderation-status\",[],[[\"@annotation\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[1,[30,[36,3],[[32,0,[\"format\",\"components\",\"view\"]]],[[\"annotation\"],[[32,1,[\"annotation\"]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,\"annotation/-summary/log\",[],[[\"@annotation\",\"@canComment\"],[[32,1],[32,2,[\"canComment\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"formatted-date\",\"if\",\"component\"]}",
    "meta": {
      "moduleName": "plutof/components/annotation/summary.hbs"
    }
  });
  class AnnotationSummary extends _component.default {
    get format() {
      return _format.AnnotationFormatImplementations[this.args.annotation.format];
    }
  }
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, AnnotationSummary);
});