define("plutof/components/layer/occurrence-areas/constraints-check", ["exports", "@glimmer/component", "ol/format"], function (_exports, _component, _format) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.areaOutsideOfLayer}}
      <span class="failed-check-message">
          &nbsp;
          <span class={{icon "info"}}></span>
          {{i18n-t "Layer.info.areaOutsideLayerError"}}
      </span>
  {{/if}}
  
  */
  {
    "id": "9Ks3c3ib",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[32,0,[\"areaOutsideOfLayer\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"failed-check-message\"],[12],[2,\"\\n         \\n        \"],[10,\"span\"],[15,0,[30,[36,0],[\"info\"],null]],[12],[13],[2,\"\\n        \"],[1,[30,[36,1],[\"Layer.info.areaOutsideLayerError\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\",\"if\"]}",
    "meta": {
      "moduleName": "plutof/components/layer/occurrence-areas/constraints-check.hbs"
    }
  });
  let ConstraintsCheck = _exports.default = (_dec = Ember.computed('args.samplingArea.geom', 'args.layerArea'), (_class = class ConstraintsCheck extends _component.default {
    constructor(...args) {
      super(...args);
      this.wkt = new _format.WKT();
    }
    get areaOutsideOfLayer() {
      const {
        samplingArea,
        layerArea
      } = this.args;
      if (Ember.isNone(samplingArea) || Ember.isNone(samplingArea.geom)) {
        return false;
      }
      const samplingAreaFeatures = this.wkt.readFeatures(samplingArea.geom);
      const layerAreaFeatures = this.wkt.readFeatures(layerArea.geom);
      const areaIntersectsLayer = samplingAreaFeatures.some(samplingAreaFeature => {
        return layerAreaFeatures.some(layerAreaFeature => {
          const areaExtent = samplingAreaFeature.getGeometry().getExtent();
          const intersectsGeometry = layerAreaFeature.getGeometry().intersectsExtent(areaExtent);
          return intersectsGeometry;
        });
      });
      return !areaIntersectsLayer;
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "areaOutsideOfLayer", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "areaOutsideOfLayer"), _class.prototype)), _class));
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ConstraintsCheck);
});