define("plutof/translations/eng/breadcrumb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    tooltips: {
      project: 'Project',
      area: 'Area',
      event: 'Sampling event',
      occurrence: 'Taxon occurrence'
    }
  };
});