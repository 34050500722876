define("plutof/components/photobank/view/classifications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <RelatedObjects::View
      @title={{i18n-t "Photobank.classifications"}}
      @pagination={{@items}}
      data-test="Photobank::View::Classifications"
  >
      <RelatedObjects::Content @pagination={{@items}} as |Field item|>
          <Field @label={{backend-translations/label "customlistitem.custom_classification"}}>
              {{item.custom_list_item.customClassificationyDisplayName}}
          </Field>
  
          <Field @label={{backend-translations/label "customitem.custom_list_item"}}>
              {{item.custom_list_item.representation}}
          </Field>
  
          <Field @label={{backend-translations/label "customitem.remarks"}}>
              {{item.remarks}}
          </Field>
      </RelatedObjects::Content>
  </RelatedObjects::View>
  
  */
  {
    "id": "UhZwOkau",
    "block": "{\"symbols\":[\"Field\",\"item\",\"@items\"],\"statements\":[[8,\"related-objects/view\",[[24,\"data-test\",\"Photobank::View::Classifications\"]],[[\"@title\",\"@pagination\"],[[30,[36,0],[\"Photobank.classifications\"],null],[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"related-objects/content\",[],[[\"@pagination\"],[[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,1],[],[[\"@label\"],[[30,[36,1],[\"customlistitem.custom_classification\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[32,2,[\"custom_list_item\",\"customClassificationyDisplayName\"]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,1],[],[[\"@label\"],[[30,[36,1],[\"customitem.custom_list_item\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[32,2,[\"custom_list_item\",\"representation\"]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,1],[],[[\"@label\"],[[30,[36,1],[\"customitem.remarks\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[32,2,[\"remarks\"]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[1,2]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"backend-translations/label\"]}",
    "meta": {
      "moduleName": "plutof/components/photobank/view/classifications.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});