define("plutof/services/clipboard", ["exports", "plutof/misc/abstract", "plutof/utils/loading", "plutof/utils/modules", "plutof/utils/pagination"], function (_exports, _abstract, _loading, _modules, _pagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class ClipboardServiceClipboard {
    constructor(ajax, contentType) {
      _defineProperty(this, "_selection", []);
      this._ajax = ajax;
      this._contentType = contentType;
    }
    getSelection() {
      return Array.from(this._selection);
    }
    async paginate({
      pageSize = 20,
      fetchPageSize = 500
    } = {}) {
      const params = _modules.default[this._contentType].clipboard;
      const counts = await this._ajax.request('clipboard/count/');
      const total = counts[params.id];
      const requestPage = async (page, pageSize) => {
        const url = (0, _abstract.construct_request)(params.url, {
          page,
          page_size: pageSize
        });
        const response = await this._ajax.request(url);
        return response.results;
      };

      // TODO: Wrap in pagination without waiting for everything to load
      // (extract that part from editable pagination)
      const records = await (0, _loading.loadAllGeneric)(() => total, requestPage, fetchPageSize);
      return (0, _pagination.paginateLocalObjects)(records, {
        pageSize
      });
    }
    paginateSelection({
      pageSize = 20
    } = {}) {
      return (0, _pagination.paginateLocalObjects)(this.getSelection(), {
        pageSize
      });
    }
  }

  // TODO: Move to utils/modules?
  const CONTENT_TYPE_CLIPBOARDS = {
    'sequence/sequence': 'sequences',
    'livingculture/strain': 'livingSpecimens',
    'materialsample/materialsample': 'materialSamples',
    'observation/observation': 'observations',
    'photobank/collectionobject': 'photobank',
    'referencebased/occurrence': 'referenceBased',
    'specimen/specimen': 'specimens'
  };
  let ClipboardService = (_dec = Ember.inject.service, (_class = class ClipboardService extends Ember.Service {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _defineProperty(this, "sequences", new ClipboardServiceClipboard(this.ajax, 'sequence/sequence'));
      _defineProperty(this, "livingSpecimens", new ClipboardServiceClipboard(this.ajax, 'livingculture/strain'));
      _defineProperty(this, "materialSamples", new ClipboardServiceClipboard(this.ajax, 'materialsample/materialsample'));
      _defineProperty(this, "observations", new ClipboardServiceClipboard(this.ajax, 'observation/observation'));
      _defineProperty(this, "photobank", new ClipboardServiceClipboard(this.ajax, 'photobank/collectionobject'));
      _defineProperty(this, "referenceBased", new ClipboardServiceClipboard(this.ajax, 'referencebased/occurrence'));
      _defineProperty(this, "specimens", new ClipboardServiceClipboard(this.ajax, 'specimen/specimen'));
    }
    // contentType is specimen/specimen etc, not the ctype record
    forContentType(contentType) {
      const id = CONTENT_TYPE_CLIPBOARDS[contentType];
      if (!id) {
        throw new Error(`${contentType} has no clipboard`);
      }
      return this[id];
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  var _default = _exports.default = ClipboardService;
});