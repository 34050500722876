define("plutof/components/taxonomy-lab/taxon-description/edit", ["exports", "@glimmer/component", "plutof/components/linked-items/edit", "plutof/services/ajax", "plutof/utils/access"], function (_exports, _component, _edit, _ajax, _access) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TaxonDescriptionEditModel = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <TaxonomyLab::TaxonDescription::Form
      @taxonDescription={{@model.taxonDescription}}
      @validationContext={{@validationContext.taxonDescription}} />
  
  <TaxonomyLab::ConservationInstructions @taxonDescription={{@model.taxonDescription}} />
  
  <LinkedItems::Edit
      @data={{@model.items}}
      @validationContext={{@validationContext.items}} />
  
  <Access::EditRights
      @rights={{@model.accessRights}}
      @validationContext={{@validationContext.access}} />
  
  */
  {
    "id": "euGvtt0U",
    "block": "{\"symbols\":[\"@model\",\"@validationContext\"],\"statements\":[[8,\"taxonomy-lab/taxon-description/form\",[],[[\"@taxonDescription\",\"@validationContext\"],[[32,1,[\"taxonDescription\"]],[32,2,[\"taxonDescription\"]]]],null],[2,\"\\n\\n\"],[8,\"taxonomy-lab/conservation-instructions\",[],[[\"@taxonDescription\"],[[32,1,[\"taxonDescription\"]]]],null],[2,\"\\n\\n\"],[8,\"linked-items/edit\",[],[[\"@data\",\"@validationContext\"],[[32,1,[\"items\"]],[32,2,[\"items\"]]]],null],[2,\"\\n\\n\"],[8,\"access/edit-rights\",[],[[\"@rights\",\"@validationContext\"],[[32,1,[\"accessRights\"]],[32,2,[\"access\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "plutof/components/taxonomy-lab/taxon-description/edit.hbs"
    }
  });
  class TaxonDescriptionEditModel {
    constructor({
      store,
      taxonDescription,
      items,
      accessRights
    }) {
      this.store = store;
      this.taxonDescription = taxonDescription;
      this.items = items;
      this.accessRights = accessRights;
    }
    async save() {
      await this.taxonDescription.save();
      await this.accessRights.save();
      await this.items.save(this.taxonDescription, this.accessRights);
    }
    static async create(store) {
      const taxonDescription = store.createRecord('laboratory/taxonomylab/taxondescription');
      const items = await (0, _edit.createLinkedItemsData)(store, taxonDescription, {
        useEventFiles: false
      });
      const accessRights = await (0, _access.create_access_rights)(store, taxonDescription);
      return new TaxonDescriptionEditModel({
        store,
        taxonDescription,
        items,
        accessRights
      });
    }
    static async load(store, id) {
      const ajax = (0, _ajax.ajaxFromControlled)(store);
      const taxonDescription = await store.findRecord('laboratory/taxonomylab/taxondescription', id);
      const items = await (0, _edit.loadLinkedItemsData)(store, taxonDescription, {
        useEventFiles: false
      });
      const accessRights = await (0, _access.get_permissions)(store, ajax, taxonDescription);
      return new TaxonDescriptionEditModel({
        store,
        taxonDescription,
        items,
        accessRights
      });
    }
  }
  _exports.TaxonDescriptionEditModel = TaxonDescriptionEditModel;
  let TaxonDescriptionEdit = _exports.default = (_dec = Ember.inject.service, (_class = class TaxonDescriptionEdit extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "i18n", _descriptor, this);
      this.args.validationContext.addChild('taxonDescription', this.i18n.translate('Taxonomy.descriptionsLabel'));
      this.args.validationContext.addChild('access', this.i18n.translate('General.access'));
      this.args.validationContext.addChild('items', this.i18n.translate('General.associatedData'));
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "i18n", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TaxonDescriptionEdit);
});