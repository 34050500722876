define("plutof/helpers/relation-id", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // NB: Isn't reactive on record.set(relation, ...)
  var _default = _exports.default = Ember.Helper.helper(function relationID([record, relation]) {
    return record ? record.belongsTo(relation).id() : null;
  });
});