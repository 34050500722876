define("plutof/components/related-objects/content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Pagination::PageControls @pagination={{@pagination}} />
  
  <Tables::List
      @records={{@pagination.objects}}
      @headersClasses="related-objects-view__headers"
      @rowClasses="related-objects-view__row"
      as |Field object|
  >
      {{yield Field object}}
  </Tables::List>
  
  <div class="spacer-large"></div>
  
  <Pagination::PageControls
      @pagination={{@pagination}}
      @removeSpacing={{true}} />
  
  */
  {
    "id": "3OP2TVmz",
    "block": "{\"symbols\":[\"Field\",\"object\",\"@pagination\",\"&default\"],\"statements\":[[8,\"pagination/page-controls\",[],[[\"@pagination\"],[[32,3]]],null],[2,\"\\n\\n\"],[8,\"tables/list\",[],[[\"@records\",\"@headersClasses\",\"@rowClasses\"],[[32,3,[\"objects\"]],\"related-objects-view__headers\",\"related-objects-view__row\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[18,4,[[32,1],[32,2]]],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"spacer-large\"],[12],[13],[2,\"\\n\\n\"],[8,\"pagination/page-controls\",[],[[\"@pagination\",\"@removeSpacing\"],[[32,3],true]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "plutof/components/related-objects/content.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});