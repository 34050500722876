define("plutof/components/glossary/collection/general-data", ["exports", "ember-cp-validations", "plutof/mixins/component-validations"], function (_exports, _emberCpValidations, _componentValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "General.generalData"}}
      data-test="Glossary::Collection::GeneralData"
  >
      <Layout::RegularGrid @columns={{2}} @cellGap={{true}}>
          <div class="{{has-error this 'collection.name'}}">
              <PlutofLabelc @path="glossary.collection.name" />
  
              <Input
                  @type="text"
                  @value={{@collection.name}}
                  class="form-control default-focus-control"
                  data-test="name" />
  
          </div>
  
          <div class="{{has-error this 'collection.description'}}">
              <PlutofLabelc @path="glossary.collection.description" />
  
              <FlexibleTextarea
                  @type="text"
                  @value={{@collection.description}}
                  class="form-control"
                  data-test="description" />
          </div>
      </Layout::RegularGrid>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "YSX0aVlD",
    "block": "{\"symbols\":[\"@collection\"],\"statements\":[[8,\"plutof-panel/simple\",[[24,\"data-test\",\"Glossary::Collection::GeneralData\"]],[[\"@title\"],[[30,[36,0],[\"General.generalData\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"layout/regular-grid\",[],[[\"@columns\",\"@cellGap\"],[2,true]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"div\"],[15,0,[31,[[30,[36,1],[[32,0],\"collection.name\"],null]]]],[12],[2,\"\\n            \"],[8,\"plutof-labelc\",[],[[\"@path\"],[\"glossary.collection.name\"]],null],[2,\"\\n\\n            \"],[8,\"input\",[[24,0,\"form-control default-focus-control\"],[24,\"data-test\",\"name\"]],[[\"@type\",\"@value\"],[\"text\",[32,1,[\"name\"]]]],null],[2,\"\\n\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"div\"],[15,0,[31,[[30,[36,1],[[32,0],\"collection.description\"],null]]]],[12],[2,\"\\n            \"],[8,\"plutof-labelc\",[],[[\"@path\"],[\"glossary.collection.description\"]],null],[2,\"\\n\\n            \"],[8,\"flexible-textarea\",[[24,0,\"form-control\"],[24,\"data-test\",\"description\"]],[[\"@type\",\"@value\"],[\"text\",[32,1,[\"description\"]]]],null],[2,\"\\n        \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"has-error\"]}",
    "meta": {
      "moduleName": "plutof/components/glossary/collection/general-data.hbs"
    }
  });
  const Validations = (0, _emberCpValidations.buildValidations)({
    'collection.name': [(0, _emberCpValidations.validator)('present-not-blank'), (0, _emberCpValidations.validator)('length', {
      max: 1024
    })],
    'collection.description': (0, _emberCpValidations.validator)('length', {
      max: 2048
    })
  });
  class GlossaryCollectionGeneral extends Ember.Component.extend(Validations, _componentValidations.default) {}
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, GlossaryCollectionGeneral);
});