define("plutof/components/common/radio-group", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  let RadioGroup = (_dec = (0, _component.classNames)('radio-group'), _dec(_class = class RadioGroup extends Ember.Component {}) || _class);
  var _default = _exports.default = RadioGroup;
});