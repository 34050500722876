define("plutof/translations/est/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    form: {
      username: 'Kasutajanimi või e-post',
      password: 'Parool',
      optionSeparator: 'või'
    },
    signin: {
      'google-oauth2': 'Sisene Google kontoga',
      'orcid': 'ORCID'
    },
    provider: {
      'google-oauth2': 'Google',
      'orcid': 'ORCID',
      'orcid-sandbox': 'ORCID sandbox',
      'taat-test': 'TAAT (test)',
      'taat': 'TAAT'
    }
  };
});