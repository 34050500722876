define("plutof/utils/search", ["exports", "moment", "plutof/utils/formatters", "plutof/utils/i18n", "plutof/utils/string"], function (_exports, moment, _formatters, _i18n, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TOP_RESULTS_READ_MORE_LIMITER = _exports.TOP_RESULTS_ENDPOINT = _exports.TIME_OPERATOR_MAP = _exports.TIME_FILTERS = _exports.SEARCH_ENDPOINT = _exports.SEARCH_DELAY = _exports.RANGE_FILTERS = _exports.MAX_RESULTS = _exports.MAX_PAGE_SIZE = _exports.EXCLUDED_RESULTS = _exports.DEFAULT_PAGE_SIZE = _exports.DEFAULT_ORDERING = _exports.DEFAULT_COLUMNS = void 0;
  _exports.createMeasurementCells = createMeasurementCells;
  _exports.decodeMeasurementValue = decodeMeasurementValue;
  _exports.getArgumentsQuery = getArgumentsQuery;
  _exports.getCellIcons = getCellIcons;
  _exports.getCellValue = getCellValue;
  _exports.mergedCell = mergedCell;
  _exports.mergedHeader = mergedHeader;
  _exports.mergedKeys = mergedKeys;
  _exports.mergedSourceKeys = mergedSourceKeys;
  _exports.removeMergedCells = removeMergedCells;
  _exports.sortHeaders = sortHeaders;
  const i18n = (0, _i18n.getI18n)();
  const TOP_RESULTS_READ_MORE_LIMITER = _exports.TOP_RESULTS_READ_MORE_LIMITER = 5;
  const DEFAULT_PAGE_SIZE = _exports.DEFAULT_PAGE_SIZE = 50;
  const SEARCH_DELAY = _exports.SEARCH_DELAY = 333;
  const SEARCH_ENDPOINT = _exports.SEARCH_ENDPOINT = '/search/';
  const DEFAULT_ORDERING = _exports.DEFAULT_ORDERING = '';
  const DEFAULT_COLUMNS = _exports.DEFAULT_COLUMNS = ['name', 'last_modified', 'owner'];
  const EXCLUDED_RESULTS = _exports.EXCLUDED_RESULTS = ['id', 'measurements', 'restricted', 'content_types', 'files', 'download_links', 'name_est', 'gathering_agents', 'determiners'];
  const TOP_RESULTS_ENDPOINT = _exports.TOP_RESULTS_ENDPOINT = '/search/top-results/';
  const MAX_PAGE_SIZE = _exports.MAX_PAGE_SIZE = 500;
  // Number of maximum browsable results.
  // Used for calculating number of browsable pages.
  const MAX_RESULTS = _exports.MAX_RESULTS = 50000;

  // filters to be augumented by range operators
  const TIME_FILTERS = _exports.TIME_FILTERS = ['timespan_begin', 'timespan_end', 'created_at', 'created', 'last_modified', 'modified', 'digitization_date', 'start_date', 'end_date', 'expected_end_date', 'date_identified', 'assessment_date', 'due_date'];
  const RANGE_FILTERS = _exports.RANGE_FILTERS = ['preparation_counts', 'individual_count', 'image_height', 'image_width', 'concentration', 'unit_of_volume', 'seq_length', 'total_transactions'];

  // time filter suffix operators and their representations
  const TIME_OPERATOR_MAP = _exports.TIME_OPERATOR_MAP = {
    '__lt': '<',
    '__lte': '<=',
    '': '=',
    '__gte': '>=',
    '__gt': '>'
  };
  const RELATED_OBJECT_STYLE_MAP = {
    audio: 'icon-file-sound',
    video: 'icon-file-video',
    image: 'icon-file-image',
    files: 'icon-file-data',
    externallink: 'icon-external-link'
  };
  function getArrayValue(array, arrayLimit = 6) {
    var fullStop = array.length < 2 ? '' : '.';
    return array.length < arrayLimit ? array.join(', ') + fullStop : array.slice(0, arrayLimit).join(', ') + '...';
  }
  function getCellIcons(source) {
    if (Ember.typeOf(source) === 'object') {
      return Object.keys(source).map(key => source[key] ? RELATED_OBJECT_STYLE_MAP[key] : null).compact();
    } else {
      return null;
    }
  }
  function getCellValue(field, source, config) {
    if (Ember.isEmpty(source) || Ember.isEmpty(config)) {
      return null;
    }

    // Taxon search
    if (field === 'vernacular_names') {
      return source.map(item => `${item.name} (${item.iso_639})`).join('; ');
    }

    // SH search
    if (field === 'taxon_node') {
      return (0, _string.truncate)(source.name);
    }
    let value = null;
    switch (config['type']) {
      case 'boolean':
        value = Ember.isEqual(source, true) ? i18n.t('General.yes') : i18n.t('General.no');
        break;
      case 'datetime':
        value = moment(source).format((0, _formatters.getAmbiguousDateFormat)(source));
        break;
      case 'object':
        if (Ember.isArray(source)) {
          value = getArrayValue(source.map(object => (0, _string.truncate)(object['name'])));
        } else {
          value = (0, _string.truncate)(source['name']);
        }
        break;
      default:
        if (Ember.isArray(source)) {
          if (Ember.isEmpty(source.compact())) {
            return null;
          }
          value = getArrayValue(source.map(item => (0, _string.truncate)(item.toString())));
        } else {
          value = (0, _string.truncate)(source.toString());
        }
    }
    return value;
  }

  // Add search module-specific query arguments
  function getArgumentsQuery(argumentsObject) {
    if (Ember.isNone(argumentsObject)) {
      return '';
    } else {
      return Object.keys(argumentsObject).map(key => {
        return `${key}=${argumentsObject[key]}`;
      }).join('&');
    }
  }
  function sortHeaders(a, b) {
    const labelA = a.config.label.toLowerCase();
    const labelB = b.config.label.toLowerCase();
    if (labelA > labelB) {
      return 1;
    } else if (labelA < labelB) {
      return -1;
    } else {
      return 0;
    }
  }
  function decodeMeasurementValue(pair) {
    let [param, value] = pair.split(':');
    const decoded = {
      id: param,
      value,
      any: undefined,
      isParentAreaTrait: false
    };
    if (param.includes('__isnull')) {
      Object.assign(decoded, {
        id: param.slice(0, param.length - '__isnull'.length),
        any: value === 'false',
        value: null
      });
    }
    if (decoded.id.startsWith('parent_area_trait_')) {
      Object.assign(decoded, {
        id: decoded.id.slice('parent_area_trait_'.length),
        isParentAreaTrait: true
      });
    }
    return decoded;
  }
  function createMeasurementCells(measurementIds, measurements, visibleColumns) {
    const measurementsArray = measurements || [];
    const cells = measurementIds.map(function (id) {
      const m = measurementsArray.findBy('measurement_id', parseInt(id));
      const cell = Ember.Object.create({
        name: id,
        value: Ember.isEmpty(m) ? null : m['value'],
        config: {
          label: id,
          type: 'string'
        },
        // mimic options
        isVisible: visibleColumns.includes(id)
      });
      return cell;
    });
    return cells;
  }

  // Currently for merging only two values.
  function mergeCellValues(a, b) {
    const valueA = Ember.isPresent(a) ? a : '-';
    const valueB = Ember.isPresent(b) ? b : '-';
    return `${valueA} / ${valueB}`;
  }
  function mergedCell(mergeConfig, cellKeys, visibleCellKeys, result, fields) {
    const values = mergeConfig.keys.map(key => getCellValue(key, result[key], fields[key]));
    return Ember.Object.create({
      value: mergeCellValues(...values),
      isVisible: visibleCellKeys.includes(mergeConfig.key),
      config: {
        label: mergeConfig.label,
        type: 'string',
        ordering: mergeConfig.ordering
      }
    });
  }
  function mergedKeys(configs = []) {
    return configs.map(c => c.key);
  }
  function mergedSourceKeys(configs = []) {
    return configs.reduce((acc, config) => {
      return acc.concat(config.keys);
    }, []);
  }
  function removeMergedCells(cells, mergeConfigs) {
    const keys = mergedSourceKeys(mergeConfigs);
    keys.forEach(key => {
      const cell = cells.findBy('name', key);
      cells.removeObject(cell);
    });
    return cells;
  }
  function mergedHeader(mergeConfig, filters, visibleCellKeys, ordering) {
    if (!mergeConfig) {
      return null;
    }
    return Ember.Object.create({
      name: mergeConfig.key,
      ascendingOrder: ordering === mergeConfig.ordering,
      ordering: mergeConfig.ordering,
      isVisible: visibleCellKeys.has(mergeConfig.key),
      config: {
        label: mergeConfig.label,
        ordering: Ember.isPresent(mergeConfig.ordering)
      }
    });
  }
});