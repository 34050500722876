define("plutof/translations/est/glossary-collection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    title: 'Märksõnade kogumikud',
    searchTitle: 'Otsi märksõnade kogumikke',
    infoTitle: 'Märksõnade kogumikud',
    glossary: 'Märksõna',
    newCollection: 'Uus märksõnade kogumik',
    editCollection: 'Muuda märksõnade kogumikku',
    keywordCollection: 'Märksõnade kogumik'
  };
});