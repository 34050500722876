define("plutof/components/search/filter-panels/annotation/time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "Search.panelTime"}}
      @collapse={{true}}
  >
      <Layout::RegularGrid @columns={{2}}>
          {{#if @filterInputs.annotated_at}}
              <Search::Filters::TimeRangeInput @filter={{@filterInputs.annotated_at}} />
          {{/if}}
      </Layout::RegularGrid>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "y/IqdPkW",
    "block": "{\"symbols\":[\"@filterInputs\"],\"statements\":[[8,\"plutof-panel/simple\",[],[[\"@title\",\"@collapse\"],[[30,[36,0],[\"Search.panelTime\"],null],true]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"layout/regular-grid\",[],[[\"@columns\"],[2]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[32,1,[\"annotated_at\"]]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"search/filters/time-range-input\",[],[[\"@filter\"],[[32,1,[\"annotated_at\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"if\"]}",
    "meta": {
      "moduleName": "plutof/components/search/filter-panels/annotation/time.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});