define("plutof/mixins/route-title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    buildRouteInfoMetadata() {
      return {
        // XXX: Why is currentModel sometimes null?
        // XXX: this.title workaround for modelless routes
        title: this.currentModel || this.title ? this.getTitle(this.currentModel) : null
      };
    },
    getTitle(model) {
      if (!Ember.isNone(this.title)) {
        return this.i18n.t(this.title);
      }
      if (!Ember.isNone(this.modelTitle) && !Ember.isNone(model)) {
        return model[this.modelTitle].representation;
      }
      return null;
    }
  });
});