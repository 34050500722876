define("plutof/components/access/-view-rights/history", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#access/-view-rights/column}}
      {{common/local-label "Access.created"}}
  
      {{! XXX: <p> inside <p>? }}
      <p>
          {{formatted-date @createdAt}}
  
          <p>
              ({{@createdBy}})
          </p>
      </p>
  {{/access/-view-rights/column}}
  
  {{#access/-view-rights/column}}
      {{common/local-label "Access.modified"}}
  
      <p>
          {{formatted-date @updatedAt}}
  
          <p>
              ({{@updatedBy}})
          </p>
      </p>
  {{/access/-view-rights/column}}
  
  */
  {
    "id": "R5qG0ffp",
    "block": "{\"symbols\":[\"@updatedAt\",\"@updatedBy\",\"@createdAt\",\"@createdBy\"],\"statements\":[[6,[37,2],null,null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],[\"Access.created\"],null]],[2,\"\\n\\n\"],[2,\"    \"],[10,\"p\"],[12],[2,\"\\n        \"],[1,[30,[36,1],[[32,3]],null]],[2,\"\\n\\n        \"],[10,\"p\"],[12],[2,\"\\n            (\"],[1,[32,4]],[2,\")\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,2],null,null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],[\"Access.modified\"],null]],[2,\"\\n\\n    \"],[10,\"p\"],[12],[2,\"\\n        \"],[1,[30,[36,1],[[32,1]],null]],[2,\"\\n\\n        \"],[10,\"p\"],[12],[2,\"\\n            (\"],[1,[32,2]],[2,\")\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"common/local-label\",\"formatted-date\",\"access/-view-rights/column\"]}",
    "meta": {
      "moduleName": "plutof/components/access/-view-rights/history.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});