define("plutof/components/annotation/list/icon", ["exports", "@glimmer/component", "plutof/utils/annotation/format"], function (_exports, _component, _format) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.isElixirAnnotation}}
      <ToolTip @tooltip="European Nucleotide Archive">
          <img src="/assets/images/ENA_logo.svg" class="svg-icon">
      </ToolTip>
  {{/if}}
  
  */
  {
    "id": "bmc+P/Qd",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,0],[[32,0,[\"isElixirAnnotation\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"tool-tip\",[],[[\"@tooltip\"],[\"European Nucleotide Archive\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"img\"],[14,\"src\",\"/assets/images/ENA_logo.svg\"],[14,0,\"svg-icon\"],[12],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "plutof/components/annotation/list/icon.hbs"
    }
  });
  class AnnotationIcon extends _component.default {
    get isElixirAnnotation() {
      return this.args.annotation.annotation.format === _format.AnnotationFormats.Elixir || this.args.annotation.annotation.intended_for_publication;
    }
  }
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, AnnotationIcon);
});