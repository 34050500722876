define("plutof/templates/sequence/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "1gwCvO3N",
    "block": "{\"symbols\":[\"Field\",\"sequence\",\"common\",\"navbar\",\"slots\"],\"statements\":[[8,\"test/route-marker\",[],[[\"@route\"],[\"sequence.index\"]],null],[2,\"\\n\"],[10,\"div\"],[14,0,\"route-marker-sequence-index\"],[12],[13],[2,\"\\n\\n\"],[8,\"navbar/list\",[],[[\"@icon\",\"@title\",\"@addRoute\",\"@pagination\",\"@infoTitle\"],[[30,[36,0],[\"sequence\"],null],[30,[36,1],[\"Sequences.title\"],null],\"sequence.add\",[32,0,[\"model\",\"pagination\"]],[30,[36,1],[\"Sequences.infoTitle\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,5,[\"primaryControls\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"import/navbar-button\",[],[[\"@navbar\",\"@module\"],[[32,4],\"sequence\"]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[4,5]}]]],[2,\"\\n\\n\"],[8,\"tables/list-route\",[],[[\"@records\"],[[32,0,[\"model\",\"pagination\",\"objects\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1],[],[[\"@label\"],[[30,[36,1],[\"PlutofListViewColumn.sequenceId\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],null,[[\"route\",\"model\"],[\"sequence.view\",[32,2,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[32,2,[\"compound_id\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,3,[\"modified\"]],[],[[],[]],null],[2,\"\\n    \"],[8,[32,3,[\"relatedObjects\"]],[],[[],[]],null],[2,\"\\n    \"],[8,[32,3,[\"clipboard\"]],[],[[\"@contentType\"],[[32,0,[\"model\",\"sequenceCType\"]]]],null],[2,\"\\n    \"],[8,[32,3,[\"access\"]],[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[1,2,3]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\",\"link-to\"]}",
    "meta": {
      "moduleName": "plutof/templates/sequence/index.hbs"
    }
  });
});