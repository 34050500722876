define("plutof/templates/components/interaction/view-single", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "V90irK5Q",
    "block": "{\"symbols\":[\"view\",\"@contentType\",\"@object\",\"@interaction\",\"@hideTaxonType\"],\"statements\":[[8,\"generic-views/record\",[],[[\"@record\",\"@testMarker\"],[[32,4],\"interaction-view\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"layout/regular-grid\",[],[[\"@columns\"],[4]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,0],[[32,1,[\"attr\"]],\"object\"],[[\"label\",\"annotated\"],[[30,[36,2],[\"Interaction.interactionObject\"],null],[30,[36,1],[[32,4],\"object_id\",\"interacting_object_id\"],null]]],[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"generic-views/pojo-record-link\",[],[[\"@contentType\",\"@id\",\"@name\"],[[32,2,[\"full_name\"]],[32,3,[\"id\"]],[32,3,[\"representation\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,3],[[32,5]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,0],[[32,1,[\"field\"]],\"interacting_taxon_type\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n        \"],[1,[30,[36,0],[[32,1,[\"field\"]],\"interaction_type\"],null]],[2,\"\\n        \"],[1,[30,[36,0],[[32,1,[\"attr\"]],\"remarks\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"annotation/fields-modified\",\"i18n-t\",\"unless\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/interaction/view-single.hbs"
    }
  });
});