define("plutof/templates/components/experiment/common/laboratory-autocomplete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "HP2snEsb",
    "block": "{\"symbols\":[\"@value\",\"@update\",\"@params\",\"&attrs\"],\"statements\":[[8,\"auto-complete/base\",[[17,4]],[[\"@backend\",\"@value\",\"@update\",\"@params\"],[[32,0,[\"backend\"]],[32,1],[32,2],[30,[36,2],[[32,3],[30,[36,1],null,[[\"sideAction\",\"sideActionIcon\"],[[32,0,[\"selectDefault\"]],[30,[36,0],[\"molecular-lab\"],null]]]]],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"hash\",\"merge-params\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/experiment/common/laboratory-autocomplete.hbs"
    }
  });
});