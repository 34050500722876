define("plutof/templates/components/file-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "a60eeB1u",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[15,0,[31,[[32,0,[\"icon\"]]]]],[12],[13],[2,\"\\n\\n\"],[6,[37,2],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],[[35,0]],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"name\",\"i18n-t\",\"if\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/file-selector.hbs"
    }
  });
});