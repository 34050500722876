define("plutof/utils/group", ["exports", "plutof/misc/profile_settings", "plutof/utils/store"], function (_exports, _profile_settings, _store) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.statusInWorkgroup = statusInWorkgroup;
  _exports.userIsInWorkgroup = userIsInWorkgroup;
  async function userIsInWorkgroup(store, workgroupId) {
    if (Ember.isNone(workgroupId)) {
      return true;
    }
    const user = await (0, _profile_settings.get_current_user)(store);
    if (Ember.isNone(user)) {
      return false;
    }
    const status = await statusInWorkgroup(store, workgroupId, user);
    return !Ember.isNone(status) && status > 0;
  }
  async function statusInWorkgroup(store, workgroupId, user) {
    const resolvedUser = user ? user : await (0, _profile_settings.get_current_user)(store);
    try {
      const membership = (await (0, _store.query)(store, 'users/usergroup', {
        group: workgroupId,
        user: resolvedUser.id
      }))[0];
      return membership.status;
    } catch (err) {
      return null;
    }
  }
});