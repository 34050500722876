define("plutof/controllers/organization/edit", ["exports", "plutof/utils/i18n", "plutof/utils/access", "plutof/utils/controllers", "plutof/utils/notifications"], function (_exports, _i18n, _access, _controllers, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.uploadLogo = uploadLogo;
  const i18n = (0, _i18n.getI18n)();
  async function uploadLogo(store, fileUpload, logo) {
    (0, _notifications.displayNotification)('status', i18n.t('Forms.uploadingImage'));

    // File must be public for its URL to be persistent
    const access = await (0, _access.create_access_rights)(store);
    access.visible = 'PUBLIC';
    const [poolFile] = await fileUpload.addLocalFiles([logo]);
    const file = await poolFile.save(access);
    return file.download_links.link;
  }
  class OrganizationEditController extends _controllers.EditController {
    _save() {
      return this.model.organization.save();
    }
  }
  _exports.default = OrganizationEditController;
});