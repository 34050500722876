define("plutof/components/search/column-control", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Tables::ColumnSelector @disabled={{@tableIsEmpty}} as |columnSelector|>
      <columnSelector.column
          @toggle={{this.toggleAllColumns}}
          @visible={{this.allHeadersVisible}}
      >
          {{i18n-t "Search.toggleAllColumns"}}
      </columnSelector.column>
  
      {{#each this.regularHeaders as |header|}}
          <Search::ColumnControl::Column
              @column={{component columnSelector.column}}
              @pin={{component columnSelector.pin}}
              @header={{header}}
              @absoluteOrdering={{@absoluteOrdering}}
              @pinColumn={{@pinColumn}}
              @toggleColumn={{@toggleColumn}} />
      {{/each}}
  
      {{#if this.traitHeaders}}
          <div class="column-control-label">
              {{i18n-t "traits.traits"}}
          </div>
  
          {{#each this.traitHeaders as |header|}}
              <Search::ColumnControl::Column
                  @column={{component columnSelector.column}}
                  @pin={{component columnSelector.pin}}
                  @header={{header}}
                  @absoluteOrdering={{@absoluteOrdering}}
                  @pinColumn={{@pinColumn}}
                  @toggleColumn={{@toggleColumn}} />
          {{/each}}
      {{/if}}
  </Tables::ColumnSelector>
  
  */
  {
    "id": "EkZlsCu0",
    "block": "{\"symbols\":[\"columnSelector\",\"header\",\"header\",\"@absoluteOrdering\",\"@pinColumn\",\"@toggleColumn\",\"@tableIsEmpty\"],\"statements\":[[8,\"tables/column-selector\",[],[[\"@disabled\"],[[32,7]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"column\"]],[],[[\"@toggle\",\"@visible\"],[[32,0,[\"toggleAllColumns\"]],[32,0,[\"allHeadersVisible\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,1],[\"Search.toggleAllColumns\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"regularHeaders\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"search/column-control/column\",[],[[\"@column\",\"@pin\",\"@header\",\"@absoluteOrdering\",\"@pinColumn\",\"@toggleColumn\"],[[30,[36,0],[[32,1,[\"column\"]]],null],[30,[36,0],[[32,1,[\"pin\"]]],null],[32,3],[32,4],[32,5],[32,6]]],null],[2,\"\\n\"]],\"parameters\":[3]}]]],[2,\"\\n\"],[6,[37,4],[[32,0,[\"traitHeaders\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"column-control-label\"],[12],[2,\"\\n            \"],[1,[30,[36,1],[\"traits.traits\"],null]],[2,\"\\n        \"],[13],[2,\"\\n\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"traitHeaders\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"search/column-control/column\",[],[[\"@column\",\"@pin\",\"@header\",\"@absoluteOrdering\",\"@pinColumn\",\"@toggleColumn\"],[[30,[36,0],[[32,1,[\"column\"]]],null],[30,[36,0],[[32,1,[\"pin\"]]],null],[32,2],[32,4],[32,5],[32,6]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"i18n-t\",\"-track-array\",\"each\",\"if\"]}",
    "meta": {
      "moduleName": "plutof/components/search/column-control.hbs"
    }
  });
  let ColumnControl = (_dec = Ember.computed('args.headers.@each.isVisible'), _dec2 = Ember.computed('args.headers.@each.isTraitHeader'), _dec3 = Ember.computed('args.headers.@each.isTraitHeader'), _dec4 = Ember._action, (_class = class ColumnControl extends _component.default {
    get allHeadersVisible() {
      const headers = this.args.headers;
      return headers && !headers.some(header => !header.isVisible);
    }
    get regularHeaders() {
      return this.args.headers.filter(header => !header.isTraitHeader);
    }
    get traitHeaders() {
      return this.args.headers.filter(header => header.isTraitHeader);
    }
    toggleAllColumns() {
      this.args.setColumnsVisibility(!this.allHeadersVisible);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "allHeadersVisible", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "allHeadersVisible"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "regularHeaders", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "regularHeaders"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "traitHeaders", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "traitHeaders"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleAllColumns", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "toggleAllColumns"), _class.prototype)), _class));
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ColumnControl);
});