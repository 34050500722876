define("plutof/translations/est/reference-series", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    title: 'Sarjad',
    searchTitle: 'Otsi sarju',
    infoTitle: 'Sarjad',
    editReference: 'Muuda kirjandusviidet',
    reference: 'Kirjandusviide'
  };
});