define("plutof/components/agent/person/edit", ["exports", "@glimmer/component", "plutof/utils/store"], function (_exports, _component, _store) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.PersonEditModel = void 0;
  var _dec, _dec2, _class, _dec3, _class2, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::Simple @title={{i18n-t "General.generalData"}}>
      <Agent::PersonForm
          @person={{@model.person}}
          @givenNameRequired={{@givenNameRequired}}
          @validationContext={{@validationContext.person}} />
  </PlutofPanel::Simple>
  
  <PlutofPanel::ItemList
      @collapse={{not @model.associations.length}}
      data-test="Agent::Person::Edit::Associations"
      as |panel|
  >
      <panel.header
          @title={{i18n-t "Agents.associatedOrg"}}
          @count={{@model.agentassociations.length}}
      >
          <panel.button
              @clicked={{@model.addAssociation}}
              @class="test-marker-btn-add"
          >
              {{i18n-t "General.Add"}}
          </panel.button>
      </panel.header>
  
      <panel.innerPanels>
          <Validation::SplitContext
              @context={{@validationContext.associations}}
              @items={{@model.associations}}
              as |association context|
          >
              <Agent::AgentAssociation
                  @agentassociation={{association}}
                  @remove={{fn @model.removeAssociation association}}
                  @validationContext={{context}} />
          </Validation::SplitContext>
      </panel.innerPanels>
  </PlutofPanel::ItemList>
  
  */
  {
    "id": "LN+cCbcY",
    "block": "{\"symbols\":[\"panel\",\"association\",\"context\",\"@model\",\"@givenNameRequired\",\"@validationContext\"],\"statements\":[[8,\"plutof-panel/simple\",[],[[\"@title\"],[[30,[36,0],[\"General.generalData\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"agent/person-form\",[],[[\"@person\",\"@givenNameRequired\",\"@validationContext\"],[[32,4,[\"person\"]],[32,5],[32,6,[\"person\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,\"plutof-panel/item-list\",[[24,\"data-test\",\"Agent::Person::Edit::Associations\"]],[[\"@collapse\"],[[30,[36,1],[[32,4,[\"associations\",\"length\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"header\"]],[],[[\"@title\",\"@count\"],[[30,[36,0],[\"Agents.associatedOrg\"],null],[32,4,[\"agentassociations\",\"length\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,1,[\"button\"]],[],[[\"@clicked\",\"@class\"],[[32,4,[\"addAssociation\"]],\"test-marker-btn-add\"]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,0],[\"General.Add\"],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,1,[\"innerPanels\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"validation/split-context\",[],[[\"@context\",\"@items\"],[[32,6,[\"associations\"]],[32,4,[\"associations\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,\"agent/agent-association\",[],[[\"@agentassociation\",\"@remove\",\"@validationContext\"],[[32,2],[30,[36,2],[[32,4,[\"removeAssociation\"]],[32,2]],null],[32,3]]],null],[2,\"\\n        \"]],\"parameters\":[2,3]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"not\",\"fn\"]}",
    "meta": {
      "moduleName": "plutof/components/agent/person/edit.hbs"
    }
  });
  let PersonEditModel = _exports.PersonEditModel = (_dec = Ember._action, _dec2 = Ember._action, (_class = class PersonEditModel {
    constructor({
      store,
      person,
      associations
    }) {
      _defineProperty(this, "deleted", []);
      this.store = store;
      this.person = person;
      this.associations = associations;
    }
    async save() {
      await Ember.RSVP.Promise.all(this.deleted.map(async association => {
        const address = await association.address;
        await address.destroyRecord();
        await association.destroyRecord();
      }));
      this.deleted = [];
      await this.person.save();
      await Ember.RSVP.Promise.all(this.associations.map(async association => {
        const address = await association.address;
        await address.save();
        await association.save();
      }));
    }
    addAssociation() {
      const newAgent = this.store.createRecord('agent/agentassociation', {
        person: this.person,
        address: this.store.createRecord('agent/address', {})
      });
      this.associations.pushObject(newAgent);
    }
    removeAssociation(association) {
      this.deleted.push(association);
      this.associations.removeObject(association);
    }
    static create(store) {
      return new PersonEditModel({
        store,
        person: store.createRecord('agent/person'),
        associations: []
      });
    }
    static async load(store, personID) {
      const [person, associations] = await Ember.RSVP.Promise.all([store.findRecord('agent/person', personID), (0, _store.query)(store, 'agent/agentassociation', {
        person: personID
      })]);
      return new PersonEditModel({
        store,
        person,
        associations
      });
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "addAssociation", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "addAssociation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeAssociation", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "removeAssociation"), _class.prototype)), _class));
  let PersonEdit = _exports.default = (_dec3 = Ember.inject.service, (_class2 = class PersonEdit extends _component.default {
    // TODO: Been doing it like this, but does this work if validationContext
    // changes?
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "i18n", _descriptor, this);
      this.args.validationContext.addChild('person', this.i18n.translate('General.generalData'));
      this.args.validationContext.addChild('associations', this.i18n.translate('Agents.associatedOrg'));
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "i18n", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2));
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PersonEdit);
});