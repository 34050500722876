define("plutof/components/tables/-list-view/access", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <@Field @label={{i18n-t "PlutofListViewColumn.access"}}>
      <span class={{icon (if @record.is_public 'public' 'private')}}></span>
  </@Field>
  
  */
  {
    "id": "E6swbSW3",
    "block": "{\"symbols\":[\"@Field\",\"@record\"],\"statements\":[[8,[32,1],[],[[\"@label\"],[[30,[36,0],[\"PlutofListViewColumn.access\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"span\"],[15,0,[30,[36,2],[[30,[36,1],[[32,2,[\"is_public\"]],\"public\",\"private\"],null]],null]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"if\",\"icon\"]}",
    "meta": {
      "moduleName": "plutof/components/tables/-list-view/access.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});