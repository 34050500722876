define("plutof/components/pagination/editable/toggle-dirty-items", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <button
      {{on "click" this.toggle}}
      disabled={{this.disabled}}
      class="btn btn-default btn-xs"
  >
      {{#if @pagination.showingDirtyItems}}
          {{i18n-t "Agents.associations.showAll"}}
  
          <span class="count-badge">
              {{@pagination.objectCount}}
          </span>
      {{else}}
          {{i18n-t "Agents.associations.showChanged"}}
  
          <span class="count-badge">
              {{@pagination.dirtyItems.length}}
          </span>
      {{/if}}
  </button>
  
  */
  {
    "id": "fiHXNFJu",
    "block": "{\"symbols\":[\"@pagination\"],\"statements\":[[11,\"button\"],[16,\"disabled\",[32,0,[\"disabled\"]]],[24,0,\"btn btn-default btn-xs\"],[4,[38,1],[\"click\",[32,0,[\"toggle\"]]],null],[12],[2,\"\\n\"],[6,[37,2],[[32,1,[\"showingDirtyItems\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,0],[\"Agents.associations.showAll\"],null]],[2,\"\\n\\n        \"],[10,\"span\"],[14,0,\"count-badge\"],[12],[2,\"\\n            \"],[1,[32,1,[\"objectCount\"]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[30,[36,0],[\"Agents.associations.showChanged\"],null]],[2,\"\\n\\n        \"],[10,\"span\"],[14,0,\"count-badge\"],[12],[2,\"\\n            \"],[1,[32,1,[\"dirtyItems\",\"length\"]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"on\",\"if\"]}",
    "meta": {
      "moduleName": "plutof/components/pagination/editable/toggle-dirty-items.hbs"
    }
  });
  let ToggleDirtyItems = _exports.default = (_dec = Ember.computed('args.pagination.showingFilteredItems', 'args.pagination.dirtyItems.[]', 'args.pagination.showingDirtyItems'), _dec2 = Ember._action, (_class = class ToggleDirtyItems extends _component.default {
    get disabled() {
      const pagination = this.args.pagination;
      return pagination.showingFilteredItems || !pagination.showingDirtyItems && pagination.dirtyItems.length === 0;
    }
    toggle() {
      if (this.args.pagination.showingDirtyItems) {
        this.args.pagination.clearView();
      } else {
        this.args.pagination.switchToDirtyItems();
      }
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "disabled", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "disabled"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggle", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "toggle"), _class.prototype)), _class));
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ToggleDirtyItems);
});