define("plutof/translations/est/collection-management", ["exports", "plutof/translations/helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    /* INDEX */
    indexTitle: (0, _helpers.alias)('General.CollectionManagement'),
    conditionAssessmentsLabel: 'Hinnangud seisundile',
    treatmentsLabel: 'Hooldused',
    conditionAssessmentsInfo: 'Siin võid seisundite hinnanguid lisada ja muuta.',
    treatmentsInfo: 'Siin võid eksemplaride hooldustingimuste kirjeldusi sirvida ja muuta.',
    photobankInfo: 'Siin võid fotopanga objekte lisada ja muuta.',
    /* MAIN LIST */
    mainTitle: 'Hinnangud seisundile',
    mainInfoTitle: 'Hinnangud seisundile',
    transactionsTitle: 'Transaktsioonid',
    /* TREATMENTS LIST */
    treatmentsTitle: 'Hooldused',
    treatmentsInfoTitle: 'Hooldused',
    /* THE REST */
    assessedBy: 'Hindaja(d)',
    newCA: 'Uus hinnang',
    CA: 'Hinnang seisundile',
    editCA: 'Muuda hinnangut',
    treatment: 'Hooldus',
    newTreatment: 'Uus hooldus',
    editTreatment: 'Muuda hoolduse andmeid',
    data: 'Üldandmed',
    conditionAssessmentTypes: {
      specimen: 'Eksemplar',
      livingSpecimen: 'Eluseksemplar'
    }
  };
});