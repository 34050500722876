define("plutof/translations/est/cloning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    // Navbar.
    clone: 'Kopeeri',
    cloneTooltip: 'Avab selle kirje andmetega täidetud uue sisestusvormi.',
    linkToArea: 'Lingi alaga',
    linkToAreaTooltip: 'Avab selle kirje andmetega täidetud ja eelvalitud alaga uue sisestusvormi. Uus kirje lingitakse eelvalitud alaga.',
    linkToEvent: 'Lingi sündmusega',
    linkToEventTooltip: 'Avab selle kirje andmetega täidetud; eelvalitud ala ja sündmusega uue sisestusvormi. Uus kirje lingitakse eelvalitud ala ja sündmusega.',
    // Save panel
    saveAndClone: 'Salvesta ja kopeeri',
    saveAndCloneTooltip: 'Kirje(te) salvestamise järel avaneb uus eeltäidetud sisestusvorm, millesse on kopeeritud ka käesoleva ala andmed. Olemasolevad ülem-alade ja projektide seosed säilitatakse.',
    saveUseArea: 'Salvesta ja lingi alaga',
    saveUseAreaTooltip: 'Kirje(te) salvestamise järel avaneb uus eeltäidetud sisestusvorm eelvalitud alaga - uued kirjed lingitakse eelvalitud alaga. Olemasolevad ala, ülem-alade ja projektide seosed säilitatakse.',
    saveUseEvent: 'Salvesta ja lingi sündmusega',
    saveUseEventTooltip: 'Kirje(te) salvestamise järel avaneb uus eeltäidetud sisestusvorm eelvalitud ala ja sündmusega - uued kirjed lingitakse eelvalitud ala ja sündmusega. Olemasolevad ala, ülem-alade ja projektide seosed säilitatakse.',
    saveAndAddNew: 'Salvesta ja lisa uus',
    saveAndAddNewTooltip: 'Kirje(te) salvestamise järel avaneb uus tühi sisestusvorm.'
  };
});