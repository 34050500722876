define("plutof/components/measurements/measurement-view/expanded", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <GenericViews::Record
      @record={{@measurement}}
      class="expanded-measurement-view"
      as |view|
  >
      <Layout::RegularGrid @columns={{2}}>
          {{#view.attr "value"}}
              {{@measurement.stringValue}}
          {{/view.attr}}
  
          {{view.attr "method_desc"}}
          {{view.attr "remarks"}}
          {{view.attr "warnings"}}
  
          {{#view.attr "determined_at"}}
              {{formatted-date @measurement.determined_at}}
          {{/view.attr}}
  
          {{view.link "determined_by" route="person.view"}}
  
          {{view.attr "aggregate_measure"}}
          {{view.attr "statistical_method"}}
          {{view.attr "individual_count"}}
          {{view.attr "dispersion"}}
          {{view.attr "measurement_value_min"}}
          {{view.attr "measurement_value_max"}}
          {{view.attr "measurement_accuracy"}}
      </Layout::RegularGrid>
  </GenericViews::Record>
  
  */
  {
    "id": "FqUTcuC7",
    "block": "{\"symbols\":[\"view\",\"@measurement\"],\"statements\":[[8,\"generic-views/record\",[[24,0,\"expanded-measurement-view\"]],[[\"@record\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"layout/regular-grid\",[],[[\"@columns\"],[2]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[32,1,[\"attr\"]],\"value\"],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[32,2,[\"stringValue\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n        \"],[1,[30,[36,1],[[32,1,[\"attr\"]],\"method_desc\"],null]],[2,\"\\n        \"],[1,[30,[36,1],[[32,1,[\"attr\"]],\"remarks\"],null]],[2,\"\\n        \"],[1,[30,[36,1],[[32,1,[\"attr\"]],\"warnings\"],null]],[2,\"\\n\\n\"],[6,[37,1],[[32,1,[\"attr\"]],\"determined_at\"],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,0],[[32,2,[\"determined_at\"]]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n        \"],[1,[30,[36,1],[[32,1,[\"link\"]],\"determined_by\"],[[\"route\"],[\"person.view\"]]]],[2,\"\\n\\n        \"],[1,[30,[36,1],[[32,1,[\"attr\"]],\"aggregate_measure\"],null]],[2,\"\\n        \"],[1,[30,[36,1],[[32,1,[\"attr\"]],\"statistical_method\"],null]],[2,\"\\n        \"],[1,[30,[36,1],[[32,1,[\"attr\"]],\"individual_count\"],null]],[2,\"\\n        \"],[1,[30,[36,1],[[32,1,[\"attr\"]],\"dispersion\"],null]],[2,\"\\n        \"],[1,[30,[36,1],[[32,1,[\"attr\"]],\"measurement_value_min\"],null]],[2,\"\\n        \"],[1,[30,[36,1],[[32,1,[\"attr\"]],\"measurement_value_max\"],null]],[2,\"\\n        \"],[1,[30,[36,1],[[32,1,[\"attr\"]],\"measurement_accuracy\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"formatted-date\",\"component\"]}",
    "meta": {
      "moduleName": "plutof/components/measurements/measurement-view/expanded.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});