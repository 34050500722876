define("plutof/translations/eng/interaction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    taxon: 'Taxon',
    interaction: 'Interaction',
    taxons: 'Taxa',
    interactionObject: 'Interaction object',
    typeOfInteraction: 'Type of interaction'
  };
});