define("plutof/utils/clipboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sendToClipboard = sendToClipboard;
  function sendToClipboard(text) {
    return window.navigator.clipboard.writeText(text);
  }
});