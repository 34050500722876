define("plutof/templates/components/tables/bulk-update/measurements", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "URQTAeNC",
    "block": "{\"symbols\":[\"measurement\",\"@selection\"],\"statements\":[[6,[37,3],[[32,0,[\"load\",\"isIdle\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,0,[\"load\",\"last\",\"value\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"plutof-label\"],[12],[2,\"\\n                \"],[1,[32,1,[\"name\"]]],[2,\"\\n            \"],[13],[2,\"\\n\\n\"],[6,[37,3],[[30,[36,2],[[32,1,[\"type\"]],\"set\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"                \"],[8,\"plutof-select/object\",[],[[\"@content\",\"@oneWay\",\"@selection\",\"@selectionChanged\",\"@optionLabelPath\",\"@optionValuePath\"],[[32,1,[\"set_choices\"]],true,[30,[36,0],[[32,2],[32,1,[\"id\"]]],null],[30,[36,1],[[32,0,[\"update\"]],[32,1]],null],\"element\",\"id\"]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"                \"],[8,\"one-way-input\",[[24,0,\"form-control\"]],[[\"@value\",\"@update\"],[[30,[36,0],[[32,2],[32,1,[\"id\"]]],null],[30,[36,1],[[32,0,[\"update\"]],[32,1]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"get\",\"fn\",\"is-equal\",\"if\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/tables/bulk-update/measurements.hbs"
    }
  });
});