define("plutof/components/collection-lab/permit/items/edit", ["exports", "@glimmer/component", "plutof/utils/modules", "plutof/models/permit/item"], function (_exports, _component, _modules, _item) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createPermitItemsEditModel = createPermitItemsEditModel;
  _exports.default = void 0;
  _exports.loadPermitItemsEditModel = loadPermitItemsEditModel;
  var _dec, _dec2, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="out-of-form-field">
      <CollectionLab::Permit::Autocomplete @update={{@model.addPermit}} />
  </div>
  
  <div class="spacer-large-below"></div>
  
  <Tables::List
      @records={{@model.entries}}
      as |Field entry|
  >
      <Field @label={{backend-translations/label "permit.permit.name"}}>
          {{entry.permit.name}}
      </Field>
  
      <Field @label={{backend-translations/label "permit.permit.status"}}>
          {{entry.permit.statusDisplayName}}
      </Field>
  
      <Field @label={{backend-translations/label "permit.permit.permit_type"}}>
          {{entry.permit.permitTypeDisplayName}}
      </Field>
  
      <Field>
          <Tables::RemoveButton @clicked={{fn @model.remove entry}} />
      </Field>
  </Tables::List>
  
  */
  {
    "id": "fMISVoRp",
    "block": "{\"symbols\":[\"Field\",\"entry\",\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"out-of-form-field\"],[12],[2,\"\\n    \"],[8,\"collection-lab/permit/autocomplete\",[],[[\"@update\"],[[32,3,[\"addPermit\"]]]],null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"spacer-large-below\"],[12],[13],[2,\"\\n\\n\"],[8,\"tables/list\",[],[[\"@records\"],[[32,3,[\"entries\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1],[],[[\"@label\"],[[30,[36,0],[\"permit.permit.name\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,2,[\"permit\",\"name\"]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,1],[],[[\"@label\"],[[30,[36,0],[\"permit.permit.status\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,2,[\"permit\",\"statusDisplayName\"]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,1],[],[[\"@label\"],[[30,[36,0],[\"permit.permit.permit_type\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,2,[\"permit\",\"permitTypeDisplayName\"]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"tables/remove-button\",[],[[\"@clicked\"],[[30,[36,1],[[32,3,[\"remove\"]],[32,2]],null]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"backend-translations/label\",\"fn\"]}",
    "meta": {
      "moduleName": "plutof/components/collection-lab/permit/items/edit.hbs"
    }
  });
  let PermitItemsEditModel = (_dec = Ember._action, _dec2 = Ember._action, (_class = class PermitItemsEditModel {
    constructor({
      store
    }) {
      // :: [{ permit, items :: ctype -> id -> item? }]
      _defineProperty(this, "entries", []);
      _defineProperty(this, "toDelete", []);
      this.store = store;
    }
    async addItem(item) {
      const permit = await item.permit;
      const entry = this._getPermitEntry(permit);
      this._registerItem(entry, item);
    }
    addPermit(permit) {
      this._getPermitEntry(permit);
    }
    remove(entry) {
      for (const items of entry.items.values()) {
        for (const item of items.values()) {
          this.toDelete.push(item);
        }
      }
      this.entries.removeObject(entry);
    }
    async save(record) {
      await Ember.RSVP.all(this.toDelete.map(r => r.destroyRecord()));
      this.toDelete = [];
      await Ember.RSVP.all(this.entries.map(entry => {
        const item = this._registerRecord(entry, record);
        if (item.isNew) {
          return item.save();
        }
      }));
    }
    _getPermitEntry(permit) {
      let entry = this.entries.find(entry => entry.permit === permit);
      if (!entry) {
        entry = {
          permit,
          items: new Map()
        };
        this.entries.pushObject(entry);
      }
      return entry;
    }
    _getContentTypeItems(entry, ctype) {
      let ids = entry.items.get(ctype);
      if (!ids) {
        ids = new Map();
        entry.items.set(ctype, ids);
      }
      return ids;
    }
    _registerRecord(entry, record) {
      let ctype = (0, _modules.getRecordContentType)(record);

      // XXX: We still need "agent/collection" as a distinct type
      // from agent/organization, even thought they are the same on
      // back end. Hopefully, not for long, otherwise will need to
      // introduce the module<->ctype binding
      if (ctype === 'agent/collection') {
        ctype = 'agent/organization';
      }
      const items = this._getContentTypeItems(entry, ctype);
      if (!items.has(record.id)) {
        const item = this.store.createRecord('permit/item', {
          content_type: ctype,
          object_id: record.id,
          permit: entry.permit
        });
        items.set(record.id, item);
      }
      return items.get(record.id);
    }
    _registerItem(entry, item) {
      const items = this._getContentTypeItems(entry, item.content_type);
      if (items.has(item.object_id.toString())) {
        throw new Error('Programmer error: preexisting item is already registered');
      }
      items.set(item.object_id.toString(), item);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "addPermit", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "addPermit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "remove", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "remove"), _class.prototype)), _class));
  function createPermitItemsEditModel(store) {
    return new PermitItemsEditModel({
      store
    });
  }
  async function loadPermitItemsEditModel(store, record) {
    const model = new PermitItemsEditModel({
      store
    });
    const items = await (0, _item.loadPermitItems)(record);
    let ctype = (0, _modules.getRecordContentType)(record);
    if (ctype === 'agent/collection') {
      ctype = 'agent/organization';
    }
    const ownItems = items.filter(item => item.content_type === ctype && item.object_id.toString() === record.id);
    for (const item of ownItems) {
      await model.addItem(item);
    }
    return model;
  }
  class PermitItemsEdit extends _component.default {}
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PermitItemsEdit);
});