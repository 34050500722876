define("plutof/utils/controllers", ["exports", "plutof/mixins/add-controller", "plutof/mixins/edit-controller", "plutof/utils/notifications"], function (_exports, _addController, _editController, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.EditController = _exports.AddController = void 0;
  var _dec, _class, _dec2, _class2;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let AddController = _exports.AddController = (_dec = Ember._action, (_class = class AddController extends Ember.Controller.extend(_addController.default) {
    async save(...args) {
      await (0, _notifications.notifyProcess)(this._save(...args), this.i18n);
      this.send('goBack');
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "save", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class));
  let EditController = _exports.EditController = (_dec2 = Ember._action, (_class2 = class EditController extends Ember.Controller.extend(_editController.default) {
    async save(...args) {
      await (0, _notifications.notifyProcess)(this._save(...args), this.i18n);
      this.send('goBack');
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "save", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "save"), _class2.prototype)), _class2));
});