define("plutof/templates/datacite/alignment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "soNyBQ09",
    "block": "{\"symbols\":[\"sequence\"],\"statements\":[[10,\"div\"],[14,0,\"route-marker-datacite-alignment\"],[12],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"fluid-container\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"datacite-alignment-table\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"sequence-list-container\"],[12],[2,\"\\n            \"],[1,[30,[36,4],null,[[\"sequences\",\"openSH\"],[[35,3],[30,[36,2],[[32,0],\"openSH\"],null]]]]],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"div\"],[14,0,\"sequence-alignment-container\"],[12],[2,\"\\n            \"],[10,\"table\"],[14,0,\"plutof-table table-hover doi-sh-sequences sequence-alignment table\"],[12],[2,\"\\n                \"],[10,\"tbody\"],[12],[2,\"\\n                    \"],[1,[30,[36,6],null,[[\"colorize\",\"length\"],[[35,0],[35,5]]]]],[2,\"\\n\\n\"],[6,[37,8],[[30,[36,7],[[30,[36,7],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"                        \"],[1,[30,[36,1],null,[[\"sequence\",\"colorize\"],[[32,1,[\"its_sequence\"]],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n                    \"],[1,[30,[36,6],null,[[\"colorize\",\"length\"],[[35,0],[35,5]]]]],[2,\"\\n                \"],[13],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"colorize-checkbox\"],[12],[2,\"\\n        \"],[1,[30,[36,9],null,[[\"label\",\"checked\"],[\"UniteSH.colorSequences\",[35,0]]]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[1,[30,[36,11],null,[[\"pagination\"],[[35,10,[\"sequencePagination\"]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"colorSequences\",\"unite/its-sequence\",\"action\",\"sequences\",\"datacite/sh-doi/variable-sequence-list\",\"maxSequenceLength\",\"unite/sequence-guidelines\",\"-track-array\",\"each\",\"check-box\",\"model\",\"pagination/load-more\"]}",
    "meta": {
      "moduleName": "plutof/templates/datacite/alignment.hbs"
    }
  });
});