define("plutof/components/clipboard/-export/csv", ["exports", "@glimmer/component", "plutof/controllers/import/add", "plutof/misc/options-getter", "plutof/utils/reflection", "plutof/components/clipboard/-export/utils"], function (_exports, _component, _add, _optionsGetter, _reflection, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ClipboardCSVExportModel = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="out-of-form-field">
      {{plutof-labelc path="export.observations.csv.vernacular_name_languages" source="endpoint"}}
  
      <AutoComplete::BindMultiple @value={{@model.commonNameLanguages}} as |add|>
          <AutoComplete::Model
              @model="geography/language"
              @update={{add}}
              class="clipboard-export-vernacular_name" />
      </AutoComplete::BindMultiple>
  </div>
  
  <Layout::RegularGrid @columns={{2}} @class="spacer-above">
      <div>
          {{#if @model.tree}}
              <SubtreeSelector @tree={{@model.tree}} />
          {{/if}}
      </div>
  
      <div>
          <div class="plutof-label spacer-below">
              {{i18n-t "Import.selectedFields"}}
          </div>
  
          {{#each @model.selectedTreeLeaves as |leaf|}}
              {{leaf.title}}
              [ {{leaf.typeName}} ]
              <br />
          {{/each}}
      </div>
  </Layout::RegularGrid>
  
  */
  {
    "id": "Vd8ks2i+",
    "block": "{\"symbols\":[\"leaf\",\"add\",\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"out-of-form-field\"],[12],[2,\"\\n    \"],[1,[30,[36,0],null,[[\"path\",\"source\"],[\"export.observations.csv.vernacular_name_languages\",\"endpoint\"]]]],[2,\"\\n\\n    \"],[8,\"auto-complete/bind-multiple\",[],[[\"@value\"],[[32,3,[\"commonNameLanguages\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"auto-complete/model\",[[24,0,\"clipboard-export-vernacular_name\"]],[[\"@model\",\"@update\"],[\"geography/language\",[32,2]]],null],[2,\"\\n    \"]],\"parameters\":[2]}]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[8,\"layout/regular-grid\",[],[[\"@columns\",\"@class\"],[2,\"spacer-above\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[12],[2,\"\\n\"],[6,[37,1],[[32,3,[\"tree\"]]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"subtree-selector\",[],[[\"@tree\"],[[32,3,[\"tree\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"plutof-label spacer-below\"],[12],[2,\"\\n            \"],[1,[30,[36,2],[\"Import.selectedFields\"],null]],[2,\"\\n        \"],[13],[2,\"\\n\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,3,[\"selectedTreeLeaves\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[32,1,[\"title\"]]],[2,\"\\n            [ \"],[1,[32,1,[\"typeName\"]]],[2,\" ]\\n            \"],[10,\"br\"],[12],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"plutof-labelc\",\"if\",\"i18n-t\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "plutof/components/clipboard/-export/csv.hbs"
    }
  });
  let ClipboardCSVExportModel = _exports.ClipboardCSVExportModel = (_dec = Ember.computed('tree.leaves.@each.selected'), _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, (_class = class ClipboardCSVExportModel extends _utils.ExportFormatModel {
    constructor({
      id,
      label,
      endpoint,
      tree
    }) {
      super({
        id,
        label
      });
      _initializerDefineProperty(this, "commonNameLanguages", _descriptor, this);
      this.endpoint = endpoint;
      this.tree = tree;
    }
    static async create(store, endpoint) {
      const ajax = (0, _reflection.getService)(store, 'ajax');
      const i18n = (0, _reflection.getService)(store, 'i18n');
      const options = await (0, _optionsGetter.getPromise)(ajax, endpoint);
      const root = {
        id: 'all',
        label: i18n.translate('Clipboard.exportAllFields'),
        children: options.csv_columns,
        selected: false
      };
      const tree = optionsToTree(store, root);
      return new ClipboardCSVExportModel({
        id: 'csv',
        label: i18n.translate('Clipboard.exportFormatCsv'),
        endpoint,
        tree
      });
    }
    prepareSaveData(objectList) {
      const data = {
        fields: this.selectedTreeLeaves.mapBy('full_id'),
        object_list: objectList
      };
      const vernacularLanguages = this.commonNameLanguages.mapBy('url');
      if (vernacularLanguages.length > 0) {
        data.vernacular_name_languages = vernacularLanguages;
      }
      return data;
    }
    get selectedTreeLeaves() {
      if (Ember.isNone(this.tree)) {
        return [];
      }

      // "specimen." etc
      const prefixLength = this.tree.get('title.length') + 1;
      return this.tree.get('leaves').filterBy('selected').map(leaf => ({
        title: leaf.get('fullTitle').substr(prefixLength),
        typeName: leaf.get('typeName'),
        full_id: leaf.get('full_id')
      }));
    }
    addCommonNameLanguage(language) {
      this.commonNameLanguages.pushObject(language);
      this.updateIncludeCommonNames();
    }
    removeCommonNameLanguage(language) {
      this.commonNameLanguages.removeObject(language);
      this.updateIncludeCommonNames();
    }

    // If user selects language we update vernacular name node to be active as well.
    updateIncludeCommonNames() {
      const identification = this.tree.children.findBy('full_id', 'identification');

      // Some clipboards don't have identifications.
      if (Ember.isNone(identification)) {
        return;
      }
      const vernacular = identification.children.findBy('id', 'include_vernacular_names');
      vernacular.set('selected', this.commonNameLanguages.length > 0);
    }
    get valid() {
      return true;
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "selectedTreeLeaves", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "selectedTreeLeaves"), _class.prototype), _descriptor = _applyDecoratedDescriptor(_class.prototype, "commonNameLanguages", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "addCommonNameLanguage", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "addCommonNameLanguage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeCommonNameLanguage", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "removeCommonNameLanguage"), _class.prototype)), _class)); // TODO: Just copied these from before, but there's a lot to change
  // here - first of all, no subclassing import nodes
  function parseNodeChildren(children, parent = null) {
    return Object.keys(children).map(key => {
      const child = children[key];
      child.id = key;
      child.title = child.label;
      child.description = child.help_text;
      child.parent = parent;
      return child;
    });
  }
  function parseTreeNode(node, key, parent = null) {
    const parsedNode = {
      id: key,
      title: node.label,
      description: node.help_text,
      parent: parent,
      selected: false,
      type: node.type,
      // Not used by clipboard but required by the tree component
      semi_required_attributes: []
    };
    if (!Ember.isNone(parent)) {
      if (Ember.isNone(parent.full_id)) {
        parsedNode.full_id = `${parsedNode.id}`;
      } else {
        parsedNode.full_id = `${parent.full_id}.${parsedNode.id}`;
      }
    }
    if (!Ember.isEmpty(node.children)) {
      parsedNode.children = parseNodeChildren(node.children, parent);
    }
    return parsedNode;
  }

  // Similar to import's specToTree()
  function optionsToTree(store, node, parent = null) {
    const parsedNode = parseTreeNode(node, node.id, parent);
    if (Ember.isNone(node.children)) {
      return _add.ImportTreeLeaf.create(parsedNode);
    } else {
      const tree = _add.ImportTreeInnerNode.create(parsedNode);
      tree.set('children', parsedNode.children.map(child => optionsToTree(store, child, tree)));
      return tree;
    }
  }
  class ClipboardCSVExport extends _component.default {}
  _exports.default = ClipboardCSVExport;
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ClipboardCSVExport);
});