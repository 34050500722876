define("plutof/translations/eng/collection", ["exports", "plutof/translations/helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    collection: 'Collection',
    list: {
      title: 'Collections',
      description: 'In this section you can add and edit collections.'
    },
    view: {
      title: (0, _helpers.alias)('collection.collection')
    },
    edit: {
      title: 'Edit Collection'
    },
    add: {
      title: 'New Collection',
      groupCreationDialog: {
        title: 'Do you want to manage this collection?',
        content: 'If so, management workgroup will be created with you a collection manager. Otherwise, collection will be unmanaged. After the collection is created, this choice can be changed only by contacting the support'
      }
    },
    transactionStats: {
      title: 'Statistics',
      allTransactions: 'Total transactions',
      activeTransactions: 'Active transactions',
      recordsWithImages: 'Records with images'
    },
    repository: {
      name: 'Name',
      repositories: 'Subrepositories'
    }
  };
});