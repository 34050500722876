define("plutof/components/navbar/-navbar-base/delete-record", ["exports", "@ember-decorators/component", "plutof/utils/model", "plutof/utils/notifications", "plutof/utils/promises", "plutof/utils/store", "plutof/utils/i18n"], function (_exports, _component, _model, _notifications, _promises, _store, _i18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.deleteRelatedFiles = deleteRelatedFiles;
  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <@navbar.button
      @clicked={{this.deleteRecord}}
      @icon={{icon "delete"}}
      class="btn-delete"
      data-test="Navbar::NavbarBase::DeleteRecord"
  >
      {{i18n-t "General.Delete"}}
  </@navbar.button>
  
  */
  {
    "id": "/zzZOdEl",
    "block": "{\"symbols\":[\"@navbar\"],\"statements\":[[8,[32,1,[\"button\"]],[[24,0,\"btn-delete\"],[24,\"data-test\",\"Navbar::NavbarBase::DeleteRecord\"]],[[\"@clicked\",\"@icon\"],[[32,0,[\"deleteRecord\"]],[30,[36,0],[\"delete\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,1],[\"General.Delete\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\"]}",
    "meta": {
      "moduleName": "plutof/components/navbar/-navbar-base/delete-record.hbs"
    }
  });
  const i18n = (0, _i18n.getI18n)();
  async function getDeletableFileItems(store, record) {
    const itemModel = 'filerepository/item';
    const items = await (0, _model.getLinkedObjects)(store, await record, itemModel);
    return _promises.default.filter(items, async item => {
      const file = await item.get('file');
      const allFileItems = await (0, _store.query)(store, itemModel, {
        file: file.id
      });
      return allFileItems.length === 1;
    });
  }
  async function deleteRelatedFiles(store, record, neverDeleteRelatedFiles, confirmationDialog, translate) {
    if (neverDeleteRelatedFiles) {
      return;
    }
    const items = await getDeletableFileItems(store, record);
    if (items.length === 0) {
      return;
    }
    const files = await Ember.RSVP.all(items.mapBy('file'));
    const dialog = Object.assign({}, confirmationDialog.DELETE_CONFIRMATION, {
      title: translate.t('General.deleteFilesDlgTitle'),
      content: files.mapBy('representation').join(', ')
    });
    await confirmationDialog.confirm(dialog).then(confirmed => {
      if (!confirmed) {
        return;
      }

      // Errors are ignored because getDeletableFileItems can mistakenly mark file as
      // deletable if the other link is privately owned by another user
      return Ember.RSVP.all(items.map(async item => {
        await item.destroyRecord();
        const file = await item.get('file');
        await file.destroyRecord();
      })).catch(() => null);
    });
  }
  let NavbarDeleteRecordButton = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, _dec(_class = (_class2 = class NavbarDeleteRecordButton extends Ember.Component {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "confirmationDialog", _descriptor, this);
      _initializerDefineProperty(this, "listRoutes", _descriptor2, this);
    }
    deleteRecord() {
      return this.confirmationDialog.confirm(this.confirmationDialog.DELETE_CONFIRMATION).then(confirmed => {
        if (!confirmed) {
          return;
        }
        return deleteRelatedFiles(this.store, this.record, this.neverDeleteRelatedFiles, this.confirmationDialog, i18n).then(() => {
          return (0, _model.destroyCompletely)(this.record).then(() => {
            // Usually not needed, but some models (taxon node) are not actually deleted,
            // but marked as such. This means that after deleting their ember-data state
            // is not consistent with the actual one, so we unload to refetch if needed.
            this.store.unloadRecord(this.record);
            if (this.module) {
              this.listRoutes.reset(this.module);
            }
            this.removed();
          });
        }).catch(err => {
          (0, _notifications.reportError)(err);
        });
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "confirmationDialog", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "listRoutes", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "deleteRecord", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "deleteRecord"), _class2.prototype)), _class2)) || _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, NavbarDeleteRecordButton);
});