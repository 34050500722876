define("plutof/translations/est/access", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    public: 'Avalik',
    private: 'Privaatne',
    userView: 'Kasutajad',
    userEdit: 'Kasutajad',
    workgroupView: 'Töörühmad',
    workgroupEdit: 'Töörühmad',
    canView: 'Nähtav',
    canEdit: 'Muudetav',
    selectedWGandUsers: 'Töörühmad ja kasutajad',
    forbidden: 'Puuduvad vaatamisõigused',
    editRightsCheckFailed: 'Enne “vaikimisi õiguste hoidja” muutmist veenduge, et olete vaikimisi õigused seadistanud viisil, mis teile sisestatud kirjete muutmisõigused tagab (nt töörühma kuulumise kaudu)',
    publicLinkingLabel: 'Sidumine',
    publicLinkingHint: `Avalik - kirjeid saavad siduda kõik kasutajad;<br />
    privaatne - kirjeid saavad siduda vaid muutmisõigusega kasutajad ja töörühmad.`,
    created: 'Sisestatud',
    modified: 'Muudetud',
    derivedFrom: 'Ülemobjekt',
    changeRelatedFiles: 'Muuda seotud failide õiguseid',
    ownerChange: {
      fileDialog: {
        title: 'Seotud failid',
        content: 'Muuta lisaks ka seotud failide õiguste hoidjat?'
      }
    },
    derivedVisibilityChange: {
      toPublic: 'See muudatus teeb praegu privaatsed kirjed avalikuks',
      toPrivate: 'See muudatus teeb praegu avalikud kirjed privaatseks'
    }
  };
});