define("plutof/translations/eng/access", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    public: 'Public',
    private: 'Private',
    userView: 'Users',
    userEdit: 'Users',
    workgroupView: 'Workgroups',
    workgroupEdit: 'Workgroups',
    canView: 'Visibility',
    canEdit: 'Edit',
    selectedWGandUsers: 'Workgroups and Users',
    forbidden: 'Private',
    editRightsCheckFailed: 'You have to give edit rights to a group you belong to in order to insert data for other users',
    publicLinkingLabel: 'Linking',
    publicLinkingHint: 'Public - everyone can link records;<br />private - only users and workgroups with edit rights can link records.',
    created: 'Created',
    modified: 'Modified',
    derivedFrom: 'Parent object',
    changeRelatedFiles: 'Change related files\' access',
    ownerChange: {
      fileDialog: {
        title: 'Linked files',
        content: 'Apply owner change to linked files as well?'
      }
    },
    derivedVisibilityChange: {
      toPublic: 'This will cause currently private record to become public',
      toPrivate: 'This will cause currently public record to become private'
    }
  };
});