define("plutof/routes/conservation-lab/red-list-assessment/edit", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "plutof/components/conservation/red-list-assessment/edit", "plutof/mixins/route", "plutof/utils/validations"], function (_exports, _authenticatedRouteMixin, _edit, _route, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class RedListAssessmentEditRoute extends Ember.Route.extend(_authenticatedRouteMixin.default, _route.default, _validations.ValidatedRoute) {
    async model({
      id
    }) {
      const record = await this.store.findRecord('redbook/red-list-assessment', id);
      const assessment = await (0, _edit.loadRedListAssessmentModel)(this.store, record);
      return {
        assessment
      };
    }
    getTitle(model) {
      return model.assessment.assessment.title;
    }
    transitionToDefaultRoute() {
      return this.transitionTo('conservation-lab.red-list-assessment.view', this.currentModel.assessment.assessment.id);
    }
  }
  var _default = _exports.default = RedListAssessmentEditRoute;
});