define("plutof/translations/est/photobank", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    colID: 'Kood',
    colTitle: 'Nimi',
    colCategory: 'Kategooria',
    colModified: 'Viimati muudetud',
    listTitle: 'Fotopank',
    objectSearchHint: 'Otsi objekti koodi järgi',
    infoTitle: 'Fotopank',
    newObject: 'Uus fotopanga objekt',
    editObject: 'Muuda fotopanga objekti',
    areaEventTitle: 'Asukoha ja sündmuse andmed',
    viewObject: 'Fotopanga objekt',
    additionalIDs: 'Teisesed koodid',
    classifications: 'Klassifikatsioonid',
    agents: 'Isikud ja asutused',
    objectID: 'Objekti ID',
    alreadyExists: 'on juba lisatud andmebaasi',
    gatheringAgents: 'Fotograaf',
    taxa: 'Taksonid'
  };
});