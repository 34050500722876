define("plutof/components/observation/-list-route-link", ["exports", "@glimmer/component", "plutof/models/taxonoccurrence/observation/observation"], function (_exports, _component, _observation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class={{if (not this.isVerified) "isNotVerified"}}>
      {{#link-to "observation.view" @observation.id}}
          {{this.prefix}}
          {{@observation.taxon_name}}
      {{/link-to}}
  </div>
  
  */
  {
    "id": "yZvQKuOv",
    "block": "{\"symbols\":[\"@observation\"],\"statements\":[[10,\"div\"],[15,0,[30,[36,1],[[30,[36,0],[[32,0,[\"isVerified\"]]],null],\"isNotVerified\"],null]],[12],[2,\"\\n\"],[6,[37,2],null,[[\"route\",\"model\"],[\"observation.view\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[32,0,[\"prefix\"]]],[2,\"\\n        \"],[1,[32,1,[\"taxon_name\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"not\",\"if\",\"link-to\"]}",
    "meta": {
      "moduleName": "plutof/components/observation/-list-route-link.hbs"
    }
  });
  const STATUS_TRANSLATION_KEYS = {
    [_observation.MODERATION_STATUSES.pending]: 'pending',
    [_observation.MODERATION_STATUSES.rejected]: 'rejected',
    [_observation.MODERATION_STATUSES.problematic]: 'error'
  };
  let ObservationListRouteLink = (_dec = Ember.inject.service, (_class = class ObservationListRouteLink extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "i18n", _descriptor, this);
    }
    get isVerified() {
      return !this.args.observation.is_public || this.args.observation.moderation_status === _observation.MODERATION_STATUSES.verified;
    }
    get prefix() {
      if (this.isVerified) {
        return '';
      }
      return this.i18n.translate(`PlutofListViewColumn.${STATUS_TRANSLATION_KEYS[this.args.observation.moderation_status]}`);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "i18n", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ObservationListRouteLink);
});