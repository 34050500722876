define("plutof/templates/series/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "NbfvwPvm",
    "block": "{\"symbols\":[\"view\"],\"statements\":[[8,\"test/route-marker\",[],[[\"@route\"],[\"series.view\"]],null],[2,\"\\n\\n\"],[8,\"navbar/view\",[],[[\"@icon\",\"@title\",\"@record\",\"@module\",\"@editRoute\",\"@deletable\",\"@accessRights\"],[[30,[36,0],[\"reference\"],null],[30,[36,1],[\"References.referenceSeries\"],null],[32,0,[\"model\",\"series\"]],\"reference/series\",\"series.edit\",true,[32,0,[\"model\",\"accessRights\"]]]],null],[2,\"\\n\\n\"],[8,\"plutof-panel/simple\",[[24,\"data-test\",\"Series::View::GeneralData\"]],[[\"@title\"],[[32,0,[\"model\",\"series\",\"title\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"generic-views/record\",[],[[\"@record\"],[[32,0,[\"model\",\"series\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"layout/regular-grid\",[],[[\"@columns\"],[2]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,2],[[32,1,[\"attr\"]],\"title\"],null]],[2,\"\\n            \"],[1,[30,[36,2],[[32,1,[\"attr\"]],\"abbreviation\"],null]],[2,\"\\n            \"],[1,[30,[36,2],[[32,1,[\"attr\"]],\"acronym\"],null]],[2,\"\\n            \"],[1,[30,[36,2],[[32,1,[\"attr\"]],\"series\"],null]],[2,\"\\n            \"],[1,[30,[36,2],[[32,1,[\"attr\"]],\"editor\"],null]],[2,\"\\n            \"],[1,[30,[36,2],[[32,1,[\"attr\"]],\"dates\"],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,\"access/view-rights\",[],[[\"@object\",\"@rights\"],[[32,0,[\"model\",\"series\"]],[32,0,[\"model\",\"accessRights\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\",\"component\"]}",
    "meta": {
      "moduleName": "plutof/templates/series/view.hbs"
    }
  });
});