define("plutof/translations/est/models", ["exports", "plutof/translations/helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    'newSamplingarea': 'Uus prooviala',
    // Non-model option .
    'noSource': 'Allikata',
    // Non-model option.
    'study': 'Projekt',
    'sample': 'Proov',
    'specimen': 'Eksemplar',
    'sequence': 'Sekvents',
    'observation': 'Vaatlus',
    'file': 'Fail',
    'strain': 'Tüvi',
    'samplingevent': 'Proovivõtt',
    'samplingarea': 'Prooviala',
    'materialsample': 'Proov',
    'organization': 'Asutus',
    'person': 'Isik',
    'transaction': 'Transaktsioon',
    'conditionassessment': (0, _helpers.alias)('CollectionManagement.conditionAssessmentsLabel'),
    'treatment': (0, _helpers.alias)('General.treatments'),
    'occurrence': (0, _helpers.alias)('General.RefBased'),
    'collectionobject': (0, _helpers.alias)('Photobank.viewObject'),
    'taxondescription': (0, _helpers.alias)('Taxonomy.descriptionsLabel'),
    'reference': (0, _helpers.alias)('References.reference'),
    'livingculture': 'Eluseksemplar',
    'livingspecimen': 'Eluseksemplar'
  };
});