define("plutof/templates/components/common/-radio-group-choice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "C5f7QMWv",
    "block": "{\"symbols\":[\"@active\",\"@name\",\"@disable\",\"&default\"],\"statements\":[[8,\"radio-button\",[[16,\"disabled\",[32,3]]],[[\"@oneWay\",\"@selection\",\"@changed\",\"@option\"],[true,[32,1],[30,[36,0],[[32,0],[32,0,[\"selected\"]]],null],[32,2]]],null],[2,\"\\n\\n\"],[18,4,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/common/-radio-group-choice.hbs"
    }
  });
});