define("plutof/templates/sequence/annotation/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "FHPD34j3",
    "block": "{\"symbols\":[],\"statements\":[[8,\"annotation/navbar/view\",[],[[\"@icon\",\"@title\",\"@annotation\",\"@permissions\",\"@parentRoute\"],[[30,[36,0],[\"sequence\"],null],[30,[36,1],[\"Sequences.annotation.view\"],null],[32,0,[\"model\",\"annotation\"]],[32,0,[\"model\",\"permissions\"]],\"sequence\"]],null],[2,\"\\n\\n\"],[8,\"annotation/summary\",[],[[\"@annotation\",\"@permissions\"],[[32,0,[\"model\",\"annotation\"]],[32,0,[\"model\",\"permissions\"]]]],null],[2,\"\\n\\n\"],[8,\"sequence/view\",[],[[\"@sequence\",\"@parent\",\"@chimericScore\",\"@canModifySample\",\"@canModifyDeterminations\"],[[32,0,[\"model\",\"sequence\"]],[32,0,[\"model\",\"parent\"]],[32,0,[\"model\",\"chimericScore\"]],false,false]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\"]}",
    "meta": {
      "moduleName": "plutof/templates/sequence/annotation/view.hbs"
    }
  });
});