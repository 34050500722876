define("plutof/templates/components/unite/centroid-sequence", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "JyDdmS5y",
    "block": "{\"symbols\":[\"@sequence\"],\"statements\":[[8,\"plutof-panel/simple\",[],[[\"@title\"],[[30,[36,0],[\"UniteSH.centroidSequence\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"tr\"],[12],[2,\"\\n        \"],[10,\"td\"],[14,0,\"field-name\"],[12],[2,\"\\n            \"],[1,[30,[36,0],[\"UniteSH.accessionNumbers\"],null]],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"td\"],[14,0,\"field-value\"],[12],[2,\"\\n\"],[6,[37,1],null,[[\"route\",\"model\"],[\"sequence.view\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"                \"],[1,[32,1,[\"representation\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"link-to\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/unite/centroid-sequence.hbs"
    }
  });
});