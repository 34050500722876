define("plutof/translations/est/annotation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    annotate: 'Annoteeri',
    noChangesMade: 'Muudatusi pole tehtud',
    unreviewedAnnotations: 'Ülevaatamist ootav annotatsioon',
    annotationSent: 'Annotatsioon on esitatud kirje õiguste hoidjale. Kui esitatud annotatsioon on üle vaadataud, saadetakse teile selle kohta teade.',
    selfAnnotationCreated: 'Kirje on annoteeritud ja muudatused on rakendatud',
    annotation: 'Annoteering',
    annotations: 'Annoteeringud',
    routes: {
      created: {
        title: 'Minu annoteeringud',
        description: 'Teie poolt loodud annoteeringud',
        buttonTitle: 'Minu annoteeringud ({{rejected}} tagasi lükatud)'
      },
      moderated: {
        title: 'Ootel annoteeringud',
        description: 'Teie ülevaadet ootavad teiste kasutajate annoteeringud',
        buttonTitle: 'Ootel annoteeringud ({{count}})'
      },
      bulk: {
        index: 'Mitme kirjete annoteeringud',
        add: {
          title: 'Uus mitme kirjete annoteering',
          records: 'Annoteeritavad kirjed'
        },
        edit: {
          title: 'Muuda mitme kirjete annoteeringut'
        },
        view: {
          title: 'Mitme kirjete annoteering',
          summary: {
            model: 'Mudel',
            attribute: 'Väli',
            change: 'Muutus'
          }
        },
        errors: {
          recordLimit: 'Hetkel saab korraga annoteerida ülimalt {{limit}} kirjet',
          noRecords: 'Ei saa annoteerida null kirjet'
        }
      },
      search: {
        includeTaxonSubtree: 'Alamtaksonid ja sünonüümid',
        contentType: 'Kirje tüüp',
        changedFields: 'Annoteeritud väljad',
        recordProperties: 'Kirje',
        topResults: {
          author: 'Top autorid',
          changedField: 'Top annoteeritud väljad'
        }
      }
    },
    commentDialog: {
      title: 'Lisa märkus',
      placeholder: 'Palun lisage annotatsioonile lühike märkus: see aitab annotatsioone üksteisest eristada ning annab ülevaate kirje ajaloost.'
    },
    moderation: {
      dialog: {
        title: 'Lisa märkus',
        placeholder: 'Palun lisage esitatud annotatsioonile tagasisideks lühike tänuavaldus või märkus.'
      }
    },
    table: {
      title: 'Esitatud annotatsioonid'
    },
    summary: {
      title: 'Annotatsioonide kokkuvõte',
      annotatorStatus: '<b>Annoteerinud</b> {{annotator}} ({{date}}) märkusega:',
      status: {
        accepted: '<b>Kinnitanud</b> {{moderator}} ({{date}}) märkusega:',
        rejected: '<b>Tagasi lükanud</b> {{moderator}} ({{date}}) märkusega:',
        moderated: '<b>Varem tagasi lükanud</b> {{moderator}} ({{date}}) märkusega:'
      }
    },
    log: {
      title: 'Annotatsiooni ajalugu',
      addComment: 'Lisa märkus',
      fields: {
        kind: 'Sündmus',
        author: 'Autor',
        comment: 'Märkus',
        timestamp: 'Kuupäev'
      }
    },
    fields: {
      status: 'Staatus',
      author: 'Autor',
      moderator: 'Moderaator',
      comment: 'Märkus',
      annotated_at: 'Kuupäev'
    },
    errors: {
      missingRelationPermission: 'Annotation links to a record {{record}} you have no permission for'
    },
    elixir: {
      annotate: 'Saada annotatsioonid ENAsse',
      previewTitle: '{{record}} annotatsiooni kokkuvõte',
      changes: {
        all: 'Kõik annoteeringut',
        elixir: 'ENA-sse saadetud annoteeringud',
        none: 'ENA-s toetatud annoteeringud puuduvad'
      },
      fields: {
        field: 'Väli',
        value: 'Väärtus',
        assertion: 'Tõendamine',
        assertionSource: 'Tõendamise allikas',
        comment: 'Märkus'
      },
      preview: {
        useReferenceAsAssertionSource: 'Kasuta PlutoF kirjandusviidet'
      },
      errors: {
        taxonResolutionFailed: 'Ei suutnud taksonit leida ENA taksonoomias'
      }
    }
  };
});