define("plutof/translations/eng/layer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    layer: 'Layer',
    layers: 'Layers',
    newLayer: 'New Layer',
    editLayer: 'Edit Layer',
    layerArea: 'Area',
    tabs: {
      areas: 'Areas',
      map: 'Map'
    },
    area: {
      name: 'Name',
      geom: 'Geometry'
    },
    actions: {
      filterAreas: 'Filter by name',
      deleteArea: 'Delete area',
      findInList: 'Find area in list'
    },
    popup: {
      layer: 'Layer',
      area: 'Area'
    },
    info: {
      geomError: {
        label: 'No geometry',
        tooltip: 'Areas without geometry can\'t be saved to a layer.'
      },
      oneAreaPerLayerError: 'Can\'t link more than one area per layer. Record is already linked to "{{area}}" in layer "{{layer}}".',
      areaOutsideLayerError: 'Layer area is outside of the sampling area'
    }
  };
});