define("plutof/controllers/datacite/edit", ["exports", "plutof/utils/controllers"], function (_exports, _controllers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DataciteEditController extends _controllers.EditController {
    _save() {
      return this.model.doi.save();
    }
  }
  var _default = _exports.default = DataciteEditController;
});