define("plutof/components/collection/treatment-items", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Common::RecordListView @records={{@items}} as |item|>
      {{#let (if
          (is-equal item.content_object.app_label 'livingculture')
          'livingspecimen.view'
          'specimen.view'
      ) as |route|}}
          {{#link-to route item.content_object.id}}
              {{item.content_object.name}}
          {{/link-to}}
      {{/let}}
  </Common::RecordListView>
  
  */
  {
    "id": "qW7HOnlz",
    "block": "{\"symbols\":[\"item\",\"route\",\"@items\"],\"statements\":[[8,\"common/record-list-view\",[],[[\"@records\"],[[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,1],[[32,1,[\"content_object\",\"app_label\"]],\"livingculture\"],null],\"livingspecimen.view\",\"specimen.view\"],null]],null,[[\"default\"],[{\"statements\":[[6,[37,0],null,[[\"route\",\"model\"],[[32,2],[32,1,[\"content_object\",\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[32,1,[\"content_object\",\"name\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"link-to\",\"is-equal\",\"if\",\"let\"]}",
    "meta": {
      "moduleName": "plutof/components/collection/treatment-items.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});