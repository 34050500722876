define("plutof/templates/components/chart/pie-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "XcHuRPYX",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\"],[6,[37,2],[[35,0,[\"type\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],null,[[\"type\",\"options\",\"data\"],[[35,0,[\"type\"]],[35,0,[\"options\"]],[35,0,[\"data\"]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"chartConfig\",\"chart/base-chart\",\"if\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/chart/pie-chart.hbs"
    }
  });
});