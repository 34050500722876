define("plutof/templates/components/unite/map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "MpLmccPI",
    "block": "{\"symbols\":[],\"statements\":[[8,\"map/cluster-map\",[],[[\"@geodata\",\"@disableResizeListener\"],[[32,0,[\"mapData\"]],false]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "plutof/templates/components/unite/map.hbs"
    }
  });
});