define("plutof/components/trait/list/view", ["exports", "@glimmer/component", "plutof/misc/measurements"], function (_exports, _component, _measurements) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#resolve-promise this.traits as |traits|}}
      <PlutofPanel::ItemList ...attributes as |panel|>
          <panel.header
              @title={{i18n-t "traits.traits"}}
              @count={{traits.length}} />
  
          <panel.content>
              <Tables::List @records={{traits}} as |Field trait|>
                  <Trait::List::-Fields
                      @field={{Field}}
                      @trait={{trait}} />
              </Tables::List>
          </panel.content>
      </PlutofPanel::ItemList>
  {{/resolve-promise}}
  
  */
  {
    "id": "PeySbc1A",
    "block": "{\"symbols\":[\"traits\",\"panel\",\"Field\",\"trait\",\"&attrs\"],\"statements\":[[6,[37,1],[[32,0,[\"traits\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"plutof-panel/item-list\",[[17,5]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,2,[\"header\"]],[],[[\"@title\",\"@count\"],[[30,[36,0],[\"traits.traits\"],null],[32,1,[\"length\"]]]],null],[2,\"\\n\\n        \"],[8,[32,2,[\"content\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,\"tables/list\",[],[[\"@records\"],[[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[8,\"trait/list/-fields\",[],[[\"@field\",\"@trait\"],[[32,3],[32,4]]],null],[2,\"\\n            \"]],\"parameters\":[3,4]}]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"resolve-promise\"]}",
    "meta": {
      "moduleName": "plutof/components/trait/list/view.hbs"
    }
  });
  let TraitListView = (_dec = Ember.inject.service, _dec2 = Ember.computed('args.form', 'args.type'), (_class = class TraitListView extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
    }
    async _resolve() {
      try {
        const form = await this.args.form;
        const formData = await (0, _measurements.getMainformData)(this.ajax, form.id, {
          forceReload: true
        });
        const traits = formData[`${form.type}_form`] || [];
        traits.sort((x, y) => x.id - y.id);
        return traits;
      } catch (err) {
        return [];
      }
    }

    // @computed just to be cached
    get traits() {
      return this._resolve();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "traits", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "traits"), _class.prototype)), _class));
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TraitListView);
});