define("plutof/components/information/content/ref-sequences/general", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Information::MarkdownReader @path="reference-sequences" />
  
  */
  {
    "id": "iso18Dfo",
    "block": "{\"symbols\":[],\"statements\":[[8,\"information/markdown-reader\",[],[[\"@path\"],[\"reference-sequences\"]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "plutof/components/information/content/ref-sequences/general.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});