define("plutof/translations/eng/sample", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    title: 'Sample',
    samples: 'Samples',
    newSample: 'New Sample',
    editSample: 'Edit sample',
    sampleLocality: 'Sample Locality',
    sampleHabitat: 'Sample Habitat',
    sampleSoil: 'Sample Soil',
    sampleData: 'Sample Data',
    locality: 'Locality',
    data: 'Data',
    results: 'Results',
    locationInfoButton: 'Get location info',
    goToButton: 'Show on map',
    originalINSD: 'Original INSD data',
    samplingArea: 'Sampling Area',
    samplingAreas: 'Sampling Areas',
    existingSamplingArea: 'Existing area',
    existingSamplingAreaTooltip: 'Select if you wish to use an already created area. No new area is created.',
    parentSamplingArea: 'Parent sampling area',
    parentSamplingAreaTooltip: 'Select if you wish to specify a detailed location within an existing area.',
    existingEvent: 'Existing event',
    subSamplingArea: 'Sub-Sampling Areas',
    newSArea: 'New Sampling Area',
    // XXX RENAME
    editSArea: 'Edit Sampling Area',
    // XXX RENAME
    name: 'Name',
    country: 'Country',
    associatedOccurrences: 'Related Records',
    areaMeasurements: 'Area measurements',
    eventMeasurements: 'Event measurements',
    geometryOutOfBounds: 'Note: location is out of bounds of parent area or layers',
    commune: 'Commune',
    mapTitle: 'Location tool',
    mapTitleTooltip: 'Selecting Area on the map using “Point/Polygon/Box" will automatically fill geographical fields - If info available.',
    countrySearchPlaceholder: 'Locate country…',
    countrySearchTooltip: 'Zoom to a country. Useful for locating small countries.',
    pointSearchPlaceholder: 'Location or coordinates',
    pointSearchTooltip: `Location name, address, or coordinates as "latitude longitude".
        Coordinates are separated by comma or space; decimal separator is decimal period.<br/>
        <br/>
        Incorrect: 58,4255, 26,3020<br/>
        Correct: 58.4255 26.3020`,
    areaSelectPlaceholder: 'Select project area',
    areaSelectTooltip: 'Areas are available only when Project is selected.',
    habitatImage: 'Mark as habitat image',
    detach: {
      label: 'Detach',
      tooltip: 'Detach from sampling event'
    },
    formSelector: {
      placeholder: 'Form name...'
    },
    event: {
      add: 'Add Sampling Event',
      edit: 'Edit Sampling Event',
      view: 'Sampling Event',
      attributes: 'Attributes',
      habitat: 'Habitat',
      gatheringAgents: 'Collected by',
      gatheringAgentsTooltip: 'If no value is entered then collector is treated as \'none/missing\'.',
      timespanBegin: 'Timespan begin',
      timespanEnd: 'Timespan end',
      beginDate: 'Begin date',
      beginTime: 'Begin time',
      endDate: 'End date',
      endTime: 'End time',
      community: {
        paal: 'Paal (local to Estonia)',
        waterbody: 'Waterbodies'
      }
    },
    area: {
      accuracy: 'Accuracy (m)',
      nameTooltip: 'Short descriptive name for sampling area, for example: Korup site 1',
      restricted: 'Restricted',
      areasTitle: 'Areas',
      areasSubtitle: 'Sampling Areas and Predefined Areas',
      areasInfo: 'Create and edit areas.',
      areasButton: 'Areas',
      uploadPolygon: 'Upload GeoJSON',
      createFromGeoJSON: 'Create area',
      geometry: 'Geometry',
      areaSelectionHelper: 'Area Selection',
      map: {
        searchFailed: 'Not found: {{query}}'
      }
    },
    habitat: {
      habitat: 'Habitat',
      envo: {
        short: {
          envo_biome: 'Broad',
          envo_local_environmental_context: 'Local',
          envo_environmental_medium: 'Medium'
        }
      }
    }
  };
});