define("plutof/components/plutof-labelc", ["exports", "ember-data", "@ember-decorators/component", "plutof/misc/abstract", "plutof/misc/options-getter"], function (_exports, _emberData, _component, _abstract, _optionsGetter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _PlutoFLabel;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let PlutoFLabel = (_dec = (0, _component.classNameBindings)('textMuted', 'hideTooltip::plutof-tooltip-hover'), _dec2 = Ember.inject.service, _dec3 = Ember.computed('path', 'source'), _dec4 = Ember.computed('hideTooltip', 'data.tooltipText'), _dec(_class = (_class2 = (_PlutoFLabel = class PlutoFLabel extends Ember.Component {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _defineProperty(this, "labelClasses", 'plutof-label');
      _defineProperty(this, "path", null);
      _defineProperty(this, "errors", null);
      _defineProperty(this, "tooltipText", null);
      _defineProperty(this, "source", 'model');
      _defineProperty(this, "hideTooltip", false);
      _defineProperty(this, "infoLink", false);
    }
    get data() {
      const path = this.path;
      const source = this.source;
      const tooltipText = this.tooltipText;
      let promise;
      if (Ember.isEmpty(path)) {
        promise = (0, _abstract.wrap_as_promise)({
          labelText: '',
          tooltipText: ''
        });
      } else {
        promise = (0, _optionsGetter.getTexts)(this.ajax, path, source).then(option => {
          return {
            'infoLinks': option.info_links || [],
            'labelText': option.label || path,
            'tooltipText': tooltipText ? tooltipText.toString() : option.help_text
          };
        });
      }
      return _emberData.default.PromiseObject.create({
        promise: promise
      });
    }
    get enableTooltip() {
      const hasText = !Ember.isEmpty(this.get('data.tooltipText'));
      const show = !this.hideTooltip;
      return hasText && show;
    }
  }, _defineProperty(_PlutoFLabel, "positionalParams", ['path']), _PlutoFLabel), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "data", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "data"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "enableTooltip", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "enableTooltip"), _class2.prototype)), _class2)) || _class);
  var _default = _exports.default = PlutoFLabel;
});