define("plutof/templates/components/map/tool-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "0Bkjd3A9",
    "block": "{\"symbols\":[\"control\"],\"statements\":[[10,\"div\"],[14,0,\"latlon-container\"],[12],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"tool-container ol-control\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"button-container\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[11,\"button\"],[16,0,[31,[\"tool-button ol-control \",[30,[36,1],[[35,0],\"\",\"hidden\"],null]]]],[16,\"title\",[32,1,[\"name\"]]],[4,[38,2],[[32,0],\"toggleControl\",[32,1]],null],[12],[2,\"\\n                \"],[10,\"span\"],[15,0,[31,[[32,1,[\"icon\"]],\" \",[30,[36,1],[[32,1,[\"isToggled\"]],\"icon-selected\"],null]]]],[12],[13],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n        \"],[11,\"button\"],[24,0,\"tool-button ol-control\"],[16,\"title\",[30,[36,6],[\"Map.controls.controls\"],null]],[4,[38,2],[[32,0],\"toggleOpen\"],null],[12],[2,\"\\n            \"],[10,\"span\"],[15,0,[31,[\"fas \",[30,[36,1],[[35,0],\"fa-chevron-right\",\"fa-chevron-left\"],null]]]],[12],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"open\",\"if\",\"action\",\"controls\",\"-track-array\",\"each\",\"i18n-t\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/map/tool-panel.hbs"
    }
  });
});