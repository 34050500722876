define("plutof/templates/study/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "wH1t7wBr",
    "block": "{\"symbols\":[],\"statements\":[[8,\"test/route-marker\",[],[[\"@route\"],[\"study.edit\"]],null],[2,\"\\n\\n\"],[8,\"navbar/edit\",[],[[\"@icon\",\"@title\",\"@record\",\"@infoContent\"],[[30,[36,0],[\"project\"],null],[30,[36,1],[\"Projects.editP\"],null],[32,0,[\"model\",\"study\"]],\"information/content/project/general\"]],null],[2,\"\\n\\n\"],[8,\"study/edit\",[],[[\"@project\",\"@projectPersons\",\"@addPerson\",\"@removePerson\",\"@projectAreas\",\"@addProjectArea\",\"@removeProjectArea\",\"@useNewLogo\",\"@identifiers\",\"@associatedData\",\"@workgroupData\",\"@accessRights\",\"@validationContext\",\"@warnAboutPermissionChanges\",\"@save\"],[[32,0,[\"model\",\"study\"]],[32,0,[\"model\",\"projectPersons\"]],[32,0,[\"addPerson\"]],[32,0,[\"removePerson\"]],[32,0,[\"model\",\"projectAreas\"]],[32,0,[\"addProjectArea\"]],[32,0,[\"removeProjectArea\"]],[32,0,[\"useNewLogo\"]],[32,0,[\"model\",\"identifiers\"]],[32,0,[\"model\",\"itemData\"]],[32,0,[\"model\",\"workgroupData\"]],[32,0,[\"model\",\"accessRights\"]],[32,0,[\"validationContext\"]],true,[32,0,[\"save\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\"]}",
    "meta": {
      "moduleName": "plutof/templates/study/edit.hbs"
    }
  });
});