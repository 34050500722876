define("plutof/translations/est/determination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    current: {
      label: 'Viimane',
      tooltip: 'Viimane määrang'
    },
    parent: {
      label: 'Seotud',
      tooltip: 'Seotud taksoni esinemise määrang (viimane)'
    },
    synonym: {
      warning: {
        edit: '"{{synonym}}" on taksoni "{{validTaxon}}" sünonüüm. Soovitatav on kasutada kehtivat taksoni nime. Sünonüümi kehtiva taksoni nimega asendamiseks vajuta "Asenda".',
        view: '"{{synonym}}" on taksoni "{{validTaxon}}" sünonüüm. Soovitatav on kasutada kehtivat taksoni nime.'
      },
      replace: 'Asenda'
    }
  };
});