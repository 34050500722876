define("plutof/translations/eng/registration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    register: 'Register',
    aboutYou: 'About You',
    workRelated: 'Work-Related',
    successRegister: 'Registration was successful!',
    successRegister2: 'Your e-mail address has been verified! Log in to continue.',
    resetSuccessMessage: 'Password recovery was successful. We have sent you an e-mail with further instructions.',
    credentials: 'Registered e-mail',
    recoverPassword: 'Recover password',
    observationForm: 'Default observation form',
    firstStepSuccess: 'To finish the registration process, please follow the instructions sent to your e-mail address.',
    welcomeStepTwo: 'Welcome to PlutoF',
    firstName: 'First name *',
    lastName: 'Last name *',
    username: 'Username *',
    email: 'E-mail address *',
    password: 'Password *',
    passwordConfirm: 'Confirm password *',
    invalidUserOrPass: 'Invalid username or password.',
    errors: {
      usernameNotUnique: 'A user with the given name is already registered!',
      emailNotUnique: 'A user with the given e-mail is already registered!'
    },
    help: {
      username: '8-30 characters: numbers, @ . - and Latin letters',
      password: 'at least 12 characters of numbers, Latin letters, and special characters ! ? @ % $ # & : * - _',
      email: 'a confirmation e-mail will be sent to this address'
    }
  };
});