define("plutof/mixins/component-validations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ValidationsMixin = Ember.Mixin.create({
    validationChildren: [],
    init() {
      this._super(...arguments);
      this.createChildren();
      this.validityChanged();
      this.addObserver('validations.isValid', 'validationContext', () => this.validityChanged());
    },
    notifyValidity() {
      var valid = this.get('validations.isValid');
      var context = this.validationContext;
      if (!Ember.isNone(valid) && !Ember.isNone(context)) {
        context.set('validity', valid);
      }
    },
    createChildren: Ember.observer('validationContext', function () {
      if (Ember.isNone(this.validationContext)) {
        return;
      }
      this.validationChildren.forEach(child => {
        if (Ember.typeOf(child) === 'object') {
          this.validationContext.addChild(child.name, this.i18n.t(child.label));
        } else {
          this.validationContext.addChild(child, child);
        }
      });
    }),
    didReceiveAttrs() {
      this._super(...arguments);
      Ember.run.once(this, 'notifyValidity');
      return true;
    },
    willDestroyElement() {
      this._super(...arguments);
      const context = this.validationContext;
      if (Ember.isNone(context) || context.isDestroyed) {
        return;
      }
      context.set('validity', true);
      context.set('anchor', null);

      // XXX: Basically validationContext.destroy() without actually destroying the root
      // TODO: Can unite? Doesn't look so, subcomponent has no business destroying the object which
      // was created by a parent component
      context.subcontexts.forEach(function (ctx) {
        context.removeChild(ctx.get('name'));
        ctx.destroy();
      });
      context.subcontexts.clear();
      context.children.clear();
    },
    willRender() {
      this._super(...arguments);
      if (!Ember.isNone(this.validationContext)) {
        this.set('validationContext.anchor', this.elementId);
      }
    },
    validityChanged() {
      Ember.run.once(this, 'notifyValidity');
    }
  });
  var _default = _exports.default = ValidationsMixin;
});