define("plutof/components/information/content/project/general", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Information::MarkdownReader @path="area/types" @locale={{@locale}} />
  
  <Information::CollapsedSection @label={{i18n-t "Projects.managingProjects"}}>
      <Information::MarkdownReader @path="project" />
  </Information::CollapsedSection>
  
  */
  {
    "id": "des/HUaa",
    "block": "{\"symbols\":[\"@locale\"],\"statements\":[[8,\"information/markdown-reader\",[],[[\"@path\",\"@locale\"],[\"area/types\",[32,1]]],null],[2,\"\\n\\n\"],[8,\"information/collapsed-section\",[],[[\"@label\"],[[30,[36,0],[\"Projects.managingProjects\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"information/markdown-reader\",[],[[\"@path\"],[\"project\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\"]}",
    "meta": {
      "moduleName": "plutof/components/information/content/project/general.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});