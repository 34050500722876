define("plutof/components/search/filters/additional-identifiers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="filter-input">
      {{! TODO: replace all this add-component stuff with a component proper}}
      <div class="add-component">
          <div class="component-part">
              <div class="control-group">
                  {{common/local-label "Specimen.additionalCode"}}
  
                  <Input
                      @type="text"
                      @value={{@filter.code}}
                      class="form-control control" />
              </div>
          </div>
  
          <div class="component-part">
              {{plutof-labelc "specimen.additionalidentifier.source"}}
  
              <Input
                  @type="text"
                  @value={{@filter.source}}
                  class="form-control control" />
          </div>
      </div>
  </div>
  
  */
  {
    "id": "QXyVNsfK",
    "block": "{\"symbols\":[\"@filter\"],\"statements\":[[10,\"div\"],[14,0,\"filter-input\"],[12],[2,\"\\n\"],[2,\"    \"],[10,\"div\"],[14,0,\"add-component\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"component-part\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"control-group\"],[12],[2,\"\\n                \"],[1,[30,[36,0],[\"Specimen.additionalCode\"],null]],[2,\"\\n\\n                \"],[8,\"input\",[[24,0,\"form-control control\"]],[[\"@type\",\"@value\"],[\"text\",[32,1,[\"code\"]]]],null],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"div\"],[14,0,\"component-part\"],[12],[2,\"\\n            \"],[1,[30,[36,1],[\"specimen.additionalidentifier.source\"],null]],[2,\"\\n\\n            \"],[8,\"input\",[[24,0,\"form-control control\"]],[[\"@type\",\"@value\"],[\"text\",[32,1,[\"source\"]]]],null],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"common/local-label\",\"plutof-labelc\"]}",
    "meta": {
      "moduleName": "plutof/components/search/filters/additional-identifiers.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});