define("plutof/controllers/specimen/annotation/add", ["exports", "plutof/utils/annotation/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _routes.annotationAddController)({
    contentType: 'specimen/specimen',
    save(model) {
      return model.specimenData.save();
    },
    getRecordID(model) {
      return model.specimenData.specimen.id;
    }
  });
});