define("plutof/components/forms/utils", ["exports", "plutof/utils/push-to-store", "plutof/utils/reflection"], function (_exports, _pushToStore, _reflection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getModerators = getModerators;
  async function getModerators(ajax, store, form) {
    if (!form.requires_moderation) {
      return [];
    }
    const endpoint = (0, _reflection.get_record_url)(form) + 'moderators/';
    const persons = await ajax.request(endpoint);
    return (0, _pushToStore.default)(store, 'agent/person', persons);
  }
});