define("plutof/templates/event/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "xPLyO8uG",
    "block": "{\"symbols\":[],\"statements\":[[8,\"test/route-marker\",[],[[\"@route\"],[\"event.add\"]],null],[2,\"\\n\\n\"],[8,\"navbar/edit\",[],[[\"@icon\",\"@title\",\"@record\",\"@reset\"],[[30,[36,0],[\"event\"],null],[30,[36,1],[\"SamplingEvent.add\"],null],[32,0,[\"model\",\"event\"]],[30,[36,2],[\"triggerReset\"],null]]],null],[2,\"\\n\\n\"],[8,\"event/edit\",[],[[\"@event\",\"@habitat\",\"@measurementsData\",\"@gatheringAgents\",\"@habitatMeasurementsData\",\"@associatedData\",\"@validationContext\",\"@save\"],[[32,0,[\"model\",\"event\"]],[32,0,[\"model\",\"habitat\"]],[32,0,[\"model\",\"measurementsData\"]],[32,0,[\"model\",\"gatheringAgents\"]],[32,0,[\"model\",\"habitatMeasurementsData\"]],[32,0,[\"model\",\"itemData\"]],[32,0,[\"validationContext\"]],[32,0,[\"save\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\",\"route-action\"]}",
    "meta": {
      "moduleName": "plutof/templates/event/add.hbs"
    }
  });
});