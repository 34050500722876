define("plutof/components/specimen/auto-complete", ["exports", "@glimmer/component", "plutof/components/auto-complete/backends"], function (_exports, _component, _backends) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <AutoComplete::Base
      @backend={{backend}}
      @controller={{@controller}}
      @value={{@value}}
      @update={{@update}}
      @query={{@query}}
      @updateQuery={{@updateQuery}}
      @params={{@params}}
      ...attributes
  >
      <:result as |result context|>
          {{hl-subs (call this.resultName result) context.query}}
  
          {{#if result.full.owner}}
              <span class="plutof-ac-suggestion-description">
                  {{result.fullowner}}
              </span>
          {{/if}}
      </:result>
  </AutoComplete::Base>
  
  */
  {
    "id": "GfQEoCH4",
    "block": "{\"symbols\":[\"__arg0\",\"__arg1\",\"__arg2\",\"result\",\"context\",\"@controller\",\"@value\",\"@update\",\"@query\",\"@updateQuery\",\"@params\",\"&attrs\"],\"statements\":[[8,\"auto-complete/base\",[[17,12]],[[\"@backend\",\"@controller\",\"@value\",\"@update\",\"@query\",\"@updateQuery\",\"@params\",\"@namedBlocksInfo\"],[[34,4],[32,6],[32,7],[32,8],[32,9],[32,10],[32,11],[30,[36,5],null,[[\"result\"],[2]]]]],[[\"default\"],[{\"statements\":[[6,[37,2],[[30,[36,6],[[32,1],\"result\"],null]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[32,2],[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,1],[[30,[36,0],[[32,0,[\"resultName\"]],[32,4]],null],[32,5,[\"query\"]]],null]],[2,\"\\n\\n\"],[6,[37,2],[[32,4,[\"full\",\"owner\"]]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"span\"],[14,0,\"plutof-ac-suggestion-description\"],[12],[2,\"\\n                \"],[1,[32,4,[\"fullowner\"]]],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"]],\"parameters\":[4,5]}]]]],\"parameters\":[]}]]]],\"parameters\":[1,2,3]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"call\",\"hl-subs\",\"if\",\"let\",\"backend\",\"hash\",\"-is-named-block-invocation\"]}",
    "meta": {
      "moduleName": "plutof/components/specimen/auto-complete.hbs"
    }
  });
  let SpecimenAutoComplete = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed('args.filters'), _dec4 = Ember._action, (_class = class SpecimenAutoComplete extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
    }
    get backend() {
      return new _backends.ModelAutocomplete({
        ajax: this.ajax,
        store: this.store,
        modelName: 'taxonoccurrence/specimen/specimen',
        filters: this.args.filters || {
          access: 'edit'
        }
      });
    }
    resultName(result) {
      const {
        name,
        additional_identifiers
      } = result.full;
      return [name].concat(additional_identifiers).join(' | ');
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "backend", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "backend"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resultName", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "resultName"), _class.prototype)), _class));
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SpecimenAutoComplete);
});