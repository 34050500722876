define("plutof/templates/components/filerepository/item-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "95thc8Sa",
    "block": "{\"symbols\":[\"__arg0\",\"__arg1\",\"group\",\"tab\",\"item\"],\"statements\":[[8,\"tab-group\",[],[[\"@namedBlocksInfo\"],[[30,[36,2],null,[[\"tabs\"],[1]]]]],[[\"default\"],[{\"statements\":[[6,[37,8],[[30,[36,7],[[32,1],\"tabs\"],null]],null,[[\"default\"],[{\"statements\":[[6,[37,6],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3,[\"tabs\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[32,3,[\"tab\"]]],[[\"name\"],[[32,4,[\"contentType\"]]]],[[\"default\"],[{\"statements\":[[6,[37,0],[[32,3,[\"header\"]]],[[\"name\"],[[32,4,[\"contentType\"]]]],[[\"default\"],[{\"statements\":[[2,\"                    \"],[1,[32,4,[\"module\",\"name\"]]],[2,\"\\n\\n                    \"],[10,\"span\"],[14,0,\"count-badge\"],[12],[2,\"\\n                        \"],[1,[32,4,[\"items\",\"length\"]]],[2,\"\\n                    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n                \"],[10,\"div\"],[14,0,\"out-of-form-field\"],[12],[2,\"\\n                    \"],[8,\"auto-complete/model\",[],[[\"@model\",\"@update\",\"@filters\"],[[30,[36,1],[[32,4,[\"module\",\"model\"]],[32,4,[\"contentType\"]]],null],[32,4,[\"add\"]],[30,[36,2],null,[[\"access\"],[\"edit\"]]]]],null],[2,\"\\n                \"],[13],[2,\"\\n\\n                \"],[8,\"common/record-list\",[],[[\"@records\",\"@remove\"],[[32,4,[\"items\"]],[32,4,[\"remove\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n                    \"],[1,[32,5,[\"object_name\"]]],[2,\"\\n                \"]],\"parameters\":[5]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[4]}]]],[2,\"    \"]],\"parameters\":[3]}]]]],\"parameters\":[]}]]]],\"parameters\":[1,2]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"or\",\"hash\",\"data\",\"-track-array\",\"each\",\"let\",\"-is-named-block-invocation\",\"if\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/filerepository/item-list.hbs"
    }
  });
});