define("plutof/translations/est/substrate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    substrate: 'Substraadi takson',
    type: 'Substraadi tüüp',
    text: 'Substraadi tekst',
    parentSubstrate: '{{parent}} substraat'
  };
});