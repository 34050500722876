define("plutof/components/annotation/record-annotations", ["exports", "@glimmer/component", "plutof/misc/content_types", "plutof/utils/pagination/builder"], function (_exports, _component, _content_types, _builder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.loadAnnotations = loadAnnotations;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <RelatedObjects::View
      @pagination={{@annotations}}
      @title={{i18n-t "annotation.table.title"}}
      data-anchor="annotations"
  >
      <I18n::ModelStrings @model="annotation.client-annotation" as |translations|>
          <RelatedObjects::Content @pagination={{@annotations}} as |Field annotation|>
              <Annotation::Fields
                  @field={{Field}}
                  @annotation={{annotation}}
                  @translations={{translations}}
                  as |fields|
              >
                  <fields.icon />
  
                  <Field @label={{i18n-t "annotation.fields.status"}}>
                      <Annotation::AnnotationLink
                          @annotation={{annotation}}
                          @accessRights={{@accessRights}}
                      >
                          {{get translations.status.choices annotation.status}}
                      </Annotation::AnnotationLink>
                  </Field>
  
                  <fields.author/>
                  <fields.comment />
                  <fields.annotatedAt />
              </Annotation::Fields>
          </RelatedObjects::Content>
      </I18n::ModelStrings>
  </RelatedObjects::View>
  
  */
  {
    "id": "kXY2VrgZ",
    "block": "{\"symbols\":[\"translations\",\"Field\",\"annotation\",\"fields\",\"@annotations\",\"@accessRights\"],\"statements\":[[8,\"related-objects/view\",[[24,\"data-anchor\",\"annotations\"]],[[\"@pagination\",\"@title\"],[[32,5],[30,[36,0],[\"annotation.table.title\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"i18n/model-strings\",[],[[\"@model\"],[\"annotation.client-annotation\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"related-objects/content\",[],[[\"@pagination\"],[[32,5]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,\"annotation/fields\",[],[[\"@field\",\"@annotation\",\"@translations\"],[[32,2],[32,3],[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[8,[32,4,[\"icon\"]],[],[[],[]],null],[2,\"\\n\\n                \"],[8,[32,2],[],[[\"@label\"],[[30,[36,0],[\"annotation.fields.status\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n                    \"],[8,\"annotation/annotation-link\",[],[[\"@annotation\",\"@accessRights\"],[[32,3],[32,6]]],[[\"default\"],[{\"statements\":[[2,\"\\n                        \"],[1,[30,[36,1],[[32,1,[\"status\",\"choices\"]],[32,3,[\"status\"]]],null]],[2,\"\\n                    \"]],\"parameters\":[]}]]],[2,\"\\n                \"]],\"parameters\":[]}]]],[2,\"\\n\\n                \"],[8,[32,4,[\"author\"]],[],[[],[]],null],[2,\"\\n                \"],[8,[32,4,[\"comment\"]],[],[[],[]],null],[2,\"\\n                \"],[8,[32,4,[\"annotatedAt\"]],[],[[],[]],null],[2,\"\\n            \"]],\"parameters\":[4]}]]],[2,\"\\n        \"]],\"parameters\":[2,3]}]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"get\"]}",
    "meta": {
      "moduleName": "plutof/components/annotation/record-annotations.hbs"
    }
  });
  async function loadAnnotations(ajax, record) {
    const ctype = await (0, _content_types.get_object_ctype)(record.store, record);
    const pagination = await (0, _builder.default)({
      ajax
    }).fromEndpoint('annotation/record-annotations/').withCount.fromEndpoint().withFilters({
      content_type: ctype.id,
      object_id: record.id,
      ordering: '-annotated_at'
    }).build();
    return pagination;
  }
  class RecordAnnotations extends _component.default {}
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, RecordAnnotations);
});