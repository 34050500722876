define("plutof/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "/zEe8Ggt",
    "block": "{\"symbols\":[\"navbar\",\"slots\"],\"statements\":[[8,\"test/route-marker\",[],[[\"@route\"],[\"index\"]],null],[2,\"\\n\\n\"],[8,\"navbar/navbar-base\",[],[[\"@kind\"],[\"view\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,1,[\"header\"]],[],[[\"@icon\",\"@title\"],[[30,[36,4],[\"person\"],null],[30,[36,5],[\"Agents.person.person\"],null]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,2,[\"primaryControls\"]],[],[[],[]],null],[2,\"\\n\\n\"],[2,\"    \"],[8,[32,2,[\"secondaryControls\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,1,[\"button\"]],[],[[\"@clicked\",\"@icon\"],[[32,0,[\"edit\"]],[30,[36,4],[\"edit\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,5],[\"General.Edit\"],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,1,[\"bookmark\"]],[],[[],[]],null],[2,\"\\n\\n        \"],[8,[32,1,[\"info\"]],[],[[\"@title\",\"@content\"],[[30,[36,5],[\"Agents.person.person\"],null],\"information/content/person/general\"]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"\\n\\n\"],[6,[37,6],[[35,0,[\"isFulfilled\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],null,[[\"person\"],[[35,0,[\"person\"]]]]]],[2,\"\\n\\n    \"],[1,[30,[36,2],null,[[\"agentassociations\"],[[35,0,[\"agentassociations\"]]]]]],[2,\"\\n\\n    \"],[1,[30,[36,3],null,[[\"person\",\"statistics\",\"statisticsOptions\"],[[35,0,[\"person\"]],[35,0,[\"statistics\"]],[35,0,[\"statisticsOptions\"]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"data\",\"person/person-view-general\",\"person/person-view-associations\",\"person/person-view-statistics\",\"icon\",\"i18n-t\",\"if\"]}",
    "meta": {
      "moduleName": "plutof/templates/index.hbs"
    }
  });
});