define("plutof/translations/eng/datacite", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    intro: {
      description: 'Create and register DataCite Digital Object Identifiers (DOIs) to datasets and other research objects.',
      title: 'DataCite DOIs'
    },
    panels: {
      dates: 'Dates',
      creators: 'Creators',
      contributors: 'Contributors',
      funders: 'Funders',
      resources: 'Resources',
      taxa: 'Taxa',
      areas: 'Areas',
      relatedIdentifiers: 'Related Identifiers',
      alternateIdentifiers: 'Alternate Identifiers',
      subjects: 'Keywords/Subjects'
    },
    actions: {
      publish: 'Register DataCite DOI'
    },
    metadata: {
      taxon: {
        label: 'Taxon'
      }
    },
    subjects: {
      subjects: {
        label: 'Subjects/keywords',
        placeholder: 'Enter a new subject'
      }
    },
    relatedIdentifier: {
      metadata: {
        label: 'DOI record',
        tooltip: 'DOI record in PlutoF. Leave empty when refering to an external identifier.'
      },
      identifier: {
        label: 'Identifier'
      },
      identifierType: {
        label: 'Identifier type'
      },
      relationType: {
        label: 'Relation type'
      },
      resourceType: {
        label: 'Resource type'
      }
    },
    creator: {
      agent: 'Creator',
      affiliation: 'Affiliation'
    }
  };
});