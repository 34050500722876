define("plutof/templates/components/search/gbif-filter-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "FizgtkcN",
    "block": "{\"symbols\":[\"add\",\"t\",\"@filter\"],\"statements\":[[6,[37,0],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"filter-input\"],[15,\"data-input\",[32,3,[\"config\",\"label\"]]],[12],[2,\"\\n\"],[6,[37,1],null,null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"div\"],[14,0,\"plutof-label\"],[12],[2,\"\\n                \"],[1,[32,3,[\"config\",\"label\"]]],[2,\"\\n            \"],[13],[2,\"\\n\\n            \"],[10,\"span\"],[15,1,[31,[[32,2,[\"tid\"]]]]],[14,0,\"wrapper-tooltip\"],[12],[2,\"\\n\"],[6,[37,0],[[32,3,[\"config\",\"help_text\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"                    \"],[1,[32,3,[\"config\",\"help_text\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"                    \"],[1,[32,3,[\"config\",\"label\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"            \"],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"    \"],[13],[2,\"\\n\\n    \"],[8,\"auto-complete/bind-multiple\",[],[[\"@value\"],[[32,3,[\"value\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"publishing/gbif/auto-complete\",[[24,0,\"filter-input-ac\"]],[[\"@update\",\"@params\",\"@datasetType\"],[[32,1],[30,[36,2],null,[[\"enableVoidQuery\"],[true]]],[32,0,[\"recordType\"]]]],null],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"tooltip-wrapper\",\"hash\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/search/gbif-filter-input.hbs"
    }
  });
});