define("plutof/translations/eng/analysis", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    newAnalysis: 'New Sequence Analysis',
    editAnalysis: 'Edit Sequence Analysis',
    analysis: 'Sequence Analysis',
    programParameters: 'Program Parameters',
    complement: 'Complement',
    complementToolt: 'Checks both DNA strands against the database, creating reverse complements.',
    concat: 'Concat',
    concatToolt: 'Saves a FASTA-file with concatenated ITS sequences (with 5.8S removed).',
    detailed: 'Detailed',
    detailedToolt: 'Saves a tab-separated list of all results.',
    preserve: 'Preserve',
    preserveToolt: 'Preserve sequence headers in input file instead of printing out ITSx headers.',
    anchorHmm: 'Hmm',
    fileTooBigInfo: 'Maximum file size of SH matching is 1 MB and ITSx 30 MB.',
    genBank: 'INSD (fungi)',
    genBankToolt: 'INSD = GenBank + ENA + DDBJ',
    animal: 'UNITE+INSD (other eukaryotes)',
    animalToolt: 'UNITE+INSD (other eukaryotes)',
    unite: 'UNITE (fungi)',
    uniteToolt: 'UNITE (fungi)',
    envir: 'Environmental',
    envirToolt: 'Environmental',
    tri12: 'ToxGen',
    tri12Toolt: 'Tri12 gene sequences for Fusarium species',
    fileLabel: 'Choose input file in FASTA format',
    fastaTextLabel: 'Paste 1-1000 sequences in FASTA format',
    running: '(running)',
    start: 'Start',
    sourceData: 'Sequences',
    addDeterminations: 'Add SH identifications',
    hasDetermination: 'Identification added',
    clipboardItems: '{{count}} sequences from clipboard',
    generateReport: 'Generate report',
    downloadCSV: 'Download CSV',
    downloadRawResults: 'Download results',
    addFASTA: 'Add from FASTA',
    fastaSequences: 'Sequences from {{filename}}',
    description: 'Choose between different analysis programs (e.g. ITSx, massBLASTer) to analyse your molecular sequence data.',
    results: 'Results',
    match: {
      sequence: 'Matched sequence',
      sh: 'UNITE SH',
      taxon: 'SH taxon name',
      distance: 'Distance',
      distanceTooltip: 'Minimum distance to the closest SH',
      compound: 'Compound cluster',
      compoundTaxon: 'Compound cluster taxon name',
      percentage: 'Percentage',
      includeIntoUNITE: 'Include into UNITE SHs',
      threshold: {
        sh: 'UNITE SH ({{threshold}}%)',
        shTaxon: 'SH taxon name ({{threshold}}%)'
      },
      details: {
        blast: {
          evalue: 'E-Value',
          bitscore: 'Blast score'
        }
      }
    },
    status: {
      prerun: 'Not started',
      ready: 'Ready',
      error: 'Error',
      running: 'Running'
    },
    sendToClipboard: {
      all: 'Send all to clipboard',
      identified: 'Send identified to clipboard'
    }
  };
});