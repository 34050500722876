define("plutof/components/access/-view-rights/derived-from", ["exports", "@glimmer/component", "plutof/utils/modules"], function (_exports, _component, _modules) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#access/-view-rights/column}}
      <div class="plutof-label">
          {{#if this.module.name}}
              {{this.module.name}}
          {{else}}
              {{i18n-t "Access.derivedFrom"}}
          {{/if}}
      </div>
  
      <p>
          {{#if this.module.routes.view}}
              {{#link-to this.module.routes.view @parent.id}}
                  <span class={{this.module.icon}}></span>
                  {{@parent.name}}
              {{/link-to}}
          {{else}}
              <span class={{this.module.icon}}></span>
              {{@parent.name}}
          {{/if}}
      </p>
  {{/access/-view-rights/column}}
  
  */
  {
    "id": "S/kQ7SYP",
    "block": "{\"symbols\":[\"@parent\"],\"statements\":[[6,[37,3],null,null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"plutof-label\"],[12],[2,\"\\n\"],[6,[37,2],[[32,0,[\"module\",\"name\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[1,[32,0,[\"module\",\"name\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"            \"],[1,[30,[36,1],[\"Access.derivedFrom\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\\n    \"],[10,\"p\"],[12],[2,\"\\n\"],[6,[37,2],[[32,0,[\"module\",\"routes\",\"view\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,0],null,[[\"route\",\"model\"],[[32,0,[\"module\",\"routes\",\"view\"]],[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"                \"],[10,\"span\"],[15,0,[32,0,[\"module\",\"icon\"]]],[12],[13],[2,\"\\n                \"],[1,[32,1,[\"name\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"            \"],[10,\"span\"],[15,0,[32,0,[\"module\",\"icon\"]]],[12],[13],[2,\"\\n            \"],[1,[32,1,[\"name\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"link-to\",\"i18n-t\",\"if\",\"access/-view-rights/column\"]}",
    "meta": {
      "moduleName": "plutof/components/access/-view-rights/derived-from.hbs"
    }
  });
  class ViewRightsDerivedFrom extends _component.default {
    get module() {
      return _modules.default[this.args.parent.type];
    }
  }
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ViewRightsDerivedFrom);
});