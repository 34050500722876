define("plutof/routes/area/add", ["exports", "plutof/components/coordinate-converter", "plutof/components/linked-items/edit", "plutof/components/measurements/measurement-form", "plutof/misc/profile_settings", "plutof/mixins/add-route", "plutof/utils/access"], function (_exports, _coordinateConverter, _edit, _measurementForm, _profile_settings, _addRoute, _access) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class AddAreaRoute extends Ember.Route.extend(_addRoute.default) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "defaultReturnRoute", 'area.index');
      _defineProperty(this, "title", 'Sample.newSArea');
    }
    async model(params) {
      if (this.get('controller.routeHasBeenLoaded')) {
        return undefined;
      }
      const area = this.store.createRecord('sample/samplingarea', {
        study: await this.getProject(params)
      });
      const rightsPromise = (0, _access.create_access_rights)(this.store, area);
      if (Ember.isPresent(params.parent_area_id)) {
        const parentArea = await this.store.findRecord('sample/samplingarea', params.parent_area_id);
        area.set('parent_samplingarea', parentArea);
      }
      if (Ember.isPresent(params.predefined)) {
        // booleanify
        area.set('site', params.predefined === 'true');
      }
      return Ember.RSVP.hash({
        samplingarea: area,
        accessRights: rightsPromise,
        measurementsData: _measurementForm.MeasurementsData.create({
          store: this.store
        }),
        itemData: (0, _edit.createLinkedItemsData)(this.store, area, {
          useEventFiles: false
        }),
        geometryConverterData: (0, _coordinateConverter.createCoordinateConverterData)(this)
      });
    }
    async getProject(params) {
      if (params.project_id) {
        return this.store.findRecord('study/study', params.project_id);
      }
      const settings = await (0, _profile_settings.get_personal_settings)(this.store);
      return settings.default_study;
    }
  }
  var _default = _exports.default = AddAreaRoute;
});