define("plutof/components/plutof-panel/item-list/footer", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="panel-title-container">
      {{! h4 ensures uniform line height with the panel header }}
      <h4 class="panel-title">
          <span>&nbsp;</span>
      </h4>
  </div>
  
  <div class="panel-header-controls panel-header-controls--flat">
      <div class="plutof-btn__controls-container">
          {{yield}}
      </div>
  </div>
  
  */
  {
    "id": "a2p3iu7+",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"panel-title-container\"],[12],[2,\"\\n\"],[2,\"    \"],[10,\"h4\"],[14,0,\"panel-title\"],[12],[2,\"\\n        \"],[10,\"span\"],[12],[2,\" \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"panel-header-controls panel-header-controls--flat\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"plutof-btn__controls-container\"],[12],[2,\"\\n        \"],[18,1,null],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "plutof/components/plutof-panel/item-list/footer.hbs"
    }
  });
  let Footer = (_dec = (0, _component.classNames)('panel-footer', 'simplified-selection__footer'), _dec(_class = class Footer extends Ember.Component {}) || _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Footer);
});