define("plutof/templates/components/photobank/custom-classifications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "GOX9erNA",
    "block": "{\"symbols\":[\"__arg0\",\"__arg1\",\"group\",\"data\"],\"statements\":[[6,[37,11],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"tab-group\",[],[[\"@namedBlocksInfo\"],[[30,[36,9],null,[[\"tabs\"],[1]]]]],[[\"default\"],[{\"statements\":[[6,[37,11],[[30,[36,10],[[32,1],\"tabs\"],null]],null,[[\"default\"],[{\"statements\":[[6,[37,8],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[30,[36,6],[[35,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[32,3,[\"tab\"]]],[[\"name\"],[[32,4,[\"name\"]]]],[[\"default\"],[{\"statements\":[[6,[37,0],[[32,3,[\"header\"]]],[[\"name\"],[[32,4,[\"name\"]]]],[[\"default\"],[{\"statements\":[[2,\"                        \"],[1,[32,4,[\"name\"]]],[2,\"\\n\\n                        \"],[10,\"span\"],[14,0,\"count-badge\"],[12],[2,\"\\n                            \"],[1,[32,4,[\"selectedClassificationItems\",\"length\"]]],[2,\"\\n                        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n                    \"],[1,[30,[36,4],null,[[\"classification\",\"data\",\"object\",\"selectedItems\",\"objectsToDelete\"],[[32,4,[\"classification\"]],[32,4],[35,3],[35,2],[35,1]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[4]}]]],[2,\"        \"]],\"parameters\":[3]}]]]],\"parameters\":[]}]]]],\"parameters\":[1,2]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"component\",\"objectsToDelete\",\"items\",\"object\",\"photobank/classification-form\",\"classificationsData\",\"-track-array\",\"each\",\"let\",\"hash\",\"-is-named-block-invocation\",\"if\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/photobank/custom-classifications.hbs"
    }
  });
});