define("plutof/controllers/import/add", ["exports", "ember-data", "@ember-decorators/object", "moment", "plutof/components/subtree-selector", "plutof/config/environment", "plutof/misc/abstract", "plutof/models/contenttype", "plutof/services/ajax", "plutof/utils/datetime", "plutof/utils/formatters", "plutof/utils/i18n", "plutof/utils/modules", "plutof/utils/notifications", "plutof/utils/store", "plutof/utils/structures"], function (_exports, _emberData, _object, moment, _subtreeSelector, _environment, _abstract, _contenttype, _ajax, _datetime, _formatters, _i18n, _modules, _notifications, _store, _structures) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.MODULES = _exports.ImportTreeLeaf = _exports.ImportTreeInnerNode = _exports.FORMATS = void 0;
  _exports.getImportTree = getImportTree;
  _exports.getTreeLeafNames = getTreeLeafNames;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _class2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const i18n = (0, _i18n.getI18n)();
  const FORMATS = _exports.FORMATS = function () {
    function momentCheck(format) {
      return value => moment(value, format, true).isValid();
    }

    // TODO: Get front date parsing/formatting in order
    function checkApproximateDatetime(value, onlyDate) {
      const fmt = (0, _formatters.getAmbiguousDateFormat)(value);
      if (onlyDate && fmt === _formatters.DEFAULT_DATETIME_FORMAT) {
        return false;
      }
      return moment(value, fmt, true).isValid();
    }
    return {
      date: {
        'approximate': {
          id: 'approximate',
          check: value => checkApproximateDatetime(value, true)
        },
        'YYYY-MM-DD': {
          id: 'YYYY-MM-DD',
          check: momentCheck('YYYY-MM-DD')
        },
        'DD/MM/YYYY': {
          id: 'DD/MM/YYYY',
          check: momentCheck('DD/MM/YYYY')
        }
      },
      datetime: {
        'approximate': {
          id: 'approximate',
          check: value => checkApproximateDatetime(value, false)
        },
        'YYYY-MM-DD hh:mm': {
          id: 'YYYY-MM-DD hh:mm',
          check: momentCheck('YYYY-MM-DD HH:mm')
        },
        'DD/MM/YYYY hh:mm': {
          id: 'DD/MM/YYYY hh:mm',
          check: momentCheck('DD/MM/YYYY HH:mm')
        }
      },
      coordinates: {
        'decimal': {
          id: 'decimal',
          latRegex: /^-?\d+(\.\d+)?$/,
          lonRegex: /^-?\d+(\.\d+)?$/
        },
        'split': {
          id: 'split',
          latRegex: /^\d+:\d+:\d+(\.\d+)?:[NS]$/,
          lonRegex: /^\d+:\d+:\d+(\.\d+)?:[WE]$/
        }
      }
    };
  }();
  const MODULES = _exports.MODULES = function () {
    const importModules = [{
      id: 'specimen',
      mainformType: 'specimen',
      contentType: 'specimen/specimen'
    }, {
      id: 'observation',
      mainformType: 'observation',
      contentType: 'observation/observation'
    }, {
      id: 'sequence',
      mainformType: 'sequence',
      contentType: 'sequence/sequence',
      hasSourceOccurrences: true
    }, {
      id: 'materialsample',
      mainformType: 'materialsample',
      contentType: 'materialsample/materialsample'
    }, {
      id: 'livingspecimen',
      mainformType: 'strain',
      contentType: 'livingculture/strain',
      hasSourceOccurrences: true
    }, {
      id: 'reference-based',
      // No mainforms
      noFormRequired: true,
      contentType: 'referencebased/occurrence'
    }, {
      id: 'sh-identification',
      noFormRequired: true,
      contentType: 'globalkey/determination'
    }, {
      id: 'area',
      noFormRequired: false,
      mainformType: null,
      contentType: 'sample/samplingarea'
    }, {
      id: 'event',
      noFormRequired: false,
      mainformType: null,
      contentType: 'sample/samplingevent'
    }];

    // TODO: Just store app module with the choice
    for (const importModule of importModules) {
      const appModule = _modules.default[importModule.contentType];
      importModule.name = appModule.name;
      importModule.listRoute = appModule.routes ? appModule.routes.list : null;
      importModule.recordModule = appModule;
    }
    return importModules;
  }();
  let ImportSetupController = (_dec = Ember.inject.service, _dec2 = Ember.computed('module'), _dec3 = Ember.computed.or('mainform', 'noMainformRequired'), _dec4 = Ember.computed('selectedDatetimeFormat', 'selectedCoordinateFormat'), _dec5 = Ember.computed('creatingImport', 'mainform', 'sourceFile', 'module', 'model.taxonomies.[]'), _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, (_class = class ImportSetupController extends Ember.Controller {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _defineProperty(this, "queryParams", ['module_id']);
      _defineProperty(this, "module_id", null);
      _defineProperty(this, "is_async", false);
      _defineProperty(this, "matchPredefinedAreas", false);
      _defineProperty(this, "useSourceEventIfMissing", false);
      _defineProperty(this, "includeEhak", false);
      _defineProperty(this, "matchSequencesByURL", false);
      _defineProperty(this, "modules", MODULES.reject(module => _environment.default.DISABLED_IMPORT_MODULES.includes(module.id)));
      _initializerDefineProperty(this, "mainformIsValid", _descriptor2, this);
    }
    init() {
      super.init(...arguments);
      this.createFormats();
    }
    selectModule(id) {
      const module = this.modules.findBy('id', id);
      this.switchModule(module);
    }
    get noMainformRequired() {
      return this.get('module.noFormRequired');
    }
    createFormats() {
      // Maybe split on lon/lat formats for consistency
      const coordinateFormats = [{
        name: i18n.t('Import.coordinatesFormat1'),
        format: FORMATS.coordinates['decimal']
      }, {
        name: i18n.t('Import.coordinatesFormat2'),
        format: FORMATS.coordinates['split']
      }];
      this.set('coordinateFormats', coordinateFormats);
      this.set('selectedCoordinateFormat', coordinateFormats[0]);
      const datetimeFormats = [{
        name: i18n.t('Import.ambiguousDatetime'),
        date: FORMATS.date['approximate'],
        datetime: FORMATS.datetime['approximate']
      }, {
        name: i18n.t('Import.dateTimeFormat1'),
        date: FORMATS.date['YYYY-MM-DD'],
        datetime: FORMATS.datetime['YYYY-MM-DD hh:mm']
      }, {
        name: i18n.t('Import.dateTimeFormat2'),
        date: FORMATS.date['DD/MM/YYYY'],
        datetime: FORMATS.datetime['DD/MM/YYYY hh:mm']
      }];
      this.set('datetimeFormats', datetimeFormats);
      this.set('selectedDatetimeFormat', datetimeFormats[0]);
      const localTimezone = (0, _datetime.getLocalTimezone)();
      const timezones = [{
        name: i18n.t('Import.timezoneUTC'),
        code: 'UTC'
      }, {
        name: localTimezone,
        code: localTimezone
      }];
      this.set('timezones', timezones);
      this.set('selectedTimezone', timezones[0]);
    }

    // Currently only date, in future will probably need to choose
    // csv dialect
    get formats() {
      const formats = {
        date: this.get('selectedDatetimeFormat.date'),
        datetime: this.get('selectedDatetimeFormat.datetime'),
        coordinates: this.get('selectedCoordinateFormat.format')
      };
      return formats;
    }
    gotoImport(importProcess, finishRoute) {
      return this.transitionToRoute('import.process').then(route => {
        route.get('controller').setupImport(importProcess, () => {
          if (importProcess.get('totalIgnored') === 0) {
            this.transitionToRoute(finishRoute);
          }
        });
      });
    }
    get cantCreate() {
      if (this.creatingImport || [this.sourceFile, this.module].any(Ember.isNone)) {
        return true;
      }
      if (this.model.taxonomies.length === 0) {
        return true;
      }
      return !this.mainform && this.module.mainformType;
    }
    switchModule(module) {
      this.set('mainform', null);
      this.set('module', module);
      this.set('module_id', this.get('module.id'));
    }
    save() {
      return this.saveProcess().then(process => {
        if (!Ember.isNone(process)) {
          this.transitionToRoute('import.index');
        }
      });
    }
    saveAndStart() {
      return this.saveProcess().then(process => {
        if (!Ember.isNone(process)) {
          this.transitionToRoute('import.process', process.id);
        }
      });
    }
    async saveProcess() {
      this.set('creatingImport', true);
      const sourceFile = this.sourceFile;
      const process = this.store.createRecord('batchimport/process', {
        source: sourceFile,
        mainform: this.mainform,
        project: this.project,
        module: this.module.id,
        match_predefined_areas: this.matchPredefinedAreas,
        use_source_event_if_missing: this.useSourceEventIfMissing,
        include_ehak: this.includeEhak,
        sh_match_sequences_by_frontend_url: this.matchSequencesByURL,
        is_async: this.is_async,
        format_coordinates: this.formats.coordinates.id,
        format_date: this.formats.date.id,
        format_datetime: this.formats.datetime.id,
        timezone: this.selectedTimezone.code,
        taxonomies: this.model.taxonomies.map(t => t.id)
      });
      try {
        await process.save();
        return process;
      } catch (err) {
        await sourceFile.destroyCompletely();
        (0, _notifications.reportError)(err);
      } finally {
        this.set('creatingImport', false);
        this.set('sourceFile', null);
      }
    }
    reset() {
      this.setProperties({
        mainform: null,
        tree: null,
        project: null,
        sourceFile: null,
        selectedTimezone: this.timezones.firstObject,
        selectedCoordinateFormat: this.coordinateFormats.firstObject,
        selectedDatetimeFormat: this.datetimeFormats.firstObject,
        matchPredefinedAreas: false,
        useSourceEventIfMissing: false,
        matchSequencesByURL: false,
        includeEhak: false,
        is_async: false
      });
    }
    generateTemplate() {
      this.router.transitionTo('import.generate-template');
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "noMainformRequired", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "noMainformRequired"), _class.prototype), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "mainformIsValid", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "formats", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "formats"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cantCreate", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "cantCreate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "switchModule", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "switchModule"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveAndStart", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "saveAndStart"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reset", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "reset"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "generateTemplate", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "generateTemplate"), _class.prototype)), _class));
  const ImportTreeNodeMixin = Ember.Mixin.create({
    fullTitle: Ember.computed('title', 'parent.fullTitle', 'parent.virtual', function () {
      const parentTitle = this.get('parent.fullTitle');
      return Ember.isEmpty(parentTitle) ? this.title : `${parentTitle}.${this.title}`;
    })
  });
  let ImportTreeLeaf = _exports.ImportTreeLeaf = (_dec11 = Ember.computed.equal('type', 'string'), _dec12 = Ember.computed.equal('type', 'integer'), _dec13 = Ember.computed.equal('type', 'float'), _dec14 = Ember.computed.equal('type', 'boolean'), _dec15 = Ember.computed.equal('type', 'date'), _dec16 = Ember.computed.equal('type', 'relation'), _dec17 = Ember.computed.equal('type', 'dropdown'), _dec18 = Ember.computed.equal('type', 'choices'), _dec19 = Ember.computed.or('isRelation', 'isDropdown'), _dec20 = Ember.computed('model', 'type'), _dec21 = Ember.computed('isDropdown', 'model', 'filters'), _dec22 = Ember.computed('isModelField', 'model'), _dec23 = (0, _object.observes)('parent', 'selected'), _dec24 = (0, _object.observes)('selected'), (_class2 = class ImportTreeLeaf extends _subtreeSelector.TreeLeaf.extend(ImportTreeNodeMixin) {
    constructor(...args) {
      super(...args);
      // XXX
      _initializerDefineProperty(this, "isString", _descriptor3, this);
      _initializerDefineProperty(this, "isInteger", _descriptor4, this);
      _initializerDefineProperty(this, "isFloat", _descriptor5, this);
      _initializerDefineProperty(this, "isBoolean", _descriptor6, this);
      _initializerDefineProperty(this, "isDate", _descriptor7, this);
      _initializerDefineProperty(this, "isRelation", _descriptor8, this);
      _initializerDefineProperty(this, "isDropdown", _descriptor9, this);
      _initializerDefineProperty(this, "isChoices", _descriptor10, this);
      _initializerDefineProperty(this, "isModelField", _descriptor11, this);
    }
    get typeName() {
      return Ember.isEmpty(this.model) ? this.type : this.model;
    }
    get dropdownValues() {
      if (!this.isDropdown) {
        return [];
      }
      return _emberData.default.PromiseArray.create({
        promise: (0, _store.findAllFiltered)(this.store, this.model, this.filters)
      });
    }
    get addRoute() {
      if (!this.isModelField) {
        return null;
      }
      if (Ember.isEmpty(this.model)) {
        return null;
      }
      const moduleDesc = _modules.default[this.model];
      if (Ember.isNone(moduleDesc)) {
        return null;
      }
      return moduleDesc.routes.add;
    }
    init() {
      super.init(...arguments);
      this.selectSemiRequiredSiblings();
    }
    selectSemiRequiredSiblings() {
      if (!Ember.isNone(this.parent) && this.selected) {
        this.parent.selectSemiRequiredChildren();
      }
    }
    clearIfSemirequiredDeselected() {
      if (!Ember.isNone(this.parent) && !this.selected) {
        if (this.parent.semi_required_attributes.includes(this.id)) {
          // TODO: Again, this gets weird because observers don't distinguish between manual
          // selection and setting selected from code. Should make checkboxes here action-up
          this.parent.set('selected', false);
          this.parent.propagateSelection();
        }
      }
    }
  }, (_descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "isString", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "isInteger", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "isFloat", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "isBoolean", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "isDate", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "isRelation", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "isDropdown", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class2.prototype, "isChoices", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class2.prototype, "isModelField", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "typeName", [_dec20], Object.getOwnPropertyDescriptor(_class2.prototype, "typeName"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "dropdownValues", [_dec21], Object.getOwnPropertyDescriptor(_class2.prototype, "dropdownValues"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "addRoute", [_dec22], Object.getOwnPropertyDescriptor(_class2.prototype, "addRoute"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "selectSemiRequiredSiblings", [_dec23], Object.getOwnPropertyDescriptor(_class2.prototype, "selectSemiRequiredSiblings"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "clearIfSemirequiredDeselected", [_dec24], Object.getOwnPropertyDescriptor(_class2.prototype, "clearIfSemirequiredDeselected"), _class2.prototype)), _class2));
  class ImportTreeInnerNode extends _subtreeSelector.TreeInnerNode.extend(ImportTreeNodeMixin) {
    // TODO: Honestly, this should just be in @selected observer: if a node is selected,
    // it selects its parent and semi_required_attributes. But then we lose select-whole-subtree,
    // which is probably ok. This will do for now, though
    //
    // TODO: this should manipulate the @required status as well - if node is selected, all its
    // semi-required children become mandatory. This requires some thinging about backend part
    // as well, this SR thing smells too much
    selectSemiRequiredChildren() {
      const srChildren = this.semi_required_attributes;
      this.children.filter(child => srChildren.includes(child.get('id'))).forEach(child => child.set('selected', true));
    }
  }
  _exports.ImportTreeInnerNode = ImportTreeInnerNode;
  function getImportTree(store, module_name, mainform) {
    let params = {
      module: module_name
    };
    if (!Ember.isNone(mainform)) {
      params.mainform = mainform.id;
    }
    const ajax = (0, _ajax.ajaxFromControlled)(store);
    const treeURL = (0, _abstract.construct_request)(`${_environment.default.API_HOST}/batchimport/tree/`, params);
    return ajax.request(treeURL).then(tree => specToTree(store, tree));
  }

  // Measurement value nodes have "Value" as name everywhere. But when fixing errors user needs to
  // understand what measurement it is, not just see "Value" eveywhere
  const NODE_USING_PARENT_TITLE = /measurement.*value/;
  function isTrait(node) {
    return node.id && node.id.startsWith('measurement');
  }
  function specToTree(store, node, parent = null) {
    if (Ember.isNone(node.children)) {
      const shortTitle = NODE_USING_PARENT_TITLE.test(node.full_id) ? parent.title : node.name;
      return ImportTreeLeaf.create({
        id: node.id,
        full_id: node.full_id,
        title: node.name,
        shortTitle,
        parent,
        description: node.description || node.name,
        required: node.required,
        selected: node.required,
        type: node.type,
        multiple: node.multiple,
        model: fixAppLabel(node.model),
        filters: node.filters,
        choices: node.choices,
        regex: Ember.isEmpty(node.regex) ? null : new RegExp(node.regex),
        scope: node.scope || 'global',
        unit: node.unit,
        store: store
      });
    } else {
      var tree = ImportTreeInnerNode.create({
        id: node.id,
        title: node.name,
        shortTitle: node.name,
        parent: parent,
        unit: node.unit,
        children: [],
        semi_required_attributes: node.semi_required_attributes
      });
      const children = node.children.map(child => specToTree(store, child, tree));
      const [traits, immediateChildren] = (0, _structures.split)(children, isTrait);
      if (traits.length > 0) {
        const title = (0, _i18n.getI18n)().t('traits.traits');
        const groupedTraits = ImportTreeInnerNode.create({
          id: 'traits',
          title: title,
          shortTitle: title,
          fullTitle: tree.fullTitle,
          parent: tree,
          children: [],
          semi_required_attributes: [],
          virtual: true
        });
        for (const trait of traits) {
          trait.set('parent', groupedTraits);
        }
        groupedTraits.set('children', traits);

        // XXX: This is meh. Proper solution for the whole
        // trait grouping thing is to do in on backend, make group nodes
        // an actual part of the tree. But that's for import v2
        const traitBlockStart = children.indexOf(traits[0]);
        immediateChildren.splice(traitBlockStart, 0, groupedTraits);
      }
      tree.set('children', immediateChildren);

      // We find measurement unit from leaves and attach it to the branch - it will be displayed at the branch.
      const unitChild = children.find(node => Ember.isPresent(node));
      if (Ember.isPresent(unitChild)) {
        tree.set('unit', unitChild.get('unit'));
      }
      return tree;
    }
  }

  // XXX: onlySelected
  function getTreeLeafNames(tree, onlySelected = true) {
    function getHeaders(node, prefix) {
      if (node.get('isLeaf')) {
        return !onlySelected || node.get('selected') ? [prefix + node.get('title')] : [];
      } else {
        const childPrefix = node.virtual ? prefix : `${prefix}${node.title}.`;
        return (0, _structures.concat)(node.get('children').map(function (child) {
          return getHeaders(child, childPrefix);
        }));
      }
    }
    const rootTitle = tree.get('title');
    return getHeaders(tree, '').map(function (header) {
      return header.substr(rootTitle.length + 1);
    });
  }
  function fixAppLabel(modelName) {
    if (Ember.isEmpty(modelName) || modelName.indexOf('/') === -1) {
      return modelName;
    }
    const [app_label, model] = modelName.split('/');
    return (0, _contenttype.fullModelName)(app_label, model);
  }
  var _default = _exports.default = ImportSetupController;
});