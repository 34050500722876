define("plutof/templates/livingspecimen/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "oNJL1lDS",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"route-marker-livingspecimen-add\"],[14,\"data-test\",\"route-livingspecimen.add\"],[12],[13],[2,\"\\n\\n\"],[8,\"navbar/edit\",[],[[\"@icon\",\"@title\",\"@record\",\"@reset\",\"@infoContent\"],[[30,[36,0],[\"livingspecimen\"],null],[30,[36,1],[\"LivingCultures.newLivingC\"],null],[32,0,[\"model\",\"strain\"]],[30,[36,2],[\"triggerReset\"],null],\"information/content/living-specimen/add\"]],null],[2,\"\\n\\n\"],[8,\"parent-object-path\",[],[[\"@object_id\",\"@contentType\"],[[32,0,[\"object_id\"]],[32,0,[\"model\",\"queryContentType\"]]]],null],[2,\"\\n\\n\"],[8,\"livingspecimen/edit\",[],[[\"@model\",\"@hideSourceSelection\",\"@quickFormChoices\",\"@validationContext\",\"@showFormSelector\",\"@usingDefaultForm\",\"@allowAreaReset\",\"@preserveZoom\"],[[32,0,[\"model\",\"livingSpecimenModel\"]],[30,[36,3],[[32,0,[\"object_id\"]],[32,0,[\"template_id\"]]],null],[32,0,[\"plutofMainforms\"]],[32,0,[\"validationContext\"]],true,[32,0,[\"model\",\"usingDefaultForm\"]],true,[32,0,[\"preserveZoom\"]]]],null],[2,\"\\n\\n\"],[8,\"sticky-bottom-wrapper\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"invalid-panels\",[],[[\"@validationContext\"],[[32,0,[\"validationContext\"]]]],null],[2,\"\\n\\n    \"],[8,\"save-panel\",[],[[\"@save\",\"@clone\",\"@cancel\",\"@ctrl\"],[[32,0,[\"save\"]],[32,0,[\"saveAndClone\"]],[30,[36,2],[\"goBack\"],null],[32,0]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\",\"route-action\",\"or\"]}",
    "meta": {
      "moduleName": "plutof/templates/livingspecimen/add.hbs"
    }
  });
});