define("plutof/templates/components/validation/split-context", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "d71NVJ4k",
    "block": "{\"symbols\":[\"vpair\",\"index\",\"&default\"],\"statements\":[[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,0,[\"validationPairs\"]]],null]],null]],[[\"key\"],[[32,0,[\"key\"]]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[18,3,[[32,1,[\"object\"]],[32,1,[\"context\"]],[32,2]]],[2,\"\\n\"]],\"parameters\":[1,2]}]]]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/validation/split-context.hbs"
    }
  });
});