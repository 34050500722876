define("plutof/transforms/enum-multiple", ["exports", "@ember-data/serializer/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Not much transforming going here, just a type marker, like enum
  class MultipleValuedEnumTransform extends _transform.default {
    deserialize(list) {
      return list || [];
    }
    serialize(list) {
      return list || [];
    }
  }
  var _default = _exports.default = MultipleValuedEnumTransform;
});