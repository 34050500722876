define("plutof/translations/est/experiment", ["exports", "plutof/translations/helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    laboratory: {
      laboratory: 'Laboratoorium',
      intro: {
        title: 'Laboratooriumid',
        description: 'Kasutaja laboratooriumid (ligipääs läbi laboratooriumit haldava töörühma)'
      }
    },
    extraction: {
      intro: {
        title: 'DNA eraldused',
        description: 'DNA eralduste nimekiri',
        enter: 'DNA eraldused'
      },
      routeTitles: {
        list: 'DNA eraldused',
        add: 'Uus DNA eraldus',
        edit: 'Muuda DNA eraldust',
        view: 'DNA eraldus'
      },
      general: {
        source: 'Source'
      },
      errors: {
        dnaMissingPermissions: 'Mõned DNA kirjed jäid uuendamata, sest puudusid muutmisõigused'
      },
      actions: {
        sendToNewExperiment: {
          label: 'Uus PCR ({{count}})',
          tooltip: 'Loo uus PCR reaktsioon valitud materjalidega.'
        },
        sendToExistingExperiment: {
          label: 'Lisa PCR-ile ({{count}})',
          tooltip: 'Lisa valitud materjalid olemasolevale PCR reaktsioonile.'
        },
        print: {
          label: 'Prindi',
          tooltip: 'Ava paneeli sisu prinditav vaade.'
        },
        add: {
          label: 'Lisa {{count}}'
        }
      }
    },
    pcr: {
      intro: {
        title: 'PCR',
        description: 'PCR reaktsioonide nimekiri',
        enter: 'PCR'
      },
      routeTitles: {
        list: 'PCR reaktsioonid',
        add: 'Uus PCR',
        edit: 'Muuda PCR-i',
        view: 'PCR'
      },
      general: {
        pcr: 'PCR'
      },
      actions: {
        sendToNewExperiment: {
          label: 'Uus DNA järjendamine ({{count}})',
          tooltip: 'Loo uus DNA järjestamine valitud materjalidega.'
        },
        sendToExistingExperiment: {
          label: 'Add to DNA Sequencing ({{count}})',
          tooltip: 'Lisa valitud materjalid olemasolevale DNA järjestamisele.'
        }
      },
      fields: {
        annealingTime: 'Annealing time (mm:ss)',
        denaturationTime: 'Denaturation time (mm:ss)',
        extensionTime: 'Extension time (mm:ss)'
      }
    },
    sequencing: {
      intro: {
        title: 'DNA järjendamine',
        description: 'DNA järjendamiste nimekiri',
        enter: 'DNA järjendamine'
      },
      routeTitles: {
        list: 'DNA järjendamine',
        add: 'New DNA järjendamine',
        edit: 'Edit DNA järjendamine',
        view: 'DNA järjendamine'
      },
      general: {
        sequencing: 'DNA järjendamine'
      },
      actions: {
        parseSourceFile: {
          label: 'Parse source file',
          result: '{{count}} faili seotud'
        },
        copyPCRText: {
          label: 'Kopeeri',
          tooltip: 'Kopeeri PCR tulba tekst süsteemi näpitstahvlile'
        }
      }
    },
    dna: {
      intro: {
        title: 'Eraldatud DNA',
        description: 'Nimekiri eraldatud DNA-dest',
        enter: 'DNA'
      },
      routeTitles: {
        list: 'Eraldatud DNA',
        add: 'Uus eraldatud DNA',
        edit: 'Muuda eraldatud DNA-d',
        view: 'Eraldatud DNA'
      },
      general: {
        dna: 'Eraldatud DNA'
      }
    },
    general: {
      experiments: 'DNA eksperimendid',
      labware: 'Labware',
      materialSource: 'Material source',
      tube: 'Tuub'
    },
    buttons: {
      addAllMaterials: 'Kõik {{count}}',
      toggleBulkUpdate: (0, _helpers.alias)('General.tableBulkUpdate.toggle'),
      fillPrimerTags: 'Autofill primer tags',
      orientation: {
        vertical: 'Vertikaalne',
        horizontal: 'Horisontaalne'
      }
    },
    panels: {
      extractions: 'DNA eraldused',
      pcrs: 'PCR reaktsioonid',
      pcrProducts: 'PCR produktid',
      sequencings: 'DNA järjendamine',
      sequencingResults: 'Järjendatud DNA',
      workgroup: 'Haldav töörühm',
      labware: (0, _helpers.alias)('experiment.general.labware'),
      material: 'Materjal',
      dnas: 'Eraldatud DNA',
      materialSamples: 'Proovid'
    }
  };
});