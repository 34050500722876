define("plutof/translations/est/gallery", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    title: 'Pealkiri',
    licenceType: 'Litsentsi tüüp',
    rightsOwner: 'Autoriõiguste omanik',
    rightsStatement: 'Autoriõiguste märge',
    rightsHolder: 'Autoriõiguste valdaja',
    credit: 'Tunnustus',
    creators: 'Faili loojad',
    openInNewTab: 'Ava uues aknas',
    openInFileRepo: 'Ava failide repositooriumis',
    download: 'Laadi originaal fail alla',
    openOnMap: 'Ava kaardil',
    owner: 'Omanik',
    noMapData: 'Pole geo infot, mida kaardil kuvada.',
    enterFullscreen: 'Täisekraan'
  };
});