define("plutof/components/livingspecimen/strain-form", ["exports", "ember-data", "@ember-decorators/component", "ember-concurrency", "ember-cp-validations", "plutof/mixins/component-validations", "plutof/utils/store"], function (_exports, _emberData, _component, _emberConcurrency, _emberCpValidations, _componentValidations, _store) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const Validations = (0, _emberCpValidations.buildValidations)({
    'strain.strain_idprim': [(0, _emberCpValidations.validator)('present-not-blank'), (0, _emberCpValidations.validator)('length', {
      max: 63
    })],
    'strain.subcode': (0, _emberCpValidations.validator)('length', {
      max: 15
    }),
    'strain.remarks': (0, _emberCpValidations.validator)('length', {
      max: 2048
    }),
    'identifierIsUnique': (0, _emberCpValidations.validator)('is-truthy'),
    'idprimIsValid': (0, _emberCpValidations.validator)('is-truthy')
  });
  let StrainForm = (_dec = (0, _component.classNames)('strain-form'), _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember.computed.and('identifierIsUnique', 'validations.attrs.strain.strain_idprim.isValid'), _dec5 = Ember.computed.and('identifierIsUnique', 'validations.attrs.strain.subcode.isValid'), _dec6 = (0, _emberConcurrency.task)({
    restartable: true
  }), _dec7 = (0, _emberConcurrency.task)({
    restartable: true
  }), _dec8 = Ember.computed('checkIdentifierUniqueness.{isRunning,last.value}'), _dec9 = Ember.computed('strain.deposited_in.id'), _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec(_class = (_class2 = class StrainForm extends Ember.Component.extend(Validations, _componentValidations.default) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "duplicatesDialogOpen", _descriptor2, this);
      _initializerDefineProperty(this, "idprimIsValid", _descriptor3, this);
      _initializerDefineProperty(this, "subcodeIsValid", _descriptor4, this);
      _initializerDefineProperty(this, "setupCollectionPrefix", _descriptor5, this);
      _initializerDefineProperty(this, "checkIdentifierUniqueness", _descriptor6, this);
    }
    // XXX: Most of the stuff below is almost-exact copy from Specimen::GeneralData
    // I tried to reuse that and it went messy really quickly, so it was decided to
    // stop wasting time one that and just copy-paste-change the logic from there.
    // There are some possibilities, but investigating them just seems not worthy atm.
    //
    // You could also extract tasks from here, but the results is abstraction for
    // the sake of it, resulting in a mess that fails the naming test
    //
    // Some day, collection prefixes, some day
    didReceiveAttrs() {
      this.setupCollectionPrefix.perform();
    }
    get identifierIsUnique() {
      return this.checkIdentifierUniqueness.isRunning || this.checkIdentifierUniqueness.performCount === 0 || this.checkIdentifierUniqueness.last.value;
    }
    get subrepositories() {
      const collectionID = this.strain.get('deposited_in.id');
      if (!collectionID) {
        return [];
      }
      return _emberData.default.PromiseArray.create({
        promise: (0, _store.query)(this.store, 'agent/repository', {
          parent_organization: collectionID
        })
      });
    }
    updatePrimaryIdentifier(idprim) {
      this.strain.set('strain_idprim', idprim);
      this.checkIdentifierUniqueness.perform();
    }
    updateCollection(collection) {
      this.strain.setProperties({
        deposited_in: collection,
        sub_repository: null
      });
      if (!this.collectionPrefixApplied && collection && collection.specimen_identifier_format_prefix) {
        // If collection is selected for the first time, use its prefix
        this.updatePrimaryIdentifier(`${collection.specimen_identifier_format_prefix}${this.strain.strain_idprim || ''}`);
        this.collectionPrefixApplied = true;
      } else {
        this.checkIdentifierUniqueness.perform();
      }
    }
    openDuplicatesDialog() {
      this.duplicatesDialogOpen = true;
    }
    closeDuplicatesDialog() {
      this.duplicatesDialogOpen = false;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "duplicatesDialogOpen", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "idprimIsValid", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "subcodeIsValid", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "setupCollectionPrefix", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        const collection = yield this.strain.deposited_in;
        this.collectionPrefixApplied = collection && this.strain.strain_idprim;

        // If collection is there, but ID is missing, collection came from settings or route etc and
        // we still need to add the prefix
        if (collection && !this.strain.strain_idprim) {
          this.updateCollection(collection);
        }
      };
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "checkIdentifierUniqueness", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        const collection = yield this.strain.deposited_in;
        if (!collection || !this.strain.strain_idprim) {
          // Specimens outside collections don't have to be unique
          return true;
        }
        yield (0, _emberConcurrency.timeout)(400);
        const query = {
          deposited_in: collection.id,
          object_idprim: this.strain.strain_idprim
        };
        const response = yield this.ajax.request('/taxonoccurrence/livingculture/strains/is_unique/', {
          data: query
        });
        if (response.is_unique) {
          return true;
        }

        // The specimen itself doesn't count as a duplicate
        return !this.strain.isNew && response.duplicate_object_id === parseInt(this.strain.id);
      };
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "identifierIsUnique", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "identifierIsUnique"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "subrepositories", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "subrepositories"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "updatePrimaryIdentifier", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "updatePrimaryIdentifier"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "updateCollection", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "updateCollection"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "openDuplicatesDialog", [_dec12], Object.getOwnPropertyDescriptor(_class2.prototype, "openDuplicatesDialog"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "closeDuplicatesDialog", [_dec13], Object.getOwnPropertyDescriptor(_class2.prototype, "closeDuplicatesDialog"), _class2.prototype)), _class2)) || _class);
  var _default = _exports.default = StrainForm;
});