define("plutof/transforms/-marker", ["exports", "@ember-data/serializer/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // @attr type explicitly for adding metadata to attributes
  class MarkerTransform extends _transform.default {
    deserialize(value) {
      return value;
    }
    serialize(value) {
      return value;
    }
  }
  var _default = _exports.default = MarkerTransform;
});