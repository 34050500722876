define("plutof/adapters/study/dmp", ["exports", "plutof/adapters/jsonapi-base"], function (_exports, _jsonapiBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _jsonapiBase.default.extend({
    modelName: 'study/dmp'
  });
});