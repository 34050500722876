define("plutof/components/search/filter-panels/annotation/record-properties", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "annotation.routes.search.recordProperties"}}
      @collapse={{true}}
  >
      <Layout::RegularGrid @columns={{4}}>
          <div>
              {{common/local-label "annotation.routes.search.contentType"}}
  
              <MultipleSelection
                  @content={{@filterInputs.content_type.choices}}
                  @selections={{@filterInputs.content_type.value}}
                  @prompt="---"
                  @optionValuePath="value"
                  @optionLabelPath="label" />
          </div>
  
          <Search::FilterInput @filter={{@filterInputs.sequence_unite_status}} />
      </Layout::RegularGrid>
  
      <Layout::SeparatedRow>
          <Search::FilterInput @filter={{@filterInputs.sequence_insd}} />
      </Layout::SeparatedRow>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "mLxKRfct",
    "block": "{\"symbols\":[\"@filterInputs\"],\"statements\":[[8,\"plutof-panel/simple\",[],[[\"@title\",\"@collapse\"],[[30,[36,0],[\"annotation.routes.search.recordProperties\"],null],true]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"layout/regular-grid\",[],[[\"@columns\"],[4]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"div\"],[12],[2,\"\\n            \"],[1,[30,[36,1],[\"annotation.routes.search.contentType\"],null]],[2,\"\\n\\n            \"],[8,\"multiple-selection\",[],[[\"@content\",\"@selections\",\"@prompt\",\"@optionValuePath\",\"@optionLabelPath\"],[[32,1,[\"content_type\",\"choices\"]],[32,1,[\"content_type\",\"value\"]],\"---\",\"value\",\"label\"]],null],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"sequence_unite_status\"]]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,\"layout/separated-row\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"sequence_insd\"]]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"common/local-label\"]}",
    "meta": {
      "moduleName": "plutof/components/search/filter-panels/annotation/record-properties.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});