define("plutof/components/unite/determinations/view-multiple", ["exports", "plutof/utils/has-many"], function (_exports, _hasMany) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const HasManyPagination = (0, _hasMany.HasManyViewMixin)('globalkey/determination', {
    parentField: 'sequence',
    extraFilters: {
      ordering: '-updated_at'
    }
  });
  class ViewMultiple extends Ember.Component.extend(HasManyPagination) {}
  var _default = _exports.default = ViewMultiple;
});