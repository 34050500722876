define("plutof/components/agent/organization/edit", ["exports", "@glimmer/component", "plutof/components/agent/organization-workgroup", "plutof/components/agent/organization/associations/edit", "plutof/controllers/organization/edit", "plutof/utils/reflection", "plutof/utils/model"], function (_exports, _component, _organizationWorkgroup, _edit, _edit2, _reflection, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createOrganizationModel = createOrganizationModel;
  _exports.default = void 0;
  _exports.loadOrganizationModel = loadOrganizationModel;
  var _dec, _class, _dec2, _class2, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::Simple @title={{i18n-t "General.generalData"}}>
      <Agent::OrganizationForm
          @organization={{@model.organization}}
          @setLogo={{@model.setLogo}}
          @validationContext={{@validationContext.organization}} />
  </PlutofPanel::Simple>
  
  <Agent::OrganizationAdditionalData
      @organization={{@model.organization}}
      @validationContext={{@validationContext.additionalData}} />
  
  {{#if @model.managingGroup}}
      <Agent::OrganizationWorkgroup
          @workgroupData={{@model.managingGroup}}
          @validationContext={{@validationContext.managingGroup}} />
  {{/if}}
  
  {{#if @model.associations}}
      <Agent::Organization::Associations::Edit
          @model={{@model.associations}}
          @validationContext={{@validationContext.associations}} />
  {{/if}}
  
  */
  {
    "id": "Z+5zlI2A",
    "block": "{\"symbols\":[\"@model\",\"@validationContext\"],\"statements\":[[8,\"plutof-panel/simple\",[],[[\"@title\"],[[30,[36,0],[\"General.generalData\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"agent/organization-form\",[],[[\"@organization\",\"@setLogo\",\"@validationContext\"],[[32,1,[\"organization\"]],[32,1,[\"setLogo\"]],[32,2,[\"organization\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,\"agent/organization-additional-data\",[],[[\"@organization\",\"@validationContext\"],[[32,1,[\"organization\"]],[32,2,[\"additionalData\"]]]],null],[2,\"\\n\\n\"],[6,[37,1],[[32,1,[\"managingGroup\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"agent/organization-workgroup\",[],[[\"@workgroupData\",\"@validationContext\"],[[32,1,[\"managingGroup\"]],[32,2,[\"managingGroup\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[32,1,[\"associations\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"agent/organization/associations/edit\",[],[[\"@model\",\"@validationContext\"],[[32,1,[\"associations\"]],[32,2,[\"associations\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"if\"]}",
    "meta": {
      "moduleName": "plutof/components/agent/organization/edit.hbs"
    }
  });
  let OrganizationModel = (_dec = Ember._action, (_class = class OrganizationModel {
    constructor({
      organization,
      managingGroup,
      associations
    }) {
      _defineProperty(this, "newLogo", null);
      this.organization = organization;
      this.managingGroup = managingGroup;
      this.associations = associations;
    }
    setLogo(image) {
      this.organization.set('logo_url', null);
      this.newLogo = image;
    }
    async save() {
      if (this.newLogo) {
        const fileUpload = (0, _reflection.getService)(this.organization.store, 'file-upload');
        const logoURL = await (0, _edit2.uploadLogo)(this.organization.store, fileUpload, this.newLogo);
        this.organization.set('logo_url', logoURL);
      }
      const address = await this.organization.address;
      await address.save();
      await this.organization.save();
      if (this.managingGroup) {
        await this.managingGroup.save();
      }
      if (this.associations) {
        await this.associations.save();
      }
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "setLogo", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "setLogo"), _class.prototype)), _class));
  function createOrganizationModel(organization) {
    return new OrganizationModel({
      organization
    });
  }
  async function loadOrganizationModel(organization) {
    let managingGroup = null;
    let associations = null;
    const managingGroupID = (0, _model.relationID)(organization, 'managing_group');
    if (managingGroupID) {
      managingGroup = await (0, _organizationWorkgroup.loadWorkgroupData)(organization.store, managingGroupID);
      associations = await (0, _edit.loadOrganizationAssociationsModel)(organization);
    }
    return new OrganizationModel({
      organization,
      managingGroup,
      associations
    });
  }
  let OrganizationEdit = (_dec2 = Ember.inject.service, (_class2 = class OrganizationEdit extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "i18n", _descriptor, this);
      this.args.validationContext.addChild('organization', this.i18n.translate('Agents.organization.organization'));
      this.args.validationContext.addChild('additionalData', this.i18n.translate('Agents.additionalData'));
      this.args.validationContext.addChild('associations', this.i18n.translate('Agents.associations.persons'));
      this.args.validationContext.addChild('managingGroup', this.i18n.translate('group.panels.workgroup'));
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "i18n", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2));
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, OrganizationEdit);
});