define("plutof/translations/eng/data-quality", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    warnings: {
      taxonRank: 'Taxon is not determined to species rank.',
      geom: 'Missing coordinates.',
      timespanAfterNow: 'Timespan begin ({{date}}) is set to the future.',
      timespanAfterCreated: 'The record has been entered ({{createdAt}}) before set timespan begin date ({{timespanBegin}}).',
      timespanMissing: 'Timespan begin is not set.',
      gatheringAgents: 'No collectors have been set.',
      country: 'Country is unspecified.',
      individualCount: 'Unusually large count ({{count}}).'
    },
    general: {
      count: 'Count'
    }
  };
});