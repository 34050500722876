define("plutof/translations/est/agents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    personsTitle: 'Isikud',
    personsSearchTitle: 'Otsi isikuid',
    personsInfoTitle: 'Isikud',
    orgTitle: 'Asutused',
    // XXX die
    orgSearchTitle: 'Otsi asutusi',
    orgInfoTitle: 'Asutused',
    associatedOrg: 'Seotud asutused',
    personAddress: 'Isiku aadress selles asutuses',
    personAdd: 'lisada isikuid ja siduda neid asutustega.',
    // In this section you can ...
    personView: 'otsida ja vaadata juba sisestatud isikuid.',
    // In this section you can ...
    additionalData: 'Lisaandmed',
    newPerson: 'Uus isik',
    editPerson: 'Muuda isiku andmeid',
    newOrganization: 'Uus asutus',
    editOrganization: 'Muuda asutuse andmeid',
    address: 'Aadress',
    findByModule: 'Otsi mooduli järgi',
    personTooltip: '<span class="icon-user"></span> - objektil on kasutaja. <br>' + '<span class="icon-person"></span> - nimi on umbmäärane, kasutatud organisatsioonide või gruppide puhul. <br>' + '<i class="person-valid-name"> jsmith </i> - loodud selle kasutaja poolt. <br>' + '<i>J.Smith</i> (John Smith) - John Smith on <i>J.Smithi</i> kehtiv nimi.',
    agentTooltip: '<span class="icon-user"></span> - objektil on kasutaja. <br>' + '<span class="icon-person"></span> - nimi on umbmäärane, kasutatud organisatsioonide või gruppide puhul. <br>' + '<span class="fas fa-archive"></span> - see asutus viitab kogule (nt herbaarium, fungaarium, entomoloogiline kogu, jne.).' + '<i class="person-valid-name"> jsmith </i> - loodud selle kasutaja poolt. <br>' + '<i>J.Smith</i> (John Smith) - John Smith on <i>J.Smithi</i> kehtiv nimi.',
    organizationTooltip: '<span class="fas fa-archive"></span> - see asutus viitab kogule (nt herbaarium, fungaarium, entomoloogiline kogu, jne.).',
    personDetails: 'Isiku andmed',
    alternateText: 'Sama isiku alternatiivne nimi asub sulgudes.',
    validNameTooltip: 'Kehtiv nimi võimaldab omavahel siduda ühe isiku ja/või asutuse erinevaid nimekujusid (nt. isiku A. Smith kehtiv nimi on Adrian Smith). Kehtiv nimi kuvatakse üle süsteemi vastava nimekuju järel sulgudes.',
    acronymTooltip: 'Vältimaks korduvaid akronüüme soovitame enne uue loomist kontrollida https://www.gbif.org/grscicoll või PlutoF platvormi.',
    selectLogo: 'Laadi logo üles',
    selectedLogo: 'Valitud logo',
    logoTooltip: 'Asutuse/kogu logo. Logo lisatakse prinditud kogude transaktsioonidele.',
    acronymClashWarning: {
      'agent/organization': 'NB! Süsteem leidis sarnase(d) organisatsiooni(d).',
      'agent/collection': 'NB! Süsteem leidis sarnase(d) kogu(d).'
    },
    person: {
      person: 'Isik',
      synonyms: 'Sünonüümid'
    },
    organization: {
      organization: 'Asutus',
      fields: {
        fullName: 'Täisnimi',
        name: 'Nimi'
      },
      panels: {
        collections: 'Kogud',
        suborganizations: 'Alamorganisatsioonid'
      }
    },
    placeholders: {
      personsSearchHint: 'Otsi nime järgi',
      orgSearchHint: 'Otsi nime järgi'
    },
    associations: {
      persons: 'Liikmed',
      showAll: 'Kuva kõik',
      showChanged: 'Kuva muudetud'
    }
  };
});