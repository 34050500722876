define("plutof/routes/referencebased/edit", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "plutof/components/layer/occurrence-areas", "plutof/components/linked-items/edit", "plutof/mixins/route", "plutof/utils/access", "plutof/utils/model", "plutof/utils/validations"], function (_exports, _authenticatedRouteMixin, _occurrenceAreas, _edit, _route, _access, _model, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let RefbasedEditRoute = (_dec = Ember.inject.service, (_class = class RefbasedEditRoute extends Ember.Route.extend(_authenticatedRouteMixin.default, _route.default, _validations.ValidatedRoute) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _defineProperty(this, "defaultReturnRoute", 'referencebased.index');
      _defineProperty(this, "confirmExit", true);
      _defineProperty(this, "modelTitle", 'occurrence');
    }
    async model(params) {
      const refbased = await this.store.findRecord('taxonoccurrence/referencebased/occurrence', params.id);
      const samplingData = await (0, _model.getOccurrenceSamplingData)(this.store, refbased).then(async sdata => {
        const project = await refbased.project;
        sdata.formData.project = project;
        sdata.formData.set('canEditEvent', true);
        return sdata;
      });
      return Ember.RSVP.hash({
        occurrence: refbased,
        speciesStatus: refbased.species_status.then(status => {
          if (Ember.isNone(status)) {
            status = this.store.createRecord('taxonoccurrence/referencebased/speciesstatus');
            refbased.set('species_status', status);
          }
          return status;
        }),
        areaEventData: samplingData.formData,
        accessRights: (0, _access.get_permissions)(this.store, this.ajax, refbased),
        layers: _occurrenceAreas.LinkedLayersModel.load(this.ajax, refbased),
        itemData: (0, _edit.loadLinkedItemsData)(this.store, refbased, {
          useEventFiles: true,
          disabledTabs: ['references', 'permits']
        })
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  var _default = _exports.default = RefbasedEditRoute;
});