define("plutof/components/common/search-query-input", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SearchQueryInputController = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="input-group">
      <Input
          @type="text"
          @value={{this.query}}
          @enter={{this.activate}}
          @disabled={{this.disabled}}
          @placeholder={{or @placeholder (i18n-t "General.Search")}}
          class="form-control" />
  
      <span class="input-group-btn">
          <button
              {{on "click" this.activate}}
              disabled={{this.disabled}}
              class="btn btn-default"
          >
              {{#if @searching}}
                  <span class="loading-icon"></span>
              {{else if (and @clearable this.sameQuery)}}
                  <span class={{icon "remove"}}></span>
              {{else}}
                  <span class={{icon "search"}}></span>
              {{/if}}
          </button>
      </span>
  </div>
  
  */
  {
    "id": "PCPA4KR2",
    "block": "{\"symbols\":[\"@clearable\",\"@placeholder\",\"@searching\"],\"statements\":[[10,\"div\"],[14,0,\"input-group\"],[12],[2,\"\\n    \"],[8,\"input\",[[24,0,\"form-control\"]],[[\"@type\",\"@value\",\"@enter\",\"@disabled\",\"@placeholder\"],[\"text\",[32,0,[\"query\"]],[32,0,[\"activate\"]],[32,0,[\"disabled\"]],[30,[36,4],[[32,2],[30,[36,3],[\"General.Search\"],null]],null]]],null],[2,\"\\n\\n    \"],[10,\"span\"],[14,0,\"input-group-btn\"],[12],[2,\"\\n        \"],[11,\"button\"],[16,\"disabled\",[32,0,[\"disabled\"]]],[24,0,\"btn btn-default\"],[4,[38,5],[\"click\",[32,0,[\"activate\"]]],null],[12],[2,\"\\n\"],[6,[37,2],[[32,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"                \"],[10,\"span\"],[14,0,\"loading-icon\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[30,[36,1],[[32,1],[32,0,[\"sameQuery\"]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"                \"],[10,\"span\"],[15,0,[30,[36,0],[\"remove\"],null]],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"                \"],[10,\"span\"],[15,0,[30,[36,0],[\"search\"],null]],[12],[13],[2,\"\\n            \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"and\",\"if\",\"i18n-t\",\"or\",\"on\"]}",
    "meta": {
      "moduleName": "plutof/components/common/search-query-input.hbs"
    }
  });
  // Have to be able to call clear() outside the component
  class SearchQueryInputController {
    attach(component) {
      this.component = component;
    }
    detach() {
      this.component = null;
    }
    clear() {
      if (this.component) {
        this.component.clear();
      } else {
        throw new Error('clear() called on a detached SearchQueryInputController');
      }
    }
  }
  _exports.SearchQueryInputController = SearchQueryInputController;
  let SearchQueryInput = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, (_class = class SearchQueryInput extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "query", _descriptor, this);
      _initializerDefineProperty(this, "lastQuery", _descriptor2, this);
      if (this.args.controller) {
        this.args.controller.attach(this);
      }
    }
    willDestroy(...args) {
      super.willDestroy(...args);
      if (this.args.controller) {
        this.args.controller.detach(this);
      }
    }
    get sameQuery() {
      const normalized = this.query.trim();
      return normalized.length > 0 && normalized === this.lastQuery;
    }
    get disabled() {
      return this.args.disabled || this.args.searching;
    }
    clear() {
      this.query = '';
      this.lastQuery = undefined;
      if (this.args.clear) {
        this.args.clear();
      } else {
        this.args.search(this.query);
      }
    }
    activate() {
      if (this.args.clearable && this.sameQuery) {
        this.clear();
      } else {
        this.args.search(this.query);
        this.lastQuery = this.query;
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "query", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "lastQuery", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "activate", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "activate"), _class.prototype)), _class));
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SearchQueryInput);
});