define("plutof/helpers/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // XXX: Ember has one now, IIRC
  function list(args) {
    return args;
  }
  var _default = _exports.default = Ember.Helper.helper(list);
});