define("plutof/components/tables/-bulk-update/update-button", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  let TableBulkUpdateButton = (_dec = (0, _component.tagName)('button'), _dec2 = (0, _component.classNames)('btn btn-default plutof-btn-green form-control'), _dec(_class = _dec2(_class = class TableBulkUpdateButton extends Ember.Component {
    click() {
      this.update();
    }
  }) || _class) || _class);
  var _default = _exports.default = TableBulkUpdateButton;
});