define("plutof/controllers/clipboard/utils/bulk-update", ["exports", "plutof/utils/i18n"], function (_exports, _i18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getBulkUpdatePanels = getBulkUpdatePanels;
  const i18n = (0, _i18n.getI18n)();
  const BULK_UPDATE_PANELS = {
    'samplingevents': {
      endpoint: 'bulk-update/samplingevents/',
      label: i18n.t('General.samplingEvent')
    },
    'samplingareas': {
      endpoint: 'bulk-update/samplingareas/',
      label: i18n.t('General.samplingArea')
    },
    'specimens': {
      endpoint: 'bulk-update/specimens/',
      label: i18n.t('General.generalData')
    },
    'livingcultures': {
      endpoint: 'bulk-update/livingcultures/',
      label: i18n.t('General.generalData')
    },
    'observations': {
      endpoint: 'bulk-update/observations/',
      label: i18n.t('General.generalData')
    },
    'photobanks': {
      endpoint: 'bulk-update/photobanks/',
      label: i18n.t('General.generalData')
    },
    'sequences': {
      endpoint: 'bulk-update/sequences/',
      label: i18n.t('General.generalData')
    },
    'sequences-access': {
      endpoint: 'access/bulk-update/',
      label: i18n.t('General.access')
    },
    'reference-based': {
      endpoint: 'bulk-update/reference-based/',
      label: i18n.t('General.generalData')
    },
    'access': {
      endpoint: 'access/bulk-update/',
      label: i18n.t('General.access')
    },
    'specimen-preparations': {
      endpoint: 'bulk-update/specimens/',
      label: i18n.t('General.preparations')
    },
    'livingculture-preparations': {
      endpoint: 'bulk-update/livingcultures/',
      label: i18n.t('General.preparations')
    },
    'condition-assessments': {
      endpoint: 'bulk-update/condition-assessments/',
      label: i18n.t('Clipboard.bulkUpdate.panels.conditionAssessments')
    },
    'materialsamples': {
      endpoint: 'bulk-update/materialsamples/',
      label: i18n.t('General.generalData')
    }
  };
  function getBulkUpdatePanels(keys) {
    return keys.map(key => {
      const config = BULK_UPDATE_PANELS[key];
      Ember.set(config, 'name', key);
      return config;
    });
  }
});