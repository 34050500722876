define("plutof/models/globalkey/shtaxonomytree", ["exports", "ember-data", "@ember-data/model", "plutof/models/plutof-model", "plutof/utils/promises"], function (_exports, _emberData, _model, _plutofModel, _promises) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let SHTaxonomyTree = (_dec = (0, _model.belongsTo)('globalkey/dshcluster'), _dec2 = (0, _model.belongsTo)('globalkey/version'), _dec3 = (0, _model.belongsTo)('globalkey/threshold'), _dec4 = (0, _model.belongsTo)('taxonomy/taxonnode'), _dec5 = (0, _model.belongsTo)('taxonomy/taxonnode'), _dec6 = (0, _model.belongsTo)('taxonomy/taxonnode'), _dec7 = (0, _model.belongsTo)('taxonomy/taxonnode'), _dec8 = (0, _model.belongsTo)('taxonomy/taxonnode'), _dec9 = (0, _model.belongsTo)('taxonomy/taxonnode'), _dec10 = (0, _model.belongsTo)('taxonomy/taxonnode'), _dec11 = (0, _model.belongsTo)('taxonoccurrence/sequence/sequence'), _dec12 = (0, _model.belongsTo)('taxonoccurrence/sequence/sequence'), _dec13 = (0, _model.belongsTo)('taxonomy/taxonnode'), _dec14 = (0, _model.belongsTo)('taxonomy/taxonnode'), _dec15 = (0, _model.attr)('number'), _dec16 = (0, _model.attr)('number'), _dec17 = (0, _model.attr)('number'), _dec18 = (0, _model.attr)('number'), _dec19 = (0, _model.attr)('boolean'), _dec20 = (0, _model.attr)('boolean'), _dec21 = (0, _model.attr)('boolean'), _dec22 = Ember.computed('dsh_kingom', 'dsh_phylum', 'dsh_class', 'dsh_order', 'dsh_family', 'dsh_genus', 'dsh_species'), (_class = class SHTaxonomyTree extends _plutofModel.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "dshcluster", _descriptor, this);
      _initializerDefineProperty(this, "version", _descriptor2, this);
      _initializerDefineProperty(this, "threshold", _descriptor3, this);
      _initializerDefineProperty(this, "dsh_kingdom", _descriptor4, this);
      _initializerDefineProperty(this, "dsh_phylum", _descriptor5, this);
      _initializerDefineProperty(this, "dsh_class", _descriptor6, this);
      _initializerDefineProperty(this, "dsh_order", _descriptor7, this);
      _initializerDefineProperty(this, "dsh_family", _descriptor8, this);
      _initializerDefineProperty(this, "dsh_genus", _descriptor9, this);
      _initializerDefineProperty(this, "dsh_species", _descriptor10, this);
      _initializerDefineProperty(this, "err1_sequence", _descriptor11, this);
      _initializerDefineProperty(this, "err2_sequence", _descriptor12, this);
      _initializerDefineProperty(this, "err1_taxon", _descriptor13, this);
      _initializerDefineProperty(this, "err2_taxon", _descriptor14, this);
      _initializerDefineProperty(this, "no_sequences", _descriptor15, this);
      _initializerDefineProperty(this, "no_specimens", _descriptor16, this);
      _initializerDefineProperty(this, "no_strains", _descriptor17, this);
      _initializerDefineProperty(this, "no_taxon_names", _descriptor18, this);
      _initializerDefineProperty(this, "is_identified", _descriptor19, this);
      _initializerDefineProperty(this, "is_updated", _descriptor20, this);
      _initializerDefineProperty(this, "is_singleton", _descriptor21, this);
    }
    get mostSpecificTaxon() {
      const levels = ['species', 'genus', 'family', 'order', 'class', 'phylum', 'kingdom'];
      return _emberData.default.PromiseObject.create({
        promise: _promises.default.find(levels, level => {
          return this.get(`dsh_${level}`).then(!Ember.isNone);
        }).then(level => {
          return this.get(`dsh_${level}`);
        })
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "dshcluster", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "version", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "threshold", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "dsh_kingdom", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "dsh_phylum", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "dsh_class", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "dsh_order", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "dsh_family", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "dsh_genus", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "dsh_species", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "err1_sequence", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "err2_sequence", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "err1_taxon", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "err2_taxon", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "no_sequences", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "no_specimens", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "no_strains", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "no_taxon_names", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "is_identified", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "is_updated", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "is_singleton", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "mostSpecificTaxon", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "mostSpecificTaxon"), _class.prototype)), _class));
  var _default = _exports.default = SHTaxonomyTree;
});