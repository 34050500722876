define("plutof/translations/eng/material-samples", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    searchTitle: 'Search Material Samples',
    infoTitle: 'Material Samples',
    title: 'Material Samples',
    newSample: 'New Material Sample',
    editSample: 'Edit Material Sample',
    viewSample: 'Material Sample',
    name: 'Material sample ID',
    type: 'Type',
    size: 'Size',
    description: 'Description',
    depositedIn: 'Deposited In',
    subRepository: 'Subrepository',
    cloneToolt: 'Copy this row to add record of the same material sample with different measurements.',
    saveProgress: 'Saved {{finished}} out of {{total}} material samples',
    placeholders: {
      searchHint: 'Search by name'
    }
  };
});