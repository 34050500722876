define("plutof/templates/publishing/gbif/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "cvVIBLF+",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"route-marker-publishing-edit\"],[12],[13],[2,\"\\n\\n\"],[8,\"navbar/edit\",[],[[\"@icon\",\"@title\",\"@record\",\"@infoContent\"],[[30,[36,1],[\"gbif\"],null],[30,[36,0],[\"dataResource.general.editDataset\"],null],[32,0,[\"model\",\"dataset\"]],\"information/content/publishing/gbif/general\"]],null],[2,\"\\n\\n\"],[6,[37,2],[[32,0,[\"model\",\"dataset\",\"is_deleted\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"alert alert--warning alert--in-page\"],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"alert__icon fas fa-exclamation-triangle\"],[12],[13],[2,\"\\n\\n        \"],[1,[30,[36,0],[\"dataResource.general.deleted\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,\"publishing/gbif/edit-form\",[],[[\"@dataset\",\"@sequences\",\"@specimens\",\"@observations\",\"@referencebased\",\"@agents\",\"@rights\",\"@validationContext\",\"@cancel\",\"@save\",\"@setLogo\"],[[32,0,[\"model\",\"dataset\"]],[32,0,[\"model\",\"sequences\"]],[32,0,[\"model\",\"specimens\"]],[32,0,[\"model\",\"observations\"]],[32,0,[\"model\",\"referencebased\"]],[32,0,[\"model\",\"agents\"]],[32,0,[\"model\",\"rights\"]],[32,0,[\"validationContext\"]],[30,[36,3],[[32,0],\"cancel\"],null],[32,0,[\"saveDataset\"]],[32,0,[\"setLogo\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"icon\",\"if\",\"action\"]}",
    "meta": {
      "moduleName": "plutof/templates/publishing/gbif/edit.hbs"
    }
  });
});