define("plutof/translations/est/reference-based", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    refBasedTaxonOc: 'Kirjanduspõhine taksoni esinemine',
    bioStatus: 'Biostaatus',
    newRefBased: 'Uus kirjanduspõhine taksoni esinemine',
    editRefBased: 'Muuda kirjanduspõhised taksoni esinemisi',
    title: 'Kirjanduspõhised taksoni esinemised',
    searchTitle: 'Otsi kirjanduspõhised taksoni esinemisi',
    infoTitle: 'Kirjanduspõhised taksoni esinemised',
    referenceB: 'Kirjanduspõhised',
    nameTitle: 'Taksoni nimi',
    nameTitleLowerC: 'taksoni nimi',
    addPanel: {
      title: 'Esinemised'
    },
    placeholders: {
      searchHint: 'Otsi taksoninime järgi'
    }
  };
});