define("plutof/components/transaction/-list-route-end-date", ["exports", "@glimmer/component", "moment"], function (_exports, _component, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class={{if this.warn "list-highlight"}}>
      {{formatted-date @transaction.expected_end_date}}
  
      {{#if this.warn}}
          {{#if this.isLate}}
              ({{i18n-t "PlutofListViewColumn.overdue"}})
          {{else if this.isDue}}
              ({{i18n-t "PlutofListViewColumn.due"}})
          {{/if}}
      {{/if}}
  </div>
  
  */
  {
    "id": "k2KEeo78",
    "block": "{\"symbols\":[\"@transaction\"],\"statements\":[[10,\"div\"],[15,0,[30,[36,1],[[32,0,[\"warn\"]],\"list-highlight\"],null]],[12],[2,\"\\n    \"],[1,[30,[36,2],[[32,1,[\"expected_end_date\"]]],null]],[2,\"\\n\\n\"],[6,[37,1],[[32,0,[\"warn\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[32,0,[\"isLate\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            (\"],[1,[30,[36,0],[\"PlutofListViewColumn.overdue\"],null]],[2,\")\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[32,0,[\"isDue\"]]],null,[[\"default\"],[{\"statements\":[[2,\"            (\"],[1,[30,[36,0],[\"PlutofListViewColumn.due\"],null]],[2,\")\\n        \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"if\",\"formatted-date\"]}",
    "meta": {
      "moduleName": "plutof/components/transaction/-list-route-end-date.hbs"
    }
  });
  class TransactionListRouteEndDate extends _component.default {
    get date() {
      return (0, _moment.default)(this.args.transaction.expected_end_date);
    }
    get isLate() {
      return this.date.isValid() && (0, _moment.default)().isAfter(this.date);
    }
    get isDue() {
      if (!this.date.isValid()) {
        return false;
      }
      const offset = this.date.subtract(14, 'day');
      return (0, _moment.default)().isAfter(offset);
    }
    get warn() {
      return !this.args.transaction.is_locked && (this.isLate || this.isDue);
    }
  }
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TransactionListRouteEndDate);
});