define("plutof/templates/not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "jUpBljg5",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"common-public-page\"],[12],[2,\"\\n    \"],[8,\"common/legend\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,0],[\"errorPage.notFoundTitle\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[10,\"p\"],[14,0,\"common-public-page__text\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"errorPage.notFoundMsg\"],null]],[2,\"\\n\"],[6,[37,1],null,[[\"route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,0],[\"errorPage.backHome\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"link-to\"]}",
    "meta": {
      "moduleName": "plutof/templates/not-found.hbs"
    }
  });
});