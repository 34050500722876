define("plutof/routes/materialsample/edit", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "plutof/components/interaction/edit-multiple", "plutof/components/layer/occurrence-areas", "plutof/components/linked-items/edit", "plutof/components/measurements/measurement-form", "plutof/misc/content_types", "plutof/mixins/route", "plutof/utils/model", "plutof/utils/structures", "plutof/utils/validations"], function (_exports, _authenticatedRouteMixin, _editMultiple, _occurrenceAreas, _edit, _measurementForm, _content_types, _route, _model, _structures, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let MaterialsampleEditRoute = (_dec = Ember.inject.service, (_class = class MaterialsampleEditRoute extends Ember.Route.extend(_authenticatedRouteMixin.default, _route.default, _validations.ValidatedRoute) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _defineProperty(this, "defaultReturnRoute", 'materialsample.index');
      _defineProperty(this, "confirmExit", true);
      _defineProperty(this, "modelTitle", 'sample');
    }
    model(params) {
      var store = this.store;
      var ctypePromise = (0, _content_types.get_ctype_by_name)(store, 'materialsample/materialsample');
      var samplePromise = store.findRecord('taxonoccurrence/materialsample/materialsample', params.id);
      return samplePromise.then(sample => {
        return Ember.RSVP.hash({
          sample: sample,
          formData: (0, _model.getOccurrenceSamplingData)(store, sample).then(function (data) {
            data.formData.project = sample.get('project');
            data.formData.set('canEditEvent', true);
            return data.formData;
          }),
          itemData: (0, _edit.loadLinkedItemsData)(store, sample, {
            useEventFiles: true,
            disabledTabs: []
          }),
          contentType: ctypePromise,
          measurementData: _measurementForm.MeasurementsData.create({
            store: store
          }),
          interactions: _editMultiple.InteractionsData.load(store, sample),
          additionalIdentifiers: store.query('taxonoccurrence/materialsample/additionalidentifier', {
            materialsample: params.id
          }).then(_structures.sortByID),
          layers: _occurrenceAreas.LinkedLayersModel.load(this.ajax, sample)
        });
      });
    }
    setupController(controller, model) {
      super.setupController(controller, model);
      controller.setProperties({
        objectsToDelete: []
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  var _default = _exports.default = MaterialsampleEditRoute;
});