define("plutof/components/dmp/contributor", ["exports", "@ember-decorators/component", "ember-cp-validations", "plutof/mixins/component-validations", "plutof/misc/options-getter", "plutof/utils/formatters"], function (_exports, _component, _emberCpValidations, _componentValidations, _optionsGetter, _formatters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <td class={{has-error this "contributor.type"}}>
      <PlutofSelect::Value
          @content={{this.contributorTypes}}
          @value={{@contributor.type}}
          @selectClass="mini-input" />
  </td>
  
  <td class={{has-error this "contributor.name"}}>
      <Input @type="text" @value={{@contributor.name}} @class="mini-input" />
  </td>
  
  <td>
      <Input @type="text" @value={{@contributor.role}} @class="mini-input" />
  </td>
  
  <td class={{has-error this "mboxValidity"}}>
      <Input
          @type="text"
          @value={{@contributor.mbox}}
          @placeholder={{i18n-t "dmp.contributor.mbox.placeholder"}}
          @class="mini-input" />
  </td>
  
  <td>
      <Input @type="text" @value={{@contributor.contributor_id.identifier}} @class="mini-input" />
  </td>
  
  <td>
      <PlutofSelect::Value
          @content={{this.contributorIDTypes}}
          @value={{@contributor.contributor_id.type}}
          @selectClass="mini-input" />
  </td>
  
  <td class="align-right">
      <button
          {{on "click" (fn @remove @contributor)}}
          class="btn btn-link data-table-btn"
      >
          <span class={{icon "remove"}}></span>
      </button>
  </td>
  
  */
  {
    "id": "DXacwwWU",
    "block": "{\"symbols\":[\"@contributor\",\"@remove\"],\"statements\":[[10,\"td\"],[15,0,[30,[36,0],[[32,0],\"contributor.type\"],null]],[12],[2,\"\\n    \"],[8,\"plutof-select/value\",[],[[\"@content\",\"@value\",\"@selectClass\"],[[32,0,[\"contributorTypes\"]],[32,1,[\"type\"]],\"mini-input\"]],null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[15,0,[30,[36,0],[[32,0],\"contributor.name\"],null]],[12],[2,\"\\n    \"],[8,\"input\",[],[[\"@type\",\"@value\",\"@class\"],[\"text\",[32,1,[\"name\"]],\"mini-input\"]],null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[12],[2,\"\\n    \"],[8,\"input\",[],[[\"@type\",\"@value\",\"@class\"],[\"text\",[32,1,[\"role\"]],\"mini-input\"]],null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[15,0,[30,[36,0],[[32,0],\"mboxValidity\"],null]],[12],[2,\"\\n    \"],[8,\"input\",[],[[\"@type\",\"@value\",\"@placeholder\",\"@class\"],[\"text\",[32,1,[\"mbox\"]],[30,[36,1],[\"dmp.contributor.mbox.placeholder\"],null],\"mini-input\"]],null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[12],[2,\"\\n    \"],[8,\"input\",[],[[\"@type\",\"@value\",\"@class\"],[\"text\",[32,1,[\"contributor_id\",\"identifier\"]],\"mini-input\"]],null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[12],[2,\"\\n    \"],[8,\"plutof-select/value\",[],[[\"@content\",\"@value\",\"@selectClass\"],[[32,0,[\"contributorIDTypes\"]],[32,1,[\"contributor_id\",\"type\"]],\"mini-input\"]],null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[14,0,\"align-right\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-link data-table-btn\"],[4,[38,3],[\"click\",[30,[36,2],[[32,2],[32,1]],null]],null],[12],[2,\"\\n        \"],[10,\"span\"],[15,0,[30,[36,4],[\"remove\"],null]],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"has-error\",\"i18n-t\",\"fn\",\"on\",\"icon\"]}",
    "meta": {
      "moduleName": "plutof/components/dmp/contributor.hbs"
    }
  });
  const Validations = (0, _emberCpValidations.buildValidations)({
    'contributor.type': (0, _emberCpValidations.validator)('presence', true),
    'contributor.name': (0, _emberCpValidations.validator)('presence', true),
    'mboxValidity': (0, _emberCpValidations.validator)('is-truthy')
  });
  let DMPContributor = (_dec = (0, _component.tagName)('tr'), _dec2 = Ember.inject.service, _dec3 = (0, _optionsGetter.getDMPChoices)('contributors.items.properties.type'), _dec4 = (0, _optionsGetter.getDMPChoices)('contributors.items.properties.contributor_id.properties.type'), _dec5 = Ember.computed('contributor.type', 'contributor.mbox'), _dec(_class = (_class2 = class DMPContributor extends Ember.Component.extend(Validations, _componentValidations.default) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "contributorTypes", _descriptor2, this);
      _initializerDefineProperty(this, "contributorIDTypes", _descriptor3, this);
    }
    get mboxValidity() {
      const type = this.contributor.type;
      const mbox = this.contributor.mbox;
      const mboxIsValid = _formatters.EMAIL_REGEX.test(mbox);
      return type === 'contact' ? Ember.isPresent(mbox) && mboxIsValid : !Ember.isPresent(mbox) || mboxIsValid;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "contributorTypes", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "contributorIDTypes", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "mboxValidity", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "mboxValidity"), _class2.prototype)), _class2)) || _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DMPContributor);
});