define("plutof/templates/components/sample/view-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "0IXEXbiD",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],null,[[\"route\",\"model\"],[[35,1],[35,0,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[35,0,[\"representation\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"object\",\"route\",\"link-to\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/sample/view-link.hbs"
    }
  });
});