define("plutof/components/trait/list/edit", ["exports", "@glimmer/component", "plutof/utils/model", "plutof/utils/store"], function (_exports, _component, _model, _store) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createTraitListModel = createTraitListModel;
  _exports.default = void 0;
  _exports.loadTraitListModel = loadTraitListModel;
  var _dec, _dec2, _class, _dec3, _dec4, _class2, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Measurements::AddExtra::WrapGeneric @add={{@model.add}} as |externalTraits|>
      <PlutofPanel::ItemList ...attributes as |panel|>
          <panel.header
              @title={{i18n-t "traits.traits"}}
              @count={{@model.traits.length}}
          >
              <panel.button @clicked={{externalTraits.request}}>
                  {{i18n-t "General.Add"}}
              </panel.button>
          </panel.header>
  
          <panel.content>
              <Tables::List @records={{@model.traitLinks}} as |Field link|>
                  <Trait::List::-Fields
                      @field={{Field}}
                      @trait={{link.measurement}} />
  
                  <Field>
                      {{!--
                      TODO: Find a single instance of this thing that looks
                      good and make it a css object or component or something
                      --}}
                      <button
                          {{on "click" (fn @model.remove link)}}
                          class="btn btn-link no-padding"
                      >
                          <span class={{icon "remove"}}></span>
                      </button>
                  </Field>
              </Tables::List>
          </panel.content>
      </PlutofPanel::ItemList>
  </Measurements::AddExtra::WrapGeneric>
  
  */
  {
    "id": "S0UPZXGq",
    "block": "{\"symbols\":[\"externalTraits\",\"panel\",\"Field\",\"link\",\"@model\",\"&attrs\"],\"statements\":[[8,\"measurements/add-extra/wrap-generic\",[],[[\"@add\"],[[32,5,[\"add\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"plutof-panel/item-list\",[[17,6]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,2,[\"header\"]],[],[[\"@title\",\"@count\"],[[30,[36,0],[\"traits.traits\"],null],[32,5,[\"traits\",\"length\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,[32,2,[\"button\"]],[],[[\"@clicked\"],[[32,1,[\"request\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[1,[30,[36,0],[\"General.Add\"],null]],[2,\"\\n            \"]],\"parameters\":[]}]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,2,[\"content\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,\"tables/list\",[],[[\"@records\"],[[32,5,[\"traitLinks\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[8,\"trait/list/-fields\",[],[[\"@field\",\"@trait\"],[[32,3],[32,4,[\"measurement\"]]]],null],[2,\"\\n\\n                \"],[8,[32,3],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[2,\"                    \"],[11,\"button\"],[24,0,\"btn btn-link no-padding\"],[4,[38,2],[\"click\",[30,[36,1],[[32,5,[\"remove\"]],[32,4]],null]],null],[12],[2,\"\\n                        \"],[10,\"span\"],[15,0,[30,[36,3],[\"remove\"],null]],[12],[13],[2,\"\\n                    \"],[13],[2,\"\\n                \"]],\"parameters\":[]}]]],[2,\"\\n            \"]],\"parameters\":[3,4]}]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"fn\",\"on\",\"icon\"]}",
    "meta": {
      "moduleName": "plutof/components/trait/list/edit.hbs"
    }
  });
  let TraitListModel = (_dec = Ember._action, _dec2 = Ember._action, (_class = class TraitListModel {
    constructor({
      store,
      form,
      linkType,
      traitLinks = []
    }) {
      _defineProperty(this, "toDelete", []);
      _defineProperty(this, "presentTraits", new Set());
      this.store = store;
      this.form = form;
      this.linkType = linkType;
      this.traitLinks = traitLinks;
      for (const link of traitLinks) {
        this.presentTraits.add((0, _model.relationID)(link, 'measurement'));
      }
    }
    add(traits) {
      const links = traits.filter(trait => !this.presentTraits.has(trait.id)).map(trait => this.store.createRecord('measurement/measurementform', {
        measurement: trait,
        mainform: this.form,
        form_type: this.linkType
      }));
      this.traitLinks.pushObjects(links);
    }
    remove(traitLink) {
      this.traitLinks.removeObject(traitLink);
      this.toDelete.push(traitLink);
      this.presentTraits.delete((0, _model.relationID)(traitLink, 'measurement'));
    }

    // This should take accessRights, but the redlist case then becomes circular:
    // - redlist workgroup is made only after redlist is saved
    // - redlist saving requires the form
    // - form requires workgroup for permissions
    // So access handling is on the caller side for now
    async save(name) {
      // NB: As public, with edit rights set to
      // managing group
      await Ember.RSVP.Promise.all(this.toDelete.map(l => l.destroyRecord()));
      this.toDelete = [];
      this.form.set('name', name);
      await this.form.save();
      await Ember.RSVP.Promise.all(this.traitLinks.map(l => l.save()));
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "add", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "add"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "remove", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "remove"), _class.prototype)), _class)); // Have to convert mainform types to link form_type choices
  const FORM_LINK_TYPES = {
    'materialsample': 6,
    'specimen': 10,
    'observation': 10,
    'sequence': 4,
    'strain': 10,
    'samplingarea': 7,
    'reference_based': 10,
    'samplingevent': 8,
    'red_list': 9
  };
  function createTraitListModel(store, type) {
    const form = store.createRecord('measurement/mainform', {
      type
    });
    return new TraitListModel({
      store,
      form,
      linkType: FORM_LINK_TYPES[type]
    });
  }
  async function loadTraitListModel(store, form) {
    const traitLinks = await (0, _store.query)(store, 'measurement/measurementform', {
      mainform: form.id
    });
    traitLinks.sort((x, y) => x.id - y.id);
    return new TraitListModel({
      store,
      form,
      linkType: FORM_LINK_TYPES[form.type],
      traitLinks
    });
  }
  let TraitListEdit = (_dec3 = Ember._tracked, _dec4 = Ember._action, (_class2 = class TraitListEdit extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "searchOpen", _descriptor, this);
    }
    showSearch() {
      this.searchOpen = true;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "searchOpen", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "showSearch", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "showSearch"), _class2.prototype)), _class2));
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TraitListEdit);
});