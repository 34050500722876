define("plutof/templates/layer/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "3XzebmSD",
    "block": "{\"symbols\":[],\"statements\":[[8,\"test/route-marker\",[],[[\"@route\"],[\"layer.edit\"]],null],[2,\"\\n\\n\"],[8,\"navbar/edit\",[],[[\"@icon\",\"@title\",\"@record\",\"@infoContent\"],[[30,[36,0],[\"area\"],null],[30,[36,1],[\"Layer.editLayer\"],null],[32,0,[\"model\",\"samplingarea\"]],\"information/content/general/area-event\"]],null],[2,\"\\n\\n\"],[8,\"layer/edit\",[],[[\"@layer\",\"@areas\",\"@save\",\"@validationContext\"],[[32,0,[\"model\",\"layer\"]],[32,0,[\"model\",\"areas\"]],[32,0,[\"save\"]],[32,0,[\"validationContext\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\"]}",
    "meta": {
      "moduleName": "plutof/templates/layer/edit.hbs"
    }
  });
});