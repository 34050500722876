define("plutof/routes/conservation-lab/red-list/edit", ["exports", "plutof/utils/routes", "plutof/components/agent/organization-workgroup", "plutof/components/conservation/red-list/edit"], function (_exports, _routes, _organizationWorkgroup, _edit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class RedListEditRoute extends _routes.EditRoute {
    async model({
      id
    }) {
      const record = await this.store.findRecord('redbook/red-list', id);
      const redlist = await (0, _edit.loadRedListModel)(this.store, record);
      const workgroup = await (0, _organizationWorkgroup.loadWorkgroupData)(this.store, record.belongsTo('workgroup').id());
      return {
        redlist,
        workgroup
      };
    }
    getTitle(model) {
      return model.redlist.redlist.representation;
    }
    transitionToDefaultRoute() {
      return this.transitionTo('conservation-lab.red-list.view', this.currentModel.redlist.redlist.id);
    }
  }
  var _default = _exports.default = RedListEditRoute;
});