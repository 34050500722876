define("plutof/templates/components/observation/taxon-sheet/taimeatlas-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "9hqkbvOV",
    "block": "{\"symbols\":[\"sheet\",\"@sheetset\",\"@data\"],\"statements\":[[6,[37,2],null,[[\"show\",\"data\",\"sheetset\",\"class\"],[[35,1],[32,3],[32,2],\"taimeatlas-sheet-dialog\"]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],null,[[\"sheet\",\"selection\",\"entries\",\"toggle\"],[[32,1,[\"sheet\"]],[32,1,[\"selection\"]],[32,1,[\"entries\"]],[32,1,[\"toggle\"]]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"observation/taxon-sheet/taimeatlas\",\"show\",\"observation/taxon-sheet/dialog\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/observation/taxon-sheet/taimeatlas-dialog.hbs"
    }
  });
});