define("plutof/components/search/filters/location", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="display-flex">
      <div class="plutof-tooltip-hover">
          <div class="plutof-label">
              {{i18n-t "Sample.mapTitle"}}
          </div>
  
          <span class="plutof-tooltip">{{i18n-t "Sample.mapTitleTooltip"}}</span>
      </div>
  
      {{information/dialog-trigger
          title=(i18n-t "Sample.mapTitle")
          content="information/content/general/map-controls"}}
  </div>
  
  <Map::EditMap
      @geodata={{@filter.value}}
      @wktPrecision={{5}} />
  
  <div class="spacer-large-below"></div>
  
  <Layout::RegularGrid @columns={{2}}>
      <div>
          {{common/local-label "Search.geometry.relation.relation"}}
  
          <PlutofSelect::Value
              @content={{this.RELATION_CHOICES}}
              @oneWay={{true}}
              @value={{default-to @filter.operator "intersects"}}
              @selectionChanged={{this.updateRelation}}
              @disabled={{not @filter.value}}
              @optionLabelPath="label"
              @prompt={{false}} />
      </div>
  
      <div class={{unless this.bufferValid "has-error"}}>
          {{common/local-label
              "Search.geometry.buffer.label"
              "Search.geometry.buffer.tooltip"}}
  
          <Input
              @value={{@filter.buffer}}
              @disabled={{not @filter.value}}
              class="form-control" />
      </div>
  </Layout::RegularGrid>
  
  */
  {
    "id": "eqGwsah+",
    "block": "{\"symbols\":[\"@filter\"],\"statements\":[[10,\"div\"],[14,0,\"display-flex\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"plutof-tooltip-hover\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"plutof-label\"],[12],[2,\"\\n            \"],[1,[30,[36,0],[\"Sample.mapTitle\"],null]],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"span\"],[14,0,\"plutof-tooltip\"],[12],[1,[30,[36,0],[\"Sample.mapTitleTooltip\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[1,[30,[36,1],null,[[\"title\",\"content\"],[[30,[36,0],[\"Sample.mapTitle\"],null],\"information/content/general/map-controls\"]]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[8,\"map/edit-map\",[],[[\"@geodata\",\"@wktPrecision\"],[[32,1,[\"value\"]],5]],null],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"spacer-large-below\"],[12],[13],[2,\"\\n\\n\"],[8,\"layout/regular-grid\",[],[[\"@columns\"],[2]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[12],[2,\"\\n        \"],[1,[30,[36,2],[\"Search.geometry.relation.relation\"],null]],[2,\"\\n\\n        \"],[8,\"plutof-select/value\",[],[[\"@content\",\"@oneWay\",\"@value\",\"@selectionChanged\",\"@disabled\",\"@optionLabelPath\",\"@prompt\"],[[32,0,[\"RELATION_CHOICES\"]],true,[30,[36,3],[[32,1,[\"operator\"]],\"intersects\"],null],[32,0,[\"updateRelation\"]],[30,[36,4],[[32,1,[\"value\"]]],null],\"label\",false]],null],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[15,0,[30,[36,5],[[32,0,[\"bufferValid\"]],\"has-error\"],null]],[12],[2,\"\\n        \"],[1,[30,[36,2],[\"Search.geometry.buffer.label\",\"Search.geometry.buffer.tooltip\"],null]],[2,\"\\n\\n        \"],[8,\"input\",[[24,0,\"form-control\"]],[[\"@value\",\"@disabled\"],[[32,1,[\"buffer\"]],[30,[36,4],[[32,1,[\"value\"]]],null]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"information/dialog-trigger\",\"common/local-label\",\"default-to\",\"not\",\"unless\"]}",
    "meta": {
      "moduleName": "plutof/components/search/filters/location.hbs"
    }
  });
  let SearchLocationFilter = (_dec = Ember.inject.service, _dec2 = Ember.computed('args.filter.buffer', 'args.filter.bufferValid'), _dec3 = Ember._action, (_class = class SearchLocationFilter extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "i18n", _descriptor, this);
      _defineProperty(this, "RELATION_CHOICES", [{
        value: 'intersects',
        label: this.i18n.translate('Search.geometry.relation.intersects')
      }, {
        value: 'within',
        label: this.i18n.translate('Search.geometry.relation.within')
      }, {
        value: 'contains',
        label: this.i18n.translate('Search.geometry.relation.contains')
      }]);
    }
    get bufferValid() {
      return this.args.filter && (!this.args.filter.buffer || this.args.filter.bufferValid);
    }
    updateRelation(choice) {
      this.args.filter.operator = choice.value;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "i18n", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "bufferValid", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "bufferValid"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateRelation", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "updateRelation"), _class.prototype)), _class));
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SearchLocationFilter);
});