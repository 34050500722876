define("plutof/translations/eng/experiment", ["exports", "plutof/translations/helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    laboratory: {
      laboratory: 'Laboratory',
      intro: {
        title: 'Laboratories',
        description: 'Laboratories linked to user\'s workgroups (Laboratory managing group)'
      }
    },
    extraction: {
      intro: {
        title: 'DNA Extraction',
        description: 'List of DNA extraction experiments',
        enter: 'DNA Extraction'
      },
      routeTitles: {
        list: 'DNA Extractions',
        add: 'New DNA Extraction',
        edit: 'Edit DNA Extraction',
        view: 'DNA Extraction'
      },
      general: {
        source: 'Source'
      },
      errors: {
        dnaMissingPermissions: 'Some DNA records were not updated because of insufficient permissions'
      },
      actions: {
        sendToNewExperiment: {
          label: 'New PCR ({{count}})',
          tooltip: 'Create a PCR experiment with selected materials.'
        },
        sendToExistingExperiment: {
          label: 'Add to PCR ({{count}})',
          tooltip: 'Add selected materials to an existing PCR experiment.'
        },
        print: {
          label: 'Print',
          tooltip: 'Open a printer-friendly view of the Material panel.'
        },
        add: {
          label: 'Add {{count}}'
        }
      }
    },
    pcr: {
      intro: {
        title: 'PCR',
        description: 'List of PCR experiments',
        enter: 'PCR'
      },
      routeTitles: {
        list: 'PCR Reactions',
        add: 'New PCR',
        edit: 'Edit PCR',
        view: 'PCR'
      },
      general: {
        pcr: 'PCR'
      },
      actions: {
        sendToNewExperiment: {
          label: 'New DNA Sequencing ({{count}})',
          tooltip: 'Create a DNA Sequencing experiment with selected materials.'
        },
        sendToExistingExperiment: {
          label: 'Add to DNA Sequencing ({{count}})',
          tooltip: 'Add selected materials to an existing DNA Sequencing experiment.'
        }
      },
      fields: {
        annealingTime: 'Annealing time (mm:ss)',
        denaturationTime: 'Denaturation time (mm:ss)',
        extensionTime: 'Extension time (mm:ss)'
      }
    },
    sequencing: {
      intro: {
        title: 'DNA Sequencing',
        description: 'List of DNA Sequencing experiments',
        enter: 'DNA Sequencing'
      },
      routeTitles: {
        list: 'DNA Sequencing',
        add: 'New DNA Sequencing',
        edit: 'Edit DNA Sequencing',
        view: 'DNA Sequencing'
      },
      general: {
        sequencing: 'DNA Sequencing'
      },
      actions: {
        parseSourceFile: {
          label: 'Parse source file',
          result: '{{count}} files linked'
        },
        copyPCRText: {
          label: 'Copy',
          tooltip: 'Copy PCR column text to system clipboard'
        }
      }
    },
    dna: {
      intro: {
        title: 'Extracted DNA',
        description: 'List of extracted DNA',
        enter: 'DNA'
      },
      routeTitles: {
        list: 'Extracted DNA',
        add: 'New Extracted DNA',
        edit: 'Edit Extracted DNA',
        view: 'Extracted DNA'
      },
      general: {
        dna: 'Extracted DNA'
      }
    },
    general: {
      experiments: 'DNA Experiments',
      labware: 'Labware',
      materialSource: 'Material source',
      tube: 'Tube'
    },
    buttons: {
      addAllMaterials: 'All {{count}}',
      toggleBulkUpdate: (0, _helpers.alias)('General.tableBulkUpdate.toggle'),
      fillPrimerTags: 'Autofill primer tags',
      orientation: {
        vertical: 'Vertical',
        horizontal: 'Horizontal'
      }
    },
    panels: {
      extractions: 'DNA Extractions',
      pcrs: 'PCR Reactions',
      pcrProducts: 'PCR Products',
      sequencings: 'DNA Sequencing',
      sequencingResults: 'DNA Sequencing Results',
      workgroup: 'Managing Group',
      labware: (0, _helpers.alias)('experiment.general.labware'),
      material: 'Material',
      dnas: 'Extracted DNA',
      materialSamples: 'Material Samples'
    }
  };
});