define("plutof/translations/eng/photobank", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    colID: 'ID',
    colTitle: 'Title',
    colCategory: 'Category',
    colModified: 'Modified',
    listTitle: 'Photobank',
    objectSearchHint: 'Search by Object ID',
    infoTitle: 'Photobank',
    newObject: 'New Collection Item',
    editObject: 'Edit Collection Item',
    areaEventTitle: 'Area and event',
    viewObject: 'Photobank item',
    additionalIDs: 'Additional identifiers',
    classifications: 'Classifications',
    agents: 'Agents',
    objectID: 'Object ID',
    alreadyExists: 'already exists in database',
    gatheringAgents: 'Photographer',
    taxa: 'Taxa'
  };
});