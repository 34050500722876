define("plutof/components/agent/organization-view/address", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <GenericViews::Record
      @record={{@address}}
      data-test="Agent::OrganizationView::Address"
      as |view|
  >
      <Layout::RegularGrid @columns={{2}}>
          {{#view.attr "country"}}
              {{@address.country.representation}}
          {{/view.attr}}
  
          {{view.attr "state"}}
          {{view.attr "city"}}
          {{view.attr "street"}}
          {{view.attr "zip"}}
          {{view.attr "mail_stop"}}
      </Layout::RegularGrid>
  </GenericViews::Record>
  
  */
  {
    "id": "caDRF39Q",
    "block": "{\"symbols\":[\"view\",\"@address\"],\"statements\":[[8,\"generic-views/record\",[[24,\"data-test\",\"Agent::OrganizationView::Address\"]],[[\"@record\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"layout/regular-grid\",[],[[\"@columns\"],[2]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,0],[[32,1,[\"attr\"]],\"country\"],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[32,2,[\"country\",\"representation\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n        \"],[1,[30,[36,0],[[32,1,[\"attr\"]],\"state\"],null]],[2,\"\\n        \"],[1,[30,[36,0],[[32,1,[\"attr\"]],\"city\"],null]],[2,\"\\n        \"],[1,[30,[36,0],[[32,1,[\"attr\"]],\"street\"],null]],[2,\"\\n        \"],[1,[30,[36,0],[[32,1,[\"attr\"]],\"zip\"],null]],[2,\"\\n        \"],[1,[30,[36,0],[[32,1,[\"attr\"]],\"mail_stop\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\"]}",
    "meta": {
      "moduleName": "plutof/components/agent/organization-view/address.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});