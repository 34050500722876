define("plutof/components/search/range-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initRange = initRange;
  var _dec, _dec2, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const OPERATORS = [{
    name: '=',
    startSuffix: '',
    endSuffix: '',
    singular: true
  }, {
    name: '<=',
    startSuffix: '__gte',
    endSuffix: '__lte',
    singular: false
  }, {
    name: '<',
    startSuffix: '__gte',
    endSuffix: '__lt',
    singular: false
  }];
  let Range = (_dec = Ember.computed('start', 'end', 'operator'), _dec2 = Ember.computed('filter', 'start', 'end', 'operator'), (_class = class Range extends Ember.Object {
    get serialized() {
      const {
        start,
        end,
        operator
      } = this.getProperties(['start', 'end', 'operator']);
      if (Ember.isEmpty(start) && Ember.isEmpty(end)) {
        return '';
      }
      const range = [start, operator.name, end].reject(Ember.isEmpty).join('');
      return range;
    }
    get query() {
      let {
        filter,
        start,
        end,
        operator
      } = this.getProperties(['filter', 'start', 'end', 'operator']);
      if (Ember.isEmpty(start) && Ember.isEmpty(end)) {
        return '';
      }
      if (operator.singular) {
        if (Ember.isEmpty(start)) {
          start = end;
        }
        end = null;
      }
      let parts = [];
      if (!Ember.isEmpty(start)) {
        parts.push(`${filter}${operator.startSuffix}=${start}`);
      }
      if (!Ember.isEmpty(end)) {
        parts.push(`${filter}${operator.endSuffix}=${end}`);
      }
      return parts.join('&');
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "serialized", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "serialized"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "query", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "query"), _class.prototype)), _class));
  const SPEC_REGEXP = /(-?\d*(?:\.\d*)?)([<=>]+)(-?\d*(?:\.\d*)?)/;

  // filte: filter name, e.g. '75'
  // spec is '=120', '120=', '120<', '120<=150', '<150' etc
  function initRange(filter, spec) {
    if (Ember.isEmpty(spec)) {
      return Range.create({
        filter: filter,
        operator: OPERATORS.findBy('name', '=')
      });
    }
    const [start, op, end] = SPEC_REGEXP.exec(spec).slice(1, 4);
    return Range.create({
      filter: filter,
      operator: OPERATORS.findBy('name', op),
      start: start,
      end: end
    });
  }
  class RangeInput extends Ember.Component {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "operators", OPERATORS);
      _defineProperty(this, "range", null);
    }
  }
  var _default = _exports.default = RangeInput;
});