define("plutof/routes/livingspecimen/annotation/view", ["exports", "plutof/routes/livingspecimen/view", "plutof/utils/annotation/routes"], function (_exports, _view, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _routes.annotationViewRoute)(_view.default, {
    parentRoute: 'livingspecimen'
  });
});