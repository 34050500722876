define("plutof/components/guided-view", ["exports", "plutof/components/guided-form"], function (_exports, _guidedForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.tooltip}}
      <ToolTip @tooltip={{this.tooltip}}>
          <div class="plutof-label">
              {{this.label}}
          </div>
      </ToolTip>
  {{else}}
      <div class="plutof-label">
          {{this.label}}
      </div>
  {{/if}}
  
  <p>
      {{yield}}
  </p>
  
  */
  {
    "id": "Aa9uPMA5",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,0],[[32,0,[\"tooltip\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"tool-tip\",[],[[\"@tooltip\"],[[32,0,[\"tooltip\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"div\"],[14,0,\"plutof-label\"],[12],[2,\"\\n            \"],[1,[32,0,[\"label\"]]],[2,\"\\n        \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"plutof-label\"],[12],[2,\"\\n        \"],[1,[32,0,[\"label\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"p\"],[12],[2,\"\\n    \"],[18,1,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "plutof/components/guided-view.hbs"
    }
  });
  class GuidedView extends _guidedForm.default {}
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, GuidedView);
});