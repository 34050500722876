define("plutof/utils/useragent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.onSafari = onSafari;
  // Don't abuse this, only if there is no other way
  function onSafari() {
    const userAgent = navigator.userAgent.toLowerCase();
    return userAgent.indexOf('safari') !== -1 && userAgent.indexOf('chrome') === -1;
  }
});