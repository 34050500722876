define("plutof/helpers/call", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // NB: With great power comes great responsibility
  var _default = _exports.default = Ember.Helper.helper(function callHelper([func, ...params]) {
    return func.apply(undefined, params);
  });
});