define("plutof/utils/map", ["exports", "@ember-decorators/object", "ol/control", "ol/geom/Polygon", "ol/Feature", "ol/format", "ol/interaction", "ol/layer/Heatmap", "ol/layer/Vector", "ol/layer/Tile", "ol/Observable", "ol/proj", "ol/source/OSM", "ol/source/Vector", "ol/source/WMTS", "ol/source/XYZ", "ol/style", "ol/tilegrid/WMTS", "plutof/config/environment", "plutof/misc/abstract", "plutof/misc/config", "plutof/services/ajax", "plutof/utils/i18n", "plutof/utils/reflection"], function (_exports, _object, _control, _Polygon, _Feature, _format, _interaction, _Heatmap, _Vector, _Tile, _Observable, _proj, _OSM, _Vector2, _WMTS, _XYZ, _style, _WMTS2, _environment, _abstract, _config, _ajax, _i18n, _reflection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.BaseLayerSet = void 0;
  _exports.MaaametLayerSet = MaaametLayerSet;
  _exports.OSMLayerSet = OSMLayerSet;
  _exports.default = _exports.OptionalLayer = void 0;
  _exports.geoqueryCountry = geoqueryCountry;
  _exports.getFormattedCentroid = getFormattedCentroid;
  _exports.getGeometryCenter = getGeometryCenter;
  _exports.getParish = getParish;
  _exports.getWKTCenter = getWKTCenter;
  _exports.requestEHAK = requestEHAK;
  _exports.stripSRID = stripSRID;
  var _dec, _class, _dec2, _class2, _dec3, _class3, _dec4, _dec5, _class4, _dec6, _dec7, _class5, _descriptor, _dec8, _class6, _dec9, _class7, _dec10, _class8, _dec11, _class9, _dec12, _class10, _dec13, _class11, _dec14, _class12;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const i18n = (0, _i18n.getI18n)();
  const GEO_LAYER_ENDPOINT = _environment.default.API_HOST + '/geography/layer-objects/geolocate/';

  // Used in maa-amet layer custom loadTile function to calculate tile row.
  const MA_SIZES = [[1, 1], [2, 2], [4, 4], [8, 8], [16, 16], [32, 32], [64, 64], [128, 128], [256, 256], [512, 512], [1024, 1024], [2048, 2048], [4096, 4096], [8192, 8192]];
  class BaseLayerSet extends Ember.Object {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "id", 'base');
      _defineProperty(this, "name", 'Base');
      _defineProperty(this, "layers", []);
      _defineProperty(this, "optionalLayers", []);
      _defineProperty(this, "internalProjection", null);
      _defineProperty(this, "externalProjection", null);
      _defineProperty(this, "extent", undefined);
      _defineProperty(this, "maxResolution", undefined);
    }
  }
  _exports.BaseLayerSet = BaseLayerSet;
  const LoadingProgressMixin = Ember.Mixin.create({
    init: function () {
      this._super(...arguments);
      this.set('partsLoading', 0);
    },
    isLoading: Ember.computed.gt('partsLoading', 0),
    startPartLoading: function () {
      this.incrementProperty('partsLoading');
    },
    finishPartLoading: function () {
      this.decrementProperty('partsLoading');
    }
  });
  class Layer extends Ember.Object {}
  let OptionalLayer = _exports.OptionalLayer = (_dec = (0, _object.observes)('layer', 'visible'), (_class = class OptionalLayer extends Layer.extend(LoadingProgressMixin) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "name", 'Layer');
      _defineProperty(this, "layer", null);
    }
    init() {
      super.init(...arguments);
      this.set('visible', false);
      // XXX: What a mess
      this.set('initialized', true);
      this.updateVisibility();
    }
    updateVisibility() {
      var layer = this.layer;
      if (!Ember.isNone(layer)) {
        layer.setVisible(this.visible);
      }
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "updateVisibility", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "updateVisibility"), _class.prototype)), _class));
  let DelayedOptionalLayer = (_dec2 = (0, _object.observes)('url', 'visible'), (_class2 = class DelayedOptionalLayer extends OptionalLayer {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "name", 'Delayed layer');
      _defineProperty(this, "url", null);
    }
    init() {
      super.init();
      this.set('initialized', false);
      this.set('layer', null);
      this.updateVisibility();
    }

    // TODO: Since OL 3.5.0 vector sources are loaded lazily,
    // we might not need manual delay for them
    loadLayer(url) {
      const source = new _Vector2.default({
        url: `${window.location.origin}/${url}`,
        format: new _format.GeoJSON()
      });
      return new _Vector.default({
        source
      });
    }
    updateVisibility() {
      var visible = this.visible;
      if (!this.initialized) {
        if (visible) {
          var layer = this.loadLayer(this.url);
          layer.setVisible(true);
          this.setProperties({
            layer,
            initialized: true
          });
        }
      } else {
        // XXX XXX: Parent constructor sets initialized to true, despite layer still missing
        if (this.layer) {
          this.layer.setVisible(visible);
        }
      }
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "updateVisibility", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "updateVisibility"), _class2.prototype)), _class2));
  const TileLayerMixin = Ember.Mixin.create({
    init: function () {
      this._super(...arguments);
      const source = this.source;
      this.set('layer', new _Tile.default({
        source
      }));
      this._startPartLoading = this.startPartLoading.bind(this);
      this._finishPartLoading = this.finishPartLoading.bind(this);
      source.on('tileloadstart', this._startPartLoading);
      source.on(['tileloadend', 'tileloaderror'], this._finishPartLoading);
    },
    willDestroy() {
      this._super(...arguments);
      const source = this.source;
      source.un('tileloadstart', this._startPartLoading);
      source.un(['tileloadend', 'tileloaderror'], this._finishPartLoading);
    }
  });
  class TileOptionalLayer extends OptionalLayer.extend(TileLayerMixin) {
    init() {
      super.init(...arguments);

      // XXX: Needed because of init order change with the move from .on('init')
      // to calling observers manually: updateVisibility in DelayedOptionalLayer
      // gets called before the layer is initialized in TileLayerMixin
      this.updateVisibility();
    }
  }
  class MapTileLayer extends Layer.extend(TileLayerMixin, LoadingProgressMixin) {}
  var LabeledLayerMixin = Ember.Mixin.create({
    labelProperty: 'id',
    maximumResolution: null,
    init() {
      this._super(...arguments);
      this.setLayerStye();
    },
    getFeatureLabel: function (feature) {
      return (feature.get(this.labelProperty) || '').toString();
    },
    style: Ember.computed('maximumResolution', function () {
      return (feature, resolution) => {
        let label = this.getFeatureLabel(feature);
        if (!Ember.isNone(this.maximumResolution) && resolution > this.maximumResolution) {
          label = '';
        }
        return [new _style.Style({
          fill: new _style.Fill({
            color: 'rgba(255, 255, 255, 0.4)'
          }),
          stroke: new _style.Stroke({
            color: '#3399CC',
            width: 1.25
          }),
          text: new _style.Text({
            font: '12px Calibri,sans-serif',
            text: label,
            fill: new _style.Fill({
              color: '#000'
            }),
            stroke: new _style.Stroke({
              color: '#fff',
              width: 3
            })
          })
        })];
      };
    }),
    setLayerStye: Ember.observer('layer', function () {
      var layer = this.layer;
      if (!Ember.isNone(layer)) {
        var style = this.style;
        if (!Ember.isNone(style)) {
          layer.setStyle(style);
        }
      }
    })
  });
  class JSONFeatureLayer extends DelayedOptionalLayer.extend(LabeledLayerMixin) {}
  let HeatmapLayer = (_dec3 = (0, _object.observes)('visible'), (_class3 = class HeatmapLayer extends OptionalLayer {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "name", i18n.t('Map.layers.heatmap'));
      _defineProperty(this, "source", null);
    }
    init() {
      super.init();
      const layer = new _Heatmap.default({
        source: this.source,
        radius: 15,
        blur: 30
      });
      this.set('layer', layer);
      this.updateStyles();
    }
    updateStyles() {
      var source = this.source;
      var visible = this.visible;

      // Hack: in OL3, feature style takes precedence over the layer one. Heatmap
      // layer style therefore is overriden, because the map sets style per-feature
      // (e.g. to support coloring). This temporarily disables styling. Could have side
      // effects if something modifies a default style while the heatmap is active
      if (visible) {
        source.getFeatures().forEach(function (feature) {
          feature.set('_normalStyle', feature.getStyle());
          feature.setStyle(null);
        });
      } else {
        source.getFeatures().forEach(function (feature) {
          feature.setStyle(feature.get('_normalStyle'));
        });
      }
    }
  }, (_applyDecoratedDescriptor(_class3.prototype, "updateStyles", [_dec3], Object.getOwnPropertyDescriptor(_class3.prototype, "updateStyles"), _class3.prototype)), _class3));
  let MapControl = (_dec4 = Ember.computed(), _dec5 = Ember.computed('node'), (_class4 = class MapControl extends Ember.Object {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "name", 'Control');
      _defineProperty(this, "className", '');
      _defineProperty(this, "target", undefined);
    }
    init() {
      super.init();
      this.set('active', false);
    }
    get node() {
      var control = document.createElement('div');
      control.className = this.className + ' ol-unselectable ol-control';
      return control;
    }
    get control() {
      var node = this.node;
      return new _control.Control({
        element: node,
        target: this.target
      });
    }
  }, (_applyDecoratedDescriptor(_class4.prototype, "node", [_dec4], Object.getOwnPropertyDescriptor(_class4.prototype, "node"), _class4.prototype), _applyDecoratedDescriptor(_class4.prototype, "control", [_dec5], Object.getOwnPropertyDescriptor(_class4.prototype, "control"), _class4.prototype)), _class4));
  class Button extends MapControl {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "name", 'Button');
      _defineProperty(this, "icon", null);
    }
    clicked() {
      // Implemented in subclasses
    }
  }
  let ToggleButton = (_dec6 = Ember.computed.alias('icon'), _dec7 = (0, _object.observes)('isToggled'), (_class5 = class ToggleButton extends Button {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "name", 'Toggle button');
      _defineProperty(this, "icon", null);
      _initializerDefineProperty(this, "onIcon", _descriptor, this);
    }
    init() {
      super.init(...arguments);
      this.set('isToggled', false);
    }
    clicked() {
      this.toggleProperty('isToggled');
    }

    // XXX
    statusChanged() {
      this.toggled(this.isToggled);
    }
    toggled(on) {
      //
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class5.prototype, "onIcon", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class5.prototype, "statusChanged", [_dec7], Object.getOwnPropertyDescriptor(_class5.prototype, "statusChanged"), _class5.prototype)), _class5));
  let LayerSelection = (_dec8 = Ember.computed('layerset', 'commonOptional'), (_class6 = class LayerSelection extends Ember.Object {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "layerset", null);
      _defineProperty(this, "possibleLayersets", []);
      _defineProperty(this, "commonOptional", []);
    }
    get optional() {
      var base = this.layerset;
      var optional = this.commonOptional;
      if (!Ember.isNone(base)) {
        optional = optional.concat(base.get('optionalLayers'));
      }
      return optional;
    }
    switchLayerset(id) {
      const layerset = this.possibleLayersets.findBy('id', id);
      if (!Ember.isNone(layerset)) {
        this.set('layerset', layerset);
      }
    }
    activateOptional(code) {
      // Should it be on active?
      this.possibleLayersets.forEach(layerset => {
        layerset.get('optionalLayers').filterBy('code', code).forEach(layer => layer.set('visible', true));
      });
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.possibleLayersets.forEach(layerset => layerset.destroy());
    }
  }, (_applyDecoratedDescriptor(_class6.prototype, "optional", [_dec8], Object.getOwnPropertyDescriptor(_class6.prototype, "optional"), _class6.prototype)), _class6));
  class ToolButton extends Button {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "className", 'tool-button');
    }
  }
  let InteractionButton = (_dec9 = Ember.computed('icon'), (_class7 = class InteractionButton extends ToggleButton {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "className", 'tool-button');
      _defineProperty(this, "interactions", []);
      // Interaction buttons that can't be on simultaneously with this one
      _defineProperty(this, "interactionGroup", []);
      // If all buttons are toggled off, this one is toggle one
      _defineProperty(this, "primary", false);
    }
    get onIcon() {
      return this.icon + ' icon-selected';
    }
    init() {
      super.init(...arguments);
      this.set('cleanupActions', []);
      var interactions = this.createInteractions();
      interactions.forEach(function (interaction) {
        interaction.setActive(false);
      });
      this.set('interactions', interactions);
    }
    createInteractions() {
      return [];
    }
    toggled(on) {
      var group = this.interactionGroup.without(this);
      this.interactions.forEach(function (interaction) {
        interaction.setActive(on);
      });
      if (on) {
        group.forEach(function (control) {
          control.set('isToggled', false);
        });
      } else if (!group.any(control => control.get('isToggled'))) {
        var primary = group.findBy('primary', true);
        if (!Ember.isNone(primary)) {
          primary.set('isToggled', true);
        }
      }
    }
    addEventListener(object, event, handler) {
      object.on(event, handler);
      this.cleanupActions.push(() => object.un(event, handler));
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.cleanupActions.forEach(action => action());
    }
  }, (_applyDecoratedDescriptor(_class7.prototype, "onIcon", [_dec9], Object.getOwnPropertyDescriptor(_class7.prototype, "onIcon"), _class7.prototype)), _class7));
  var ToolPanelButtons = {
    Navigate: class NavigateTool extends InteractionButton {
      constructor(...args) {
        super(...args);
        _defineProperty(this, "name", i18n.t('Map.controls.navigate'));
        _defineProperty(this, "icon", 'fas fa-arrows-alt');
        _defineProperty(this, "primary", true);
      }
      createInteractions() {
        return (0, _interaction.defaults)().getArray();
      }
    },
    Modify: class ModifyTool extends InteractionButton {
      constructor(...args) {
        super(...args);
        _defineProperty(this, "name", i18n.t('Map.controls.modify'));
        _defineProperty(this, "icon", 'fas fa-edit');
        _defineProperty(this, "layers", []);
      }
      featureModified() {
        //
      }
      createInteractions() {
        var select = new _interaction.Select({
          layers: this.layers,
          multi: false
        });
        var selection = select.getFeatures();
        var modify = new _interaction.Modify({
          features: selection
        });
        modify.on(['modifyend'], event => {
          this.featureModified(event.features.getArray().firstObject);
        });
        this.addEventListener(selection, 'remove', evt => this.featureModified(evt.element));
        this.addEventListener(selection, 'change:active', () => selection.clear());

        // TODO: Notify about changes - for now, modifying doesn't
        // trigger output update
        return [select, modify];
      }
    },
    Point: class PointTool extends InteractionButton {
      constructor(...args) {
        super(...args);
        _defineProperty(this, "name", i18n.t('Map.controls.point'));
        _defineProperty(this, "icon", 'fas fa-map-marker-alt');
        _defineProperty(this, "source", null);
      }
      createInteractions() {
        return [new _interaction.Draw({
          source: this.source,
          type: 'Point'
        }), new _interaction.DragPan()];
      }
    },
    Polygon: class PolygonTool extends InteractionButton {
      constructor(...args) {
        super(...args);
        _defineProperty(this, "name", i18n.t('Map.controls.polygon'));
        _defineProperty(this, "icon", 'icon-area');
        _defineProperty(this, "source", null);
      }
      createInteractions() {
        return [new _interaction.Draw({
          source: this.source,
          type: 'Polygon'
        }), new _interaction.DragPan()];
      }
    },
    Box: class BoxTool extends InteractionButton {
      constructor(...args) {
        super(...args);
        _defineProperty(this, "name", i18n.t('Map.controls.box'));
        _defineProperty(this, "icon", 'fas fa-stop fa-sm');
        _defineProperty(this, "source", null);
      }
      createInteractions() {
        const drawBoxes = new _interaction.DragBox();
        let box_top;
        this.addEventListener(drawBoxes, 'boxstart', evt => {
          box_top = evt.coordinate;
        });
        this.addEventListener(drawBoxes, 'boxend', evt => {
          const x1 = box_top[0];
          const x2 = evt.coordinate[0];
          const y1 = box_top[1];
          const y2 = evt.coordinate[1];
          const polygon = new _Polygon.default([[[x1, y1], [x2, y1], [x2, y2], [x1, y2], [x1, y1]]], 'XY');
          this.source.addFeature(new _Feature.default(polygon));
        });
        return [drawBoxes];
      }
    },
    // XXX: Can't directly serialize circles to WKT - can only use
    // circle drawing for radial selection
    Radius: class RadiusTool extends InteractionButton {
      constructor(...args) {
        super(...args);
        _defineProperty(this, "name", i18n.t('Map.controls.radius'));
        _defineProperty(this, "icon", 'far fa-circle');
        _defineProperty(this, "source", null);
      }
      createInteractions() {
        var source = this.source;
        var circleSource = new _Vector2.default();
        var drawCircles = new _interaction.Draw({
          source: circleSource,
          type: 'Circle'
        });
        this.addEventListener(drawCircles, 'drawend', evt => {
          const polygon = (0, _Polygon.fromCircle)(evt.feature.getGeometry());
          const feature = new _Feature.default({
            geometry: polygon
          });
          source.addFeature(feature);
        });
        return [drawCircles, new _interaction.DragPan()];
      }
    },
    Clear: class ClearTool extends ToolButton {
      constructor(...args) {
        super(...args);
        _defineProperty(this, "name", i18n.t('Map.controls.clearMap'));
        _defineProperty(this, "icon", 'fas fa-times');
        _defineProperty(this, "source", null);
        _defineProperty(this, "interactions", []);
      }
      // XXX

      clicked() {
        this.source.clear();
      }
    },
    Select: class SelectTool extends InteractionButton {
      constructor(...args) {
        super(...args);
        _defineProperty(this, "name", i18n.t('General.Select'));
        _defineProperty(this, "icon", 'fas fa-hand-point-up');
        _defineProperty(this, "interactions", []);
        _defineProperty(this, "layers", []);
        _defineProperty(this, "style", undefined);
      }
      selectionChanged(selected) {
        //
      }
      filter(feature, layer) {
        return true;
      }
      clearSelection() {
        this.select.getFeatures().clear();
      }
      createInteractions() {
        var self = this;
        var select = new _interaction.Select({
          layers: self.get('layers'),
          filter: this.filter,
          style: false
        });

        // Select interaction "supports" styling selected features, however
        // the style is applied to the selection layer, so the feature style
        // takes presedence. Because of that, we handle restyling manually

        function selectionChanged() {
          self.selectionChanged(select.getFeatures().getArray());
        }
        var features = select.getFeatures();
        const addKey = features.on('add', function (evt) {
          var feature = evt.element;
          if (!feature.get('selected')) {
            var style = feature.getStyle();
            feature.set('_oldStyle', style);
            feature.set('selected', true);
            feature.setStyle(self.getStyle(feature));
          }
          selectionChanged();
        });
        const removeKey = features.on('remove', function (evt) {
          if (evt.element.get('selected')) {
            var style = evt.element.get('_oldStyle');
            evt.element.setStyle(style);
            evt.element.set('selected', false);
          }
          selectionChanged();
        });
        const changeKey = select.on('change:active', function () {
          features.getArray().forEach(function (feature) {
            feature.set('selected', false);
          });
          features.clear();
          selectionChanged();
        });
        this.set('select', select);
        this.set('features', features);
        this.set('handlerKeys', [addKey, removeKey, changeKey]);
        return (0, _interaction.defaults)().getArray().concat(select);
      }
      willDestroy() {
        super.willDestroy(...arguments);
        this.handlerKeys.forEach(_Observable.unByKey);
        this.set('features', null);
        this.set('select', null);
        this.set('handlerKeys', []);
      }
    }
  };
  let FullscreenButton = (_dec10 = Ember.computed(), (_class8 = class FullscreenButton extends MapControl {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "name", i18n.t('Map.fullscreen'));
    }
    clicked(inFullscreen) {
      //
    }
    get control() {
      var label = document.createElement('span');
      label.className = 'fas fa-expand';
      var labelActive = document.createElement('span');
      labelActive.className = 'fas fa-compress';
      return new _control.FullScreen({
        label: label,
        labelActive: labelActive,
        target: this.target
      });
    }
  }, (_applyDecoratedDescriptor(_class8.prototype, "control", [_dec10], Object.getOwnPropertyDescriptor(_class8.prototype, "control"), _class8.prototype)), _class8));
  let ZoomControl = (_dec11 = Ember.computed(), (_class9 = class ZoomControl extends MapControl {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "name", 'Zoom');
    }
    get control() {
      return new _control.Zoom({
        target: this.target
      });
    }
  }, (_applyDecoratedDescriptor(_class9.prototype, "control", [_dec11], Object.getOwnPropertyDescriptor(_class9.prototype, "control"), _class9.prototype)), _class9));
  let AttributionControl = (_dec12 = Ember.computed(), (_class10 = class AttributionControl extends MapControl {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "name", 'Attribute');
    }
    get control() {
      return new _control.Attribution({
        collapsible: false
      });
    }
  }, (_applyDecoratedDescriptor(_class10.prototype, "control", [_dec12], Object.getOwnPropertyDescriptor(_class10.prototype, "control"), _class10.prototype)), _class10));
  class MousePositionWithoutEmptyPadding extends _control.MousePosition {
    handleMouseOut(event) {
      super.handleMouseOut(event);
      this.element.hidden = true;
    }
    handleMouseMove(event) {
      this.element.hidden = false;
      super.handleMouseMove(event);
    }
  }
  let MousePositionControl = (_dec13 = Ember.computed(), (_class11 = class MousePositionControl extends MapControl {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "name", 'Mouse position');
      _defineProperty(this, "projection", null);
      _defineProperty(this, "target", undefined);
    }
    get control() {
      return new MousePositionWithoutEmptyPadding({
        projection: this.projection,
        target: this.target,
        coordinateFormat(coords) {
          return coords[0].toFixed(2) + ' ' + coords[1].toFixed(2);
        }
      });
    }
  }, (_applyDecoratedDescriptor(_class11.prototype, "control", [_dec13], Object.getOwnPropertyDescriptor(_class11.prototype, "control"), _class11.prototype)), _class11));
  let ScaleLineControl = (_dec14 = Ember.computed(), (_class12 = class ScaleLineControl extends MapControl {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "name", 'Scale line');
    }
    get control() {
      return new _control.ScaleLine({
        target: this.target
      });
    }
  }, (_applyDecoratedDescriptor(_class12.prototype, "control", [_dec14], Object.getOwnPropertyDescriptor(_class12.prototype, "control"), _class12.prototype)), _class12));
  function layersetFromSource(id, name, source) {
    return BaseLayerSet.create({
      id,
      name,
      layers: [MapTileLayer.create({
        source: source
      })],
      internalProjection: source.getProjection(),
      externalProjection: (0, _proj.get)('EPSG:4326')
    });
  }
  function OSMLayerSet() {
    return layersetFromSource('osm', 'OpenStreetMap 3', new _OSM.default());
  }
  function MapboxLayerSet() {
    const layerset = layersetFromSource('mapbox', 'Mapbox Satellite', new _XYZ.default({
      tileSize: [512, 512],
      url: 'https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v9/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoicGx1dG9mIiwiYSI6ImNpcWo4OHV3MTAwYzVocG1jZHZpcmYwM3kifQ.EJxPtfHAmx70baunS5WoiA',
      attributions: ['© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>']
    }));
    layerset.set('maxZoom', 18);
    return layerset;
  }
  function MaaametLayerSet() {
    var internalProjection = (0, _proj.get)('EPSG:3301');
    var externalProjection = (0, _proj.get)('EPSG:4326');

    // http://tiles.maaamet.ee/tm/wmts?service=WMTS&version=1.0.0&request=GetCapabilities
    const MA_GRID_PARAMS = {
      extent: [40500, 5993000, 1064500, 7017000],
      origin: [40500, 5993000],
      resolutions: [4000, 2000, 1000, 500, 250, 125, 62.5, 31.25, 15.625, 7.8125, 3.90625, 1.953125, 0.9765625, 0.48828125],
      matrixIds: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13'],
      tileSize: [256, 256]
    };
    function loadTile(imageTile, src) {
      // TODO: Replace TileRow with regex?
      const {
        endpoint,
        params
      } = (0, _abstract.decodeURL)(src);
      const matrixWidth = MA_SIZES[MA_GRID_PARAMS.matrixIds.indexOf(params['TileMatrix'])][0];
      params['TileRow'] = matrixWidth + parseInt(params['TileRow']);
      params['format'] = 'binary';
      imageTile.getImage().src = (0, _abstract.construct_request)(endpoint, params);
    }
    function createMALayerSource(layer, attribution = undefined) {
      return new _WMTS.default({
        url: _environment.default.API_HOST + '/geography/estonian-maps/',
        tileGrid: new _WMTS2.default(MA_GRID_PARAMS),
        projection: internalProjection,
        layer: layer,
        style: 'default',
        matrixSet: 'LEST',
        format: 'image/png',
        attributions: attribution,
        tileLoadFunction: loadTile
      });
    }
    const base = createMALayerSource('kaart', i18n.t('Map.attribution.maaametBase'));
    const ortophoto = createMALayerSource('foto', i18n.t('Map.attribution.maaametOrtophoto'));
    return BaseLayerSet.create({
      id: 'maaamet',
      name: i18n.t('Map.layers.maaamet'),
      layers: [MapTileLayer.create({
        source: base
      })],
      optionalLayers: [TileOptionalLayer.create({
        name: i18n.t('Map.layers.ortophoto'),
        source: ortophoto,
        code: 'eesti_ortophoto'
      }), JSONFeatureLayer.create({
        name: i18n.t('Map.layers.kaitsealad'),
        url: 'assets/data/kaitsealad.geojson',
        labelProperty: 'kr_kood',
        maximumResolution: 100,
        code: 'eesti_nature_reserves'
      }), JSONFeatureLayer.create({
        name: i18n.t('Map.layers.waterbirds'),
        url: 'assets/data/waterbirds.geojson',
        labelProperty: 'MIFCODE',
        maximumResolution: 160,
        code: 'eesti_water_birds'
      }), JSONFeatureLayer.create({
        name: i18n.t('Map.layers.taimeatlas'),
        url: 'assets/data/taimeatlase_ruudud.json',
        labelProperty: 'ruudu_kood',
        maximumResolution: 160,
        code: 'eesti_plant_atlas_grid'
      }), JSONFeatureLayer.create({
        name: i18n.t('Map.layers.grid2x2'),
        url: 'assets/data/grid2x2.geojson',
        labelProperty: 'Id',
        maximumResolution: 30,
        code: 'eesti_2x2_grid'
      })],
      internalProjection: internalProjection,
      externalProjection: externalProjection,
      extent: [289558.5, 6312054, 845558.5, 6708054],
      maxResolution: 1000
    });
  }
  function geoqueryCountry(store, lon, lat) {
    var countryRequestURL = (0, _abstract.construct_request)(_environment.default.API_HOST + '/geography/countries/point_location/', {
      latitude: lat,
      longitude: lon
    });
    const ajax = (0, _ajax.ajaxFromControlled)(store);
    return ajax.request(countryRequestURL).then(function (response) {
      if (Ember.isNone(response.name)) {
        return null;
      }
      var countryId = (0, _reflection.recordURLToID)(response.url);
      return store.findRecord('geography/country', countryId);
    });
  }
  function requestEHAK(ajax, lon, lat) {
    var request_url = (0, _abstract.construct_request)(_environment.default.API_HOST + '/geography/ehaks/geolocations/', {
      latitude: lat,
      longitude: lon
    });
    return ajax.request(request_url).then(function (response) {
      if (!Ember.isNone(response.error) || Ember.isNone(response.geo_location)) {
        return null;
      }
      return response.geo_location;
    }, function (response) {
      return null;
    });
  }
  function getFormattedCentroid(geom) {
    if (Ember.isEmpty(geom)) {
      return {
        lat: null,
        lon: null
      };
    } else {
      const center = getWKTCenter(geom);
      return {
        lon: (0, _abstract.decimal_round)(center[0], _config.default.Sample.COORDINATE_DECIMAL_PLACES),
        lat: (0, _abstract.decimal_round)(center[1], _config.default.Sample.COORDINATE_DECIMAL_PLACES)
      };
    }
  }
  function getGeometryCenter(geometry) {
    // XXX: Code below lies, center calculation is much more involved in most projections.
    // However, OL3 doesn't contain anything, so here we go

    // For points it's way off, but at least we can get the center in this case
    if (geometry.getType() === 'Point') {
      return geometry.getCoordinates();
    }
    var extent = geometry.getExtent();
    return [(extent[0] + extent[2]) / 2, (extent[1] + extent[3]) / 2];
  }

  // SRID=4326;POINT (x y) -> POINT (x y)
  function stripSRID(geom) {
    if (geom.indexOf('SRID') !== -1) {
      const [sridSpec, wkt] = geom.split(';');
      const srid = sridSpec.slice('SRID='.length);
      if (srid !== '4326') {
        throw `Unknown SRID: ${srid}, map assumes that geometry is provided in 4326`;
      }
      geom = wkt;
    }
    return geom;
  }
  function getWKTCenter(geom) {
    var geometry = new _format.WKT().readGeometry(stripSRID(geom));
    return getGeometryCenter(geometry);
  }
  function getParish(ajax, lng, lat) {
    const url = (0, _abstract.construct_request)(GEO_LAYER_ENDPOINT, {
      lng: lng,
      lat: lat
    });
    return ajax.request(url);
  }
  var _default = _exports.default = {
    BaseLayerSet: BaseLayerSet,
    Control: MapControl,
    OptionalLayer: OptionalLayer,
    LayerSelection: LayerSelection,
    MaaametLayerSet: MaaametLayerSet,
    layersets: {
      OSM: OSMLayerSet,
      Mapbox: MapboxLayerSet,
      Maaamet: MaaametLayerSet
    },
    controls: {
      FullscreenButton: FullscreenButton,
      Zoom: ZoomControl,
      Attribution: AttributionControl,
      MousePosition: MousePositionControl,
      ScaleLine: ScaleLineControl,
      Button: Button,
      toolButtons: ToolPanelButtons
    },
    optionalLayers: {
      Heatmap: HeatmapLayer
    }
  };
});