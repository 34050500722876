define("plutof/templates/components/tab-group/tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "eCihXr1P",
    "block": "{\"symbols\":[\"&default\",\"&attrs\",\"@dontPrecreate\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"single-tab \",[30,[36,0],[[32,0,[\"tab\",\"selected\"]],\"single-tab--active\"],null]]]],[17,2],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"tab-content\"],[12],[2,\"\\n\"],[6,[37,0],[[30,[36,1],[[32,3],false],null]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,0],[[32,0,[\"tab\",\"selected\"]]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"            \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"default-to\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/tab-group/tab.hbs"
    }
  });
});