define("plutof/routes/taxonomy/trees", ["exports", "plutof/misc/config", "ember-simple-auth/mixins/authenticated-route-mixin", "plutof/mixins/route", "plutof/utils/loading"], function (_exports, _config, _authenticatedRouteMixin, _route, _loading) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class TaxonomyTreesRoute extends Ember.Route.extend(_authenticatedRouteMixin.default, _route.default) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "title", 'Taxonomy.treesTitle');
      _defineProperty(this, "defaultReturnRoute", 'taxonomy-lab.index');
    }
    async model(params) {
      const treesPromise = (0, _loading.loadAll)(this.store, 'taxonomy/tree');
      let selectedTaxon, selectedTree;
      if (params && !Ember.isEmpty(params.taxon_id)) {
        selectedTaxon = await this.store.findRecord('taxonomy/taxonnode', params.taxon_id);
      }
      const trees = await treesPromise;
      if (selectedTaxon) {
        selectedTree = await selectedTaxon.tree;
      } else {
        const treeID = params && !Ember.isEmpty(params.tree_id) ? params.tree_id : _config.default.Taxonomy.DEFAULT_TAXONOMY_ID;
        selectedTree = await this.store.findRecord('taxonomy/tree', treeID);
      }
      return {
        trees,
        selectedTree,
        selectedTaxon
      };
    }
    setupController(controller, model) {
      super.setupController(controller, model);
      controller.setProperties({
        selectedTree: model.selectedTree,
        selectedTaxon: model.selectedTaxon
      });
      controller.checkTreeRights.perform();
    }
  }
  var _default = _exports.default = TaxonomyTreesRoute;
});