define("plutof/components/specimen/measurements", ["exports", "ember-cp-validations", "plutof/mixins/component-validations"], function (_exports, _emberCpValidations, _componentValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Measurements::AddExtra::Panel
      @measurements={{@measurements}}
      @module="taxon_occurrence"
  >
      <Layout::RegularGrid @columns={{6}}>
          {{#if this.showIsolationFields}}
              <div class="{{unless this.isolationDateValidity 'has-error'}}">
                  {{plutof-labelc "specimen.attrib_isolation_date"}}
  
                  <DatePicker
                      @value={{@specimen.attrib_isolation_date}}
                      @validity={{this.isolationDateValidity}} />
              </div>
  
              <div class="{{unless this.emergenceDateValidity 'has-error'}}">
                  {{plutof-labelc "specimen.attrib_emergence_date"}}
  
                  <DatePicker
                      @value={{@specimen.attrib_emergence_date}}
                      @validity={{this.emergenceDateValidity}} />
              </div>
          {{/if}}
      </Layout::RegularGrid>
  
      <Measurements::MeasurementForm
          @object={{@specimen}}
          @mainform={{@specimen.mainform}}
          @data={{@measurements}}
          @row_length={{6}}
          @validationContext={{@validationContext.measurements}} />
  </Measurements::AddExtra::Panel>
  
  */
  {
    "id": "yUaiOlV2",
    "block": "{\"symbols\":[\"@specimen\",\"@measurements\",\"@validationContext\"],\"statements\":[[8,\"measurements/add-extra/panel\",[],[[\"@measurements\",\"@module\"],[[32,2],\"taxon_occurrence\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"layout/regular-grid\",[],[[\"@columns\"],[6]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[32,0,[\"showIsolationFields\"]]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"div\"],[15,0,[31,[[30,[36,0],[[32,0,[\"isolationDateValidity\"]],\"has-error\"],null]]]],[12],[2,\"\\n                \"],[1,[30,[36,1],[\"specimen.attrib_isolation_date\"],null]],[2,\"\\n\\n                \"],[8,\"date-picker\",[],[[\"@value\",\"@validity\"],[[32,1,[\"attrib_isolation_date\"]],[32,0,[\"isolationDateValidity\"]]]],null],[2,\"\\n            \"],[13],[2,\"\\n\\n            \"],[10,\"div\"],[15,0,[31,[[30,[36,0],[[32,0,[\"emergenceDateValidity\"]],\"has-error\"],null]]]],[12],[2,\"\\n                \"],[1,[30,[36,1],[\"specimen.attrib_emergence_date\"],null]],[2,\"\\n\\n                \"],[8,\"date-picker\",[],[[\"@value\",\"@validity\"],[[32,1,[\"attrib_emergence_date\"]],[32,0,[\"emergenceDateValidity\"]]]],null],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,\"measurements/measurement-form\",[],[[\"@object\",\"@mainform\",\"@data\",\"@row_length\",\"@validationContext\"],[[32,1],[32,1,[\"mainform\"]],[32,2],6,[32,3,[\"measurements\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"unless\",\"plutof-labelc\",\"if\"]}",
    "meta": {
      "moduleName": "plutof/components/specimen/measurements.hbs"
    }
  });
  const Validations = (0, _emberCpValidations.buildValidations)({
    'isolationDateValidity': (0, _emberCpValidations.validator)('is-truthy'),
    'emergenceDateValidity': (0, _emberCpValidations.validator)('is-truthy')
  });
  let SpecimenMeasurements = (_dec = Ember.computed.reads('specimen.mainform.attrib_emergence_date'), (_class = class SpecimenMeasurements extends Ember.Component.extend(Validations, _componentValidations.default) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "showIsolationFields", _descriptor, this);
      _defineProperty(this, "validationChildren", ['measurements']);
    }
    didReceiveAttrs() {
      super.didReceiveAttrs();
      this.set('isolationDateValidity', true);
      this.set('emergenceDateValidity', true);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "showIsolationFields", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SpecimenMeasurements);
});