define("plutof/components/access/edit/only-is-public", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "General.access"}}
  >
      <Common::LocalLabel @label="Access.canView" />
  
      <Access::Edit::PublicOrPrivate
          @value={{@isPublic}}
          @update={{@updateIsPublic}} />
  </PlutofPanel::Simple>
  
  */
  {
    "id": "ZLAsJyAG",
    "block": "{\"symbols\":[\"@isPublic\",\"@updateIsPublic\"],\"statements\":[[8,\"plutof-panel/simple\",[],[[\"@title\"],[[30,[36,0],[\"General.access\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"common/local-label\",[],[[\"@label\"],[\"Access.canView\"]],null],[2,\"\\n\\n    \"],[8,\"access/edit/public-or-private\",[],[[\"@value\",\"@update\"],[[32,1],[32,2]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\"]}",
    "meta": {
      "moduleName": "plutof/components/access/edit/only-is-public.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});