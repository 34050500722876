define("plutof/translations/est/taxon-description", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    taxonDesc: 'Taksonikirjeldus',
    newTaxonDesc: 'Uus taksonikirjeldus',
    editTaxonDesc: 'Muuda taksonikirjeldust',
    conservationInstructions: 'Kaitsejuhised'
  };
});