define("plutof/components/clipboard/bulk-update-sequence-access", ["exports", "@glimmer/component", "plutof/components/clipboard/bulk-update"], function (_exports, _component, _bulkUpdate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Common::LocalLabel @label="Access.canView" />
  
  <Access::Edit::PublicOrPrivate
      @value={{@data.data.public}}
      @update={{this.setPublic}} />
  
  */
  {
    "id": "czTba6Fi",
    "block": "{\"symbols\":[\"@data\"],\"statements\":[[8,\"common/local-label\",[],[[\"@label\"],[\"Access.canView\"]],null],[2,\"\\n\\n\"],[8,\"access/edit/public-or-private\",[],[[\"@value\",\"@update\"],[[32,1,[\"data\",\"public\"]],[32,0,[\"setPublic\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "plutof/components/clipboard/bulk-update-sequence-access.hbs"
    }
  });
  let BulkUpdateSequenceAccess = (_dec = Ember._action, (_class = class BulkUpdateSequenceAccess extends _component.default {
    constructor(...args) {
      super(...args);

      // XXX Dummy input - not visible in UI but isActive status is used to enable the update button.
      const inputs = {
        dummy: (0, _bulkUpdate.createInput)('string', {
          labelPath: 'Access.canView',
          labelSource: 'local',
          isActive: true
        })
      };

      // XXX, See bulk-update-specimen-general
      Ember.run.next(() => {
        Ember.set(this.args, 'data.inputs', (0, _bulkUpdate.processInputs)(inputs, this.args.data));
      });
    }
    setPublic(isPublic) {
      Ember.set(this.args.data, 'data.visible', isPublic ? 'PUBLIC' : 'PRIVATE');
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "setPublic", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "setPublic"), _class.prototype)), _class));
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, BulkUpdateSequenceAccess);
});