define("plutof/templates/components/import/process-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "1nWSdFZW",
    "block": "{\"symbols\":[\"process\",\"@stop\",\"@download\",\"@delete\",\"@processes\"],\"statements\":[[8,\"plutof-panel/headerless\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"table-responsive\"],[12],[2,\"\\n        \"],[10,\"table\"],[14,0,\"plutof-table table-hover\"],[12],[2,\"\\n            \"],[10,\"thead\"],[12],[2,\"\\n                \"],[10,\"tr\"],[12],[2,\"\\n                    \"],[10,\"th\"],[12],[1,[30,[36,1],[\"Search.module\"],null]],[13],[2,\"\\n                    \"],[10,\"th\"],[12],[1,[30,[36,1],[\"Model.file\"],null]],[13],[2,\"\\n                    \"],[10,\"th\"],[12],[1,[30,[36,1],[\"PlutofListViewColumn.status\"],null]],[13],[2,\"\\n                    \"],[10,\"th\"],[12],[1,[30,[36,1],[\"Import.progress.progress\"],null]],[13],[2,\"\\n                    \"],[10,\"th\"],[12],[1,[30,[36,1],[\"PlutofListViewColumn.modified\"],null]],[13],[2,\"\\n                    \"],[10,\"th\"],[12],[1,[30,[36,1],[\"UniteSH.actions\"],null]],[13],[2,\"\\n                \"],[13],[2,\"\\n            \"],[13],[2,\"\\n\\n            \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"                    \"],[8,\"import/process-list/row\",[],[[\"@process\",\"@stop\",\"@sendToClipboard\",\"@download\",\"@delete\"],[[32,1],[30,[36,0],[[32,2],[32,1]],null],[30,[36,0],[[32,0,[\"sendToClipboard\"]],[32,1]],null],[30,[36,0],[[32,3],[32,1]],null],[30,[36,0],[[32,4],[32,1]],null]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"i18n-t\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/import/process-list.hbs"
    }
  });
});