define("plutof/components/common/data-quality/warning/no-geom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <ToolTip
      @tooltip={{i18n-t 'DataQuality.warnings.geom'}}
      @class="plutof-tooltip-hover--inline"
  >
      <span class="fas fa-map-marker-alt data-quality-warning data-quality-warning--icon"></span>
  </ToolTip>
  
  */
  {
    "id": "EEE192u+",
    "block": "{\"symbols\":[],\"statements\":[[8,\"tool-tip\",[],[[\"@tooltip\",\"@class\"],[[30,[36,0],[\"DataQuality.warnings.geom\"],null],\"plutof-tooltip-hover--inline\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"span\"],[14,0,\"fas fa-map-marker-alt data-quality-warning data-quality-warning--icon\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\"]}",
    "meta": {
      "moduleName": "plutof/components/common/data-quality/warning/no-geom.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});