define("plutof/components/publishing/gbif/view/agent-row", ["exports", "@ember-decorators/component", "plutof/utils/formatters"], function (_exports, _component, _formatters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _class2;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <td class="generic-view-value">{{@agent.first_name}}</td>
  <td class="generic-view-value">{{@agent.last_name}}</td>
  <td class="generic-view-value">{{@agent.type}}</td>
  <td class="generic-view-value">{{@agent.position}}</td>
  <td class="generic-view-value">{{@agent.email}}</td>
  
  <td class="generic-view-value">
      {{#if this.orcidUrlValidity}}
          <a href="{{@agent.orcid_identifier}}" target="_blank">
              {{@agent.orcid_identifier}}
          </a>
      {{else}}
          {{@agent.orcid_identifier}}
      {{/if}}
  </td>
  
  */
  {
    "id": "UbnCVZXx",
    "block": "{\"symbols\":[\"@agent\"],\"statements\":[[10,\"td\"],[14,0,\"generic-view-value\"],[12],[1,[32,1,[\"first_name\"]]],[13],[2,\"\\n\"],[10,\"td\"],[14,0,\"generic-view-value\"],[12],[1,[32,1,[\"last_name\"]]],[13],[2,\"\\n\"],[10,\"td\"],[14,0,\"generic-view-value\"],[12],[1,[32,1,[\"type\"]]],[13],[2,\"\\n\"],[10,\"td\"],[14,0,\"generic-view-value\"],[12],[1,[32,1,[\"position\"]]],[13],[2,\"\\n\"],[10,\"td\"],[14,0,\"generic-view-value\"],[12],[1,[32,1,[\"email\"]]],[13],[2,\"\\n\\n\"],[10,\"td\"],[14,0,\"generic-view-value\"],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"orcidUrlValidity\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"a\"],[15,6,[31,[[32,1,[\"orcid_identifier\"]]]]],[14,\"target\",\"_blank\"],[12],[2,\"\\n            \"],[1,[32,1,[\"orcid_identifier\"]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[32,1,[\"orcid_identifier\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "plutof/components/publishing/gbif/view/agent-row.hbs"
    }
  });
  let AgentRow = (_dec = (0, _component.tagName)('tr'), _dec2 = Ember.computed('agent.orcid_identifier'), _dec(_class = (_class2 = class AgentRow extends Ember.Component {
    get orcidUrlValidity() {
      return _formatters.ORCID_URL_REGEX.test(this.agent.orcid_identifier);
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "orcidUrlValidity", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "orcidUrlValidity"), _class2.prototype)), _class2)) || _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, AgentRow);
});