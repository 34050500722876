define("plutof/services/geocoding", ["exports", "ember-ajax/request", "plutof/config/environment", "plutof/misc/abstract", "plutof/misc/config", "plutof/services/ajax", "plutof/utils/map", "plutof/utils/notifications"], function (_exports, _request, _environment, _abstract, _config, _ajax, _map, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function dummyProvider(lon, lat) {
    return (0, _abstract.wrap_as_promise)({
      state: null,
      district: null,
      commune: null,
      locality_text: null
    });
  }
  function ehakProvider(store, lon, lat) {
    const ajax = (0, _ajax.ajaxFromControlled)(store);
    return (0, _map.requestEHAK)(ajax, lon, lat).then(function (ehak) {
      if (!Ember.isEmpty(ehak)) {
        return store.findRecord('geography/country', _config.default.Sample.ESTONIA_COUNTRY_ID).then(function (estonia) {
          return {
            country: estonia,
            state: null,
            district: ehak.district,
            commune: ehak.commune,
            locality_text: ehak.name
          };
        });
      } else {
        return null;
      }
    });
  }
  const ADMIN_LEVELS = ['administrative_area_level_1', 'administrative_area_level_2', 'administrative_area_level_3', 'administrative_area_level_4', 'administrative_area_level_5'];
  function googleProvider(store, lon, lat) {
    const endpoint = 'https://maps.googleapis.com/maps/api/geocode/json';
    const url = (0, _abstract.construct_request)(endpoint, {
      latlng: lat + ',' + lon,
      key: _environment.default.GOOGLE_GEOCODING_API_KEY,
      language: 'en',
      result_type: ADMIN_LEVELS.join('|')
    });
    return (0, _request.default)(url, {
      method: 'GET',
      noAuth: true,
      xhrFields: {
        withCredentials: false
      }
    }).then(function (response) {
      // zero is ok
      if (response.status !== 'OK' && response.status !== 'ZERO_RESULTS') {
        (0, _notifications.reportError)('Google geocoding failed: ' + response.status);
        return null;
      }
      var levelEntries = {};
      response.results.forEach(function (result) {
        result.address_components.forEach(function (component) {
          component.types.forEach(function (type) {
            if (ADMIN_LEVELS.includes(type)) {
              if (Ember.isNone(levelEntries[type])) {
                levelEntries[type] = [];
              }
              levelEntries[type].addObject(component.long_name);
            }
          });
        });
      });
      const availableLevels = ADMIN_LEVELS.filter(level => level in levelEntries);
      const parts = availableLevels.map(level => levelEntries[level].join('/'));
      return {
        // Could parse country here but we already have a reliable mechanism of
        // getting country by coordinates. The only reason country is returned
        // is because of ehak polygons including sea areas
        country: null,
        district: parts[0],
        commune: parts[1],
        locality_text: parts.slice(2).join('; ')
      };
    });
  }
  function compoundProvider(store, providers, lon, lat) {
    if (providers.length === 0) {
      return null;
    }
    const currentProvider = providers.shift();
    return currentProvider(store, lon, lat).then(function (result) {
      return Ember.isPresent(result) ? result : compoundProvider(store, providers, lon, lat);
    });
  }
  let GeoCoding = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class GeoCoding extends Ember.Service {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "emberDataStore", _descriptor2, this);
      _initializerDefineProperty(this, "i18n", _descriptor3, this);
    }
    // Reverse geocoding
    resolve(lon, lat) {
      const providers = [ehakProvider, googleProvider, dummyProvider];
      return compoundProvider(this.emberDataStore, providers, lon, lat);
    }

    // Geocoding
    search(query) {
      const endpoint = 'https://maps.googleapis.com/maps/api/geocode/json';

      // NB: ajax service doesn't work here
      return (0, _request.default)(endpoint, {
        method: 'GET',
        data: {
          address: query,
          key: _environment.default.GOOGLE_GEOCODING_API_KEY,
          language: 'en',
          result_type: ''
        },
        noAuth: true,
        xhrFields: {
          withCredentials: false
        }
      }).then(response => {
        if (response.status !== 'OK') {
          // TODO Wrap in Error properly, but this needs to be done
          // without triggering rollbar publish
          throw this.i18n.translate('Sample.area.map.searchFailed', {
            hash: {
              query
            }
          });
        }
        const coords = response.results[0].geometry.location;
        return {
          lon: coords.lng,
          lat: coords.lat
        };
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "emberDataStore", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "i18n", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  var _default = _exports.default = GeoCoding;
});