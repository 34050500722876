define("plutof/adapters/dna-lab/dna", ["exports", "plutof/adapters/jsonapi-base", "plutof/utils/push-to-store", "plutof/serializers/jsonapi-base"], function (_exports, _jsonapiBase, _pushToStore, _jsonapiBase2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class DNAAdapter extends _jsonapiBase.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "modelName", 'dna-lab/dna');
      _defineProperty(this, "partialIncludes", new Set(['Specimen', 'MaterialSample', 'LivingSpecimen']));
    }
    handleResponse(status, headers, payload, requestData) {
      // XXX: Somethimes we don't get payload.
      // TODO: Find out why.
      if (payload && payload.included) {
        let included = [];
        for (let {
          id,
          type,
          attributes
        } of payload.included) {
          const model = _jsonapiBase2.RESOURCE_TYPE_TO_MODEL[type];
          if (model) {
            const partial = (0, _pushToStore.default)(this.store, model, Object.assign({
              id
            }, attributes));
            partial.set('__partial', true);
            included.push(partial);
          }
        }
      }
      return super.handleResponse(...arguments);
    }
  }
  var _default = _exports.default = DNAAdapter;
});