define("plutof/components/filerepository/auto-complete", ["exports", "@glimmer/component", "ember-concurrency", "plutof/components/auto-complete/backends", "plutof/misc/fileupload"], function (_exports, _component, _emberConcurrency, _backends, _fileupload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <AutoComplete::Base
      @backend={{this.backend}}
      @value={{@value}}
      @update={{@update}}
      @params={{merge-params @params (hash
          sideAction=(if @localSelected (perform this.selectLocal))
          sideActionIcon=(icon "upload")
      )}}
      ...attributes
  >
      <:result as |result context|>
          {{#if result.full.type}}
              <Filerepository::TypeIcon @type={{result.full.type}} />
          {{/if}}
  
          {{hl-subs result.value context.query}}
  
          {{#if result.full.owner}}
              <span class="plutof-ac-suggestion-description">
                  {{result.full.owner}}
              </span>
          {{/if}}
      </:result>
  </AutoComplete::Base>
  
  */
  {
    "id": "ShGuG1dJ",
    "block": "{\"symbols\":[\"__arg0\",\"__arg1\",\"__arg2\",\"result\",\"context\",\"@value\",\"@update\",\"@localSelected\",\"@params\",\"&attrs\"],\"statements\":[[8,\"auto-complete/base\",[[17,10]],[[\"@backend\",\"@value\",\"@update\",\"@params\",\"@namedBlocksInfo\"],[[32,0,[\"backend\"]],[32,6],[32,7],[30,[36,6],[[32,9],[30,[36,5],null,[[\"sideAction\",\"sideActionIcon\"],[[30,[36,0],[[32,8],[30,[36,4],[[32,0,[\"selectLocal\"]]],null]],null],[30,[36,3],[\"upload\"],null]]]]],null],[30,[36,5],null,[[\"result\"],[2]]]]],[[\"default\"],[{\"statements\":[[6,[37,0],[[30,[36,7],[[32,1],\"result\"],null]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[32,2],[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,0],[[32,4,[\"full\",\"type\"]]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"filerepository/type-icon\",[],[[\"@type\"],[[32,4,[\"full\",\"type\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n        \"],[1,[30,[36,1],[[32,4,[\"value\"]],[32,5,[\"query\"]]],null]],[2,\"\\n\\n\"],[6,[37,0],[[32,4,[\"full\",\"owner\"]]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"span\"],[14,0,\"plutof-ac-suggestion-description\"],[12],[2,\"\\n                \"],[1,[32,4,[\"full\",\"owner\"]]],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"]],\"parameters\":[4,5]}]]]],\"parameters\":[]}]]]],\"parameters\":[1,2,3]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"hl-subs\",\"let\",\"icon\",\"perform\",\"hash\",\"merge-params\",\"-is-named-block-invocation\"]}",
    "meta": {
      "moduleName": "plutof/components/filerepository/auto-complete.hbs"
    }
  });
  let FileAutoComplete = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed('args.access', 'args.filters'), _dec5 = (0, _emberConcurrency.task)({
    restartable: true
  }), (_class = class FileAutoComplete extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "settings", _descriptor2, this);
      _initializerDefineProperty(this, "store", _descriptor3, this);
      _initializerDefineProperty(this, "selectLocal", _descriptor4, this);
    }
    // TODO: Might as well fold access into filters on call site,
    // now that we just have to pass to them here
    get backend() {
      const filters = Object.assign({}, this.args.filters || {});
      if (this.args.access) {
        filters.access = this.args.access;
      }
      return new _backends.ModelAutocomplete({
        ajax: this.ajax,
        store: this.store,
        modelName: 'filerepository/file',
        filters
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "settings", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "backend", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "backend"), _class.prototype), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "selectLocal", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* (update) {
        const files = yield (0, _fileupload.selectFile)({
          multiple: !this.args.localSelectSingle
        });
        if (files && this.args.localSelected) {
          this.args.localSelected(files);
        }
      };
    }
  })), _class));
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FileAutoComplete);
});