define("plutof/components/time-input", ["exports", "@ember-decorators/component", "@ember-decorators/object", "plutof/utils/i18n-translated"], function (_exports, _component, _object, _i18nTranslated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const AMBIGUOUS_TIME_REGEXP = new RegExp('^([0-9]|0[0-9]|1[0-9]|2[0-3])\\D?([0-5]|[0-5][0-9]|)$');
  const MAXIMUM_HOURS = 23;
  let TimeInput = (_dec = (0, _component.classNames)('time-input'), _dec2 = (0, _i18nTranslated.default)('General.timePlaceholder'), _dec3 = (0, _object.observes)('value'), _dec4 = (0, _object.on)('focusOut'), _dec(_class = (_class2 = class TimeInput extends Ember.TextField {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "validity", true);
      _initializerDefineProperty(this, "placeholder", _descriptor, this);
    }
    init() {
      super.init(...arguments);
      this.inputObserver();
    }
    addZeroes(string) {
      const halves = string.split(':');
      if (halves[1].length < 2) {
        return this.addZeroes(string + '0');
      } else if (halves[0].length < 2) {
        return this.addZeroes('0' + string);
      } else {
        return string;
      }
    }
    getSeparatorIndex(input) {
      // Set the separator after the first or the second digit, depending on the length of the input.
      let separatorIndex = input.length === 1 || input.length === 3 ? 1 : 2;
      /*
      In case of two digits of value over 23, set separator to the beginning of the input for then it will be
      considered to be minutes, not hours.
      */
      separatorIndex = input.length === 2 && input > MAXIMUM_HOURS ? 0 : separatorIndex;
      return separatorIndex;
    }
    correctInput(input) {
      if (Ember.isEmpty(input)) {
        return null;
      }
      const value = input.toString().trim();
      if (AMBIGUOUS_TIME_REGEXP.test(value)) {
        // Check whether or not the value is digits only.
        if (value.match(/^(\d+)$/)) {
          const separatorIndex = this.getSeparatorIndex(value);
          return this.addZeroes(value.substr(0, separatorIndex) + ':' + value.substr(separatorIndex, value.length));
        } else {
          return this.addZeroes(value.replace(/\D/, ':'));
        }
      } else {
        return null;
      }
    }

    // Triggers on keypress.
    keyDown(event) {
      if (event.keyCode === 13) {
        this.completeShortTimeInput();
      }
    }
    inputObserver() {
      const value = this.value;
      const time = this.correctInput(value);
      this.set('validity', !Ember.isNone(time) || Ember.isEmpty(value));
    }
    completeShortTimeInput() {
      const time = this.correctInput(this.value);
      Ember.run.next(this, function () {
        this.set('value', time);
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "placeholder", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "inputObserver", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "inputObserver"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "completeShortTimeInput", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "completeShortTimeInput"), _class2.prototype)), _class2)) || _class);
  var _default = _exports.default = TimeInput;
});