define("plutof/utils/store", ["exports", "copy-anything", "plutof/utils/objects"], function (_exports, _copyAnything, _objects) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.findAll = findAll;
  _exports.findAllFiltered = findAllFiltered;
  _exports.query = query;
  var find_all_cache = {}; // model_name => Promise<[Record]>

  function wrap(recordArrayPromise) {
    return recordArrayPromise.then(recordArray => {
      const records = recordArray.toArray();
      recordArray.destroy();
      return records;
    });
  }
  function query(store, model, params) {
    return wrap(store.query(model, params));
  }
  function findAll(store, model) {
    if (!store.modelFor(model).cacheable) {
      return wrap(store.findAll(model));
    }
    if (!find_all_cache[model]) {
      find_all_cache[model] = wrap(store.findAll(model, {
        reload: true
      }));
    }
    return find_all_cache[model];
  }
  var cache = {}; // model_name => [params, Promise<[Record]>]

  function findAllFiltered(store, model, params) {
    params = params || {};
    if (!store.modelFor(model).cacheable) {
      return query(store, model, params);
    }
    cache[model] = cache[model] || [];
    var entry = cache[model].find(function (desc) {
      return (0, _objects.objects_are_equal)(desc.params, params);
    });
    if (Ember.isNone(entry)) {
      entry = {
        params: (0, _copyAnything.copy)(params),
        request: query(store, model, params)
      };
      cache[model].pushObject(entry);
    }
    return entry.request;
  }
});