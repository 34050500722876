define("plutof/components/information/content/taxon/general", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Information::MarkdownReader @path="taxon/general" @locale={{@locale}} />
  
  <h4> GBIF import </h4>
  
  <Information::MarkdownReader @path="taxon/gbif-import" @locale={{@locale}} />
  
  */
  {
    "id": "yyoS0ZWW",
    "block": "{\"symbols\":[\"@locale\"],\"statements\":[[8,\"information/markdown-reader\",[],[[\"@path\",\"@locale\"],[\"taxon/general\",[32,1]]],null],[2,\"\\n\\n\"],[10,\"h4\"],[12],[2,\" GBIF import \"],[13],[2,\"\\n\\n\"],[8,\"information/markdown-reader\",[],[[\"@path\",\"@locale\"],[\"taxon/gbif-import\",[32,1]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "plutof/components/information/content/taxon/general.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});