define("plutof/templates/components/tool-tip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "9bqVq8Qq",
    "block": "{\"symbols\":[\"&default\",\"@preWrap\",\"@tooltip\"],\"statements\":[[18,1,null],[2,\"\\n\\n\"],[10,\"span\"],[15,1,[31,[[32,0,[\"tid\"]]]]],[15,0,[31,[\"wrapper-tooltip \",[30,[36,0],[[32,2],\"wrapper-tooltip--pre-wrap\"],null]]]],[12],[1,[32,3]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/tool-tip.hbs"
    }
  });
});