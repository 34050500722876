define("plutof/templates/components/unite/options-toggle-control", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "rw5oHplN",
    "block": "{\"symbols\":[\"columnSelector\",\"entry\"],\"statements\":[[8,\"tables/column-selector\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"entries\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,[32,1,[\"column\"]],[],[[\"@toggle\",\"@visible\"],[[30,[36,0],[[32,0,[\"toggleOption\"]],[32,2]],null],[32,2,[\"value\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,1],[[32,2,[\"title\"]]],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"i18n-t\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/unite/options-toggle-control.hbs"
    }
  });
});