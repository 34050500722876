define("plutof/components/login/form-input-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="login-input-status">
      {{#if (and @isValid (not @isConfirmed))}}
          <div class="login-input-status__warning hidden-xs">
              {{@warning}}
          </div>
      {{else}}
          <span class="login-input-status__check
              {{if (and @isValid @isConfirmed) 'login-input-status__check--checked'}}"></span>
      {{/if}}
  </div>
  
  */
  {
    "id": "JCSikm9H",
    "block": "{\"symbols\":[\"@isConfirmed\",\"@isValid\",\"@warning\"],\"statements\":[[10,\"div\"],[14,0,\"login-input-status\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[32,2],[30,[36,2],[[32,1]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"login-input-status__warning hidden-xs\"],[12],[2,\"\\n            \"],[1,[32,3]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"span\"],[15,0,[31,[\"login-input-status__check\\n            \",[30,[36,1],[[30,[36,0],[[32,2],[32,1]],null],\"login-input-status__check--checked\"],null]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"and\",\"if\",\"not\"]}",
    "meta": {
      "moduleName": "plutof/components/login/form-input-status.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});