define("plutof/templates/taxonomy/addtree", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "cmpirSzi",
    "block": "{\"symbols\":[],\"statements\":[[8,\"test/route-marker\",[],[[\"@route\"],[\"taxonomy.add-tree\"]],null],[2,\"\\n\\n\"],[8,\"navbar/edit\",[],[[\"@title\",\"@icon\",\"@infoContent\",\"@reset\"],[[30,[36,0],[\"Taxonomy.newTree\"],null],[30,[36,1],[\"taxonomy\"],null],\"information/content/taxon/general\",[32,0,[\"reset\"]]]],null],[2,\"\\n\\n\"],[8,\"taxonomy/tree-attributes\",[],[[\"@name\",\"@rootNode\",\"@originTree\",\"@validationContext\"],[[32,0,[\"name\"]],[32,0,[\"model\",\"rootNode\"]],[32,0,[\"model\",\"originTree\"]],[32,0,[\"validationContext\",\"tree\"]]]],null],[2,\"\\n\\n\"],[8,\"access/edit-rights\",[],[[\"@rights\",\"@validationContext\"],[[32,0,[\"model\",\"accessRights\"]],[32,0,[\"validationContext\",\"access\"]]]],null],[2,\"\\n\\n\"],[8,\"sticky-bottom-wrapper\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"invalid-panels\",[],[[\"@validationContext\"],[[32,0,[\"validationContext\"]]]],null],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"action-buttons\"],[12],[2,\"\\n        \"],[8,\"common/save-button\",[],[[\"@save\",\"@disabled\"],[[32,0,[\"save\"]],[32,0,[\"disableSave\"]]]],null],[2,\"\\n\\n        \"],[8,\"common/cancel-button\",[],[[\"@cancel\"],[[32,0,[\"cancel\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"icon\"]}",
    "meta": {
      "moduleName": "plutof/templates/taxonomy/addtree.hbs"
    }
  });
});