define("plutof/controllers/datacite/index", ["exports", "plutof/controllers/publishing/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DataCiteIndex extends Ember.Controller {
    init() {
      super.init(...arguments);
      this.set('publishingAllowed', false);
      (0, _utils.userIsAllowedToRegisterDOIs)(this.store).then(allowed => {
        this.set('publishingAllowed', allowed);
      });
    }
  }
  _exports.default = DataCiteIndex;
});