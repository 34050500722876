define("plutof/templates/experiment/dna/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "fHPX1o1Q",
    "block": "{\"symbols\":[\"translations\",\"Field\",\"dna\",\"common\"],\"statements\":[[10,\"div\"],[14,0,\"route-marker-dna-index\"],[12],[13],[2,\"\\n\\n\"],[8,\"navbar/list\",[],[[\"@icon\",\"@title\",\"@addRoute\",\"@goBack\",\"@pagination\",\"@infoTitle\",\"@infoContent\"],[[30,[36,0],[\"experiment\"],null],[30,[36,1],[\"experiment.dna.routeTitles.list\"],null],\"experiment.dna.add\",[30,[36,2],[\"goBack\"],null],[32,0,[\"model\",\"pagination\"]],[30,[36,1],[\"experiment.general.experiments\"],null],\"information/content/dna-lab/general\"]],null],[2,\"\\n\\n\"],[8,\"i18n/model-strings\",[],[[\"@model\"],[\"dna-lab.dna\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"tables/list-route\",[],[[\"@records\"],[[32,0,[\"model\",\"pagination\",\"objects\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,2],[],[[\"@label\"],[[32,1,[\"name\",\"label\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],null,[[\"route\",\"model\"],[\"experiment.dna.view\",[32,3,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"                \"],[1,[32,3,[\"name\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,2],[],[[\"@label\"],[[32,1,[\"secondary_identifier\",\"label\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[32,3,[\"secondary_identifier\"]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,4,[\"modified\"]],[],[[],[]],null],[2,\"\\n    \"]],\"parameters\":[2,3,4]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\",\"route-action\",\"link-to\"]}",
    "meta": {
      "moduleName": "plutof/templates/experiment/dna/index.hbs"
    }
  });
});