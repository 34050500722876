define("plutof/templates/components/clipboard/remove-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Ap3ia2hk",
    "block": "{\"symbols\":[\"@icon\",\"@title\"],\"statements\":[[6,[37,0],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[15,0,[31,[[32,1]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[1,[32,2]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/clipboard/remove-button.hbs"
    }
  });
});