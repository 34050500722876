define("plutof/validators/is-truthy", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class IsTruthy extends _base.default {
    validate(value) {
      return Boolean(value);
    }
  }
  IsTruthy.reopenClass({
    getDependentsFor() {
      return [];
    }
  });
  var _default = _exports.default = IsTruthy;
});