define("plutof/components/annotation/-summary/moderation-status", ["exports", "@glimmer/component", "plutof/models/annotation/annotation"], function (_exports, _component, _annotation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div>
      {{i18n-t this.statusString
          moderator=@annotation.moderator.representation
          date=(formatted-date @annotation.moderated_at)}}
  </div>
  
  <p>
      {{@annotation.moderator_comment}}
  </p>
  
  */
  {
    "id": "yzldd3Bf",
    "block": "{\"symbols\":[\"@annotation\"],\"statements\":[[10,\"div\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[[32,0,[\"statusString\"]]],[[\"moderator\",\"date\"],[[32,1,[\"moderator\",\"representation\"]],[30,[36,0],[[32,1,[\"moderated_at\"]]],null]]]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"p\"],[12],[2,\"\\n    \"],[1,[32,1,[\"moderator_comment\"]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"formatted-date\",\"i18n-t\"]}",
    "meta": {
      "moduleName": "plutof/components/annotation/-summary/moderation-status.hbs"
    }
  });
  class AnnotationStatusDisplay extends _component.default {
    get statusString() {
      switch (this.args.annotation.status) {
        case _annotation.AnnotationStatus.BeingReviewed:
          return 'annotation.summary.status.moderated';
        case _annotation.AnnotationStatus.Accepted:
          return 'annotation.summary.status.accepted';
        case _annotation.AnnotationStatus.Rejected:
          return 'annotation.summary.status.rejected';
        default:
          throw new Error(`Unknown status ${this.args.annotation.status}`);
      }
    }
  }
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, AnnotationStatusDisplay);
});