define("plutof/components/unite/determinations/edit", ["exports", "@glimmer/component", "copy-anything", "plutof/controllers/analysis/utils", "plutof/models/globalkey/determination", "plutof/utils/pagination"], function (_exports, _component, _copyAnything, _utils, _determination, _pagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createUniteIdentificationsModel = createUniteIdentificationsModel;
  _exports.default = void 0;
  _exports.loadUniteIdentificationsModel = loadUniteIdentificationsModel;
  var _dec, _class, _dec2, _dec3, _dec4, _dec5, _class2, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::ItemList as |panel|>
      <panel.header
          @title={{i18n-t "UniteSH.determinations"}}
          @count={{@model.count}}
      >
          <panel.button @clicked={{@model.add}}>
              {{i18n-t "General.Add"}}
          </panel.button>
      </panel.header>
  
      {{! XXX: PlutofPanel::ItemList has no well-defined function }}
      <panel.innerPanels>
          <Validation::SplitContext
              @context={{@validationContext}}
              @items={{@model.pagination.objects}}
              as |entry context|
          >
              <Unite::Determinations::EditSingle
                  @entry={{entry}}
                  @validationContext={{context}}
                  @remove={{fn @model.remove entry}} />
          </Validation::SplitContext>
  
          <Pagination::LoadMore @pagination={{@model.pagination}} />
      </panel.innerPanels>
  </PlutofPanel::ItemList>
  
  */
  {
    "id": "+uEB/Vd1",
    "block": "{\"symbols\":[\"panel\",\"entry\",\"context\",\"@model\",\"@validationContext\"],\"statements\":[[8,\"plutof-panel/item-list\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"header\"]],[],[[\"@title\",\"@count\"],[[30,[36,0],[\"UniteSH.determinations\"],null],[32,4,[\"count\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,1,[\"button\"]],[],[[\"@clicked\"],[[32,4,[\"add\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,0],[\"General.Add\"],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[2,\"    \"],[8,[32,1,[\"innerPanels\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"validation/split-context\",[],[[\"@context\",\"@items\"],[[32,5],[32,4,[\"pagination\",\"objects\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,\"unite/determinations/edit-single\",[],[[\"@entry\",\"@validationContext\",\"@remove\"],[[32,2],[32,3],[30,[36,1],[[32,4,[\"remove\"]],[32,2]],null]]],null],[2,\"\\n        \"]],\"parameters\":[2,3]}]]],[2,\"\\n\\n        \"],[8,\"pagination/load-more\",[],[[\"@pagination\"],[[32,4,[\"pagination\"]]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"fn\"]}",
    "meta": {
      "moduleName": "plutof/components/unite/determinations/edit.hbs"
    }
  });
  const IdentificationPagination = Ember.Mixin.create({
    preprocessPage(pageData) {
      return Ember.RSVP.Promise.resolve(pageData.map(identification => new UniteIdentificationEntry({
        identification
      })));
    }
  });
  let UniteIdentificationEntry = (_dec = Ember.computed('identification.source_details.analysis'), (_class = class UniteIdentificationEntry {
    constructor({
      identification
    }) {
      this.identification = identification;
      // XXX: Necessary for annotations
      this.details = Object.assign({
        analysis_details: {}
      }, (0, _copyAnything.copy)(identification.source_details));
    }
    get analysisType() {
      return (0, _utils.getAnalysisType)(this.identification.source_details.analysis || 'blastn-sh');
    }
    save() {
      const details = this.details.analysis_details;
      this.analysisType.details.forEach(({
        field
      }) => {
        if (!Ember.isNone(details[field])) {
          Ember.set(details, field, parseFloat(details[field]));
        }
      });
      Ember.set(this.identification, 'source_details', this.details);
      return this.identification.save();
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "analysisType", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "analysisType"), _class.prototype)), _class)); // TODO: HasManyDataMixin can just be a base class, no need to proliferate mixins
  // Most of this logic and should be reused
  let UniteIdentificationsModel = (_dec2 = Ember._tracked, _dec3 = Ember.computed('pagination.objectCount', 'localCount'), _dec4 = Ember._action, _dec5 = Ember._action, (_class2 = class UniteIdentificationsModel {
    constructor({
      store,
      pagination,
      sequence
    }) {
      _defineProperty(this, "objectsToDelete", []);
      _initializerDefineProperty(this, "localCount", _descriptor, this);
      this.store = store;
      this.pagination = pagination;
      this.sequence = sequence;
    }
    get count() {
      return this.pagination.objectCount + this.localCount;
    }
    add() {
      const identification = this.store.createRecord('globalkey/determination', {
        sequence: this.sequence,
        source_type: _determination.SourceType.App,
        source_details: {
          analysis: 'blastn-sh',
          analysis_details: {}
        }
      });
      const entry = new UniteIdentificationEntry({
        identification
      });
      this.pagination.objects.pushObject(entry);
      this.localCount++;
    }
    remove(entry) {
      this.pagination.objects.removeObject(entry);
      this.objectsToDelete.push(entry.identification);
      this.localCount--;
    }
    async save() {
      await Ember.RSVP.all(this.objectsToDelete.map(obj => obj.destroyRecord()));
      this.objectsToDelete = [];
      await Ember.RSVP.all(this.pagination.objects.map(entry => entry.save()));
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "localCount", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "count", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "count"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "add", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "add"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "remove", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "remove"), _class2.prototype)), _class2));
  function createUniteIdentificationsModel({
    store,
    sequence
  }) {
    return new UniteIdentificationsModel({
      store,
      sequence,
      pagination: _pagination.EmptyPagination.create()
    });
  }
  async function loadUniteIdentificationsModel({
    store,
    sequence
  }) {
    const query = {
      sequence: sequence.id,
      ordering: '-updated_at'
    };
    const mixins = [_pagination.AccumulatingPaginationMixin, IdentificationPagination];
    const pagination = await (0, _pagination.paginateModel)(store, 'globalkey/determination', query, mixins);
    return new UniteIdentificationsModel({
      store,
      pagination,
      sequence
    });
  }
  class UniteIdentifications extends _component.default {}
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, UniteIdentifications);
});