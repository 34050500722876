define("plutof/translations/eng/living-cultures", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    newLivingC: 'New Living Specimen',
    editLivingC: 'Edit Living Specimen',
    livingCulture: 'Living Specimen',
    panelAdditionalIdentifiers: 'Additional Identifiers',
    panelIsolation: 'Isolation',
    panelInteractingTaxa: 'Interactions',
    searchTitle: 'Search Living Specimens',
    infoTitle: 'Living Specimens',
    alreadyExists: 'already exists in database',
    code: 'Code',
    placeholders: {
      searchHint: 'Search by strain number'
    },
    annotation: {
      add: 'Annotate living specimen',
      edit: 'Edit annotation',
      view: 'Annotated living specimen'
    },
    duplicates: 'Duplicates',
    duplicateRecord: {
      duplicateRecord: {
        label: 'Duplicate record',
        tooltip: 'A duplicate from this record.'
      }
    },
    originalRecord: {
      label: 'Original record',
      record: {
        label: 'Original record',
        tooltip: 'Original record this one is a duplicate of.'
      },
      originalId: {
        label: 'Original record ID',
        tooltip: 'Identification this record is a duplicate of.'
      },
      organization: {
        label: 'Original record organization',
        tooltip: 'Organization to which original record of this duplicate belongs to.'
      },
      remarks: {
        label: 'Original record remarks',
        tooltip: 'General remarks describing this record\'s original record or its relation to the original record.'
      }
    },
    editOriginalRecord: 'Original living specimen'
  };
});