define("plutof/components/access/-view-rights/panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="view-rights__panel {{if @highlight 'view-rights__panel--highlight'}}">
      {{yield}}
  </div>
  
  */
  {
    "id": "PCHpHt5z",
    "block": "{\"symbols\":[\"@highlight\",\"&default\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"view-rights__panel \",[30,[36,0],[[32,1],\"view-rights__panel--highlight\"],null]]]],[12],[2,\"\\n    \"],[18,2,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "plutof/components/access/-view-rights/panel.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});