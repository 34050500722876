define("plutof/components/observation/moderation/bulk-controls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{!-- ### BULK ACCEPT AND REJECT ### --}}
  <PromiseButton
      @clicked={{@bulkAccept}}
      @disabled={{not @activeEntries.length}}
      @restIcon="{{icon 'ok'}}"
      class="btn btn-default btn-xs"
  >
      {{i18n-t "moderation.accept"}}
  
      <span class="count-badge count-badge--slim">
          {{@activeEntries.length}}
      </span>
  </PromiseButton>
  
  <button {{on 'click' @showRejectPanel}} disabled={{not @activeEntries.length}} class="btn btn-default btn-xs">
      <span class="icon-remove--red"></span>
  
      {{i18n-t "moderation.reject"}}
  
      <span class="count-badge count-badge--slim">
          {{@activeEntries.length}}
      </span>
  </button>
  
  <button
      {{on "click" @bulkForward}}
      disabled={{not @activeEntries.length}}
      class="btn btn-default btn-xs"
  >
      <span class={{icon "observation-moderation-forward"}}></span>
  
      {{i18n-t "moderation.forward"}}
  
      <span class="count-badge count-badge--slim">
          {{@activeEntries.length}}
      </span>
  </button>
  
  */
  {
    "id": "3/gtkjWr",
    "block": "{\"symbols\":[\"@bulkAccept\",\"@activeEntries\",\"@showRejectPanel\",\"@bulkForward\"],\"statements\":[[8,\"promise-button\",[[24,0,\"btn btn-default btn-xs\"]],[[\"@clicked\",\"@disabled\",\"@restIcon\"],[[32,1],[30,[36,0],[[32,2,[\"length\"]]],null],[31,[[30,[36,1],[\"ok\"],null]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,2],[\"moderation.accept\"],null]],[2,\"\\n\\n    \"],[10,\"span\"],[14,0,\"count-badge count-badge--slim\"],[12],[2,\"\\n        \"],[1,[32,2,[\"length\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[11,\"button\"],[16,\"disabled\",[30,[36,0],[[32,2,[\"length\"]]],null]],[24,0,\"btn btn-default btn-xs\"],[4,[38,3],[\"click\",[32,3]],null],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"icon-remove--red\"],[12],[13],[2,\"\\n\\n    \"],[1,[30,[36,2],[\"moderation.reject\"],null]],[2,\"\\n\\n    \"],[10,\"span\"],[14,0,\"count-badge count-badge--slim\"],[12],[2,\"\\n        \"],[1,[32,2,[\"length\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[11,\"button\"],[16,\"disabled\",[30,[36,0],[[32,2,[\"length\"]]],null]],[24,0,\"btn btn-default btn-xs\"],[4,[38,3],[\"click\",[32,4]],null],[12],[2,\"\\n    \"],[10,\"span\"],[15,0,[30,[36,1],[\"observation-moderation-forward\"],null]],[12],[13],[2,\"\\n\\n    \"],[1,[30,[36,2],[\"moderation.forward\"],null]],[2,\"\\n\\n    \"],[10,\"span\"],[14,0,\"count-badge count-badge--slim\"],[12],[2,\"\\n        \"],[1,[32,2,[\"length\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"not\",\"icon\",\"i18n-t\",\"on\"]}",
    "meta": {
      "moduleName": "plutof/components/observation/moderation/bulk-controls.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});