define("plutof/models/dna-lab/pcr", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let PCR = (_dec = (0, _model.belongsTo)('users/user'), _dec2 = (0, _model.belongsTo)('users/user'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.belongsTo)('agent/person'), _dec6 = (0, _model.belongsTo)('agent/organization'), _dec7 = (0, _model.belongsTo)('reference/reference'), _dec8 = (0, _model.belongsTo)('dna-lab/polymerase-mix'), _dec9 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec10 = (0, _model.attr)('string'), _dec11 = (0, _model.attr)('text'), _dec12 = (0, _model.attr)('decimal'), _dec13 = (0, _model.attr)('decimal'), _dec14 = (0, _model.attr)('decimal'), _dec15 = (0, _model.attr)('decimal'), _dec16 = (0, _model.attr)('decimal'), _dec17 = (0, _model.attr)('decimal'), _dec18 = (0, _model.attr)('number'), _dec19 = (0, _model.attr)('number'), _dec20 = (0, _model.attr)('number'), _dec21 = (0, _model.attr)('ambiguous-datetime'), _dec22 = (0, _model.attr)('ambiguous-datetime'), _dec23 = (0, _model.attr)('boolean'), _dec24 = Ember.computed('title'), (_class = class PCR extends _model.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "created_by", _descriptor, this);
      _initializerDefineProperty(this, "updated_by", _descriptor2, this);
      _initializerDefineProperty(this, "created_at", _descriptor3, this);
      _initializerDefineProperty(this, "updated_at", _descriptor4, this);
      _initializerDefineProperty(this, "executed_by", _descriptor5, this);
      _initializerDefineProperty(this, "laboratory", _descriptor6, this);
      _initializerDefineProperty(this, "protocol", _descriptor7, this);
      _initializerDefineProperty(this, "polymerase_mix", _descriptor8, this);
      _initializerDefineProperty(this, "labware_oriented_vertically", _descriptor9, this);
      _initializerDefineProperty(this, "title", _descriptor10, this);
      _initializerDefineProperty(this, "design_description", _descriptor11, this);
      _initializerDefineProperty(this, "nr_of_cycles", _descriptor12, this);
      _initializerDefineProperty(this, "dna_volume", _descriptor13, this);
      _initializerDefineProperty(this, "reaction_volume", _descriptor14, this);
      _initializerDefineProperty(this, "denaturation_temp", _descriptor15, this);
      _initializerDefineProperty(this, "annealing_temp", _descriptor16, this);
      _initializerDefineProperty(this, "extension_temp", _descriptor17, this);
      _initializerDefineProperty(this, "denaturation_time", _descriptor18, this);
      _initializerDefineProperty(this, "annealing_time", _descriptor19, this);
      _initializerDefineProperty(this, "extension_time", _descriptor20, this);
      _initializerDefineProperty(this, "timespan_begin", _descriptor21, this);
      _initializerDefineProperty(this, "timespan_end", _descriptor22, this);
      _initializerDefineProperty(this, "is_finished", _descriptor23, this);
    }
    get representation() {
      return this.title;
    }
    destroyCompletely() {
      return this.destroyRecord().catch(error => {
        this.rollbackAttributes();
        throw error;
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "created_by", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "updated_by", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "created_at", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "updated_at", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "executed_by", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "laboratory", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "protocol", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "polymerase_mix", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "labware_oriented_vertically", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "title", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "design_description", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "nr_of_cycles", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "dna_volume", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "reaction_volume", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "denaturation_temp", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "annealing_temp", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "extension_temp", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "denaturation_time", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "annealing_time", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "extension_time", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "timespan_begin", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "timespan_end", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "is_finished", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "representation", [_dec24], Object.getOwnPropertyDescriptor(_class.prototype, "representation"), _class.prototype)), _class));
  var _default = _exports.default = PCR;
});