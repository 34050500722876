define("plutof/translations/est/moderation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    taxonName: 'Taksoni nimi',
    count: 'Arv',
    sample: 'Vaatluskoht',
    observedBy: 'Vaatleja(d)',
    controls: 'Toimingud',
    rejectionInfo: 'Vaatlusele kommentaari lisamisega lükatakse see tagasi. Vaatlus ilmub uuesti kinnitamiseks peale kasutajapoolset kirje muutmist või vastukommentaari lisamist.',
    rejectObservation: 'Lükka tagasi',
    close: 'Sulge',
    denialOfObservation: 'Vaatluse tagasilükkamine',
    comment: 'Kommentaar',
    required: 'kohustuslik',
    discussion: 'Kommentaarid',
    project: 'Projekt',
    observationsModeration: 'Vaatluste modereerimine',
    accept: 'Kinnita',
    reject: 'Lükka tagasi',
    forward: 'Paluda teist kasutajat modereerima',
    selectModerators: 'Vali modereerijaid',
    moderationPlaceholder: 'Sisesta vastuvõtmise või tagasilükkamise sõnum.',
    replyPlaceholder: 'Arutle ja küsi nõu.',
    rejectPlaceholder: 'Sisesta tagasilükkamise põhjus. Teade saadetakse kasutajale.',
    wasChanged: '{{property}} muudeti:',
    oldValue: 'Vana väärtus',
    newValue: 'Uus väärtus',
    successfullyAccepted: 'Vastuvõtmine õnnestus',
    reasonOfDenial: 'Tagasilükkamine õnnestus',
    identification: 'Määrang',
    moderatedByMe: 'Minu modereertiud',
    status: {
      pending: 'Vaatlus ootab kinnitamist.',
      rejected: 'Vaatlus on vormi moderaatori poolt tagasi lükatud.'
    },
    dataQuality: {
      label: 'Puudused ja hoiatused',
      tooltip: 'Hoiatused, mis viitavad võimalikele andmekvaliteedi probleemidele. Hoiatustes viidatu võib vajada tähelepanelikku ülevaatamist.'
    },
    advancedTableFields: {
      name: 'Takson',
      study: 'Projekt',
      mainform: 'Vormi nimi',
      timespan_begin: 'Alguskuupäev ja -aeg',
      timespan_end: 'Lõppkuupäev ja -aeg',
      created_at: 'Sisestuskuupäev ja -aeg',
      created_by: 'Looja',
      individual_count: 'Arv',
      measurements: 'Tunnused',
      related_objects: 'Seotud objektid',
      gathering_agents: 'Vaatlejad',
      determiners: 'Määrajad',
      controls: 'Toimingud',
      country: 'Riik',
      commune: 'Vald/Linn',
      district: 'Maakond',
      state: 'Osariik'
    },
    filters: {
      panelTitle: 'Filtrid',
      form: 'Vorm',
      project: 'Projekt',
      createdAt: 'Sisestatud',
      today: 'Täna',
      week: '7 päeva',
      month: '1 kuu',
      anytime: 'Kõik',
      hasMedia: 'Meedia',
      hasAudio: 'Heli',
      country: 'Riik'
    },
    forwarding: {
      forwardedTo: 'Sunnatud kasutajatele {{moderators}}',
      forwardedRecords: {
        label: 'Suunatud',
        tooltip: 'Suunatud teile modereerimiseks.'
      },
      allRecords: {
        label: 'Kõik',
        tooltip: 'Kõik kirjed, mida saate modereerida - kaasa arvatud teile modereerimiseks suunatud kirjed.'
      }
    },
    quickReplyOptions: {
      needProof: 'Määrangu kinnitamiseks on vaja tõendusmaterjali (foto, video või helisalvestus).',
      needExpertise: 'Määrangu kinnitamiseks on vajalik tõendus ekpertiisi kohta.',
      taxonTime: 'Taksoni esinemine sellisel ajal pole tõenäoline.',
      taxonLocation: 'Taksoni esinemine sellises paigas pole tõenäoline.'
    },
    controlTooltip: {
      details: 'Ava vaatluse meedia ja detailide dialoog.',
      reject: 'Ava tagasilükkamise dialoog.',
      accept: 'Kinnita vaatlus.',
      forward: 'Ava suunamise dialoog.'
    }
  };
});