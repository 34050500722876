define("plutof/components/common/ui/-control-label/toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <button
      {{on "click" @toggle}}
      class="label-control__btn {{if @checked 'label-control__btn--active'}}"
  >
      {{yield}}
  </button>
  
  */
  {
    "id": "bCuKafhV",
    "block": "{\"symbols\":[\"@checked\",\"@toggle\",\"&default\"],\"statements\":[[11,\"button\"],[16,0,[31,[\"label-control__btn \",[30,[36,0],[[32,1],\"label-control__btn--active\"],null]]]],[4,[38,1],[\"click\",[32,2]],null],[12],[2,\"\\n    \"],[18,3,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"on\"]}",
    "meta": {
      "moduleName": "plutof/components/common/ui/-control-label/toggle.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});