define("plutof/components/collection-lab/treatment/edit", ["exports", "plutof/components/linked-items/edit", "plutof/misc/content_types", "plutof/mixins/component-validations", "plutof/utils/access", "plutof/utils/store", "plutof/utils/reflection"], function (_exports, _edit, _content_types, _componentValidations, _access, _store, _reflection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TreatmentEditModel = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <CollectionLab::Treatment::-GeneralData
      @treatment={{@model.treatment}}
      @treatmentItems={{@model.treatmentItems}}
  
      @livingCtype={{@model.livingSpecimenContentType}}
      @deadCtype={{@model.specimenContentType}}
  
      @occ_type={{@model.occurrenceType}}
      @objectsToDelete={{@model.objectsToDelete}}
      @validationContext={{@validationContext.generalData}} />
  
  <LinkedItems::Edit
      @data={{@model.linkedItems}}
      @validationContext={{@validationContext.linkedItems}} />
  
  <Access::EditRights
      @rights={{@model.access}}
      @validationContext={{@validationContext.access}} />
  
  */
  {
    "id": "ilw0mKAT",
    "block": "{\"symbols\":[\"@model\",\"@validationContext\"],\"statements\":[[8,\"collection-lab/treatment/-general-data\",[],[[\"@treatment\",\"@treatmentItems\",\"@livingCtype\",\"@deadCtype\",\"@occ_type\",\"@objectsToDelete\",\"@validationContext\"],[[32,1,[\"treatment\"]],[32,1,[\"treatmentItems\"]],[32,1,[\"livingSpecimenContentType\"]],[32,1,[\"specimenContentType\"]],[32,1,[\"occurrenceType\"]],[32,1,[\"objectsToDelete\"]],[32,2,[\"generalData\"]]]],null],[2,\"\\n\\n\"],[8,\"linked-items/edit\",[],[[\"@data\",\"@validationContext\"],[[32,1,[\"linkedItems\"]],[32,2,[\"linkedItems\"]]]],null],[2,\"\\n\\n\"],[8,\"access/edit-rights\",[],[[\"@rights\",\"@validationContext\"],[[32,1,[\"access\"]],[32,2,[\"access\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "plutof/components/collection-lab/treatment/edit.hbs"
    }
  });
  let TreatmentEditModel = _exports.TreatmentEditModel = (_dec = Ember._tracked, _dec2 = Ember._tracked, (_class = class TreatmentEditModel {
    constructor({
      store,
      treatment,
      treatmentItems,
      access,
      linkedItems,
      specimenContentType,
      livingSpecimenContentType
    }) {
      _initializerDefineProperty(this, "occurrenceType", _descriptor, this);
      // TODO: This shouldn't be tracked
      _initializerDefineProperty(this, "objectsToDelete", _descriptor2, this);
      this.store = store;
      this.treatment = treatment;
      this.treatmentItems = treatmentItems;
      this.access = access;
      this.linkedItems = linkedItems;

      // TODO: Those are probably not necessary here, keeping them like this simply
      // because of the existing general data component
      this.specimenContentType = specimenContentType;
      this.livingSpecimenContentType = livingSpecimenContentType;
    }
    async save() {
      await this.treatment.save();
      await Ember.RSVP.all(this.objectsToDelete.map(object => object.destroyRecord()));
      this.objectsToDelete = [];
      await Ember.RSVP.all([Ember.RSVP.all(this.treatmentItems.map(item => item.save())), this.access.save(), this.linkedItems.save(this.treatment, this.access)]);
    }
    static async create(store) {
      const treatment = store.createRecord('collection-lab/treatment');
      const fields = await Ember.RSVP.hash({
        treatment,
        treatmentItems: [],
        access: (0, _access.create_access_rights)(store, treatment),
        linkedItems: (0, _edit.createLinkedItemsData)(store, treatment, {
          useEventFiles: false,
          disabledTabs: ['references', 'glossaries', 'links', 'permits']
        }),
        specimenContentType: (0, _content_types.get_ctype_by_name)(store, 'specimen/specimen'),
        livingSpecimenContentType: (0, _content_types.get_ctype_by_name)(store, 'livingculture/strain')
      });
      return new TreatmentEditModel(fields);
    }
    static async load(store, treatmentID) {
      const ajax = (0, _reflection.getService)(store, 'ajax');
      const treatment = await store.findRecord('collection-lab/treatment', treatmentID);
      const fields = await Ember.RSVP.hash({
        treatment,
        treatmentItems: (0, _store.query)(store, 'collection-lab/treatment-item', {
          treatment: treatmentID
        }),
        access: (0, _access.get_permissions)(store, ajax, treatment),
        linkedItems: (0, _edit.loadLinkedItemsData)(store, treatment, {
          useEventFiles: false,
          disabledTabs: ['references', 'glossaries', 'links', 'permits']
        }),
        specimenContentType: (0, _content_types.get_ctype_by_name)(store, 'specimen/specimen'),
        livingSpecimenContentType: (0, _content_types.get_ctype_by_name)(store, 'livingculture/strain')
      });
      return new TreatmentEditModel(fields);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "occurrenceType", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "objectsToDelete", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  })), _class));
  class TreatmentEdit extends Ember.Component.extend(_componentValidations.default) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "validationChildren", [{
        name: 'generalData',
        label: 'General.generalData'
      }, {
        name: 'linkedItems',
        label: 'General.associatedData'
      }, {
        name: 'access',
        label: 'General.access'
      }]);
    }
  }
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TreatmentEdit);
});