define("plutof/components/chart/line-chart", ["exports", "moment", "plutof/utils/chart", "plutof/utils/i18n"], function (_exports, moment, _chart, _i18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const i18n = (0, _i18n.getI18n)();

  /*
      config: {
          properties: ['unique_taxa', 'total_taxa'], // Results having 2 lines, y values use these properties
          translations: [i18n.t('chart.unique'), i18n.t('chart.total')], // Each line's legend, should use same index above
          key: 'timespan_begin', // Where data is stored in json
          label: i18n.t('chart.cumulative') // Chart title
      }
  
      will work for given result json
  
      results: {
          interval: month  ,
          timespan_begin: [{
              unique_taxa: 1,
              total_taxa: 2,
              key: 2015-12...,
          }]
      }
  */

  function createLineOptions(data, config, interval) {
    return {
      responsive: true,
      title: {
        display: true,
        text: config.label
      },
      legend: {
        display: true,
        labels: {
          fontSize: 10,
          boxWidth: 20
        }
      },
      elements: {
        point: {
          pointStyle: 'circle'
        }
      },
      onClick: function (event, items) {
        // We can not add filters of unsupported intervals.
      },
      hover: {
        mode: 'nearest'
      },
      scales: {
        xAxes: [{
          scaleLabel: {
            display: true,
            labelString: `${i18n.t('chart.time')} (${i18n.t('chart.' + interval)})`
          }
        }],
        yAxes: [{
          scaleLabel: {
            display: true,
            labelString: i18n.t('chart.count')
          }
        }]
      }
    };
  }
  function createDefaultLine(i, yProp) {
    const color = (0, _chart.getChartColor)(yProp, i);
    return {
      backgroundColor: color,
      borderColor: color,
      data: [],
      lineTension: 0,
      fill: false,
      pointRadius: 3,
      pointHoverRadius: 8,
      showLine: true,
      yProp: yProp
    };
  }
  function getLineData(data, config, interval) {
    const results = data[config.key].data;
    const lines = config.properties.map((yProp, index) => {
      const line = createDefaultLine(index, yProp);
      line.label = config.translations[index];
      return line;
    });
    results.forEach(result => {
      const xValue = moment(result.key).format(_chart.INTERVAL_FORMATS[interval]);
      lines.forEach(line => {
        line.data.pushObject({
          x: xValue,
          y: result[line.yProp]
        });
      });
    });
    return lines;
  }
  function createLineConfig({
    context,
    data,
    config
  }) {
    // Fail request handling.
    if (Ember.isEmpty(data)) {
      return {};
    }
    const interval = data[config.key].calendar_interval;
    const dataContainer = data[config.key].data;
    return {
      type: 'line',
      data: {
        labels: (0, _chart.getLabels)(dataContainer, interval),
        datasets: getLineData(data, config, interval)
      },
      options: createLineOptions(data, config, interval)
    };
  }
  let CumulativeChart = (_dec = Ember.computed('config.isFulfilled'), (_class = class CumulativeChart extends Ember.Component {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "config", null);
    }
    get chartConfig() {
      const config = this.config;
      if (this.get('config.isFulfilled')) {
        return createLineConfig(config.getProperties('context', 'data', 'config'));
      } else {
        return null;
      }
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "chartConfig", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "chartConfig"), _class.prototype)), _class));
  var _default = _exports.default = CumulativeChart;
});