define("plutof/components/related-objects/content/photobank-objects", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <RelatedObjects::Content @pagination={{@pagination}} as |Field pbo|>
      <Field @label={{i18n-t "Photobank.colID"}}>
          {{#link-to "photobank.view" pbo.id}}
              {{pbo.object_idprim}}
          {{/link-to}}
      </Field>
  
      <Field @label={{i18n-t "Photobank.colTitle"}}>
          {{pbo.title}}
      </Field>
  
      <Field @label={{i18n-t "Photobank.colCategory"}}>
          {{pbo.category}}
      </Field>
  
      <Field @label={{i18n-t "RelatedObjectsViewColumn.modified"}}>
          {{formatted-date pbo.updated_at}}
      </Field>
  
      <Field @label={{i18n-t "PlutofListViewColumn.clipboard"}}>
          <RelatedObjects::ClipboardStatus
              @onClipboard={{pbo.on_clipboard}}
              @recordID={{pbo.id}}
              @clipboard="photobanks" />
      </Field>
  </RelatedObjects::Content>
  
  */
  {
    "id": "VGSXeM89",
    "block": "{\"symbols\":[\"Field\",\"pbo\",\"@pagination\"],\"statements\":[[8,\"related-objects/content\",[],[[\"@pagination\"],[[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1],[],[[\"@label\"],[[30,[36,0],[\"Photobank.colID\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],null,[[\"route\",\"model\"],[\"photobank.view\",[32,2,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[32,2,[\"object_idprim\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,1],[],[[\"@label\"],[[30,[36,0],[\"Photobank.colTitle\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,2,[\"title\"]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,1],[],[[\"@label\"],[[30,[36,0],[\"Photobank.colCategory\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,2,[\"category\"]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,1],[],[[\"@label\"],[[30,[36,0],[\"RelatedObjectsViewColumn.modified\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,2],[[32,2,[\"updated_at\"]]],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,1],[],[[\"@label\"],[[30,[36,0],[\"PlutofListViewColumn.clipboard\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"related-objects/clipboard-status\",[],[[\"@onClipboard\",\"@recordID\",\"@clipboard\"],[[32,2,[\"on_clipboard\"]],[32,2,[\"id\"]],\"photobanks\"]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"link-to\",\"formatted-date\"]}",
    "meta": {
      "moduleName": "plutof/components/related-objects/content/photobank-objects.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});