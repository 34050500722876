define("plutof/translations/eng/identifiers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    identifiers: 'Occurrence identifiers',
    examples: 'Example identifiers',
    format: {
      defined: 'Identifier format: {{prefix}} + number zero-padded to {{width}} digits',
      notDefined: 'No identifier format is defined yet. Define one to enable reserving identifier blocks',
      defineFormat: 'Define format',
      changeFormat: 'Change format',
      dialog: {
        info: `
                An identifier consists of a prefix and a zero-padded number.
                The format is not mandatory: occurrences do not have to
                adhere to it. If you require a different format, please contact support
            `,
        changeWarning: `
                There are some blocks already reserved.
                If you change the format, those reservations will still use the old format
            `,
        prefix: 'Prefix',
        padding: 'Padding',
        confirm: 'Confirm'
      }
    },
    list: {
      range: 'Identifiers',
      reservedFor: 'Reserved for',
      reserve: 'Reserve block',
      pending: 'Pending reservations',
      reserved: 'Reserved blocks',
      used: 'Used',
      reserveDialog: {
        reserveFor: 'Reserve for',
        anonymous: 'Allow anyone to use this block',
        size: 'Block length',
        start: 'First identifier',
        end: 'Last identifier',
        available: {
          title: 'Available block of given size',
          description: 'We will try to allocate a block with requested size',
          representation: 'An available block of {{size}} identifiers'
        },
        specific: {
          title: 'Specific block',
          description: 'Ask for a specific interval. If it is available, it will be allocated'
        }
      }
    },
    view: {
      reservedBlocks: 'Reserved identifier blocks',
      printLabels: 'Print labels',
      print: 'Print',
      printDialog: {
        format: 'Format',
        formats: {
          tube: 'Tube',
          bag: 'Bag'
        },
        onlyUnused: 'Include only unused identifiers'
      }
    },
    errors: {
      reserve: 'Error reserving {{block}}: {{error}}',
      availability: {
        occurrence: 'There is already an occurrence {{occurrence}} in this block',
        overlapping: 'Block overlaps with [{{start}}:{{end}}] already reserved for {{reservedFor}}',
        unknown: 'Block not available'
      }
    }
  };
});