define("plutof/components/publishing/gbif/view/records", ["exports", "@glimmer/component", "plutof/config/environment", "plutof/controllers/publishing/utils"], function (_exports, _component, _environment, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <RelatedObjects::Tabs
      @searchQuery={{hash gbif_dataset=@dataset.id}}
      as |tabs|
  >
      {{#let
          (component "publishing/gbif/view/records/record-content"
              dataset=@dataset)
          as |recordContent|
      }}
          <tabs.sequences
              @content={{component recordContent}}
              @viewRoute="sequence.view"
              @customPagination={{this.sequences}} />
  
          <tabs.specimens
              @content={{component recordContent}}
              @viewRoute="specimen.view"
              @customPagination={{this.specimens}} />
  
          <tabs.observations
              @content={{component recordContent}}
              @viewRoute="observation.view"
              @customPagination={{observations}} />
  
          <tabs.refbased
              @content={{component recordContent}}
              @viewRoute="referencebased.view"
              @customPagination={{referencebased}} />
      {{/let}}
  </RelatedObjects::Tabs>
  
  */
  {
    "id": "fNExXD74",
    "block": "{\"symbols\":[\"tabs\",\"recordContent\",\"@dataset\"],\"statements\":[[8,\"related-objects/tabs\",[],[[\"@searchQuery\"],[[30,[36,3],null,[[\"gbif_dataset\"],[[32,3,[\"id\"]]]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,4],[[30,[36,0],[\"publishing/gbif/view/records/record-content\"],[[\"dataset\"],[[32,3]]]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,[32,1,[\"sequences\"]],[],[[\"@content\",\"@viewRoute\",\"@customPagination\"],[[30,[36,0],[[32,2]],null],\"sequence.view\",[32,0,[\"sequences\"]]]],null],[2,\"\\n\\n        \"],[8,[32,1,[\"specimens\"]],[],[[\"@content\",\"@viewRoute\",\"@customPagination\"],[[30,[36,0],[[32,2]],null],\"specimen.view\",[32,0,[\"specimens\"]]]],null],[2,\"\\n\\n        \"],[8,[32,1,[\"observations\"]],[],[[\"@content\",\"@viewRoute\",\"@customPagination\"],[[30,[36,0],[[32,2]],null],\"observation.view\",[34,1]]],null],[2,\"\\n\\n        \"],[8,[32,1,[\"refbased\"]],[],[[\"@content\",\"@viewRoute\",\"@customPagination\"],[[30,[36,0],[[32,2]],null],\"referencebased.view\",[34,2]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"observations\",\"referencebased\",\"hash\",\"let\"]}",
    "meta": {
      "moduleName": "plutof/components/publishing/gbif/view/records.hbs"
    }
  });
  class GbifViewRecords extends _component.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "sequences", (0, _utils.paginateRecords)(this.args.dataset, `${_environment.default.API_HOST}/public/sequences`));
      _defineProperty(this, "specimens", (0, _utils.paginateRecords)(this.args.dataset, `${_environment.default.API_HOST}/public/specimens`));
      _defineProperty(this, "observations", (0, _utils.paginateRecords)(this.args.dataset, `${_environment.default.API_HOST}/public/observations`));
      _defineProperty(this, "referencebased", (0, _utils.paginateRecords)(this.args.dataset, `${_environment.default.API_HOST}/taxonoccurrence/reference-based-occurrences`));
    }
  }
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, GbifViewRecords);
});