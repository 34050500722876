define("plutof/templates/filerepository/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "xkTCMI8G",
    "block": "{\"symbols\":[],\"statements\":[[8,\"test/route-marker\",[],[[\"@route\"],[\"filerepository.index\"]],null],[2,\"\\n\\n\"],[8,\"navbar/list\",[],[[\"@icon\",\"@title\",\"@addRoute\",\"@pagination\",\"@infoTitle\",\"@infoContent\"],[[30,[36,0],[\"file\"],null],[30,[36,1],[\"FileRepository.title\"],null],\"filerepository.upload\",[32,0,[\"model\",\"pagination\"]],[30,[36,1],[\"FileRepository.infoTitle\"],null],\"information/content/filerepository/index\"]],null],[2,\"\\n\\n\"],[8,\"filerepository/file-list\",[],[[\"@files\",\"@delete\"],[[32,0,[\"model\",\"pagination\",\"objects\"]],[32,0,[\"delete\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\"]}",
    "meta": {
      "moduleName": "plutof/templates/filerepository/index.hbs"
    }
  });
});