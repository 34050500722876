define("plutof/components/sample/list-related-objects", ["exports", "ember-concurrency", "plutof/models/filerepository/file", "plutof/misc/content_types", "plutof/utils/notifications"], function (_exports, _emberConcurrency, _file, _content_types, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const SHOWN_EXTERNAL_LINK_NUMBER = 5;
  let ListRelatedObjects = (_dec = Ember.computed.notEmpty('openFile'), _dec2 = Ember.computed('canOpenFiles'), _dec3 = Ember.computed('objects.external_links.[]'), _dec4 = (0, _emberConcurrency.task)({
    restartable: true
  }), _dec5 = Ember._action, (_class = class ListRelatedObjects extends Ember.Component {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "canOpenFiles", _descriptor, this);
      _defineProperty(this, "FileType", _file.FileType);
      _initializerDefineProperty(this, "_openMedia", _descriptor2, this);
    }
    get fileLinkClasses() {
      return this.canOpenFiles ? 'clickable btn-link' : '';
    }
    findMediaAndOpen(files, type) {
      const mediaFile = files.findBy('type', type);
      if (!Ember.isNone(mediaFile) && !Ember.isNone(this.openFile)) {
        this.openFile(mediaFile);
      }
    }
    get externalLinks() {
      const links = this.get('objects.external_links') || [];
      return links.slice(0, SHOWN_EXTERNAL_LINK_NUMBER);
    }
    async getQuery() {
      const ctype = await (0, _content_types.get_ctype_by_url)(this.store, this.parentObject.url);
      return {
        object_id: this.parentObject.id,
        content_type: ctype.id
      };
    }
    openMedia(type) {
      if (this.canOpenFiles) {
        this._openMedia.perform(type);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "canOpenFiles", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fileLinkClasses", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "fileLinkClasses"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "externalLinks", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "externalLinks"), _class.prototype), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_openMedia", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* (type) {
        try {
          const query = yield this.getQuery();
          const items = yield this.store.query('filerepository/item', query);
          const files = yield Ember.RSVP.all(items.mapBy('file'));
          yield this.findMediaAndOpen(files.compact(), type);
        } catch (err) {
          (0, _notifications.reportError)(err);
        }
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "openMedia", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "openMedia"), _class.prototype)), _class));
  var _default = _exports.default = ListRelatedObjects;
});