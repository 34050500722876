define("plutof/mixins/edit-controller", ["exports", "plutof/mixins/record-reset"], function (_exports, _recordReset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const EditControllerMixin = Ember.Mixin.create(_recordReset.default, {
    clearParams() {
      if (Ember.isPresent(this.queryParams)) {
        this.queryParams.forEach(param => this.set(param, null));
      }
    },
    actions: {
      cancel() {
        this.clearParams();
        return this.unloadRecords().then(() => {
          this.send('goBack');
        });
      }
    }
  });
  var _default = _exports.default = EditControllerMixin;
});