define("plutof/components/taxonomy-lab/conservation-instructions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "TaxonDescription.conservationInstructions"}}
      @collapse={{not @taxonDescription.conservation_instructions}}
      class="conservation-instructions"
      data-test="TaxonomyLab::ConservationInstructions"
  >
      <FlexibleTextarea
          @value={{@taxonDescription.conservation_instructions}}
          class="form-control conservation-instructions__content"
          data-test="content" />
  </PlutofPanel::Simple>
  
  */
  {
    "id": "pV3Cc9kv",
    "block": "{\"symbols\":[\"@taxonDescription\"],\"statements\":[[8,\"plutof-panel/simple\",[[24,0,\"conservation-instructions\"],[24,\"data-test\",\"TaxonomyLab::ConservationInstructions\"]],[[\"@title\",\"@collapse\"],[[30,[36,0],[\"TaxonDescription.conservationInstructions\"],null],[30,[36,1],[[32,1,[\"conservation_instructions\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"flexible-textarea\",[[24,0,\"form-control conservation-instructions__content\"],[24,\"data-test\",\"content\"]],[[\"@value\"],[[32,1,[\"conservation_instructions\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"not\"]}",
    "meta": {
      "moduleName": "plutof/components/taxonomy-lab/conservation-instructions.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});