define("plutof/components/tables/-bulk-update/select-all", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _class2;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let TableBulkUpdateSelectAll = (_dec = (0, _component.tagName)(''), _dec2 = Ember.computed('records.[]', 'selectionSize'), _dec(_class = (_class2 = class TableBulkUpdateSelectAll extends Ember.Component {
    get checked() {
      const records = this.records || [];
      return records.length === this.selectionSize && records.length > 0;
    }
    set checked(value) {
      if (value) {
        this.select(this.records);
      } else {
        this.clean();
      }
      return value;
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "checked", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "checked"), _class2.prototype)), _class2)) || _class);
  var _default = _exports.default = TableBulkUpdateSelectAll;
});