define("plutof/components/common/data-quality/warning/gathering-agents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <ToolTip
      @tooltip={{i18n-t 'DataQuality.warnings.gatheringAgents'}}
      @class="plutof-tooltip-hover--inline"
  >
      <span class="{{icon 'person'}} data-quality-warning data-quality-warning--icon"></span>
  </ToolTip>
  
  */
  {
    "id": "DPIB4kbU",
    "block": "{\"symbols\":[],\"statements\":[[8,\"tool-tip\",[],[[\"@tooltip\",\"@class\"],[[30,[36,0],[\"DataQuality.warnings.gatheringAgents\"],null],\"plutof-tooltip-hover--inline\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"span\"],[15,0,[31,[[30,[36,1],[\"person\"],null],\" data-quality-warning data-quality-warning--icon\"]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"icon\"]}",
    "meta": {
      "moduleName": "plutof/components/common/data-quality/warning/gathering-agents.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});