define("plutof/templates/components/plutof-select/object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "A3JfiAPf",
    "block": "{\"symbols\":[\"xs\",\"option\"],\"statements\":[[6,[37,13],null,[[\"value\",\"one-way\",\"action\",\"class\",\"disabled\"],[[35,12],true,[30,[36,11],[[32,0],\"valueChanged\"],null],[35,10],[35,9]]],[[\"default\"],[{\"statements\":[[6,[37,5],[[35,4]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[32,1,[\"option\"]]],[[\"value\"],[null]],[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[34,4]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,8],[[30,[36,7],[[30,[36,7],[[35,6]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[32,1,[\"option\"]]],[[\"value\"],[[30,[36,1],[[32,2],[35,2]],null]]],[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,1],[[32,2],[35,0]],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"optionLabelPath\",\"get\",\"optionValuePath\",\"component\",\"prompt\",\"if\",\"content\",\"-track-array\",\"each\",\"disabled\",\"selectClass\",\"action\",\"value\",\"x-select\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/plutof-select/object.hbs"
    }
  });
});