define("plutof/translations/est/history", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    table: {
      header: 'Kirje muutmise ajalugu',
      changes: 'Muudatused',
      actions: 'Toimingud',
      modified: 'Muudetud',
      group: {
        edited: '{{timespan}} - {{author}}',
        annotated: '{{timespan}} - {{author}}, kinnitatud - {{moderator}}'
      }
    },
    fields: {
      record: 'Kirje',
      author: 'Isik',
      timestamp: 'Ajatempel',
      diff: 'Muudatused'
    },
    diff: {
      add: 'Lisatud',
      remove: 'Eemaldatud'
    }
  };
});