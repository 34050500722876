define("plutof/translations/eng/gis-lab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    moduleHub: {
      import: {
        title: 'Import',
        info: 'Import areas from GeoJSON, WKT, or KML source.',
        button: 'Import'
      },
      layers: {
        title: 'Layers',
        info: 'Manage map layers.',
        button: 'Layers'
      }
    },
    import: {
      title: 'Import Areas',
      sourceHelp: 'Import areas from geometry files or text source.',
      fields: {
        files: 'Geometry from files',
        source: 'Geometry as text'
      },
      tabs: {
        list: 'Areas',
        map: 'Map'
      },
      actions: {
        fillCommune: 'Fill commune info',
        createAreas: 'Create areas',
        removeAll: 'Remove all',
        removeSelected: 'Remove ({{count}})'
      },
      columns: {
        file: 'File name',
        name: 'Area name',
        country: 'Country',
        district: 'District',
        commune: 'Commune',
        localityText: 'Locality text',
        parish: 'Parish',
        geom: 'Geometry',
        project: 'Project'
      }
    },
    information: {
      title: 'Import'
    }
  };
});