define("plutof/templates/components/common/info-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "pQ5ONpK9",
    "block": "{\"symbols\":[\"__arg0\"],\"statements\":[[8,\"ui/modal/dialog\",[],[[\"@open\",\"@close\",\"@namedBlocksInfo\"],[[32,0,[\"infoDialog\",\"visible\"]],[32,0,[\"infoDialog\",\"close\"]],[30,[36,6],null,[[\"header\",\"body\",\"footer\"],[0,0,0]]]]],[[\"default\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[32,1],\"header\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,0,[\"infoDialog\",\"title\"]]],[2,\"\\n    \"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[30,[36,2],[[32,1],\"body\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[32,0,[\"infoDialog\",\"content\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,5],[[32,0,[\"infoDialog\",\"content\"]]],[[\"locale\"],[[35,4]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"            \"],[10,\"p\"],[12],[1,[30,[36,1],[\"General.NoInfo\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[30,[36,2],[[32,1],\"footer\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[11,\"button\"],[24,0,\"btn btn-default\"],[4,[38,0],[\"click\",[32,0,[\"infoDialog\",\"close\"]]],null],[12],[2,\"\\n            \"],[1,[30,[36,1],[\"General.Close\"],null]],[2,\"\\n        \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\",\"i18n-t\",\"-is-named-block-invocation\",\"if\",\"locale\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/common/info-dialog.hbs"
    }
  });
});