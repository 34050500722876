define("plutof/controllers/taxonomy/view", ["exports", "plutof/utils/notifications"], function (_exports, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let TaxonomyViewController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed.alias('model.accessRights.canModify'), _dec5 = Ember.computed.alias('model.taxon.is_deleted'), _dec6 = Ember.computed('model.accessRights.canModify', 'model.taxon.synonym_type'), _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, (_class = class TaxonomyViewController extends Ember.Controller {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "i18n", _descriptor3, this);
      _initializerDefineProperty(this, "treeIsEditable", _descriptor4, this);
      _initializerDefineProperty(this, "taxonIsDeleted", _descriptor5, this);
    }
    get canAddSynonyms() {
      return this.get('model.accessRights.canModify') && Ember.isEmpty(this.get('model.taxon.synonym_type'));
    }
    showDeletedTaxonMessage() {
      if (this.taxonIsDeleted) {
        (0, _notifications.displayNotification)('warning', this.i18n.t('Taxonomy.deletedTaxon'), null, 20000);
      }
    }
    addNewTree() {
      this.router.transitionTo('taxonomy.addtree', {
        queryParams: {
          root_id: this.get('model.taxon.id')
        }
      });
    }
    newChildTaxon() {
      const treeId = this.get('model.tree.id');
      if (Ember.isNone(treeId)) {
        return;
      }
      this.router.transitionTo('taxonomy.add', treeId, {
        queryParams: {
          parent_id: this.get('model.taxon.id')
        }
      });
    }
    restoreTaxon() {
      const taxonId = this.get('model.taxon.id');
      const url = `/taxonomy/taxonnodes/${taxonId}/restore_taxon_node/`;
      this.ajax.put(url, {
        data: {}
      }).then(() => {
        this.set('model.taxon.is_deleted', false);
        (0, _notifications.displayNotification)('success', this.i18n.t('General.done'));
      }, _notifications.reportError);
    }
    showOnTree() {
      this.router.transitionTo('taxonomy.trees', {
        queryParams: {
          taxon_id: this.get('model.taxon.id')
        }
      });
    }
    edit() {
      this.router.transitionTo('taxonomy.edit', this.get('model.taxon.id'));
    }
    searchOccurrences() {
      this.router.transitionTo('search.index', {
        queryParams: {
          module: 'taxonoccurrence',
          taxon_node: this.model.taxon.id,
          parent_taxon: true
        }
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "i18n", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "treeIsEditable", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "taxonIsDeleted", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "canAddSynonyms", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "canAddSynonyms"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showDeletedTaxonMessage", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "showDeletedTaxonMessage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addNewTree", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "addNewTree"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "newChildTaxon", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "newChildTaxon"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "restoreTaxon", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "restoreTaxon"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showOnTree", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "showOnTree"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "edit", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "edit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchOccurrences", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "searchOccurrences"), _class.prototype)), _class));
  var _default = _exports.default = TaxonomyViewController;
});