define("plutof/templates/components/tables/-bulk-update/select-all", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "SldwbiQB",
    "block": "{\"symbols\":[\"@records\"],\"statements\":[[1,[30,[36,1],null,[[\"type\",\"checked\",\"disabled\"],[\"checkbox\",[32,0,[\"checked\"]],[30,[36,0],[[32,1,[\"length\"]]],null]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"not\",\"input\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/tables/-bulk-update/select-all.hbs"
    }
  });
});