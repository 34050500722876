define("plutof/components/profile/groups/auto-complete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{! Existing group AC-s default to memberships-only, so replicating here }}
  <AutoComplete::Model
      @model="users/workgroup"
      @value={{@value}}
      @update={{@update}}
      @filters={{if (default-to @membershipsOnly true) (hash user="self")}}
      @params={{@params}}
      @disabled={{@disabled}} />
  
  */
  {
    "id": "+MDmGTDM",
    "block": "{\"symbols\":[\"@value\",\"@update\",\"@membershipsOnly\",\"@params\",\"@disabled\"],\"statements\":[[8,\"auto-complete/model\",[],[[\"@model\",\"@value\",\"@update\",\"@filters\",\"@params\",\"@disabled\"],[\"users/workgroup\",[32,1],[32,2],[30,[36,2],[[30,[36,1],[[32,3],true],null],[30,[36,0],null,[[\"user\"],[\"self\"]]]],null],[32,4],[32,5]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hash\",\"default-to\",\"if\"]}",
    "meta": {
      "moduleName": "plutof/components/profile/groups/auto-complete.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});