define("plutof/templates/components/map/locate-me", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "lsGgLAKU",
    "block": "{\"symbols\":[],\"statements\":[[11,\"button\"],[16,\"disabled\",[34,0]],[16,\"title\",[30,[36,1],[\"Map.locateMe\"],null]],[24,4,\"button\"],[4,[38,2],[[32,0],\"locateMe\"],null],[12],[2,\"\\n\"],[6,[37,4],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[14,0,\"loading-icon\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"span\"],[14,0,\"fas fa-crosshairs\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"disabled\",\"i18n-t\",\"action\",\"inProgress\",\"if\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/map/locate-me.hbs"
    }
  });
});