define("plutof/components/navbar/navbar-base", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class NavbarBase extends _component.default {
    get kindClass() {
      switch (this.args.kind) {
        case 'edit':
          return 'navbar-base--edit';
        case 'view':
          return 'navbar-base--view';
        case 'util':
          return 'navbar-base--util';
        case 'annotation':
          return 'navbar-base--annotation';
        default:
          return '';
      }
    }
  }
  var _default = _exports.default = NavbarBase;
});