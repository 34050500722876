define("plutof/helpers/read-only-boolean", ["exports", "plutof/utils/i18n"], function (_exports, _i18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.translateBoolean = translateBoolean;
  function translateBoolean([booleanValue]) {
    var translation = '';
    if (Ember.isEqual(booleanValue, true)) {
      translation = (0, _i18n.getI18n)().t('General.yes', {});
    } else if (Ember.isEqual(booleanValue, false)) {
      translation = (0, _i18n.getI18n)().t('General.no', {});
    }
    return translation;
  }
  var _default = _exports.default = Ember.Helper.helper(translateBoolean);
});