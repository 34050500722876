define("plutof/templates/components/info-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "GpDDGCj+",
    "block": "{\"symbols\":[],\"statements\":[[11,\"span\"],[24,0,\"icon-info clickable\"],[4,[38,0],[[32,0],\"openInfo\"],null],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/info-link.hbs"
    }
  });
});