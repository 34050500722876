define("plutof/components/sample/-map/layer-area-popup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <table class="map-area-tooltip">
      <tbody>
          <tr>
              <td>{{i18n-t "Layer.popup.layer"}}:</td>
  
              <td>
                  {{#link-to "layer.view" @area.layerId}}
                      {{@area.layer_name}}
                  {{/link-to}}
              </td>
          </tr>
  
          <tr>
              <td>{{i18n-t "Layer.popup.area"}}:</td>
              <td>{{@area.area_name}}</td>
          </tr>
      </tbody>
  </table>
  
  */
  {
    "id": "71LL27n5",
    "block": "{\"symbols\":[\"@area\"],\"statements\":[[10,\"table\"],[14,0,\"map-area-tooltip\"],[12],[2,\"\\n    \"],[10,\"tbody\"],[12],[2,\"\\n        \"],[10,\"tr\"],[12],[2,\"\\n            \"],[10,\"td\"],[12],[1,[30,[36,0],[\"Layer.popup.layer\"],null]],[2,\":\"],[13],[2,\"\\n\\n            \"],[10,\"td\"],[12],[2,\"\\n\"],[6,[37,1],null,[[\"route\",\"model\"],[\"layer.view\",[32,1,[\"layerId\"]]]],[[\"default\"],[{\"statements\":[[2,\"                    \"],[1,[32,1,[\"layer_name\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"tr\"],[12],[2,\"\\n            \"],[10,\"td\"],[12],[1,[30,[36,0],[\"Layer.popup.area\"],null]],[2,\":\"],[13],[2,\"\\n            \"],[10,\"td\"],[12],[1,[32,1,[\"area_name\"]]],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"link-to\"]}",
    "meta": {
      "moduleName": "plutof/components/sample/-map/layer-area-popup.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});