define("plutof/translations/est/menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    labs: {
      collection: 'Kogude labor',
      publishing: 'Publitseerimislabor',
      taxonomy: 'Taksonoomia labor',
      analysis: 'Analüüside labor',
      molecular: 'Molekulaarlabor',
      gis: 'GIS labor'
    },
    notifications: {
      notifications: 'Teavitused'
    }
  };
});