define("plutof/templates/components/publishing/gbif/access-rights/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "4zwy2R8d",
    "block": "{\"symbols\":[\"vr\",\"@dataset\"],\"statements\":[[8,\"plutof-panel/simple\",[],[[\"@title\"],[[30,[36,0],[\"General.access\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"access/view-rights-base\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,1,[\"panel\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[10,\"div\"],[12],[13],[2,\"\\n\\n\"],[6,[37,1],[[32,0,[\"viewUsers\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"                \"],[8,[32,1,[\"view\",\"selected\"]],[],[[\"@usernames\"],[[32,0,[\"viewUsers\"]]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"                \"],[8,[32,1,[\"view\",\"private\"]],[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[32,0,[\"editUsers\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"                \"],[8,[32,1,[\"edit\",\"selected\"]],[],[[\"@usernames\"],[[32,0,[\"editUsers\"]]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"                \"],[8,[32,1,[\"edit\",\"private\"]],[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n            \"],[8,[32,1,[\"owner\"]],[],[[\"@owner\"],[[32,0,[\"owner\"]]]],null],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,1,[\"panel\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,[32,1,[\"history\"]],[],[[\"@createdBy\",\"@createdAt\",\"@updatedBy\",\"@updatedAt\"],[[32,0,[\"owner\"]],[32,2,[\"created\"]],[32,0,[\"owner\"]],[32,2,[\"modified\"]]]],null],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"if\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/publishing/gbif/access-rights/view.hbs"
    }
  });
});