define("plutof/components/datacite/view/creator", ["exports", "@glimmer/component", "plutof/utils/modules"], function (_exports, _component, _modules) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#link-to this.module.routes.view @creator.id}}
      <span class={{this.module.icon}}></span>
  
      {{@creator.name}}
  
      {{#if @creator.affiliation}}
          ({{@creator.affiliation}})
      {{/if}}
  {{/link-to}}
  
  */
  {
    "id": "lSgu+Y1Z",
    "block": "{\"symbols\":[\"@creator\"],\"statements\":[[6,[37,1],null,[[\"route\",\"model\"],[[32,0,[\"module\",\"routes\",\"view\"]],[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[15,0,[32,0,[\"module\",\"icon\"]]],[12],[13],[2,\"\\n\\n    \"],[1,[32,1,[\"name\"]]],[2,\"\\n\\n\"],[6,[37,0],[[32,1,[\"affiliation\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        (\"],[1,[32,1,[\"affiliation\"]]],[2,\")\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"link-to\"]}",
    "meta": {
      "moduleName": "plutof/components/datacite/view/creator.hbs"
    }
  });
  class DataciteViewCreator extends _component.default {
    get module() {
      const ctype = this.args.creator.type === 'organization' ? 'agent/organization' : 'agent/person';
      return _modules.default[ctype];
    }
  }
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DataciteViewCreator);
});