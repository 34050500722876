define("plutof/translations/eng/gallery", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    title: 'Title',
    licenceType: 'Licence Type',
    rightsOwner: 'Rights Owner',
    rightsStatement: 'Rights Statement',
    rightsHolder: 'Rights Holder',
    credit: 'Credit',
    creators: 'Creators',
    openInNewTab: 'Open in new tab',
    openInFileRepo: 'Open in File Repository',
    download: 'Download original file',
    openOnMap: 'Show on map',
    owner: 'Owner',
    noMapData: 'No map related data found for given result',
    enterFullscreen: 'Fullscreen'
  };
});