define("plutof/controllers/profile/groups/group", ["exports", "plutof/misc/abstract", "plutof/models/users/usergroup", "plutof/utils/loading", "plutof/utils/notifications", "plutof/components/common/search-query-input"], function (_exports, _abstract, _usergroup, _loading, _notifications, _searchQueryInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ProfileGroupsGroupController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed.gte('model.ownPersistedStatus', _usergroup.UserStatus.Moderator), _dec5 = Ember.computed.or('isModerator', 'settings.profile.user.is_superuser'), _dec6 = Ember.computed.reads('canModerate'), _dec7 = Ember.computed.reads('canModerate'), _dec8 = Ember.computed.reads('canModerate'), _dec9 = Ember.computed.equal('model.ownPersistedStatus', _usergroup.UserStatus.Owner), _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, (_class = class ProfileGroupsGroupController extends Ember.Controller {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "confirmationDialog", _descriptor2, this);
      _initializerDefineProperty(this, "settings", _descriptor3, this);
      _initializerDefineProperty(this, "isModerator", _descriptor4, this);
      _initializerDefineProperty(this, "canModerate", _descriptor5, this);
      _initializerDefineProperty(this, "canEdit", _descriptor6, this);
      _initializerDefineProperty(this, "canSendEmail", _descriptor7, this);
      _initializerDefineProperty(this, "canInvite", _descriptor8, this);
      _initializerDefineProperty(this, "canDelete", _descriptor9, this);
      _defineProperty(this, "searchQueryInputController", new _searchQueryInput.SearchQueryInputController());
    }
    init() {
      super.init(...arguments);
      this.set('emailDialogOpen', false);
    }
    addMember() {
      this.searchQueryInputController.clear();
      // this.model.members.clearFilter();
      this.model.members.add();
    }
    save() {
      const members = this.model.members.save();
      const group = this.model.group.save();
      return (0, _notifications.notifyProcess)(Ember.RSVP.all([members, group]), this.i18n).then(() => {
        this.set('model.ownPersistedStatus', this.model.ownMembership.status);

        // Fetch new join request count. Could also adjust it locally, but that would require
        // tracking status changes, which is nontrivial. But a possible TODO nevertheless
        return (0, _loading.reload)(this.model.group).then(() => {
          this.send('goBack');
        });
      });
    }
    openEmailDialog() {
      this.set('emailDialogOpen', true);
    }
    closeEmailDialog() {
      this.set('emailDialogOpen', false);
    }
    sendEmail(subject, content) {
      return this.ajax.request(`users/workgroups/${this.model.group.id}/email/`, {
        method: 'PUT',
        data: {
          subject,
          content
        }
      }).then(() => {
        (0, _notifications.displayNotification)('success', this.i18n.t('General.done'));
      }).catch(_notifications.reportError);
    }
    leave() {
      return this.confirmationDialog.confirm({
        title: this.i18n.t('Settings.groups.leave.title'),
        content: this.i18n.t('Settings.groups.leave.content', {
          hash: {
            group: this.model.group.representation
          }
        })
      }).then(confirmed => {
        if (!confirmed) {
          return _abstract.EMPTY_PROMISE;
        }
        return this.model.ownMembership.destroyRecord().then(() => {
          (0, _notifications.displayNotification)('success', this.i18n.t('General.done'));
          this.send('goBack');
        }).catch(error => {
          this.model.ownMembership.rollbackAttributes();
          (0, _notifications.reportError)(error);
          throw error;
        });
      });
    }
    delete() {
      return this.confirmationDialog.confirm(this.confirmationDialog.DELETE_CONFIRMATION).then(confirmed => {
        if (!confirmed) {
          return;
        }
        this.model.group.destroyRecord().then(() => {
          (0, _notifications.displayNotification)('success', this.i18n.t('General.done'));
          this.send('goBack');
        }).catch(_notifications.reportError);
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "confirmationDialog", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "settings", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isModerator", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "canModerate", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "canEdit", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "canSendEmail", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "canInvite", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "canDelete", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "addMember", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "addMember"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openEmailDialog", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "openEmailDialog"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeEmailDialog", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "closeEmailDialog"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sendEmail", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "sendEmail"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "leave", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "leave"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "delete", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "delete"), _class.prototype)), _class));
  var _default = _exports.default = ProfileGroupsGroupController;
});