define("plutof/utils/unite", ["exports", "plutof/utils/store", "plutof/utils/caching"], function (_exports, _store, _caching) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getLastUNITEVersion = void 0;
  const getLastUNITEVersion = _exports.getLastUNITEVersion = (0, _caching.lazy)(async function (store) {
    const versions = await (0, _store.findAll)(store, 'globalkey/version');
    return versions.filterBy('is_active', true).sortBy('id').reverse()[0];
  });
});