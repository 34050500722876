define("plutof/templates/components/unite/sh-statistics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "zNJeBOOO",
    "block": "{\"symbols\":[\"@threshold\",\"@count\"],\"statements\":[[8,\"plutof-panel/simple\",[],[[\"@title\"],[[30,[36,0],[\"General.Statistics\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"table\"],[12],[2,\"\\n        \"],[10,\"tr\"],[12],[2,\"\\n            \"],[10,\"td\"],[14,0,\"field-name\"],[12],[2,\"\\n                \"],[1,[30,[36,0],[\"DoiSHref.minimumDistance\"],null]],[2,\"\\n            \"],[13],[2,\"\\n\\n            \"],[10,\"td\"],[14,0,\"field-value\"],[12],[2,\"\\n                \"],[1,[32,1,[\"threshold\"]]],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"tr\"],[12],[2,\"\\n            \"],[10,\"td\"],[14,0,\"field-name\"],[12],[2,\"\\n                \"],[1,[30,[36,0],[\"DoiSHref.nrOfSeqInSH\"],null]],[2,\"\\n            \"],[13],[2,\"\\n\\n            \"],[10,\"td\"],[14,0,\"field-value\"],[12],[2,\"\\n                \"],[1,[32,2]],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/unite/sh-statistics.hbs"
    }
  });
});