define("plutof/translations/eng/panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    invalidPanelTooltip: 'Please fill fields in panel "{{name}}".'
  };
});