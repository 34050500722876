define("plutof/templates/components/occurrences-table/-responsive-row/row-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "D6Plbtpz",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"plutof-label occ-table-responsive-row__cell-label\"],[12],[2,\"\\n    #\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"occ-table-responsive-row__row-number\"],[12],[2,\"\\n    \"],[1,[32,0,[\"number\"]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "plutof/templates/components/occurrences-table/-responsive-row/row-number.hbs"
    }
  });
});