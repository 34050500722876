define("plutof/components/interaction/form", ["exports", "@ember-decorators/component", "ember-cp-validations", "plutof/components/interaction/target-type-selector", "plutof/misc/content_types", "plutof/mixins/component-validations", "plutof/utils/model"], function (_exports, _component, _emberCpValidations, _targetTypeSelector, _content_types, _componentValidations, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.UnboundInteraction = _exports.BoundInteraction = void 0;
  _exports.loadInteraction = loadInteraction;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _dec4, _dec5, _dec6, _dec7, _class2, _class3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  // Interaction data not backed by an actual interaction. Used to share interaction fields between objects
  class UnboundInteraction {
    constructor({
      store,
      targetType = _targetTypeSelector.TARGET_TYPES[0]
    }) {
      // TODO: Just in case this gets reused in a weird way, cache should be indexed by both model and occ id
      _defineProperty(this, "cache", {});
      _defineProperty(this, "target", null);
      _defineProperty(this, "taxonType", null);
      _defineProperty(this, "type", null);
      _defineProperty(this, "remarks", '');
      this.store = store;
      this.targetType = targetType;
    }
    async save(occurrence) {
      if (Ember.isNone(this.cache[occurrence.id])) {
        this.cache[occurrence.id] = this.store.createRecord('taxonoccurrence/interaction/interaction');
      }
      const interaction = this.cache[occurrence.id];
      const [occCType, targetCType] = await Ember.RSVP.Promise.all([(0, _content_types.get_object_ctype)(this.store, occurrence), (0, _content_types.get_object_ctype)(this.store, this.target)]);
      interaction.setProperties({
        content_type: occCType,
        object_id: occurrence.id,
        interacting_content_type: targetCType,
        interacting_object_id: this.target.id,
        interaction_type: this.type,
        interacting_taxon_type: this.taxonType,
        remarks: this.remarks
      });
      return interaction.save();
    }
    async destroy() {
      await Ember.RSVP.all(Object.values(this.cache).map(instance => instance.destroyRecord()));
    }
  }

  // Interaction data bound to a single interaction record
  _exports.UnboundInteraction = UnboundInteraction;
  let BoundInteraction = _exports.BoundInteraction = (_dec = Ember.computed.alias('interaction.interacting_taxon_type'), _dec2 = Ember.computed.alias('interaction.interaction_type'), _dec3 = Ember.computed.alias('interaction.remarks'), (_class = class BoundInteraction {
    constructor({
      store,
      interaction,
      target
    }) {
      _initializerDefineProperty(this, "taxonType", _descriptor, this);
      _initializerDefineProperty(this, "type", _descriptor2, this);
      _initializerDefineProperty(this, "remarks", _descriptor3, this);
      this.store = store;
      this.interaction = interaction;
      this.target = target;
      let targetType = _targetTypeSelector.TARGET_TYPES[0];
      if (target) {
        targetType = _targetTypeSelector.TARGET_TYPES.findBy('model', target.constructor.modelName);
      }
      this.targetType = targetType;
    }
    async save(occurrence) {
      if (this.target) {
        const ctype = await (0, _content_types.get_object_ctype)(this.store, this.target);
        this.interaction.setProperties({
          content_type: await (0, _content_types.get_object_ctype)(this.store, occurrence),
          object_id: occurrence.id,
          interacting_content_type: ctype,
          interacting_object_id: this.target.id
        });
        return this.interaction.save();
      } else {
        return this.interaction.destroyRecord();
      }
    }
    async destroy() {
      await this.interaction.destroyRecord();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "taxonType", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "type", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "remarks", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  async function loadInteraction(store, occurrence) {
    const ctype = await (0, _content_types.get_object_ctype)(store, occurrence);
    let interaction = await store.queryRecord('taxonoccurrence/interaction/interaction', {
      content_type: ctype.id,
      object_id: occurrence.id
    });
    let target;
    if (Ember.isNone(interaction)) {
      interaction = store.createRecord('taxonoccurrence/interaction/interaction', {
        content_type: ctype,
        object_id: occurrence.id
      });
      target = null;
    } else {
      target = await interaction.interacting_object;
    }
    return new BoundInteraction({
      store,
      interaction,
      target
    });
  }
  const Validations = (0, _emberCpValidations.buildValidations)({
    targetSelected: (0, _emberCpValidations.validator)('is-truthy')
  });

  // TODO: Change class name to .interaction-form
  let InteractionEditComponent = (_dec4 = (0, _component.classNames)('interaction-edit'), _dec5 = Ember.computed.notEmpty('data.target'), _dec6 = (0, _model.choices)('taxonoccurrence.interaction.interaction.interaction_type'), _dec7 = (0, _model.choices)('taxonoccurrence.interaction.interaction.interacting_taxon_type'), _dec4(_class2 = (_class3 = class InteractionEditComponent extends Ember.Component.extend(Validations, _componentValidations.default) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "targetSelected", _descriptor4, this);
      _initializerDefineProperty(this, "types", _descriptor5, this);
      _initializerDefineProperty(this, "taxaTypes", _descriptor6, this);
    }
  }, (_descriptor4 = _applyDecoratedDescriptor(_class3.prototype, "targetSelected", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class3.prototype, "types", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class3.prototype, "taxaTypes", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class3)) || _class2);
  var _default = _exports.default = InteractionEditComponent;
});