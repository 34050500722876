define("plutof/helpers/html-unsafe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // NB: Better be 100% sure that this is sanitized. NO user-provided data
  var _default = _exports.default = Ember.Helper.helper(function ([html]) {
    return Ember.String.htmlSafe(html);
  });
});