define("plutof/templates/components/tables/bulk-fields-update", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "fkI+P4fS",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[32,0,[\"values\"]],[30,[36,1],[\"tables/-bulk-update/update-button\"],[[\"update\"],[[30,[36,0],[[32,0],[32,0,[\"runUpdate\"]]],null]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"component\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/tables/bulk-fields-update.hbs"
    }
  });
});