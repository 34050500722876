define("plutof/misc/profile_settings", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.get_current_user = get_current_user;
  _exports.get_default_collection = get_default_collection;
  _exports.get_personal_settings = get_personal_settings;
  _exports.get_rights_owner_person = get_rights_owner_person;
  var request_promise;
  var requestFailed = false;

  // Some templates are bound to the settings objects. One way to do this is to use a PromiseObject
  // NB: Prefer services/settings::wait to this
  function get_personal_settings(store) {
    if (Ember.isNone(request_promise) || requestFailed) {
      requestFailed = false;
      request_promise = store.query('profile/personalsettings/personalsetting', {
        self: true
      }).then(function (settings) {
        requestFailed = Ember.isEmpty(settings);
        return requestFailed ? null : settings.get('firstObject');
      }, function () {
        /* eslint-disable no-console */
        console.error('Profile request failed');
        /* eslint-enable no-console */
        requestFailed = true;
      });
    }
    return _emberData.default.PromiseObject.create({
      promise: request_promise
    });
  }
  function get_current_user(store) {
    return get_personal_settings(store).then(function (settings) {
      return Ember.isPresent(settings) ? settings.get('user') : null;
    });
  }
  function get_rights_owner(store) {
    return get_personal_settings(store).then(settings => {
      return settings.get('default_owner').then(owner => {
        return owner || settings.get('user');
      });
    });
  }
  function get_rights_owner_person(store) {
    return get_rights_owner(store).then(user => {
      return store.queryRecord('agent/person', {
        user: user.get('id')
      });
    });
  }
  function get_default_collection(store) {
    return get_personal_settings(store).then(ps => {
      return ps.get('default_collection');
    });
  }
});