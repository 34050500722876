define("plutof/transforms/json", ["exports", "@ember-data/serializer/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class JSONTransform extends _transform.default {
    deserialize(serialized) {
      if (Ember.typeOf(serialized) === 'string') {
        return JSON.parse(serialized);
      } else {
        return serialized;
      }
    }
    serialize(json) {
      return json;
    }
  }
  var _default = _exports.default = JSONTransform;
});