define("plutof/translations/eng/determination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    current: {
      label: 'Latest',
      tooltip: 'Latest (valid) determination'
    },
    parent: {
      label: 'Parent',
      tooltip: 'Latest (valid) parent taxon occurrence determination'
    },
    synonym: {
      warning: {
        edit: '"{{synonym}}" is a synonym of "{{validTaxon}}". Using valid taxon name is recommended. Click "Replace" to make the change.',
        view: '"{{synonym}}" is a synonym of "{{validTaxon}}". Using valid taxon name is recommended.'
      },
      replace: 'Replace'
    }
  };
});