define("plutof/controllers/sequence/annotation/edit", ["exports", "plutof/controllers/sequence/annotation/add", "plutof/utils/annotation/format", "plutof/utils/annotation/routes"], function (_exports, _add, _format, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SequenceAnnotationEditController extends (0, _routes.annotationEditControllerWithElixir)({
    save: model => model.sequenceData.save(),
    extract(extractor, changes) {
      return (0, _add.extractSequence)(extractor, changes, this.model.sequenceData, this.store);
    }
  }) {
    get isElixirAnnotation() {
      return this.model.annotation.annotation.format === _format.AnnotationFormats.Elixir;
    }
  }
  _exports.default = SequenceAnnotationEditController;
});