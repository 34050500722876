define("plutof/components/information/content/general/info-empty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @locale.eng}}
      Info yet to come...
  {{/if}}
  
  {{#if @locale.est}}
      Info saabub peagi...
  {{/if}}
  
  */
  {
    "id": "oSo513m1",
    "block": "{\"symbols\":[\"@locale\"],\"statements\":[[6,[37,0],[[32,1,[\"eng\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    Info yet to come...\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,0],[[32,1,[\"est\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    Info saabub peagi...\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "plutof/components/information/content/general/info-empty.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});