define("plutof/controllers/annotations/bulk/add", ["exports", "plutof/utils/annotation/moderation", "plutof/utils/controllers"], function (_exports, _moderation, _controllers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class BulkAnnotationAddController extends _controllers.AddController {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "queryParams", ['selection_only']);
      _defineProperty(this, "selection_only", true);
    }
    async _save() {
      const annotation = await this.model.annotation.save();
      if (annotation.is_self_annotation) {
        await (0, _moderation.acceptAnnotation)(annotation);
      }
    }
  }
  var _default = _exports.default = BulkAnnotationAddController;
});