define("plutof/components/experiment/common/printing", ["exports", "plutof/components/experiment/utils", "plutof/utils/structures"], function (_exports, _utils, _structures) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.printHTML = printHTML;
  function prepareCells(data) {
    return Array.from(Array(96).keys()).map(index => {
      const product = data[index];
      const sourceName = Ember.isPresent(product) ? product.get('name') : '';
      const sourceID = Ember.isPresent(sourceName) ? sourceName.split(' | ').shift() : '&nbsp;'; // XXX

      return {
        label: (0, _utils.labwareTubeLabel)(index, true),
        id: sourceID
      };
    });
  }
  function renderRows(rows) {
    return rows.map((row, index) => {
      const tr = document.createElement('tr');
      row.reverse().forEach(cell => {
        const td = document.createElement('td');
        const label = document.createElement('div');
        const id = document.createElement('div');
        td.appendChild(label);
        td.appendChild(id);
        tr.appendChild(td);
        label.innerText = cell.label;
        id.innerHTML = cell.id;
        id.style.textAlign = 'right';
        label.style.marginBottom = '.5rem';
        label.style.color = '#666';
        td.style.borderBottom = '1px solid #aaa';
        td.style.borderLeft = '1px solid #aaa';
        td.style.width = '6rem';
        td.style.padding = '.33rem';
      });
      return tr;
    });
  }
  function printHTML(data, experiment) {
    const container = document.createElement('div');
    const table = document.createElement('table');
    const tbody = document.createElement('tbody');
    const cells = prepareCells(data.sortBy('tube_nr'));
    const rows = renderRows((0, _structures.chop)(8, cells));
    rows.forEach(row => tbody.appendChild(row));
    table.appendChild(tbody);
    container.appendChild(table);
    table.style.fontSize = '14px';
    table.style.color = '#111';
    const printWindow = window.open('_blank');
    printWindow.document.write(container.innerHTML);
    printWindow.document.title = experiment.title;
  }
});