define("plutof/serializers/application", ["exports", "plutof/serializers/drf", "ember-inflector", "plutof/config/environment", "plutof/misc/abstract", "plutof/utils/reflection"], function (_exports, _drf, _emberInflector, _environment, _abstract, _reflection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ApplicationSerializer extends _drf.default {
    isStringLink(str) {
      return !Ember.isNone(str.match) && typeof str.match === 'function' && str.match(/^((https?:)?\/\/|\/)\w/);
    }
    recordURL(type, id) {
      return _environment.default.API_HOST + '/' + (0, _emberInflector.pluralize)(type) + '/' + id + '/';
    }
    extractRelationships(modelClass, resourceHash) {
      if (!resourceHash.hasOwnProperty('links')) {
        resourceHash['links'] = {};
      }
      if (!resourceHash.hasOwnProperty('relationships')) {
        resourceHash['relationships'] = {};
      }
      var hasmanyRelations = [];
      modelClass.eachRelationship(function (key, relationshipMeta) {
        var payloadRelKey = this.keyForRelationship(key);
        if (!resourceHash.hasOwnProperty(payloadRelKey)) {
          return;
        }
        if (relationshipMeta.kind === 'hasMany' || relationshipMeta.kind === 'belongsTo') {
          // Matches strings starting with: https://, http://, //, /
          var payloadRel = resourceHash[payloadRelKey];
          if (!Ember.isNone(payloadRel)) {
            if (this.isStringLink(payloadRel)) {
              delete resourceHash[payloadRelKey];
              resourceHash[payloadRelKey] = {
                id: (0, _reflection.recordURLToID)(payloadRel),
                type: relationshipMeta.type,
                links: {
                  related: payloadRel
                }
              };
            }
            if (Ember.isArray(payloadRel)) {
              const type = relationshipMeta.type;
              var serialized = payloadRel.map(function (link) {
                return {
                  id: (0, _reflection.recordURLToID)(link),
                  type: type
                };
              }, this);
              hasmanyRelations[key] = {
                data: serialized
              };
              resourceHash['relationships'][key] = serialized;
              delete resourceHash[payloadRelKey];
            }
          }
        }
      }, this);
      var result = super.extractRelationships(modelClass, resourceHash);
      return (0, _abstract.mergeObjects)(result, hasmanyRelations);
    }
    serializeBelongsTo(snapshot, json, relationship) {
      var key = relationship.key;
      if (this._canSerialize(key)) {
        var belongsToId = snapshot.belongsTo(key, {
          id: true
        });
        var payloadKey = this._getMappedKey(key, snapshot.type);
        if (payloadKey === key && this.keyForRelationship) {
          payloadKey = this.keyForRelationship(key, 'belongsTo', 'serialize');
        }
        if (Ember.isNone(belongsToId)) {
          json[payloadKey] = null;
        } else {
          // The only line different from JSON serializer. Unfortunately, the closest override
          // hook for this is serializeBelongsTo, nothing smaller
          json[payloadKey] = this.recordURL(relationship.type, belongsToId);
        }
        if (relationship.options.polymorphic) {
          this.serializePolymorphicType(snapshot, json, relationship);
        }
      }
    }
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (requestType === 'queryRecord' && payload.length > 0) {
        payload = payload[0];
      }
      return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
    }
  }
  var _default = _exports.default = ApplicationSerializer;
});