define("plutof/components/sequence/regions", ["exports", "@glimmer/component", "ember-concurrency", "plutof/utils/model", "plutof/components/auto-complete/backends"], function (_exports, _component, _emberConcurrency, _model, _backends) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div data-test="Sequence::Regions">
      {{common/local-label "Sequences.sequencedRegions"}}
  
      <AutoComplete::Base
          @backend={{this.autocomplete}}
          @update={{this.add}}
          @params={{hash
              enableVoidQuery=true
              clearOnFocus=true
          }}
          data-test="regions" />
  
      <Common::RecordList
          @records={{@regions}}
          @remove={{this.remove}}
          as |item|
      >
          {{get item "representation"}}
      </Common::RecordList>
  </div>
  
  */
  {
    "id": "3dAcN2/P",
    "block": "{\"symbols\":[\"item\",\"@regions\"],\"statements\":[[10,\"div\"],[14,\"data-test\",\"Sequence::Regions\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"Sequences.sequencedRegions\"],null]],[2,\"\\n\\n    \"],[8,\"auto-complete/base\",[[24,\"data-test\",\"regions\"]],[[\"@backend\",\"@update\",\"@params\"],[[32,0,[\"autocomplete\"]],[32,0,[\"add\"]],[30,[36,1],null,[[\"enableVoidQuery\",\"clearOnFocus\"],[true,true]]]]],null],[2,\"\\n\\n    \"],[8,\"common/record-list\",[],[[\"@records\",\"@remove\"],[[32,2],[32,0,[\"remove\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,2],[[32,1],\"representation\"],null]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"common/local-label\",\"hash\",\"get\"]}",
    "meta": {
      "moduleName": "plutof/components/sequence/regions.hbs"
    }
  });
  let SequenceRegions = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = (0, _model.choices)('taxonoccurrence.sequence.sequencetype.type'), _dec4 = Ember._action, _dec5 = Ember._action, (_class = class SequenceRegions extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "types", _descriptor2, this);
      _initializerDefineProperty(this, "typeChoices", _descriptor3, this);
      _initializerDefineProperty(this, "load", _descriptor4, this);
      this.load.perform();
    }
    get autocomplete() {
      return new _backends.StaticAutocomplete({
        records: this.types,
        id: record => record.id,
        value: record => record.display_name
      });
    }
    add(typeChoice) {
      if (Ember.isNone(typeChoice)) {
        return;
      }
      const type = typeChoice.value;
      if (this.args.regions.some(region => region.type === type)) {
        return;
      }
      const region = this.store.createRecord('taxonoccurrence/sequence/sequencetype', {
        sequence: this.args.sequence,
        type
      });
      this.args.regions.pushObject(region);
    }
    remove(region) {
      this.args.regions.removeObject(region);
      this.args.objectsToDelete.pushObject(region);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "types", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "typeChoices", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "load", [_emberConcurrency.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        const types = yield this.typeChoices.then(types => types.sortBy('display_name'));
        this.types = types;
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "add", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "add"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "remove", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "remove"), _class.prototype)), _class));
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SequenceRegions);
});