define("plutof/translations/helpers", ["exports", "plutof/misc/config"], function (_exports, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.alias = alias;
  function alias(code) {
    return _config.default.I18n.ALIAS_PREFIX + code;
  }
});