define("plutof/controllers/taxonomy/addtree", ["exports", "plutof/mixins/add-controller", "plutof/mixins/component-validations", "plutof/utils/notifications"], function (_exports, _addController, _componentValidations, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let AddTreeController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed.or('validationContext.isInvalid', 'saving'), _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, (_class = class AddTreeController extends Ember.Controller.extend(_addController.default, _componentValidations.default) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "i18n", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _defineProperty(this, "queryParams", ['root_id', 'origin_tree_id']);
      _defineProperty(this, "root_id", null);
      _defineProperty(this, "origin_tree_id", null);
      _defineProperty(this, "validationChildren", [{
        name: 'tree',
        label: 'Taxonomy.treeData'
      }, {
        name: 'access',
        label: 'General.access'
      }]);
      _initializerDefineProperty(this, "disableSave", _descriptor3, this);
    }
    async saveTree(record) {
      const tree = await record.save();
      await this.get('model.accessRights').saveWithTarget(tree);
      return tree;
    }
    save() {
      (0, _notifications.displayNotification)('status', this.i18n.t('General.saving'));
      const record = this.store.createRecord('taxonomy/tree', {
        name: this.name,
        origin_tree: this.get('model.originTree'),
        root_node: this.get('model.rootNode')
      });
      this.set('saving', true);
      this.saveTree(record).then(tree => {
        this.set('routeHasBeenLoaded', false);
        (0, _notifications.displayNotification)('success', this.i18n.t('General.done'));
        this.router.transitionTo('taxonomy.trees', {
          queryParams: {
            tree_id: tree.get('id')
          }
        });
      }, _notifications.reportError).finally(() => this.set('saving', false));
    }
    cancel() {
      this.reset();
      this.router.transitionTo('taxonomy.trees');
    }
    reset() {
      this.set('name', '');
      this.set('root_id', null);
      this.set('origin_tree_id', null);
      this.set('model.rootNode', null);
      this.set('model.originTree', null);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "i18n", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "disableSave", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "save", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reset", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "reset"), _class.prototype)), _class));
  var _default = _exports.default = AddTreeController;
});