define("plutof/components/search/query/search-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{common/local-label "Search.inputLabel" "Search.inputTooltip"}}
  
  <div class="input-group">
      <Input
          @type="text"
          @enter={{@search}}
          @value={{@searchString}}
          class="form-control search-value-input" />
  
      <span class="input-group-addon">
          <span class="plutof-tooltip-hover">
              <label class="search-over-all-label">
                  <Input @type="checkbox" @checked={{@searchAllFields}} />
                  {{i18n-t "Search.searchAllFields"}}
              </label>
  
              <span class="plutof-tooltip">
                  <span class="monospace">[✔]</span> {{i18n-t "Search.searchAllFields"}}
                  <br />
                  <span class="monospace">[&nbsp;]</span> {{i18n-t "Search.searchNameField"}}
              </span>
          </span>
      </span>
  </div>
  
  */
  {
    "id": "2BwY002L",
    "block": "{\"symbols\":[\"@search\",\"@searchString\",\"@searchAllFields\"],\"statements\":[[1,[30,[36,0],[\"Search.inputLabel\",\"Search.inputTooltip\"],null]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"input-group\"],[12],[2,\"\\n    \"],[8,\"input\",[[24,0,\"form-control search-value-input\"]],[[\"@type\",\"@enter\",\"@value\"],[\"text\",[32,1],[32,2]]],null],[2,\"\\n\\n    \"],[10,\"span\"],[14,0,\"input-group-addon\"],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"plutof-tooltip-hover\"],[12],[2,\"\\n            \"],[10,\"label\"],[14,0,\"search-over-all-label\"],[12],[2,\"\\n                \"],[8,\"input\",[],[[\"@type\",\"@checked\"],[\"checkbox\",[32,3]]],null],[2,\"\\n                \"],[1,[30,[36,1],[\"Search.searchAllFields\"],null]],[2,\"\\n            \"],[13],[2,\"\\n\\n            \"],[10,\"span\"],[14,0,\"plutof-tooltip\"],[12],[2,\"\\n                \"],[10,\"span\"],[14,0,\"monospace\"],[12],[2,\"[✔]\"],[13],[2,\" \"],[1,[30,[36,1],[\"Search.searchAllFields\"],null]],[2,\"\\n                \"],[10,\"br\"],[12],[13],[2,\"\\n                \"],[10,\"span\"],[14,0,\"monospace\"],[12],[2,\"[ ]\"],[13],[2,\" \"],[1,[30,[36,1],[\"Search.searchNameField\"],null]],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"common/local-label\",\"i18n-t\"]}",
    "meta": {
      "moduleName": "plutof/components/search/query/search-string.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});