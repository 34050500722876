define("plutof/components/agent/link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#link-to
      (if
          (is-equal @agent.agent_type "organization")
          "organization.view"
          "person.view"
      )
      @agent.id
  }}
      {{@agent.representation}}
  {{/link-to}}
  
  */
  {
    "id": "Hh+PAQoY",
    "block": "{\"symbols\":[\"@agent\"],\"statements\":[[6,[37,2],null,[[\"route\",\"model\"],[[30,[36,1],[[30,[36,0],[[32,1,[\"agent_type\"]],\"organization\"],null],\"organization.view\",\"person.view\"],null],[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[32,1,[\"representation\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"is-equal\",\"if\",\"link-to\"]}",
    "meta": {
      "moduleName": "plutof/components/agent/link.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});