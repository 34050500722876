define("plutof/controllers/import/generate-template", ["exports", "ember-concurrency", "plutof/config/environment", "plutof/controllers/import/add", "plutof/utils/file-serialization", "plutof/utils/notifications"], function (_exports, _emberConcurrency, _environment, _add, _fileSerialization, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ImportGenerateTemplateController = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = (0, _emberConcurrency.task)({
    restartable: true
  }), _dec4 = Ember.computed.reads('loadTree.last.value'), _dec5 = Ember.computed('tree', 'canLoadTree'), _dec6 = Ember.computed('tree.leaves.@each.selected'), _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, (_class = class ImportGenerateTemplateController extends Ember.Controller {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "module", _descriptor, this);
      _initializerDefineProperty(this, "form", _descriptor2, this);
      _defineProperty(this, "modules", _add.MODULES.reject(module => _environment.default.DISABLED_IMPORT_MODULES.includes(module.id)));
      _initializerDefineProperty(this, "loadTree", _descriptor3, this);
      _initializerDefineProperty(this, "tree", _descriptor4, this);
    }
    get noFormRequired() {
      return this.module && this.module.noFormRequired;
    }
    get canLoadTree() {
      return this.module && (this.form || this.noFormRequired);
    }
    get requiredComponentMissing() {
      return !this.tree || !this.canLoadTree;
    }
    get selectedLeaves() {
      if (Ember.isNone(this.tree)) {
        return [];
      }

      // "specimen." etc
      const prefixLength = this.tree.title.length + 1;
      return this.tree.leaves.filterBy('selected').map(leaf => ({
        title: leaf.fullTitle.substr(prefixLength),
        typeName: leaf.typeName,
        unit: leaf.unit
      }));
    }
    updateModule(module) {
      this.module = module;
      if (module && this.form && this.form.type !== module.mainformType) {
        this.form = null;
      }
      this.loadTree.perform();
    }
    updateForm(form) {
      this.form = form;
      if (form && !this.module) {
        this.module = this.modules.find(m => m.mainformType === form.type);
      }
      this.loadTree.perform();
    }
    generateTemplate() {
      const headers = (0, _add.getTreeLeafNames)(this.tree, true);
      const headerRow = headers.map(_fileSerialization.escapeCSV).join(',') + '\n';
      const filename = this.form ? `template_${this.form.name}.csv` : 'template.csv';
      (0, _fileSerialization.saveFile)([headerRow], filename);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "module", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "form", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "loadTree", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        if (!this.canLoadTree) {
          return null;
        }
        try {
          const tree = yield (0, _add.getImportTree)(this.store, this.module.id, this.form);
          return tree;
        } catch (err) {
          (0, _notifications.reportError)(err);
        }
      };
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "tree", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "requiredComponentMissing", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "requiredComponentMissing"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectedLeaves", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "selectedLeaves"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateModule", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "updateModule"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateForm", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "updateForm"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "generateTemplate", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "generateTemplate"), _class.prototype)), _class));
  var _default = _exports.default = ImportGenerateTemplateController;
});