define("plutof/utils/coordinates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DMSToDecimal = DMSToDecimal;
  function DMSToDecimal(dms, negative = dms.degrees < 0) {
    const sign = negative ? -1 : 1;
    return sign * (Math.abs(dms.degrees) + dms.minutes / 60 + dms.seconds / 3600);
  }
});