define("plutof/translations/est/datacite", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    intro: {
      description: 'Halda DataCite DOI-ga (Digital Object Identifier) varustatud andmesette.',
      title: 'DataCite DOI-d'
    },
    panels: {
      dates: 'Ajavahemikud',
      creators: 'Autorid',
      contributors: 'Kaasautorid',
      funders: 'Rahastajad',
      resources: 'Ressursid',
      taxa: 'Taksonid',
      areas: 'Alad',
      relatedIdentifiers: 'Seotud identifikaatorid',
      alternateIdentifiers: 'Alternatiivsed identifikaatorid',
      subjects: 'Märksõnad'
    },
    actions: {
      publish: 'Registreeri DataCite-i DOI'
    },
    metadata: {
      taxon: {
        label: 'Takson'
      }
    },
    subjects: {
      subjects: {
        label: 'Märksõnad',
        placeholder: 'Sisesta uus märksõna'
      }
    },
    relatedIdentifier: {
      metadata: {
        label: 'DOI kirje',
        tooltip: 'DOI kirje PlutoF-is. Välisele identifikaatorile viitamiseks jätke tühjaks.'
      },
      identifier: {
        label: 'Identifikaator'
      },
      identifierType: {
        label: 'Identifikaatori tüüp'
      },
      relationType: {
        label: 'Seose tüüp'
      },
      resourceType: {
        label: 'Ressursi tüüp'
      }
    },
    creator: {
      agent: 'Autor',
      affiliation: 'Asutus'
    }
  };
});