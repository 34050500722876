define("plutof/routes/profile/groups/index", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "plutof/misc/profile_settings", "plutof/mixins/route", "plutof/utils/pagination"], function (_exports, _authenticatedRouteMixin, _profile_settings, _route, _pagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ProfileGroupsIndexRoute extends Ember.Route.extend(_authenticatedRouteMixin.default, _route.default) {
    async model() {
      const user = await (0, _profile_settings.get_current_user)(this.store);
      const memberships = await (0, _pagination.paginateModel)(this.store, 'users/usergroup', {
        user: user.id
      });
      return {
        user,
        memberships
      };
    }
    setupController() {
      super.setupController(...arguments);
      this.controllerFor('profile').set('currentTab', 'groups');
    }
  }
  var _default = _exports.default = ProfileGroupsIndexRoute;
});