define("plutof/templates/components/datacite/funder-row-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "UXFOTcmB",
    "block": "{\"symbols\":[\"@funder\",\"@remove\"],\"statements\":[[10,\"td\"],[15,0,[31,[\"data-table-short-cell \",[30,[36,1],[[35,0,[\"attrs\",\"funder\",\"identifier_type\",\"isInvalid\"]],\"has-error\"],null]]]],[12],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"content\",\"value\",\"selectClass\",\"class\"],[[35,2],[32,1,[\"identifier_type\"]],\"mini-input\",\"datacite-edit-funders__type\"]]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[15,0,[30,[36,1],[[35,0,[\"attrs\",\"funder\",\"funder_name\",\"isInvalid\"]],\"has-error\"],null]],[12],[2,\"\\n    \"],[1,[30,[36,4],null,[[\"type\",\"value\",\"class\"],[\"text\",[32,1,[\"funder_name\"]],\"mini-input\"]]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[12],[2,\"\\n    \"],[1,[30,[36,4],null,[[\"type\",\"value\",\"class\"],[\"text\",[32,1,[\"funder_identifier\"]],\"mini-input\"]]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[12],[2,\"\\n    \"],[1,[30,[36,4],null,[[\"type\",\"value\",\"class\"],[\"text\",[32,1,[\"award_number\"]],\"mini-input\"]]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[12],[2,\"\\n    \"],[1,[30,[36,4],null,[[\"type\",\"value\",\"class\"],[\"text\",[32,1,[\"award_title\"]],\"mini-input\"]]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[12],[2,\"\\n    \"],[1,[30,[36,4],null,[[\"type\",\"value\",\"class\"],[\"text\",[32,1,[\"award_uri\"]],\"mini-input\"]]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[14,0,\"data-table-icon-cell align-right\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-link data-table-btn\"],[4,[38,5],[\"click\",[32,2]],null],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"icon-remove--red\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"validations\",\"if\",\"identifierTypes\",\"plutof-select/value\",\"input\",\"on\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/datacite/funder-row-form.hbs"
    }
  });
});