define("plutof/mixins/record-reset", ["exports", "plutof/utils/structures"], function (_exports, _structures) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function discardChanges(record) {
    if (!record || record.isNew) {
      return;
    }
    record.rollbackAttributes();
    return record.reload();
  }
  const RecordResetMixin = Ember.Mixin.create({
    recordsToUnload: [],
    unloadRecords() {
      return Ember.RSVP.all(this.recordsToUnload.map(({
        record,
        unloadSelf = true,
        subRecords = []
      }) => {
        const primary = Ember.get(this.model, record);
        let tobeUnloaded = [];

        // True by default
        if (unloadSelf !== false) {
          tobeUnloaded.push(primary);
        }
        subRecords.forEach(relation => {
          (0, _structures.makeArray)(primary.get(relation)).forEach(record => tobeUnloaded.push(record));
        });
        return Ember.RSVP.all(tobeUnloaded.map(async record => {
          await discardChanges(await record);
        }));
      }));
    }
  });
  var _default = _exports.default = RecordResetMixin;
});