define("plutof/helpers/default-to", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.defaultToHelper = defaultToHelper;
  function defaultToHelper([value, defaultValue]) {
    return Ember.isNone(value) ? defaultValue : value;
  }
  var _default = _exports.default = Ember.Helper.helper(defaultToHelper);
});