define("plutof/utils/store/proxy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ProxyStore {
    constructor(store) {
      this._store = store;
    }
    createRecord(...args) {
      return this._store.createRecord(...args);
    }
    find(...args) {
      return this._store.find(...args);
    }
    findAll(...args) {
      return this._store.findAll(...args);
    }
    findRecord(...args) {
      return this._store.findRecord(...args);
    }
    modelFor(...args) {
      return this._store.modelFor(...args);
    }
    query(...args) {
      return this._store.query(...args);
    }
    queryRecord(...args) {
      return this._store.queryRecord(...args);
    }
    unloadRecord(...args) {
      return this._store.unloadRecord(...args);
    }
    peekRecord(...args) {
      return this._store.peekRecord(...args);
    }
    peekAll(...args) {
      return this._store.peekAll(...args);
    }
    normalize(...args) {
      return this._store.normalize(...args);
    }
    push(...args) {
      return this._store.push(...args);
    }
    pushPayload(...args) {
      return this._store.pushPayload(...args);
    }
  }
  var _default = _exports.default = ProxyStore;
});