define("plutof/initializers/add-projections", ["exports", "proj4", "ol/proj/proj4"], function (_exports, _proj, _proj2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'add-projections',
    initialize(application) {
      _proj.default.defs('EPSG:3301', '+proj=lcc +lat_1=59.33333333333334 +lat_2=58 +lat_0=57.51755393055556 +lon_0=24' + '+x_0=500000 +y_0=6375000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs');
      (0, _proj2.register)(_proj.default);
    }
  };
});