define("plutof/components/collection-lab/permit/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::Simple @title={{i18n-t "General.generalData"}}>
      <GenericViews::Record @record={{@permit}} as |view|>
          <Layout::RegularGrid @columns={{3}} as |grid|>
              {{view.field "name"}}
              {{view.field "status"}}
              {{view.field "permit_type"}}
  
              {{#grid.span 3}}
                  {{view.field "status_qualifier"}}
              {{/grid.span}}
  
              {{#grid.span 3}}
                  {{view.field "permit_text"}}
              {{/grid.span}}
  
              {{#grid.span 3}}
                  {{#view.attr "items" label=(i18n-t "permit.usedIn")}}
                      <Common::RecordListView @records={{@items}} as |item|>
                          <GenericViews::PojoRecordLink
                              @contentType={{item.content_type}}
                              @id={{item.object_id}}
                              @name={{item.content_object}} />
                      </Common::RecordListView>
                  {{/view.attr}}
              {{/grid.span}}
          </Layout::RegularGrid>
      </GenericViews::Record>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "fQzx0Eop",
    "block": "{\"symbols\":[\"view\",\"grid\",\"item\",\"@items\",\"@permit\"],\"statements\":[[8,\"plutof-panel/simple\",[],[[\"@title\"],[[30,[36,0],[\"General.generalData\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"generic-views/record\",[],[[\"@record\"],[[32,5]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"layout/regular-grid\",[],[[\"@columns\"],[3]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,1],[[32,1,[\"field\"]],\"name\"],null]],[2,\"\\n            \"],[1,[30,[36,1],[[32,1,[\"field\"]],\"status\"],null]],[2,\"\\n            \"],[1,[30,[36,1],[[32,1,[\"field\"]],\"permit_type\"],null]],[2,\"\\n\\n\"],[6,[37,1],[[32,2,[\"span\"]],3],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[1,[30,[36,1],[[32,1,[\"field\"]],\"status_qualifier\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[32,2,[\"span\"]],3],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[1,[30,[36,1],[[32,1,[\"field\"]],\"permit_text\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[32,2,[\"span\"]],3],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[32,1,[\"attr\"]],\"items\"],[[\"label\"],[[30,[36,0],[\"permit.usedIn\"],null]]],[[\"default\"],[{\"statements\":[[2,\"                    \"],[8,\"common/record-list-view\",[],[[\"@records\"],[[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n                        \"],[8,\"generic-views/pojo-record-link\",[],[[\"@contentType\",\"@id\",\"@name\"],[[32,3,[\"content_type\"]],[32,3,[\"object_id\"]],[32,3,[\"content_object\"]]]],null],[2,\"\\n                    \"]],\"parameters\":[3]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"        \"]],\"parameters\":[2]}]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"component\"]}",
    "meta": {
      "moduleName": "plutof/components/collection-lab/permit/view.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});