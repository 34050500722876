define("plutof/translations/eng/references", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    refInfo: 'View, search and manage references.',
    refSeriesTitle: 'Reference Series',
    refSeriesInfo: 'View and create reference series.',
    glossaryInfo: 'View, create and link keywords as thesauri.',
    glossaryCollectionsInfo: 'Browse keyword collections and create new ones to catalogue your keywords.',
    refSeries: 'Reference Series',
    keywordsCollection: 'Keyword Collections',
    authors: 'Authors',
    newReference: 'New Reference',
    editReference: 'Edit Reference',
    reference: 'Reference',
    newRefSeries: 'New Reference Series',
    editRefSeries: 'Edit Reference Series',
    referenceSeries: 'Reference Series',
    newPublisher: 'New Publisher',
    editPublisher: 'Edit Publisher',
    publisher: 'Publisher',
    newGlossary: 'New Keyword',
    editGlossary: 'Edit Keyword',
    glossary: 'Keyword',
    newCollection: 'New Keyword Collection',
    editCollection: 'Edit Keyword Collection',
    keywordCollection: 'Keyword Collection',
    searchTitle: 'Search References',
    searchDOI: 'Search by free text or DOI',
    searchDOITooltip: 'Search from https://refindit.org/',
    placeholders: {
      searchHint: 'Search by title, keyword'
    }
  };
});