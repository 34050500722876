define("plutof/templates/components/navbar/-navbar-base/title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "DLWECere",
    "block": "{\"symbols\":[\"@icon\",\"@title\"],\"statements\":[[10,\"span\"],[15,0,[31,[[30,[36,0],[[32,0,[\"routingStatus\",\"routeLoading\"]],\"spinicon\"],null],\" \",[32,1],\" navbar-base__title-icon\"]]],[15,\"data-test-route-status\",[31,[[30,[36,0],[[32,0,[\"routingStatus\",\"routeLoading\"]],\"loading\",\"settled\"],null]]]],[12],[13],[2,\"\\n\\n\"],[1,[32,2]],[2,\" \\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/navbar/-navbar-base/title.hbs"
    }
  });
});