define("plutof/components/related-objects/content/areas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <RelatedObjects::Content @pagination={{@pagination}} as |Field area|>
      <Field @label={{i18n-t "RelatedObjectsViewColumn.name"}}>
          {{#link-to "area.view" area.id}}
              {{area.name}}
          {{/link-to}}
      </Field>
  
      <Field @label={{i18n-t "RelatedObjectsViewColumn.country"}}>
          {{area.country_name}}
      </Field>
  
      <Field @label={{i18n-t "RelatedObjectsViewColumn.timespan"}}>
          {{formatted-timespan area.timespan_begin area.timespan_end}}
      </Field>
  
      <Field @label={{i18n-t "RelatedObjectsViewColumn.modified"}}>
          {{formatted-date area.updated_at}}
      </Field>
  </RelatedObjects::Content>
  
  */
  {
    "id": "ARPPakk9",
    "block": "{\"symbols\":[\"Field\",\"area\",\"@pagination\"],\"statements\":[[8,\"related-objects/content\",[],[[\"@pagination\"],[[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1],[],[[\"@label\"],[[30,[36,0],[\"RelatedObjectsViewColumn.name\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],null,[[\"route\",\"model\"],[\"area.view\",[32,2,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[32,2,[\"name\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,1],[],[[\"@label\"],[[30,[36,0],[\"RelatedObjectsViewColumn.country\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,2,[\"country_name\"]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,1],[],[[\"@label\"],[[30,[36,0],[\"RelatedObjectsViewColumn.timespan\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,2],[[32,2,[\"timespan_begin\"]],[32,2,[\"timespan_end\"]]],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,1],[],[[\"@label\"],[[30,[36,0],[\"RelatedObjectsViewColumn.modified\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,3],[[32,2,[\"updated_at\"]]],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"link-to\",\"formatted-timespan\",\"formatted-date\"]}",
    "meta": {
      "moduleName": "plutof/components/related-objects/content/areas.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});