define("plutof/components/tab-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Tab extends Ember.Object {}
  class TabGroup extends Ember.Component {
    init() {
      super.init(...arguments);
      this.set('tabs', []);
      this.set('headersContainerId', Math.floor(Math.random() * 1e9).toString());
    }
    createTab(name) {
      return Tab.create({
        name,
        selected: false
      });
    }
    getTab(name) {
      const tabs = this.tabs;
      const selectedName = this.selected;
      let tab = tabs.findBy('name', name);
      if (Ember.isNone(tab)) {
        tab = this.createTab(name);
        tabs.pushObject(tab);

        // TODO: external initial selection
        const selected = selectedName === name || Ember.isNone(selectedName) && tabs.get('length') === 1;
        tab.set('selected', selected);
        return tab;
      }
      return tab;
    }
    notifySelection(tab) {
      if (!Ember.isNone(this.tabSwitched)) {
        this.tabSwitched(tab.get('name'));
      }
    }
    switchTab(tab, manual = false) {
      this.tabs.forEach(t => {
        t.set('selected', t === tab);
      });
      if (manual) {
        this.notifySelection(tab);
      }
    }
    get headersContainer() {
      return document.getElementById(this.headersContainerId);
    }
  }
  var _default = _exports.default = TabGroup;
});