define("plutof/translations/eng/group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    fields: {
      description: 'Description'
    },
    actions: {
      join: 'Join'
    },
    status: {
      0: 'Pending approval',
      2: 'Regular member',
      4: 'Moderator',
      8: 'Owner'
    },
    messages: {
      joinRequestSent: 'Request to join sent.'
    },
    panels: {
      workgroup: 'Managing Group'
    }
  };
});