define("plutof/templates/components/information/popup-term", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "6L5ub9kr",
    "block": "{\"symbols\":[\"&default\",\"@image\"],\"statements\":[[10,\"span\"],[14,0,\"information-popup-term\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"information-popup-term__trigger\"],[12],[2,\"\\n        \"],[18,1,null],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"information-popup-term__popup\"],[12],[2,\"\\n        \"],[10,\"img\"],[15,\"src\",[31,[[32,2]]]],[14,0,\"information-popup-term__image\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "plutof/templates/components/information/popup-term.hbs"
    }
  });
});