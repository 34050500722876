define("plutof/components/tables/-list-view/order-group-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Tables::-ListView::Order
      @field={{@field}}
      @active={{is-equal @key @orderedBy}}
      @ascending={{@ascending}}
      @sortBy={{fn @sortBy @key}}
      @label={{@label}}
      @disabled={{@disabled}}
  >
      {{yield}}
  </Tables::-ListView::Order>
  
  */
  {
    "id": "xwVDzSO/",
    "block": "{\"symbols\":[\"@field\",\"@orderedBy\",\"@key\",\"@ascending\",\"@sortBy\",\"@label\",\"@disabled\",\"&default\"],\"statements\":[[8,\"tables/-list-view/order\",[],[[\"@field\",\"@active\",\"@ascending\",\"@sortBy\",\"@label\",\"@disabled\"],[[32,1],[30,[36,0],[[32,3],[32,2]],null],[32,4],[30,[36,1],[[32,5],[32,3]],null],[32,6],[32,7]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[18,8,null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"is-equal\",\"fn\"]}",
    "meta": {
      "moduleName": "plutof/components/tables/-list-view/order-group-field.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});