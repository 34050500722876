define("plutof/templates/components/check-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "K6dhhsNG",
    "block": "{\"symbols\":[],\"statements\":[[10,\"label\"],[14,0,\"plutof-label plutof-label--overflow\"],[12],[2,\"\\n    \"],[1,[30,[36,7],null,[[\"type\",\"checked\",\"disabled\"],[\"checkbox\",[35,6],[35,0]]]]],[2,\"\\n\\n\"],[6,[37,1],[[35,8]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,5],null,[[\"path\",\"source\",\"tagName\"],[[35,2],[35,4],\"span\"]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[15,0,[31,[\"plutof-label plutof-label--overflow \",[30,[36,1],[[35,0],\"text-muted\"],null]]]],[12],[2,\"\\n            \"],[1,[30,[36,3],[[35,2]],null]],[2,\"\\n        \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"disabled\",\"if\",\"label\",\"i18n-t\",\"source\",\"plutof-labelc\",\"checked\",\"input\",\"isField\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/check-box.hbs"
    }
  });
});