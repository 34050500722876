define("plutof/components/sample/sample-breadcrumbs", ["exports", "@glimmer/component", "plutof/utils/modules"], function (_exports, _component, _modules) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let SampleBreadcrumbs = (_dec = Ember.computed('args.{occurrence.id,area.id,event.id,project.id}'), (_class = class SampleBreadcrumbs extends _component.default {
    async loadAncestry(leaf, getParent) {
      let ancestry = [];
      let current = leaf;
      do {
        ancestry.push(current);
        current = await getParent(current);
      } while (current);
      ancestry.reverse();
      return ancestry;
    }
    async _load() {
      const event = this.args.occurrence ? await this.args.occurrence.samplingevent : this.args.event;
      const area = await (event ? event.samplingarea : this.args.area);
      let areas = [];
      let project;
      let projects = [];
      if (area) {
        areas = await this.loadAncestry(area, area => area.guardedParent);
        const topLevelArea = areas[0];
        project = await topLevelArea.guardedProject;
      } else {
        project = this.args.project;
      }
      if (project) {
        projects = await this.loadAncestry(project, project => project.guardedParent);
      }
      return {
        occurrence: this.args.occurrence ? {
          record: this.args.occurrence,
          icon: (0, _modules.getRecordModule)(this.args.occurrence).icon
        } : null,
        event,
        areas,
        projects,
        leaf: await (this.args.occurrence || this.args.event || this.args.area || this.args.project)
      };
    }
    get breadcrumbs() {
      return this._load();
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "breadcrumbs", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "breadcrumbs"), _class.prototype)), _class));
  var _default = _exports.default = SampleBreadcrumbs;
});