define("plutof/routes/observation/add", ["exports", "plutof/components/interaction/edit-multiple", "plutof/components/linked-items/edit", "plutof/components/observation/observation-table", "plutof/misc/content_types", "plutof/utils/exif", "plutof/misc/profile_settings", "plutof/mixins/add-route", "plutof/utils/access", "plutof/utils/cloning", "plutof/utils/model", "plutof/components/layer/occurrence-areas"], function (_exports, _editMultiple, _edit, _observationTable, _content_types, _exif, _profile_settings, _addRoute, _access, _cloning, _model, _occurrenceAreas) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ObservationAddRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, (_class = class ObservationAddRoute extends Ember.Route.extend(_addRoute.default) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "fileUpload", _descriptor2, this);
      _initializerDefineProperty(this, "geocoding", _descriptor3, this);
      _defineProperty(this, "defaultReturnRoute", 'observation.index');
      _defineProperty(this, "paramsClearedOnReset", ['template_id', 'file_ids']);
      _defineProperty(this, "title", 'Observations.newObservation');
    }
    model(params) {
      if (this.get('controller.routeHasBeenLoaded')) {
        return undefined;
      }
      return this.createObservationData(params).then(data => {
        // Clear existing data.
        if (!Ember.isNone(this.controller)) {
          this.set('controller.samplingAreaEventFormData', null);
          this.set('controller.itemData', null);
        }
        return Ember.RSVP.hash({
          observationsData: data.observationsData,
          determinations: [],
          accessRights: data.accessRights,
          itemData: data.itemData,
          selectedMeasurementsForm: data.selectedMeasurementsForm,
          samplingAreaEventFormData: data.samplingAreaEventFormData,
          layers: _occurrenceAreas.LinkedLayersModel.create(this.ajax),
          materialSample: data.materialSample,
          livingSpecimen: data.livingSpecimen,
          interactionData: new _editMultiple.UnboundInteractionsData({
            store: this.store
          }),
          contentType: (0, _content_types.get_ctype_by_name)(this.store, 'observation/observation')
        });
      });
    }
    async createObservationData(params) {
      if (!Ember.isNone(params) && !Ember.isNone(params.template_id)) {
        const template = await this.store.findRecord('taxonoccurrence/observation/observation', params.template_id);
        return this.cloneExistingObservation(template, params.clone_area === 'true', params.clone_event === 'true');
      }
      const samplingData = await (0, _model.getSamplingDataForParams)(this.store, params);
      const [files, itemData] = await this.loadFiles(params);
      return Ember.RSVP.hash({
        observationsData: _observationTable.ObservationTableData.initialize(this.store, this.fileUpload, [], {
          sharedItems: itemData
        }),
        accessRights: (0, _access.create_access_rights)(this.store),
        itemData,
        selectedMeasurementsForm: this.loadForm(params),
        samplingAreaEventFormData: (0, _exif.fillSampleFromEXIF)(this.store, this.geocoding, samplingData.formData, files),
        materialSample: samplingData.materialsample,
        livingSpecimen: samplingData.livingspecimen
      });
    }
    async loadFiles(params) {
      let files = [];
      const itemData = await (0, _edit.createMultipleLinkedItemsData)(this.store, {
        useEventFiles: true
      });
      if (!Ember.isEmpty(params.file_ids)) {
        files = await Ember.RSVP.all(params.file_ids.split(',').map(id => {
          return this.store.findRecord('filerepository/file', id);
        }));
        for (const file of files) {
          itemData.files.add({
            file
          });
        }
      }
      return [files, itemData];
    }
    async loadForm(params) {
      if (Ember.isNone(params.mainform_id)) {
        const settings = await (0, _profile_settings.get_personal_settings)(this.store);
        return settings.observation_form;
      }
      return this.store.findRecord('measurement/mainform', params.mainform_id);
    }
    async cloneExistingObservation(template, cloneArea, cloneEvent) {
      const observation = await (0, _cloning.clone_record)(this.store, template);
      const samplingData = await (0, _model.getOccurrenceSamplingData)(this.store, template).then(samplingData => {
        return (0, _cloning.clone_sampling_data)(this.store, samplingData.formData, cloneArea, cloneEvent);
      });
      return Ember.RSVP.hash({
        observationsData: _observationTable.ObservationTableData.initialize(this.store, this.fileUpload),
        selectedMeasurementsForm: observation.mainform,
        itemData: (0, _edit.createMultipleLinkedItemsData)(this.store, {
          useEventFiles: true
        }),
        accessRights: (0, _access.create_access_rights)(this.store),
        materialSample: samplingData.materialsample,
        livingSpecimen: samplingData.livingspecimen,
        samplingAreaEventFormData: samplingData
      });
    }
    setupController(controller, model) {
      super.setupController(controller, model);
      controller.setProperties({
        closePanels: false,
        routeHasBeenLoaded: true,
        sharedFileWarningShown: false
      });
    }
    doRefresh() {
      this.refresh();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fileUpload", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "geocoding", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "doRefresh", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "doRefresh"), _class.prototype)), _class));
  var _default = _exports.default = ObservationAddRoute;
});