define("plutof/translations/est/linked-items", ["exports", "plutof/translations/helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    browserAudio: 'Sinu veebilehitseja ei toeta HTML5 helimängijat.',
    browserVideo: 'Sinu veebilehitseja ei toeta HTML5 videomängijat.',
    of: '/',
    record: (0, _helpers.alias)('General.record'),
    event: 'Kogumise sündmus/elupaik'
  };
});