define("plutof/components/sequence/parent-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "Sequences.linkedTo"}}
      data-test="Sequence::ParentSelector"
  >
      <Common::RadioGroup
          @active={{this.activeType}}
          @activate={{this.switchType}}
          as |radioGroup|
      >
          {{#each this.types as |type|}}
              <radioGroup.choice
                  @name={{type}}
                  data-test={{type.id}}
              >
                  {{i18n-t type.name}}
              </radioGroup.choice>
          {{/each}}
      </Common::RadioGroup>
  
      {{#if this.activeType.model}}
          <Layout::RegularGrid @columns={{2}} @cellGap={{true}}>
              <div class="{{if this.localSelection '' 'has-error'}}">
                  <AutoComplete::Generic
                      @model={{this.activeType.model}}
                      @value={{this.localSelection}}
                      @update={{this.changeParent}}
                      @filters={{hash access=(if @requireEditPermissions "edit" "view")}}
                      data-test="selection" />
              </div>
          </Layout::RegularGrid>
      {{/if}}
  </PlutofPanel::Simple>
  
  */
  {
    "id": "A/om3YfD",
    "block": "{\"symbols\":[\"radioGroup\",\"type\",\"@requireEditPermissions\"],\"statements\":[[8,\"plutof-panel/simple\",[[24,\"data-test\",\"Sequence::ParentSelector\"]],[[\"@title\"],[[30,[36,2],[\"Sequences.linkedTo\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"common/radio-group\",[],[[\"@active\",\"@activate\"],[[32,0,[\"activeType\"]],[32,0,[\"switchType\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,0,[\"types\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,[32,1,[\"choice\"]],[[16,\"data-test\",[32,2,[\"id\"]]]],[[\"@name\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[1,[30,[36,2],[[32,2,[\"name\"]]],null]],[2,\"\\n            \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"    \"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"],[6,[37,0],[[32,0,[\"activeType\",\"model\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"layout/regular-grid\",[],[[\"@columns\",\"@cellGap\"],[2,true]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[10,\"div\"],[15,0,[31,[[30,[36,0],[[32,0,[\"localSelection\"]],\"\",\"has-error\"],null]]]],[12],[2,\"\\n                \"],[8,\"auto-complete/generic\",[[24,\"data-test\",\"selection\"]],[[\"@model\",\"@value\",\"@update\",\"@filters\"],[[32,0,[\"activeType\",\"model\"]],[32,0,[\"localSelection\"]],[32,0,[\"changeParent\"]],[30,[36,1],null,[[\"access\"],[[30,[36,0],[[32,3],\"edit\",\"view\"],null]]]]]],null],[2,\"\\n            \"],[13],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"hash\",\"i18n-t\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "plutof/components/sequence/parent-selector.hbs"
    }
  });
  let ParentSelector = (_dec = Ember.inject.service, _dec2 = Ember.computed('enableLivingSpecimens'), _dec3 = Ember._action, _dec4 = Ember._action, (_class = class ParentSelector extends Ember.Component {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _defineProperty(this, "activeType", null);
      _defineProperty(this, "localSelection", null);
      _defineProperty(this, "enableLivingSpecimens", false);
    }
    get types() {
      const modelType = (model, name) => ({
        id: model,
        name,
        model,
        activate: () => null,
        select: occurrence => this.setToOccurrence(occurrence)
      });
      return [{
        id: 'noParent',
        name: 'GenericSelectorModel.none',
        activate: () => this.unlinkParent(),
        select: () => null
      }, modelType('taxonoccurrence/specimen/specimen', 'GenericSelectorModel.specimen'), this.enableLivingSpecimens ? modelType('taxonoccurrence/livingculture/strain', 'GenericSelectorModel.strain') : null, modelType('taxonoccurrence/materialsample/materialsample', 'GenericSelectorModel.materialsample')].filter(Boolean);
    }
    didReceiveAttrs() {
      if (this.parent) {
        const type = this.types.findBy('model', this.parent.constructor.modelName);
        if (type) {
          this.setProperties({
            activeType: type,
            localSelection: this.parent
          });
        }
      } else {
        this.setProperties({
          activeType: this.types.findBy('id', 'noParent')
        });
      }
    }
    switchType(type) {
      this.set('activeType', type);
      if (this.parent && type.model === this.parent.constructor.modelName) {
        this.set('localSelection', this.parent);
      } else if (this.event && type.id === 'existingEvent') {
        this.set('localSelection', this.event);
      } else {
        this.set('localSelection', null);
      }
      type.activate();
    }
    changeParent(parent) {
      this.set('localSelection', parent);
      if (parent) {
        this.activeType.select(parent);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "types", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "types"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "switchType", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "switchType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeParent", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "changeParent"), _class.prototype)), _class));
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ParentSelector);
});