define("plutof/components/taxonomy/common-names/table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Tables::List
      @records={{@commonNames}}
      data-test="Taxonomy::CommonNames::Table"
      as |Field commonName|
  >
      <I18n::ModelStrings @model="taxonomy/commonname" as |translations|>
          <Field @label={{translations.common_name.label}}>
              {{commonName.common_name}}
          </Field>
  
          <Field @label={{translations.iso_639.label}}>
              {{commonName.iso_639.representation}}
          </Field>
  
          <Field @label={{translations.is_preferred.label}}>
              {{read-only-boolean commonName.is_preferred}}
          </Field>
  
          {{#if @remove}}
              <Field>
                  <button
                      {{on "click" (fn @remove commonName)}}
                      class="btn-fake-link"
                  >
                      <span class={{icon "remove"}}></span>
                  </button>
              </Field>
          {{/if}}
      </I18n::ModelStrings>
  </Tables::List>
  
  */
  {
    "id": "rkHfZRs4",
    "block": "{\"symbols\":[\"Field\",\"commonName\",\"translations\",\"@remove\",\"@commonNames\"],\"statements\":[[8,\"tables/list\",[[24,\"data-test\",\"Taxonomy::CommonNames::Table\"]],[[\"@records\"],[[32,5]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"i18n/model-strings\",[],[[\"@model\"],[\"taxonomy/commonname\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,1],[],[[\"@label\"],[[32,3,[\"common_name\",\"label\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[32,2,[\"common_name\"]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,1],[],[[\"@label\"],[[32,3,[\"iso_639\",\"label\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[32,2,[\"iso_639\",\"representation\"]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,1],[],[[\"@label\"],[[32,3,[\"is_preferred\",\"label\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,3],[[32,2,[\"is_preferred\"]]],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[6,[37,4],[[32,4]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[11,\"button\"],[24,0,\"btn-fake-link\"],[4,[38,1],[\"click\",[30,[36,0],[[32,4],[32,2]],null]],null],[12],[2,\"\\n                    \"],[10,\"span\"],[15,0,[30,[36,2],[\"remove\"],null]],[12],[13],[2,\"\\n                \"],[13],[2,\"\\n            \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"]],\"parameters\":[3]}]]],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"icon\",\"read-only-boolean\",\"if\"]}",
    "meta": {
      "moduleName": "plutof/components/taxonomy/common-names/table.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});