define("plutof/components/plutof-panel/panel", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _class2;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{! TODO: Use PlutofPanel::Base for ui}}
  <div class="panel-heading">
      <div
          {{on "click" this.toggle}}
          class="panel-title-container {{unless this.open 'panel-title-container--collapsed'}}"
          data-test="panel-title"
      >
          <h4 class="panel-title {{if @invalid 'panel-title--invalid'}}">
              {{yield (hash
                  title=(component "named-slot")
                  badge=(component "plutof-panel/-panel/badge")
              )}}
          </h4>
      </div>
  
      <div class="panel-header-controls inline-flex">
          {{yield (hash controls=(component "named-slot"))}}
          {{yield (hash button=(component "plutof-panel/-panel/action-button"))}}
      </div>
  </div>
  
  <div {{collapse this.open}}>
      {{#if this.renderBody}}
          <div class="panel-body {{if @outer 'no-padding'}}">
              {{yield (hash body=(component "named-slot"))}}
          </div>
      {{/if}}
  </div>
  
  */
  {
    "id": "8hlTU4DU",
    "block": "{\"symbols\":[\"@outer\",\"&default\",\"@invalid\"],\"statements\":[[10,\"div\"],[14,0,\"panel-heading\"],[12],[2,\"\\n    \"],[11,\"div\"],[16,0,[31,[\"panel-title-container \",[30,[36,3],[[32,0,[\"open\"]],\"panel-title-container--collapsed\"],null]]]],[24,\"data-test\",\"panel-title\"],[4,[38,4],[\"click\",[32,0,[\"toggle\"]]],null],[12],[2,\"\\n        \"],[10,\"h4\"],[15,0,[31,[\"panel-title \",[30,[36,0],[[32,3],\"panel-title--invalid\"],null]]]],[12],[2,\"\\n            \"],[18,2,[[30,[36,2],null,[[\"title\",\"badge\"],[[30,[36,1],[\"named-slot\"],null],[30,[36,1],[\"plutof-panel/-panel/badge\"],null]]]]]],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"panel-header-controls inline-flex\"],[12],[2,\"\\n        \"],[18,2,[[30,[36,2],null,[[\"controls\"],[[30,[36,1],[\"named-slot\"],null]]]]]],[2,\"\\n        \"],[18,2,[[30,[36,2],null,[[\"button\"],[[30,[36,1],[\"plutof-panel/-panel/action-button\"],null]]]]]],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[11,\"div\"],[4,[38,5],[[32,0,[\"open\"]]],null],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"renderBody\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[15,0,[31,[\"panel-body \",[30,[36,0],[[32,1],\"no-padding\"],null]]]],[12],[2,\"\\n            \"],[18,2,[[30,[36,2],null,[[\"body\"],[[30,[36,1],[\"named-slot\"],null]]]]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"component\",\"hash\",\"unless\",\"on\",\"collapse\"]}",
    "meta": {
      "moduleName": "plutof/components/plutof-panel/panel.hbs"
    }
  });
  let Panel = (_dec = (0, _component.classNames)('panel'), _dec2 = Ember.computed('dontRenderCollapsed', 'open'), _dec3 = Ember._action, _dec(_class = (_class2 = class Panel extends Ember.Component {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "open", true);
    }
    didReceiveAttrs() {
      if (!Ember.isNone(this.collapse) && this.collapse === this.open) {
        this.toggle();
      }
    }

    // Pass @dontRenderCollapsed to replicate {{plutof-panel}} behaviour of destroying
    // collapsed content
    get renderBody() {
      return !this.dontRenderCollapsed || this.open;
    }
    toggle() {
      this.set('open', !this.open);
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "renderBody", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "renderBody"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "toggle", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "toggle"), _class2.prototype)), _class2)) || _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Panel);
});