define("plutof/routes/forms/add", ["exports", "plutof/components/forms/taxon-sheet", "plutof/routes/forms/edit", "plutof/utils/routes"], function (_exports, _taxonSheet, _edit, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class FormsAddRoute extends _routes.AddRoute {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "defaultReturnRoute", 'forms.index');
      _defineProperty(this, "title", 'Forms.newTitle');
      _defineProperty(this, "searchModule", 'trait');
    }
    async model() {
      const mainform = this.store.createRecord('measurement/mainform', {
        type: null
      });

      // XXX
      const mainformData = await Ember.RSVP.hash({
        mainform,
        areaForm: (0, _edit.getMeasurementsPromise)(this.store, 'samplingarea'),
        eventForm: (0, _edit.getMeasurementsPromise)(this.store, 'samplingevent'),
        habitatForm: (0, _edit.getMeasurementsPromise)(this.store, 'habitat'),
        measurementForms: [],
        taxonMainforms: [],
        taxonSheetData: (0, _taxonSheet.createTaxonSheetData)(this.store, mainform),
        itemsToDelete: []
      });
      return {
        mainformData
      };
    }
  }
  var _default = _exports.default = FormsAddRoute;
});