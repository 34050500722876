define("plutof/components/information/content/observation/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Information::MarkdownReader @path="observation/add" @locale={{@locale}} />
  <Information::Content::Observation::General @locale={{@locale}} />
  
  */
  {
    "id": "4d8cja61",
    "block": "{\"symbols\":[\"@locale\"],\"statements\":[[8,\"information/markdown-reader\",[],[[\"@path\",\"@locale\"],[\"observation/add\",[32,1]]],null],[2,\"\\n\"],[8,\"information/content/observation/general\",[],[[\"@locale\"],[[32,1]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "plutof/components/information/content/observation/add.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});