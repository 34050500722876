define("plutof/templates/components/datacite/date-row-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "eTVEj/I7",
    "block": "{\"symbols\":[\"@date\",\"@remove\"],\"statements\":[[10,\"td\"],[15,0,[31,[\"data-table-short-cell \",[30,[36,1],[[35,0,[\"attrs\",\"date\",\"date_type\",\"isInvalid\"]],\"has-error\"],null]]]],[12],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"content\",\"value\",\"selectClass\",\"class\"],[[35,2],[32,1,[\"date_type\"]],\"mini-input\",\"datacite-edit-dates__type\"]]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[15,0,[30,[36,1],[[35,0,[\"attrs\",\"dateStartIsValid\",\"isInvalid\"]],\"has-error\"],null]],[12],[2,\"\\n    \"],[1,[30,[36,5],null,[[\"value\",\"validity\",\"class\"],[[32,1,[\"date_start\"]],[35,4],\"mini-input datacite-edit-dates__start\"]]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[15,0,[30,[36,1],[[35,0,[\"attrs\",\"date\",\"dateEndValidity\",\"isInvalid\"]],\"has-error\"],null]],[12],[2,\"\\n    \"],[1,[30,[36,5],null,[[\"value\",\"validity\",\"class\"],[[32,1,[\"date_end\"]],[35,6],\"mini-input datacite-edit-dates__end\"]]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[12],[2,\"\\n    \"],[8,\"flexible-textarea\",[[24,0,\"mini-input datacite-edit-dates__info\"]],[[\"@value\"],[[34,7,[\"date_information\"]]]],null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[14,0,\"data-table-icon-cell align-right\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-link data-table-btn\"],[4,[38,8],[\"click\",[32,2]],null],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"icon-remove--red\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"validations\",\"if\",\"dateTypes\",\"plutof-select/value\",\"dateStartValidity\",\"pikaday-date\",\"dateEndValidity\",\"date\",\"on\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/datacite/date-row-form.hbs"
    }
  });
});