define("plutof/components/search/filters/choice-or-exists", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @choiceFilter}}
      <div class="plutof-tooltip-hover">
          <div class="filter-input">
              <div class="label-control">
                  <Search::Filters::Checkbox
                      @filter={{@existsFilter}}
                      @changed={{this.updateFilters}}
                  >
                      {{@choiceFilter.config.label}}
                  </Search::Filters::Checkbox>
              </div>
  
              <PlutofSelect::Value
                  @content={{this.choices}}
                  @value={{@choiceFilter.value}}
                  @disabled={{this.disableSelection}} />
  
              <div class="plutof-tooltip">
                  <div>{{@existsFilter.config.help_text}}</div>
  
                  <Search::TriStateTooltip />
              </div>
          </div>
      </div>
  {{/if}}
  
  */
  {
    "id": "TQojcUen",
    "block": "{\"symbols\":[\"@existsFilter\",\"@choiceFilter\"],\"statements\":[[6,[37,0],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"plutof-tooltip-hover\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"filter-input\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"label-control\"],[12],[2,\"\\n                \"],[8,\"search/filters/checkbox\",[],[[\"@filter\",\"@changed\"],[[32,1],[32,0,[\"updateFilters\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n                    \"],[1,[32,2,[\"config\",\"label\"]]],[2,\"\\n                \"]],\"parameters\":[]}]]],[2,\"\\n            \"],[13],[2,\"\\n\\n            \"],[8,\"plutof-select/value\",[],[[\"@content\",\"@value\",\"@disabled\"],[[32,0,[\"choices\"]],[32,2,[\"value\"]],[32,0,[\"disableSelection\"]]]],null],[2,\"\\n\\n            \"],[10,\"div\"],[14,0,\"plutof-tooltip\"],[12],[2,\"\\n                \"],[10,\"div\"],[12],[1,[32,1,[\"config\",\"help_text\"]]],[13],[2,\"\\n\\n                \"],[8,\"search/tri-state-tooltip\",[],[[],[]],null],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "plutof/components/search/filters/choice-or-exists.hbs"
    }
  });
  let ChoiceOrExistsFilter = _exports.default = (_dec = Ember.computed('args.choiceFilter.choices'), _dec2 = Ember.computed('args.existsFilter.value'), _dec3 = Ember._action, (_class = class ChoiceOrExistsFilter extends _component.default {
    get choices() {
      if (Ember.isNone(this.args.choiceFilter)) {
        return [];
      }
      const choices = this.args.choiceFilter.config.choices;
      return choices.map(choice => {
        return {
          display_name: choice.display_name,
          value: String(choice.value)
        };
      });
    }
    get disableSelection() {
      const filter = this.args.existsFilter;
      return Ember.isNone(filter) || !Ember.isEmpty(filter.value);
    }
    updateFilters(exists) {
      if (!Ember.isNone(exists)) {
        this.args.choiceFilter.set('value', null);
      }
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "choices", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "choices"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "disableSelection", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "disableSelection"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateFilters", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "updateFilters"), _class.prototype)), _class));
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ChoiceOrExistsFilter);
});