define("plutof/routes/index", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "plutof/mixins/route"], function (_exports, _authenticatedRouteMixin, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class IndexRoute extends Ember.Route.extend(_route.default, _authenticatedRouteMixin.default) {
    model(params) {
      return {};
    }
  }
  var _default = _exports.default = IndexRoute;
});