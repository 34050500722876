define("plutof/templates/components/study/area-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "DRGbdPWa",
    "block": "{\"symbols\":[\"panel\",\"area\",\"@rights\",\"@addArea\"],\"statements\":[[8,\"plutof-panel/item-list\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"header\"]],[],[[\"@title\"],[[30,[36,0],[\"Projects.samplingAreaOnMap\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"plutof-select/object\",[],[[\"@content\",\"@selection\",\"@optionLabelPath\",\"@optionValuePath\",\"@prompt\",\"@selectClass\"],[[32,0,[\"modes\"]],[32,0,[\"currentMode\"]],\"name\",\"value\",null,\"panel-header-input form-control form-control-xs\"]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,1,[\"content\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[32,0,[\"mapComponent\"]]],[[\"geodata\",\"selected\",\"popupEnabled\",\"externalLoading\"],[[32,0,[\"data\"]],[32,0,[\"selection\"]],[35,2,[\"popup\"]],[35,1]]],[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"map/multi-popup\",[],[[\"@items\"],[[32,0,[\"selection\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[8,\"study/area-popup\",[],[[\"@area\",\"@viewRouteArgument\"],[[32,2,[\"source\"]],[32,2,[\"areaID\"]]]],null],[2,\"\\n            \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"],[8,\"related-objects/view\",[[24,\"data-test\",\"Project::AreaMap::List\"],[24,0,\"project-area-map__areas\"]],[[\"@pagination\",\"@canModify\",\"@add\",\"@title\"],[[32,0,[\"areaPagination\"]],[32,3,[\"canModify\"]],[32,4],[30,[36,0],[\"Projects.samplingArea\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"related-objects/content/areas\",[],[[\"@pagination\"],[[32,0,[\"areaPagination\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"dataIsLoading\",\"currentMode\",\"component\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/study/area-map.hbs"
    }
  });
});