define("plutof/initializers/inject-store-into-components", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'inject-store-into-components',
    after: 'ember-data',
    initialize: function (application) {
      application.inject('component', 'store', 'service:store');
    }
  };
});