define("plutof/templates/conservation-lab/red-list/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "/x4nGXET",
    "block": "{\"symbols\":[\"translations\",\"Field\",\"redlist\"],\"statements\":[[8,\"navbar/list\",[],[[\"@icon\",\"@title\",\"@addRoute\",\"@goBack\",\"@pagination\"],[[30,[36,0],[\"conservation\"],null],[30,[36,1],[\"conservation.redList.index.title\"],null],\"conservation-lab.red-list.add\",[30,[36,2],[\"goBack\"],null],[32,0,[\"model\",\"pagination\"]]]],null],[2,\"\\n\\n\"],[8,\"i18n/model-strings\",[],[[\"@model\"],[\"redbook.red-list\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"tables/list-route\",[],[[\"@records\"],[[32,0,[\"model\",\"pagination\",\"objects\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,2],[],[[\"@label\"],[[32,1,[\"name\",\"label\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],null,[[\"route\",\"model\"],[\"conservation-lab.red-list.view\",[32,3,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"                \"],[1,[32,3,[\"representation\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,2],[],[[\"@label\"],[[32,1,[\"created_by\",\"label\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[32,3,[\"created_by\",\"representation\"]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[2,3]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\",\"route-action\",\"link-to\"]}",
    "meta": {
      "moduleName": "plutof/templates/conservation-lab/red-list/index.hbs"
    }
  });
});