define("plutof/templates/components/compound-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "MIbML0qn",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],[[32,0,[\"alignmentSequences\",\"length\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"plutof-panel/headerless\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[11,\"div\"],[24,0,\"unite-table__alignment-container\"],[4,[38,5],[[32,0,[\"alignmentSequences\"]],[32,0,[\"buildAlignmentConfig\"]]],null],[12],[2,\"\\n        \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"inline-flex\"],[12],[2,\"\\n\"],[6,[37,4],[[35,6]],null,[[\"default\"],[{\"statements\":[[6,[37,4],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[10,\"span\"],[14,0,\"spinicon fas fa-sync-alt\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"            \"],[11,\"p\"],[24,0,\"btn-link clickable\"],[4,[38,0],[[32,0],\"loadMore\"],null],[12],[2,\"\\n                \"],[1,[30,[36,1],[\"General.loadMore\"],null]],[2,\"\\n            \"],[13],[2,\"\\n              \\n            \"],[11,\"p\"],[24,0,\"btn-link clickable\"],[4,[38,0],[[32,0],\"loadAll\"],null],[12],[2,\"\\n                \"],[1,[30,[36,1],[\"General.loadAll\"],null]],[2,\"  (\"],[1,[35,2,[\"objects_count\"]]],[2,\")\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"i18n-t\",\"totalCount\",\"loading\",\"if\",\"unite/alignment-renderer\",\"haveLoadMore\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/compound-table.hbs"
    }
  });
});