define("plutof/components/collection/repositories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::ItemList
      @collapse={{not @repositories.length}}
      as |panel|
  >
      <panel.header
          @title={{i18n-t "collection.repository.repositories"}}
          @count={{@repositories.length}}
      >
  
          <panel.button @clicked={{this.add}}>
              {{i18n-t "General.Add"}}
          </panel.button>
      </panel.header>
  
      <panel.content>
          <div class="table-responsive">
              <table class="data-table table-hover">
                  <thead>
                      <th colspan="2">
                          {{i18n-t "collection.repository.name"}}
                      </th>
                  </thead>
  
                  <tbody>
                      <Validation::SplitContext
                          @context={{@validationContext}}
                          @items={{@repositories}}
                          as |repository context|
                      >
                          <Collection::Repository
                              @repository={{repository}}
                              @validationContext={{context}}
                              @remove={{fn this.remove repository}} />
                      </Validation::SplitContext>
                  </tbody>
              </table>
          </div>
      </panel.content>
  </PlutofPanel::ItemList>
  
  */
  {
    "id": "tcoqVX+o",
    "block": "{\"symbols\":[\"panel\",\"repository\",\"context\",\"@repositories\",\"@validationContext\"],\"statements\":[[8,\"plutof-panel/item-list\",[],[[\"@collapse\"],[[30,[36,0],[[32,4,[\"length\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"header\"]],[],[[\"@title\",\"@count\"],[[30,[36,1],[\"collection.repository.repositories\"],null],[32,4,[\"length\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n        \"],[8,[32,1,[\"button\"]],[],[[\"@clicked\"],[[32,0,[\"add\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,1],[\"General.Add\"],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,1,[\"content\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"div\"],[14,0,\"table-responsive\"],[12],[2,\"\\n            \"],[10,\"table\"],[14,0,\"data-table table-hover\"],[12],[2,\"\\n                \"],[10,\"thead\"],[12],[2,\"\\n                    \"],[10,\"th\"],[14,\"colspan\",\"2\"],[12],[2,\"\\n                        \"],[1,[30,[36,1],[\"collection.repository.name\"],null]],[2,\"\\n                    \"],[13],[2,\"\\n                \"],[13],[2,\"\\n\\n                \"],[10,\"tbody\"],[12],[2,\"\\n                    \"],[8,\"validation/split-context\",[],[[\"@context\",\"@items\"],[[32,5],[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n                        \"],[8,\"collection/repository\",[],[[\"@repository\",\"@validationContext\",\"@remove\"],[[32,2],[32,3],[30,[36,2],[[32,0,[\"remove\"]],[32,2]],null]]],null],[2,\"\\n                    \"]],\"parameters\":[2,3]}]]],[2,\"\\n                \"],[13],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"not\",\"i18n-t\",\"fn\"]}",
    "meta": {
      "moduleName": "plutof/components/collection/repositories.hbs"
    }
  });
  let CollectionRepositories = _exports.default = (_dec = Ember._action, _dec2 = Ember._action, (_class = class CollectionRepositories extends Ember.Component {
    add() {
      const newRepository = this.store.createRecord('agent/repository');
      this.repositories.pushObject(newRepository);
    }
    remove(repository) {
      this.objectsToDelete.pushObject(repository);
      this.repositories.removeObject(repository);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "add", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "add"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "remove", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "remove"), _class.prototype)), _class));
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CollectionRepositories);
});