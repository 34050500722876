define("plutof/transforms/djangodate", ["exports", "@ember-data/serializer/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function numToString(num) {
    var s = num.toString();
    if (num < 10) {
      s = '0' + s;
    }
    return s;
  }
  class DateTransform extends _transform.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "regexp", /^((\d{4})-(\d\d)-(\d\d))/);
    }
    deserialize(serialized) {
      if (serialized === null) {
        return null;
      }
      var matches = serialized.match(this.get('regexp'));
      if (matches === null) {
        return null;
      }

      // TODO: Date would be preferred, but if fails when used with datepicker
      // var d = new Date(Date.UTC(matches[2], parseInt(matches[3], 10)-1, matches[4]));
      // return d;

      return matches[1];
    }
    serialize(deserialized) {
      if (Ember.isEmpty(deserialized)) {
        return null;
      }
      var date = deserialized;
      if (typeof date === 'string') {
        // TODO: Check errors
        date = new Date(Date.parse(date));
      }
      var year = date.getUTCFullYear(),
        month = date.getUTCMonth() + 1,
        day = date.getUTCDate();
      return year + '-' + numToString(month) + '-' + numToString(day) + 'T00:00Z';
    }
  }
  var _default = _exports.default = DateTransform;
});