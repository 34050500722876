define("plutof/templates/sequence/parse-file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "RcyAWmpR",
    "block": "{\"symbols\":[],\"statements\":[[8,\"navbar/view\",[],[[\"@icon\",\"@title\"],[[30,[36,0],[\"sequence\"],null],[32,0,[\"model\",\"file\",\"representation\"]]]],null],[2,\"\\n\\n\"],[8,\"plutof-panel/headerless\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[11,\"div\"],[24,0,\"unite-table__alignment-container\"],[4,[38,1],[[32,0,[\"sequences\"]],[32,0,[\"buildAlignmentConfig\"]]],null],[12],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"unite/alignment-renderer\"]}",
    "meta": {
      "moduleName": "plutof/templates/sequence/parse-file.hbs"
    }
  });
});