define("plutof/components/publishing/gbif/auto-complete", ["exports", "@glimmer/component", "plutof/components/auto-complete/backends", "plutof/config/environment"], function (_exports, _component, _backends, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <AutoComplete::Base
      @backend={{this.backend}}
      @value={{@value}}
      @update={{@update}}
      @params={{@params}}
      ...attributes />
  
  */
  {
    "id": "h9W8KzkV",
    "block": "{\"symbols\":[\"@value\",\"@update\",\"@params\",\"&attrs\"],\"statements\":[[8,\"auto-complete/base\",[[17,4]],[[\"@backend\",\"@value\",\"@update\",\"@params\"],[[32,0,[\"backend\"]],[32,1],[32,2],[32,3]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "plutof/components/publishing/gbif/auto-complete.hbs"
    }
  });
  // XXX: Couldn't test if it works
  class GBIFDatasetAutoCompleteBackend extends _backends.ModelAutocomplete {
    constructor({
      ajax,
      store,
      datasetType,
      onlyEditable
    } = {}) {
      const filters = {};
      if (datasetType) {
        filters.dataset_type = datasetType.id;
      }
      if (onlyEditable) {
        filters.access = 'edit';
      }
      super({
        ajax,
        store,
        modelName: 'publishing/gbif/dataset',
        filters
      });
    }
    async autocomplete(query) {
      const response = await this.ajax.request(`${_environment.default.PUBLISHING_API_HOST}/datasets/`, {
        data: Object.assign({
          search: query.trim()
        }, this.filters)
      });
      return response.data.map(result => ({
        id: result.id,
        value: result.attributes.title,
        full: result
      }));
    }
  }
  let GBIFDatasetAutoComplete = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed('args.datasetType', 'args.onlyEditable'), (_class = class GBIFDatasetAutoComplete extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
    }
    get backend() {
      return new GBIFDatasetAutoCompleteBackend({
        ajax: this.ajax,
        store: this.store,
        datasetType: this.args.datasetType,
        onlyEditable: this.args.onlyEditable
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "backend", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "backend"), _class.prototype)), _class));
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, GBIFDatasetAutoComplete);
});