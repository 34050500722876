define("plutof/components/sample/gathering-agents", ["exports", "@ember-decorators/component", "@ember-decorators/object", "ember-cp-validations", "plutof/misc/profile_settings", "plutof/mixins/component-validations"], function (_exports, _component, _object, _emberCpValidations, _profile_settings, _componentValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _class2;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const Validations = (0, _emberCpValidations.buildValidations)({
    'haveIfRequired': (0, _emberCpValidations.validator)('is-truthy')
  });

  // TODO: Something weird going in in here. What is addCurrentUser?
  let GatheringAgents = (_dec = (0, _component.classNames)('gathering-agents'), _dec2 = (0, _object.observes)('agents', 'addCurrentUser'), _dec3 = Ember.computed('require', 'agents.[]'), _dec4 = Ember._action, _dec(_class = (_class2 = class GatheringAgents extends Ember.Component.extend(Validations, _componentValidations.default) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "agents", []);
      _defineProperty(this, "require", false);
      _defineProperty(this, "vertical", true);
    }
    // XXX ...

    init() {
      super.init(...arguments);
      this.addDefaultAgent();
    }
    addCurrentUserToAgents() {
      if (!this.addCurrentUser) {
        return;
      }
      let agents = this.agents;
      if (Ember.isNone(agents) || !Ember.isEmpty(agents)) {
        return;
      }
      (0, _profile_settings.get_rights_owner_person)(this.store).then(person => {
        if (!Ember.isNone(person)) {
          agents.pushObject(person);
        }
      });
    }
    addDefaultAgent() {
      Ember.run.once(this, 'addCurrentUserToAgents');
    }
    get haveIfRequired() {
      return !this.require || !Ember.isEmpty(this.agents);
    }
    remove(agent) {
      this.agents.removeObject(agent);
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "addDefaultAgent", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "addDefaultAgent"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "haveIfRequired", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "haveIfRequired"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "remove", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "remove"), _class2.prototype)), _class2)) || _class);
  var _default = _exports.default = GatheringAgents;
});