define("plutof/components/common/delete-options", ["exports", "@glimmer/component", "plutof/utils/i18n"], function (_exports, _component, _i18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <p>
      <span class="{{icon "info"}}"></span>
      {{i18n-t "navbar.delete.info"}}
  </p>
  
  <p>
      <Common::RadioGroup
          @active={{this.selectedDeleteMode}}
          @activate={{fn (mut this.selectedDeleteMode)}}
          @invalid={{@invalid}}
          as |radioGroup|
      >
          {{#each-in this.deleteChoices as |key choice|}}
              <radioGroup.choice
                  @name={{key}}
                  @clicked={{fn this.updateDeleteChoice choice}}
              >
                  {{choice.label}}
              </radioGroup.choice>
          {{/each-in}}
      </Common::RadioGroup>
  </p>
  
  */
  {
    "id": "OzdxMorJ",
    "block": "{\"symbols\":[\"radioGroup\",\"choice\",\"key\",\"@invalid\"],\"statements\":[[10,\"p\"],[12],[2,\"\\n    \"],[10,\"span\"],[15,0,[31,[[30,[36,1],[\"info\"],null]]]],[12],[13],[2,\"\\n    \"],[1,[30,[36,2],[\"navbar.delete.info\"],null]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"p\"],[12],[2,\"\\n    \"],[8,\"common/radio-group\",[],[[\"@active\",\"@activate\",\"@invalid\"],[[32,0,[\"selectedDeleteMode\"]],[30,[36,0],[[30,[36,3],[[32,0,[\"selectedDeleteMode\"]]],null]],null],[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[32,0,[\"deleteChoices\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,[32,1,[\"choice\"]],[],[[\"@name\",\"@clicked\"],[[32,3],[30,[36,0],[[32,0,[\"updateDeleteChoice\"]],[32,2]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[1,[32,2,[\"label\"]]],[2,\"\\n            \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[2,3]}]]],[2,\"    \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"icon\",\"i18n-t\",\"mut\",\"-each-in\",\"each\"]}",
    "meta": {
      "moduleName": "plutof/components/common/delete-options.hbs"
    }
  });
  const i18n = (0, _i18n.getI18n)();
  const DELETE_CHOICES = {
    area: {
      label: i18n.t('navbar.delete.choices.area'),
      remove: {
        remove_areas: true,
        remove_events: true
      }
    },
    event: {
      label: i18n.t('navbar.delete.choices.event'),
      remove: {
        remove_events: true
      }
    },
    record: {
      label: i18n.t('navbar.delete.choices.record'),
      remove: {}
    }
  };
  let DeleteOptions = (_dec = Ember._action, (_class = class DeleteOptions extends _component.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "deleteChoices", DELETE_CHOICES);
      _defineProperty(this, "selectedDeleteMode", null);
    }
    updateDeleteChoice(choice) {
      this.args.changed(choice.remove);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "updateDeleteChoice", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "updateDeleteChoice"), _class.prototype)), _class));
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DeleteOptions);
});