define("plutof/translations/eng/import", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    import: 'Import',
    generateTemplate: 'Generate template',
    formats: 'Formatting',
    selectFile: 'Select file',
    datetimeFormat: 'Datetime format',
    ambiguousDatetime: 'Ambiguous datetime [YYYY-MM-DD hh:mm, YYYY-MM-DD, YYYY-MM or YYYY]',
    coordinateFormat: 'Coordinate format',
    source: 'File',
    createdCount: 'Created',
    totalCount: 'Importing',
    fixedCount: 'Rows fixed',
    ignoredCount: 'Rows ignored',
    finished: 'Finished',
    resolutions: 'Resolutions',
    saveSession: 'Download unprocessed/ignored rows',
    sendFixedRows: 'Save and continue',
    sendToClipboardBtn: 'Add imported records to clipboard',
    statusHeading: 'Status',
    legendHeading: 'Legend',
    indexTemplatePage: 'Generate template',
    indexImportPage: 'Import',
    downloadTemplate: 'Download file',
    parameters: 'Parameters',
    async: 'Asynchronous',
    asyncInfo: 'For big (>500 rows) files; recommended if data is already familiar to PlutoF',
    matchPredefinedAreas: 'Match predefined areas',
    useSourceEventIfMissing: 'Use source record\'s area and event if event columns are empty',
    copyToClipboard: 'Copy to clipboard',
    coordinatesFormat1: 'Decimal [58.29, -26.42]',
    coordinatesFormat2: 'Separate parts [50:40:30:N, 20:30:00:W]',
    dateTimeFormat1: 'YYYY-MM-DD hh:mm',
    dateTimeFormat2: 'DD/MM/YYYY hh:mm',
    timezone: 'Timezone',
    timezoneUTC: 'UTC',
    timezoneLocal: 'Local ({{offset}})',
    selectedFields: 'Selected fields',
    includeEhak: 'Automatically fill out district/commune information (Estonia only)',
    options: {
      matchSequencesByURL: {
        label: 'Match SH sequences by URL',
        tooltip: 'Instead of linking to sequences by accession numbers, use precise IDs in the form of PlutoF URLs, e.g https://app.plutof.ut.ee/sequence/view/1'
      }
    },
    areaMatching: {
      label: 'Area matching',
      none: 'Disabled',
      predefined: 'Match predefined/shared areas',
      project: 'Match project sampling areas'
    },
    progress: {
      progress: 'Progress',
      created: 'created',
      ignored: 'ignored',
      unprocessed: 'unprocessed'
    },
    actions: {
      fixTooltip: 'Fix errors',
      downloadTooltip: 'Download unprocessed/ignored rows',
      stopTooltip: 'Stop processing file',
      clearTooltip: 'Cancel',
      deleteTooltip: 'Cancel and delete file',
      clipboardTooltip: 'Add imported records to Clipboard',
      selectTemplateFile: {
        label: 'Template file',
        tooltip: 'Template file (CSV) containing import data'
      },
      save: {
        label: 'Save',
        tooltip: 'Save import process'
      },
      saveAndStart: {
        label: 'Save and start',
        tooltip: 'Save and start import process'
      }
    },
    status: {
      waiting: {
        sync: 'Action required',
        async: 'In queue'
      },
      processing: 'Processing',
      errors: 'Has errors',
      finished: 'Finished',
      stopped: 'Stopped',
      stopping: 'Stopping'
    },
    legend: {
      addRecord: 'Add new record (if not found in PlutoF)',
      needsFix: 'Fixing recommended (click to copy value)',
      ignore: 'Ignore full row or individual field (Ignored info will stay in file for later download)',
      required: 'Fix required',
      sendFixes: 'Saves fixed data and continues with the import',
      back: 'Back to imported files',
      stop: 'Downloads .CSV file with only unprocessed/ignored rows (current import will be stopped)'
    },
    info: {
      generateTemplate: 'Template file preparation',
      setupImport: 'Set up an import process with data file'
    },
    template: {
      column: 'CSV column name',
      unit: 'Unit',
      type: 'Type'
    }
  };
});