define("plutof/components/information/article/-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @ordered}}
      <ol>
          {{yield (hash item=(component "information/article/-list-item"))}}
      </ol>
  {{else}}
      <ul>
          {{yield (hash item=(component "information/article/-list-item"))}}
      </ul>
  {{/if}}
  
  */
  {
    "id": "8W8xmmPn",
    "block": "{\"symbols\":[\"&default\",\"@ordered\"],\"statements\":[[6,[37,2],[[32,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"ol\"],[12],[2,\"\\n        \"],[18,1,[[30,[36,1],null,[[\"item\"],[[30,[36,0],[\"information/article/-list-item\"],null]]]]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"ul\"],[12],[2,\"\\n        \"],[18,1,[[30,[36,1],null,[[\"item\"],[[30,[36,0],[\"information/article/-list-item\"],null]]]]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\",\"if\"]}",
    "meta": {
      "moduleName": "plutof/components/information/article/-list.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});