define("plutof/components/filerepository/upload-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::Headerless>
      <Tables::List
          @records={{@uploader.uploads}}
          as |Field upload|
      >
          <Field @label={{i18n-t "FileRepository.colFileName"}}>
              {{#if upload.finished}}
                  {{#link-to "filerepository.view" upload.record.id}}
                      {{upload.file.name}}
                  {{/link-to}}
              {{else}}
                  {{upload.file.name}}
              {{/if}}
  
              {{#if upload.working}}
                  <span class={{icon "loading"}}></span>
              {{/if}}
          </Field>
  
          <Field @label={{i18n-t "FileRepository.colProgress"}}>
              <ProgressBar @progress={{upload.progress}} />
          </Field>
      </Tables::List>
  
      {{#unless (is-equal @uploader.uploads.length @localFilesCount)}}
          <span class={{icon "loading"}}></span>
      {{/unless}}
  </PlutofPanel::Headerless>
  
  */
  {
    "id": "/DBqqr9h",
    "block": "{\"symbols\":[\"Field\",\"upload\",\"@uploader\",\"@localFilesCount\"],\"statements\":[[8,\"plutof-panel/headerless\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"tables/list\",[],[[\"@records\"],[[32,3,[\"uploads\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,1],[],[[\"@label\"],[[30,[36,2],[\"FileRepository.colFileName\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[32,2,[\"finished\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,1],null,[[\"route\",\"model\"],[\"filerepository.view\",[32,2,[\"record\",\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"                    \"],[1,[32,2,[\"file\",\"name\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"                \"],[1,[32,2,[\"file\",\"name\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,3],[[32,2,[\"working\"]]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[10,\"span\"],[15,0,[30,[36,0],[\"loading\"],null]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,1],[],[[\"@label\"],[[30,[36,2],[\"FileRepository.colProgress\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,\"progress-bar\",[],[[\"@progress\"],[[32,2,[\"progress\"]]]],null],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[1,2]}]]],[2,\"\\n\\n\"],[6,[37,5],[[30,[36,4],[[32,3,[\"uploads\",\"length\"]],[32,4]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[15,0,[30,[36,0],[\"loading\"],null]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"link-to\",\"i18n-t\",\"if\",\"is-equal\",\"unless\"]}",
    "meta": {
      "moduleName": "plutof/components/filerepository/upload-list.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});