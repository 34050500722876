define("plutof/misc/resetmixin", ["exports", "plutof/misc/abstract", "plutof/utils/objects"], function (_exports, _abstract, _objects) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // TODO: Move to app/mixins
  var _default = _exports.default = Ember.Mixin.create({
    paramsClearedOnReset: [],
    extractAllParams: function (transition) {
      const routeParams = transition.to.params;
      const queryParams = transition.to.queryParams;
      return (0, _abstract.mergeObjects)(routeParams, queryParams);
    },
    beforeModel: function (transition) {
      this._super(...arguments);
      let controller = this.controller;

      // Note: for this to work with save/cancel, they should set routeHasBeenLoaded to true
      if (!Ember.isNone(controller)) {
        const currentParams = this.extractAllParams(transition);
        const savedParams = this.savedParams;
        if (Ember.isNone(savedParams) || Object.keys(currentParams).length !== 0 && !(0, _objects.objects_are_equal)(currentParams, savedParams)) {
          controller.set('routeHasBeenLoaded', false);
        }
      }
    },
    afterModel: function (model, transition) {
      this._super(model, transition);
      if (!Ember.isNone(model)) {
        this.set('savedRouteParams', transition.to.params);
        this.set('savedParams', this.extractAllParams(transition));
      }
    },
    setupController: function (controller, model) {
      this._super(...arguments);
      if (Ember.isNone(model)) {
        controller.setProperties(this.savedParams);
      }
      controller.set('routeHasBeenLoaded', true);
    },
    clearQueryParams: function () {
      let controller = this.controller;
      if (Ember.isNone(controller) || controller.get('keepQueryParams')) {
        return;
      }
      const queryParams = controller.get('queryParams') || [];
      queryParams.forEach(qparam => {
        controller.set(qparam, null);
      });
    },
    actions: {
      triggerReset: function () {
        this.clearQueryParams();
        this.controller.set('routeHasBeenLoaded', false);
        this.model(this.savedRouteParams).then(resolvedModel => {
          this.set('savedParams', this.savedRouteParams);
          this.set('controller.model', resolvedModel);
          this.set('controller.routeHasBeenLoaded', true);
        });
      },
      willTransition: function (transition) {
        this._super(...arguments);
        const controller = this.controller;
        const reloadModel = !Ember.isNone(controller) && !controller.get('routeHasBeenLoaded');
        // Don't clear qp-s if we are transitioning to the same route and the model will be reloaded
        if (!reloadModel || transition.targetName !== this.routeName) {
          this.clearQueryParams();
        }
      }
    }
  });
});