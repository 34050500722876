define("plutof/translations/eng/models", ["exports", "plutof/translations/helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    'newSamplingarea': 'New Sampling Area',
    // Non-model option.
    'noSource': 'No Source',
    // Non-model option.
    'study': 'Project',
    'sample': 'Sample',
    'specimen': 'Specimen',
    'sequence': 'Sequence',
    'observation': 'Observation',
    'file': 'File',
    'strain': 'Living Specimen',
    'samplingevent': 'Sampling Event',
    'samplingarea': 'Sampling Area',
    'materialsample': 'Material Sample',
    'organization': 'Organization',
    'person': 'Person',
    'transaction': 'Transaction',
    'conditionassessment': (0, _helpers.alias)('CollectionManagement.conditionAssessmentsLabel'),
    'treatment': (0, _helpers.alias)('General.treatments'),
    'occurrence': (0, _helpers.alias)('General.RefBased'),
    'collectionobject': (0, _helpers.alias)('Photobank.viewObject'),
    'taxondescription': (0, _helpers.alias)('Taxonomy.descriptionsLabel'),
    'reference': (0, _helpers.alias)('References.reference'),
    'livingculture': 'Living Specimen',
    'livingspecimen': 'Living Specimen'
  };
});