define("plutof/initializers/setup-model-aliases", ["exports", "plutof/misc/options-getter"], function (_exports, _optionsGetter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'setup-model-aliases',
    after: 'ember-data',
    initialize: function (application) {
      var prefix = 'plutof/models/';
      var model_regex = new RegExp('^' + prefix);
      var models = Object.keys(require._eak_seen).filter(model_regex.test.bind(model_regex));
      (0, _optionsGetter.setupAliases)(models.map(function (model) {
        return model.substr(prefix.length).replace(/\//g, '.');
      }));
    }
  };
});