define("plutof/translations/est/profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    infoTitle: 'Profiili seaded',
    defaultLicence: 'Vaikimisi õigused',
    defaultRightsTooltip: 'Kasutada, kui sisestate andmeid kellegi teise nimel. <br>' + 'Valitud nimest saab andmete "Õiguste hoidja". Iseennast ei pea lisama',
    CreativeCommons: 'Creative Commons litsentsid',
    personalMenu: {
      measurements: {
        selectGuide: 'Vali tunnused vormisse lisamiseks',
        selection: 'Valitud:',
        noResults: 'Sobivad tunnused puuduvad'
      }
    }
  };
});