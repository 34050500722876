define("plutof/components/transaction/view/objects/row/edit-controls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <button
      {{on "click" @cancel}}
      class="btn btn-link observation-table__control"
  >
      <ToolTip @tooltip={{i18n-t "transactions.objects.actions.cancel.tooltip"}}>
          {{i18n-t "transactions.objects.actions.cancel.label"}}
      </ToolTip>
  </button>
  
  <button
      {{on "click" @save}}
      class="btn btn-link observation-table__control"
      disabled={{@disableSave}}
  >
      <ToolTip @tooltip={{i18n-t "transactions.objects.actions.save.tooltip"}}>
          {{i18n-t "transactions.objects.actions.save.label"}}
      </ToolTip>
  </button>
  
  */
  {
    "id": "GMwnzytk",
    "block": "{\"symbols\":[\"@cancel\",\"@disableSave\",\"@save\"],\"statements\":[[11,\"button\"],[24,0,\"btn btn-link observation-table__control\"],[4,[38,0],[\"click\",[32,1]],null],[12],[2,\"\\n    \"],[8,\"tool-tip\",[],[[\"@tooltip\"],[[30,[36,1],[\"transactions.objects.actions.cancel.tooltip\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,1],[\"transactions.objects.actions.cancel.label\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[11,\"button\"],[24,0,\"btn btn-link observation-table__control\"],[16,\"disabled\",[32,2]],[4,[38,0],[\"click\",[32,3]],null],[12],[2,\"\\n    \"],[8,\"tool-tip\",[],[[\"@tooltip\"],[[30,[36,1],[\"transactions.objects.actions.save.tooltip\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,1],[\"transactions.objects.actions.save.label\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\",\"i18n-t\"]}",
    "meta": {
      "moduleName": "plutof/components/transaction/view/objects/row/edit-controls.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});