define("plutof/components/forms/view/taxon-sheet", ["exports", "@ember-decorators/component", "plutof/utils/has-many"], function (_exports, _component, _hasMany) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "Forms.taxonSheet.title"}}
      @collapse={{not this.pagination.objectCount}}
  >
      <Pagination::PageControls @pagination={{this.pagination}} />
  
      <ol class="taxon-sheet-view__taxa">
          {{#each this.pagination.objects as |taxon|}}
              <li>
                  {{#link-to "taxonomy.view" taxon.taxon_id}}
                      {{taxon.taxon_name}}
                  {{/link-to}}
              </li>
          {{/each}}
      </ol>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "AnlXIzdA",
    "block": "{\"symbols\":[\"taxon\"],\"statements\":[[8,\"plutof-panel/simple\",[],[[\"@title\",\"@collapse\"],[[30,[36,1],[\"Forms.taxonSheet.title\"],null],[30,[36,2],[[32,0,[\"pagination\",\"objectCount\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"pagination/page-controls\",[],[[\"@pagination\"],[[32,0,[\"pagination\"]]]],null],[2,\"\\n\\n    \"],[10,\"ol\"],[14,0,\"taxon-sheet-view__taxa\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,0,[\"pagination\",\"objects\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"li\"],[12],[2,\"\\n\"],[6,[37,0],null,[[\"route\",\"model\"],[\"taxonomy.view\",[32,1,[\"taxon_id\"]]]],[[\"default\"],[{\"statements\":[[2,\"                    \"],[1,[32,1,[\"taxon_name\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"            \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"link-to\",\"i18n-t\",\"not\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "plutof/components/forms/view/taxon-sheet.hbs"
    }
  });
  const HasMany = (0, _hasMany.HasManyViewMixin)('measurement/sheet-taxon', {
    parentField: 'mainform'
  });
  let TaxonSheetView = (_dec = (0, _component.classNames)('taxon-sheet-view'), _dec(_class = class TaxonSheetView extends Ember.Component.extend(HasMany) {}) || _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TaxonSheetView);
});