define("plutof/components/related-objects/view", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::ItemList
      @collapse={{@pagination.isEmpty}}
      data-test="RelatedObjects::View"
      data-test-loaded={{not @pagination.isLoading}}
      ...attributes
      as |panel|
  >
      <panel.header
          @title={{@title}}
          @count={{@pagination.objectCount}}
      >
          <RelatedObjects::SearchButton
              @searchQuery={{@searchQuery}}
              @searchModule={{@searchModule}} />
  
          {{! TODO: Those should be panel.button }}
          {{#if @clipboard}}
              <RelatedObjects::ClipboardButtons
                  @pagination={{@pagination}}
                  @clipboard={{@clipboard}}
                  @clipboardQuery={{@clipboardQuery}}
                  @disabled={{not @pagination.objects}} />
          {{/if}}
  
          {{#if this.canAdd}}
              <panel.button
                  @clicked={{@add}}
                  data-test="add-button"
              >
                  {{i18n-t "General.New"}}
              </panel.button>
          {{/if}}
      </panel.header>
  
      <panel.content>
          {{yield}}
      </panel.content>
  </PlutofPanel::ItemList>
  
  */
  {
    "id": "3cW+4TsG",
    "block": "{\"symbols\":[\"panel\",\"@add\",\"@pagination\",\"@clipboard\",\"@clipboardQuery\",\"&attrs\",\"@title\",\"@searchQuery\",\"@searchModule\",\"&default\"],\"statements\":[[8,\"plutof-panel/item-list\",[[24,\"data-test\",\"RelatedObjects::View\"],[16,\"data-test-loaded\",[30,[36,1],[[32,3,[\"isLoading\"]]],null]],[17,6]],[[\"@collapse\"],[[32,3,[\"isEmpty\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"header\"]],[],[[\"@title\",\"@count\"],[[32,7],[32,3,[\"objectCount\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"related-objects/search-button\",[],[[\"@searchQuery\",\"@searchModule\"],[[32,8],[32,9]]],null],[2,\"\\n\\n\"],[6,[37,2],[[32,4]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"related-objects/clipboard-buttons\",[],[[\"@pagination\",\"@clipboard\",\"@clipboardQuery\",\"@disabled\"],[[32,3],[32,4],[32,5],[30,[36,1],[[32,3,[\"objects\"]]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,2],[[32,0,[\"canAdd\"]]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,[32,1,[\"button\"]],[[24,\"data-test\",\"add-button\"]],[[\"@clicked\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[1,[30,[36,0],[\"General.New\"],null]],[2,\"\\n            \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,1,[\"content\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[18,10,null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"not\",\"if\"]}",
    "meta": {
      "moduleName": "plutof/components/related-objects/view.hbs"
    }
  });
  let RelatedObjectsView = (_dec = Ember.computed('canModify', 'add'), (_class = class RelatedObjectsView extends _component.default {
    get canAdd() {
      return this.args.canModify && Boolean(this.args.add);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "canAdd", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "canAdd"), _class.prototype)), _class));
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, RelatedObjectsView);
});