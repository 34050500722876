define("plutof/components/study/area-map", ["exports", "ember-data", "@ember-decorators/component", "ember-concurrency", "plutof/config/environment", "plutof/utils/pagination", "plutof/misc/abstract"], function (_exports, _emberData, _component, _emberConcurrency, _environment, _pagination, _abstract) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _class2, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let AreaMap = (_dec = (0, _component.classNames)('project-area-map'), _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = (0, _emberConcurrency.task)({
    restartable: true
  }), _dec5 = Ember.computed('currentMode.cluster'), _dec6 = Ember.computed('samplingAreas.[]', 'predefinedAreas.[]', 'currentMode'), _dec7 = Ember.computed('projectAreas.[]'), _dec8 = Ember.computed('project.id'), _dec9 = Ember.computed('currentMapMode', 'mapPredefinedAreas.isPending', 'mapSamplingAreas.isPending', 'mapPaginatedAreas.isPending'), _dec10 = Ember._action, _dec(_class = (_class2 = class AreaMap extends Ember.Component {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "selection", _descriptor2, this);
      _initializerDefineProperty(this, "setupAreaPagination", _descriptor3, this);
    }
    init() {
      super.init(...arguments);
      const samplingAreasMode = {
        name: this.i18n.t('Projects.samplingAreasOnMap'),
        value: 'samplingareas',
        predefined: false,
        sampling: true,
        cluster: true,
        popup: true
      };
      const modes = [{
        name: this.i18n.t('Projects.predefinedAreasOnMap'),
        value: 'projectareas',
        predefined: true,
        sampling: false,
        cluster: true,
        popup: true
      }, samplingAreasMode, {
        name: this.i18n.t('Projects.allPointsOnMap'),
        value: 'allpoints',
        predefined: true,
        sampling: true,
        cluster: false,
        popup: false
      }];
      this.set('currentMode', samplingAreasMode);
      this.set('modes', modes);
    }
    didReceiveAttrs() {
      this.setupAreaPagination.perform();
    }
    get mapComponent() {
      return this.currentMode.cluster ? 'map/cluster-map' : 'map/view-map';
    }
    get data() {
      let areas = [];
      if (this.currentMode.predefined) {
        areas = areas.concat(this.predefinedAreas.toArray());
      }
      if (this.currentMode.sampling) {
        areas = areas.concat(this.samplingAreas.toArray());
      }
      return areas.filter(area => area.geom);
    }
    loadAreas(areasPromise, areaIDField, styleClass) {
      return _emberData.default.PromiseArray.create({
        promise: areasPromise.then(areas => areas.map(area => ({
          id: Ember.get(area, 'id'),
          areaID: Ember.get(area, areaIDField),
          source: area,
          geom: Ember.get(area, 'geom'),
          styleClass: styleClass
        })))
      });
    }
    get predefinedAreas() {
      return this.loadAreas(Ember.RSVP.Promise.resolve(this.projectAreas), 'area_id', 'predefined');
    }

    // This used to query /sample/samplingareas/geoms/, but now users want
    // to select areas on the map
    get samplingAreas() {
      return this.loadAreas(this._fetchAreas(this.project.id), 'id', 'base');
    }
    async _fetchAreas(projectID) {
      const pageSize = 500;
      const loadPage = number => {
        return this.ajax.request(`${_environment.default.API_HOST}/sample/samplingareas/search/`, {
          data: {
            study: projectID,
            page: number,
            page_size: pageSize
          }
        });
      };

      // Have to fetch the first page to get the count
      const firstPage = await loadPage(1);
      const pageCount = Math.ceil(firstPage.count / pageSize);

      // The rest can be fetched in parallel
      const rest = await Ember.RSVP.Promise.all((0, _abstract.range)(2, pageCount + 1).map(number => loadPage(number)));
      const areas = [].concat(...[firstPage, ...rest].map(page => page.results));
      return areas.filter(area => !area.is_site);
    }
    get dataIsLoading() {
      return this.currentMode.predefined && this.get('mapPredefinedAreas.isPending') || this.currentMode.predefined && this.get('mapPaginatedAreas.isPending') || this.currentMode.sampling && this.get('mapSamplingAreas.isPending');
    }
    _addArea() {
      return this.addArea();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "selection", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "setupAreaPagination", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        let pagination = yield (0, _pagination.paginateAjaxEndpoint)(this.ajax, 'sample/samplingareas', {
          filters: {
            study: this.project.id,
            parent_samplingarea: null,
            site: 'False'
          }
        });
        this.set('areaPagination', pagination);
      };
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "mapComponent", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "mapComponent"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "data", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "data"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "predefinedAreas", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "predefinedAreas"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "samplingAreas", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "samplingAreas"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "dataIsLoading", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "dataIsLoading"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_addArea", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "_addArea"), _class2.prototype)), _class2)) || _class);
  var _default = _exports.default = AreaMap;
});