define("plutof/components/sample/event-fields-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <GenericViews::Record
      @record={{@event}}
      @testMarker="event-fields-view"
      class="event-fields-view"
      as |view|
  >
      {{view.field "event_id"}}
      {{view.field "timespan_begin"}}
      {{view.field "timespan_end"}}
  
      {{#view.attr "description"}}
          <LongText
              @text={{@event.description}}
              @text-height="height-2"
              @tagName="span" />
      {{/view.attr}}
  
      <Sample::MeasurementFields
          @object={{@event}}
          @mainform={{@externalMainform}} />
  </GenericViews::Record>
  
  */
  {
    "id": "gVWlXBgk",
    "block": "{\"symbols\":[\"view\",\"@event\",\"@externalMainform\"],\"statements\":[[8,\"generic-views/record\",[[24,0,\"event-fields-view\"]],[[\"@record\",\"@testMarker\"],[[32,2],\"event-fields-view\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[[32,1,[\"field\"]],\"event_id\"],null]],[2,\"\\n    \"],[1,[30,[36,0],[[32,1,[\"field\"]],\"timespan_begin\"],null]],[2,\"\\n    \"],[1,[30,[36,0],[[32,1,[\"field\"]],\"timespan_end\"],null]],[2,\"\\n\\n\"],[6,[37,0],[[32,1,[\"attr\"]],\"description\"],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"long-text\",[],[[\"@text\",\"@text-height\",\"@tagName\"],[[32,2,[\"description\"]],\"height-2\",\"span\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,\"sample/measurement-fields\",[],[[\"@object\",\"@mainform\"],[[32,2],[32,3]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\"]}",
    "meta": {
      "moduleName": "plutof/components/sample/event-fields-view.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});