define("plutof/controllers/publishing/gbif/edit", ["exports", "plutof/misc/fileupload", "plutof/mixins/edit-controller", "plutof/utils/access", "plutof/utils/structures", "plutof/utils/notifications"], function (_exports, _fileupload, _editController, _access, _structures, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function unpackAgent(agent) {
    return agent.types.map(type => {
      const newAgent = Ember.getProperties(agent, 'email', 'first_name', 'last_name', 'position', 'orcid_identifier');
      newAgent.agent_type = type;
      return newAgent;
    });
  }
  let PublishingGbifEditController = (_dec = Ember._action, _dec2 = Ember._action, (_class = class PublishingGbifEditController extends Ember.Controller.extend(_editController.default) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "logoPreview", null);
      _defineProperty(this, "recordsToUnload", [{
        record: 'dataset'
      }]);
    }
    async setNewLogo(logo) {
      (0, _notifications.displayNotification)('status', this.i18n.t('Forms.uploadingImage'));
      const uploader = await (0, _fileupload.get_uploader)();
      const file = await uploader.upload(logo).wait();

      // Make file public
      const access = await (0, _access.create_access_rights)(this.store, file);
      access.set('visible', 'PUBLIC');
      await access.save();
      const url = file.download_links.link;
      this.model.dataset.set('logo_url', url);
    }
    async save() {
      if (Ember.isPresent(this.newLogo)) {
        await this.setNewLogo(this.newLogo);
        this.set('newLogo', null);
      }

      // Unpack agents that were previously packed for UI purposes.
      const agents = (0, _structures.flatten)(this.model.agents.map(unpackAgent));
      this.model.dataset.set('people', agents);
      await this.model.dataset.save();
      await Ember.RSVP.all([this.model.rights.save(), this.model.sequences.save(), this.model.specimens.save(), this.model.observations.save(), this.model.referencebased.save()]);
    }
    saveDataset() {
      (0, _notifications.clearNotifications)();
      (0, _notifications.displayNotification)('status', this.i18n.t('General.saving'));
      return this.save().then(() => {
        (0, _notifications.displayNotification)('success', this.i18n.t('General.done'));
        this.model.sequences.destroy();
        this.model.specimens.destroy();
        this.model.observations.destroy();
        this.model.referencebased.destroy();
        this.model.rights.destroy();
        this.set('routeHasBeenLoaded', false);
        this.send('goBack');
      }).catch(_notifications.reportError);
    }
    setLogo(image) {
      this.set('newLogo', image);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "saveDataset", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "saveDataset"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setLogo", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "setLogo"), _class.prototype)), _class));
  var _default = _exports.default = PublishingGbifEditController;
});