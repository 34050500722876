define("plutof/components/access/edit-rights", ["exports", "@ember-decorators/component", "@ember-decorators/object", "ember-cp-validations", "ember-concurrency", "plutof/components/auto-complete/backends", "plutof/mixins/component-validations", "plutof/utils/access"], function (_exports, _component, _object, _emberCpValidations, _emberConcurrency, _backends, _componentValidations, _access) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const Validations = (0, _emberCpValidations.buildValidations)({
    'currentUserHasEditRights': (0, _emberCpValidations.validator)('is-truthy')
  });
  let EditRights = (_dec = (0, _component.classNames)('edit-rights'), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.computed('ownerLocked', 'rights.ownedByCurrentUser'), _dec7 = Ember.computed('enableOwnerEdit', 'rights.ownedByCurrentUser'), _dec8 = (0, _object.observes)('rights.visibleToOwner', 'rights.editableByOwner', 'rights.user_edit.[]', 'rights.group_edit.[]', 'rights.owner.id', 'rights.ownerModified'), _dec9 = (0, _emberConcurrency.task)({
    restartable: true
  }), _dec10 = Ember._action, _dec11 = Ember._action, _dec(_class = (_class2 = class EditRights extends Ember.Component.extend(Validations, _componentValidations.default) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "confirmationDialog", _descriptor2, this);
      _initializerDefineProperty(this, "settings", _descriptor3, this);
      _initializerDefineProperty(this, "store", _descriptor4, this);
      _defineProperty(this, "validationChildren", ['form']);
      _defineProperty(this, "ownerLocked", true);
      // Because specimen consist of multiple records, we can't just switch owner immediately - e.g. after saving
      // specimen under another owner, current user won't be able to add an interaction. To prevent that, we make
      // sure that current user has edit rights even after ownership is transfered
      _defineProperty(this, "currentUserHasEditRights", true);
      _initializerDefineProperty(this, "_checkCurrentUserEditRights", _descriptor5, this);
      _defineProperty(this, "ownerAutocomplete", new _backends.ModelAutocomplete({
        ajax: this.ajax,
        store: this.store,
        modelName: 'users/user'
      }));
    }
    didReceiveAttrs() {
      this.set('ownerLocked', true);
    }
    get cannotEditOwner() {
      return this.ownerLocked || !this.rights.ownedByCurrentUser;
    }
    get showOwner() {
      return this.enableOwnerEdit || !this.rights.ownedByCurrentUser;
    }
    checkCurrentUserEditRights() {
      this._checkCurrentUserEditRights.perform();
    }
    toggleOwnerLock() {
      this.toggleProperty('ownerLocked');
    }
    async changeOwner(owner) {
      // Don't allow clearing owner
      if (!owner) {
        return;
      }
      const disownFiles = this.enableLinkedFilesOwnerEdit && (await this.confirmationDialog.confirm({
        title: this.i18n.t('Access.ownerChange.fileDialog.title'),
        content: this.i18n.t('Access.ownerChange.fileDialog.content')
      }));
      let update = {
        owner,
        ownerModified: true,
        fileOwnerModified: disownFiles
      };

      // If access has just editable-to-owner, add current user to edit, which will need to happen anyway
      // to satisfy the ownership transfer check (another case is adding a group, but then user can just
      // remove themselves from users_edit)
      if (this.rights.editableByOwner || this.rights.user_edit.length === 0 && this.rights.group_edit.length === 0) {
        const currentUser = await this.settings.profile.user;
        if (currentUser !== owner) {
          update.editable = 'WORKGROUPS';
          update.user_edit = [currentUser];
        }
        if (this.rights.visibleToOwner) {
          update.visible = 'WORKGROUPS';
        }
      }
      this.rights.setProperties(update);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "confirmationDialog", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "settings", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "cannotEditOwner", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "cannotEditOwner"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "showOwner", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "showOwner"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "checkCurrentUserEditRights", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "checkCurrentUserEditRights"), _class2.prototype), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "_checkCurrentUserEditRights", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        if (!this.rights.ownerModified) {
          this.set('currentUserHasEditRights', true);
          return;
        }
        const hasRights = yield (0, _access.rightsPermitEdit)(this.ajax, this.rights, this.settings.profile);
        this.set('currentUserHasEditRights', hasRights);
      };
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "toggleOwnerLock", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleOwnerLock"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "changeOwner", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "changeOwner"), _class2.prototype)), _class2)) || _class);
  var _default = _exports.default = EditRights;
});