define("plutof/components/study/occurrence-statistics", ["exports", "ember-data", "@ember-decorators/component", "plutof/misc/abstract", "plutof/utils/chart", "plutof/utils/i18n", "plutof/utils/notifications"], function (_exports, _emberData, _component, _abstract, _chart, _i18n, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const i18n = (0, _i18n.getI18n)();
  let OccurrenceStatistics = (_dec = (0, _component.classNames)('occurrence-statistics'), _dec2 = Ember.inject.service, _dec3 = Ember.computed.notEmpty('histogramData.created_at.data'), _dec4 = Ember.computed.notEmpty('histogramData.timespan_begin.data'), _dec5 = Ember.computed.or('createdAtNotEmpty', 'timespanBeginNotEmpty'), _dec6 = Ember._tracked, _dec7 = Ember.computed.or('holdOpen', 'haveData'), _dec8 = Ember.computed(), _dec9 = Ember.computed('searchArgs.@each.value', 'study.id'), _dec10 = Ember.computed('module', 'query'), _dec11 = Ember.computed('histogramData'), _dec12 = Ember.computed('histogramData'), _dec13 = Ember._action, _dec(_class = (_class2 = class OccurrenceStatistics extends Ember.Component {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _defineProperty(this, "fields", ['timespan_begin', 'created_at']);
      _defineProperty(this, "module", 'taxonoccurrence');
      _defineProperty(this, "objectModule", 'study');
      _initializerDefineProperty(this, "createdAtNotEmpty", _descriptor2, this);
      _initializerDefineProperty(this, "timespanBeginNotEmpty", _descriptor3, this);
      _initializerDefineProperty(this, "haveData", _descriptor4, this);
      // If the panel is opened solely based on this.haveData:
      //
      // 1. User clicks a chart to dig into it
      // 2. This trigger data reload
      // 3. haveData goes to false
      // 4. Panel closes until the request finishes, which looks goofy
      //
      // This deals with that problem by not letting the panel close while
      // the data is being fetched
      _initializerDefineProperty(this, "holdOpen", _descriptor5, this);
      _initializerDefineProperty(this, "open", _descriptor6, this);
    }
    get panelParams() {
      return {
        extraButtons: [{
          position: 'right',
          type: 'button',
          title: 'General.Reset',
          icon: 'icon-reset',
          action: () => this.send('resetChart')
        }]
      };
    }
    didInsertElement() {
      super.didInsertElement(...arguments);
      this.set('searchArgs', [Ember.Object.create({
        filter: 'timespan_begin',
        value: null
      }), Ember.Object.create({
        filter: 'created_at',
        value: null
      }), Ember.Object.create({
        filter: 'study',
        value: this.get('study.id')
      }), Ember.Object.create({
        filter: 'sub_field',
        value: 'module'
      })]);
    }
    didReceiveAttrs() {
      super.didReceiveAttrs();
      this.holdOpen = false;
    }
    get query() {
      const args = this.searchArgs;
      const studyId = this.get('study.id');
      if (Ember.isNone(args) || Ember.isNone(studyId)) {
        return '';
      }

      // Override study_id,on parent/child study re-routing
      args.findBy('filter', 'study').set('value', studyId);
      return args.map(field => {
        const value = field.get('value');
        const filterValue = value ? '=' + value : '';
        return value ? field.get('filter') + filterValue : null;
      }).compact().join('&');
    }
    get histogramData() {
      const query = this.query;
      const module = this.module;
      const fields = this.fields;
      const endpoint = module + _chart.HISTOGRAM_ENDPOINT;
      const shouldNotQuery = Ember.isEmpty(fields) || Ember.isEmpty(query);
      const dataPromise = shouldNotQuery ? (0, _abstract.wrap_as_promise)(null) : (0, _chart.getChartData)(this.ajax, endpoint, fields, query);
      return _emberData.default.PromiseObject.create({
        promise: dataPromise
      });
    }
    get createdAtConfig() {
      return _emberData.default.PromiseObject.create({
        promise: this.histogramData.then(data => {
          return {
            context: this,
            data: data,
            field: {
              key: 'created_at',
              label: i18n.t('chart.createdAt')
            },
            type: 'clustered'
          };
        }).catch(_notifications.reportError)
      });
    }
    get timespanBeginConfig() {
      return _emberData.default.PromiseObject.create({
        promise: this.histogramData.then(data => {
          return {
            context: this,
            data: data,
            field: {
              key: 'timespan_begin',
              label: i18n.t('chart.timespanBegin')
            },
            type: 'clustered'
          };
        }).catch(_notifications.reportError)
      });
    }
    handleClick(event, items, interval, field) {
      const chartItem = Ember.get(items, 'firstObject');
      const value = Ember.isPresent(chartItem) ? Ember.get(chartItem, '_model.label') : null;
      if (!Ember.isNone(chartItem)) {
        this.searchArgs.findBy('filter', field).set('value', value);
        this.holdOpen = true;
      }
    }
    resetChart() {
      this.searchArgs.findBy('filter', 'timespan_begin').set('value', null);
      this.searchArgs.findBy('filter', 'created_at').set('value', null);
      this.holdOpen = true;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "createdAtNotEmpty", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "timespanBeginNotEmpty", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "haveData", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "holdOpen", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "open", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "panelParams", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "panelParams"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "query", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "query"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "histogramData", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "histogramData"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "createdAtConfig", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "createdAtConfig"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "timespanBeginConfig", [_dec12], Object.getOwnPropertyDescriptor(_class2.prototype, "timespanBeginConfig"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "resetChart", [_dec13], Object.getOwnPropertyDescriptor(_class2.prototype, "resetChart"), _class2.prototype)), _class2)) || _class);
  var _default = _exports.default = OccurrenceStatistics;
});