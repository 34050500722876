define("plutof/helpers/is-sub-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isSubRoute = isSubRoute;
  function isSubRoute(paths) {
    const routePath = Ember.get(paths, 'firstObject');
    return paths.without(routePath).any(path => routePath.startsWith(path));
  }
  var _default = _exports.default = Ember.Helper.helper(isSubRoute);
});