define("plutof/components/observation/taxon-sheet/form", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  let FormTaxonSheet = (_dec = (0, _component.classNames)('form-taxon-sheet'), _dec(_class = class FormTaxonSheet extends Ember.Component {}) || _class);
  var _default = _exports.default = FormTaxonSheet;
});