define("plutof/translations/eng/occurrence-converter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    title: 'Occurrence Converter',
    info: 'Convert specimen record to observtion record. Choose to keep or delete the specimen record.',
    feedback: {
      newRecord: 'New observation',
      created: 'created.',
      originalDeleted: 'Specimen was deleted.',
      originalNotDeleted: 'Specimen was not deleted.',
      toList: 'To specimens\' list.'
    },
    actions: {
      openConverter: 'Convert',
      deleteOriginal: 'Delete specimen',
      keepOriginal: 'Keep specimen',
      convertToObservation: 'Convert to observation'
    }
  };
});