define("plutof/templates/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "8+KylCRv",
    "block": "{\"symbols\":[],\"statements\":[[2,\"LOADING...\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "plutof/templates/loading.hbs"
    }
  });
});