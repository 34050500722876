define("plutof/templates/components/filter-selection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "cLpcOEmH",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[1,[30,[36,10],null,[[\"value\",\"placeholder\",\"autocomplete\",\"classNames\"],[[35,3],[35,9],\"off\",[35,8]]]]],[2,\"\\n\\n\"],[6,[37,0],[[35,11]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"plutof-ac-dropdown-menu\"],[12],[2,\"\\n        \"],[10,\"span\"],[12],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[30,[36,6],[[35,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[11,\"div\"],[16,0,[31,[\"plutof-ac-suggestion \",[30,[36,0],[[32,1,[\"hasFocus\"]],\"plutof-ac-suggestion-focus\"],null]]]],[4,[38,2],[\"touchend\",[30,[36,1],[[32,0,[\"touchItem\"]],[32,1]],null]],null],[4,[38,2],[\"mouseenter\",[30,[36,1],[[32,0,[\"focusItem\"]],[32,1]],null]],null],[4,[38,2],[\"touchstart\",[30,[36,1],[[32,0,[\"focusItem\"]],[32,1]],null]],null],[4,[38,2],[\"mouseleave\",[30,[36,1],[[32,0,[\"unfocusItem\"]],[32,1]],null]],null],[12],[2,\"\\n                    \"],[10,\"p\"],[12],[2,\"\\n                        \"],[1,[30,[36,4],[[32,1,[\"label\"]],[35,3]],null]],[2,\"\\n                    \"],[13],[2,\"\\n                \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"fn\",\"on\",\"value\",\"hl-subs\",\"visibleItems\",\"-track-array\",\"each\",\"inputClassNames\",\"placeholder\",\"input\",\"showDropdownMenu\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/filter-selection.hbs"
    }
  });
});