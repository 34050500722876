define("plutof/models/users/usergroup", ["exports", "@ember-data/model", "plutof/mixins/display-name-from-value", "plutof/models/plutof-model", "plutof/utils/i18n"], function (_exports, _model, _displayNameFromValue, _plutofModel, _i18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.UserStatus = _exports.UserRights = void 0;
  _exports.isGroupMember = isGroupMember;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  // TODO: Inject into models, or make it a service
  const i18n = (0, _i18n.getI18n)();
  const UserRights = _exports.UserRights = {
    None: 0,
    NeedsModerating: 1,
    NotModerated: 2,
    Moderator: 3
  };
  const UserStatus = _exports.UserStatus = {
    Unverified: 0,
    ReadOnly: 1,
    ReadWrite: 2,
    Moderator: 4,
    Owner: 8
  };
  let Usergroup = (_dec = (0, _model.belongsTo)('users/workgroup'), _dec2 = (0, _model.belongsTo)('users/user'), _dec3 = (0, _model.attr)('number'), _dec4 = (0, _model.attr)('number'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('boolean'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _displayNameFromValue.displayName)('rights'), _dec10 = (0, _displayNameFromValue.displayName)('status'), _dec11 = pendingDisplayName('rights'), _dec12 = pendingDisplayName('status'), _dec13 = (0, _model.attr)('string'), _dec14 = (0, _model.attr)('string'), (_class = class Usergroup extends _plutofModel.default.extend(_displayNameFromValue.DisplayNameMixin) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "group", _descriptor, this);
      _initializerDefineProperty(this, "user", _descriptor2, this);
      _initializerDefineProperty(this, "rights", _descriptor3, this);
      _initializerDefineProperty(this, "status", _descriptor4, this);
      _initializerDefineProperty(this, "join_reason", _descriptor5, this);
      _initializerDefineProperty(this, "user_can_be_modified", _descriptor6, this);
      _initializerDefineProperty(this, "accepted_by_name", _descriptor7, this);
      _initializerDefineProperty(this, "name", _descriptor8, this);
      _defineProperty(this, "optionMixinModelName", 'users.usergroup');
      _initializerDefineProperty(this, "_rightsDisplayName", _descriptor9, this);
      _initializerDefineProperty(this, "_statusDisplayName", _descriptor10, this);
      // Those are needed because status/rights are not settable to 0 by user,
      // so OPTIONS request does not include them. We still, however, need to
      // show them, thus hacks like this.
      _initializerDefineProperty(this, "rightsDisplayName", _descriptor11, this);
      _initializerDefineProperty(this, "statusDisplayName", _descriptor12, this);
      // Readonly
      _initializerDefineProperty(this, "user_full_name", _descriptor13, this);
      _initializerDefineProperty(this, "group_name", _descriptor14, this);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "group", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "rights", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "join_reason", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "user_can_be_modified", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "accepted_by_name", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "_rightsDisplayName", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "_statusDisplayName", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "rightsDisplayName", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "statusDisplayName", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "user_full_name", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "group_name", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  function pendingDisplayName(field) {
    const dnField = `_${field}DisplayName`;
    return Ember.computed(field, dnField, function () {
      return this.get(field) === 0 ? i18n.t('Settings.groups.pending') : this.get(dnField);
    });
  }
  async function isGroupMember(store, userID, groupID, {
    minRights = UserRights.NeedsModerating,
    minStatus = UserStatus.ReadOnly
  } = {}) {
    const membership = await store.queryRecord('users/usergroup', {
      user: userID,
      group: groupID
    });
    return membership && membership.rights >= minRights && membership.status >= minStatus;
  }
  var _default = _exports.default = Usergroup;
});