define("plutof/controllers/area/edit", ["exports", "plutof/mixins/edit-controller", "plutof/utils/i18n", "plutof/utils/notifications"], function (_exports, _editController, _i18n, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const i18n = (0, _i18n.getI18n)();
  let EditAreaController = (_dec = Ember._action, _dec2 = Ember._action, (_class = class EditAreaController extends Ember.Controller.extend(_editController.default) {
    async save() {
      const {
        accessRights,
        measurementsData,
        itemData,
        geometryConverterData,
        samplingarea
      } = this.model;
      const {
        lat,
        lon
      } = geometryConverterData.extractCoordinates();
      samplingarea.setProperties({
        latitude: lat,
        longitude: lon
      });
      (0, _notifications.displayNotification)('status', i18n.t('General.saving'));
      if (samplingarea.get('site')) {
        // Sites can't have a direct project relation.
        samplingarea.set('study', null);
        // Sites can't be child areas.
        samplingarea.set('parent_samplingarea', null);
      }
      try {
        await samplingarea.save();
        if (samplingarea.site) {
          await accessRights.save();
        }
        await Ember.RSVP.all([measurementsData.save(), itemData.save(samplingarea, accessRights)]);
        (0, _notifications.displayNotification)('success', i18n.t('General.done'));
        this.send('goBack');
        this.set('routeHasBeenLoaded', false);
      } catch (error) {
        (0, _notifications.reportError)(error);
      }
    }
    cancel() {
      const area = this.get('model.samplingarea');
      area.rollbackAttributes();
      area.reload().then(() => this.send('goBack'));
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "save", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype)), _class));
  var _default = _exports.default = EditAreaController;
});