define("plutof/components/taxonomy/edit-remarks-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::Simple
      @title={{@title}}
      data-test="Taxonomy::TaxonNode::EditRemarksPanel"
  >
      <p>
          <span class={{icon "info"}}></span>
          {{i18n-t "Taxonomy.editPanelInfo"}}
      </p>
  
      <Layout::RegularGrid @columns={{2}}>
          <div>
              {{plutof-labelc "taxonomy.taxonnode.remarks"}}
  
              <Input
                  @type="text"
                  @value={{@taxon.remarks}}
                  class="form-control"
                  data-test="remarks" />
          </div>
  
          <div>
              {{plutof-labelc "taxonomy.taxonnode.citation_text"}}
  
              <Input
                  @type="text"
                  @value={{@taxon.citation_text}}
                  class="form-control"
                  data-test="citation_text" />
          </div>
      </Layout::RegularGrid>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "ezjzMCq9",
    "block": "{\"symbols\":[\"@title\",\"@taxon\"],\"statements\":[[8,\"plutof-panel/simple\",[[24,\"data-test\",\"Taxonomy::TaxonNode::EditRemarksPanel\"]],[[\"@title\"],[[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"p\"],[12],[2,\"\\n        \"],[10,\"span\"],[15,0,[30,[36,0],[\"info\"],null]],[12],[13],[2,\"\\n        \"],[1,[30,[36,1],[\"Taxonomy.editPanelInfo\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[8,\"layout/regular-grid\",[],[[\"@columns\"],[2]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"div\"],[12],[2,\"\\n            \"],[1,[30,[36,2],[\"taxonomy.taxonnode.remarks\"],null]],[2,\"\\n\\n            \"],[8,\"input\",[[24,0,\"form-control\"],[24,\"data-test\",\"remarks\"]],[[\"@type\",\"@value\"],[\"text\",[32,2,[\"remarks\"]]]],null],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"div\"],[12],[2,\"\\n            \"],[1,[30,[36,2],[\"taxonomy.taxonnode.citation_text\"],null]],[2,\"\\n\\n            \"],[8,\"input\",[[24,0,\"form-control\"],[24,\"data-test\",\"citation_text\"]],[[\"@type\",\"@value\"],[\"text\",[32,2,[\"citation_text\"]]]],null],[2,\"\\n        \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\",\"plutof-labelc\"]}",
    "meta": {
      "moduleName": "plutof/components/taxonomy/edit-remarks-panel.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});