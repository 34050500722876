define("plutof/translations/est/transactions", ["exports", "plutof/translations/helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    title: 'Kogude transaktsioonid',
    description: 'Siin võid lisada ja muuta kogude transaktsioone (laenud, deposiidid).',
    newTitle: 'Uus kogude transaktsioon',
    editTitle: 'Muuda kogude transaktsiooni',
    viewTitle: 'Kogude transaktsioon',
    agents: 'Agendid',
    correspondence: 'Korrespondentsid',
    items: 'Objektid',
    type: 'Tüüp',
    content: 'Sisu',
    sender: 'Saatja',
    receiver: 'Vastuvõtja',
    sentDate: 'Saatmise kuupäev',
    receivedDate: 'Vastuvõtmise kuupäev',
    itemId: 'ID',
    name: 'Nimi',
    comment: 'Kommentaar',
    depositedAs: 'Deponeeritud kui',
    transaction: 'Transaktsioon',
    shipments: 'Saadetised',
    fillAutomatically: 'Täida automaatselt',
    fillAutoToolt: 'Täida automaatselt, andmed võetakse transaktsiooni agendite paneelist.',
    number: 'Number',
    generatedNumber: 'Luuakse automaatselt',
    print: 'Prindi',
    dnaExtraction: 'Luba DNA proovide võtmine',
    dnaExtToolTip: 'DNA proovide võtmine on lubatud',
    isItemReturned: 'On tagastatud',
    period: 'Perioodi pikkus kuudes',
    editPermissionsMissing: 'Teil puuduvad muutmisõigused kirjele {{target}}',
    isUpdating: `Transaktsiooni objekte uuendatakse. Objekte saab muuta uuenduse
        lõppedes.`,
    itemTargetLabel: {
      specimen: 'Eksemplari ID',
      livingspecimen: 'Eluseksemplari ID',
      materialsample: 'Proovi ID',
      photobank: 'Fotopanga objekti ID'
    },
    objects: {
      tabs: {
        databased: 'Andmebaasistatud',
        notDatabased: 'Andmebaasistamata'
      },
      columns: {
        controls: (0, _helpers.alias)('General.controls'),
        newName: 'Täpsustatud taksoni nimi',
        gatheringAgents: 'Kogujad'
      },
      actions: {
        edit: {
          tooltip: 'Muuda transaktsiooni objekti.'
        },
        cancel: {
          label: 'Tühista',
          tooltip: 'Tühista muudatused.'
        },
        save: {
          label: 'Salvesta',
          tooltip: 'Salvesta muudatused'
        },
        delete: {
          tooltip: 'Kustuta transaktsiooni objekt.'
        },
        filter: {
          placeholder: 'Filtreeri (ID)'
        }
      }
    },
    dialogs: {
      markReturned: {
        title: 'Muuda "On tagastatud"',
        content: 'Kas märkida kõik transaktsiooni objektid tagastatuks?',
        confirm: 'Märgi tagastatuks'
      },
      markDnaExtractable: {
        title: 'Muuda "Luba DNA proovide võtmine"',
        content: 'Kas lubada DNA proovide võtmine kõikidel transaktsiooni objektidel?',
        confirm: 'Luba DNA proovide võtmine'
      },
      printing: {
        title: 'Prindi',
        ordering: 'Kirjete järjekord',
        orderingChoices: {
          name: 'ID',
          taxon: 'Takson'
        }
      }
    },
    invalidRoles: 'Vähemalt üks agent peab olema laenaja ja vähemalt üks vastuvõtja.'
  };
});