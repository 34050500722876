define("plutof/components/filerepository/-local-file-preview/button", ["exports", "@glimmer/component", "plutof/utils/filetypes"], function (_exports, _component, _filetypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.isImage}}
      <button
          {{on "click" (fn @preview @file)}}
          class="btn-fake-link"
      >
          <span class={{icon "preview"}}></span>
      </button>
  {{/if}}
  
  */
  {
    "id": "U+gZHddr",
    "block": "{\"symbols\":[\"@file\",\"@preview\"],\"statements\":[[6,[37,3],[[32,0,[\"isImage\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"button\"],[24,0,\"btn-fake-link\"],[4,[38,1],[\"click\",[30,[36,0],[[32,2],[32,1]],null]],null],[12],[2,\"\\n        \"],[10,\"span\"],[15,0,[30,[36,2],[\"preview\"],null]],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"icon\",\"if\"]}",
    "meta": {
      "moduleName": "plutof/components/filerepository/-local-file-preview/button.hbs"
    }
  });
  class LocalFilePreviewButton extends _component.default {
    get isImage() {
      return (0, _filetypes.is_file_image)(this.args.file);
    }
  }
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, LocalFilePreviewButton);
});