define("plutof/translations/est/collection", ["exports", "plutof/translations/helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    collection: 'Kogu',
    list: {
      title: 'Kogud',
      description: 'Siin võid kogusid lisada ja muuta.'
    },
    view: {
      title: (0, _helpers.alias)('collection.collection')
    },
    edit: {
      title: 'Muuda kogu'
    },
    add: {
      title: 'Uus kogu',
      groupCreationDialog: {
        title: 'Kas soovite seda kogu hallata?',
        content: 'Kui soovite kogu hallata, lisatakse teid kogu töörühma haldajaks. Vastasel juhul jääb kogu haldajata. Pärast haldajata kogu loomist saab seda muuta kasutajatoega ühendust võttes.'
      }
    },
    transactionStats: {
      title: 'Statistika',
      allTransactions: 'Transaktsioone kokku',
      activeTransactions: 'Aktiivseid transaktsioone',
      recordsWithImages: 'Piltidega kirjeid'
    },
    repository: {
      name: 'Nimi',
      repositories: 'Alamkogud'
    }
  };
});