define("plutof/translations/eng/taxon-import", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    importGbif: 'GBIF import',
    searchGbif: 'Search from GBIF',
    searchGbifTooltip: 'Import taxon name from GBIF',
    gbifDataset: 'GBIF dataset',
    importMycobank: 'MycoBank import',
    searchMycobank: 'Search from MycoBank',
    searchMycobankTooltip: 'Import missing taxon name from MycoBank',
    notFound: 'No matching records found.',
    synonymOf: 'synonym of {{acceptedName}}',
    newCreated: 'New taxon name successfully added.',
    modal: {
      title: 'Import Taxa'
    },
    actions: {
      open: 'Import taxa',
      close: 'Close',
      view: 'View',
      import: 'Import'
    }
  };
});