define("plutof/templates/trait/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "IUXzNfnH",
    "block": "{\"symbols\":[\"navbar\",\"slots\"],\"statements\":[[8,\"navbar/view\",[],[[\"@icon\",\"@title\",\"@record\",\"@module\",\"@editRoute\",\"@deletable\",\"@permissions\"],[[30,[36,0],[\"trait\"],null],[30,[36,1],[\"Forms.traits.trait\"],null],[32,0,[\"model\",\"trait\"]],\"measurement/measurement\",\"trait.edit\",true,[32,0,[\"model\",\"permissions\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"primaryControls\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,1,[\"button\"]],[],[[\"@clicked\",\"@icon\"],[[32,0,[\"searchOccurrences\"]],[30,[36,0],[\"search\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,1],[\"General.taxonOccurrences\"],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"\\n\\n\"],[8,\"trait/view/general-data\",[],[[\"@trait\",\"@sources\",\"@setChoices\"],[[32,0,[\"model\",\"trait\"]],[32,0,[\"model\",\"sources\"]],[32,0,[\"model\",\"setChoices\"]]]],null],[2,\"\\n\\n\"],[6,[37,2],[[32,0,[\"model\",\"trait\",\"isSet\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"trait/view/set-choices\",[],[[\"@choices\"],[[32,0,[\"model\",\"setChoices\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,\"history/view\",[],[[\"@record\"],[[32,0,[\"model\",\"trait\"]]]],null],[2,\"\\n\\n\"],[8,\"trait/view/permissions\",[],[[\"@trait\"],[[32,0,[\"model\",\"trait\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\",\"if\"]}",
    "meta": {
      "moduleName": "plutof/templates/trait/view.hbs"
    }
  });
});