define("plutof/translations/est/material-samples", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    searchTitle: 'Otsi proove',
    infoTitle: 'Proovid',
    title: 'Proovid',
    newSample: 'Uus proov',
    editSample: 'Muuda proovi',
    viewSample: 'Proov',
    name: 'Proovi ID',
    type: 'Tüüp',
    size: 'Suurus',
    description: 'Kirjeldus',
    depositedIn: 'Hoiustatud',
    subRepository: 'Alamkogu',
    cloneToolt: 'Kopeeri rida lisamaks sama proovi kirje erinevate tunnustega.',
    saveProgress: '{{finished}}/{{total}} proovi on salvestatud',
    placeholders: {
      searchHint: 'Otsi nime järgi'
    }
  };
});