define("plutof/controllers/livingspecimen/edit", ["exports", "plutof/utils/controllers"], function (_exports, _controllers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class LivingspecimenEditController extends _controllers.EditController {
    _save() {
      return this.model.livingSpecimenModel.save();
    }
  }
  var _default = _exports.default = LivingspecimenEditController;
});