define("plutof/templates/collection/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "JLRjXXo+",
    "block": "{\"symbols\":[\"Field\",\"collection\",\"common\"],\"statements\":[[8,\"navbar/list\",[],[[\"@icon\",\"@title\",\"@addRoute\",\"@goBack\",\"@pagination\",\"@infoContent\"],[[30,[36,0],[\"collection\"],null],[30,[36,1],[\"collection.list.title\"],null],\"collection.add\",[30,[36,2],[\"goBack\"],null],[32,0,[\"model\",\"pagination\"]],\"information/content/collection/general\"]],null],[2,\"\\n\\n\"],[8,\"tables/list-route\",[],[[\"@records\"],[[32,0,[\"model\",\"pagination\",\"objects\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1],[],[[\"@label\"],[[30,[36,1],[\"PlutofListViewColumn.name\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],null,[[\"route\",\"model\"],[\"collection.view\",[32,2,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[32,2,[\"agent_name\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,3,[\"modified\"]],[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[1,2,3]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\",\"route-action\",\"link-to\"]}",
    "meta": {
      "moduleName": "plutof/templates/collection/index.hbs"
    }
  });
});