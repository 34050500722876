define("plutof/templates/import/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Vtd4jYZX",
    "block": "{\"symbols\":[\"navbar\",\"slots\",\"@disableAdd\"],\"statements\":[[8,\"test/route-marker\",[],[[\"@route\"],[\"import.index\"]],null],[2,\"\\n\\n\"],[8,\"navbar/list\",[],[[\"@icon\",\"@title\",\"@pagination\",\"@infoTitle\",\"@infoContent\"],[[30,[36,0],[\"import\"],null],[30,[36,1],[\"Import.import\"],null],[32,0,[\"model\",\"pagination\"]],[30,[36,1],[\"Import.import\"],null],\"information/content/import/general\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"primaryControls\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,1,[\"button\"]],[],[[\"@clicked\",\"@icon\",\"@disabled\"],[[32,0,[\"generateTemplate\"]],[30,[36,0],[\"file\"],null],[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,1],[\"Import.indexTemplatePage\"],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,1,[\"button\"]],[[24,0,\"navbar-list__add\"]],[[\"@clicked\",\"@icon\",\"@disabled\"],[[32,0,[\"add\"]],[30,[36,0],[\"add-new\"],null],[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,1],[\"General.New\"],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"\\n\\n\"],[8,\"import/process-list\",[],[[\"@processes\",\"@stop\",\"@delete\",\"@download\"],[[32,0,[\"model\",\"pagination\",\"objects\"]],[32,0,[\"stop\"]],[32,0,[\"remove\"]],[32,0,[\"stopAndDownload\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\"]}",
    "meta": {
      "moduleName": "plutof/templates/import/index.hbs"
    }
  });
});