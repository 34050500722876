define("plutof/components/conservation/red-list/view/assessments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <RelatedObjects::View
      @pagination={{@assessments}}
      @add={{@add}}
      @canModify={{@canModify}}
      @searchModule="red-list-assessments"
      @searchQuery={{hash red_list=@redlist.id}}
      @title={{i18n-t "conservation.redList.view.assessments"}}
  >
      <RelatedObjects::Content @pagination={{@assessments}} as |Field assessment|>
          <Field @label={{backend-translations/label "red-list-assessment.title"}}>
              {{#link-to "conservation-lab.red-list-assessment.view" assessment.id}}
                  {{assessment.title}}
              {{/link-to}}
          </Field>
  
          <Field @label={{backend-translations/label "red-list-assessment.taxon_node"}}>
              {{assessment.taxon_node.representation}}
          </Field>
  
          <Field @label={{backend-translations/label "red-list-assessment.assessor"}}>
              {{assessment.assessor.representation}}
          </Field>
  
          <Field @label={{backend-translations/label "red-list-assessment.reviewer"}}>
              {{assessment.reviewer.representation}}
          </Field>
      </RelatedObjects::Content>
  </RelatedObjects::View>
  
  */
  {
    "id": "dotKK8Mz",
    "block": "{\"symbols\":[\"Field\",\"assessment\",\"@assessments\",\"@add\",\"@canModify\",\"@redlist\"],\"statements\":[[8,\"related-objects/view\",[],[[\"@pagination\",\"@add\",\"@canModify\",\"@searchModule\",\"@searchQuery\",\"@title\"],[[32,3],[32,4],[32,5],\"red-list-assessments\",[30,[36,0],null,[[\"red_list\"],[[32,6,[\"id\"]]]]],[30,[36,1],[\"conservation.redList.view.assessments\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"related-objects/content\",[],[[\"@pagination\"],[[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,1],[],[[\"@label\"],[[30,[36,2],[\"red-list-assessment.title\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],null,[[\"route\",\"model\"],[\"conservation-lab.red-list-assessment.view\",[32,2,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"                \"],[1,[32,2,[\"title\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,1],[],[[\"@label\"],[[30,[36,2],[\"red-list-assessment.taxon_node\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[32,2,[\"taxon_node\",\"representation\"]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,1],[],[[\"@label\"],[[30,[36,2],[\"red-list-assessment.assessor\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[32,2,[\"assessor\",\"representation\"]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,1],[],[[\"@label\"],[[30,[36,2],[\"red-list-assessment.reviewer\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[32,2,[\"reviewer\",\"representation\"]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[1,2]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hash\",\"i18n-t\",\"backend-translations/label\",\"link-to\"]}",
    "meta": {
      "moduleName": "plutof/components/conservation/red-list/view/assessments.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});