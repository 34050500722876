define("plutof/templates/components/experiment/sequencing/view/tube-summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "OI0jhth+",
    "block": "{\"symbols\":[\"@source\",\"@material\"],\"statements\":[[10,\"table\"],[14,0,\"data-table\"],[12],[2,\"\\n    \"],[10,\"tbody\"],[12],[2,\"\\n        \"],[10,\"tr\"],[12],[2,\"\\n            \"],[10,\"td\"],[12],[1,[30,[36,0],[\"experiment.extraction.general.source\"],[[\"disableTooltip\"],[true]]]],[13],[2,\"\\n            \"],[10,\"td\"],[12],[1,[32,1,[\"representation\"]]],[13],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"tr\"],[12],[2,\"\\n            \"],[10,\"td\"],[12],[1,[30,[36,1],null,[[\"path\",\"hideTooltip\"],[\"sequencing-result.primer\",true]]]],[13],[2,\"\\n            \"],[10,\"td\"],[12],[1,[32,2,[\"primer\",\"representation\"]]],[13],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"tr\"],[12],[2,\"\\n            \"],[10,\"td\"],[12],[1,[30,[36,1],null,[[\"path\",\"hideTooltip\"],[\"sequencing-result.original_file\",true]]]],[13],[2,\"\\n            \"],[10,\"td\"],[12],[1,[32,2,[\"source_file\",\"representation\"]]],[13],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"tr\"],[12],[2,\"\\n            \"],[10,\"td\"],[12],[1,[30,[36,1],null,[[\"path\",\"hideTooltip\"],[\"sequencing-result.raw_sequence\",true]]]],[13],[2,\"\\n            \"],[10,\"td\"],[12],[1,[32,2,[\"raw_sequence\"]]],[13],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"tr\"],[12],[2,\"\\n            \"],[10,\"td\"],[12],[1,[30,[36,1],null,[[\"path\",\"hideTooltip\"],[\"sequencing-result.quality\",true]]]],[13],[2,\"\\n            \"],[10,\"td\"],[12],[1,[32,2,[\"quality\"]]],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"common/local-label\",\"plutof-labelc\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/experiment/sequencing/view/tube-summary.hbs"
    }
  });
});