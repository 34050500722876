define("plutof/mixins/add-route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "plutof/misc/resetmixin", "plutof/mixins/default-focus-route", "plutof/mixins/route", "plutof/utils/validations"], function (_exports, _authenticatedRouteMixin, _resetmixin, _defaultFocusRoute, _route, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Default mixin soup for add routes
  //
  // NB: Don't add logic here, it must be just a combination on mixins - not every add route
  // will work with everything herer (but most do)
  const AddRoute = Ember.Mixin.create(_authenticatedRouteMixin.default, _resetmixin.default, _route.default, _defaultFocusRoute.default, _validations.ValidatedRoute, {});
  var _default = _exports.default = AddRoute;
});