define("plutof/templates/experiment/dna-extraction/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "qBR74hx8",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"route-marker-dna-extraction-add\"],[12],[13],[2,\"\\n\\n\"],[8,\"navbar/edit\",[],[[\"@icon\",\"@title\",\"@record\",\"@reset\",\"@infoContent\"],[[30,[36,0],[\"experiment\"],null],[30,[36,1],[\"experiment.extraction.routeTitles.add\"],null],[32,0,[\"model\",\"experiment\"]],[30,[36,2],[\"triggerReset\"],null],\"information/content/dna-lab/general\"]],null],[2,\"\\n\\n\"],[1,[30,[36,6],null,[[\"experiment\",\"dnas\",\"linkedItemsData\",\"validationContext\",\"cancel\",\"save\"],[[35,5,[\"experiment\"]],[35,5,[\"materials\"]],[35,5,[\"linkedItemsData\"]],[35,4],[30,[36,3],[[32,0],\"cancel\"],null],[30,[36,3],[[32,0],\"saveExperiment\"],null]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\",\"route-action\",\"action\",\"validationContext\",\"model\",\"experiment/dna-extraction/edit-form\"]}",
    "meta": {
      "moduleName": "plutof/templates/experiment/dna-extraction/add.hbs"
    }
  });
});