define("plutof/services/store", ["exports", "plutof/utils/annotation/store"], function (_exports, _store) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let StoreService = (_dec = Ember.inject.service, (_class = class StoreService extends Ember.Service {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "emberDataStore", _descriptor, this);
      _defineProperty(this, "_store", this.emberDataStore);
      _defineProperty(this, "annotatedStore", null);
    }
    /* Store proxy */
    createRecord(...args) {
      return this._store.createRecord(...args);
    }
    find(...args) {
      return this._store.find(...args);
    }
    findAll(...args) {
      return this._store.findAll(...args);
    }
    findRecord(...args) {
      return this._store.findRecord(...args);
    }
    modelFor(...args) {
      return this._store.modelFor(...args);
    }
    query(...args) {
      return this._store.query(...args);
    }
    queryRecord(...args) {
      return this._store.queryRecord(...args);
    }
    unloadRecord(...args) {
      return this._store.unloadRecord(...args);
    }
    peekRecord(...args) {
      return this._store.peekRecord(...args);
    }
    peekAll(...args) {
      return this._store.peekAll(...args);
    }
    normalize(...args) {
      return this._store.normalize(...args);
    }
    push(...args) {
      return this._store.push(...args);
    }
    /* End store proxy */

    // This is not as pure (if that world can be appplied to whatever that is) as
    // dumb proxy, but some things do unfortunately need to know whether they are
    // annotating or changing
    startAnnotation(annotation = null, options = {}) {
      if (this.inAnnotation) {
        throw new Error('Already inside annotation');
      }
      const annotatedStore = new _store.AnnotatedStore(this.emberDataStore, annotation, options);
      this._store = annotatedStore;
      this.annotatedStore = annotatedStore;
    }
    endAnnotation() {
      if (!this.inAnnotation) {
        throw new Error('Not inside annotation');
      }
      this._store = this.emberDataStore;
      this.annotatedStore = null;
    }
    get inAnnotation() {
      return Boolean(this.annotatedStore);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "emberDataStore", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  var _default = _exports.default = StoreService;
});