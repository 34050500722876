define("plutof/templates/components/common/radio-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "duY4CfQC",
    "block": "{\"symbols\":[\"@invalid\",\"@activate\",\"@active\",\"&default\"],\"statements\":[[18,4,[[30,[36,2],null,[[\"choice\"],[[30,[36,1],[\"common/-radio-group-choice\"],[[\"active\",\"activate\",\"invalid\"],[[32,3],[30,[36,0],[[32,0],[32,2]],null],[32,1]]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/common/radio-group.hbs"
    }
  });
});