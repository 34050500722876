define("plutof/components/analysis/wrapper-source", ["exports", "@ember-decorators/component", "ember-cp-validations", "plutof/components/filerepository/select-or-upload", "plutof/mixins/component-validations", "plutof/utils/access"], function (_exports, _component, _emberCpValidations, _selectOrUpload, _componentValidations, _access) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createWrapperSourceData = createWrapperSourceData;
  _exports.default = void 0;
  _exports.loadWrapperSourceData = loadWrapperSourceData;
  var _dec, _dec2, _dec3, _class, _descriptor, _dec4, _dec5, _class2, _class3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  // TODO: Move those to ANALYSIS_TYPES
  const shMatchMaxSize = 1000000;
  const blasterMaxSize = 2000000;
  const ITSxMaxSize = 30000000;
  let WrapperSourceData = (_dec = Ember.inject.service, _dec2 = Ember.computed('source.present', 'fasta_in_text'), _dec3 = Ember.computed('run,analysis_type', 'source.size'), (_class = class WrapperSourceData extends Ember.Object {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "i18n", _descriptor, this);
    }
    init() {
      super.init(...arguments);
      this.set('fasta_in_text', '');
    }
    isSizeAcceptable(size) {
      const type = this.run.analysis_type;
      switch (type) {
        case 'itsx':
          return size <= ITSxMaxSize;
        case 'sh_match':
          return size <= shMatchMaxSize;
        case 'blaster':
        case 'blastn':
          return size <= blasterMaxSize;
        default:
          return Ember.isNone(type) || Ember.isNone(size);
      }
    }
    get present() {
      return this.source.present || !Ember.isEmpty(this.fasta_in_text);
    }
    get fileSizeIsAcceptable() {
      const size = this.source.size;
      return this.isSizeAcceptable(size);
    }
    async save() {
      if (!this.source.present) {
        if (Ember.isEmpty(this.fasta_in_text)) {
          // Sanity check. Should never happen
          throw new Error('Analysis source missing');
        }
        const filename = 'sequences_' + Math.round(Math.random() * 100000) + '.fsa';
        const fastaFile = new File([this.fasta_in_text], filename, {
          type: 'text/x-fasta'
        });
        await this.source.selectNew(fastaFile);
      }
      const sourceFile = await this.source.save(this.access);
      this.run.set('source_file', sourceFile);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "i18n", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "present", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "present"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fileSizeIsAcceptable", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "fileSizeIsAcceptable"), _class.prototype)), _class));
  async function createWrapperSourceData(store, run, sourceFile = null) {
    const container = Ember.getOwner(store);
    const fileUpload = container.lookup('service:fileUpload');
    const source = new _selectOrUpload.SelectOrUploadFileModel({
      fileUpload,
      selection: sourceFile
    });
    const ownerInjection = container.ownerInjection();
    return WrapperSourceData.create(ownerInjection, {
      run,
      source,
      access: await (0, _access.create_access_rights)(store)
    });
  }
  async function loadWrapperSourceData(store, run) {
    const sourceFile = await run.source_file;
    return createWrapperSourceData(store, run, sourceFile);
  }
  const Validations = (0, _emberCpValidations.buildValidations)({
    'data.fileSizeIsAcceptable': (0, _emberCpValidations.validator)('is-truthy'),
    'data.present': (0, _emberCpValidations.validator)('is-truthy')
  });
  let WrapperSource = (_dec4 = (0, _component.classNames)('analysis-wrapper-source'), _dec5 = Ember._action, _dec4(_class2 = (_class3 = class WrapperSource extends Ember.Component.extend(Validations, _componentValidations.default) {
    uploadNew(file) {
      this.data.uploadFromFile(file);
    }
  }, (_applyDecoratedDescriptor(_class3.prototype, "uploadNew", [_dec5], Object.getOwnPropertyDescriptor(_class3.prototype, "uploadNew"), _class3.prototype)), _class3)) || _class2);
  var _default = _exports.default = WrapperSource;
});