define("plutof/components/transaction/view/objects/row/item-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <td>
      {{@item.item_id}}
  </td>
  
  {{#if @itemType.contains.additionalID}}
      <td>
          {{! Nothing here. }}
      </td>
  {{/if}}
  
  {{#if @itemType.contains.taxon}}
      <td>
          {{! Nothing here. }}
      </td>
  {{/if}}
  
  <td>
      {{! new_name - Nothing here. }}
  </td>
  
  <td>
      {{! gathering_agents_repr - Nothing here. }}
  </td>
  
  {{#if @itemType.contains.deposited_as}}
      <td>
          {{! Nothing here. }}
      </td>
  {{/if}}
  
  <td>
      {{@item.comment}}
  </td>
  
  <td>
      {{read-only-boolean @item.can_extract_dna}}
  </td>
  
  <td>
      {{read-only-boolean @item.is_returned}}
  </td>
  
  {{#unless @disableControls}}
      <td>
          <Transaction::View::Objects::Row::ViewControls
              @disable={{@disableEdit}}
              @edit={{@edit}}
              @delete={{@delete}} />
      </td>
  {{/unless}}
  
  */
  {
    "id": "chyBoIdR",
    "block": "{\"symbols\":[\"@disableEdit\",\"@edit\",\"@delete\",\"@item\",\"@itemType\",\"@disableControls\"],\"statements\":[[10,\"td\"],[12],[2,\"\\n    \"],[1,[32,4,[\"item_id\"]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,0],[[32,5,[\"contains\",\"additionalID\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"td\"],[12],[2,\"\\n\"],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,0],[[32,5,[\"contains\",\"taxon\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"td\"],[12],[2,\"\\n\"],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"td\"],[12],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[12],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,0],[[32,5,[\"contains\",\"deposited_as\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"td\"],[12],[2,\"\\n\"],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"td\"],[12],[2,\"\\n    \"],[1,[32,4,[\"comment\"]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[[32,4,[\"can_extract_dna\"]]],null]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[[32,4,[\"is_returned\"]]],null]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,2],[[32,6]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"td\"],[12],[2,\"\\n        \"],[8,\"transaction/view/objects/row/view-controls\",[],[[\"@disable\",\"@edit\",\"@delete\"],[[32,1],[32,2],[32,3]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"read-only-boolean\",\"unless\"]}",
    "meta": {
      "moduleName": "plutof/components/transaction/view/objects/row/item-view.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});