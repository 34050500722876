define("plutof/templates/components/plutof-select/direct", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "M+HfzQwU",
    "block": "{\"symbols\":[\"xs\",\"option\"],\"statements\":[[6,[37,9],null,[[\"value\",\"action\",\"class\"],[[35,6],[30,[36,8],[[32,0],[30,[36,7],[[35,6]],null]],null],\"form-control\"]],[[\"default\"],[{\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[32,1,[\"option\"]]],[[\"value\"],[null]],[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[34,1]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[32,1,[\"option\"]]],[[\"value\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[32,2]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"component\",\"prompt\",\"if\",\"content\",\"-track-array\",\"each\",\"value\",\"mut\",\"action\",\"x-select\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/plutof-select/direct.hbs"
    }
  });
});