define("plutof/components/occurrences-table/-responsive-row/cells", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  let ResponsiveRowCells = (_dec = (0, _component.classNames)('occ-table-responsive-row__cells'), _dec(_class = class ResponsiveRowCells extends Ember.Component {}) || _class);
  var _default = _exports.default = ResponsiveRowCells;
});