define("plutof/controllers/clipboard/photobank", ["exports", "plutof/config/environment", "plutof/controllers/clipboard/clipboard", "plutof/controllers/clipboard/utils/bulk-update", "plutof/mixins/component-validations"], function (_exports, _environment, _clipboard, _bulkUpdate, _componentValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class PhotobankClipboardController extends Ember.Controller.extend(_clipboard.ClipboardControllerMixin, _componentValidations.default) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "clipboardURL", _environment.default.API_HOST + '/clipboard/clipboards/photobanks/');
      _defineProperty(this, "contentType", 'photobank/collectionobject');
      _defineProperty(this, "modelPath", 'photobank/collectionobject');
      _defineProperty(this, "viewRoute", 'photobank.view');
      _defineProperty(this, "countField", 'collectionobjects');
      _defineProperty(this, "bulkUpdatePanels", (0, _bulkUpdate.getBulkUpdatePanels)(['photobanks', 'samplingareas', 'samplingevents']));
      _defineProperty(this, "bulkEndpoint", 'bulk-update/photobanks/');
      _defineProperty(this, "mainformType", null);
      _defineProperty(this, "clipboardModule", 'clipboard_photobank');
    }
  }
  var _default = _exports.default = PhotobankClipboardController;
});