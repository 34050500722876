define("plutof/components/observation/observation-table", ["exports", "@ember-decorators/component", "plutof/components/occurrences-table", "plutof/components/linked-items/-edit/files", "plutof/misc/abstract", "plutof/misc/config", "plutof/misc/content_types", "plutof/mixins/occurrence_row_mixin", "plutof/utils/model", "plutof/utils/validations"], function (_exports, _component, _occurrencesTable, _files, _abstract, _config, _content_types, _occurrence_row_mixin, _model, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ObservationTableData = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _dec12, _dec13, _dec14, _class2, _class3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const COUNT_REGEXP = new RegExp('^(-1)$|^([0-9]{1,8})$');
  const TAXON_SOURCES = ['commonName', 'estBirdsAcronym', 'sheetItem'];

  // observation, taxon, taxonValue, estBirdsAcronym, commonName,
  // tameatlasAcronym
  //
  // TODO: Move mixin to OccurrenceEntry
  let ObservationEntry = (_dec = Ember.computed.alias('observation'), _dec2 = Ember.computed('commonName', 'taxon', 'observation.individual_count', 'observation.remarks'), _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember.computed('mainform.id'), _dec8 = Ember.computed('countIsRequired', 'observation.individual_count'), _dec9 = Ember.computed.notEmpty('taxon'), _dec10 = Ember.computed('observation.remarks'), _dec11 = Ember.computed.and('countIsValid', 'taxonIsValid', 'measurementValidations.isValid', 'remarksAreValid'), (_class = class ObservationEntry extends _occurrencesTable.OccurrenceEntry.extend(_occurrence_row_mixin.default) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "occurrence", _descriptor, this);
      _initializerDefineProperty(this, "taxonIsValid", _descriptor2, this);
      // ember-validations just refuse to work here
      _initializerDefineProperty(this, "isValid", _descriptor3, this);
    }
    init() {
      super.init(...arguments);
      this.loadMeasurementValidations();
    }
    async save(access) {
      await super.save(...arguments);
      const store = this.get('observation.store'); // TODO: Pass store to entry constructor
      const ctype = await (0, _content_types.get_ctype_by_name)(store, 'observation/observation');
      this.determination.setProperties({
        content_type: ctype,
        object_id: this.observation.id,
        taxon_node: this.taxon
      });
      await this.determination.save();
      await Ember.RSVP.all(this.savedDeterminers.invoke('destroyRecord'));

      // Determiners should be an array, but in one case where it's used, it's just an
      // autocomplete field, which makes sense because there is no room. Still, limiting
      // data to one agent is very meh, so this works with both
      this.savedDeterminers.clear();
      const determinerSaves = Ember.makeArray(this.determiners).map(person => {
        const determiner = store.createRecord('determination/determiner', {
          determination: this.determination,
          person
        });
        this.savedDeterminers.pushObject(determiner);
        return determiner.save();
      });
      await Ember.RSVP.all(determinerSaves);
      await this.linkedFiles.save(access);
    }
    get defaultValuesAreEmpty() {
      const obsFields = ['commonName', 'taxon', 'observation.individual_count', 'observation.remarks'];
      return obsFields.map(prop => this.get(prop)).every(Ember.isEmpty);
    }
    setTaxonFrom(taxon, source = null) {
      this.set('taxon', taxon);
      TAXON_SOURCES.filter(src => src !== source).forEach(src => this.set(src, null));
    }
    setCommonName(commonName) {
      this.set('commonName', commonName);
      if (!Ember.isNone(commonName)) {
        commonName.get('taxon_node').then(taxon => this.setTaxonFrom(taxon, 'commonName'));
      }
    }
    setEstBirdsAcronym(acronym) {
      this.set('estBirdsAcronym', acronym);
      if (!Ember.isNone(acronym)) {
        acronym.get('taxon_node').then(taxon => this.setTaxonFrom(taxon, 'estBirdsAcronym'));
      }
    }
    setSheetItem(sheetItem) {
      this.set('sheetItem', sheetItem);
      if (!Ember.isNone(sheetItem)) {
        this.observation.store.findRecord('taxonomy/taxonnode', sheetItem.id).then(taxon => {
          this.setTaxonFrom(taxon, 'sheetItem');
        });
      }
    }

    // validations
    loadMeasurementValidations() {
      const context = _validations.ValidationContext.create({
        name: 'Measurements'
      });
      this.set('measurementValidations', context);
    }
    get countIsRequired() {
      return this.get('mainform.id') === _config.default.Observation.BIRD_MAINFORM_ID;
    }
    get countIsValid() {
      const count = this.get('observation.individual_count');
      return Ember.isPresent(count) ? COUNT_REGEXP.test(count) : !this.countIsRequired;
    }
    get remarksAreValid() {
      const remarks = this.get('observation.remarks');
      return Ember.isEmpty(remarks) || remarks.length <= 2048;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "occurrence", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "defaultValuesAreEmpty", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "defaultValuesAreEmpty"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setTaxonFrom", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "setTaxonFrom"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setCommonName", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "setCommonName"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setEstBirdsAcronym", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "setEstBirdsAcronym"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setSheetItem", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "setSheetItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "countIsRequired", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "countIsRequired"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "countIsValid", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "countIsValid"), _class.prototype), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "taxonIsValid", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "remarksAreValid", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "remarksAreValid"), _class.prototype), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isValid", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  class ObservationTableData extends _occurrencesTable.OccurrencesData {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "enableDeterminers", false);
      _defineProperty(this, "modelPath", 'taxonoccurrence/observation/observation');
      _defineProperty(this, "measurementsForm", 'observation_form');
    }
    createEntry(observation, measurements) {
      const determination = this.store.createRecord('determination/determination');
      const substrate = this.store.createRecord('substrate/substrate');
      return ObservationEntry.create({
        rowId: this.generateID(),
        observation,
        mainform: this.mainform,
        measurementsData: measurements,
        substrate,
        // Compared to the usual style, determination data here is populated only on save. Previously,
        // it was also constructed there, however it led to corruption on save error, so we force
        // a single determination instance
        determination,
        // Person list, editable through the row form
        determiners: [],
        // Contains determination/determiner instances, that were created during save(). If the save
        // fails, those are removed before new ones are made. Better way would be to do the usual
        // "add new, remove old ones", but this will suffice until I think of a good way to do
        // it everywhere without boilerplate
        savedDeterminers: [],
        linkedFiles: this._createLinkedFiles(observation)
      });
    }
    _createLinkedFiles(observation) {
      // XXX: Have to massage the model a bit so that both
      // versions have the same .save signature
      //
      // TODO: No longer necessary
      const model = (0, _files.createFileItemsModel)(this.store, {
        sharedItems: this.sharedItems
      });
      const wrapper = Object.create(model);
      wrapper.save = function (access) {
        return model.save(observation, access);
      };
      return wrapper;
    }

    // XXX: Not a fan of this
    addEntry(entry) {
      this.entries.pushObject(entry);
    }
    static initialize(store, fileUpload, observations, fields = {}) {
      if (Ember.isNone(observations)) {
        observations = [];
      }
      const data = ObservationTableData.create((0, _abstract.mergeObjects)({
        entries: [],
        store,
        fileUpload
      }, fields));
      observations.forEach(obs => data.addOccurrence(obs));
      return data;
    }
  }
  _exports.ObservationTableData = ObservationTableData;
  let ObservationTable = (_dec12 = (0, _component.classNames)('observation-table'), _dec13 = Ember._action, _dec14 = (0, _model.direct_property)(['tableHeaders.length', 'mainform.estbirds_status', 'mainform.common_names', 'data.enableDeterminers'], function () {
    const [dynamicHeaderCount, ...toggleHeaders] = Array.from(arguments);
    const toggledHeaderCount = toggleHeaders.filter(h => h).length;
    return dynamicHeaderCount + toggledHeaderCount + 4;
  }), _dec12(_class2 = (_class3 = class ObservationTable extends _occurrencesTable.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "templateName", 'components/observation/observation-table');
      _defineProperty(this, "modelPath", 'taxonoccurrence/observation/observation');
      _defineProperty(this, "cloningRules", {
        occurrence: {
          included: ['mainform'],
          excluded: ['individual_count']
        },
        entry: {
          included: ['mainform', 'taxon', 'estBirdsAcronym', 'estBirdsAcronymValue', 'commonName', 'taxonValue'],
          excluded: []
        },
        measurements: {
          included: [],
          excluded: ['38', '52', '53'] // Sex, Age1, Age2.
        }
      });
      // XXX: Doesn't work
      _initializerDefineProperty(this, "tableColumnCount", _descriptor4, this);
    }
    showSheet() {
      this.set('showTaxonSheet', true);
    }
  }, (_applyDecoratedDescriptor(_class3.prototype, "showSheet", [_dec13], Object.getOwnPropertyDescriptor(_class3.prototype, "showSheet"), _class3.prototype), _descriptor4 = _applyDecoratedDescriptor(_class3.prototype, "tableColumnCount", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class3)) || _class2);
  var _default = _exports.default = ObservationTable;
});