define("plutof/translations/est/analysis", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    newAnalysis: 'Uus sekventsi analüüs',
    editAnalysis: 'Muuda sekventsi analüüsi',
    analysis: 'Sekventsi analüüs',
    programParameters: 'Programmi parameetrid',
    complement: 'Complement',
    complementToolt: 'Checks both DNA strands against the database, creating reverse complements.',
    concat: 'Concat',
    concatToolt: 'Saves a FASTA-file with concatenated ITS sequences (with 5.8S removed).',
    detailed: 'Detailed',
    detailedToolt: 'Saves a tab-separated list of all results.',
    preserve: 'Preserve',
    preserveToolt: 'Preserve sequence headers in input file instead of printing out ITSx headers.',
    anchorHmm: 'Hmm',
    fileTooBigInfo: 'Maksimaalne faili suurus SH match puhul 1 MB ja ITSx 30 MB.',
    genBank: 'INSD (fungi)',
    genBankToolt: 'INSD = GenBank + ENA + DDBJ',
    animal: 'UNITE+INSD (other eukaryotes)',
    animalToolt: 'UNITE+INSD (other eukaryotes)',
    unite: 'UNITE (fungi)',
    uniteToolt: 'UNITE (fungi)',
    envir: 'Keskkonnast',
    envirToolt: 'Keskkonnast',
    tri12: 'ToxGen',
    tri12Toolt: 'Perekonna Fusarium liikide Tri12 geenijärjetused',
    fileLabel: 'Vali fail FASTA formaadis',
    fastaTextLabel: 'Sisesta 1-1000 sekventsi FASTA formaadis',
    running: '(jookseb)',
    start: 'Alusta',
    sourceData: 'Sekventsid',
    addDeterminations: 'Lisa liigihüpoteeside määrangud',
    hasDetrmination: 'Määrang lisatud',
    clipboardItems: '{{count}} sekventsi näpitstahvlilt',
    generateReport: 'Koosta aruanne',
    downloadCSV: 'Laadi CSV alla',
    downloadRawResults: 'Laadi tulemused alla',
    addFASTA: 'Lisa FASTA failist',
    fastaSequences: 'Sekventsid failist {{filename}}',
    description: 'Analüüsi geenijärjestusi erinevate analüüsiprogrammidega (nt. ITSx, massBLASTer).',
    results: 'Tulemused',
    match: {
      sequence: 'Matched sequence',
      sh: 'UNITE SH',
      taxon: 'SH taxon name',
      distance: 'Distance',
      distanceTooltip: 'Minimum distance to the closest SH',
      compound: 'Compound cluster',
      compoundTaxon: 'Compound cluster taxon name',
      percentage: 'Percentage',
      includeIntoUNITE: 'Include into UNITE SHs',
      threshold: {
        sh: 'UNITE SH ({{threshold}}%)',
        shTaxon: 'SH taxon name ({{threshold}}%)'
      },
      details: {
        blast: {
          evalue: 'E-Value',
          bitscore: 'Blast score'
        }
      }
    },
    status: {
      prerun: 'Alustamata',
      ready: 'Valmis',
      error: 'Vigane',
      running: 'Töös'
    },
    sendToClipboard: {
      all: 'Saada kõik näpitstahvlile',
      identified: 'Saada määratud näpitstahvlile'
    }
  };
});