define("plutof/components/common/ui/control-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="label-control">
      <div class="plutof-label label-control__label">
          {{#if (has-block "label")}}
              {{yield to="label"}}
          {{else}}
              {{@label}}
          {{/if}}
      </div>
  
      {{yield (hash
          toggle=(component "common/ui/-control-label/toggle")
      ) to="controls"}}
  </div>
  
  */
  {
    "id": "J3bN2cDO",
    "block": "{\"symbols\":[\"@label\",\"&default\",\"@namedBlocksInfo\"],\"statements\":[[10,\"div\"],[14,0,\"label-control\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"plutof-label label-control__label\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[32,3],\"label\",false],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[18,2,[[30,[36,0],[\"label\"],null]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"            \"],[1,[32,1]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\\n    \"],[18,2,[[30,[36,0],[\"controls\"],null],[30,[36,4],null,[[\"toggle\"],[[30,[36,3],[\"common/ui/-control-label/toggle\"],null]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-named-block-invocation\",\"-has-block\",\"if\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "plutof/components/common/ui/control-label.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});