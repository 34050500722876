define("plutof/misc/abstract", ["exports", "ember-data", "ember-inflector", "plutof/config/environment", "plutof/utils/structures"], function (_exports, _emberData, _emberInflector, _environment, _structures) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.EMPTY_PROMISE = void 0;
  _exports.buildRecordURL = buildRecordURL;
  _exports.construct_request = construct_request;
  _exports.decimal_round = decimal_round;
  _exports.decodeURL = decodeURL;
  _exports.ensure_paths_loaded = ensure_paths_loaded;
  _exports.isNumeric = isNumeric;
  _exports.lift_to_undefined = lift_to_undefined;
  _exports.mergeObjects = mergeObjects;
  _exports.merge_objects_rec = merge_objects_rec;
  _exports.nonstrictGet = nonstrictGet;
  _exports.range = range;
  _exports.safe_set = safe_set;
  _exports.sum = sum;
  _exports.wrap_as_promise = wrap_as_promise;
  _exports.wrap_as_promise_array = wrap_as_promise_array;
  // General functions not really belonging anywhere else

  // Returns a promise that resolves to <value>
  function wrap_as_promise(value) {
    return new Ember.RSVP.Promise(function (resolve, reject) {
      resolve(value);
    });
  }
  function wrap_as_promise_array(values) {
    return _emberData.default.PromiseArray.create({
      promise: wrap_as_promise(values)
    });
  }
  var EMPTY_PROMISE = _exports.EMPTY_PROMISE = wrap_as_promise(null);
  function lift_to_undefined(f, default_return_value) {
    return function () {
      var args = Array.prototype.slice.apply(arguments);
      if (args.any(Ember.isNone)) {
        return default_return_value;
      } else {
        return f.apply(undefined, args);
      }
    };
  }

  // TODO: Shoot it between the eyes, can be done more consisely
  // ([['a', 'b'], ['a', 'c'], ['d']]) -> {a : {'b': {}, 'c': {}}, d: {}}
  function construct_paths(path_lists) {
    if (Ember.isEmpty(path_lists) || path_lists.every(Ember.isEmpty)) {
      return {};
    }
    var object = {};
    var top_layer = (0, _structures.group)(function (path_list) {
      return path_list[0];
    }, path_lists);
    Object.keys(top_layer).forEach(function (key) {
      // object[key] = construct_paths(list_tail(top_layer[key]));
      object[key] = construct_paths(top_layer[key].map(_structures.list_tail));
    });
    return object;
  }

  // (record, ['foo.bar', 'foo.baz']) forces full loading of foo
  // (record, ['foo.bar.baz', 'foop.bloop']) forces full loading of foo, foo.bar and foop
  // TODO: Remove. Photobank edit controller actually uses it, need to refactor it first
  function ensure_paths_loaded(record, paths) {
    var internal_paths = paths.map(function (path) {
      return (0, _structures.list_init)(path.split('.'));
    }).reject(Ember.isEmpty);
    var path_tree = construct_paths(internal_paths);
    function load_path_tree(record, tree) {
      if (Ember.isNone(record)) {
        return wrap_as_promise(record);
      }
      return Ember.RSVP.Promise.resolve(record).then(function (record) {
        return Ember.RSVP.all(Object.keys(tree).map(function (key) {
          return load_path_tree(Ember.get(record, key), tree[key]);
        }));
      });
    }
    return load_path_tree(record, path_tree);
  }

  // Compines object fields, right-preference
  // XXX: Shallow!
  function mergeObjects(x, y) {
    var result = {};
    Object.keys(x).forEach(function (prop) {
      result[prop] = x[prop];
    });
    Object.keys(y).forEach(function (prop) {
      result[prop] = y[prop];
    });
    return result;
  }
  function merge_objects_rec(x, y) {
    if (Ember.isNone(x)) {
      return y;
    }
    if (Ember.isNone(y)) {
      return x;
    }
    if (typeof x === 'object' && typeof y === 'object') {
      var result = {};
      Object.keys(x).forEach(function (key) {
        result[key] = Ember.isNone(y[key]) ? x[key] : merge_objects_rec(x[key], y[key]);
      });
      Object.keys(y).forEach(function (key) {
        if (Ember.isNone(result[key])) {
          result[key] = y[key];
        }
      });
      return result;
    }
    return y;
  }

  // Can't set fields on destroyed records. If the view has an observer
  // that sets something after the promise resolves, it can happened that
  // the view is already destroyed at that point. set() then throws an
  // exception
  function safe_set(object, key, value) {
    if (object.get && !object.get('isDestroyed')) {
      object.set(key, value);
    }
  }

  // pre-ember-2.10 Ember.get
  function nonstrictGet(object, key) {
    return key === '' ? object : Ember.get(object, key);
  }
  function construct_request(url, params, allowNone) {
    const tail = Object.keys(params).filter(param => {
      return allowNone || !Ember.isNone(params[param]);
    }).map(param => {
      const value = Ember.isNone(params[param]) ? '' : params[param].toString();
      return param + '=' + encodeURIComponent(value);
    }).join('&');
    return url + '?' + tail;
  }
  function decodeURL(url) {
    const parts = url.split('?');
    const endpoint = parts[0];
    let params = {};
    if (parts.length === 2) {
      parts[1].split('&').map(kvp => kvp.split('=')).forEach(([arg, value]) => {
        params[arg] = value;
      });
    }
    return {
      endpoint: endpoint,
      params: params
    };
  }
  function sum(arr) {
    var s = 0;
    for (var i = 0; i < arr.length; i++) {
      s += arr[i];
    }
    return s;
  }
  function decimal_round(value, places) {
    var shift = Math.pow(10, places);
    var mantissa = Math.round(value * shift);
    return mantissa / shift;
  }

  // http://stackoverflow.com/questions/18082/validate-decimal-numbers-in-javascript-isnumeric?lq=1
  function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  // [start,end)
  function range(start, end, step = 1) {
    var result = [];
    for (let i = start; i < end; i += step) {
      result.push(i);
    }
    return result;
  }
  function buildRecordURL(record) {
    const pluralizedEndPoint = (0, _emberInflector.pluralize)(record.constructor.modelName);
    return `${_environment.default.API_HOST}/${pluralizedEndPoint}/${record.get('id')}/`;
  }
});