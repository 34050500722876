define("plutof/components/common/ui/one-way-checkbox", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Common::CheckBox
      @value={{this.proxy}}
      @disabled={{@disabled}}
      ...attributes
  >
      {{yield}}
  </Common::CheckBox>
  
  */
  {
    "id": "d3EjyE6R",
    "block": "{\"symbols\":[\"@disabled\",\"&attrs\",\"&default\"],\"statements\":[[8,\"common/check-box\",[[17,2]],[[\"@value\",\"@disabled\"],[[32,0,[\"proxy\"]],[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[18,3,null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "plutof/components/common/ui/one-way-checkbox.hbs"
    }
  });
  let OneWayCheckbox = (_dec = Ember.computed('args.value'), (_class = class OneWayCheckbox extends _component.default {
    get proxy() {
      return this.args.value;
    }
    set proxy(value) {
      this.args.update(value);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "proxy", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "proxy"), _class.prototype)), _class));
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, OneWayCheckbox);
});