define("plutof/components/annotation/-link", ["exports", "@glimmer/component", "plutof/utils/modules", "ember-inflector"], function (_exports, _component, _modules, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <a
      {{on "click" this.open}}
      href={{this.data.url}}
      class={{if @highlight "unreviewed-annotation-link"}}
  >
      {{#if (has-block)}}
          {{yield}}
      {{else}}
          {{this.data.name}}
      {{/if}}
  </a>
  
  */
  {
    "id": "5263p4Ci",
    "block": "{\"symbols\":[\"&default\",\"@highlight\",\"@namedBlocksInfo\"],\"statements\":[[11,\"a\"],[16,6,[32,0,[\"data\",\"url\"]]],[16,0,[30,[36,0],[[32,2],\"unreviewed-annotation-link\"],null]],[4,[38,1],[\"click\",[32,0,[\"open\"]]],null],[12],[2,\"\\n\"],[6,[37,0],[[30,[36,2],[[32,3],\"default\",[27,[32,1]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[32,0,[\"data\",\"name\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"on\",\"-has-block\"]}",
    "meta": {
      "moduleName": "plutof/components/annotation/-link.hbs"
    }
  });
  let PendingAnnotationLink = (_dec = Ember.inject.service, _dec2 = Ember.computed('args.annotation'), _dec3 = Ember._action, (_class = class PendingAnnotationLink extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "router", _descriptor, this);
    }
    get data() {
      const {
        annotation
      } = this.args;
      const payload = annotation.annotation;
      if (payload.type === 'client-annotation') {
        const module = _modules.default[payload.record.content_type];
        const url = this.router.urlFor(module.routes.annotations.view, payload.record.id, annotation.id);
        return {
          name: payload.record.name,
          url
        };
      } else if (payload.type === 'bulk-annotation') {
        const module = _modules.default[payload.records.content_type];
        const name = `${payload.records.count} ${(0, _emberInflector.pluralize)(module.name).toLowerCase()}`;
        const url = this.router.urlFor('annotations.bulk.view', annotation.id);
        return {
          name,
          url
        };
      } else {
        throw new Error(`Logic error: unhandled annotation type ${payload.type}`);
      }
    }
    open(event) {
      event.preventDefault();
      this.router.transitionTo(this.data.url);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "data", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "data"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "open", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "open"), _class.prototype)), _class));
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PendingAnnotationLink);
});