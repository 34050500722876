define("plutof/templates/components/load-more", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "qO7LQZdn",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"span\"],[12],[2,\"\\n    \"],[18,1,null],[2,\"\\n\\n\"],[6,[37,6],[[35,0]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,6],[[35,7]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"span\"],[14,0,\"loading-icon\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[6,[37,6],[[35,5]],null,[[\"default\"],[{\"statements\":[[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[11,\"button\"],[16,\"disabled\",[34,0]],[24,0,\"btn btn-xs btn-link\"],[4,[38,1],[[32,0],\"more\"],null],[12],[2,\"\\n                    \"],[1,[30,[36,2],[\"General.loadMore\"],null]],[2,\"\\n                \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"isLoading\",\"action\",\"i18n-t\",\"isLastPage\",\"unless\",\"showControls\",\"if\",\"showInterface\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/load-more.hbs"
    }
  });
});