define("plutof/models/taxonoccurrence/referencebased/occurrence", ["exports", "@ember-data/model", "plutof/mixins/display-name-from-value", "plutof/models/taxonoccurrence/taxonoccurrence-model"], function (_exports, _model, _displayNameFromValue, _taxonoccurrenceModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ReferenceBasedOccurrence = (_dec = (0, _displayNameFromValue.displayName)('occurrence_assessment'), _dec2 = (0, _displayNameFromValue.displayName)('identification_assessment'), _dec3 = (0, _model.belongsTo)('reference/reference'), _dec4 = (0, _model.belongsTo)('taxonoccurrence/referencebased/speciesstatus', {
    own: true
  }), _dec5 = (0, _model.belongsTo)('taxonomy/taxonnode'), _dec6 = (0, _model.attr)('boolean'), _dec7 = (0, _model.attr)('boolean'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('string'), _dec11 = (0, _model.attr)('string'), _dec12 = (0, _model.attr)('string'), _dec13 = (0, _model.attr)('string'), _dec14 = (0, _model.attr)('string'), _dec15 = (0, _model.attr)('string'), _dec16 = (0, _model.attr)('string'), _dec17 = (0, _model.attr)('string'), _dec18 = Ember.computed.reads('taxon_name'), (_class = class ReferenceBasedOccurrence extends _taxonoccurrenceModel.default.extend(_displayNameFromValue.DisplayNameMixin) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "optionMixinModelName", 'taxonoccurrence.referencebased.occurrence');
      _initializerDefineProperty(this, "occurrenceAssessmentDisplay", _descriptor, this);
      _initializerDefineProperty(this, "idAssessmentDisplay", _descriptor2, this);
      _initializerDefineProperty(this, "reference", _descriptor3, this);
      _initializerDefineProperty(this, "species_status", _descriptor4, this);
      _initializerDefineProperty(this, "taxon_node", _descriptor5, this);
      _initializerDefineProperty(this, "first_occurrence", _descriptor6, this);
      _initializerDefineProperty(this, "misspelling", _descriptor7, this);
      _initializerDefineProperty(this, "identification_assessment", _descriptor8, this);
      _initializerDefineProperty(this, "identification_comment", _descriptor9, this);
      _initializerDefineProperty(this, "occurrence_assessment", _descriptor10, this);
      _initializerDefineProperty(this, "occurrence_comment", _descriptor11, this);
      _initializerDefineProperty(this, "earliest_date", _descriptor12, this);
      _initializerDefineProperty(this, "latest_date", _descriptor13, this);
      _initializerDefineProperty(this, "new_name", _descriptor14, this);
      _initializerDefineProperty(this, "pages", _descriptor15, this);
      _initializerDefineProperty(this, "remarks", _descriptor16, this);
      _initializerDefineProperty(this, "taxon_name", _descriptor17, this);
      _initializerDefineProperty(this, "representation", _descriptor18, this);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "occurrenceAssessmentDisplay", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "idAssessmentDisplay", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "reference", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "species_status", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "taxon_node", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "first_occurrence", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "misspelling", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "identification_assessment", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "identification_comment", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "occurrence_assessment", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "occurrence_comment", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "earliest_date", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "latest_date", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "new_name", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "pages", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "remarks", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "taxon_name", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "representation", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  var _default = _exports.default = ReferenceBasedOccurrence;
});