define("plutof/routes/datacite/add", ["exports", "plutof/controllers/publishing/utils", "plutof/components/datacite/edit", "plutof/utils/routes"], function (_exports, _utils, _edit, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class DataciteAddRoute extends _routes.AddRoute {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "defaultReturnRoute", 'datacite.index');
      _defineProperty(this, "title", 'Publishing.newDOI');
    }
    async beforeModel(transition) {
      super.beforeModel(...arguments);
      const allowed = await (0, _utils.userIsAllowedToRegisterDOIs)(this.store);
      if (!allowed) {
        transition.abort();
        this.transitionTo('/not-found');
      }
    }
    async model() {
      if (this.get('controller.routeHasBeenLoaded')) {
        return undefined;
      }
      const doi = await _edit.DataciteEditModel.create(this.store);
      return {
        doi
      };
    }
  }
  var _default = _exports.default = DataciteAddRoute;
});