define("plutof/helpers/in-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.helper(function ([list, value]) {
    if (!list) {
      return false;
    }
    return list.includes(value);
  });
});