define("plutof/translations/est/data-quality", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    warnings: {
      taxonRank: 'Takson pole määratud liigi tasemeni.',
      geom: 'Puuduvad koordinaadid.',
      timespanAfterNow: 'Alguskuupäev ({{date}}) on tulevikus.',
      timespanAfterCreated: 'Algukuupäev ({{timespanBegin}}) on hilisem kirje sisestuskuupäevast ({{createdAt}}).',
      timespanMissing: 'Alguskuupäev puudub.',
      gatheringAgents: 'Puuduvad kogujad.',
      country: 'Riik on täpsustamata.',
      individualCount: 'Suur isendite arv ({{count}}).'
    },
    general: {
      count: 'Arv'
    }
  };
});