define("plutof/components/import/utils", ["exports", "plutof/models/contenttype", "plutof/misc/clipboard"], function (_exports, _contenttype, _clipboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.importModel = importModel;
  _exports.sendToClipboard = sendToClipboard;
  async function sendToClipboard(confirmationDialog, ajax, process) {
    const recordModule = process.moduleAttributes.recordModule;
    const dialog = confirmationDialog.CLIPBOARD_APPEND(recordModule.clipboard.id);
    const option = await confirmationDialog.selectOption(dialog);
    if (option === 'cancel') {
      return;
    }
    if (option === 'overwrite') {
      await (0, _clipboard.clearClipboard)(ajax, recordModule.clipboard.id);
    }
    await ajax.request(recordModule.clipboard.url, {
      type: 'POST',
      processData: false,
      contentType: 'application/json; charset=UTF-8',
      data: JSON.stringify({
        import_process: process.id
      })
    });
  }
  function importModel(model) {
    if (model === 'agent/collection') {
      return 'agent/organization';
    }
    if (model === 'users/user') {
      return 'auth/user';
    }
    return (0, _contenttype.shortModelName)(model);
  }
});