define("plutof/components/related-objects/-tabs/transaction-objects", ["exports", "@glimmer/component", "ember-concurrency", "plutof/config/environment", "plutof/components/transaction/utils", "plutof/misc/abstract", "plutof/utils/pagination"], function (_exports, _component, _emberConcurrency, _environment, _utils, _abstract, _pagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{on-change this.reloadObjects @transaction}}
  
  <@tabGroup.tab
      @name={{@name}}
      class="related-objects-tab"
      data-test="RelatedObjects::Tabs::TransactionObjects"
      data-test-loaded={{not this.pagination.isLoading}}
      data-test-tab-name={{@name}}
  >
      <@tabGroup.header @name={{@name}}>
          <span data-test="tab-name">
              {{@title}}
          </span>
  
          {{#if this.objectCount}}
              <span class="count-badge" data-test="tab-count">
                  {{this.objectCount}}
              </span>
          {{/if}}
      </@tabGroup.header>
  
      {{#if @enableClipboard}}
          <div class="related-objects-tab__controls">
              <RelatedObjects::ClipboardButtons
                  @pagination={{this.pagination}}
                  @clipboard={{this.itemType.clipboard}}
                  @clipboardQuery={{this.clipboardQuery}}
                  @disabled={{this.isEmpty}} />
          </div>
      {{/if}}
  
      <@content
          @pagination={{this.pagination}}
          @filterProperty={{@filter}}
          @transaction={{@transaction}}
          @accessRights={{@accessRights}}
          @disableControls={{@disableControls}} />
  </@tabGroup.tab>
  
  */
  {
    "id": "VPmYxky0",
    "block": "{\"symbols\":[\"@transaction\",\"@tabGroup\",\"@name\",\"@title\",\"@enableClipboard\",\"@content\",\"@filter\",\"@accessRights\",\"@disableControls\"],\"statements\":[[1,[30,[36,0],[[32,0,[\"reloadObjects\"]],[32,1]],null]],[2,\"\\n\\n\"],[8,[32,2,[\"tab\"]],[[24,0,\"related-objects-tab\"],[24,\"data-test\",\"RelatedObjects::Tabs::TransactionObjects\"],[16,\"data-test-loaded\",[30,[36,1],[[32,0,[\"pagination\",\"isLoading\"]]],null]],[16,\"data-test-tab-name\",[32,3]]],[[\"@name\"],[[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"header\"]],[],[[\"@name\"],[[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"span\"],[14,\"data-test\",\"tab-name\"],[12],[2,\"\\n            \"],[1,[32,4]],[2,\"\\n        \"],[13],[2,\"\\n\\n\"],[6,[37,2],[[32,0,[\"objectCount\"]]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"span\"],[14,0,\"count-badge\"],[14,\"data-test\",\"tab-count\"],[12],[2,\"\\n                \"],[1,[32,0,[\"objectCount\"]]],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[6,[37,2],[[32,5]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"related-objects-tab__controls\"],[12],[2,\"\\n            \"],[8,\"related-objects/clipboard-buttons\",[],[[\"@pagination\",\"@clipboard\",\"@clipboardQuery\",\"@disabled\"],[[32,0,[\"pagination\"]],[32,0,[\"itemType\",\"clipboard\"]],[32,0,[\"clipboardQuery\"]],[32,0,[\"isEmpty\"]]]],null],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,[32,6],[],[[\"@pagination\",\"@filterProperty\",\"@transaction\",\"@accessRights\",\"@disableControls\"],[[32,0,[\"pagination\"]],[32,7],[32,1],[32,8],[32,9]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on-change\",\"not\",\"if\"]}",
    "meta": {
      "moduleName": "plutof/components/related-objects/-tabs/transaction-objects.hbs"
    }
  });
  const paginateItemsMixin = Ember.Object.create({
    constructQuery(number) {
      let query = Object.assign({
        'page[number]': number,
        'page[size]': this.pageSize
      }, this.filters);
      return query;
    },
    loadPage(number) {
      const query = this.constructQuery(number);
      const url = (0, _abstract.construct_request)(this.endpoint, query);
      return this.ajax.request(url);
    }
  });
  function paginateItems(ajax, transactionId, endpoint, filters) {
    const url = `${_environment.default.API_HOST}/transaction/transactions/${transactionId}/${endpoint}/`;
    const mixins = [paginateItemsMixin];
    return (0, _pagination.paginateJSONAPI)(ajax, url, {
      mixins,
      filters
    });
  }
  let RelatedObjectsTrasnactionObjects = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = (0, _emberConcurrency.task)({
    restartable: true
  }), _dec6 = Ember.computed.reads('updatePagination.last.value'), (_class = class RelatedObjectsTrasnactionObjects extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "objectCount", _descriptor2, this);
      _initializerDefineProperty(this, "itemType", _descriptor3, this);
      _defineProperty(this, "defaultOrdering", '-name');
      _initializerDefineProperty(this, "fetchItemType", _descriptor4, this);
      _initializerDefineProperty(this, "updatePagination", _descriptor5, this);
      _initializerDefineProperty(this, "pagination", _descriptor6, this);
      this.updatePagination.perform();
      this.fetchItemType.perform();
    }
    get clipboardQuery() {
      return {
        dynamic_object_list: `transaction=${this.args.transaction.id}`
      };
    }
    reloadObjects() {
      this.updatePagination.perform();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "objectCount", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "itemType", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "fetchItemType", [_emberConcurrency.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        this.itemType = yield (0, _utils.getTransactionItemType)(this.args.transaction.item_type);
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "reloadObjects", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "reloadObjects"), _class.prototype), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "updatePagination", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        const pagination = yield paginateItems(this.ajax, this.args.transaction.id, this.args.name, {
          ordering: this.defaultOrdering
        });

        // Tab-switching hack
        this.args.loaded(this.args.name, pagination);
        this.objectCount = pagination.objectCount;
        return pagination;
      };
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "pagination", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, RelatedObjectsTrasnactionObjects);
});