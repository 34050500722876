define("plutof/transforms/ambiguous-datetime", ["exports", "@ember-data/serializer/transform", "moment"], function (_exports, _transform, moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const COMPLETE_DATETIME = /^\d{4}-\d{1,2}-\d{1,2} \d{1,2}:\d{1,2}$/;
  class AmbiguousDatetime extends _transform.default {
    deserialize(serialized) {
      return serialized;
    }
    serialize(datetime) {
      if (!COMPLETE_DATETIME.test(datetime)) {
        // Don't do anything to ambiguous values
        return datetime;
      }

      // But normalize when everything's available
      return moment(datetime).toISOString();
    }
  }
  var _default = _exports.default = AmbiguousDatetime;
});