define("plutof/translations/est/projects", ["exports", "plutof/translations/helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    infoTitle: (0, _helpers.alias)('General.Project'),
    area: 'Ala',
    newP: 'Uus projekt',
    editP: 'Muuda projekti',
    project: 'Projekt',
    samplingArea: 'Proovialad',
    samplingAreaOnMap: 'Proovialad kaardil',
    samplePresented: 'Sellel vaatlusel on registreeritud proovid, mille tõttu on kaardi kasutus keelatud.',
    predefinedAreas: 'Eeldefineeritud Alad',
    areaOnMap: 'Prooviala',
    includeSubAreasOnMap: 'Kaasa alam alad',
    predefinedAreasOnMap: 'Eeldefineeritud alad',
    samplingAreasOnMap: 'Proovialad',
    paginatedAreasOnMap: 'Loendi proovialad',
    allPointsOnMap: 'Punktid',
    searchTitle: 'Otsi projekte',
    noDefaultStudy: 'Vaikimisi projekt pole sätestatud. Projekti saab seada kasutaja profiili alt.',
    childProjects: 'Alam-projektid',
    mainforms: 'Lubatud vormid',
    permissionsChangeWarning: 'Changing visibility will also affect all of the project\'s occurrences, except those that belong in a Collection',
    managingProjects: 'Projektide haldamine',
    areaHierarchy: {
      title: 'Proovialade hierarhia',
      notInProject: 'projektist väljas'
    },
    placeholders: {
      searchHint: 'Otsi nime järgi'
    },
    actions: {
      editWorkgroup: 'Muuda',
      newExperiment: 'Uus',
      exportToJSON: 'JSON'
    },
    fields: {
      name: 'Nimi'
    }
  };
});