define("plutof/components/analysis/view/matching-content", ["exports", "@ember-decorators/component", "plutof/controllers/analysis/utils", "plutof/misc/clipboard", "plutof/utils/has-many"], function (_exports, _component, _utils, _clipboard, _hasMany) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class2, _class3, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const HasManyPagination = (0, _hasMany.HasManyViewMixin)('analysis/sequence-matching-run-item', {
    parentField: 'run',
    extraFilters: {
      ordering: 'ordering'
    }
  });

  // XXX: Ideally, we'd have this code only in Entry.canAddDetermination, but this would require
  // selectedMatchingsLackingDetermination to depend on entries, not items, which is overall a worse
  // design. At least this is contained
  //
  // If we just enable all checkboxes, can do it properly
  function canAddDetermination(item) {
    return item.is_matched && !item.has_determination;
  }
  let Entry = (_dec = Ember.computed(), _dec2 = Ember.computed('item.{is_matched,determination.id}'), (_class = class Entry extends Ember.Object {
    get selected() {
      return this.parent.isItemSelected(this.item);
    }
    set selected(value) {
      if (value) {
        this.parent.selectItem(this.item);
      } else {
        this.parent.deselectItem(this.item);
      }
      return value;
    }
    get canAddDetermination() {
      return canAddDetermination(this.item);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "selected", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "selected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "canAddDetermination", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "canAddDetermination"), _class.prototype)), _class));
  let MatchingContent = (_dec3 = (0, _component.classNames)('analysis-matching-source-view'), _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.computed('pagination.objects.[]'), _dec8 = Ember.computed('selectedItems.@each.determination'), _dec9 = Ember.computed.empty('selectedMatchingsLackingDetermination'), _dec10 = Ember.computed.empty('entries'), _dec11 = Ember.computed('run.status', 'entries.[]'), _dec12 = Ember.computed('entries.@each.selected'), _dec13 = Ember.computed('entries.@each.canAddDetermination'), _dec14 = Ember.computed.equal('run.status', 'ready'), _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, _dec3(_class2 = (_class3 = class MatchingContent extends Ember.Component.extend(HasManyPagination) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "confirmationDialog", _descriptor2, this);
      _initializerDefineProperty(this, "router", _descriptor3, this);
      _initializerDefineProperty(this, "cannotAddDeterminations", _descriptor4, this);
      _initializerDefineProperty(this, "cannotSendToClipboard", _descriptor5, this);
      _initializerDefineProperty(this, "enableDeterminations", _descriptor6, this);
    }
    init() {
      super.init(...arguments);

      // Preserves selection between pages
      this.set('selectedItems', []);
      this.set('selectedItemIDs', {});
      // Ember objects are not GC-d automatically, so we keep track of them
      this.set('createdEntries', []);
    }
    isItemSelected(item) {
      return Boolean(this.selectedItemIDs[item.id]);
    }
    selectItem(item) {
      if (this.isItemSelected(item)) {
        return;
      }
      this.selectedItemIDs[item.id] = true;
      this.selectedItems.pushObject(item);
    }
    deselectItem(item) {
      this.selectedItemIDs[item.id] = false;
      this.selectedItems.removeObject(item);
    }
    extractDetails(item) {
      if (!this.run.type.details) {
        return [];
      }
      const resultDetails = item.result_details || {};
      return this.run.type.details.map(field => field.extract(resultDetails));
    }
    createEntry(item) {
      const entry = Entry.create({
        parent: this,
        item,
        details: this.extractDetails(item)
      });
      this.createdEntries.pushObject(entry);
      return entry;
    }
    get entries() {
      const items = this.get('pagination.objects');
      if (Ember.isNone(items)) {
        return [];
      }
      return items.map(item => this.createEntry(item));
    }
    get selectedMatchingsLackingDetermination() {
      return this.selectedItems.filter(canAddDetermination);
    }
    get cannnotDownload() {
      return this.run.status !== 'ready' || this.entries.length === 0;
    }
    get pageSelected() {
      return this.entries.every(entry => entry.selected);
    }
    set pageSelected(value) {
      this.entries.forEach(entry => entry.set('selected', value));
      return value;
    }
    get pageSelectDisabled() {
      return this.entries.every(entry => !entry.canAddDetermination);
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.createdEntries.forEach(entry => entry.destroy());
    }
    addDeterminations() {
      const items = this.selectedMatchingsLackingDetermination;
      return Ember.RSVP.all(items.map(item => (0, _utils.addSHDetermination)(this.store, this.ajax, item)));
    }
    sendToClipboard(onlyIdentified) {
      const dialog = this.confirmationDialog.CLIPBOARD_APPEND('sequences');
      return this.confirmationDialog.selectOption(dialog).then(async option => {
        if (option === 'cancel') {
          return;
        }
        if (option === 'overwrite') {
          await (0, _clipboard.clearClipboard)(this.ajax, 'sequences');
        }
        return (0, _utils.sendItemsToClipboard)(this.ajax, this.run, {
          onlyIdentified
        }).then(() => {
          this.router.transitionTo('clipboard.sequence');
        });
      });
    }
    download() {
      (0, _utils.downloadRunItems)(this.ajax, this.run);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class3.prototype, "ajax", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class3.prototype, "confirmationDialog", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class3.prototype, "router", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class3.prototype, "entries", [_dec7], Object.getOwnPropertyDescriptor(_class3.prototype, "entries"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "selectedMatchingsLackingDetermination", [_dec8], Object.getOwnPropertyDescriptor(_class3.prototype, "selectedMatchingsLackingDetermination"), _class3.prototype), _descriptor4 = _applyDecoratedDescriptor(_class3.prototype, "cannotAddDeterminations", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class3.prototype, "cannotSendToClipboard", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class3.prototype, "cannnotDownload", [_dec11], Object.getOwnPropertyDescriptor(_class3.prototype, "cannnotDownload"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "pageSelected", [_dec12], Object.getOwnPropertyDescriptor(_class3.prototype, "pageSelected"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "pageSelectDisabled", [_dec13], Object.getOwnPropertyDescriptor(_class3.prototype, "pageSelectDisabled"), _class3.prototype), _descriptor6 = _applyDecoratedDescriptor(_class3.prototype, "enableDeterminations", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class3.prototype, "addDeterminations", [_dec15], Object.getOwnPropertyDescriptor(_class3.prototype, "addDeterminations"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "sendToClipboard", [_dec16], Object.getOwnPropertyDescriptor(_class3.prototype, "sendToClipboard"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "download", [_dec17], Object.getOwnPropertyDescriptor(_class3.prototype, "download"), _class3.prototype)), _class3)) || _class2);
  var _default = _exports.default = MatchingContent;
});