define("plutof/templates/annotations/created", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "qmNGwIh0",
    "block": "{\"symbols\":[\"translations\",\"Field\",\"annotation\",\"fields\"],\"statements\":[[8,\"navbar/list\",[],[[\"@icon\",\"@title\",\"@goBack\",\"@pagination\"],[[30,[36,0],[\"annotation\"],null],[30,[36,1],[\"annotation.routes.created.title\"],null],[30,[36,2],[\"goBack\"],null],[32,0,[\"model\",\"annotations\"]]]],null],[2,\"\\n\\n\"],[8,\"i18n/model-strings\",[],[[\"@model\"],[\"annotation.annotation\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"tables/list-route\",[],[[\"@records\"],[[32,0,[\"model\",\"annotations\",\"objects\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"annotation/fields\",[],[[\"@field\",\"@annotation\",\"@translations\"],[[32,2],[32,3],[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,[32,4,[\"icon\"]],[],[[],[]],null],[2,\"\\n            \"],[8,[32,4,[\"link\"]],[],[[],[]],null],[2,\"\\n            \"],[8,[32,4,[\"status\"]],[],[[],[]],null],[2,\"\\n            \"],[8,[32,4,[\"moderator\"]],[],[[],[]],null],[2,\"\\n            \"],[8,[32,4,[\"comment\"]],[],[[],[]],null],[2,\"\\n            \"],[8,[32,4,[\"annotatedAt\"]],[],[[],[]],null],[2,\"\\n        \"]],\"parameters\":[4]}]]],[2,\"\\n    \"]],\"parameters\":[2,3]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\",\"route-action\"]}",
    "meta": {
      "moduleName": "plutof/templates/annotations/created.hbs"
    }
  });
});