define("plutof/controllers/study/edit", ["exports", "plutof/controllers/organization/edit", "plutof/mixins/edit-controller", "plutof/utils/notifications"], function (_exports, _edit, _editController, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let StudyEditController = (_dec = Ember.inject.service, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_class = class StudyEditController extends Ember.Controller.extend(_editController.default) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "fileUpload", _descriptor, this);
      _defineProperty(this, "recordsToUnload", [{
        record: 'study'
      }]);
    }
    async uploadNewLogo() {
      const fileURL = await (0, _edit.uploadLogo)(this.store, this.fileUpload, this.newLogo);
      this.model.study.set('project_image_url', fileURL);
      this.set('newLogo', null);
    }
    async _save() {
      if (!Ember.isNone(this.newLogo)) {
        await this.uploadNewLogo();
      }
      await this.model.study.save();
      if (Ember.isPresent(this.model.workgroupData)) {
        // Should really be in add controller, but :shrug:
        if (!this.model.workgroupData.group) {
          const group = await this.model.study.managing_group;
          this.model.workgroupData.changeGroup(group);
        }
        await this.model.workgroupData.save();
      }
      const removed = await Ember.RSVP.all(this.objectsToDelete.invoke('destroyRecord')).then(() => {
        this.set('objectsToDelete', []);
      });
      function saveHasMany(records) {
        return Ember.RSVP.all(records.filterBy('hasDirtyAttributes').invoke('save'));
      }
      return Ember.RSVP.all([removed, saveHasMany(this.model.projectPersons), saveHasMany(this.model.projectAreas), this.model.identifiers.save(), this.model.itemData.save(this.model.study, this.model.accessRights)]);
    }
    addPerson(person, role) {
      const projectPerson = this.store.createRecord('study/studyperson', {
        study: this.model.study,
        person,
        role
      });
      this.model.projectPersons.pushObject(projectPerson);
    }
    removePerson(projectPerson) {
      this.model.projectPersons.removeObject(projectPerson);
      this.objectsToDelete.pushObject(projectPerson);
    }

    // TODO: Add uniqueness check
    addProjectArea(area) {
      if (!area) {
        return;
      }
      const projectArea = this.store.createRecord('sample/project-area', {
        project: this.model.study,
        area,
        /*
         * The following properties mirror what back-end sends to
         * project-area bulk request. These properties are necessary to
         * represent new project-areas in writing, and on the map.
         */
        area_name: area.get('representation'),
        area_id: area.get('id'),
        commune: area.get('commune'),
        country_name: area.get('country.representation'),
        district: area.get('district'),
        geom: area.get('geom'),
        locality_text: area.get('locality_text'),
        restricted: area.get('restricted'),
        timespan_begin: area.get('timespan_begin'),
        timespan_end: area.get('timespan_end')
      });
      this.model.projectAreas.pushObject(projectArea);
    }
    removeProjectArea(projectArea) {
      this.model.projectAreas.removeObject(projectArea);
      this.objectsToDelete.pushObject(projectArea);
    }
    save() {
      const saved = this._save().then(() => {
        this.send('goBack');
        this.set('routeHasBeenLoaded', false);
      });
      return (0, _notifications.notifyProcess)(saved, this.i18n);
    }
    useNewLogo(logo) {
      this.model.study.set('project_image_url', null);
      this.set('newLogo', logo);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fileUpload", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "addPerson", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "addPerson"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removePerson", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "removePerson"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addProjectArea", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "addProjectArea"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeProjectArea", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "removeProjectArea"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "useNewLogo", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "useNewLogo"), _class.prototype)), _class));
  var _default = _exports.default = StudyEditController;
});