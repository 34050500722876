define("plutof/components/chart/scatter-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.MAX_RECT_HEIGHT = _exports.DEFAULT_LABELS = void 0;
  _exports.getElementID = getElementID;
  _exports.getTooltip = getTooltip;
  _exports.getUniqueIDs = getUniqueIDs;
  _exports.getUniqueLabelName = getUniqueLabelName;
  _exports.getYears = getYears;
  _exports.getYearsUnique = getYearsUnique;
  const DEFAULT_LABELS = _exports.DEFAULT_LABELS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const MAX_RECT_HEIGHT = _exports.MAX_RECT_HEIGHT = 15;
  function getTooltip(tooltipItem, datasets) {
    return datasets[tooltipItem.datasetIndex].data.find(datum => {
      return datum.x === tooltipItem.xLabel && datum.y === tooltipItem.yLabel;
    });
  }
  function getYears(data) {
    return Object.keys(data).map(k => k.split('_')[1]);
  }
  function getYearsUnique(data) {
    return getYears(data).uniq();
  }
  function getElementID(key) {
    return key.split('_')[0];
  }
  function getUniqueIDs(keys) {
    return keys.map(key => getElementID(key)).uniq();
  }
  function getUniqueLabelName(data) {
    // [aves, aves, aves] => aves
    return Object.keys(data).map(k => data[k].name).uniq();
  }
  class ScatterChart extends Ember.Component {}
  var _default = _exports.default = ScatterChart;
});