define("plutof/translations/eng/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    mainforms: 'Forms',
    defaultValues: 'Default values',
    workgroups: 'Workgroups',
    changePassword: 'Change password',
    updateProfile: 'Update profile',
    oldPw: 'Old password',
    newPw: 'New Password',
    repeatPw: 'Repeat password',
    noMatch: 'New passwords do not match.',
    clickToManage: 'Click to manage a group.',
    clickToSee: 'Click to see detailed view',
    clickForAccess: 'Click to see access rights',
    join: 'Join',
    create: 'Create',
    manage: 'Manage',
    newUser: 'New user(s)',
    name: 'Name',
    rights: 'Rights',
    status: 'Status',
    sendRequest: 'Send request',
    registrationRequests: 'Registration requests',
    approve: 'Approve',
    deny: 'Deny',
    user: 'User',
    defaultAccess: 'Default Access Rights',
    limits: 'Limits',
    noForms: 'You have no input forms to manage.',
    noGroups: 'You are not in any workgroups.',
    admin: 'Admin',
    groups: {
      list: 'My groups',
      create: 'Create group',
      join: 'Join group',
      pending: 'Pending',
      filterMembers: 'Filter members',
      addMember: 'Add member',
      requestsPending: '{{count}} users awaiting approval',
      reason: 'Reason for joining the workgroup',
      leave: {
        button: 'Leave group',
        title: 'Leave group?',
        content: 'Do you want to leave {{group}}?'
      },
      email: {
        showDialog: 'E-mail',
        title: 'Write an e-mail to all group members.',
        subject: 'Subject',
        content: 'Message',
        send: 'Send'
      },
      fields: {
        user: 'User',
        group: 'Group',
        rights: 'Observation moderation',
        status: 'Status in workgroup',
        acceptedBy: 'Accepted by',
        joinedAt: 'Join date'
      }
    },
    classifications: {
      habitatCl: 'Default Habitat Classifications',
      habitatToolT: 'Habitat Classifications used in PlutoF. If unchecked - field is not displayed',
      taxonCl: 'Default Taxon Classifications',
      taxonToolT: 'Taxon Classifications used in PlutoF. If removed - classification is not found in Taxon fields.'
    },
    notifications: {
      label: 'Users Notifications',
      toolT: 'Get e-mail notifications of selected events.',
      systemNotifications: 'System notifications'
    },
    identities: {
      title: 'Identities',
      listLabel: 'Link account',
      disconnect: 'Disconnect'
    }
  };
});