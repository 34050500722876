define("plutof/components/information/article/-heading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <h4>
      {{@heading}}
  </h4>
  
  */
  {
    "id": "NhSeMUua",
    "block": "{\"symbols\":[\"@heading\"],\"statements\":[[10,\"h4\"],[12],[2,\"\\n    \"],[1,[32,1]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "plutof/components/information/article/-heading.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});