define("plutof/templates/components/organization-label-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "H01KIjhm",
    "block": "{\"symbols\":[\"t\",\"info\"],\"statements\":[[6,[37,7],null,null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[31,[[34,1]]]],[12],[2,\"\\n        \"],[1,[35,2,[\"labelText\"]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"span\"],[15,1,[31,[[32,1,[\"tid\"]]]]],[14,0,\"wrapper-tooltip\"],[12],[2,\"\\n        \"],[1,[30,[36,3],[[35,2,[\"tooltipText\"]]],null]],[2,\" \"],[10,\"br\"],[12],[13],[2,\"\\n\\n        \"],[1,[30,[36,4],[\"Agents.organizationTooltip\"],null]],[2,\"\\n\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,2,[\"infoLinks\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,0],null,[[\"infoLink\"],[[32,2,[\"url\"]]]]]],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"info-link\",\"labelClasses\",\"data\",\"break-line\",\"i18n-t\",\"-track-array\",\"each\",\"tooltip-wrapper\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/organization-label-tooltip.hbs"
    }
  });
});