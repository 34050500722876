define("plutof/templates/experiment/dna/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "CH5llaFw",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"route-marker-dna-view\"],[12],[13],[2,\"\\n\\n\"],[8,\"navbar/view\",[],[[\"@icon\",\"@title\",\"@record\",\"@permissions\",\"@editRoute\",\"@deletable\",\"@infoTitle\",\"@infoContent\"],[[30,[36,0],[\"experiment\"],null],[30,[36,1],[\"experiment.dna.routeTitles.view\"],null],[32,0,[\"model\",\"dna\"]],[32,0,[\"model\",\"permissions\"]],\"experiment.dna.edit\",true,[30,[36,1],[\"experiment.general.experiments\"],null],\"information/content/dna-lab/general\"]],null],[2,\"\\n\\n\"],[8,\"experiment/dna/view/general-data\",[],[[\"@dna\"],[[32,0,[\"model\",\"dna\"]]]],null],[2,\"\\n\\n\"],[6,[37,2],[[32,0,[\"model\",\"source\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"view-determinations\",[],[[\"@parentId\",\"@parentCType\",\"@disableEdit\"],[[32,0,[\"model\",\"source\",\"id\"]],[32,0,[\"model\",\"sourceCType\"]],true]],null],[2,\"\\n\\n    \"],[8,\"sample/area-event-view-panel\",[],[[\"@parent\",\"@canModify\",\"@showAreaType\"],[[32,0,[\"model\",\"source\"]],false,false]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,\"experiment/dna/view/pcr-products\",[],[[\"@dna\"],[[32,0,[\"model\",\"dna\"]]]],null],[2,\"\\n\"],[8,\"experiment/dna/view/sequencing-results\",[],[[\"@dna\"],[[32,0,[\"model\",\"dna\"]]]],null],[2,\"\\n\\n\"],[8,\"collection-lab/view/condition-assessments\",[],[[\"@object\",\"@occurrenceType\"],[[32,0,[\"model\",\"dna\"]],\"dna\"]],null],[2,\"\\n\\n\"],[8,\"experiment/dna/view/record-metadata\",[],[[\"@record\"],[[32,0,[\"model\",\"dna\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\",\"if\"]}",
    "meta": {
      "moduleName": "plutof/templates/experiment/dna/view.hbs"
    }
  });
});