define("plutof/components/common/legend", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <legend class="legend">
      {{yield}}
  </legend>
  
  */
  {
    "id": "R6F6aBEC",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"legend\"],[14,0,\"legend\"],[12],[2,\"\\n    \"],[18,1,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "plutof/components/common/legend.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});