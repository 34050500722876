define("plutof/components/clickable-textfield", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  let ClickableTextfield = (_dec = (0, _component.classNameBindings)('classes'), _dec(_class = class ClickableTextfield extends Ember.TextField {
    click() {
      this.clicked();
    }
  }) || _class);
  var _default = _exports.default = ClickableTextfield;
});