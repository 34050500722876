define("plutof/translations/est/observations", ["exports", "plutof/translations/helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    title: 'Vaatlused',
    searchTitle: 'Otsi vaatluseid',
    infoTitle: 'Vaatlused',
    observers: 'Vaatlejad',
    newObservation: 'Uus vaatlus',
    editObservation: 'Muuda vaatlust',
    observation: 'Vaatlus',
    moderate: 'Modereeri',
    parentSamplingArea: 'Ülem ala',
    parentSamplingAreaTooltip: (0, _helpers.alias)('Sample.parentSamplingAreaTooltip'),
    existingSamplingArea: 'Olemasolev ala',
    // "Olemasolev vaatlusala" doesn't quite fit where it goes.
    existingSamplingAreaTooltip: (0, _helpers.alias)('Sample.existingSamplingAreaTooltip'),
    samplingArea: 'Vaatlusala',
    samplingEvent: 'Vaatlussündmus',
    samplingAreaEvent: 'Vaatlusala ja -sündmuse andmed',
    beginDate: 'Vaatluse algus',
    endDate: 'Vaatluse lõpp',
    beginTime: 'Alguse aeg',
    endTime: 'Lõpu aeg',
    cloneToolt: 'Kopeeri rida lisamaks sama taksoni kirje erinevate tunnustega.',
    showTaimeatlas: 'Lisa leht',
    taAcronym: 'TA Akronüüm',
    taSheetCurrent: 'Praegune leht',
    taSheetOld: 'Vana leht',
    addMediaObservation: 'Failipõhine',
    savingRest: 'Salvestab ülejaanud objektid',
    saveProgress: '{{finished}}/{{total}} vaatlust on salvestatud',
    fileToolt: 'Lisa fail antud vaatluse külge.',
    expandedFormToolt: 'Ava laiendatud vorm, kuhu on lisatud harva kasutatavad väljad',
    placeholders: {
      searchHint: 'Otsi taksoninime järgi',
      observationTaxon: 'Taksoni nimi',
      taxon: 'Liik',
      observationSource: 'Allikas',
      observationRemarks: 'Märkused',
      observationCount: 'Arv',
      observationCountToolt: 'Vaadeldud isendite arv<br>0 - liike otsiti, kuid ei leitud;<br>-1 - liike esines, age ei loendatud.',
      commonNameToolt: 'Rahvapärane nimetus. Nt. rasvatihane',
      observationEstbirds: '3+3 kood',
      estbirdsToolt: 'Liiginimetuste  nn. "3+3 koodid" moodustatakse reeglina teadusliku perekonna- ja liiginimetuse kolmest esimesest tähest.',
      substrateTaxon: 'Substraadi taksoni nimi',
      taAcronym: 'Taimeatlase akronüüm',
      determiner: 'Määraja',
      determinerToolt: 'Määraja'
    }
  };
});