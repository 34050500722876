define("plutof/components/auto-complete/scientific-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @taxon.kingdom}}
      (<span class="taxon-ac-scientific-name">{{@taxon.taxon_rank}}</span>
  
      {{i18n-t "Autocomplete.rankInKingdom"}}
  
      <span class="taxon-ac-scientific-name">{{@taxon.kingdom}}</span>)
  {{else}}
      (<span class="taxon-ac-scientific-name">{{@taxon.taxon_rank}}</span>)
  {{/if}}
  
  */
  {
    "id": "X864aVBA",
    "block": "{\"symbols\":[\"@taxon\"],\"statements\":[[6,[37,1],[[32,1,[\"kingdom\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    (\"],[10,\"span\"],[14,0,\"taxon-ac-scientific-name\"],[12],[1,[32,1,[\"taxon_rank\"]]],[13],[2,\"\\n\\n    \"],[1,[30,[36,0],[\"Autocomplete.rankInKingdom\"],null]],[2,\"\\n\\n    \"],[10,\"span\"],[14,0,\"taxon-ac-scientific-name\"],[12],[1,[32,1,[\"kingdom\"]]],[13],[2,\")\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    (\"],[10,\"span\"],[14,0,\"taxon-ac-scientific-name\"],[12],[1,[32,1,[\"taxon_rank\"]]],[13],[2,\")\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"if\"]}",
    "meta": {
      "moduleName": "plutof/components/auto-complete/scientific-name.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});