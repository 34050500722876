define("plutof/components/information/markdown-reader", ["exports", "@glimmer/component", "ember-concurrency", "plutof/helpers/icon"], function (_exports, _component, _emberConcurrency, _icon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.isLoading}}
      <span class="fas fa-sync-alt spinicon"></span>
  {{else}}
      <div class="markdown-content">
          {{this.content}}
      </div>
  {{/if}}
  
  */
  {
    "id": "lHQDPjHN",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,0],[[32,0,[\"isLoading\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"fas fa-sync-alt spinicon\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"markdown-content\"],[12],[2,\"\\n        \"],[1,[32,0,[\"content\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "plutof/components/information/markdown-reader.hbs"
    }
  });
  const markedImage = {
    name: 'image',
    level: 'inline',
    renderer(token) {
      return `<img src=${token.href} class="img-responsive">`;
    }
  };

  // ::icon:project::
  const markedIcon = {
    name: 'icon',
    level: 'inline',
    start(src) {
      return src.indexOf('::icon:');
    },
    tokenizer(src, tokens) {
      const rule = /^::icon:(.+?)::/;
      const match = rule.exec(src);
      if (!match) {
        return;
      }
      const name = match[1];
      const icon = name;
      if (!icon) {
        return;
      }
      return {
        type: 'icon',
        raw: match[0],
        name,
        icon
      };
    },
    renderer(token) {
      return `<span class="${(0, _icon.icon)(token.icon)}"></span>`;
    }
  };

  // ::link-to:area.add:New area::
  function markedLinkTo(router) {
    return {
      name: 'link-to',
      level: 'inline',
      start(src) {
        return src.indexOf('::link-to:');
      },
      tokenizer(src, tokens) {
        const rule = /^::link-to:(.+?):(.+?)::/;
        const match = rule.exec(src);
        if (!match) {
          return;
        }
        const route = match[1];
        const title = match[2];
        if (!route) {
          return;
        }
        const url = router.urlFor(route);
        return {
          type: 'link-to',
          raw: match[0],
          url,
          title
        };
      },
      renderer(token) {
        return `<a href="${token.url}">${token.title}</a>`;
      }
    };
  }
  const markedLink = {
    name: 'link',
    level: 'inline',
    renderer(token) {
      return `<a href=${token.href} target="_blank">${token.text}</a>`;
    }
  };
  function loadContent(ajax, router, marked, path, locale) {
    const localePath = Ember.isNone(locale) ? '' : `${locale.locale}/`;
    const url = `${window.location.origin}/information/${localePath}${path}.md`;
    const linkedTo = markedLinkTo(router);
    marked.use({
      extensions: [markedIcon, markedImage, markedLink, linkedTo],
      headerIds: false,
      mangle: false
    });
    return ajax.raw(url, {
      dataType: 'text'
    }).then(({
      response
    }) => Ember.String.htmlSafe(marked.parse(response)));
  }
  let MarkdownReader = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = (0, _emberConcurrency.task)({
    restartable: true
  }), _dec6 = (0, _emberConcurrency.task)({
    restartable: true
  }), _dec7 = Ember.computed('importMarked.isRunning', 'load.isRunning'), (_class = class MarkdownReader extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "content", _descriptor3, this);
      _initializerDefineProperty(this, "marked", _descriptor4, this);
      _initializerDefineProperty(this, "importMarked", _descriptor5, this);
      _initializerDefineProperty(this, "load", _descriptor6, this);
      this.importMarked.perform().then(() => this.load.perform(this.args.path, this.args.locale));
    }
    get isLoading() {
      return this.importMarked.isRunning || this.load.isRunning;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "content", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "marked", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "importMarked", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        this.marked = yield emberAutoImportDynamic("marked");
      };
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "load", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* (path, locale) {
        this.content = yield loadContent(this.ajax, this.router, this.marked, path, locale);
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "isLoading", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "isLoading"), _class.prototype)), _class));
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, MarkdownReader);
});