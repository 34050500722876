define("plutof/components/auto-complete/bound-model", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{! TODO: Just inline this everywhere? }}
  {{! Replacement for a lot of classic auto-complete uses}}
  {{#async/bind-relation @value as |value update|}}
      <AutoComplete::Model
          @model={{@model}}
          @value={{value}}
          @update={{update}}
          @disabled={{@disabled}}
          @filters={{@filters}}
          @params={{@params}}
          ...attributes />
  {{/async/bind-relation}}
  
  */
  {
    "id": "BIYD+D6y",
    "block": "{\"symbols\":[\"value\",\"update\",\"@model\",\"@disabled\",\"@filters\",\"@params\",\"&attrs\",\"@value\"],\"statements\":[[6,[37,0],[[32,8]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"auto-complete/model\",[[17,7]],[[\"@model\",\"@value\",\"@update\",\"@disabled\",\"@filters\",\"@params\"],[[32,3],[32,1],[32,2],[32,4],[32,5],[32,6]]],null],[2,\"\\n\"]],\"parameters\":[1,2]}]]]],\"hasEval\":false,\"upvars\":[\"async/bind-relation\"]}",
    "meta": {
      "moduleName": "plutof/components/auto-complete/bound-model.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});