define("plutof/routes/sequence/add", ["exports", "plutof/misc/abstract", "plutof/misc/config", "plutof/misc/content_types", "plutof/mixins/add-route", "plutof/mixins/clear_query_params", "plutof/utils/model", "plutof/components/sequence/edit"], function (_exports, _abstract, _config, _content_types, _addRoute, _clear_query_params, _model, _edit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ctypeRequiresDetermination = ctypeRequiresDetermination;
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function ctypeRequiresDetermination(store, ctypeID, typesRequiringNoDetermination) {
    if (Ember.isEmpty(ctypeID)) {
      return (0, _abstract.wrap_as_promise)(true);
    } else {
      return store.findRecord('contenttype', ctypeID).then(ctype => {
        return !typesRequiringNoDetermination.includes(ctype.get('model'));
      });
    }
  }
  let SequenceAddRoute = (_dec = Ember.inject.service, _dec2 = Ember._action, (_class = class SequenceAddRoute extends Ember.Route.extend(_addRoute.default, _clear_query_params.default) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "settings", _descriptor, this);
      _defineProperty(this, "defaultReturnRoute", 'sequence.index');
      _defineProperty(this, "title", 'Sequences.newSequence');
    }
    async processParams(params) {
      let sequence;
      let parent = null;
      let project = null;
      let sampleData;
      if (!Ember.isNone(params.content_type) && !Ember.isNone(params.object_id)) {
        const ctype = await this.store.findRecord('contenttype', params.content_type);
        parent = await (0, _content_types.resolve_generic_link)(this.store, ctype, params.object_id);
      }
      if (Ember.isNone(params.project_id)) {
        project = await this.settings.wait().then(settings => settings.default_study);
      } else {
        project = await this.store.findRecord('study/study', params.project_id);
      }
      const samplingData = await (0, _model.getSamplingDataForParams)(this.store, params);
      sampleData = samplingData.formData;
      let form;
      if (Ember.isNone(params.mainform_id)) {
        form = await this.settings.wait().then(settings => settings.sequence_form);
      } else {
        form = await this.store.findRecord('measurement/mainform', params.mainform_id);
      }
      sequence = this.store.createRecord('taxonoccurrence/sequence/sequence', {
        project,
        mainform: form,
        is_public: await this.settings.wait().then(settings => settings.access_view === 'PUBLIC')
      });
      return [sequence, parent, sampleData];
    }
    async model(params = {}) {
      // XXX: Is this used?
      if (this.get('controller.routeHasBeenLoaded')) {
        return undefined;
      }
      const [sequence, parent, sampleData] = await this.processParams(params || {});
      const requireDetermination = await ctypeRequiresDetermination(this.store, params.content_type, _config.default.Sequence.TARGETS_REQUIRING_NO_DETERMINATION);
      const sequenceData = await (0, _edit.createSequenceData)(this.store, sequence, parent, sampleData, {
        requireDetermination
      });
      if (parent) {
        await sequenceData.setParentToOccurrence(parent);
      }
      return {
        sequenceData
      };
    }
    setupController(controller, model) {
      super.setupController(...arguments);
      controller.set('routeHasBeenLoaded', true);
    }
    doRefresh() {
      this.set('controller.routeHasBeenLoaded', false);
      this.refresh();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "settings", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "doRefresh", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "doRefresh"), _class.prototype)), _class));
  var _default = _exports.default = SequenceAddRoute;
});