define("plutof/utils/file-serialization", ["exports", "file-saver"], function (_exports, _fileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.escapeCSV = escapeCSV;
  _exports.getHumanReadableSize = getHumanReadableSize;
  _exports.readFile = readFile;
  _exports.saveFile = saveFile;
  function escapeCSV(value) {
    if (Ember.isNone(value)) {
      return '';
    }
    var s = value.toString();
    const markers = ['\n', '"', ','];
    const must_quote = markers.some(marker => s.indexOf(marker) !== -1);
    s = s.replace(/"/g, '""');
    if (must_quote) {
      s = '"' + s + '"';
    }
    return s;
  }
  async function saveFile(parts, filename) {
    await (0, _fileSaver.saveAs)(new Blob(parts), filename);
  }
  function readFile(file) {
    return new Ember.RSVP.Promise(function (resolve, reject) {
      var reader = new FileReader();
      reader.onload = function (evt) {
        resolve(reader.result);
      };
      reader.readAsText(file);
    });
  }
  function getHumanReadableSize(size) {
    const labels = ['B', 'KB', 'MB', 'GB', 'TB'];
    let i = 0;
    while (i < labels.length - 1 && size >= 1000) {
      i++;
      size /= 1000;
    }
    return Math.round(size) + ' ' + labels[i];
  }
});