define("plutof/templates/components/parent-object-path", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "i4hndMfy",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"icon-specimen\"],[12],[13],[2,\"\\n\"],[6,[37,2],null,[[\"route\",\"model\"],[\"specimen.view\",[35,1]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[35,0,[\"representation\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,4],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"icon-livingculture\"],[12],[13],[2,\"\\n\"],[6,[37,2],null,[[\"route\",\"model\"],[\"livingspecimen.view\",[35,1]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[35,0,[\"representation\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,4],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"icon-materialsample\"],[12],[13],[2,\"\\n\"],[6,[37,2],null,[[\"route\",\"model\"],[\"materialsample.view\",[35,1]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[35,0,[\"representation\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,4],[[35,7]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"icon-project\"],[12],[13],[2,\"\\n\"],[6,[37,2],null,[[\"route\",\"model\"],[\"study.view\",[35,1]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[35,0,[\"representation\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"parentObject\",\"object_id\",\"link-to\",\"isSpecimen\",\"if\",\"isStrain\",\"isMaterialSample\",\"isStudy\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/parent-object-path.hbs"
    }
  });
});