define("plutof/controllers/forms/view", ["exports", "plutof/controllers/study/edit", "plutof/config/environment"], function (_exports, _edit, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let FormsViewController = (_dec = Ember.computed('model.mainform.type', 'model.serializedLinks'), _dec2 = Ember.computed('model.mainform.id'), (_class = class FormsViewController extends _edit.default {
    get customLinks() {
      return this.get('model.serializedLinks.' + this.get('model.mainform.type') + '_form') || [];
    }
    get enableTaxonSheet() {
      return _environment.default.TAXON_SHEET_MAINFORMS.includes(this.model.mainform.id);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "customLinks", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "customLinks"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "enableTaxonSheet", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "enableTaxonSheet"), _class.prototype)), _class));
  var _default = _exports.default = FormsViewController;
});