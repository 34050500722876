define("plutof/components/series/edit", ["exports", "plutof/mixins/component-validations"], function (_exports, _componentValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Series::GeneralData
      @series={{@series}}
      @validationContext={{@validationContext.series}} />
  
  <Access::EditRights
      @rights={{@accessRights}}
      @validationContext={{@validationContext.access}} />
  
  <StickyBottomWrapper>
      <InvalidPanels @validationContext={{@validationContext}} />
  
      <div class="action-buttons">
          <Common::SaveButton @save={{@save}} @disabled={{@validationContext.isInvalid}} />
          <Common::CancelButton @cancel={{route-action "goBack"}} />
      </div>
  </StickyBottomWrapper>
  
  */
  {
    "id": "q2DgPH90",
    "block": "{\"symbols\":[\"@series\",\"@validationContext\",\"@accessRights\",\"@save\"],\"statements\":[[8,\"series/general-data\",[],[[\"@series\",\"@validationContext\"],[[32,1],[32,2,[\"series\"]]]],null],[2,\"\\n\\n\"],[8,\"access/edit-rights\",[],[[\"@rights\",\"@validationContext\"],[[32,3],[32,2,[\"access\"]]]],null],[2,\"\\n\\n\"],[8,\"sticky-bottom-wrapper\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"invalid-panels\",[],[[\"@validationContext\"],[[32,2]]],null],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"action-buttons\"],[12],[2,\"\\n        \"],[8,\"common/save-button\",[],[[\"@save\",\"@disabled\"],[[32,4],[32,2,[\"isInvalid\"]]]],null],[2,\"\\n        \"],[8,\"common/cancel-button\",[],[[\"@cancel\"],[[30,[36,0],[\"goBack\"],null]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"route-action\"]}",
    "meta": {
      "moduleName": "plutof/components/series/edit.hbs"
    }
  });
  class ReferenceSeriesEdit extends Ember.Component.extend(_componentValidations.default) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "validationChildren", [{
        name: 'series',
        label: 'General.generalData'
      }, {
        name: 'access',
        label: 'General.access'
      }]);
    }
  }
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ReferenceSeriesEdit);
});