define("plutof/templates/conservation-lab/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "rm5kDPqv",
    "block": "{\"symbols\":[\"hub\"],\"statements\":[[8,\"layout/module-hub\",[],[[\"@icon\",\"@title\"],[[30,[36,0],[\"conservation\"],null],[30,[36,1],[\"conservation.laboratory.title\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"layout/regular-grid\",[],[[\"@columns\"],[3]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,1,[\"link\"]],[],[[\"@title\",\"@description\",\"@route\",\"@icon\"],[[30,[36,1],[\"conservation.redList.index.title\"],null],[30,[36,1],[\"conservation.redList.index.description\"],null],\"conservation-lab.red-list\",[30,[36,0],[\"conservation\"],null]]],null],[2,\"\\n\\n        \"],[8,[32,1,[\"link\"]],[],[[\"@title\",\"@description\",\"@route\",\"@icon\"],[[30,[36,1],[\"conservation.redListAssessment.index.title\"],null],[30,[36,1],[\"conservation.redListAssessment.index.description\"],null],\"conservation-lab.red-list-assessment\",[30,[36,0],[\"conservation\"],null]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\"]}",
    "meta": {
      "moduleName": "plutof/templates/conservation-lab/index.hbs"
    }
  });
});