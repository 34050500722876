define("plutof/routes/publishing/gbif/edit", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "plutof/components/publishing/gbif/access-rights/edit", "plutof/components/publishing/gbif/records/records-table", "plutof/mixins/route", "plutof/utils/notifications", "plutof/utils/structures", "plutof/utils/validations"], function (_exports, _authenticatedRouteMixin, _edit, _recordsTable, _route, _notifications, _structures, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function getAgentInstances(agent, agents) {
    return agents.filter(a => a.first_name === agent.first_name && a.last_name === agent.last_name);
  }
  function packAgentInstances(instances) {
    if (!instances.length) {
      return null;
    }
    const types = instances.map(c => c.agent_type);
    const agent = Ember.getProperties(instances[0], 'email', 'first_name', 'last_name', 'position', 'orcid_identifier');
    agent.types = types;
    return agent;
  }
  function packAgents(agentsArg) {
    let agents = [].concat(agentsArg);
    const uniqueAgents = [];
    agentsArg.forEach(agent => {
      const instances = getAgentInstances(agent, agents);
      const packedAgent = packAgentInstances(instances);
      agents = (0, _structures.set_difference)(agents, instances);
      uniqueAgents.push(packedAgent);
    });
    return uniqueAgents.compact();
  }
  class PublishingGbifEditRoute extends Ember.Route.extend(_authenticatedRouteMixin.default, _route.default, _validations.ValidatedRoute) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "defaultReturnRoute", 'publishing.gbif.index');
      _defineProperty(this, "title", 'dataResource.general.editDataset');
      _defineProperty(this, "confirmExit", true);
    }
    async model(params) {
      const id = params.id;
      const dataset = await this.store.findRecord('publishing/gbif/dataset', id);
      // Pack name duplicates to one name and multiple agent for UI purposes.
      const agents = packAgents(dataset.get('people'));
      const [sequences, specimens, observations, referencebased, rights] = await Ember.RSVP.all([(0, _recordsTable.loadRecordsTableData)(this.ajax, dataset, 'Sequence'), (0, _recordsTable.loadRecordsTableData)(this.ajax, dataset, 'Specimen'), (0, _recordsTable.loadRecordsTableData)(this.ajax, dataset, 'Observation'), (0, _recordsTable.loadRecordsTableData)(this.ajax, dataset, 'Reference Based'), (0, _edit.loadDatasetRights)(this.store, this.ajax, dataset)]).catch(_notifications.reportError);
      return {
        agents,
        dataset,
        sequences,
        specimens,
        observations,
        referencebased,
        rights
      };
    }
  }
  var _default = _exports.default = PublishingGbifEditRoute;
});