define("plutof/components/gallery/footer/-right-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="gallery-footer-right__row">
      {{yield (hash
          control=(component "gallery/footer/-right-control")
          item=(component "gallery/footer/-right-item")
      )}}
  </div>
  
  */
  {
    "id": "83Fv0cIA",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"gallery-footer-right__row\"],[12],[2,\"\\n    \"],[18,1,[[30,[36,1],null,[[\"control\",\"item\"],[[30,[36,0],[\"gallery/footer/-right-control\"],null],[30,[36,0],[\"gallery/footer/-right-item\"],null]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "plutof/components/gallery/footer/-right-row.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});