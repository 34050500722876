define("plutof/controllers/permit/edit", ["exports", "plutof/utils/controllers"], function (_exports, _controllers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PermitEditController extends _controllers.EditController {
    async _save() {
      await this.model.permit.save();
    }
  }
  var _default = _exports.default = PermitEditController;
});