define("plutof/translations/eng/collection-management", ["exports", "plutof/translations/helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    /* INDEX */
    indexTitle: (0, _helpers.alias)('General.CollectionManagement'),
    conditionAssessmentsLabel: 'Condition Assessments',
    treatmentsLabel: 'Treatments',
    conditionAssessmentsInfo: 'In this section you can add and edit condition assessments.',
    treatmentsInfo: 'In this section you can browse and manage specimen treatment protocols.',
    photobankInfo: 'In this section you can add and edit photobank items.',
    /* MAIN LIST */
    mainTitle: 'Condition Assessments',
    mainInfoTitle: 'Condition Assessments',
    transactionsTitle: 'Transactions',
    /* TREATMENTS LIST */
    treatmentsTitle: 'Treatments',
    treatmentsInfoTitle: 'Treatments',
    /* THE REST */
    assessedBy: 'Asessed by',
    newCA: 'New Condition Assessment',
    CA: 'Condition Assessment',
    editCA: 'Edit Condition Assessment',
    treatment: 'Treatment',
    newTreatment: 'New Treatment',
    editTreatment: 'Edit Treatment',
    data: 'General Data',
    conditionAssessmentTypes: {
      specimen: 'Specimen',
      livingSpecimen: 'Living specimen'
    }
  };
});