define("plutof/routes/organization/add", ["exports", "plutof/components/agent/organization/edit", "plutof/utils/routes"], function (_exports, _edit, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class OrganizationAddRoute extends _routes.AddRoute {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "defaultReturnRoute", 'organization.index');
      _defineProperty(this, "paramsClearedOnReset", ['parent_organization']);
    }
    async model(params) {
      if (this.get('controller.routeHasBeenLoaded')) {
        return undefined;
      }
      const organization = this.store.createRecord('agent/organization', {
        address: this.store.createRecord('agent/address')
      });
      if (!(Ember.isNone(params) || Ember.isNone(params.parent_organization))) {
        const parent = await this.store.findRecord('agent/organization', params.parent_organization);
        organization.set('parent_organization', parent);
      }
      return {
        organization: (0, _edit.createOrganizationModel)(organization)
      };
    }
  }
  _exports.default = OrganizationAddRoute;
});