define("plutof/translations/est/occurrence-converter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    title: 'Taksoni esinemise konverter',
    info: 'Tee eksemplari kirjest vaatluse kirje. Vali kas jätta eksemplari kirje alles või kustutada.',
    feedback: {
      newRecord: 'Uus vaatlus',
      created: 'on salvestatud.',
      originalDeleted: 'Eksemplar kustutati.',
      originalNotDeleted: 'Eksemplari ei kustutatud.',
      toList: 'Eksemplaride nimekirja.'
    },
    actions: {
      openConverter: 'Konverdi',
      deleteOriginal: 'Kustuta eksemplar',
      keepOriginal: 'Jäta eksemplar alles',
      convertToObservation: 'Muuda vaatluseks'
    }
  };
});