define("plutof/translations/est/living-cultures", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    newLivingC: 'Uus eluseksemplar',
    editLivingC: 'Muuda eluseksemplari andmeid',
    livingCulture: 'Eluseksemplar',
    panelAdditionalIdentifiers: 'Teisesed koodid',
    panelIsolation: 'Isoleerimine',
    panelInteractingTaxa: 'Interaktsioonid',
    searchTitle: 'Otsi eluseksemplare',
    infoTitle: 'Eluseksemplarid',
    alreadyExists: 'on juba lisatud andmebaasi',
    code: 'Kood',
    placeholders: {
      searchHint: 'Otsi tüve numbri järgi'
    },
    annotation: {
      add: 'Annoteeri eluseksemplari',
      edit: 'Muuda annotatsiooni',
      view: 'Annoteeritud eluseksemplar'
    },
    duplicates: 'Duplumid',
    duplicateRecord: {
      duplicateRecord: {
        label: 'Duplum',
        tooltip: 'Selle kirje duplum.'
      }
    },
    originalRecord: {
      label: 'Originaalkirje',
      record: {
        label: 'Originaalkirje',
        tooltip: 'Duplumi originaaalkirje.'
      },
      originalId: {
        label: 'Orignaalkirje ID',
        tooltip: 'Duplumi originaalkirje identifikaator.'
      },
      organization: {
        label: 'Originaalkirje organisatsioon',
        tooltip: 'Duplumi originaalkirje organisatsioon.'
      },
      remarks: {
        label: 'Originaalkirje märkused',
        tooltip: 'Üldised märkused originaalkirje kohta.'
      }
    },
    editOriginalRecord: 'Originaalkirje'
  };
});