define("plutof/models/measurement/measurement", ["exports", "@ember-data/model", "plutof/mixins/display-name-from-value", "plutof/models/plutof-model", "plutof/utils/model"], function (_exports, _model, _displayNameFromValue, _plutofModel, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const displayName = (0, _displayNameFromValue.displayNames)('measurement.measurement');
  let Measurement = (_dec = Ember.inject.service, _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('enum'), _dec6 = (0, _model.attr)('boolean'), _dec7 = (0, _model.attr)('number'), _dec8 = (0, _model.attr)('number'), _dec9 = (0, _model.belongsTo)('geography/language'), _dec10 = (0, _model.attr)('string'), _dec11 = (0, _model.attr)('string'), _dec12 = Ember.computed.equal('type', 'int'), _dec13 = Ember.computed.equal('type', 'float'), _dec14 = Ember.computed.equal('type', 'text'), _dec15 = Ember.computed.equal('type', 'set'), _dec16 = Ember.computed.equal('type', 'logical'), _dec17 = Ember.computed.or('isInteger', 'isFloat'), _dec18 = displayName('type'), _dec19 = Ember.computed.or('isFloat', 'isInteger'), _dec20 = (0, _model.attr)(), _dec21 = (0, _model.attr)(), _dec22 = (0, _model2.hasmany_property)('measurement/measurementsetelement', {
    trait: 'id'
  }), _dec23 = Ember.computed('locale.locale', 'language.iso_639', 'name', 'name_translated'), _dec24 = Ember.computed.reads('nameLocalized'), (_class = class Measurement extends _plutofModel.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "locale", _descriptor, this);
      _initializerDefineProperty(this, "name", _descriptor2, this);
      _initializerDefineProperty(this, "description", _descriptor3, this);
      _initializerDefineProperty(this, "type", _descriptor4, this);
      _initializerDefineProperty(this, "module", _descriptor5, this);
      _initializerDefineProperty(this, "allow_multiple", _descriptor6, this);
      _initializerDefineProperty(this, "min_allowed_value", _descriptor7, this);
      _initializerDefineProperty(this, "max_allowed_value", _descriptor8, this);
      _initializerDefineProperty(this, "language", _descriptor9, this);
      _initializerDefineProperty(this, "name_translated", _descriptor10, this);
      _initializerDefineProperty(this, "description_translated", _descriptor11, this);
      _initializerDefineProperty(this, "isInteger", _descriptor12, this);
      _initializerDefineProperty(this, "isFloat", _descriptor13, this);
      _initializerDefineProperty(this, "isText", _descriptor14, this);
      _initializerDefineProperty(this, "isSet", _descriptor15, this);
      _initializerDefineProperty(this, "isLogical", _descriptor16, this);
      _initializerDefineProperty(this, "isNumeric", _descriptor17, this);
      _initializerDefineProperty(this, "typeDisplayName", _descriptor18, this);
      _initializerDefineProperty(this, "needsUnits", _descriptor19, this);
      _initializerDefineProperty(this, "info_links", _descriptor20, this);
      _initializerDefineProperty(this, "set_choices", _descriptor21, this);
      // TODO - trait actually has a "set_choices" field. No reason to do this extra query at all
      _initializerDefineProperty(this, "elements", _descriptor22, this);
      _initializerDefineProperty(this, "representation", _descriptor23, this);
    }
    get nameLocalized() {
      const traitLocale = this.get('language.iso_639');
      if (Ember.isNone(traitLocale)) {
        return this.name;
      }
      const locale = this.locale.locale;
      const translate = locale === 'est' && locale === traitLocale;
      const translatedName = translate ? this.name_translated : this.name;
      return translatedName || this.name;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "locale", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "type", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "module", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "allow_multiple", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "min_allowed_value", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "max_allowed_value", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "language", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "name_translated", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "description_translated", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "isInteger", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "isFloat", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "isText", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "isSet", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "isLogical", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "isNumeric", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "typeDisplayName", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "needsUnits", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "info_links", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "set_choices", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "elements", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "nameLocalized", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "nameLocalized"), _class.prototype), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "representation", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  var _default = _exports.default = Measurement;
});