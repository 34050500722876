define("plutof/adapters/determination/typification", ["exports", "plutof/adapters/application", "plutof/utils/caching"], function (_exports, _application, _caching) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _caching.cacheAdapterRecords)(_application.default, {
    cacheName: 'determination/typification',
    version: 1,
    translated: true
  });
});