define("plutof/translations/est/traits", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    title: 'Tunnused ja mõõtmised',
    traits: 'Tunnused',
    translations: 'Tõlked',
    languageInfo: 'Tunnused sisestatakse inglise keeles. Tekstid teises keeles saab sisestada "Tõlked" paneeli.'
  };
});