define("plutof/templates/components/clipboard/list-view-clipboard-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "PihigH63",
    "block": "{\"symbols\":[],\"statements\":[[11,\"a\"],[24,\"data-test\",\"add-to-clipboard\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"toggleInclusion\"]]],null]],[[\"bubbles\"],[false]]],[12],[2,\"\\n    \"],[10,\"span\"],[15,0,[31,[[32,0,[\"iconClass\"]]]]],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"perform\",\"on\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/clipboard/list-view-clipboard-button.hbs"
    }
  });
});