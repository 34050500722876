define("plutof/components/datacite/sh-doi/variable-sequence-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class VariableSequenceList extends Ember.Component {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "thresholds", ['0.5', '1.0', '1.5', '2.0', '2.5', '3.0']);
      _defineProperty(this, "headers", [{
        option: 'status',
        title: 'Settings.status'
      }, {
        option: 'accnos',
        title: 'DoiSHref.seqIDShort'
      }, {
        option: 'unitetaxon',
        title: 'DoiSHref.UniteTaxName'
      }, {
        option: 'insdtaxon',
        title: 'DoiSHref.INSDtaxName'
      }, {
        option: 'country',
        title: 'DoiSHref.country'
      }, {
        option: 'source',
        title: 'DoiSHref.DNAsource'
      }]);
    }
    init() {
      super.init(...arguments);
      let inclusion = {};
      this.headers.forEach(header => inclusion[header.option] = true);
      this.set('headerInclusion', Ember.Object.create(inclusion));
    }
  }
  var _default = _exports.default = VariableSequenceList;
});