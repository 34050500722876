define("plutof/templates/livingspecimen/annotation/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "MDx/zsER",
    "block": "{\"symbols\":[],\"statements\":[[8,\"navbar/edit\",[],[[\"@kind\",\"@icon\",\"@title\",\"@record\",\"@infoContent\"],[\"annotation\",[30,[36,0],[\"livingspecimen\"],null],[30,[36,1],[\"LivingCultures.annotation.edit\"],null],[32,0,[\"model\",\"strain\"]],\"information/content/living-specimen/add\"]],null],[2,\"\\n\\n\"],[8,\"livingspecimen/edit\",[],[[\"@model\",\"@validationContext\"],[[32,0,[\"model\",\"livingSpecimenModel\"]],[32,0,[\"validationContext\"]]]],null],[2,\"\\n\\n\"],[8,\"sticky-bottom-wrapper\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"invalid-panels\",[],[[\"@validationContext\"],[[32,0,[\"validationContext\"]]]],null],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"action-buttons\"],[12],[2,\"\\n        \"],[8,\"annotation/annotate-button\",[],[[\"@annotate\",\"@disabled\"],[[32,0,[\"annotate\"]],[32,0,[\"validationContext\",\"isInvalid\"]]]],null],[2,\"\\n\\n        \"],[8,\"common/cancel-button\",[],[[\"@cancel\"],[[30,[36,2],[\"goBack\"],null]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\",\"route-action\"]}",
    "meta": {
      "moduleName": "plutof/templates/livingspecimen/annotation/edit.hbs"
    }
  });
});