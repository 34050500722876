define("plutof/templates/components/tables/-bulk-update/update-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "/Oj5X9TO",
    "block": "{\"symbols\":[\"@open\",\"&default\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"table-bulk-update__update-panel \",[30,[36,0],[[32,1],\"table-bulk-update__update-panel--open\"],null]]]],[12],[2,\"\\n    \"],[18,2,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/tables/-bulk-update/update-panel.hbs"
    }
  });
});