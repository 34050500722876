define("plutof/components/plutof-panel/-panel/action-button", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <span class="{{@icon}} panel-header-btn__icon">{{yield}}</span>
  
  */
  {
    "id": "r/1W2Plm",
    "block": "{\"symbols\":[\"@icon\",\"&default\"],\"statements\":[[10,\"span\"],[15,0,[31,[[32,1],\" panel-header-btn__icon\"]]],[12],[18,2,null],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "plutof/components/plutof-panel/-panel/action-button.hbs"
    }
  });
  let PanelActionButton = (_dec = (0, _component.tagName)('button'), _dec2 = (0, _component.classNames)('panel__action-button panel-header-btn'), _dec(_class = _dec2(_class = class PanelActionButton extends Ember.Component {
    click() {
      this.clicked();
    }
  }) || _class) || _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PanelActionButton);
});