define("plutof/templates/experiment/dna-extraction/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "WW7rxiA6",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"route-marker-dna-extraction-edit\"],[12],[13],[2,\"\\n\\n\"],[8,\"navbar/edit\",[],[[\"@icon\",\"@title\",\"@record\",\"@infoContent\"],[[30,[36,0],[\"experiment\"],null],[30,[36,1],[\"experiment.extraction.routeTitles.edit\"],null],[32,0,[\"model\",\"experiment\"]],\"information/content/dna-lab/general\"]],null],[2,\"\\n\\n\"],[1,[30,[36,5],null,[[\"experiment\",\"dnas\",\"linkedItemsData\",\"validationContext\",\"cancel\",\"save\"],[[35,4,[\"experiment\"]],[35,4,[\"materials\"]],[35,4,[\"linkedItemsData\"]],[35,3],[30,[36,2],[[32,0],\"cancel\"],null],[30,[36,2],[[32,0],\"saveExperiment\"],null]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\",\"action\",\"validationContext\",\"model\",\"experiment/dna-extraction/edit-form\"]}",
    "meta": {
      "moduleName": "plutof/templates/experiment/dna-extraction/edit.hbs"
    }
  });
});