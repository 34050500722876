define("plutof/components/tables/-list-view/modified", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <@Field @label={{i18n-t "PlutofListViewColumn.modified"}}>
      {{formatted-date @record.updated_at}}
  </@Field>
  
  */
  {
    "id": "kYdscWKl",
    "block": "{\"symbols\":[\"@Field\",\"@record\"],\"statements\":[[8,[32,1],[],[[\"@label\"],[[30,[36,0],[\"PlutofListViewColumn.modified\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,1],[[32,2,[\"updated_at\"]]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"formatted-date\"]}",
    "meta": {
      "moduleName": "plutof/components/tables/-list-view/modified.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});