define("plutof/components/unite/map", ["exports", "plutof/components/map/mixins/default-styling"], function (_exports, _defaultStyling) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let UniteMap = (_dec = Ember.computed('data'), (_class = class UniteMap extends Ember.Component {
    get mapData() {
      const countryFeatures = this.data.countries.map(country => {
        const [lon, lat] = country.geom;
        const geom = `POINT(${lon} ${lat})`;
        const centroid = (0, _defaultStyling.makeCountryCentroidFeature)(country.code, geom);
        return {
          id: country.id,
          dontCluster: true,
          rawStyle: centroid.rawStyle,
          geom: centroid.geom
        };
      });
      const pointFeatures = this.data.points.map(([lon, lat]) => {
        const geom = `POINT(${lon} ${lat})`;
        return {
          id: geom,
          geom,
          styleClass: 'base'
        };
      });
      return countryFeatures.concat(pointFeatures);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "mapData", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "mapData"), _class.prototype)), _class));
  var _default = _exports.default = UniteMap;
});