define("plutof/helpers/annotation/added-removed-class", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.helper(function ([record], {
    container = false
  } = {}) {
    if (!record) {
      return '';
    }
    if (record.addedInAnnotation) {
      return 'annotated';
    }
    if (record.removedInAnnotation) {
      return container ? 'annotated-removed-container' : 'annotated-removed';
    }
    return '';
  });
});