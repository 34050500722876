define("plutof/translations/est/glossary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    title: 'Märksõnad',
    searchTitle: 'Otsi märksõnu',
    infoTitle: 'Märksõnad',
    glossary: 'Märksõna',
    newGlossary: 'Uus märksõna',
    editGlossary: 'Muuda märksõna',
    relatedKeywords: 'Seotud märksõnad',
    word: 'Sõna',
    wordTooltip: 'Defineeri uus märksõna või vali olemsolev'
  };
});