define("plutof/components/dmp/edit", ["exports", "plutof/mixins/component-validations"], function (_exports, _componentValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Dmp::General
      @dmp={{@data.dmp}}
      @validationContext={{this.validationContext.general}} />
  
  <Dmp::Contributors
      @dmp={{@data.dmp}}
      @validationContext={{this.validationContext.contributors}} />
  
  <Dmp::Costs
      @dmp={{@data.dmp}}
      @validationContext={{this.validationContext.costs}} />
  
  <Dmp::Project
      @data={{@data}}
      @validationContext={{this.validationContext.project}} />
  
  <Dmp::Dataset @dataset={{@data.dmp.dataset}} />
  
  <Dmp::Distribution
      @distribution={{@data.dmp.dataset.distribution}}
      @validationContext={{this.validationContext.distribution}} />
  
  <Dmp::Host @host={{@data.dmp.dataset.distribution.host}} />
  
  <Access::EditRights
      @rights={{@accessRights}}
      @validationContext={{@validationContext.access}} />
  
  <StickyBottomWrapper>
      <InvalidPanels @validationContext={{this.validationContext}} />
  
      <div class="action-buttons">
          <Common::SaveButton @save={{action @save}} @disabled={{this.validationContext.isInvalid}} />
          <Common::CancelButton @cancel={{action @cancel}} />
      </div>
  </StickyBottomWrapper>
  
  */
  {
    "id": "xXFLbDTO",
    "block": "{\"symbols\":[\"@data\",\"@accessRights\",\"@validationContext\",\"@save\",\"@cancel\"],\"statements\":[[8,\"dmp/general\",[],[[\"@dmp\",\"@validationContext\"],[[32,1,[\"dmp\"]],[32,0,[\"validationContext\",\"general\"]]]],null],[2,\"\\n\\n\"],[8,\"dmp/contributors\",[],[[\"@dmp\",\"@validationContext\"],[[32,1,[\"dmp\"]],[32,0,[\"validationContext\",\"contributors\"]]]],null],[2,\"\\n\\n\"],[8,\"dmp/costs\",[],[[\"@dmp\",\"@validationContext\"],[[32,1,[\"dmp\"]],[32,0,[\"validationContext\",\"costs\"]]]],null],[2,\"\\n\\n\"],[8,\"dmp/project\",[],[[\"@data\",\"@validationContext\"],[[32,1],[32,0,[\"validationContext\",\"project\"]]]],null],[2,\"\\n\\n\"],[8,\"dmp/dataset\",[],[[\"@dataset\"],[[32,1,[\"dmp\",\"dataset\"]]]],null],[2,\"\\n\\n\"],[8,\"dmp/distribution\",[],[[\"@distribution\",\"@validationContext\"],[[32,1,[\"dmp\",\"dataset\",\"distribution\"]],[32,0,[\"validationContext\",\"distribution\"]]]],null],[2,\"\\n\\n\"],[8,\"dmp/host\",[],[[\"@host\"],[[32,1,[\"dmp\",\"dataset\",\"distribution\",\"host\"]]]],null],[2,\"\\n\\n\"],[8,\"access/edit-rights\",[],[[\"@rights\",\"@validationContext\"],[[32,2],[32,3,[\"access\"]]]],null],[2,\"\\n\\n\"],[8,\"sticky-bottom-wrapper\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"invalid-panels\",[],[[\"@validationContext\"],[[32,0,[\"validationContext\"]]]],null],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"action-buttons\"],[12],[2,\"\\n        \"],[8,\"common/save-button\",[],[[\"@save\",\"@disabled\"],[[30,[36,0],[[32,0],[32,4]],null],[32,0,[\"validationContext\",\"isInvalid\"]]]],null],[2,\"\\n        \"],[8,\"common/cancel-button\",[],[[\"@cancel\"],[[30,[36,0],[[32,0],[32,5]],null]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "plutof/components/dmp/edit.hbs"
    }
  });
  class DMPEditForm extends Ember.Component.extend(_componentValidations.default) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "validationChildren", [{
        name: 'general',
        label: 'dmp.panels.general'
      }, {
        name: 'contributors',
        label: 'dmp.panels.contributors'
      }, {
        name: 'costs',
        label: 'dmp.panels.costs'
      }, {
        name: 'project',
        label: 'dmp.panels.project'
      }, {
        name: 'distribution',
        label: 'dmp.panels.distribution'
      }]);
    }
  }
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DMPEditForm);
});