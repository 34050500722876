define("plutof/templates/profile/identities", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "WUgf9N4Y",
    "block": "{\"symbols\":[\"identity\"],\"statements\":[[8,\"test/route-marker\",[],[[\"@route\"],[\"profile.identities\"]],null],[2,\"\\n\\n\"],[1,[30,[36,4],[\"Settings.identities.listLabel\"],null]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"spacer-large\"],[12],[13],[2,\"\\n\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[32,0,[\"model\",\"identities\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"spacer-below\"],[12],[2,\"\\n\"],[6,[37,3],[[32,1,[\"identity\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,2],[[32,1,[\"provider\",\"name\"]]],null]],[2,\": \"],[1,[32,1,[\"identity\"]]],[2,\"\\n\\n            \"],[11,\"button\"],[24,0,\"btn-fake-link\"],[4,[38,0],[[32,0],[32,0,[\"disconnect\"]],[32,1]],null],[12],[2,\"\\n                \"],[1,[30,[36,2],[\"Settings.identities.disconnect\"],null]],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"            \"],[1,[30,[36,1],[[32,1,[\"provider\",\"button\"]]],[[\"clicked\"],[[30,[36,0],[[32,0],[32,0,[\"connect\"]],[32,1,[\"provider\"]]],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"component\",\"i18n-t\",\"if\",\"common/local-label\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "plutof/templates/profile/identities.hbs"
    }
  });
});