define("plutof/templates/organization/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "giQbFw+G",
    "block": "{\"symbols\":[\"Field\",\"organization\",\"common\"],\"statements\":[[10,\"div\"],[14,0,\"route-marker-organization-index\"],[14,\"data-test\",\"route-organization.index\"],[12],[13],[2,\"\\n\\n\"],[8,\"navbar/list\",[],[[\"@icon\",\"@title\",\"@addRoute\",\"@pagination\",\"@infoContent\"],[[30,[36,0],[\"organization\"],null],[30,[36,1],[\"Agents.orgTitle\"],null],\"organization.add\",[32,0,[\"model\",\"pagination\"]],\"information/content/organization/index\"]],null],[2,\"\\n\\n\"],[8,\"tables/list-route\",[],[[\"@records\"],[[32,0,[\"model\",\"pagination\",\"objects\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1],[],[[\"@label\"],[[30,[36,1],[\"PlutofListViewColumn.name\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],null,[[\"route\",\"model\"],[\"organization.view\",[32,2,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[32,2,[\"agent_name\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,3,[\"modified\"]],[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[1,2,3]}]]],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\",\"link-to\"]}",
    "meta": {
      "moduleName": "plutof/templates/organization/index.hbs"
    }
  });
});