define("plutof/templates/components/auth/identity-provider-buttons/orcid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "2FJVWX4n",
    "block": "{\"symbols\":[],\"statements\":[[10,\"img\"],[14,\"src\",\"/assets/images/auth/orcid.png\"],[14,0,\"social-login-button__image\"],[12],[13],[2,\"\\n\"],[1,[30,[36,0],[\"login.signin.orcid\"],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/auth/identity-provider-buttons/orcid.hbs"
    }
  });
});