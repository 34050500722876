define("plutof/templates/components/clipboard/navbar-button-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "HGeHY/vs",
    "block": "{\"symbols\":[\"@navbar\"],\"statements\":[[8,[32,1,[\"button\"]],[[24,\"data-test\",\"toggle-clipboard\"]],[[\"@icon\",\"@clicked\",\"@disabled\"],[[32,0,[\"icon\"]],[32,0,[\"toggle\"]],[32,0,[\"resolve\",\"isRunning\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"General.Clipboard\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/clipboard/navbar-button-new.hbs"
    }
  });
});