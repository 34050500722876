define("plutof/components/study/view/area-tree/pagination", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.loading}}
      <li class="area-tree__child">
          <div class="area-tree__vertical-notch"></div>
          <div class="area-tree__horizontal-notch"></div>
  
          <div class="area-tree__node">
              <span class="loading-icon"></span>
              {{i18n-t "General.loading"}}...
          </div>
      </li>
  {{else if (not @pagination.onLastPage)}}
      <li class="area-tree__child">
          <div class="area-tree__vertical-notch"></div>
          <div class="area-tree__horizontal-notch"></div>
  
          <button {{on "click" this.loadMore}} class="btn-reset area-tree__node area-tree__node--action">
              {{i18n-t "General.loadMore"}}
          </button>
      </li>
  {{/if}}
  
  */
  {
    "id": "RLb4L+i3",
    "block": "{\"symbols\":[\"@pagination\"],\"statements\":[[6,[37,3],[[32,0,[\"loading\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"li\"],[14,0,\"area-tree__child\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"area-tree__vertical-notch\"],[12],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"area-tree__horizontal-notch\"],[12],[13],[2,\"\\n\\n        \"],[10,\"div\"],[14,0,\"area-tree__node\"],[12],[2,\"\\n            \"],[10,\"span\"],[14,0,\"loading-icon\"],[12],[13],[2,\"\\n            \"],[1,[30,[36,1],[\"General.loading\"],null]],[2,\"...\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[30,[36,2],[[32,1,[\"onLastPage\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"li\"],[14,0,\"area-tree__child\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"area-tree__vertical-notch\"],[12],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"area-tree__horizontal-notch\"],[12],[13],[2,\"\\n\\n        \"],[11,\"button\"],[24,0,\"btn-reset area-tree__node area-tree__node--action\"],[4,[38,0],[\"click\",[32,0,[\"loadMore\"]]],null],[12],[2,\"\\n            \"],[1,[30,[36,1],[\"General.loadMore\"],null]],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"on\",\"i18n-t\",\"not\",\"if\"]}",
    "meta": {
      "moduleName": "plutof/components/study/view/area-tree/pagination.hbs"
    }
  });
  let AreaTreePagination = (_dec = Ember.computed('args.pagination', 'args.pagination.isLoading'), _dec2 = Ember._action, (_class = class AreaTreePagination extends _component.default {
    get loading() {
      return !this.args.pagination || this.args.pagination.isLoading;
    }
    loadMore() {
      this.args.pagination.nextPage();
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "loading", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "loading"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadMore", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "loadMore"), _class.prototype)), _class));
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, AreaTreePagination);
});