define("plutof/controllers/reference/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let ReferenceViewController = (_dec = Ember.computed('model.reference.id'), _dec2 = Ember.computed('model.reference.reference_type.isLoaded'), _dec3 = Ember._action, (_class = class ReferenceViewController extends Ember.Controller {
    get itemsQuery() {
      return Ember.Object.create({
        model: 'reference/item',
        store: this.store,
        count: 0,
        args: {
          reference: this.get('model.reference.id')
        }
      });
    }
    get referenceContent() {
      var isLoaded = this.get('model.reference.reference_type.isLoaded');
      if (!isLoaded) {
        return [];
      }
      var referenceType = this.get('model.reference.reference_type');
      return this.fields.map(function (field) {
        var label = referenceType.get(field.field);
        var value = this.get('model.reference.' + field.field);
        label = Ember.isNone(label) ? field.field : label;
        return {
          field: label,
          propertyPath: referenceType.get(field.field),
          value: value,
          marker: field.field
        };
      }.bind(this)).compact();
    }
    newRefbasedOccurrence() {
      this.transitionToRoute('referencebased.add', {
        queryParams: {
          reference_id: this.get('model.reference.id')
        }
      });
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "itemsQuery", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "itemsQuery"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "referenceContent", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "referenceContent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "newRefbasedOccurrence", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "newRefbasedOccurrence"), _class.prototype)), _class));
  var _default = _exports.default = ReferenceViewController;
});