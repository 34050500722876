define("plutof/components/map/edit-map", ["exports", "@ember-decorators/object", "ember-concurrency", "ol/extent", "ol/Feature", "ol/Observable", "ol/proj", "ol/source/Vector", "ol/layer/Vector", "ol/style", "plutof/components/map/base-map", "plutof/components/map/mixins/preferred-layerset", "plutof/utils/map", "plutof/utils/structures"], function (_exports, _object, _emberConcurrency, _extent, _Feature, _Observable, _proj, _Vector, _Vector2, _style, _baseMap, _preferredLayerset, _map, _structures) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let MapEdit = (_dec = (0, _object.observes)('constraintSource', 'constraintGeometries', 'constraintGeometries.[]', 'view'), _dec2 = (0, _object.observes)('geodata', 'geodata.[]'), _dec3 = (0, _emberConcurrency.task)({
    restartable: true
  }), _dec4 = (0, _object.observes)('country.id', 'countrySource', 'showCountry'), _dec5 = (0, _emberConcurrency.task)({
    restartable: true
  }), _dec6 = (0, _object.observes)('area'), _dec7 = Ember._action, (_class = class MapEdit extends _baseMap.default.extend(_preferredLayerset.default) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "layoutName", 'components/map/base-map');
      // TODO: To mixin
      _defineProperty(this, "constraintGeometries", []);
      _defineProperty(this, "constraintsViolated", false);
      // TODO: To mixin!
      _defineProperty(this, "geolocationEnabled", true);
      _defineProperty(this, "modifyOnLocateMe", true);
      // TODO: Can we extract those?
      _defineProperty(this, "enabledControls", ['navigate', 'select', 'modify', 'point', 'polygon', 'box', 'circle', 'latlon', 'layerset', 'clear']);
      _defineProperty(this, "defaultControl", 'navigate');
      _defineProperty(this, "mutable", true);
      _defineProperty(this, "collapseSmallPolygons", false);
      _initializerDefineProperty(this, "_countryChanged", _descriptor, this);
      // Geolocation
      _defineProperty(this, "methods", [{
        name: 'From map',
        value: 'MAP'
      }, {
        name: 'GPS',
        value: 'GPS'
      }, {
        name: 'UTM',
        value: 'UTM'
      }]);
      _initializerDefineProperty(this, "updateAreaFields", _descriptor2, this);
    }
    init() {
      super.init(...arguments);
      this.constraintsChanged();
      this.countryChanged();
      this.syncAreaSelection();
    }

    // TODO: Mixin
    storeMapResolution(view, map) {
      var currentExtent = view.calculateExtent(map.getSize());
      const extent = (0, _proj.transformExtent)(currentExtent, this.internalProjection, this.externalProjection);
      this.set('currentExtent', extent);
    }
    reinitializeMap() {
      this.set('feedbackWKT', null);
      this.set('previousCountry', null);
      this.set('showCountry', true);
      super.reinitializeMap(...arguments);
    }
    createFullToolbar() {
      let buttons = super.createFullToolbar(...arguments);
      const featureLayer = this.featureLayer;
      const drawingSource = new _Vector.default({});
      const drawingSourceOnAdd = drawingSource.on('addfeature', function (evt) {
        this.addFeature(evt.feature);
      }.bind(this));
      this.cleanupActions.pushObject(function () {
        (0, _Observable.unByKey)(drawingSourceOnAdd);
      });
      buttons.modify = _map.default.controls.toolButtons.Modify.create({
        layers: [featureLayer],
        featureModified: function (feature) {
          this.featureModified(feature);
        }.bind(this)
      });
      buttons.point = _map.default.controls.toolButtons.Point.create({
        source: drawingSource
      });
      buttons.polygon = _map.default.controls.toolButtons.Polygon.create({
        source: drawingSource
      });
      buttons.box = _map.default.controls.toolButtons.Box.create({
        source: drawingSource
      });
      buttons.circle = _map.default.controls.toolButtons.Radius.create({
        source: drawingSource
      });
      buttons.clear = _map.default.controls.toolButtons.Clear.create({
        clicked: function () {
          drawingSource.clear();
          this.clear();
          buttons.navigate.set('isToggled', true);
        }.bind(this)
      });
      return buttons;
    }
    createCommonLayers() {
      let layers = super.createCommonLayers(...arguments);
      function createDefaultStyle(strokeColor, fillColor) {
        var fill = new _style.Fill({
          color: fillColor
        });
        var stroke = new _style.Stroke({
          color: strokeColor,
          width: 1.25
        });
        return new _style.Style({
          image: new _style.Circle({
            fill: fill,
            stroke: stroke,
            radius: 5
          }),
          fill: fill,
          stroke: stroke
        });
      }
      const constraintSource = new _Vector.default();
      const constraintLayer = new _Vector2.default({
        source: constraintSource,
        style: createDefaultStyle('#0000dd', 'rgba(255,255,255,0.1)')
      });
      const countrySource = new _Vector.default();
      const countryLayer = new _Vector2.default({
        source: countrySource,
        style: createDefaultStyle('#3399CC', 'rgba(255,255,255,0.1)')
      });
      this.setProperties({
        constraintLayer: constraintLayer,
        constraintSource: constraintSource,
        countryLayer: countryLayer,
        countrySource: countrySource
      });
      return [countryLayer, constraintLayer].concat(...layers);
    }
    createMap(base, extent) {
      super.createMap(...arguments);
      const {
        view,
        map
      } = this.getProperties(['view', 'map']);

      // TODO: you guessed it, mixin
      const resolutionHandlerKey = view.on('change:resolution', () => {
        Ember.run.once(this, this.storeMapResolution, view, map);
      });
      const panHandlerKey = view.on('change:center', () => {
        Ember.run.once(this, this.storeMapResolution, view, map);
      });
      this.cleanupActions.pushObject(() => {
        (0, _Observable.unByKey)(resolutionHandlerKey);
        (0, _Observable.unByKey)(panHandlerKey);
      });
    }
    cleanup() {
      super.cleanup(...arguments);
      this.setProperties({
        constraintLayer: null,
        constraintSource: null,
        countryLayer: null,
        countrySource: null
      });
      this.set('feedbackWKT', null);
    }
    addFeature(feature) {
      this.showFeature(feature);
      if (Ember.isNone(feature)) {
        if (this.singular) {
          // XXX: Prevent feedback loop
          this.set('geodata', null);
        }
      } else {
        this.featureAdded(feature);
      }
    }
    featureAdded(feature) {
      const wkt = this.writeWKT(feature);
      this.set('feedbackWKT', wkt);
      const [lon, lat] = this.mapToGeoCoords((0, _map.getGeometryCenter)(feature.getGeometry()));
      const structure = this.createGeodata(wkt, this, {
        lon: lon,
        lat: lat
      });
      if (this.singular) {
        this.set('geodata', structure);
      } else {
        this.geodata.pushObject(structure);
      }
      if (!Ember.isNone(this.geometryAdded)) {
        this.geometryAdded(wkt);
      }
      this.updateAreaFields.perform(feature.getGeometry());
    }
    featureModified(feature) {
      if (this.singular) {
        this.featureAdded(feature);
      } else {
        let geodata = this.geodata;
        const wkt = this.writeWKT(feature);
        if (Ember.isEmpty(this.geomProperty)) {
          const i = geodata.indexOf(feature.get('data'));
          if (i === -1) {
            throw 'featureModified: can\'t find modified feature in geodata array';
          } else {
            geodata.replace(i, 1, [wkt]);
          }
        } else {
          Ember.set(feature.get('data'), this.geomProperty, wkt);
        }
      }
    }
    clear() {
      this.featureSource.clear();
      this.set('geodata', this.singular ? null : []);
    }

    // TODO: Remove 'view' from observer list, replace with some has-initialized, because view changes
    // a lot
    constraintsChanged() {
      let source = this.constraintSource;
      const view = this.view;
      if (Ember.isNone(source) || Ember.isNone(view)) {
        return;
      }
      source.clear();
      let constraints = this.constraintGeometries;
      if (!Ember.isEmpty(constraints)) {
        constraints = (0, _structures.makeArray)(constraints).compact();
        const noGeodata = Ember.isEmpty(this.geodata);
        constraints.forEach(constraint => {
          let feature = this.parseWKT(constraint);
          feature.setStyle(this.getStyle(feature.getGeometry().getType(), {
            styleClass: 'parent'
          }));
          source.addFeature(feature);
          if (noGeodata && constraints.get('length') === 1) {
            const geometry = feature.getGeometry();
            this.focusOn(geometry, geometry.getType() !== 'Point');
          }
        });
      }
      this.checkConstraints();
    }
    checkConstraints() {
      const source = this.constraintSource;
      let geodata = (0, _structures.makeArray)(this.geodata);
      if (Ember.isNone(source) || Ember.isNone(geodata)) {
        return;
      }
      var sFeatures = source.getFeatures();
      var isPoint = false;
      if (!Ember.isEmpty(sFeatures)) {
        isPoint = sFeatures[0].getGeometry().getType() === 'Point';
      }
      const constraints = sFeatures.map(feature => feature.getGeometry()).filter(geometry => {
        const type = geometry.getType();
        return type === 'Polygon' || type === 'MultiPolygon';
      }).map(geometry => {
        return {
          geometry,
          extent: geometry.getExtent()
        };
      });
      if (!Ember.isEmpty(constraints)) {
        if (!Ember.isEmpty(this.geomProperty)) {
          geodata = geodata.mapBy(this.geomProperty);
        }
        const geometries = geodata.map(geom => this.parseWKT(geom).getGeometry());
        const violated = geometries.some(geom => {
          return constraints.some(constraint => {
            // TODO: This works for points, but return true even if only a part
            // of geom polygon lies inside constrain
            const extent = geom.getExtent();
            const inside = (0, _extent.intersects)(constraint.extent, extent);
            return !inside || !constraint.geometry.intersectsExtent(extent);
          });
        });
        this.set('constraintsViolated', violated);
      } else if (isPoint) {
        this.set('constraintsViolated', Ember.isPresent(this.geodata));
      }
    }
    countryChanged() {
      this._countryChanged.perform();
    }
    // Can't directly bind search to area, otherwise user will be able to set it to null,
    // which must not happen
    syncAreaSelection() {
      if (!Ember.isNone(this.area)) {
        this.set('areaSelection', this.area);
      }
    }
    searchCoordinates(lon, lat) {
      const geometry = this.coordsToGeometry(lon, lat);
      this.focusOn(geometry, false);
      this.addFeature(new _Feature.default(geometry));
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "constraintsChanged", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "constraintsChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "checkConstraints", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "checkConstraints"), _class.prototype), _descriptor = _applyDecoratedDescriptor(_class.prototype, "_countryChanged", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        if (!this.showCountry) {
          return;
        }
        const country = yield this.country;
        if (Ember.isNone(country) || country.id === this.previousCountry) {
          return;
        }
        this.set('previousCountry', country.id);
        let source = this.countrySource;

        // This can be called after the map is cleaned up, so need to check that
        // the source exists
        if (Ember.isNone(source)) {
          return;
        }
        source.clear();
        const geom = country.get('geom');
        if (!Ember.isEmpty(geom)) {
          const feature = this.parseWKT(geom);
          source.addFeature(feature);

          // If there are no other sources of zoom info, zoom to country
          if (Ember.isEmpty(this.geodata) && Ember.isEmpty(this.constraintGeometries)) {
            this.focusOn(feature.getGeometry(), true);
          }
        }
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "countryChanged", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "countryChanged"), _class.prototype), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "updateAreaFields", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* (geometry) {
        const center = (0, _map.getGeometryCenter)(geometry);
        const [lon, lat] = this.mapToGeoCoords(center);
        const country = yield (0, _map.geoqueryCountry)(this.store, lon, lat);
        if (!Ember.isNone(country)) {
          this.set('previousCountry', country.id);
          this.set('country', country);
        }
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "syncAreaSelection", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "syncAreaSelection"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchCoordinates", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "searchCoordinates"), _class.prototype)), _class));
  var _default = _exports.default = MapEdit;
});