define("plutof/adapters/jsonapi-base", ["exports", "@ember-data/adapter/json-api", "plutof/config/environment", "plutof/serializers/jsonapi-base", "plutof/adapters/auth"], function (_exports, _jsonApi, _environment, _jsonapiBase, _auth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class JSONAPIBaseAdapter extends _jsonApi.default.extend(_auth.default) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "host", _environment.default.API_HOST);
      // Resource types which will be marked as partial includes in (model-specific subclass)
      // adapter. Usually because they are private
      _defineProperty(this, "partialIncludes", new Set());
    }
    buildURL(modelName, id, snapshot, requestType, query) {
      let url = super.buildURL(`${this.modelName}`, id, snapshot, requestType, query);

      // XXX Well, damn.
      if (url.charAt(url.length - 1) !== '/') {
        url += '/';
      }
      return url;
    }
    resolveResourceTypes(recordData) {
      const {
        id,
        type,
        attributes = {},
        relationships = {}
      } = recordData;
      let transformedRelationships = {};
      function resolve(pointer) {
        return {
          id: pointer.id,
          type: _jsonapiBase.RESOURCE_TYPE_TO_MODEL[pointer.type]
        };
      }
      Object.keys(relationships).forEach(field => {
        const pointer = relationships[field];
        if (pointer && pointer.data) {
          transformedRelationships[field] = {
            data: Ember.isArray(pointer.data) ? pointer.data.map(resolve) : resolve(pointer.data)
          };
        }
      });
      return {
        id,
        type: _jsonapiBase.RESOURCE_TYPE_TO_MODEL[type],
        attributes,
        relationships: transformedRelationships
      };
    }
    handleResponse(status, headers, payload, requestData) {
      // XXX: Somethimes we don't get payload.
      // TODO: Find out why.
      if (payload && payload.included) {
        for (let data of payload.included) {
          const model = _jsonapiBase.RESOURCE_TYPE_TO_MODEL[data.type];
          if (model) {
            // XXX: JSON-API models' payload must be shoved as-is
            // But old-style APIs have to be transformed by collapsing id, attrs, rels etc
            // This is usually done by ember-data. However, types like Specimen, which are usually
            // serialized old-way, get sideloaded as json-api resources. Which means that serializer
            // and data don't match
            const record = this.store.push({
              data: this.resolveResourceTypes(data)
            });
            record.set('__partial', this.partialIncludes.has(data.type));
          }
        }
        payload.included = [];
      }
      return super.handleResponse(...arguments);
    }
  }
  var _default = _exports.default = JSONAPIBaseAdapter;
});