define("plutof/modifiers/unite/alignment-renderer", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _emberModifier.modifier)(function collapse(mount, [sequences, buildConfig]) {
    // 12px of panel padding
    const height = window.AlignmentRenderer.expectedHeight(sequences) + 12 * 3;
    mount.style.height = `min(${height}px, 90vh)`;
    const renderer = window.AlignmentRenderer.render(mount, sequences, buildConfig);
    return function cleanup() {
      renderer.destroy();
    };
  });
});