define("plutof/components/plutof-panel/item-list/toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <button
      {{on "click" (fn @toggle (not @checked))}}
      class="panel-control-toggle {{if @checked 'panel-control-toggle--checked'}}"
  >
      {{yield}}
  </button>
  
  */
  {
    "id": "5k64mwPb",
    "block": "{\"symbols\":[\"@checked\",\"@toggle\",\"&default\"],\"statements\":[[11,\"button\"],[16,0,[31,[\"panel-control-toggle \",[30,[36,0],[[32,1],\"panel-control-toggle--checked\"],null]]]],[4,[38,3],[\"click\",[30,[36,2],[[32,2],[30,[36,1],[[32,1]],null]],null]],null],[12],[2,\"\\n    \"],[18,3,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"not\",\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "plutof/components/plutof-panel/item-list/toggle.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});