define("plutof/components/plutof-panel/simple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::Panel
      @collapse={{@collapse}}
      @invalid={{@invalid}}
      ...attributes
      as |panel|
  >
      <panel.title>
          {{@title}}
      </panel.title>
  
      <panel.body>
          {{yield}}
      </panel.body>
  </PlutofPanel::Panel>
  
  */
  {
    "id": "Ab8TH1CD",
    "block": "{\"symbols\":[\"panel\",\"@collapse\",\"@invalid\",\"&attrs\",\"@title\",\"&default\"],\"statements\":[[8,\"plutof-panel/panel\",[[17,4]],[[\"@collapse\",\"@invalid\"],[[32,2],[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"title\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,5]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,1,[\"body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[18,6,null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "plutof/components/plutof-panel/simple.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});