define("plutof/translations/eng/sequences", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    title: 'Sequences',
    searchTitle: 'Search Sequences',
    infoTitle: 'Sequences',
    sIdentifiers: 'Sequence Identifiers',
    sTypes: 'Sequence types',
    accessionNumber: 'Accession number',
    searchLink: 'Search',
    newSequence: 'New Sequence',
    addMultiple: 'Upload from file',
    editSequence: 'Edit Sequence',
    sequence: 'Sequence',
    generalInfo: 'General Info',
    linkedTo: 'Linked to',
    sequencedRegions: 'Sequenced regions',
    object: 'Object',
    taxon: 'Taxon',
    length: 'Length',
    determinedBy: 'Determined by',
    project: 'Project',
    types: 'Types',
    parentTaxonNode: 'Parent taxon node',
    lineage: 'Lineage',
    lineageDescription: 'Lineage description',
    allToClipboard: 'All to clipboard',
    toClipboard: 'To clipboard',
    source: 'Source',
    originalSource: 'Original source',
    cannotRemoveUniteID: 'Can not remove UNITE accession number from UNITE sequence',
    potentiallyChimeric: 'This sequence is potentially chimeric',
    chimericScore: 'score',
    chimeraParents: 'Putative parent sequences are',
    id: 'Sequence ID',
    uniteStatusOption: 'Request UNITE code',
    parentEventReuseDialog: {
      title: 'Use source\'s sampling event?',
      content: 'If you want to link sequence record to its source\'s sampling event, you will not be able to edit sampling event record unless you edit the source record.',
      eventFromSource: 'Use source\'s Event',
      createEvent: 'Create new Event',
      continueUsingSameEvent: 'Continue using the same event'
    },
    annotation: {
      add: 'Annotate sequence',
      edit: 'Edit annotation',
      view: 'Annotated sequence'
    }
  };
});