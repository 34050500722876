define("plutof/translations/est/general", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    Projects: 'Projektid',
    Project: 'Projekt',
    CitizenScienceProjects: 'Harrastusteaduse projektid',
    taxon: 'Takson',
    taxonOccurrences: 'Taksoni esinemised',
    Persons: 'Isikud',
    Person: 'Isik',
    References: 'Kirjandusviited',
    Clipboard: 'Näpitstahvel',
    ClipboardModule: 'Näpitstahvel & Eksport',
    Settings: 'Seaded',
    laboratories: 'Laboratooriumid',
    Manuals: 'Kasutusjuhendid',
    PublishedDois: 'Avaldatud DOI-d',
    Doi: 'DOI',
    Specimens: 'Eksemplarid',
    Observations: 'Vaatlused',
    Sequences: 'Sekventsid',
    LivingC: 'Eluseksemplarid',
    RefBased: 'Kirjanduspõhised',
    FileRepo: 'Failide repositoorium',
    editFileRepo: 'Muuda faili',
    Publishing: 'Publitseerimine',
    CollectionManagement: 'Kogude labor',
    SequenceAnalyze: 'Sekventside analüüsid',
    UNITESH: 'UNITE liigihüpoteesid',
    materialSamples: 'Proovid',
    Photobank: 'Fotopank',
    Analysis: 'Analüüs',
    MolecularLab: 'Molekulaarlabor',
    AnalysesLab: 'Analüüside labor',
    PublishingLab: 'Publitseerimislabor',
    gisLab: 'Geoinfosüsteemide labor',
    New: 'Uus',
    Existing: 'Olemasolev',
    createNew: 'Lisa uus',
    Save: 'Salvesta',
    Edit: 'Muuda',
    Bookmark: 'Lisa järjehoidjatesse',
    Bookmarks: 'Järjehoidjad',
    Info: 'Info',
    Cancel: 'Tühista',
    NoInfo: 'Info puudub.',
    Reset: 'Lähtesta',
    Reject: 'Lükka tagasi',
    Remove: 'Eemalda',
    Add: 'Lisa',
    EnterNext: 'Sisesta järgmine',
    Back: 'Tagasi',
    Upload: 'Laadi üles',
    Close: 'Sulge',
    Search: 'Otsing',
    Browse: 'Vaata',
    Select: 'Vali',
    Download: 'Laadi alla',
    Downloads: 'Allalaadimised',
    Statistics: 'Statistika',
    Clear: 'Puhasta',
    Hide: 'Peida',
    Delete: 'Kustuta',
    showMore: 'Näita rohkem',
    language: 'Keel',
    unknown: 'Teadmata',
    Reply: 'Vasta',
    autoCompletePlaceholder: 'Otsi...',
    datepickerPlaceholder: 'AAAA-KK-PP',
    timePlaceholder: 'tt:mm',
    loggedInAs: 'Oled sisselogitud kasutajaga',
    userName: 'Kasutajanimi',
    passWord: 'Parool',
    addNext: 'Lisa järgmine',
    measurements: 'Tunnused ja mõõtmised',
    associatedData: 'Seotud objektid',
    identifications: 'Määrangud',
    identifiedBy: 'Määraja(d)',
    collectedBy: 'Koguja(d)',
    samplingArea: 'Asukoha andmed',
    samplingEvent: 'Kogumise sündmus',
    samplingEvents: 'Kogumise sündmused',
    samplingAreaEvent: 'Prooviala ja kogumise andmed',
    localityAndHabitat: 'Asukoht ja elupaik',
    preparations: 'Prepareerimise tüübid',
    preparedBy: 'Prepareerija(d)',
    interactions: 'Interaktsioonid',
    additionalIdentifiers: 'Teisesed koodid',
    substrate: 'Substraat',
    treatments: 'Hooldused',
    access: 'Ligipääsu õigused',
    sample: 'Proov',
    exsiccata: 'Eksikaat',
    generalData: 'Üldandmed',
    backgroundInformation: 'Taustainfo',
    loadingMeasurement: 'Tunnuste nimekirja laadimine',
    loading: 'Laadimine',
    show: 'näita',
    hide: 'peida',
    error: 'Viga',
    pending: 'Ootel',
    rejected: 'Tagasi lükatud',
    controls: 'Toimingud',
    markers: 'Markerid',
    Definition: 'Definitsioon',
    Options: 'Valikud',
    date: 'Kuupäev',
    time: 'Aeg',
    Converter: 'GPS konverter',
    converterButtonTooltip: 'Konverter',
    formType: 'Vormi tüüp',
    externalLink: 'Välised lingid',
    addEditIdentifications: 'Lisa ja muuda määranguid',
    id: 'ID',
    open: 'Ava',
    owner: 'Õiguste hoidja',
    ownerTooltip: 'PlutoF kirje õiguste hoidja, saab kirjet muuta ja sellele ligipääsu õiguseid anda',
    mainformName: 'Vormi nimi',
    measurementMethod: 'Meetod',
    loadMore: 'Laadi juurde',
    loadAll: 'Laadi kõik',
    createdUpdated: 'Sisestatud/Muudetud',
    identifierExists: 'Soovitud teisesed koodid juba eksisteerivad selles organisatsioonis.',
    yes: 'Jah',
    no: 'Ei',
    ok: 'OK',
    myMenu: 'Minu menüü',
    saving: 'Salvestab...',
    deleting: 'Kustutab...',
    done: 'Valmis.',
    bookmarksAndSearches: 'Järjehoidjad ja otsingud',
    createdBy: 'Looja',
    'continue': 'Jätka',
    videos: 'Õppevideod',
    Notifications: 'Teated',
    record: 'Kirje',
    searches: 'Otsingud',
    identificationsLabelPart: 'määrang',
    routeExitDlgTitle: 'Kas olete kindel, et soovite lahkuda salvestamata?',
    routeExitDlgContent: 'Lahkudes ilma salvestamata lähevad muudatused kaotsi.',
    deleteFilesDlgTitle: 'Kustuta seotud failid',
    deleted: 'Kustutatud',
    restore: 'Taasta',
    fields: 'Väljad',
    protectedObjects: 'Kaitstud kirjed',
    finish: 'Lõpeta',
    all: 'Kõik',
    login: 'Logi sisse',
    logout: 'Logi välja',
    loggingIn: 'profiili laadimine...',
    openWorkbench: 'Ava töölaud',
    updateNotification: 'Uuendus saadaval. <button class="btn-fake-link" onclick="window.location.reload(true)">Palun värskenda lehte</button>.',
    browserUpdateNotification: 'Uuendus saadaval. Palun värskenda lehte.',
    pageTitle: 'PlutoF elurikkuse platvorm',
    etc: 'jt.',
    addExternalMeasurements: 'Lisa tunnused',
    recordsNotFound: 'Vasteid otsingule ei leitud.',
    description: 'Kirjeldus',
    dialogs: {
      delete: {
        title: 'Kustuta?',
        content: 'Kustutatud andmeid ei saa taastada.',
        yes: 'Jah',
        no: 'Ei'
      },
      clipboardAppend: {
        title: 'Lisa {{moduleRecords}} näpitstahvlile',
        content: 'Lisa {{moduleRecords}} näpitstahvlile või kirjuta näpitstahvel üle.',
        append: 'Lisa kirjed',
        overwrite: 'Kirjuta üle',
        modules: {
          livingcultures: 'eluseksemplarid',
          materialsamples: 'proovid',
          observations: 'vaatlused',
          sequences: 'sekventsid',
          specimens: 'eksemplarid',
          occurrences: 'kirjanduspõhised esinemised',
          photobanks: 'fotopanga objektid'
        }
      }
    },
    connection: {
      lost: 'Ühendus katkes',
      restored: 'Ühendus on taastatud'
    },
    readMore: {
      more: 'Veel…',
      less: 'Vähem…'
    },
    tableBulkUpdate: {
      toggle: 'Muuda',
      update: 'Muuda'
    },
    termsOfService: {
      dialogTitle: 'Andmekaitse',
      accept: 'Nõustu',
      decline: 'Keeldu'
    },
    occurrencesCount: {
      label: 'Taksoni esinemisi'
    },
    pagination: {
      maxPageTooltip: 'Oled viimasel sirvitaval lehel.',
      maxPageNotification: 'Oled sirvitavate otsingutulemuste lõpus: {{maxResults}} kirjet.'
    }
  };
});