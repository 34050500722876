define("plutof/translations/est/forms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    baseForm: 'Baasvorm (vaikimisi väljad)',
    specimen: 'Eksemplar',
    observation: 'Vaatlus',
    Sequence: 'DNA järjestus',
    useAsTemplate: 'Mallid',
    materialS: 'Proov',
    livingculture: 'Eluseksemplar',
    title: 'Vormid',
    customForm: 'Kohandatud vorm',
    clickToMake: 'Linnuke märgib vormi välja kohustuslikuks.',
    needsModeration: 'Vajab modereerimist',
    moderateCheck: 'Märgi valituks kui selle vormiga sisestatud kirjed vajavad modereerimist.',
    moderators: 'Moderaatorid',
    usesCommonNames: 'Luba sisestamist omakeelsete nimede järgi',
    usesCommonNamesTooltip: 'Luba sisestamist omakeelsete nimede järgi',
    isolation: 'Isoleerimine',
    licenceType: 'Litsentsi tüüp',
    uploadingImage: 'Pildi laadimine...',
    taxonList: 'Taxon list',
    taxonListTooltip: 'Taksonid ja nende alamtaksonid, mida saab vormi juures kasutada',
    newTitle: 'Uus vorm',
    editTitle: 'Muuda vormi',
    default: 'Vaikimisi',
    bird: 'Lind',
    insect: 'Putukas',
    animal: 'Loom',
    fungus: 'Seen',
    plant: 'Taim',
    host: 'Peremehega seotud',
    // Samples
    plantAssociated: 'Taimega seotud',
    soil: 'Pinnas',
    water: 'Vesi',
    builtEnv: 'Tehiskeskkond',
    materialSample: {
      bulk: 'Hulgi proovid'
    },
    htsRepresentative: 'HTS representative',
    // Sequence

    algae: 'Vetikas',
    // Specimen
    mammalia: 'Imetaja',
    bat: 'Nahkhiir',
    traitsLabel: 'Tunnused ja mõõtmised',
    taxonSheet: {
      title: 'Sisestusleht'
    },
    palette: {
      headers: {
        habitat: 'Elupaiga väljad',
        event: 'Sündmuse väljad',
        area: 'Prooviala väljad',
        observation: 'Vaatluse väljad',
        specimen: 'Eksemplari väljad',
        materialsample: 'Proovi väljad',
        strain: 'Eluseksemplari väljad',
        sequence: 'Sekventsi väljad'
      }
    },
    formSelector: {
      title: 'Vorm',
      manual: 'Teised',
      placeholder: 'Vormi nimi...',
      nonwhitelistedForm: 'Projekt {{project}} ei toeta sellist kirjetüüpi'
    },
    traits: {
      trait: 'Tunnus',
      addRoute: 'Uus tunnus',
      editRoute: 'Muuda tunnust',
      setChoices: 'Valikud',
      source: 'Allikas'
    }
  };
});