define("plutof/utils/loading", ["exports", "ember-inflector", "plutof/config/environment", "plutof/misc/abstract", "plutof/misc/config", "plutof/services/ajax", "plutof/utils/promises", "plutof/utils/structures"], function (_exports, _emberInflector, _environment, _abstract, _config, _ajax, _promises, _structures) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.loadAll = loadAll;
  _exports.loadAllCountless = loadAllCountless;
  _exports.loadAllGeneric = loadAllGeneric;
  _exports.loadAllRaw = loadAllRaw;
  _exports.loadAllRawCountless = loadAllRawCountless;
  _exports.loadEntirePagination = loadEntirePagination;
  _exports.reload = reload;
  _exports.staggeredList = staggeredList;
  function reload(record) {
    var store = record.get('store');
    var id = record.get('id');
    var model = record.constructor.modelName;
    store.unloadRecord(record);
    return store.findRecord(model, id);
  }

  // Don't want to start all page requests immediately, because that would stall data
  // requests from other sources
  //
  // Going one-by-one is potentially slower
  //
  // Batching is ok, because other requests will be scheduled between batches
  const LOAD_ALL_BATCH_SIZE = 3;
  function loadAllGeneric(getCount, requestPage, pageSize = _config.default.Common.PAGE_SIZE, limit = Infinity) {
    if (pageSize > limit) {
      pageSize = limit;
    }
    return Ember.RSVP.Promise.resolve(getCount()).then(count => {
      const pageCount = Math.ceil(Math.min(count, limit) / pageSize);
      let pageRequests = [];
      for (let page = 0; page < pageCount; page++) {
        pageRequests.push(() => requestPage(page + 1, pageSize));
      }
      return _promises.default.batch(pageRequests, LOAD_ALL_BATCH_SIZE).then(_structures.flatten);
    });
  }
  function build_list_url(model) {
    return _environment.default.API_HOST + '/' + (0, _emberInflector.pluralize)(model) + '/';
  }

  // findAll crutch for paginated views
  function loadAll(store, model, query = {}, page_size = _config.default.Common.PAGE_SIZE, limit = Infinity) {
    const ajax = (0, _ajax.ajaxFromControlled)(store);
    async function getCount() {
      const url = (0, _abstract.construct_request)(build_list_url(model) + 'count/', query);
      let count = await ajax.request(url).then(meta => meta.objects_count);
      if (count === 0 && store.inAnnotation) {
        // Something might be among annotation changes. This is not 100% correct,
        // but the annotation changes are sort-of private and would rather not
        // dig for them outside the AnnotatedStore
        count = 1;
      }
      return count;
    }
    function loadPage(page, page_size) {
      // TODO: use utils/store::query
      return store.query(model, (0, _abstract.mergeObjects)({
        page: page,
        page_size: page_size
      }, query));
    }
    return loadAllGeneric(getCount, loadPage, page_size, limit);
  }
  function loadAllRaw(ajax, endpoint, query) {
    function getCount() {
      const url = (0, _abstract.construct_request)(`${endpoint}count/`, query);
      return ajax.request(url).then(meta => meta.objects_count);
    }
    function loadPage(page) {
      const url = (0, _abstract.construct_request)(endpoint, (0, _abstract.mergeObjects)(query, {
        page: page
      }));
      return ajax.request(url);
    }
    return loadAllGeneric(getCount, loadPage);
  }
  function loadAllCountless(store, model) {
    var objects = [];
    function load(page) {
      return store.query(model, {
        page: page
      }).then(function (results) {
        objects.pushObjects(results.toArray());
        if (results.get('length') === _config.default.Common.PAGE_SIZE) {
          return load(page + 1);
        } else {
          return objects;
        }
      }, function () {
        return objects;
      });
    }
    return load(1);
  }
  function loadAllRawCountless(ajax, endpoint, query, pageSize = _config.default.Common.PAGE_SIZE) {
    const objects = [];
    function load(page) {
      const url = (0, _abstract.construct_request)(endpoint, (0, _abstract.mergeObjects)(query, {
        page: page,
        page_size: pageSize
      }));
      return ajax.request(url).then(results => {
        objects.pushObjects(results.toArray());
        if (results.get('length') === pageSize) {
          return load(page + 1);
        } else {
          return objects;
        }
      }, () => {
        return objects;
      });
    }
    return load(1);
  }
  function staggeredList(from, {
    batchSize = 20
  } = {}) {
    return Ember.computed(from, function () {
      // Copy, because this hack is async and @from can change midway
      const source = this[from].slice();
      let staggered = [];
      let batchStart = 0;
      function stagger() {
        staggered.pushObjects(source.slice(batchStart, batchStart + batchSize));
        batchStart += batchSize;
        if (batchStart <= source.length) {
          Ember.run.next(this, stagger);
        }
      }
      stagger();
      return staggered;
    });
  }
  async function loadEntirePagination(pagination) {
    const pages = await Ember.RSVP.Promise.all((0, _abstract.range)(1, pagination.pageCount + 1).map(number => pagination.loadPage(number)));
    return [].concat(...pages);
  }
});