define("plutof/translations/eng/read-more", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    more: 'More…',
    less: 'Less…'
  };
});