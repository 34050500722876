define("plutof/translations/est/interaction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    taxon: 'Takson',
    interaction: 'Interaktsioon',
    taxons: 'Taksonid',
    interactionObject: 'Interaktsiooni objekt',
    typeOfInteraction: 'Interaktsiooni tüüp'
  };
});