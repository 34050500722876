define("plutof/misc/fileupload", ["exports", "plutof/config/environment", "plutof/misc/config", "plutof/utils/file-serialization", "plutof/utils/push-to-store", "plutof/utils/useragent"], function (_exports, _environment, _config, _fileSerialization, _pushToStore, _useragent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Uploader = void 0;
  _exports.downloadFile = downloadFile;
  _exports.downloadResponse = downloadResponse;
  _exports.get_uploader = get_uploader;
  _exports.init_uploader = init_uploader;
  _exports.readFileContent = readFileContent;
  _exports.selectFile = selectFile;
  _exports.shouldAnalyze = shouldAnalyze;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function shouldAnalyze(file) {
    return file.size <= _config.default.Filerepository.MAX_ANALYZED_SIZE;
  }
  let uploaderDeferrered = Ember.RSVP.defer();

  // TODO: Make uploader a service
  // TODO: Remove store parameter from all callers
  function get_uploader(store) {
    return uploaderDeferrered.promise;
  }
  function init_uploader(store, ajax, token) {
    const uploader = new Uploader(store, ajax);
    uploaderDeferrered.resolve(uploader);
  }
  function downloadFile(url) {
    if ((0, _useragent.onSafari)()) {
      // The only working way to programmatically download a link from a different
      // subdomain in Safari that worked for me.
      window.open(url, '_self');
    } else {
      const link = document.createElement('a');
      link.setAttribute('download', 'download');
      link.setAttribute('target', '_blank');
      link.href = url;
      const event = document.createEvent('MouseEvents');
      event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      link.dispatchEvent(event);
    }
  }

  // Calling ajax.request hits CORS in live. And at this point fetch is supported
  // everywhere anyway
  function readFileContent(url) {
    return fetch(url).then(response => response.json());
  }

  // If the "file" we want to download is behind auth-required endpoint,
  // can't delegate download to browser. So, this first downloads the response,
  // then prompts the user to save it
  async function downloadResponse(session, url, filename) {
    const response = await fetch(url, {
      headers: {
        'Authorization': `Bearer ${session.data.authenticated.access_token}`
      }
    });
    if (response.ok) {
      const content = await response.blob();
      await (0, _fileSerialization.saveFile)([content], filename);
    } else {
      throw new Error(await response.text());
    }
  }

  // Can return null when multiple=true, when user cancels selection.
  //
  // Alternatives:
  // - reject - but it's not really an error and given that our error handling is just reportError
  //   everywhere, it would be a mess
  // - not resolve at all - cleaner for existing callsites, but means that .then callbacks will
  //   stay forever; and if those refer to something big, we'd get a memory leak
  //
  // So check the result on callsite. Ugly, but better than the solutions above.
  // Wish this was TS, where we could just check this on type level
  function selectFile({
    multiple = false,
    accept
  } = {}) {
    const input = document.createElement('input');
    input.type = 'file';
    input.multiple = multiple;
    if (accept) {
      input.accept = accept;
    }

    // WTH
    if ((0, _useragent.onSafari)()) {
      const body = document.querySelector('body');
      body.appendChild(input);
    }
    return new Ember.RSVP.Promise((resolve, reject) => {
      function handler() {
        input.removeEventListener('change', handler);
        input.removeEventListener('cancel', handler);
        const files = Array.from(input.files);
        resolve(multiple ? files : files[0]);
        if ((0, _useragent.onSafari)()) {
          const body = document.querySelector('body');
          body.removeChild(input);
        }
      }
      input.addEventListener('change', handler);
      input.addEventListener('cancel', handler);
      input.click();
    });
  }
  let Upload = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, (_class = class Upload {
    constructor(store, ajax, file, {
      frame,
      isPublic = false,
      modelPath,
      endpoint
    } = {}) {
      _initializerDefineProperty(this, "progress", _descriptor, this);
      _initializerDefineProperty(this, "working", _descriptor2, this);
      _initializerDefineProperty(this, "finished", _descriptor3, this);
      _initializerDefineProperty(this, "record", _descriptor4, this);
      this.store = store;
      this.ajax = ajax;
      this.file = file;
      this.frame = frame;
      this.isPublic = isPublic;
      this.modelPath = modelPath;
      this.endpoint = endpoint;
    }
    async _start() {
      if (this.working) {
        throw new Error('Programmer error: start() called on in-progress Upload');
      }
      this.working = true;
      try {
        const recordData = await this._upload();

        // This is not really files endpoint but front-end model path.
        this.record = (0, _pushToStore.default)(this.store, this.modelPath, recordData);
        this.finished = true;
        this.progress = 1;
        return this.record;
      } finally {
        this.working = false;
      }
    }
    start() {
      this._promise = this._start();
    }
    wait() {
      return this._promise;
    }
    _upload() {
      this.working = true;
      const formData = new FormData();
      formData.append('upload', this.file);
      formData.append('is_public', this.isPublic);
      if (this.frame) {
        formData.append('frame', `${this.frame.x},${this.frame.y},${this.frame.width},${this.frame.height}`);
      }
      const params = {
        method: 'POST',
        data: formData,
        processData: false,
        contentType: false
      };
      params.xhr = () => {
        const xhr = new XMLHttpRequest();
        xhr.upload.addEventListener('progress', evt => {
          this.progress = evt.loaded / evt.total;
        });
        return xhr;
      };
      const endpoint = _environment.default.API_HOST + this.endpoint;
      return this.ajax.request(endpoint, params);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "progress", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "working", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "finished", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "record", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  })), _class));
  class Uploader {
    constructor(store, ajax) {
      _defineProperty(this, "uploads", []);
      this.store = store;
      this.ajax = ajax;
    }
    upload(file, {
      frame,
      isPublic = false
    } = {}) {
      const modelPath = 'filerepository/file';
      const endpoint = '/filerepository/files/upload/';
      const upload = new Upload(this.store, this.ajax, file, {
        frame,
        isPublic,
        modelPath,
        endpoint
      });
      this.uploads.pushObject(upload);
      upload.start();
      return upload;
    }
    remove(record) {
      const upload = this.uploads.find(upload => upload.record === record);
      this.uploads.removeObject(upload);
    }
  }

  /* eslint-enable no-console */
  _exports.Uploader = Uploader;
  var _default = _exports.default = Uploader;
});