define("plutof/translations/eng/substrate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    substrate: 'Substrate taxon',
    type: 'Substrate type',
    text: 'Substrate text',
    parentSubstrate: '{{parent}} substrate'
  };
});