define("plutof/components/glossary/auto-complete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <AutoComplete::Model
      @model="glossary/glossary"
      @value={{@value}}
      @update={{@update}}
      @params={{@params}}
      @representation="word"
      ...attributes />
  
  */
  {
    "id": "wfbERyJD",
    "block": "{\"symbols\":[\"@value\",\"@update\",\"@params\",\"&attrs\"],\"statements\":[[8,\"auto-complete/model\",[[17,4]],[[\"@model\",\"@value\",\"@update\",\"@params\",\"@representation\"],[\"glossary/glossary\",[32,1],[32,2],[32,3],\"word\"]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "plutof/components/glossary/auto-complete.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});