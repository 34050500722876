define("plutof/components/search/filters/vernacular-name-language", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @filter}}
      <div class="filter-input" data-input={{@filter.config.label}}>
          <div class="plutof-label">
              {{@filter.config.label}}
          </div>
  
          <AutoComplete::Model
              @model="geography/language"
              @update={{this.updateLanguage}}
              @value={{@filter.value.firstObject}}
              class="filter-input-ac" />
      </div>
  {{/if}}
  
  */
  {
    "id": "3ISFKp6W",
    "block": "{\"symbols\":[\"@filter\"],\"statements\":[[6,[37,0],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"filter-input\"],[15,\"data-input\",[32,1,[\"config\",\"label\"]]],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"plutof-label\"],[12],[2,\"\\n            \"],[1,[32,1,[\"config\",\"label\"]]],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[8,\"auto-complete/model\",[[24,0,\"filter-input-ac\"]],[[\"@model\",\"@update\",\"@value\"],[\"geography/language\",[32,0,[\"updateLanguage\"]],[32,1,[\"value\",\"firstObject\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "plutof/components/search/filters/vernacular-name-language.hbs"
    }
  });
  let VernacularNameLanguage = (_dec = Ember._action, (_class = class VernacularNameLanguage extends _component.default {
    updateLanguage(language) {
      this.args.filter.set('value', language ? [language] : []);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "updateLanguage", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "updateLanguage"), _class.prototype)), _class));
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, VernacularNameLanguage);
});