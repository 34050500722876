define("plutof/utils/datetime", ["exports", "plutof/misc/config"], function (_exports, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getLocalTimezone = getLocalTimezone;
  function getLocalTimezone() {
    if (window.Intl) {
      const tz = window.Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (!Ember.isEmpty(tz)) {
        return tz;
      }
    }
    return _config.default.Common.DEFAULT_TIMEZONE;
  }
});