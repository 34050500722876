define("plutof/templates/annotations/moderated", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "q1gXhiD6",
    "block": "{\"symbols\":[\"Field\",\"annotation\",\"fields\"],\"statements\":[[8,\"navbar/list\",[],[[\"@icon\",\"@title\",\"@goBack\",\"@pagination\"],[[30,[36,0],[\"annotation\"],null],[30,[36,1],[\"annotation.routes.moderated.title\"],null],[30,[36,2],[\"goBack\"],null],[32,0,[\"model\",\"annotations\"]]]],null],[2,\"\\n\\n\"],[8,\"tables/list-route\",[],[[\"@records\"],[[32,0,[\"model\",\"annotations\",\"objects\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"annotation/fields\",[],[[\"@field\",\"@annotation\"],[[32,1],[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,3,[\"icon\"]],[],[[],[]],null],[2,\"\\n        \"],[8,[32,3,[\"link\"]],[],[[],[]],null],[2,\"\\n        \"],[8,[32,3,[\"author\"]],[],[[],[]],null],[2,\"\\n        \"],[8,[32,3,[\"comment\"]],[],[[],[]],null],[2,\"\\n        \"],[8,[32,3,[\"annotatedAt\"]],[],[[],[]],null],[2,\"\\n    \"]],\"parameters\":[3]}]]],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\",\"route-action\"]}",
    "meta": {
      "moduleName": "plutof/templates/annotations/moderated.hbs"
    }
  });
});