define("plutof/components/annotation/navbar/view", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Navbar::View
      @kind="annotation"
      @icon={{@icon}}
      @title={{@title}}
      @record={{@annotation}}
      @deletable={{true}}
      @permissions={{@permissions}}
      @neverDeleteRelatedFiles={{true}}
      as |navbar slots|
  >
      <slots.primaryControls>
          {{#if @permissions.canModerate}}
              <Annotation::ModerationButtons
                  @button={{navbar.button}}
                  @annotation={{@annotation}}
                  @moderated={{route-action "goBack"}} />
          {{/if}}
      </slots.primaryControls>
  
      <slots.secondaryControls>
          {{#if @permissions.canModify}}
              <navbar.button
                  @clicked={{this.edit}}
                  @icon={{icon "edit"}}
              >
                  {{i18n-t "General.Edit"}}
              </navbar.button>
          {{/if}}
      </slots.secondaryControls>
  </Navbar::View>
  
  */
  {
    "id": "Vbh9JXD3",
    "block": "{\"symbols\":[\"navbar\",\"slots\",\"@annotation\",\"@icon\",\"@title\",\"@permissions\"],\"statements\":[[8,\"navbar/view\",[],[[\"@kind\",\"@icon\",\"@title\",\"@record\",\"@deletable\",\"@permissions\",\"@neverDeleteRelatedFiles\"],[\"annotation\",[32,4],[32,5],[32,3],true,[32,6],true]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"primaryControls\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[32,6,[\"canModerate\"]]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"annotation/moderation-buttons\",[],[[\"@button\",\"@annotation\",\"@moderated\"],[[32,1,[\"button\"]],[32,3],[30,[36,2],[\"goBack\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,2,[\"secondaryControls\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[32,6,[\"canModify\"]]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,[32,1,[\"button\"]],[],[[\"@clicked\",\"@icon\"],[[32,0,[\"edit\"]],[30,[36,0],[\"edit\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[1,[30,[36,1],[\"General.Edit\"],null]],[2,\"\\n            \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\",\"route-action\",\"if\"]}",
    "meta": {
      "moduleName": "plutof/components/annotation/navbar/view.hbs"
    }
  });
  let AnnotationViewNavbar = (_dec = Ember.inject.service, _dec2 = Ember._action, (_class = class AnnotationViewNavbar extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "router", _descriptor, this);
    }
    edit() {
      const model = this.args.annotation.constructor.modelName;

      // XXX
      if (model === 'annotation/client-annotation') {
        return this.router.transitionTo(`${this.args.parentRoute}.annotation.edit`, this.args.annotation.object_id, this.args.annotation.id);
      } else if (model === 'annotation/bulk-annotation') {
        return this.router.transitionTo('annotations.bulk.edit', this.args.annotation.id);
      } else {
        throw new Error(`Unknown annotation kind: ${model}`);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "edit", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "edit"), _class.prototype)), _class));
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, AnnotationViewNavbar);
});