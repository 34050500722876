define("plutof/templates/components/unite/singleton-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "IT4iTNoa",
    "block": "{\"symbols\":[\"@value\"],\"statements\":[[1,[30,[36,0],[\"UniteSH.includeSingletons\"],null]],[2,\"\\n\\n\"],[8,\"plutof-select/value\",[],[[\"@content\",\"@value\",\"@optionValuePath\",\"@optionLabelPath\"],[[32,0,[\"options\"]],[32,1],\"value\",\"label\"]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"common/local-label\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/unite/singleton-options.hbs"
    }
  });
});