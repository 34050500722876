define("plutof/components/view-preparations", ["exports", "ember-data", "@ember-decorators/component", "plutof/models/common/preparation", "plutof/utils/store"], function (_exports, _emberData, _component, _preparation, _store) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let PreparationsViewComponent = (_dec = (0, _component.classNames)('view-preparations'), _dec2 = Ember.inject.service, _dec3 = Ember.computed('model'), _dec4 = Ember.computed('parent.id'), _dec(_class = (_class2 = class PreparationsViewComponent extends Ember.Component {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "model", 'taxonoccurrence/specimen/specimen');
      _initializerDefineProperty(this, "router", _descriptor, this);
    }
    get modelset() {
      return (0, _preparation.getPreparationModelset)(this.model);
    }
    get entries() {
      const parentID = this.get('parent.id');
      if (Ember.isNone(parentID)) {
        return [];
      }
      const modelset = this.modelset;
      let params = {};
      params[modelset.objectField] = parentID;
      return _emberData.default.PromiseArray.create({
        promise: (0, _store.query)(this.store, modelset.preparationModel, params).then(preparations => {
          return Ember.RSVP.all(preparations.map(preparation => {
            return (0, _store.query)(this.store, modelset.agentModel, {
              preparation: preparation.id,
              ordering: 'id'
            }).then(agents => ({
              preparation,
              agents
            }));
          }));
        })
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "modelset", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "modelset"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "entries", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "entries"), _class2.prototype)), _class2)) || _class);
  var _default = _exports.default = PreparationsViewComponent;
});