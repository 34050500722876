define("plutof/components/publishing/gbif/contact-row-form", ["exports", "@ember-decorators/object", "ember-cp-validations", "plutof/utils/formatters", "plutof/mixins/component-validations"], function (_exports, _object, _emberCpValidations, _formatters, _componentValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const CONTACT_TYPE = 1;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'contact.first_name': (0, _emberCpValidations.validator)('presence', true),
    'contact.last_name': (0, _emberCpValidations.validator)('presence', true),
    'typeValidity': (0, _emberCpValidations.validator)('is-truthy'),
    'emailValidity': (0, _emberCpValidations.validator)('is-truthy'),
    'orcidValidity': (0, _emberCpValidations.validator)('is-truthy')
  });
  let ContactRowForm = (_dec = Ember.computed.alias('contact.types'), _dec2 = Ember.computed.gt('contact.types.length', 0), _dec3 = Ember.computed('contact.email', 'types.[]'), _dec4 = Ember.computed('contact.orcid_identifier'), _dec5 = Ember.computed('orcidUrlValidity', 'contact.orcid_identifier'), _dec6 = Ember.computed('types.[]', 'agentTypes.[]'), _dec7 = Ember._action, _dec8 = (0, _object.observes)('agentType'), _dec9 = Ember._action, (_class = class ContactRowForm extends Ember.Component.extend(Validations, _componentValidations.default) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "tagName", 'tr');
      _defineProperty(this, "dummyPerson", null);
      _defineProperty(this, "agentType", null);
      _initializerDefineProperty(this, "types", _descriptor, this);
      _initializerDefineProperty(this, "typeValidity", _descriptor2, this);
    }
    get emailValidity() {
      const email = this.contact.email;
      const isRequired = this.types.includes(CONTACT_TYPE);
      if (isRequired) {
        return _formatters.EMAIL_REGEX.test(email);
      } else {
        return Ember.isEmpty(email) || _formatters.EMAIL_REGEX.test(email);
      }
    }
    get orcidUrlValidity() {
      return _formatters.ORCID_URL_REGEX.test(this.contact.orcid_identifier);
    }
    get orcidValidity() {
      const url = this.contact.orcid_identifier;
      return Ember.isEmpty(url) || _formatters.ORCID_URL_REGEX.test(url);
    }

    // Agent types are numbers, but we display representations.
    get contactTypeRepresentations() {
      if (Ember.isNone(this.agentTypes)) {
        return null;
      }
      return this.types.map(type => {
        return this.agentTypes.findBy('value', type);
      });
    }
    fillPerson(person) {
      // Preserving old behaviour
      this.set('dummyPerson', person);
      if (!person) {
        return;
      }
      const contact = this.contact;
      const primaryGivenName = person.get('primary_given_name');
      const givenName = person.get('given_name');
      const familyName = person.get('family_name');
      const orcid = person.get('orcid_identifier');
      Ember.set(contact, 'first_name', primaryGivenName || givenName);
      Ember.set(contact, 'last_name', familyName);
      Ember.set(contact, 'orcid_identifier', orcid);
    }
    agentTypeSelector() {
      const type = this.agentType;
      if (Ember.isNone(type)) {
        return;
      }
      if (!this.types.includes(type)) {
        this.types.pushObject(type);
      }

      // XXX Necessary for clearing selection input.
      Ember.run.next(this, () => Ember.set(this, 'agentType', null));
    }
    removeType(record) {
      this.types.removeObject(record.value);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "types", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "typeValidity", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "emailValidity", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "emailValidity"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "orcidUrlValidity", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "orcidUrlValidity"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "orcidValidity", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "orcidValidity"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "contactTypeRepresentations", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "contactTypeRepresentations"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fillPerson", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "fillPerson"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "agentTypeSelector", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "agentTypeSelector"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeType", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "removeType"), _class.prototype)), _class));
  var _default = _exports.default = ContactRowForm;
});