define("plutof/routes/unite/view", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "plutof/mixins/route", "plutof/utils/reflection"], function (_exports, _authenticatedRouteMixin, _route, _reflection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ViewClusterRoute = (_dec = Ember.inject.service, (_class = class ViewClusterRoute extends Ember.Route.extend(_authenticatedRouteMixin.default, _route.default) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _defineProperty(this, "defaultReturnRoute", 'unite.referencesequences');
      _defineProperty(this, "modelTitle", 'dshcluster');
    }
    model(params) {
      const store = this.store;
      const clusterP = store.findRecord('globalkey/dshcluster', params.id);
      return Ember.RSVP.hash({
        dshcluster: clusterP,
        collapseLevels: this.getCollapseLevels(params.id),
        refSeq: store.queryRecord('globalkey/referencesequence', {
          dshcluster: params.id
        }),
        dois: clusterP.then(async sh => {
          let dois = [];
          const currentVersionDOI = await sh.doi;
          async function version(sh) {
            const cluster = await sh.cluster;
            return cluster.version;
          }
          if (currentVersionDOI) {
            dois.push({
              current: true,
              version: version(sh),
              dois: [currentVersionDOI]
            });
          } else {
            dois.push({
              current: true,
              dois: []
            });
            const mappings = await this.ajax.request('/globalkey/shmappings/', {
              data: {
                dshcluster_current: sh.id
              }
            });
            const resolvedMappings = await Ember.RSVP.all(mappings.map(async mapping => {
              const previousVersionID = (0, _reflection.recordURLToID)(mapping.dshcluster_previous);
              const previousVersion = await store.findRecord('globalkey/dshcluster', previousVersionID);
              return [await version(previousVersion), await previousVersion.doi];
            }));
            const versions = new Map();
            for (const [version, doi] of resolvedMappings) {
              if (!doi) {
                continue;
              }
              if (!versions.has(version)) {
                versions.set(version, []);
              }
              versions.get(version).push(doi);
            }
            dois = dois.concat(Array.from(versions.entries()).sort().map(([version, dois]) => ({
              current: false,
              version,
              dois
            })));
          }
          return dois;
        }),
        thLineage: this.ajax.request('/globalkey/thshmappings/', {
          data: {
            dshcluster: params.id
          }
        }).then(async mappings => {
          if (mappings.length === 0) {
            return null;
          }
          const cluster = await clusterP;
          const threshold = await cluster.threshold;
          const mapping = mappings.find(mapping => (0, _reflection.recordURLToID)(mapping.threshold) === threshold.id);
          if (!mapping) {
            // Sure hope there are none of these
            return null;
          }
          return this.ajax.request(mapping.thobject + '/lineage/');
        }),
        mapData: this.ajax.request(`/globalkey/dshclusters/${params.id}/map/`)
      });
    }
    setupController(controller, model) {
      super.setupController(controller, model);
      controller.setProperties({
        'refSeq': model.refSeq,
        'modified': false,
        'canModifyRefseqs': null,
        'canModifyExclusions': null
      });
    }
    async getCollapseLevels(shID) {
      const counts = await this.ajax.request(`globalkey/dshclusters/${shID}/collapse-levels/`);
      return counts.map((count, level) => ({
        label: this.i18n.t('UniteSH.collapse.level', {
          hash: {
            level: level + 1
          }
        }),
        info: this.i18n.t(`UniteSH.collapse.levelDescriptions.${level}`),
        level,
        count: count
      }));
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  var _default = _exports.default = ViewClusterRoute;
});