define("plutof/translations/est/references", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    refInfo: 'Vaata, otsi ja halda kirjandusviiteid.',
    refSeriesTitle: 'Sarjad',
    refSeriesInfo: 'Vaata, otsi ja lisa uusi sarju.',
    glossaryInfo: 'Vaata, otsi ja lisa uusi märksõnu.',
    glossaryCollectionsInfo: 'Vaata, otsi ja lisa uusi märksõnade kogumikke.',
    refSeries: 'Sarjad',
    keywordsCollection: 'Märksõnade kogumikud',
    authors: 'Autorid',
    newReference: 'Uus kirjandusviide',
    editReference: 'Muuda kirjandusviidet',
    reference: 'Kirjandusviide',
    newRefSeries: 'Uus sari',
    editRefSeries: 'Muuda sarju',
    referenceSeries: 'Sari',
    newPublisher: 'Uus kirjastaja',
    editPublisher: 'Muuda kirjasta andmeid',
    publisher: 'Kirjastaja',
    newGlossary: 'Uus märksõna',
    editGlossary: 'Muuda märksõna',
    glossary: 'Märksõna',
    newCollection: 'Uus märksõnade kogumik',
    editCollection: 'Muuda märksõnade kogumikku',
    keywordCollection: 'Märksõnade kogumik',
    searchTitle: 'Otsi kirjandusviiteid',
    searchDOI: 'Otsi vabatekstina või DOI-d.',
    searchDOITooltip: 'Otsingud aadressilt https://refindit.org/'
  };
});