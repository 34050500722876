define("plutof/components/interaction/edit-multiple", ["exports", "@ember-decorators/component", "plutof/components/interaction/form", "plutof/utils/model"], function (_exports, _component, _form, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.UnboundInteractionsData = _exports.InteractionsData = void 0;
  var _dec, _dec2, _class, _dec3, _class2;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let BaseInteractionsData = (_dec = Ember._action, _dec2 = Ember._action, (_class = class BaseInteractionsData {
    constructor() {
      // { fadeId :: num, interaction :: BoundInteraction }
      _defineProperty(this, "entries", []);
      _defineProperty(this, "removedEntries", []);
    }
    _createInteractionData() {
      throw new Error('Not implemented');
    }
    add() {
      this.addInteractionData(this._createInteractionData());
    }
    addInteractionData(interaction) {
      this.entries.pushObject({
        fadeId: Math.random(),
        interaction
      });
    }
    remove(entry) {
      this.entries.removeObject(entry);
      this.removedEntries.push(entry);
    }
    async save() {
      await Ember.RSVP.all(this.removedEntries.mapBy('interaction').invoke('destroy'));
      this.removedEntries = [];
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "add", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "add"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "remove", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "remove"), _class.prototype)), _class));
  class InteractionsData extends BaseInteractionsData {
    constructor({
      store,
      occurrence
    }) {
      super();
      this.store = store;
      this.occurrence = occurrence;
    }
    _createInteractionData() {
      return new _form.BoundInteraction({
        store: this.store,
        interaction: this.store.createRecord('taxonoccurrence/interaction/interaction')
      });
    }
    async save() {
      await super.save();
      await Ember.RSVP.all(this.entries.map(entry => entry.interaction.save(this.occurrence)));
    }
    static async load(store, occurrence) {
      const data = new InteractionsData({
        store,
        occurrence
      });
      for (let interaction of await (0, _model.getObjectInteractions)(store, occurrence)) {
        const target = await interaction.interacting_object;
        data.addInteractionData(new _form.BoundInteraction({
          store,
          interaction,
          target
        }));
      }
      return data;
    }
  }
  _exports.InteractionsData = InteractionsData;
  class UnboundInteractionsData extends BaseInteractionsData {
    constructor({
      store
    }) {
      super();
      this.store = store;
    }
    _createInteractionData() {
      return new _form.UnboundInteraction({
        store: this.store
      });
    }
    async save(occurrences) {
      await super.save();
      await Ember.RSVP.all(occurrences.map(occurrence => {
        return Ember.RSVP.all(this.entries.map(entry => entry.interaction.save(occurrence)));
      }));
    }
  }
  _exports.UnboundInteractionsData = UnboundInteractionsData;
  let InteractionEditMultipleComponent = (_dec3 = (0, _component.classNames)('edit-interactions'), _dec3(_class2 = class InteractionEditMultipleComponent extends Ember.Component {}) || _class2);
  var _default = _exports.default = InteractionEditMultipleComponent;
});