define("plutof/models/analysis/type-mixins", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SHMATCH = _exports.ITSx = _exports.BLAST = void 0;
  const ITSx = _exports.ITSx = Ember.Mixin.create({
    domains: (0, _model.attr)('number'),
    concat: (0, _model.attr)('boolean'),
    detailed: (0, _model.attr)('boolean'),
    preserve: (0, _model.attr)('boolean'),
    organism: (0, _model.attr)('string'),
    anchor: (0, _model.attr)('number'),
    anchor_hmm: (0, _model.attr)('boolean'),
    partial: (0, _model.attr)('number'),
    minlen: (0, _model.attr)('number')
  });
  const BLAST = _exports.BLAST = Ember.Mixin.create({
    megablast_params: (0, _model.attr)('number', {
      defaultValue: 1
    }),
    unite: (0, _model.attr)('boolean'),
    gen_bank: (0, _model.attr)('boolean'),
    envir: (0, _model.attr)('boolean'),
    animal: (0, _model.attr)('boolean'),
    tri12: (0, _model.attr)('boolean'),
    mb_no_of_alignments: (0, _model.attr)('number', {
      defaultValue: 1
    }),
    mb_output_style: (0, _model.attr)('number', {
      defaultValue: 1
    })
  });
  const SHMATCH = _exports.SHMATCH = Ember.Mixin.create({
    sequence_region: (0, _model.attr)('string', {
      defaultValue: 'itsfull'
    }),
    itsx_step: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    substring_dereplication: (0, _model.attr)('boolean', {
      defaultValue: true
    })
  });
});