define("plutof/routes/conservation-lab/red-list/view", ["exports", "plutof/utils/routes", "plutof/utils/access", "plutof/utils/pagination"], function (_exports, _routes, _access, _pagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class RedListViewRoute extends _routes.ViewRoute {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "defaultReturnRoute", 'conservation-lab.red-list.index');
      _defineProperty(this, "title", 'conservation.redList.view.title');
    }
    async model({
      id
    }) {
      const redlist = await this.store.findRecord('redbook/red-list', id);
      const permissions = await (0, _access.groupBasedPermissions)(this.store, redlist, {
        getManagingGroup: () => redlist.workgroup
      });
      const workgroup = await redlist.workgroup;
      const assessments = await (0, _pagination.paginatePublishingModel)(this.store, 'redbook/red-list-assessment', {
        red_list: id
      });
      return {
        redlist,
        permissions,
        workgroup,
        assessments
      };
    }
  }
  var _default = _exports.default = RedListViewRoute;
});