define("plutof/mixins/model_mixins", ["exports", "ember-data", "plutof/misc/abstract", "plutof/misc/content_types", "plutof/utils/model"], function (_exports, _emberData, _abstract, _content_types, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DeterminationsMixin = void 0;
  // TODO: Remove this
  var DeterminationsMixin = _exports.DeterminationsMixin = Ember.Mixin.create({
    init: function () {
      this._super();
    },
    determinations: Ember.computed('id', 'modelType', function () {
      var store = this.store;
      var id = this.id;
      var model_type = this.modelType;
      if (Ember.isNone(id) || Ember.isNone(model_type)) {
        return (0, _abstract.wrap_as_promise_array)([]);
      }
      return _emberData.default.PromiseArray.create({
        promise: (0, _content_types.get_ctype_by_name)(store, model_type).then(function (ctype) {
          return (0, _model.getDeterminations)(store, ctype, id);
        })
      });
    }),
    lastDetermination: Ember.computed.alias('determinations.firstObject')
  });
});