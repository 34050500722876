define("plutof/templates/annotations/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "OspsPGKG",
    "block": "{\"symbols\":[\"hub\"],\"statements\":[[8,\"layout/module-hub\",[],[[\"@icon\",\"@title\"],[[30,[36,0],[\"annotation\"],null],[30,[36,1],[\"annotation.annotations\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"layout/regular-grid\",[],[[\"@columns\"],[3]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,1,[\"link\"]],[],[[\"@title\",\"@description\",\"@buttonTitle\",\"@route\",\"@icon\"],[[30,[36,1],[\"annotation.routes.created.title\"],null],[30,[36,1],[\"annotation.routes.created.description\"],null],[30,[36,1],[\"annotation.routes.created.buttonTitle\"],[[\"rejected\"],[[32,0,[\"model\",\"rejectedCount\"]]]]],\"annotations.created\",[30,[36,0],[\"annotation\"],null]]],null],[2,\"\\n\\n        \"],[8,[32,1,[\"link\"]],[],[[\"@title\",\"@description\",\"@buttonTitle\",\"@route\",\"@icon\"],[[30,[36,1],[\"annotation.routes.moderated.title\"],null],[30,[36,1],[\"annotation.routes.moderated.description\"],null],[30,[36,1],[\"annotation.routes.moderated.buttonTitle\"],[[\"count\"],[[32,0,[\"model\",\"pendingCount\"]]]]],\"annotations.moderated\",[30,[36,0],[\"annotation\"],null]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\"]}",
    "meta": {
      "moduleName": "plutof/templates/annotations/index.hbs"
    }
  });
});