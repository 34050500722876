define("plutof/components/measurements/measurement-view/single", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="plutof-tooltip-hover">
      <div class="plutof-label">
          {{@measurement.fullName}}
  
          {{#if @expand}}
              <button
                  {{on "click" @expand}}
                  class="btn-fake-link btn-fake-link--no-underline"
              >
                  ...
              </button>
          {{/if}}
      </div>
  
      <div>
          <span class="plutof-tooltip">
              {{break-line @measurement.fullDescription}}
  
              {{#each @measurement.infoLinks as |info|}}
                  {{info-link infoLink=info}}
              {{/each}}
          </span>
      </div>
  </div>
  
  <p
      data-measurement="{{@measurement.measurementData.name}}"
      class="view-measurements__measurement {{if @measurement.augmented 'annotated'}}"
  >
      {{@measurement.representation}}&nbsp;
  </p>
  
  */
  {
    "id": "gK6vxacl",
    "block": "{\"symbols\":[\"info\",\"@expand\",\"@measurement\"],\"statements\":[[10,\"div\"],[14,0,\"plutof-tooltip-hover\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"plutof-label\"],[12],[2,\"\\n        \"],[1,[32,3,[\"fullName\"]]],[2,\"\\n\\n\"],[6,[37,2],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[11,\"button\"],[24,0,\"btn-fake-link btn-fake-link--no-underline\"],[4,[38,1],[\"click\",[32,2]],null],[12],[2,\"\\n                ...\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"plutof-tooltip\"],[12],[2,\"\\n            \"],[1,[30,[36,3],[[32,3,[\"fullDescription\"]]],null]],[2,\"\\n\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,3,[\"infoLinks\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[1,[30,[36,0],null,[[\"infoLink\"],[[32,1]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"p\"],[15,\"data-measurement\",[31,[[32,3,[\"measurementData\",\"name\"]]]]],[15,0,[31,[\"view-measurements__measurement \",[30,[36,2],[[32,3,[\"augmented\"]],\"annotated\"],null]]]],[12],[2,\"\\n    \"],[1,[32,3,[\"representation\"]]],[2,\" \\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"info-link\",\"on\",\"if\",\"break-line\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "plutof/components/measurements/measurement-view/single.hbs"
    }
  });
  class ViewSingleMeasurement extends _component.default {}
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ViewSingleMeasurement);
});