define("plutof/templates/components/linked-items/edit-links", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "YaDQPLfB",
    "block": "{\"symbols\":[\"item\",\"@itemStore\"],\"statements\":[[10,\"div\"],[14,0,\"linked-items__form-container\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"linked-items__form add-component\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"component-part\"],[12],[2,\"\\n            \"],[1,[30,[36,0],null,[[\"path\"],[\"reference.externallink.link\"]]]],[2,\"\\n\\n            \"],[1,[30,[36,2],null,[[\"type\",\"value\",\"placeholder\",\"class\"],[\"text\",[35,1],\"https://www.example.com/\",\"form-control control linked-items__external-link__link\"]]]],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"div\"],[14,0,\"component-part\"],[12],[2,\"\\n            \"],[1,[30,[36,0],null,[[\"path\"],[\"reference.externallink.description\"]]]],[2,\"\\n\\n            \"],[8,\"flexible-textarea\",[[24,0,\"linked-items__form-textarea form-control control\"]],[[\"@value\"],[[34,3]]],null],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"div\"],[14,0,\"component-part component-part--button\"],[12],[2,\"\\n            \"],[11,\"button\"],[16,\"disabled\",[30,[36,5],[[35,4]],null]],[24,0,\"btn btn-default control linked-items__external-link__add-button\"],[4,[38,6],[[32,0],\"add\"],null],[12],[2,\"\\n                \"],[10,\"span\"],[15,0,[30,[36,7],[\"ok\"],null]],[12],[13],[2,\"\\n                \"],[1,[30,[36,8],[\"General.Add\"],null]],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[8,\"common/record-list\",[],[[\"@records\",\"@remove\"],[[32,2,[\"items\"]],[32,0,[\"remove\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[32,1,[\"link\"]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"],[1,[30,[36,10],null,[[\"pagination\"],[[35,9,[\"pagination\"]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"plutof-labelc\",\"link\",\"input\",\"linkDescription\",\"canAdd\",\"not\",\"action\",\"icon\",\"i18n-t\",\"itemStore\",\"pagination/load-more\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/linked-items/edit-links.hbs"
    }
  });
});